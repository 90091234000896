import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAirEmissionsJournalDto, AirEmissionsJournalApi, UpdateAirEmissionsJournalDto, AirEmissionsJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface AirEmissionsJournalsState {
  airEmissionsJournals: AirEmissionsJournal[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AirEmissionsJournalsState = {
  airEmissionsJournals: [],
  status: "idle",
};

export const createAirEmissionsJournalAsync = createAsyncThunk(
  "airEmissionsJournals/create",
  async (airEmissionsJournal: CreateAirEmissionsJournalDto, { rejectWithValue }) => {
    try {
      const airEmissionsJournalsApi = new AirEmissionsJournalApi();
      const response = await airEmissionsJournalsApi.airEmissionsJournalControllerCreate(airEmissionsJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAirEmissionsJournalAsync = createAsyncThunk(
  "airEmissionsJournals/update",
  async ({ airEmissionsJournal, id }: { airEmissionsJournal: UpdateAirEmissionsJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const airEmissionsJournalsApi = new AirEmissionsJournalApi();
      const response = await airEmissionsJournalsApi.airEmissionsJournalControllerUpdate(airEmissionsJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAirEmissionsJournalAsync = createAsyncThunk(
  "airEmissionsJournals/remove",
  async ({ airEmissionsJournal }: { airEmissionsJournal: AirEmissionsJournal }, { rejectWithValue }) => {
    try {
      const airEmissionsJournalsApi = new AirEmissionsJournalApi();
      const response = await airEmissionsJournalsApi.airEmissionsJournalControllerRemove(airEmissionsJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAirEmissionsJournalsByOrganizationAsync = createAsyncThunk("airEmissionsJournals/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const airEmissionsApi = new AirEmissionsJournalApi();
    const response = await airEmissionsApi.airEmissionsJournalControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAirEmissionsJournalsByAirEmissionsSite = () => {
    const { pending, fulfilled, rejected } = fetchAirEmissionsJournalsByOrganizationAsync;
    return {
      [`${pending}`]: (state: AirEmissionsJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AirEmissionsJournal[]>) => {
        state.airEmissionsJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAirEmissionsJournal = () => {
    const { pending, fulfilled, rejected } = createAirEmissionsJournalAsync;
    return {
      [`${pending}`]: (state: AirEmissionsJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AirEmissionsJournal>) => {
        state.airEmissionsJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAirEmissionsJournal = () => {
    const { pending, fulfilled, rejected } = updateAirEmissionsJournalAsync;

    return {
      [`${pending}`]: (state: AirEmissionsJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AirEmissionsJournal>) => {
        state.airEmissionsJournals = state.airEmissionsJournals.map((airEmissionsJournal) => (airEmissionsJournal.id === action.payload.id ? action.payload : airEmissionsJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAirEmissionsJournal = () => {
    const { pending, fulfilled, rejected } = deleteAirEmissionsJournalAsync;
    return {
      [`${pending}`]: (state: AirEmissionsJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AirEmissionsJournal>) => {
        state.airEmissionsJournals.splice(state.airEmissionsJournals.indexOf(
          state.airEmissionsJournals.find((ws: AirEmissionsJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAirEmissionsJournalsByAirEmissionsSite(), ...createAirEmissionsJournal(), ...updateAirEmissionsJournal(), ...removeAirEmissionsJournal() };
};

export const airEmissionsJournalsSlice = createSlice({
  name: "airEmissionsJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAirEmissionsJournals = (state: RootState) => state.airEmissionsJournals.airEmissionsJournals;

export default airEmissionsJournalsSlice.reducer;
