import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteAccumulationSiteDto, WasteAccumulationSitesApi, UpdateWasteAccumulationSiteDto, WasteAccumulationSite } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteAccumulationSitesState {
  wasteAccumulationSites: WasteAccumulationSite[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteAccumulationSitesState = {
  wasteAccumulationSites: [],
  status: "idle",
};

export const createWasteAccumulationSiteAsync = createAsyncThunk(
  "wasteAccumulationSites/create",
  async (wasteAccumulationSite: CreateWasteAccumulationSiteDto, { rejectWithValue }) => {
    try {
      const wasteAccumulationSitesApi = new WasteAccumulationSitesApi();
      const response = await wasteAccumulationSitesApi.wasteAccumulationSitesControllerCreate(wasteAccumulationSite);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteAccumulationSiteAsync = createAsyncThunk(
  "wasteAccumulationSites/update",
  async ({ wasteAccumulationSite, id }: { wasteAccumulationSite: UpdateWasteAccumulationSiteDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteAccumulationSitesApi = new WasteAccumulationSitesApi();
      const response = await wasteAccumulationSitesApi.wasteAccumulationSitesControllerUpdate(wasteAccumulationSite, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteAccumulationSiteAsync = createAsyncThunk(
  "wasteAccumulationSites/remove",
  async ({ wasteAccumulationSite }: { wasteAccumulationSite: WasteAccumulationSite }, { rejectWithValue }) => {
    try {
      const wasteAccumulationSitesApi = new WasteAccumulationSitesApi();
      const response = await wasteAccumulationSitesApi.wasteAccumulationSitesControllerRemove(wasteAccumulationSite.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteAccumulationSitesByOrganizationAsync = createAsyncThunk("wasteAccumulationSites/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteAccumulationSitesApi();
    const response = await wasteApi.wasteAccumulationSitesControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteAccumulationSitesByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWasteAccumulationSitesByOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteAccumulationSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationSitesState, action: PayloadAction<WasteAccumulationSite[]>) => {
        state.wasteAccumulationSites = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteAccumulationSite = () => {
    const { pending, fulfilled, rejected } = createWasteAccumulationSiteAsync;
    return {
      [`${pending}`]: (state: WasteAccumulationSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationSitesState, action: PayloadAction<WasteAccumulationSite>) => {
        state.wasteAccumulationSites.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteAccumulationSite = () => {
    const { pending, fulfilled, rejected } = updateWasteAccumulationSiteAsync;

    return {
      [`${pending}`]: (state: WasteAccumulationSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationSitesState, action: PayloadAction<WasteAccumulationSite>) => {
        state.wasteAccumulationSites = state.wasteAccumulationSites.map((wasteAccumulationSite) => (wasteAccumulationSite.id === action.payload.id ? action.payload : wasteAccumulationSite));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteAccumulationSite = () => {
    const { pending, fulfilled, rejected } = deleteWasteAccumulationSiteAsync;
    return {
      [`${pending}`]: (state: WasteAccumulationSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationSitesState, action: PayloadAction<WasteAccumulationSite>) => {
        state.wasteAccumulationSites.splice(state.wasteAccumulationSites.indexOf(
          state.wasteAccumulationSites.find((ws: WasteAccumulationSite) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteAccumulationSitesByOrganization(), ...createWasteAccumulationSite(), ...updateWasteAccumulationSite(), ...removeWasteAccumulationSite() };
};

export const wasteAccumulationSitesSlice = createSlice({
  name: "wasteAccumulationSites",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteAccumulationSites = (state: RootState) => state.wasteAccumulationSites.wasteAccumulationSites;

export default wasteAccumulationSitesSlice.reducer;
