import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { WasteRegister, WasteRegisterHazardEnum, WasteRegisterTypeEnum } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";

export const WasteRegistersColumns = (): EntityColumns<WasteRegister>[] => {
  const organization = useAppSelector(selectOrganizationsProfile);
  const period = useAppSelector(selectOrganizationModulePeriod);

  function getHazardLabel(code: string): string {
    switch (code) {
      case WasteRegisterHazardEnum.DANGER:
        return "Опасный";
      case WasteRegisterHazardEnum.NOTDANGER:
        return "Не опасный";
      case WasteRegisterHazardEnum.MIRROR:
        return "Зеркальный";
      default:
        return code;
    }
  }

  function getTypeLabel(code: string): string {
    switch (code) {
      case WasteRegisterTypeEnum.Overburden:
        return "Вскрышные породы";
      case WasteRegisterTypeEnum.Landfill:
        return "Вымещающие породы";
      case WasteRegisterTypeEnum.Enrichment:
        return "Отходы обогащения";
      default:
        return code;
    }
  }

  return [
    {
      title: "Разрешение",
      dataIndex: "permission",
      key: "permissionId",
      inputNode: <Select options={organization?.permissions?.map(
        (permission) => {
          return {
            value: permission.id,
            label: permission.name
          }
        }
      )} />,
      render: (perm) => <Text className="w-64" ellipsis={{ tooltip: `${organization?.permissions?.find((permission) => permission.id === perm)?.name}` }}>{organization?.permissions?.find((permission) => permission.id === perm)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Наименование отхода",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Код отхода",
      dataIndex: "code",
      key: "code",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Уровень опасности",
      dataIndex: "hazard",
      key: "hazard",
      inputNode: <Select options={[
        {
          value: WasteRegisterHazardEnum.DANGER,
          label: getHazardLabel(WasteRegisterHazardEnum.DANGER)
        },
        {
          value: WasteRegisterHazardEnum.NOTDANGER,
          label: getHazardLabel(WasteRegisterHazardEnum.NOTDANGER)
        },
        {
          value: WasteRegisterHazardEnum.MIRROR,
          label: getHazardLabel(WasteRegisterHazardEnum.MIRROR)
        }
      ]} />,
      render: (hazard) => <Text className="w-64" ellipsis={{ tooltip: `${getHazardLabel(hazard)}` }}>{getHazardLabel(hazard)}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      inputNode: <Select options={[
        {
          value: null,
          label: null,
        },
        {
          value: WasteRegisterTypeEnum.Overburden,
          label: getTypeLabel(WasteRegisterTypeEnum.Overburden)
        },
        {
          value: WasteRegisterTypeEnum.Landfill,
          label: getTypeLabel(WasteRegisterTypeEnum.Landfill)
        },
        {
          value: WasteRegisterTypeEnum.Enrichment,
          label: getTypeLabel(WasteRegisterTypeEnum.Enrichment)
        }
      ]} />,
      render: (type) => <Text className="w-64" ellipsis={{ tooltip: `${getTypeLabel(type)}` }}>{getTypeLabel(type)}</Text>,
      rules: [{ message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
