import { Layout, Tabs } from "antd";
import { Organization } from "../../api";
import { history } from "../../app/helpers";
import { TaxDropsComponent } from "../taxDrops/TaxDropsComponent";
import { TaxEmissionsComponent } from "../taxEmissions/TaxEmissionsComponent";
import { TaxWastesComponent } from "../taxWaste/TaxWastesComponent";

interface TaxComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const TaxComponent: React.FC<TaxComponentProps> = ({ organization, year, quarter }) => {
  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane={true}
          onChange={(path) => {
            let tabPath = history?.location?.pathname.split('/') || [];
            tabPath[6] = path;
            history.navigate && history.navigate(`${tabPath.join('/')}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[6]}
          items={[
            {
              label: 'Выбросы',
              key: 'tax-emissions',
              children: <TaxEmissionsComponent />,
            },
            {
              label: 'Сбросы',
              key: 'tax-drops',
              children: <TaxDropsComponent />,
            },
            {
              label: 'Отходы',
              key: 'tax-wastes',
              children: <TaxWastesComponent />
            }
          ]}
        />
      </Layout>
    </>
  );
};
