import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWastePassportDto, WastePassportsApi, UpdateWastePassportDto, WastePassport } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WastePassportsState {
  wastePassports: WastePassport[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WastePassportsState = {
  wastePassports: [],
  status: "idle",
};

export const createWastePassportAsync = createAsyncThunk(
  "wastePassports/create",
  async (wastePassport: CreateWastePassportDto, { rejectWithValue }) => {
    try {
      const wastePassportsApi = new WastePassportsApi();
      const response = await wastePassportsApi.wastePassportsControllerCreate(wastePassport);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWastePassportAsync = createAsyncThunk(
  "wastePassports/update",
  async ({ wastePassport, id }: { wastePassport: UpdateWastePassportDto; id: string }, { rejectWithValue }) => {
    try {
      const wastePassportsApi = new WastePassportsApi();
      const response = await wastePassportsApi.wastePassportsControllerUpdate(wastePassport, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWastePassportAsync = createAsyncThunk(
  "wastePassports/remove",
  async ({ wastePassport }: { wastePassport: WastePassport }, { rejectWithValue }) => {
    try {
      const wastePassportsApi = new WastePassportsApi();
      const response = await wastePassportsApi.wastePassportsControllerRemove(wastePassport.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWastePassportsByOrganizationIdAsync = createAsyncThunk("wastePassports/fetch", async ({ organizationId }: { organizationId: string }, { rejectWithValue }) => {
  try {
    const wasteApi = new WastePassportsApi();
    const response = await wasteApi.wastePassportsControllerFindByOrganizationid(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWastePassportsByOrganizationId = () => {
    const { pending, fulfilled, rejected } = fetchWastePassportsByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: WastePassportsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WastePassportsState, action: PayloadAction<WastePassport[]>) => {
        state.wastePassports = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WastePassportsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWastePassport = () => {
    const { pending, fulfilled, rejected } = createWastePassportAsync;
    return {
      [`${pending}`]: (state: WastePassportsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WastePassportsState, action: PayloadAction<WastePassport>) => {
        state.wastePassports.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WastePassportsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWastePassport = () => {
    const { pending, fulfilled, rejected } = updateWastePassportAsync;

    return {
      [`${pending}`]: (state: WastePassportsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WastePassportsState, action: PayloadAction<WastePassport>) => {
        state.wastePassports = state.wastePassports.map((wastePassport) => (wastePassport.id === action.payload.id ? action.payload : wastePassport));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WastePassportsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWastePassport = () => {
    const { pending, fulfilled, rejected } = deleteWastePassportAsync;
    return {
      [`${pending}`]: (state: WastePassportsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WastePassportsState, action: PayloadAction<WastePassport>) => {
        state.wastePassports.splice(state.wastePassports.indexOf(
          state.wastePassports.find((ws: WastePassport) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WastePassportsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWastePassportsByOrganizationId(), ...createWastePassport(), ...updateWastePassport(), ...removeWastePassport() };
};

export const wastePassportsSlice = createSlice({
  name: "wastePassports",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWastePassports = (state: RootState) => state.wastePassports.wastePassports;

export default wastePassportsSlice.reducer;
