import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateSoilJournalDto, SoilJournalApi, UpdateSoilJournalDto, SoilJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface SoilJournalsState {
  soilJournals: SoilJournal[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: SoilJournalsState = {
  soilJournals: [],
  status: "idle",
};

export const createSoilJournalAsync = createAsyncThunk(
  "soilJournals/create",
  async (soilJournal: CreateSoilJournalDto, { rejectWithValue }) => {
    try {
      const soilJournalsApi = new SoilJournalApi();
      const response = await soilJournalsApi.soilJournalControllerCreate(soilJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSoilJournalAsync = createAsyncThunk(
  "soilJournals/update",
  async ({ soilJournal, id }: { soilJournal: UpdateSoilJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const soilJournalsApi = new SoilJournalApi();
      const response = await soilJournalsApi.soilJournalControllerUpdate(soilJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSoilJournalAsync = createAsyncThunk(
  "soilJournals/remove",
  async ({ soilJournal }: { soilJournal: SoilJournal }, { rejectWithValue }) => {
    try {
      const soilJournalsApi = new SoilJournalApi();
      const response = await soilJournalsApi.soilJournalControllerRemove(soilJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSoilJournalsByQuarterAsync = createAsyncThunk("soilJournals/fetch", async ({organizationId, year, quarter}: {organizationId: string, year: number, quarter: number}, { rejectWithValue }) => {
  try {
    const soilApi = new SoilJournalApi();
    const response = await soilApi.soilJournalControllerFindByQuart(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchSoilJournalsBySoilSite = () => {
    const { pending, fulfilled, rejected } = fetchSoilJournalsByQuarterAsync;
    return {
      [`${pending}`]: (state: SoilJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilJournalsState, action: PayloadAction<SoilJournal[]>) => {
        state.soilJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createSoilJournal = () => {
    const { pending, fulfilled, rejected } = createSoilJournalAsync;
    return {
      [`${pending}`]: (state: SoilJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilJournalsState, action: PayloadAction<SoilJournal>) => {
        state.soilJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateSoilJournal = () => {
    const { pending, fulfilled, rejected } = updateSoilJournalAsync;

    return {
      [`${pending}`]: (state: SoilJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilJournalsState, action: PayloadAction<SoilJournal>) => {
        state.soilJournals = state.soilJournals.map((soilJournal) => (soilJournal.id === action.payload.id ? action.payload : soilJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeSoilJournal = () => {
    const { pending, fulfilled, rejected } = deleteSoilJournalAsync;
    return {
      [`${pending}`]: (state: SoilJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilJournalsState, action: PayloadAction<SoilJournal>) => {
        state.soilJournals.splice(state.soilJournals.indexOf(
          state.soilJournals.find((ws: SoilJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchSoilJournalsBySoilSite(), ...createSoilJournal(), ...updateSoilJournal(), ...removeSoilJournal() };
};

export const soilJournalsSlice = createSlice({
  name: "soilJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectSoilJournals = (state: RootState) => state.soilJournals.soilJournals;

export default soilJournalsSlice.reducer;
