import { Layout, Tabs } from "antd";
import { Organization } from "../../api";
import { history } from "../../app/helpers";
import { CarbonCoefficients } from "./carbonCoefficients/CarbonCoefficients";
import { CarbonStationarySource } from "./carbonStationarySource/components/CarbonStationarySource";
import { CarbonMobileSourceComponent } from "./carbonMobileSource/CarbonMobileSourceComponent";
import { CarbonContractWorkComponent } from "./carbonContractWork/CarbonContractWorkComponent";
import { CarbonWasteRecycleComponent } from "./carbonWasteRecycle/CarbonWasteRecycleComponent";
import { CarbonIndirectEmissionComponent } from "./carbonIndirectEmission/CarbonIndirectEmissionComponent";

interface CarbonComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const CarbonComponent: React.FC<CarbonComponentProps> = ({ organization, year, quarter }) => {
  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane={true}
          onChange={(path) => {
            let tabPath = history?.location?.pathname.split('/') || [];
            tabPath[5] = path;
            history.navigate && history.navigate(`${tabPath.join('/')}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[5]}
          items={[
            {
              label: 'Коэффициенты',
              key: 'carbonCoefficients',
              children: <CarbonCoefficients />,
            },
            {
              label: 'Расчет ПГ от стационарных источников',
              key: 'carbonStationarySource',
              children: <CarbonStationarySource organization={organization} year={year} quarter={quarter} />,
            },
            {
              label: 'Расчет ПГ от передвижных источников',
              key: 'carbonMobileSource',
              children: <CarbonMobileSourceComponent organization={organization} year={year} quarter={quarter} />,
            },
            {
              label: 'Расчет выбросов от подрядных работ',
              key: 'carbonContractWork',
              children: <CarbonContractWorkComponent organization={organization} year={year} quarter={quarter} />,
            },
            {
              label: 'Расчет выбросов ПГ при захоронении и сжигании отходов',
              key: 'carbonWasteRecycle',
              children: <CarbonWasteRecycleComponent organization={organization} year={year} quarter={quarter} />,
            },
            {
              label: 'Косвенные выбросы ПГ',
              key: 'carbonIndirectEmission',
              children: <CarbonIndirectEmissionComponent organization={organization} year={year} quarter={quarter} />,
            }
          ]}
        />
      </Layout>
    </>
  );
};
