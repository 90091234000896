import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateInfluenceObjectDto, InfluenceObject, UpdateInfluenceObjectDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { InfluenceObjectForm } from "./components/InfluenceObjectForm";
import { InfluenceObjectColumns } from "./form/columns";
import { createInfluenceObjectAsync, updateInfluenceObjectAsync, selectInfluenceObject, deleteInfluenceObjectAsync, fetchInfluenceObjectByOrganizationIdAsync } from "./InfluenceObjectsSlice";
import { fetchPollutionSubstancesAsync } from "../pollutionSubstances/pollutionSubstancesSlice";

interface InfluenceObjectComponentProps {
  organizationId?: string;
}

export const InfluenceObjectComponent: React.FC<InfluenceObjectComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const influenceObject = useAppSelector(selectInfluenceObject);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchInfluenceObjectByOrganizationIdAsync(organizationId));
      dispatch(fetchPollutionSubstancesAsync())
    }
  }, [organizationId, dispatch]);

  const onUpdate = async (influenceObject: UpdateInfluenceObjectDto, id: string) => {
    return dispatch(updateInfluenceObjectAsync({ influenceObject, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateInfluenceObject = (influenceObject: CreateInfluenceObjectDto) => {
    if (!organizationId) return;

    dispatch(createInfluenceObjectAsync({ ...influenceObject, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteInfluenceObject = (influenceObject: InfluenceObject) => {
    return dispatch(deleteInfluenceObjectAsync({ influenceObject })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить объект воздействия
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <InfluenceObjectForm visible={visible} setVisible={setVisible} onCreateInfluenceObject={onCreateInfluenceObject} />}
      <EditableTable<InfluenceObject, UpdateInfluenceObjectDto>
        onSave={onUpdate}
        onDelete={deleteInfluenceObject}
        entityColumns={InfluenceObjectColumns()}
        dataSource={influenceObject}
        footer={() => addButton}
      />
    </Layout>
  );
};
