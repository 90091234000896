import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterDrainageHouseholdDto, WaterDrainageHousehold, Organization, UpdateWaterDrainageHouseholdDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { WaterDrainageHouseholdForm } from "./components/WaterDrainageHouseholdForm";
import { WaterDrainageHouseholdColumns } from "./form/columns";
import { createWaterDrainageHouseholdAsync, updateWaterDrainageHouseholdAsync, selectWaterDrainageHousehold, deleteWaterDrainageHouseholdAsync, fetchWaterDrainageHouseholdByOrganizationIdAsync } from "./WaterDrainageHouseholdsSlice";
import { fetchWaterDrainageObjectsAsync } from "../../waterDrainageObjects/WaterDrainageObjectsSlice";

interface WaterDrainageHouseholdComponentProps {
  organization?: Organization;
  year?: number;
}

export const WaterDrainageHouseholdComponent: React.FC<WaterDrainageHouseholdComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const waterDrainageHousehold = useAppSelector(selectWaterDrainageHousehold);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWaterDrainageHouseholdByOrganizationIdAsync({ organizationId: organization.id, year }));
      dispatch(fetchWaterDrainageObjectsAsync());
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (waterDrainageHousehold: UpdateWaterDrainageHouseholdDto, id: string) => {
    return dispatch(updateWaterDrainageHouseholdAsync({ waterDrainageHousehold, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterDrainageHousehold = (waterDrainageHousehold: CreateWaterDrainageHouseholdDto) => {
    if (!organization || !year) return;

    dispatch(createWaterDrainageHouseholdAsync({ ...waterDrainageHousehold, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterDrainageHousehold = (waterDrainageHousehold: WaterDrainageHousehold) => {
    return dispatch(deleteWaterDrainageHouseholdAsync({ waterDrainageHousehold })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterDrainageHouseholdForm visible={visible} setVisible={setVisible} onCreateWaterDrainageHousehold={onCreateWaterDrainageHousehold} />}
      <EditableTable<WaterDrainageHousehold, UpdateWaterDrainageHouseholdDto>
        onSave={onUpdate}
        onDelete={deleteWaterDrainageHousehold}
        entityColumns={WaterDrainageHouseholdColumns()}
        dataSource={structuredClone(waterDrainageHousehold).map((data: any) => {
          const waterDrainageObject = data?.waterDrainageObject?.id;
          delete data?.waterDrainageObject;
          data.waterDrainageObject = waterDrainageObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
