import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { UpdateUserDto, User } from "../../../api";
import { DefaultOptionType } from "antd/es/select";

interface UserFormProps {
  user: User;
  roles: DefaultOptionType[];
  organizations?: DefaultOptionType[];
  visible: boolean;
  setVisible: Function;
  onUpdateUser: (values: UpdateUserDto, id: string) => void;
}

export const UserUpdateForm: React.FC<UserFormProps> = ({ user, roles, organizations, visible, setVisible, onUpdateUser }) => {
  const [form] = Form.useForm<UpdateUserDto>();

  const onFinish = (values: UpdateUserDto) => {
    onUpdateUser(values, user.id);
  };

  return (
    <Modal open={visible} onOk={form.submit} okText="Обновить" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onFinish} initialValues={{
        organizationIds: user.organizations.map((org) => (org.id))
      }}>
        <Form.Item label="Имя" name="name" initialValue={user && user?.name} rules={[{ required: true, message: "Заполните поле" }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Отделы" name="roles" initialValue={user && user?.roles} rules={[{ required: true, message: "Выберите отделы" }]}>
          <Select mode="multiple" placeholder="Выберите отделы" options={roles} />
        </Form.Item>

        <Form.Item label="Телефон" name="phone" initialValue={user && user?.phone}>
          <Input prefix={"+7"} maxLength={10} />
        </Form.Item>

        <Form.Item label="Филиал" name="organizationIds">
          <Select mode="multiple" placeholder="Выберите филиалы" options={organizations} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
