import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterDrainageProductionDto, WaterDrainageProductionsApi, UpdateWaterDrainageProductionDto, WaterDrainageProduction } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WaterDrainageProductionState {
  waterDrainageProduction: WaterDrainageProduction[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterDrainageProductionState = {
  waterDrainageProduction: [],
  status: "idle",
};

export const createWaterDrainageProductionAsync = createAsyncThunk(
  "waterDrainageProduction/create",
  async (waterDrainageProduction: CreateWaterDrainageProductionDto, { rejectWithValue }) => {
    try {
      const waterDrainageProductionApi = new WaterDrainageProductionsApi();
      const response = await waterDrainageProductionApi.waterDrainageProductionsControllerCreate(waterDrainageProduction);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterDrainageProductionAsync = createAsyncThunk(
  "waterDrainageProduction/update",
  async ({ waterDrainageProduction, id }: { waterDrainageProduction: UpdateWaterDrainageProductionDto; id: string }, { rejectWithValue }) => {
    try {
      const waterDrainageProductionApi = new WaterDrainageProductionsApi();
      const response = await waterDrainageProductionApi.waterDrainageProductionsControllerUpdate(waterDrainageProduction, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterDrainageProductionAsync = createAsyncThunk(
  "waterDrainageProduction/remove",
  async ({ waterDrainageProduction }: { waterDrainageProduction: WaterDrainageProduction }, { rejectWithValue }) => {
    try {
      const waterDrainageProductionApi = new WaterDrainageProductionsApi();
      const response = await waterDrainageProductionApi.waterDrainageProductionsControllerRemove(waterDrainageProduction.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterDrainageProductionByOrganizationIdAsync = createAsyncThunk("waterDrainageProduction/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const waterDrainageProductionApi = new WaterDrainageProductionsApi();
    const response = await waterDrainageProductionApi.waterDrainageProductionsControllerFindByOrganizationid(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterDrainageProductionByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWaterDrainageProductionByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: WaterDrainageProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageProductionState, action: PayloadAction<WaterDrainageProduction[]>) => {
        state.waterDrainageProduction = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterDrainageProduction = () => {
    const { pending, fulfilled, rejected } = createWaterDrainageProductionAsync;
    return {
      [`${pending}`]: (state: WaterDrainageProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageProductionState, action: PayloadAction<WaterDrainageProduction>) => {
        state.waterDrainageProduction.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterDrainageProduction = () => {
    const { pending, fulfilled, rejected } = updateWaterDrainageProductionAsync;

    return {
      [`${pending}`]: (state: WaterDrainageProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageProductionState, action: PayloadAction<WaterDrainageProduction>) => {
        state.waterDrainageProduction = state.waterDrainageProduction.map((waterDrainageProduction) => (waterDrainageProduction.id === action.payload.id ? action.payload : waterDrainageProduction));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterDrainageProduction = () => {
    const { pending, fulfilled, rejected } = deleteWaterDrainageProductionAsync;
    return {
      [`${pending}`]: (state: WaterDrainageProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageProductionState, action: PayloadAction<WaterDrainageProduction>) => {
        state.waterDrainageProduction.splice(state.waterDrainageProduction.indexOf(
          state.waterDrainageProduction.find((ws: WaterDrainageProduction) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterDrainageProductionByOrganization(), ...createWaterDrainageProduction(), ...updateWaterDrainageProduction(), ...removeWaterDrainageProduction() };
};

export const waterDrainageProductionSlice = createSlice({
  name: "waterDrainageProduction",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterDrainageProduction = (state: RootState) => state.waterDrainageProduction.waterDrainageProduction;

export default waterDrainageProductionSlice.reducer;
