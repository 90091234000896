import { Input, InputNumber } from "antd";
import { EntityColumns } from "../../../app/types";
import { RopDocument } from "../../../api/models/rop-document";

export const RopDocumentsColumns = (): EntityColumns<RopDocument>[] => {
    
    return [
        {
            title: `№`,
            dataIndex: "no",
            key: "no",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Название файла`,
            dataIndex: "fileName",
            key: "fileName",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: false,
            copyable: true,
        }
    ];
}