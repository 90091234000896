import { Input, InputNumber, Select } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { selectRopGoodQualifiers } from "../RopGoodClassifierSlice";
import { selectRopPackagingQualifiers } from "../RopPackagingClassifierSlice";
import { RopCalculationColumns } from "./rop-calculation-columns";
import { DefaultOptionType } from "antd/es/select";

export const RopCalculationsColumns = (): EntityColumns<RopCalculationColumns>[] => {
    const period = useAppSelector(selectOrganizationModulePeriod);
    const ropGoodsClassifiers = useAppSelector(selectRopGoodQualifiers);
    const ropPackagingClassifiers = useAppSelector(selectRopPackagingQualifiers);

    let ropGoodsClassifiersLabels: DefaultOptionType[] = [];
    for (const classifier of ropGoodsClassifiers) {
        ropGoodsClassifiersLabels = [...ropGoodsClassifiersLabels, {
            value: classifier.id,
            label: `${classifier?.name}  - ${classifier.coefficient}`
        }]
    }

    let ropPackagingClassifiersLabels: DefaultOptionType[] = [];
    for (const classifier of ropPackagingClassifiers) {
        ropPackagingClassifiersLabels = [...ropPackagingClassifiersLabels, {
            value: classifier.id,
            label: `${classifier?.name}  - ${classifier.coefficient}`
        }]
    }

    return [
        {
            title: `№`,
            dataIndex: "no",
            key: "no",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Номер декларации`,
            dataIndex: "declarationNumber",
            key: "declarationNumber",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `БИН/ИИН`,
            dataIndex: "bin",
            key: "bin",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Наименование компании`,
            dataIndex: "organizationName",
            key: "organizationName",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Код ТН ВЭД ЕАЭС`,
            dataIndex: "codeTNVEDEAEU",
            key: "codeTNVEDEAEU",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: "Наименование импортированного товара",
            dataIndex: "goodsClassifierId",
            key: "poigoodsClassifierId",
            inputNode:
                <Select
                    options={ropGoodsClassifiersLabels}
                    showSearch
                    filterOption={(input, option) => {
                        if (option) {
                            const str = option.label + '';
                            return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        } else {
                            return true
                        }
                    }}
                />,
            render: (value: any, record: RopCalculationColumns, index: any) => (
                record.goodsClassifierName
            ),
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: "Материал упаковки импортированного товара",
            dataIndex: "packageClassifierId",
            key: "packageClassifierId",
            inputNode:
                <Select
                    options={ropPackagingClassifiersLabels}
                    showSearch
                    filterOption={(input, option) => {
                        if (option) {
                            const str = option.label + '';
                            return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        } else {
                            return true
                        }
                    }}
                />,
            render: (value: any, record: RopCalculationColumns, index: any) => (
                record.packageClassifierName
            ),
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Масса нетто в тоннах импортированного товара`,
            dataIndex: "goodsNetWeight",
            key: "goodsNetWeight",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Масса нетто в тоннах упаковки импортированного товара`,
            dataIndex: "packageNetWeight",
            key: "packageNetWeight",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Ставка МРП на отчетный период'`,
            dataIndex: "mciRate",
            key: "mciRate",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Сумма платежа'`,
            dataIndex: "amount",
            key: "amount",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: "Год",
            dataIndex: "year",
            key: "year",
            inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: false, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: "Квартал",
            dataIndex: "quarter",
            key: "quarter",
            inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: false, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
    ];
}
