import { Input, InputNumber, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { InfluenceObject } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const InfluenceObjectColumns = (): EntityColumns<InfluenceObject>[] => {
  return [
    {
      title: "Наименование объекта воздействия",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Широта",
      dataIndex: "latitude",
      key: "latitude",
      inputNode: <Input placeholder="48.0854" />,
      rules: [
        { required: true, message: "Широта неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },
      ],
      editable: true,
      copyable: true,
    },
    {
      title: "Долгота",
      dataIndex: "longitude",
      key: "longitude",
      inputNode: <Input placeholder="48.0854" />,
      rules: [
        { required: true, message: "Долгота неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },
      ],
      editable: true,
      copyable: true,
    },
    {
      title: "Место сброса сточных вод, широта",
      dataIndex: "latitudeWater",
      key: "latitudeWater",
      inputNode: <Input placeholder="48.0854" />,
      rules: [
        { required: true, message: "Широта неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },
      ],
      editable: true,
      copyable: true,
    },
    {
      title: "Место сброса сточных вод, долгота",
      dataIndex: "longitudeWater",
      key: "longitudeWater",
      inputNode: <Input placeholder="48.0854" />,
      rules: [
        { required: true, message: "Долгота неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },
      ],
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
