import { Input, InputNumber, Select } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { DefaultOptionType } from "antd/es/select";
import { useState } from "react";
import { selectRopTransportClassifiers } from "../RopTransportClassifiersSlice";
import { RopCalculationTransport } from "../../../api/models/rop-calculation-transport";
import { Organization } from "../../../api";

export const RopCalculationsTransportColumns = (organization?: Organization): EntityColumns<RopCalculationTransport>[] => {
    const period = useAppSelector(selectOrganizationModulePeriod);
    const transportClassifiers = useAppSelector(selectRopTransportClassifiers);

    let [selectedTransport, setTransport] = useState<string>("");
    let transportClassifierLabels: DefaultOptionType[] = [];
    for (const classifier of transportClassifiers) {
        transportClassifierLabels = [...transportClassifierLabels, {
            value: classifier.id,
            label: classifier?.name || ""
        }]
    }

    let transportSubcategoryClassifiersLabels: DefaultOptionType[] = []
    for (const classifier of transportClassifiers) {
        if (classifier.id === selectedTransport) {
            for (const subcat of classifier.ropTransportSubcategoryClassifier) {
                transportSubcategoryClassifiersLabels = [...transportSubcategoryClassifiersLabels, {
                    value: subcat.id,
                    label: subcat?.name || "",
                    coefficient: subcat.coefficient
                }]
            }
        }
    }


    return [
        {
            title: `№`,
            dataIndex: "no",
            key: "no",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Номер декларации`,
            dataIndex: "declarationNumber",
            key: "declarationNumber",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `БИН/ИИН`,
            dataIndex: "bin" as keyof RopCalculationTransport,
            key: "bin",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            render: () => organization?.bin,
            editable: false,
            copyable: true,
        },
        {
            title: `Наименование компании`,
            dataIndex: "organizationName" as keyof RopCalculationTransport,
            key: "organizationName",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            render: () => organization?.name,
            editable: false,
            copyable: true,
        },
        {
            title: `Код ТН ВЭД ЕАЭС`,
            dataIndex: "codeTNVEDEAEU",
            key: "codeTNVEDEAEU",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Название товара`,
            dataIndex: "name",
            key: "name",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: "Вид транспортных средств и самоходной сельскохозяйственной техники",
            dataIndex: "transportClassifierId" as keyof RopCalculationTransport,
            key: "transportClassifierId",
            inputNode:
                <Select
                    options={transportClassifierLabels}
                    showSearch
                    filterOption={(input, option) => {
                        if (option) {
                            const str = option.label + '';
                            return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        } else {
                            return true
                        }
                    }}
                    onSelect={(selectedClassifier) => {
                        setTransport(selectedClassifier)
                    }}
                />,
            render: (value: any, record: RopCalculationTransport, index: any) => (
                record.transportClassifier?.name || ""
            ),
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: "Категория транспортных средств и самоходной сельскохозяйственной техники",
            dataIndex: "transportSubcategoryClassifierId" as keyof RopCalculationTransport,
            key: "transportSubcategoryClassifierId",
            inputNode:
                <Select
                    options={transportSubcategoryClassifiersLabels}
                    showSearch
                    filterOption={(input, option) => {
                        if (option) {
                            const str = option.label + '';
                            return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        } else {
                            return true
                        }
                    }}
                />,
            rules: [{ required: true, message: "Заполните поле" }],
            render: (value: any, record: RopCalculationTransport, index: any) => (
                record.transportSubcategoryClassifier?.name || ""
            ),
            editable: true,
            copyable: true,
        },
        {
            title: `Базовая ставка'`,
            dataIndex: "baseRate",
            key: "baseRate",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Коэффициент'`,
            dataIndex: "coefficient",
            key: "coefficient",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Ставка МРП на отчетный период'`,
            dataIndex: "mciRate",
            key: "mciRate",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Сумма платежа'`,
            dataIndex: "amount",
            key: "amount",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: "Год",
            dataIndex: "year",
            key: "year",
            inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: false, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: "Квартал",
            dataIndex: "quarter",
            key: "quarter",
            inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: false, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
    ];
}
