import { Form, Modal } from "antd";
import React from "react";
import { CreatePollutionSourceDto } from "../../../api";
import { pollutionSourcesColumns } from "../form/columns";

interface PollutionSoucreFormProps {
  visible: boolean;
  setVisible: Function;
  onCreatePollutionSource: (values: CreatePollutionSourceDto) => void;
}

export const PollutionSourceForm: React.FC<PollutionSoucreFormProps> = ({ visible, setVisible, onCreatePollutionSource }) => {
  const [form] = Form.useForm<CreatePollutionSourceDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreatePollutionSource}>
        {pollutionSourcesColumns().map(({ title, key, rules, inputNode }) => (
          <Form.Item label={title} name={key} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
