import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateWorkshopDto, UpdateWorkshopDto, Workshop, WorkshopsApi } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface WorkshopsState {
  workshops: Workshop[];
  workshopProfile?: Workshop;
  statusProfile: "idle" | "loading" | "failed";
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WorkshopsState = {
  workshops: [],
  status: "idle",
  statusProfile: "idle",
};

export const fetchWorkshopsAsync = createAsyncThunk("workshops/fetch", async (organizationId: string, { rejectWithValue }) => {
  try {
    const workshopsApi = new WorkshopsApi();
    const response = await workshopsApi.workshopsControllerFindByOrganizationId(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchWorkshopProfileAsync = createAsyncThunk("workshops/fetchProfile", async (workshopId: string, { rejectWithValue }) => {
  try {
    const workshopsApi = new WorkshopsApi();
    const response = await workshopsApi.workshopsControllerFindOne(workshopId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createWorkshopAsync = createAsyncThunk("workshops/create", async (workshop: CreateWorkshopDto, { rejectWithValue }) => {
  try {
    const workshopsApi = new WorkshopsApi();
    const response = await workshopsApi.workshopsControllerCreate(workshop);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateWorkshopAsync = createAsyncThunk(
  "workshops/update",
  async ({ workshop, id }: { workshop: UpdateWorkshopDto; id: string }, { rejectWithValue }) => {
    try {
      const workshopsApi = new WorkshopsApi();
      const response = await workshopsApi.workshopsControllerUpdate(workshop, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWorkshopAsync = createAsyncThunk("workshops/delete", async (id: string, { rejectWithValue }) => {
  try {
    const workshopsApi = new WorkshopsApi();
    const response = await workshopsApi.workshopsControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWorkshops = () => {
    const { pending, fulfilled, rejected } = fetchWorkshopsAsync;
    return {
      [`${pending}`]: (state: WorkshopsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WorkshopsState, action: PayloadAction<Workshop[]>) => {
        state.workshops = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WorkshopsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchWorkshopProfile = () => {
    const { pending, fulfilled, rejected } = fetchWorkshopProfileAsync;

    return {
      [`${pending}`]: (state: WorkshopsState) => {
        state.statusProfile = "loading";
      },
      [`${fulfilled}`]: (state: WorkshopsState, action: PayloadAction<Workshop>) => {
        state.workshopProfile = action.payload;
        state.statusProfile = "idle";
      },
      [`${rejected}`]: (state: WorkshopsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.statusProfile = "failed";
        state.error = error;
      },
    };
  };

  const createWorkshop = () => {
    const { pending, fulfilled, rejected } = createWorkshopAsync;
    return {
      [`${pending}`]: (state: WorkshopsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WorkshopsState, action: PayloadAction<Workshop>) => {
        state.workshops.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WorkshopsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWorkshop = () => {
    const { pending, fulfilled, rejected } = updateWorkshopAsync;

    return {
      [`${pending}`]: (state: WorkshopsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WorkshopsState, action: PayloadAction<Workshop>) => {
        state.workshops = state.workshops.map((workshop) => (workshop.id === action.payload.id ? action.payload : workshop));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WorkshopsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const deleteWorkshop = () => {
    const { pending, fulfilled, rejected } = deleteWorkshopAsync;

    return {
      [`${pending}`]: (state: WorkshopsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WorkshopsState, action: PayloadAction<Workshop>) => {
        state.workshops = state.workshops.filter((workshop) => workshop.id !== action.payload.id);

        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WorkshopsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWorkshops(), ...createWorkshop(), ...updateWorkshop(), ...fetchWorkshopProfile(), ...deleteWorkshop() };
};

export const workshopsSlice = createSlice({
  name: "workshops",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectWorkshops = (state: RootState) => state.workshops.workshops;
export const selectWorkshopProfile = (state: RootState) => state.workshops.workshopProfile;

export default workshopsSlice.reducer;
