import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterConsumptionIntakeDto, WaterConsumptionIntake, Organization, UpdateWaterConsumptionIntakeDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { WaterConsumptionIntakeForm } from "./components/WaterConsumptionIntakeForm";
import { WaterConsumptionIntakeColumns } from "./form/columns";
import { createWaterConsumptionIntakeAsync, updateWaterConsumptionIntakeAsync, selectWaterConsumptionIntake, deleteWaterConsumptionIntakeAsync, fetchWaterConsumptionIntakeByOrganizationIdAsync } from "./WaterConsumptionIntakesSlice";
import { fetchWaterConsumptionObjectsAsync } from "../../waterConsumptionObject/WaterConsumptionObjectsSlice";

interface WaterConsumptionIntakeComponentProps {
  organization?: Organization;
  year?: number;
}

export const WaterConsumptionIntakeComponent: React.FC<WaterConsumptionIntakeComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const waterConsumptionIntake = useAppSelector(selectWaterConsumptionIntake);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWaterConsumptionIntakeByOrganizationIdAsync({ organizationId: organization.id, year }));
      dispatch(fetchWaterConsumptionObjectsAsync());
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (waterConsumptionIntake: UpdateWaterConsumptionIntakeDto, id: string) => {
    return dispatch(updateWaterConsumptionIntakeAsync({ waterConsumptionIntake, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterConsumptionIntake = (waterConsumptionIntake: CreateWaterConsumptionIntakeDto) => {
    if (!organization || !year) return;

    dispatch(createWaterConsumptionIntakeAsync({ ...waterConsumptionIntake, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterConsumptionIntake = (waterConsumptionIntake: WaterConsumptionIntake) => {
    return dispatch(deleteWaterConsumptionIntakeAsync({ waterConsumptionIntake })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterConsumptionIntakeForm visible={visible} setVisible={setVisible} onCreateWaterConsumptionIntake={onCreateWaterConsumptionIntake} />}
      <EditableTable<WaterConsumptionIntake, UpdateWaterConsumptionIntakeDto>
        onSave={onUpdate}
        onDelete={deleteWaterConsumptionIntake}
        entityColumns={WaterConsumptionIntakeColumns()}
        dataSource={structuredClone(waterConsumptionIntake).map((data: any) => {
          const waterConsumptionObject = data?.waterConsumptionObject?.id;
          delete data?.waterConsumptionObject;
          data.waterConsumptionObject = waterConsumptionObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
