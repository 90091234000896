import { Form, Modal } from "antd";
import React from "react";
import { CreateAnalyticsProductionForKpiDto } from "../../../../api";
import { AnalyticsProductionForKpisColumns } from "../form/columns";

interface AnalyticsProductionForKpisFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateAnalyticsProductionForKpi: (value: CreateAnalyticsProductionForKpiDto) => void;
}

export const AnalyticsProductionForKpisForm: React.FC<AnalyticsProductionForKpisFormProps> = ({ visible, setVisible, onCreateAnalyticsProductionForKpi }) => {
  const [form] = Form.useForm<CreateAnalyticsProductionForKpiDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateAnalyticsProductionForKpi}>
        {AnalyticsProductionForKpisColumns().map(({ title, key, rules, inputNode, hideOnForm }, index) => (
          <>
            {!hideOnForm && <Form.Item label={title} name={key} rules={rules} key={index}>
              {inputNode}
            </Form.Item>}
          </>
        ))}
      </Form>
    </Modal>
  );
};
