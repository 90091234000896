import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateAirSzzPointPollutantDto, AirSzzPointPollutant, UpdateAirSzzPointPollutantDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { AirSzzPointPollutantForm } from "./components/AirSzzPointPollutantForm";
import { AirSzzPointPollutantsColumns } from "./form/columns";
import { createAirSzzPointPollutantAsync, updateAirSzzPointPollutantAsync, selectAirSzzPointPollutants, deleteAirSzzPointPollutantAsync, fetchAirSzzPointPollutantsByPointAsync } from "./AirSzzPointPollutantsSlice";
import { fetchPollutantCodesAsync } from "../pollutantCodes/PollutantCodesSlice";

interface AirSzzPointPollutantsComponentProps {
  pointId?: string;
}

export const AirSzzPointPollutantsComponent: React.FC<AirSzzPointPollutantsComponentProps> = ({ pointId }) => {
  const dispatch = useAppDispatch();
  const airSzzPointPollutants = useAppSelector(selectAirSzzPointPollutants);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (pointId) {
      dispatch(fetchAirSzzPointPollutantsByPointAsync(pointId));
      dispatch(fetchPollutantCodesAsync())
    }
  }, [pointId, dispatch]);

  const onUpdate = async (airSzzPointPollutant: UpdateAirSzzPointPollutantDto, id: string) => {
    return dispatch(updateAirSzzPointPollutantAsync({ airSzzPointPollutant, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateAirSzzPointPollutant = (airSzzPointPollutant: CreateAirSzzPointPollutantDto) => {
    if (!pointId) return;

    dispatch(createAirSzzPointPollutantAsync({ ...airSzzPointPollutant, pointId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteAirSzzPointPollutants = (airSzzPointPollutant: AirSzzPointPollutant) => {
    return dispatch(deleteAirSzzPointPollutantAsync({ airSzzPointPollutant })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить загрязнение
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <AirSzzPointPollutantForm visible={visible} setVisible={setVisible} onCreateAirSzzPointPollutant={onCreateAirSzzPointPollutant} />}
      <EditableTable<AirSzzPointPollutant, UpdateAirSzzPointPollutantDto>
        onSave={onUpdate}
        onDelete={deleteAirSzzPointPollutants}
        entityColumns={AirSzzPointPollutantsColumns()}
        dataSource={airSzzPointPollutants}
        footer={() => addButton}
      />
    </Layout>
  );
};
