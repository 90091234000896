/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateWasteEquipmentSortDto } from '../models';
import { UpdateWasteEquipmentSortDto } from '../models';
import { WasteEquipmentSort } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * WasteEquipmentSortsApi - axios parameter creator
 * @export
 */
export const WasteEquipmentSortsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteEquipmentSortDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteEquipmentSortsControllerCreate: async (body: CreateWasteEquipmentSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteEquipmentSortsControllerCreate.');
            }
            const localVarPath = `/waste-equipment-sorts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteEquipmentSortsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/waste-equipment-sorts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteEquipmentSortsControllerFindByOrganizationid: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling wasteEquipmentSortsControllerFindByOrganizationid.');
            }
            const localVarPath = `/waste-equipment-sorts/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteEquipmentSortsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteEquipmentSortsControllerFindOne.');
            }
            const localVarPath = `/waste-equipment-sorts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteEquipmentSortsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteEquipmentSortsControllerRemove.');
            }
            const localVarPath = `/waste-equipment-sorts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWasteEquipmentSortDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteEquipmentSortsControllerUpdate: async (body: UpdateWasteEquipmentSortDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteEquipmentSortsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteEquipmentSortsControllerUpdate.');
            }
            const localVarPath = `/waste-equipment-sorts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WasteEquipmentSortsApi - functional programming interface
 * @export
 */
export const WasteEquipmentSortsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteEquipmentSortDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerCreate(body: CreateWasteEquipmentSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteEquipmentSort>>> {
            const localVarAxiosArgs = await WasteEquipmentSortsApiAxiosParamCreator(configuration).wasteEquipmentSortsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteEquipmentSort>>>> {
            const localVarAxiosArgs = await WasteEquipmentSortsApiAxiosParamCreator(configuration).wasteEquipmentSortsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerFindByOrganizationid(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteEquipmentSort>>>> {
            const localVarAxiosArgs = await WasteEquipmentSortsApiAxiosParamCreator(configuration).wasteEquipmentSortsControllerFindByOrganizationid(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteEquipmentSort>>> {
            const localVarAxiosArgs = await WasteEquipmentSortsApiAxiosParamCreator(configuration).wasteEquipmentSortsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteEquipmentSort>>> {
            const localVarAxiosArgs = await WasteEquipmentSortsApiAxiosParamCreator(configuration).wasteEquipmentSortsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateWasteEquipmentSortDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerUpdate(body: UpdateWasteEquipmentSortDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteEquipmentSort>>> {
            const localVarAxiosArgs = await WasteEquipmentSortsApiAxiosParamCreator(configuration).wasteEquipmentSortsControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WasteEquipmentSortsApi - factory interface
 * @export
 */
export const WasteEquipmentSortsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateWasteEquipmentSortDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerCreate(body: CreateWasteEquipmentSortDto, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteEquipmentSort>> {
            return WasteEquipmentSortsApiFp(configuration).wasteEquipmentSortsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteEquipmentSort>>> {
            return WasteEquipmentSortsApiFp(configuration).wasteEquipmentSortsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerFindByOrganizationid(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteEquipmentSort>>> {
            return WasteEquipmentSortsApiFp(configuration).wasteEquipmentSortsControllerFindByOrganizationid(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteEquipmentSort>> {
            return WasteEquipmentSortsApiFp(configuration).wasteEquipmentSortsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteEquipmentSort>> {
            return WasteEquipmentSortsApiFp(configuration).wasteEquipmentSortsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWasteEquipmentSortDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteEquipmentSortsControllerUpdate(body: UpdateWasteEquipmentSortDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteEquipmentSort>> {
            return WasteEquipmentSortsApiFp(configuration).wasteEquipmentSortsControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WasteEquipmentSortsApi - object-oriented interface
 * @export
 * @class WasteEquipmentSortsApi
 * @extends {BaseAPI}
 */
export class WasteEquipmentSortsApi extends BaseAPI {
    /**
     * 
     * @param {CreateWasteEquipmentSortDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteEquipmentSortsApi
     */
    public async wasteEquipmentSortsControllerCreate(body: CreateWasteEquipmentSortDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteEquipmentSort>> {
        return WasteEquipmentSortsApiFp(this.configuration).wasteEquipmentSortsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteEquipmentSortsApi
     */
    public async wasteEquipmentSortsControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteEquipmentSort>>> {
        return WasteEquipmentSortsApiFp(this.configuration).wasteEquipmentSortsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteEquipmentSortsApi
     */
    public async wasteEquipmentSortsControllerFindByOrganizationid(organizationId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteEquipmentSort>>> {
        return WasteEquipmentSortsApiFp(this.configuration).wasteEquipmentSortsControllerFindByOrganizationid(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteEquipmentSortsApi
     */
    public async wasteEquipmentSortsControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteEquipmentSort>> {
        return WasteEquipmentSortsApiFp(this.configuration).wasteEquipmentSortsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteEquipmentSortsApi
     */
    public async wasteEquipmentSortsControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteEquipmentSort>> {
        return WasteEquipmentSortsApiFp(this.configuration).wasteEquipmentSortsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateWasteEquipmentSortDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteEquipmentSortsApi
     */
    public async wasteEquipmentSortsControllerUpdate(body: UpdateWasteEquipmentSortDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteEquipmentSort>> {
        return WasteEquipmentSortsApiFp(this.configuration).wasteEquipmentSortsControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
