import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteAccumulationLimitDto } from "../../../api";
import { WasteAccumulationLimitsColumns } from "../form/columns";

interface WasteAccumulationLimitFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteAccumulationLimit: (values: CreateWasteAccumulationLimitDto) => void;
}

export const WasteAccumulationLimitForm: React.FC<WasteAccumulationLimitFormProps> = ({ visible, setVisible, onCreateWasteAccumulationLimit }) => {
  const [form] = Form.useForm<CreateWasteAccumulationLimitDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteAccumulationLimit}>
        {WasteAccumulationLimitsColumns().map(({ title, key, rules, inputNode }, index) => (
          <Form.Item label={title} name={key} rules={rules} key={index}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
