import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateInfluenceObjectDto, InfluenceObjectApi, UpdateInfluenceObjectDto, InfluenceObject } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface InfluenceObjectState {
  influenceObject: InfluenceObject[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: InfluenceObjectState = {
  influenceObject: [],
  status: "idle",
};

export const createInfluenceObjectAsync = createAsyncThunk(
  "influenceObject/create",
  async (influenceObject: CreateInfluenceObjectDto, { rejectWithValue }) => {
    try {
      const influenceObjectApi = new InfluenceObjectApi();
      const response = await influenceObjectApi.influenceObjectControllerCreate(influenceObject);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInfluenceObjectAsync = createAsyncThunk(
  "influenceObject/update",
  async ({ influenceObject, id }: { influenceObject: UpdateInfluenceObjectDto; id: string }, { rejectWithValue }) => {
    try {
      const influenceObjectApi = new InfluenceObjectApi();
      const response = await influenceObjectApi.influenceObjectControllerUpdate(influenceObject, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInfluenceObjectAsync = createAsyncThunk(
  "influenceObject/remove",
  async ({ influenceObject }: { influenceObject: InfluenceObject }, { rejectWithValue }) => {
    try {
      const influenceObjectApi = new InfluenceObjectApi();
      const response = await influenceObjectApi.influenceObjectControllerRemove(influenceObject.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchInfluenceObjectByOrganizationIdAsync = createAsyncThunk("influenceObject/fetch", async (organizationId: string, { rejectWithValue }) => {
  try {
    const influenceObjectApi = new InfluenceObjectApi();
    const response = await influenceObjectApi.influenceObjectControllerFindByOrganizationid(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchInfluenceObjectByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchInfluenceObjectByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: InfluenceObjectState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: InfluenceObjectState, action: PayloadAction<InfluenceObject[]>) => {
        state.influenceObject = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: InfluenceObjectState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createInfluenceObject = () => {
    const { pending, fulfilled, rejected } = createInfluenceObjectAsync;
    return {
      [`${pending}`]: (state: InfluenceObjectState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: InfluenceObjectState, action: PayloadAction<InfluenceObject>) => {
        state.influenceObject.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: InfluenceObjectState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateInfluenceObject = () => {
    const { pending, fulfilled, rejected } = updateInfluenceObjectAsync;

    return {
      [`${pending}`]: (state: InfluenceObjectState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: InfluenceObjectState, action: PayloadAction<InfluenceObject>) => {
        state.influenceObject = state.influenceObject.map((influenceObject) => (influenceObject.id === action.payload.id ? action.payload : influenceObject));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: InfluenceObjectState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeInfluenceObject = () => {
    const { pending, fulfilled, rejected } = deleteInfluenceObjectAsync;
    return {
      [`${pending}`]: (state: InfluenceObjectState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: InfluenceObjectState, action: PayloadAction<InfluenceObject>) => {
        state.influenceObject.splice(state.influenceObject.indexOf(
          state.influenceObject.find((ws: InfluenceObject) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: InfluenceObjectState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchInfluenceObjectByOrganization(), ...createInfluenceObject(), ...updateInfluenceObject(), ...removeInfluenceObject() };
};

export const influenceObjectSlice = createSlice({
  name: "influenceObject",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectInfluenceObject = (state: RootState) => state.influenceObject.influenceObject;

export default influenceObjectSlice.reducer;
