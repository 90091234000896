import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { TaxWastesColumns } from "./form/columns";
import { CreateTaxWasteDto } from "../../api/models/create-tax-waste-dto";
import { TaxWaste } from "../../api/models/tax-waste";
import { UpdateTaxWasteDto } from "../../api/models/update-tax-waste-dto";
import { selectTaxWastes, fetchTaxWastesAsync, updateTaxWasteAsync, createTaxWasteAsync, deleteTaxWasteAsync } from "./TaxWastesSlice";
import { TaxWasteForm } from "./components/TaxDropForm";

export const TaxWastesComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const taxWastes = useAppSelector(selectTaxWastes);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchTaxWastesAsync());
  }, [dispatch]);

  const onUpdateTaxWaste = async (taxWaste: UpdateTaxWasteDto, id: string) => {
    return dispatch(updateTaxWasteAsync({ taxWaste, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateTaxWaste = (taxWaste: CreateTaxWasteDto) => {

    dispatch(createTaxWasteAsync({ ...taxWaste })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteTaxWaste = (taxWaste: TaxWaste) => {
    return dispatch(deleteTaxWasteAsync({ taxWaste })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <TaxWasteForm visible={visible} setVisible={setVisible} onCreateTaxWaste={onCreateTaxWaste} />}
      <EditableTable<TaxWaste, UpdateTaxWasteDto>
        onSave={onUpdateTaxWaste}
        onDelete={deleteTaxWaste}
        dataSource={taxWastes}
        entityColumns={TaxWastesColumns()}
        footer={() => addButton}
      />
    </Layout>
  );
};
