import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterConsumptionObjectDto, WaterConsumptionObject, UpdateWaterConsumptionObjectDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WaterConsumptionObjectForm } from "./components/WaterConsumptionObjectForm";
import { waterConsumptionObjectsColumns } from "./form/columns";
import { selectWaterConsumptionObjects, updateWaterConsumptionObjectAsync, createWaterConsumptionObjectAsync, deleteWaterConsumptionObjectAsync, fetchWaterConsumptionObjectsAsync } from "./WaterConsumptionObjectsSlice";

export const WaterConsumptionObjectsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const waterConsumptionObjects = useAppSelector(selectWaterConsumptionObjects);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchWaterConsumptionObjectsAsync());
  }, [dispatch]);

  const onUpdateWaterConsumptionObject = async (waterConsumptionObject: UpdateWaterConsumptionObjectDto, id: string) => {
    return dispatch(updateWaterConsumptionObjectAsync({ waterConsumptionObject, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterConsumptionObject = (waterConsumptionObject: CreateWaterConsumptionObjectDto) => {

    dispatch(createWaterConsumptionObjectAsync({ ...waterConsumptionObject })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterConsumptionObject = (waterConsumptionObject: WaterConsumptionObject) => {
    return dispatch(deleteWaterConsumptionObjectAsync({ waterConsumptionObject })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить объект
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterConsumptionObjectForm visible={visible} setVisible={setVisible} onCreateWaterConsumptionObject={onCreateWaterConsumptionObject} />}
      <EditableTable<WaterConsumptionObject, UpdateWaterConsumptionObjectDto>
        onSave={onUpdateWaterConsumptionObject}
        onDelete={deleteWaterConsumptionObject}
        entityColumns={waterConsumptionObjectsColumns}
        dataSource={waterConsumptionObjects}
        footer={() => addButton}
      />
    </Layout>
  );
};
