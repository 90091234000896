import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterDrainageProductionDto, WaterDrainageProduction, Organization, UpdateWaterDrainageProductionDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { WaterDrainageProductionForm } from "./components/WaterDrainageProductionForm";
import { WaterDrainageProductionColumns } from "./form/columns";
import { createWaterDrainageProductionAsync, updateWaterDrainageProductionAsync, selectWaterDrainageProduction, deleteWaterDrainageProductionAsync, fetchWaterDrainageProductionByOrganizationIdAsync } from "./WaterDrainageProductionsSlice";
import { fetchWaterDrainageObjectsAsync } from "../../waterDrainageObjects/WaterDrainageObjectsSlice";

interface WaterDrainageProductionComponentProps {
  organization?: Organization;
  year?: number;
}

export const WaterDrainageProductionComponent: React.FC<WaterDrainageProductionComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const waterDrainageProduction = useAppSelector(selectWaterDrainageProduction);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWaterDrainageProductionByOrganizationIdAsync({ organizationId: organization.id, year }));
      dispatch(fetchWaterDrainageObjectsAsync());
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (waterDrainageProduction: UpdateWaterDrainageProductionDto, id: string) => {
    return dispatch(updateWaterDrainageProductionAsync({ waterDrainageProduction, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterDrainageProduction = (waterDrainageProduction: CreateWaterDrainageProductionDto) => {
    if (!organization || !year) return;

    dispatch(createWaterDrainageProductionAsync({ ...waterDrainageProduction, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterDrainageProduction = (waterDrainageProduction: WaterDrainageProduction) => {
    return dispatch(deleteWaterDrainageProductionAsync({ waterDrainageProduction })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterDrainageProductionForm visible={visible} setVisible={setVisible} onCreateWaterDrainageProduction={onCreateWaterDrainageProduction} />}
      <EditableTable<WaterDrainageProduction, UpdateWaterDrainageProductionDto>
        onSave={onUpdate}
        onDelete={deleteWaterDrainageProduction}
        entityColumns={WaterDrainageProductionColumns()}
        dataSource={structuredClone(waterDrainageProduction).map((data: any) => {
          const waterDrainageObject = data?.waterDrainageObject?.id;
          delete data?.waterDrainageObject;
          data.waterDrainageObject = waterDrainageObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
