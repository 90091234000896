import { Checkbox, Form, Input } from "antd";
import { WaterConsumptionObject } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const waterConsumptionObjectsColumns: EntityColumns<WaterConsumptionObject>[] =
  [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Код источника",
      dataIndex: "sourceCode",
      key: "sourceCode",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Код качества",
      dataIndex: "qualityCode",
      key: "qualityCode",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "От природных источников",
      dataIndex: "naturalSource",
      key: "naturalSource",
      inputNode:
        <Form.Item name="naturalSource" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
    {
      title: "От других организации",
      dataIndex: "otherOrganization",
      key: "otherOrganization",
      inputNode:
        <Form.Item name="otherOrganization" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
    {
      title: "Передано",
      dataIndex: "transferred",
      key: "transferred",
      inputNode:
        <Form.Item name="transferred" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
    {
      title: "Оборотное",
      dataIndex: "circulating",
      key: "circulating",
      inputNode:
        <Form.Item name="circulating" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
  ];