import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteContragentDto, UpdateWasteContragentDto, WasteContragent } from "../../api";
import { WasteContragentsApi } from "../../api/apis/waste-contragents-api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteContragentsState {
  wasteContragents: WasteContragent[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteContragentsState = {
  wasteContragents: [],
  status: "idle",
};

export const createWasteContragentsAsync = createAsyncThunk(
  "wasteContragents/create",
  async (wasteContragent: CreateWasteContragentDto, { rejectWithValue }) => {
    try {
      const wasteContragentsApi = new WasteContragentsApi();
      const response = await wasteContragentsApi.wasteContragentsControllerCreate(wasteContragent);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteContragentAsync = createAsyncThunk(
  "wasteContragents/update",
  async ({ wasteContragent, id }: { wasteContragent: UpdateWasteContragentDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteContragentsApi = new WasteContragentsApi();
      const response = await wasteContragentsApi.wasteContragentsControllerUpdate(wasteContragent, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteContragentAsync = createAsyncThunk(
  "wasteContragents/remove",
  async ({ wasteContragent }: { wasteContragent: WasteContragent }, { rejectWithValue }) => {
    try {
      const wasteContragentsApi = new WasteContragentsApi();
      const response = await wasteContragentsApi.wasteContragentsControllerRemove(wasteContragent.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteContragentsByOrganizationAsync = createAsyncThunk(
  "wasteContragents/fetch",
  async (id: string, { rejectWithValue }) => {
  try {
    const wasteContragentsApi = new WasteContragentsApi();
    const response = await wasteContragentsApi.wasteContragentsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteContragentsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWasteContragentsByOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteContragentsState, action: PayloadAction<WasteContragent[]>) => {
        state.wasteContragents = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteContragent = () => {
    const { pending, fulfilled, rejected } = createWasteContragentsAsync;
    return {
      [`${pending}`]: (state: WasteContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteContragentsState, action: PayloadAction<WasteContragent>) => {
        state.wasteContragents.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteContragent = () => {
    const { pending, fulfilled, rejected } = updateWasteContragentAsync;

    return {
      [`${pending}`]: (state: WasteContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteContragentsState, action: PayloadAction<WasteContragent>) => {
        state.wasteContragents = state.wasteContragents.map((wasteContragent) => (wasteContragent.id === action.payload.id ? action.payload : wasteContragent));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteContragent = () => {
    const { pending, fulfilled, rejected } = deleteWasteContragentAsync;
    return {
      [`${pending}`]: (state: WasteContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteContragentsState, action: PayloadAction<WasteContragent>) => {
        state.wasteContragents.splice(state.wasteContragents.indexOf(
          state.wasteContragents.find((ws: WasteContragent) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteContragentsByOrganization(), ...createWasteContragent(), ...updateWasteContragent(), ...removeWasteContragent() };
};

export const wasteContragentsSlice = createSlice({
  name: "wasteContragents",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteContragents = (state: RootState) => state.wasteContragents.wasteContragents;

export default wasteContragentsSlice.reducer;
