import { Col, Layout, Tabs } from "antd";
import { Organization } from "../../api";
import { history } from "../../app/helpers";
import { TotalEmission } from "./components/TotalEmissions";
import { WasteBurialLimitsInfoComponent } from "../wasteBurialLimitsInfo/WasteBurialLimitsInfoComponent";

interface TotalEmissionsComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const TotalEmissionsComponent: React.FC<TotalEmissionsComponentProps> = ({ organization, year, quarter }) => {
  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane={true}
          onChange={(path) => {
            let tabPath = history?.location?.pathname.split('/') || [];
            tabPath[6] = path;
            history.navigate && history.navigate(`${tabPath.join('/')}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[6]}
          items={[
            {
              label: 'Выбросы',
              key: 'air',
              children:
                <Col span={24} md={24} className="mb-24 overflow-scroll">
                  <TotalEmission organization={organization} year={year} quarter={quarter} type={'air'} />
                </Col>
              ,
            },
            {
              label: 'Сбросы',
              key: 'drop',
              children:
                <Col span={24} md={24} className="mb-24 overflow-scroll">
                  <TotalEmission organization={organization} year={year} quarter={quarter} type={'drop'} />
                </Col>
              ,
            },
            {
              label: 'Отходы',
              key: 'waste',
              children:
                <Col span={24} md={24} className="mb-24 overflow-scroll">
                  <WasteBurialLimitsInfoComponent organization={organization} year={year} quarter={quarter} />
                </Col>
              ,
            }
          ]}
        />
      </Layout>
    </>
  );
};
