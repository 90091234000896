import { Button, Divider, Layout } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import React, {useState} from "react";
import { User } from "../../../api";
import { ProfileUpdateForm } from "../../profile/components/ProfileUpdateForm";

interface PersonalInformationProps {
  authUser?: User;
}
export const PersonalInformation: React.FC<PersonalInformationProps> = ({ authUser }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Layout className="bg-white p-8">
      <Title level={3} className="!my-0 mt-4">
        Персональная информация
      </Title>

      <Divider />
      <div className="grid grid-cols-2 gap-3 w-fit text-base">
        <Text className="text-gray-500">Имя: </Text>
        <Text className="font-semibold">{authUser?.name}</Text>

        <Text className="text-gray-500">Email: </Text>
        <Text className="font-semibold">{authUser?.email}</Text>

        <Text className="text-gray-500">Телефон: </Text>
        <Text className="font-semibold">{authUser?.phone}</Text>

        <Text className="text-gray-500">Отдел: </Text>
        <Text className="font-semibold">{authUser?.roles}</Text>
      </div>
      <Divider className="w-full" />
      <Button className="w-40" size="large" onClick={() => setVisible(true)}>
        Изменить
      </Button>
      <ProfileUpdateForm user={authUser!} visible={visible} setVisible={setVisible}/>
    </Layout>
  );
};
