import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteAccumulationSiteDto, WasteAccumulationSite, UpdateWasteAccumulationSiteDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WasteAccumulationSiteForm } from "./components/WasteAccumulationSitesForm";
import { wasteAccumulationSitesColumns } from "./form/columns";
import { createWasteAccumulationSiteAsync, updateWasteAccumulationSiteAsync, fetchWasteAccumulationSitesByOrganizationAsync, selectWasteAccumulationSites, deleteWasteAccumulationSiteAsync } from "./WasteAccumulationSitesSlice";

interface WasteAccumulationSitesComponentProps {
  organizationId?: string;
}

export const WasteAccumulationSitesComponent: React.FC<WasteAccumulationSitesComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const wasteAccumulationSites = useAppSelector(selectWasteAccumulationSites);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWasteAccumulationSitesByOrganizationAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdate = async (wasteAccumulationSite: UpdateWasteAccumulationSiteDto, id: string) => {
    return dispatch(updateWasteAccumulationSiteAsync({ wasteAccumulationSite, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteAccumulationSite = (wasteAccumulationSite: CreateWasteAccumulationSiteDto) => {
    if (!organizationId) return;

    dispatch(createWasteAccumulationSiteAsync({ ...wasteAccumulationSite, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteAccumulationSites = (wasteAccumulationSite: WasteAccumulationSite) => {
    return dispatch(deleteWasteAccumulationSiteAsync({ wasteAccumulationSite })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить площадку накопления
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteAccumulationSiteForm visible={visible} setVisible={setVisible} onCreateWasteAccumulationSite={onCreateWasteAccumulationSite} />}
      <EditableTable<WasteAccumulationSite, UpdateWasteAccumulationSiteDto>
        onSave={onUpdate}
        onDelete={deleteWasteAccumulationSites}
        entityColumns={wasteAccumulationSitesColumns}
        dataSource={wasteAccumulationSites}
        footer={() => addButton}
      />
    </Layout>
  );
};
