import { Input, InputNumber, Select } from "antd";
import { TaxDrop } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import Text from "antd/es/typography/Text";
import { selectDropCodes } from "../../dropCodes/DropCodesSlice";

export const TaxDropsColumns = (): EntityColumns<TaxDrop>[] => {

  const dropCodes = useAppSelector(selectDropCodes);

  return [
    {
      title: "№п/п",
      dataIndex: "indexNumber",
      key: "indexNumber",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вид загрязняющего вещества",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вещества",
      dataIndex: "dropCodes",
      key: "dropCodes",
      inputNode:
        <Select
          mode="multiple"
          showSearch
          filterOption={(input, option) => {
            if (option) {
              return (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }
          }}
          options={dropCodes?.map(
            (dropCode) => {
              return {
                label: dropCode?.name,
                value: dropCode?.id,
              }
            }
          )}
        />,
      render: (dropCodeIds: string[]) => dropCodeIds?.map(dropCodeId => {
        const dropCode = dropCodes?.find(dropCode => dropCode?.id === dropCodeId);
        return (
          <div>
            <Text className="w-128 mb-2" ellipsis={{ tooltip: dropCode?.name }}>
              {dropCode?.name}
            </Text>
          </div>
        );
      }),
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП)",
      dataIndex: "mciRate",
      key: "mciRate",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];
}