import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateRadiologyPointDto, RadiologyPointsApi, UpdateRadiologyPointDto, RadiologyPoint } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface RadiologyPointsState {
  radiologyPoints: RadiologyPoint[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: RadiologyPointsState = {
  radiologyPoints: [],
  status: "idle",
};

export const createRadiologyPointAsync = createAsyncThunk(
  "radiologyPoints/create",
  async (radiologyPoint: CreateRadiologyPointDto, { rejectWithValue }) => {
    try {
      const radiologyPointsApi = new RadiologyPointsApi();
      const response = await radiologyPointsApi.radiologyPointsControllerCreate(radiologyPoint);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRadiologyPointAsync = createAsyncThunk(
  "radiologyPoints/update",
  async ({ radiologyPoint, id }: { radiologyPoint: UpdateRadiologyPointDto; id: string }, { rejectWithValue }) => {
    try {
      const radiologyPointsApi = new RadiologyPointsApi();
      const response = await radiologyPointsApi.radiologyPointsControllerUpdate(radiologyPoint, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRadiologyPointAsync = createAsyncThunk(
  "radiologyPoints/remove",
  async ({ radiologyPoint }: { radiologyPoint: RadiologyPoint }, { rejectWithValue }) => {
    try {
      const radiologyPointsApi = new RadiologyPointsApi();
      const response = await radiologyPointsApi.radiologyPointsControllerRemove(radiologyPoint.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const copyRadiologyPointAsync = createAsyncThunk(
  "radiologyPoints/copy",
  async ({id} : { id: string }, { rejectWithValue }) => {
    try {
      const radiologyPointsApi = new RadiologyPointsApi();
      const response = await radiologyPointsApi.radiologyPointsControllerCopy(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRadiologyPointsByOrganizationidAsync = createAsyncThunk("radiologyPoints/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const radiologyPointsApi = new RadiologyPointsApi();
    const response = await radiologyPointsApi.radiologyPointsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchRadiologyPointsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchRadiologyPointsByOrganizationidAsync;
    return {
      [`${pending}`]: (state: RadiologyPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointsState, action: PayloadAction<RadiologyPoint[]>) => {
        state.radiologyPoints = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createRadiologyPoint = () => {
    const { pending, fulfilled, rejected } = createRadiologyPointAsync;
    return {
      [`${pending}`]: (state: RadiologyPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointsState, action: PayloadAction<RadiologyPoint>) => {
        state.radiologyPoints.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateRadiologyPoint = () => {
    const { pending, fulfilled, rejected } = updateRadiologyPointAsync;

    return {
      [`${pending}`]: (state: RadiologyPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointsState, action: PayloadAction<RadiologyPoint>) => {
        state.radiologyPoints = state.radiologyPoints.map((RadiologyPoint) => (RadiologyPoint.id === action.payload.id ? action.payload : RadiologyPoint));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeRadiologyPoint = () => {
    const { pending, fulfilled, rejected } = deleteRadiologyPointAsync;
    return {
      [`${pending}`]: (state: RadiologyPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointsState, action: PayloadAction<RadiologyPoint>) => {
        state.radiologyPoints.splice(state.radiologyPoints.indexOf(
          state.radiologyPoints.find((ws: RadiologyPoint) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const copyRadiologyPoint = () => {
    const { pending, fulfilled, rejected } = copyRadiologyPointAsync;
    return {
      [`${pending}`]: (state: RadiologyPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointsState, action: PayloadAction<RadiologyPoint>) => {
        state.radiologyPoints.push(action.payload);
        message.success("Успешно скопирован");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchRadiologyPointsByOrganization(), ...createRadiologyPoint(), ...updateRadiologyPoint(), ...removeRadiologyPoint(), ...copyRadiologyPoint() };
};

export const radiologyPointsSlice = createSlice({
  name: "radiologyPoints",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectRadiologyPoints = (state: RootState) => state.radiologyPoints.radiologyPoints;

export default radiologyPointsSlice.reducer;
