import { Layout, Tabs } from "antd";
import { Organization } from "../../api/models/organization";
import { history } from "../../app/helpers";
import { WasteApplication7RecordsComponent } from "./wasteApplication7Records/WasteApplication7RecordsComponent";

interface WasteApplication7ByQuarterComponentProps {
  organization?: Organization;
  year: number;
  quarter: number;
}

export const WasteApplication7ByQuarterComponent: React.FC<WasteApplication7ByQuarterComponentProps> = ({ organization, year, quarter }) => {
  
  function getMonthName(month: number) {
    switch (month) {
      case 1: return "Январь";
      case 2: return "Февраль"; 
      case 3: return "Март";
      case 4: return "Апрель";
      case 5: return "Май";
      case 6: return "Июнь";
      case 7: return "Июль";
      case 8: return "Август";
      case 9: return "Сентябрь";
      case 10: return "Октябрь";
      case 11: return "Ноябрь";
      case 12: return "Декабрь";
      default: return "" + month;
    }
  }
  
  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane={true}
          onChange={(path) => {
            let tabPath = history?.location?.pathname.split('/') || [];
            tabPath[6] = path;
            history.navigate && history.navigate(`${tabPath.join('/')}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[6]}
          items={[
            {
              label: getMonthName((quarter*3)-3+1),
              key: 'one',
              children: <WasteApplication7RecordsComponent organization={organization} year={year} quarter={quarter} month={(quarter*3)-3+1} />,
            },
            {
              label: getMonthName((quarter*3)-3+2),
              key: 'two',
              children: <WasteApplication7RecordsComponent organization={organization} year={year} quarter={quarter} month={(quarter*3)-3+2} />,
            },
            {
              label: getMonthName((quarter*3)-3+3),
              key: 'three',
              children: <WasteApplication7RecordsComponent organization={organization} year={year} quarter={quarter} month={(quarter*3)-3+3} />,
            },
          ]}
        />
      </Layout>
    </>
  );
};
