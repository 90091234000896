import { Button, Form, Modal, Upload, UploadFile } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { UploadOutlined } from '@ant-design/icons';
import { BASE_PATH } from "../../../api/base";
import { getFromStorage } from "../../../app/helpers";
import { CreateRopCalculationsCableDto } from "../../../api/models/create-rop-calculations-cable-dto";
import { RopCalculationsCableColumns } from "../form/column";
import { deleteFileAsync } from "../../files/FilesEntitySlice";

interface RopCalculationsCableFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateRopCalculationsCable: (values: CreateRopCalculationsCableDto) => void;
  organizationId?: string;
}

export const RopCalculationsCableForm: React.FC<RopCalculationsCableFormProps> = ({ visible, setVisible, onCreateRopCalculationsCable, organizationId }) => {
  const [form] = Form.useForm<any>();

  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectOrganizationsProfile);
  const period = useAppSelector(selectOrganizationModulePeriod);
  const onChange = (value: any, additional?: any) => {

    (Object.keys(form.getFieldsValue()) as (keyof CreateRopCalculationsCableDto)[]).find((key) => {
      return form.getFieldsValue()[key] === value;
    });

  }

  const [ids, setIds] = useState<string[]>([]);
  const deleteFile = (id: string) => {
    return dispatch(deleteFileAsync({ id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }
  const handleRemove = (file: UploadFile) => {
    if (file.response) {
      setIds(ids.filter(id => id === file.response.id));
      deleteFile(file.response.id)
    }
  };

  const onFinish = (value: any) => {
    const dto: CreateRopCalculationsCableDto = {
      declarationNumber: value.declarationNumber,
      codeTNVEDEAEU: value.codeTNVEDEAEU,
      name: value.name,
      price: value.price,
      year: value.year,
      quarter: value.quarter,
      isActive: true,
      fileIds: ids,
      organizationId: organizationId,
    }
    onCreateRopCalculationsCable(dto);
}

  return (
    <Modal width="60%" open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form initialValues={{ year: period.year, quarter: period.quarter, bin: organization?.bin, organizationName: organization?.name }} form={form} className="p-8" layout="vertical" onFinish={onFinish}>
        {RopCalculationsCableColumns()
          .filter((column) =>
            column.key !== "amount" &&
            column.key !== "no" &&
            column.key !== "coefficient")
          .map(({ title, dataIndex, rules, inputNode }, index) => (
            <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
              {
                React.cloneElement(
                  inputNode,
                  {
                    onChange: onChange
                  }
                )
              }
            </Form.Item>
          ))}
        <Upload
          action={`${BASE_PATH}/files/`}
          listType="picture"
          maxCount={3}
          headers={{
            Authorization: 'Bearer ' + getFromStorage("token"),
          }}
          onChange={(info) => {
            if (info.file.status === "done") {
              console.log(info.file.response.id)
              let temp = [...ids]
              temp.push(info.file.response?.id);
              setIds(temp);
            }
          }}
          onRemove={handleRemove}
        >
          <Button icon={<UploadOutlined />}>Upload (Max: 3)</Button>
        </Upload>
      </Form>
    </Modal>
  );
};
