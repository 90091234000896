/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreatePollutionSourceDto } from '../models';
import { InlineResponse200 } from '../models';
import { PollutionSource } from '../models';
import { UpdatePollutionSourceDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * PollutionSourcesApi - axios parameter creator
 * @export
 */
export const PollutionSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePollutionSourceDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerCreate: async (body: CreatePollutionSourceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling pollutionSourcesControllerCreate.');
            }
            const localVarPath = `/pollution-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerFindAll: async (order?: string, page?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pollution-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerFindAllByOrganizationId: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling pollutionSourcesControllerFindAllByOrganizationId.');
            }
            const localVarPath = `/pollution-sources/organization/{organizationId}/all`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerFindByOrganizationId: async (organizationId: string, order?: string, page?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling pollutionSourcesControllerFindByOrganizationId.');
            }
            const localVarPath = `/pollution-sources/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workshopId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerFindByWorkshopId: async (workshopId: string, order?: string, page?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopId' is not null or undefined
            if (workshopId === null || workshopId === undefined) {
                throw new RequiredError('workshopId', 'Required parameter workshopId was null or undefined when calling pollutionSourcesControllerFindByWorkshopId.');
            }
            const localVarPath = `/pollution-sources/workshop/{workshopId}`
                .replace(`{${"workshopId"}}`, encodeURIComponent(String(workshopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerFindForMethodicByOrganizationId: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling pollutionSourcesControllerFindForMethodicByOrganizationId.');
            }
            const localVarPath = `/pollution-sources/methodic/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling pollutionSourcesControllerFindOne.');
            }
            const localVarPath = `/pollution-sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling pollutionSourcesControllerRemove.');
            }
            const localVarPath = `/pollution-sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePollutionSourceDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollutionSourcesControllerUpdate: async (body: UpdatePollutionSourceDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling pollutionSourcesControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling pollutionSourcesControllerUpdate.');
            }
            const localVarPath = `/pollution-sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PollutionSourcesApi - functional programming interface
 * @export
 */
export const PollutionSourcesApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePollutionSourceDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerCreate(body: CreatePollutionSourceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSource>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindAll(order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse200>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerFindAll(order, page, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindAllByOrganizationId(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<PollutionSource>>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerFindAllByOrganizationId(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindByOrganizationId(organizationId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse200>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerFindByOrganizationId(organizationId, order, page, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} workshopId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindByWorkshopId(workshopId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse200>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerFindByWorkshopId(workshopId, order, page, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<PollutionSource>>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSource>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSource>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdatePollutionSourceDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerUpdate(body: UpdatePollutionSourceDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSource>>> {
            const localVarAxiosArgs = await PollutionSourcesApiAxiosParamCreator(configuration).pollutionSourcesControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PollutionSourcesApi - factory interface
 * @export
 */
export const PollutionSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreatePollutionSourceDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerCreate(body: CreatePollutionSourceDto, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindAll(order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerFindAll(order, page, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindAllByOrganizationId(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PollutionSource>>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerFindAllByOrganizationId(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindByOrganizationId(organizationId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerFindByOrganizationId(organizationId, order, page, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workshopId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindByWorkshopId(workshopId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerFindByWorkshopId(workshopId, order, page, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PollutionSource>>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePollutionSourceDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollutionSourcesControllerUpdate(body: UpdatePollutionSourceDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
            return PollutionSourcesApiFp(configuration).pollutionSourcesControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PollutionSourcesApi - object-oriented interface
 * @export
 * @class PollutionSourcesApi
 * @extends {BaseAPI}
 */
export class PollutionSourcesApi extends BaseAPI {
    /**
     * 
     * @param {CreatePollutionSourceDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerCreate(body: CreatePollutionSourceDto, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [order] 
     * @param {number} [page] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerFindAll(order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerFindAll(order, page, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerFindAllByOrganizationId(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PollutionSource>>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerFindAllByOrganizationId(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {string} [order] 
     * @param {number} [page] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerFindByOrganizationId(organizationId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerFindByOrganizationId(organizationId, order, page, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} workshopId 
     * @param {string} [order] 
     * @param {number} [page] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerFindByWorkshopId(workshopId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerFindByWorkshopId(workshopId, order, page, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PollutionSource>>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdatePollutionSourceDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollutionSourcesApi
     */
    public async pollutionSourcesControllerUpdate(body: UpdatePollutionSourceDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSource>> {
        return PollutionSourcesApiFp(this.configuration).pollutionSourcesControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
