import { InputNumber, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { AirSzzPointPollutant } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import Text from "antd/es/typography/Text";
import { selectPollutionSubstances } from "../../pollutionSubstances/pollutionSubstancesSlice";



export const AirSzzPointPollutantsColumns = (): EntityColumns<AirSzzPointPollutant>[] =>{

  const pollutantCodes = useAppSelector(selectPollutionSubstances);
  let list: DefaultOptionType[] = [];
  for (const item of pollutantCodes) {
    list = [...list, {
      value: item.code,
      label: `${item.code} - ${item.name}`
    }];
  }

  function getLabelByCode(code: string) {
    return list.find(item => item.value === code)?.label
  }

  return [
    {
      title: "Код загрязнения",
      dataIndex: "code",
      key: "code",
      inputNode: 
        <Select 
          options={list} 
          showSearch
          filterOption={(input, option) => {
            if (option){
              const str = option.label + '';
              return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }            
          }}
        />,
      render: (code) => <Text className="w-64" ellipsis={{ tooltip: `${getLabelByCode(code)}` }}>{getLabelByCode(code)}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: `Предельно допустимая концентрация (максимально разовая, мг/м3)`,
      dataIndex: "maxAvailableConcentration",
      key: "maxAvailableConcentration",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];
} 