import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { RopCalculation } from "../../api/models/rop-calculation";
import { CreateRopCalculationDto } from "../../api/models/create-rop-calculation-dto";
import { UpdateRopCalculationDto } from "../../api/models/update-rop-calculation-dto";
import { RopCalculationsApi } from "../../api/apis/rop-calculations-api";

export interface RopCalculationsState {
    ropCalculations: RopCalculation[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: RopCalculationsState = {
    ropCalculations: [],
    status: "idle",
};

export const createRopCalculationAsync = createAsyncThunk(
    "ropCalculations/create",
    async (ropCalculation: CreateRopCalculationDto, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsApi();
            const response = await ropCalculationsApi.ropCalculationsControllerCreate(ropCalculation);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateRopCalculationAsync = createAsyncThunk(
    "ropCalculations/update",
    async ({ ropCalculation, id }: { ropCalculation: UpdateRopCalculationDto; id: string }, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsApi();
            const response = await ropCalculationsApi.ropCalculationsControllerUpdate(ropCalculation, id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteRopCalculationAsync = createAsyncThunk(
    "ropCalculations/remove",
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsApi();
            const response = await ropCalculationsApi.ropCalculationsControllerRemove(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchRopCalculationsByQuarterAsync = createAsyncThunk(
    "ropCalculations/fetch",
    async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
        try {
            const ropCalculationApi = new RopCalculationsApi();
            const response = await ropCalculationApi.ropCalculationsControllerFindByQuart(organizationId, year, quarter);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchRopCalculationsBySite = () => {
        const { pending, fulfilled, rejected } = fetchRopCalculationsByQuarterAsync;
        return {
            [`${pending}`]: (state: RopCalculationsState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsState, action: PayloadAction<RopCalculation[]>) => {
                state.ropCalculations = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const createRopCalculation = () => {
        const { pending, fulfilled, rejected } = createRopCalculationAsync;
        return {
            [`${pending}`]: (state: RopCalculationsState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsState, action: PayloadAction<RopCalculation>) => {
                state.ropCalculations.push(action.payload);
                message.success("Успешно создан");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const updateRopCalculation = () => {
        const { pending, fulfilled, rejected } = updateRopCalculationAsync;

        return {
            [`${pending}`]: (state: RopCalculationsState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsState, action: PayloadAction<RopCalculation>) => {
                state.ropCalculations = state.ropCalculations.map((ropCalculation) => (ropCalculation.id === action.payload.id ? action.payload : ropCalculation));
                message.success("Успешно изменено");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const removeRopCalculation = () => {
        const { pending, fulfilled, rejected } = deleteRopCalculationAsync;
        return {
            [`${pending}`]: (state: RopCalculationsState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsState, action: PayloadAction<RopCalculation>) => {
                state.ropCalculations.splice(state.ropCalculations.indexOf(
                    state.ropCalculations.find((ws: RopCalculation) => ws.id === action.payload.id) || action.payload
                ), 1);
                message.success("Успешно удален");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    return { ...fetchRopCalculationsBySite(), ...createRopCalculation(), ...updateRopCalculation(), ...removeRopCalculation() };
};

export const RopCalculationsSlice = createSlice({
    name: "RopCalculations",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
});

export const selectRopCalculations = (state: RootState) => state.ropCalculations.ropCalculations;

export default RopCalculationsSlice.reducer;
