import { Checkbox, Form, Input } from "antd";
import { WaterDrainageObject } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const waterDrainageObjectsColumns: EntityColumns<WaterDrainageObject>[] =
  [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Код источника",
      dataIndex: "code",
      key: "code",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Фактический объем сбросов ПЭК",
      dataIndex: "factPEK",
      key: "factPEK",
      inputNode:
        <Form.Item name="factPEK" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
    {
      title: "Передано",
      dataIndex: "transferred",
      key: "transferred",
      inputNode:
        <Form.Item name="transferred" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
    {
      title: "Оборотное",
      dataIndex: "circulating",
      key: "circulating",
      inputNode:
        <Form.Item name="circulating" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (b) => <Checkbox checked={b} />,
      editable: true,
      copyable: true,
    },
  ];