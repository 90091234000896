import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { AuthLoginBody } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuthError, signInAsync } from "./authSlice";

const { Text } = Typography;

export const SignInForm: React.FC = () => {
  const authError = useAppSelector(selectAuthError);
  const dispatch = useAppDispatch();

  const onFinish = (values: AuthLoginBody) => {
    dispatch(signInAsync(values));
  };

  return (
    <Form name="login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Заполните email!" },
          { type: "email", message: "Неправильный формат email" },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: "Заполните пароль!" }]}>
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Пароль" />
      </Form.Item>
      <Form.Item>
        <Row justify="space-between">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>
          <Link className="login-form-forgot" to="/forgot-password">
            Забыли пароль ?
          </Link>
        </Row>
      </Form.Item>

      {authError && (
        <p style={{ width: "100%", textAlign: "center" }}>
          <Text type="danger">{authError.message}</Text>
        </p>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button" block >
          Войти
        </Button>
      </Form.Item>
    </Form>
  );
};
