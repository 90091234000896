import { Form, Modal } from "antd";
import React from "react";
import { CreateReportPaymentDto } from "../../../api/models/create-report-payment-dto";
import { ReportPaymentsColumns } from "../form/columns";

interface ReportPaymentsFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateReportPayment: (values: CreateReportPaymentDto) => void;
  organizationId?: string;
}

export const ReportPaymentForm: React.FC<ReportPaymentsFormProps> = ({ visible, setVisible, onCreateReportPayment, organizationId }) => {
  const [form] = Form.useForm<CreateReportPaymentDto>();

  const onChange = (value: any, additional?: any) => {

    (Object.keys(form.getFieldsValue()) as (keyof CreateReportPaymentDto)[]).find((key) => {
      return form.getFieldsValue()[key] === value;
    });

  }

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateReportPayment}>
        {ReportPaymentsColumns().map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
            {
              React.cloneElement(
                inputNode,
                {
                  onChange: onChange
                }
              )
            }
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
