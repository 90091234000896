import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateDropCodeDto, DropCodesApi, UpdateDropCodeDto, DropCode } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface DropCodesState {
  dropCodes: DropCode[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: DropCodesState = {
  dropCodes: [],
  status: "idle",
};

export const createDropCodeAsync = createAsyncThunk(
  "dropCodes/create",
  async (dropCode: CreateDropCodeDto, { rejectWithValue }) => {
    try {
      const dropCodesApi = new DropCodesApi();
      const response = await dropCodesApi.dropCodesControllerCreate(dropCode);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDropCodeAsync = createAsyncThunk(
  "dropCodes/update",
  async ({ dropCode, id }: { dropCode: UpdateDropCodeDto; id: string }, { rejectWithValue }) => {
    try {
      const dropCodesApi = new DropCodesApi();
      const response = await dropCodesApi.dropCodesControllerUpdate(dropCode, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDropCodeAsync = createAsyncThunk(
  "dropCodes/remove",
  async ({ dropCode }: { dropCode: DropCode }, { rejectWithValue }) => {
    try {
      const dropCodesApi = new DropCodesApi();
      const response = await dropCodesApi.dropCodesControllerRemove(dropCode.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDropCodesAsync = createAsyncThunk(
  "dropCodes/fetch", async (_, { rejectWithValue }) => {
    try {
      const dropCodesApi = new DropCodesApi();
      const response = await dropCodesApi.dropCodesControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchDropCodes = () => {
    const { pending, fulfilled, rejected } = fetchDropCodesAsync;
    return {
      [`${pending}`]: (state: DropCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropCodesState, action: PayloadAction<DropCode[]>) => {
        state.dropCodes = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createDropCode = () => {
    const { pending, fulfilled, rejected } = createDropCodeAsync;
    return {
      [`${pending}`]: (state: DropCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropCodesState, action: PayloadAction<DropCode>) => {
        state.dropCodes = [action.payload, ...state.dropCodes];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateDropCode = () => {
    const { pending, fulfilled, rejected } = updateDropCodeAsync;

    return {
      [`${pending}`]: (state: DropCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropCodesState, action: PayloadAction<DropCode>) => {
        state.dropCodes = state.dropCodes.map((DropCode) => (DropCode.id === action.payload.id ? action.payload : DropCode));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeDropCode = () => {
    const { pending, fulfilled, rejected } = deleteDropCodeAsync;
    return {
      [`${pending}`]: (state: DropCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropCodesState, action: PayloadAction<DropCode>) => {
        state.dropCodes.splice(state.dropCodes.indexOf(
          state.dropCodes.find((ws: DropCode) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchDropCodes(), ...createDropCode(), ...updateDropCode(), ...removeDropCode() };
};

export const dropCodesSlice = createSlice({
  name: "dropCodes",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectDropCodes = (state: RootState) => state.dropCodes.dropCodes;

export default dropCodesSlice.reducer;
