import {
  Col, Layout, Menu, MenuProps, Row,
} from "antd";
import { useState } from "react";
import { SoilPointsComponent } from "../soilPoints/SoilPointsComponent";
import { AirSzzPointsComponent } from "../airSzzPoints/AirSzzPointsComponent";
import { WaterPointsComponent } from "../waterPoints/WaterPointsComponent";
import { RadiologyPointsComponent } from "../radiologyPoints/RadiologyPointsComponent";

interface LaboratoryPointsComponentProps {
  organizationId?: string;
}

type MenuItem = Required<MenuProps>['items'][number];

export const LaboratoryPointsComponent: React.FC<LaboratoryPointsComponentProps> = ({ organizationId }) => {

  const [menuSelected, setMenuSelected] = useState<any[]>([getItem('Атмосферный воздух', 'airSzzPoints')]);

  const menuItems: MenuProps['items'] = [
    getItem('Атмосферный воздух', 'airSzzPoints'),
    getItem('Водные объекты', 'waterPoints'),
    getItem('Почва', 'siolPoints'),
    getItem('Радиологическая безопасность', 'radiologyPoints'),
  ];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const onClick: MenuProps['onClick'] = (e) => {
    let item: any;
    for (const key of e.keyPath.reverse()) {
      if (item && item.length && item[0] && item[0].children) {
        item = [...item, ...item[0].children.filter((child: any) => child?.key === key)];
      } else {
        item = menuItems.filter((item) => item?.key === key)
      }
    }
    setMenuSelected(item);
  };

  const menuComponents = {
    "siolPoints": (<SoilPointsComponent organizationId={organizationId} />),
    "waterPoints": (<WaterPointsComponent organizationId={organizationId} />),
    "airSzzPoints": (<AirSzzPointsComponent organizationId={organizationId} />),
    "radiologyPoints": (<RadiologyPointsComponent organizationId={organizationId} />),
  }

  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Row gutter={[24, 0]}>
          <Col span={24} md={6} className="mb-24">
            <Menu
              onClick={onClick}
              style={{ width: 256 }}
              defaultSelectedKeys={['airSzzPoints']}
              mode="inline"
              items={menuItems}
            />
          </Col>

          <Col span={24} md={18} className="mb-24">
            <h2>
              {menuSelected.map((menu: any, index) => {
                if (index + 1 >= menuSelected.length) {
                  return `${menu?.label}`;
                } else {
                  return `${menu?.label} / `;
                }
              })}
            </h2>

            {menuSelected.length > 0 && menuComponents[menuSelected[menuSelected.length - 1].key as keyof typeof menuComponents]}
          </Col>
        </Row>
      </Layout>
    </>
  );
};
