/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AirEmissionsPointPollutant } from '../models';
import { CreateAirEmissionsPointPollutantDto } from '../models';
import { UpdateAirEmissionsPointPollutantDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * AirEmissionsPointPollutantsApi - axios parameter creator
 * @export
 */
export const AirEmissionsPointPollutantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAirEmissionsPointPollutantDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsPointPollutantsControllerCreate: async (body: CreateAirEmissionsPointPollutantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling airEmissionsPointPollutantsControllerCreate.');
            }
            const localVarPath = `/air-emissions-point-pollutants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsPointPollutantsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/air-emissions-point-pollutants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsPointPollutantsControllerFindByPointId: async (pointId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointId' is not null or undefined
            if (pointId === null || pointId === undefined) {
                throw new RequiredError('pointId','Required parameter pointId was null or undefined when calling airEmissionsPointPollutantsControllerFindByPointId.');
            }
            const localVarPath = `/air-emissions-point-pollutants/point/{pointId}`
                .replace(`{${"pointId"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsPointPollutantsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling airEmissionsPointPollutantsControllerFindOne.');
            }
            const localVarPath = `/air-emissions-point-pollutants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsPointPollutantsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling airEmissionsPointPollutantsControllerRemove.');
            }
            const localVarPath = `/air-emissions-point-pollutants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAirEmissionsPointPollutantDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsPointPollutantsControllerUpdate: async (body: UpdateAirEmissionsPointPollutantDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling airEmissionsPointPollutantsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling airEmissionsPointPollutantsControllerUpdate.');
            }
            const localVarPath = `/air-emissions-point-pollutants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AirEmissionsPointPollutantsApi - functional programming interface
 * @export
 */
export const AirEmissionsPointPollutantsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAirEmissionsPointPollutantDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerCreate(body: CreateAirEmissionsPointPollutantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsPointPollutant>>> {
            const localVarAxiosArgs = await AirEmissionsPointPollutantsApiAxiosParamCreator(configuration).airEmissionsPointPollutantsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AirEmissionsPointPollutant>>>> {
            const localVarAxiosArgs = await AirEmissionsPointPollutantsApiAxiosParamCreator(configuration).airEmissionsPointPollutantsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerFindByPointId(pointId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AirEmissionsPointPollutant>>>> {
            const localVarAxiosArgs = await AirEmissionsPointPollutantsApiAxiosParamCreator(configuration).airEmissionsPointPollutantsControllerFindByPointId(pointId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsPointPollutant>>> {
            const localVarAxiosArgs = await AirEmissionsPointPollutantsApiAxiosParamCreator(configuration).airEmissionsPointPollutantsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsPointPollutant>>> {
            const localVarAxiosArgs = await AirEmissionsPointPollutantsApiAxiosParamCreator(configuration).airEmissionsPointPollutantsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateAirEmissionsPointPollutantDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerUpdate(body: UpdateAirEmissionsPointPollutantDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsPointPollutant>>> {
            const localVarAxiosArgs = await AirEmissionsPointPollutantsApiAxiosParamCreator(configuration).airEmissionsPointPollutantsControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AirEmissionsPointPollutantsApi - factory interface
 * @export
 */
export const AirEmissionsPointPollutantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAirEmissionsPointPollutantDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerCreate(body: CreateAirEmissionsPointPollutantDto, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsPointPollutant>> {
            return AirEmissionsPointPollutantsApiFp(configuration).airEmissionsPointPollutantsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AirEmissionsPointPollutant>>> {
            return AirEmissionsPointPollutantsApiFp(configuration).airEmissionsPointPollutantsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerFindByPointId(pointId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AirEmissionsPointPollutant>>> {
            return AirEmissionsPointPollutantsApiFp(configuration).airEmissionsPointPollutantsControllerFindByPointId(pointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsPointPollutant>> {
            return AirEmissionsPointPollutantsApiFp(configuration).airEmissionsPointPollutantsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsPointPollutant>> {
            return AirEmissionsPointPollutantsApiFp(configuration).airEmissionsPointPollutantsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAirEmissionsPointPollutantDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsPointPollutantsControllerUpdate(body: UpdateAirEmissionsPointPollutantDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsPointPollutant>> {
            return AirEmissionsPointPollutantsApiFp(configuration).airEmissionsPointPollutantsControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AirEmissionsPointPollutantsApi - object-oriented interface
 * @export
 * @class AirEmissionsPointPollutantsApi
 * @extends {BaseAPI}
 */
export class AirEmissionsPointPollutantsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAirEmissionsPointPollutantDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsPointPollutantsApi
     */
    public async airEmissionsPointPollutantsControllerCreate(body: CreateAirEmissionsPointPollutantDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsPointPollutant>> {
        return AirEmissionsPointPollutantsApiFp(this.configuration).airEmissionsPointPollutantsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsPointPollutantsApi
     */
    public async airEmissionsPointPollutantsControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AirEmissionsPointPollutant>>> {
        return AirEmissionsPointPollutantsApiFp(this.configuration).airEmissionsPointPollutantsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} pointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsPointPollutantsApi
     */
    public async airEmissionsPointPollutantsControllerFindByPointId(pointId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AirEmissionsPointPollutant>>> {
        return AirEmissionsPointPollutantsApiFp(this.configuration).airEmissionsPointPollutantsControllerFindByPointId(pointId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsPointPollutantsApi
     */
    public async airEmissionsPointPollutantsControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsPointPollutant>> {
        return AirEmissionsPointPollutantsApiFp(this.configuration).airEmissionsPointPollutantsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsPointPollutantsApi
     */
    public async airEmissionsPointPollutantsControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsPointPollutant>> {
        return AirEmissionsPointPollutantsApiFp(this.configuration).airEmissionsPointPollutantsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateAirEmissionsPointPollutantDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsPointPollutantsApi
     */
    public async airEmissionsPointPollutantsControllerUpdate(body: UpdateAirEmissionsPointPollutantDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsPointPollutant>> {
        return AirEmissionsPointPollutantsApiFp(this.configuration).airEmissionsPointPollutantsControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
