import { Input, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { CarbonIndirectEmission } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { EntityColumns } from "../../../../app/types";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";

export const CarbonIndirectEmissionColumns = (): EntityColumns<CarbonIndirectEmission>[] => {
  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Энергетическая система",
      dataIndex: "energySystem",
      key: "energySystem",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Потребленеие электрической энергии, полученной от внешних генерирующих объектов (МВтч)",
      dataIndex: "consumption",
      key: "consumption",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Коэффициент косвенных энергетических выбросов (тСО2е/МВтч)",
      dataIndex: "coefficient",
      key: "coefficient",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Количество выбросов (т СО2е)",
      dataIndex: "co2e",
      key: "carco2ebon",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
