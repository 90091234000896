import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { WasteAccumulationLimit } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";
import { selectWasteAccumulationSites } from "../../wasteAccumulationSites/WasteAccumulationSitesSlice";
import { selectWasteRegisters } from "../../wasteRegister/WasteRegisterSlice";

export const WasteAccumulationLimitsColumns = (): EntityColumns<WasteAccumulationLimit>[] => {
  const organization = useAppSelector(selectOrganizationsProfile);
  const wasteAccumulationSites = useAppSelector(selectWasteAccumulationSites);
  const wasteRegisters = useAppSelector(selectWasteRegisters);
  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Разрешение",
      dataIndex: "permission",
      key: "permissionId",
      inputNode: <Select options={organization?.permissions?.map(
        (permission) => {
          return {
            value: permission.id,
            label: permission.name
          }
        }
      )} />,
      render: (perm) => <Text className="w-64" ellipsis={{ tooltip: `${organization?.permissions?.find((permission) => permission.id === perm)?.name}` }}>{organization?.permissions?.find((permission) => permission.id === perm)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Промышленная площадка",
      dataIndex: "industrialSite",
      key: "industrialSiteId",
      inputNode: <Select options={organization?.industrialSites?.map(
        (industrialSite) => {
          return {
            value: industrialSite.id,
            label: industrialSite.name
          }
        }
      )} />,
      render: (site) => <Text className="w-64" ellipsis={{ tooltip: `${organization?.industrialSites?.find((industrialSite) => industrialSite.id === site)?.name}` }}>{organization?.industrialSites?.find((industrialSite) => industrialSite.id === site)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Отход",
      dataIndex: "wasteRegister",
      key: "wasteRegisterId",
      inputNode: <Select options={wasteRegisters?.map(
        (wasteRegister) => {
          return {
            value: wasteRegister.id,
            label: wasteRegister.name
          }
        }
      )} />,
      render: (wasteRegister) => <Text className="w-64" ellipsis={{ tooltip: `${wasteRegisters?.find((wbs) => wbs.id === wasteRegister)?.name} ${wasteRegisters?.find((wbs) => wbs.id === wasteRegister)?.code}` }}>{wasteRegisters?.find((wbs) => wbs.id === wasteRegister)?.name} {wasteRegisters?.find((wbs) => wbs.id === wasteRegister)?.code}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Место накопления",
      dataIndex: "wasteAccumulationSite",
      key: "wasteAccumulationSiteId",
      inputNode: <Select options={wasteAccumulationSites?.map(
        (wasteAccumulationSite) => {
          return {
            value: wasteAccumulationSite.id,
            label: wasteAccumulationSite.name
          }
        }
      )} />,
      render: (site) => <Text className="w-64" ellipsis={{ tooltip: `${wasteAccumulationSites?.find((wasteAccumulationSite) => wasteAccumulationSite.id === site)?.name}` }}>{wasteAccumulationSites?.find((wasteAccumulationSite) => wasteAccumulationSite.id === site)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Лимит накопления отходов, тонн/год",
      dataIndex: "limit",
      key: "limit",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Установленный срок накопления",
      dataIndex: "term",
      key: "term",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
