import { Layout } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { Profile } from "../features/profile/Profile";

const ProfilePage: React.FC = () => (
  <Layout className="p-8">
    <Title className="ml-2 !mt-0" level={1}>
      Профиль
    </Title>
    <Profile />
  </Layout>
);

export default ProfilePage;
