
import { Button, Layout } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { CreateCategoryDto, UpdateCategoryDto, Category } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CategoryForm } from "./components/CategoryForm";
import { createCategoryAsync, deleteCategoryAsync, fetchCategoriesAsync, selectCategories, updateCategoryAsync } from "./categoriesSlice";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { CategoryColumns } from "./form/columns";
import { history } from "../../app/helpers";
import { CategoryVariablesComponent } from "../categoryVariables/CategoryVariablesComponent";

interface CategoriesComponentProps {
  organizationId: string;
}

export const CategoriesComponent: React.FC<CategoriesComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectCategories);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchCategoriesAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateCategory = async (category: UpdateCategoryDto, id: string) => {
    return dispatch(updateCategoryAsync({ category, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateCategory = (category: CreateCategoryDto) => {
    if (organizationId) {
      dispatch(createCategoryAsync({ ...category, organizationId })).then((res: any) => {
        if (!res.error) {
          setVisible(false);
        }
      });
    }
  };

  const deleteCategory = (record: Category) => {
    return dispatch(deleteCategoryAsync(record.id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="primary">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">

      <Routes>
        <Route path="/organizationSource/categories/:categoryId" element={<CategoryVariablesComponent />} />
        <Route path="*" element={
          <>
            {visible && <CategoryForm visible={visible} setVisible={setVisible} onCreateCategory={onCreateCategory} />}
            <EditableTable<Category, UpdateCategoryDto>
              onSave={onUpdateCategory}
              onDelete={deleteCategory}
              onWatch={(category) => { history.navigate && history.navigate(`/organizations/${organizationId}/module/directory/organizationSource/categories/${category.id}`) }}
              entityColumns={CategoryColumns()}
              dataSource={categories}
              footer={() => addButton}
            />
          </>
        } />
      </Routes>
    </Layout>
  );
};
