/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
import { CleaningEquipment } from "../models";
import { CreateCleaningEquipmentDto } from "../models";
import { InlineResponse2003 } from "../models";
import { UpdateCleaningEquipmentDto } from "../models";
import { getFromStorage } from "../../app/helpers";
/**
 * CleaningEquipmentsApi - axios parameter creator
 * @export
 */
export const CleaningEquipmentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateCleaningEquipmentDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleaningEquipmentsControllerCreate: async (body: CreateCleaningEquipmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError("body", "Required parameter body was null or undefined when calling cleaningEquipmentsControllerCreate.");
      }
      const localVarPath = `/cleaning-equipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = typeof body !== "string" || (localVarRequestOptions.headers || {})["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [order]
     * @param {number} [page]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleaningEquipmentsControllerFindAll: async (
      order?: string,
      page?: number,
      take?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/cleaning-equipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      if (order !== undefined) {
        localVarQueryParameter["order"] = order;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (take !== undefined) {
        localVarQueryParameter["take"] = take;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} pollutionSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleaningEquipmentsControllerFindByPollutionSourceId: async (pollutionSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      if (pollutionSourceId === null || pollutionSourceId === undefined) {
        throw new RequiredError(
          "pollutionSourceId",
          "Required parameter pollutionSourceId was null or undefined when calling selectionSourcesControllerFindOne."
        );
      }
      const localVarPath = `/cleaning-equipments/pollutionSource/{pollutionSourceId}`.replace(
        `{${"pollutionSourceId"}}`,
        encodeURIComponent(String(pollutionSourceId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleaningEquipmentsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling cleaningEquipmentsControllerFindOne.");
      }
      const localVarPath = `/cleaning-equipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleaningEquipmentsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling cleaningEquipmentsControllerRemove.");
      }
      const localVarPath = `/cleaning-equipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateCleaningEquipmentDto} body
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleaningEquipmentsControllerUpdate: async (
      body: UpdateCleaningEquipmentDto,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError("body", "Required parameter body was null or undefined when calling cleaningEquipmentsControllerUpdate.");
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling cleaningEquipmentsControllerUpdate.");
      }
      const localVarPath = `/cleaning-equipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = typeof body !== "string" || (localVarRequestOptions.headers || {})["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CleaningEquipmentsApi - functional programming interface
 * @export
 */
export const CleaningEquipmentsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateCleaningEquipmentDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerCreate(
      body: CreateCleaningEquipmentDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CleaningEquipment>>> {
      const localVarAxiosArgs = await CleaningEquipmentsApiAxiosParamCreator(configuration).cleaningEquipmentsControllerCreate(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [order]
     * @param {number} [page]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerFindAll(
      order?: string,
      page?: number,
      take?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse2003>>> {
      const localVarAxiosArgs = await CleaningEquipmentsApiAxiosParamCreator(configuration).cleaningEquipmentsControllerFindAll(
        order,
        page,
        take,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
     /**
     *
     * @param {string} pollutionSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
      async cleaningEquipmentsControllerFindByPollutionSourceId(
        pollutionSourceId: string,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse2003>>> {
        const localVarAxiosArgs = await CleaningEquipmentsApiAxiosParamCreator(configuration).cleaningEquipmentsControllerFindByPollutionSourceId(
          pollutionSourceId,
          options
        );
        return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
          const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
          return axios.request(axiosRequestArgs);
        };
      },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerFindOne(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CleaningEquipment>>> {
      const localVarAxiosArgs = await CleaningEquipmentsApiAxiosParamCreator(configuration).cleaningEquipmentsControllerFindOne(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CleaningEquipment>>> {
      const localVarAxiosArgs = await CleaningEquipmentsApiAxiosParamCreator(configuration).cleaningEquipmentsControllerRemove(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpdateCleaningEquipmentDto} body
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerUpdate(
      body: UpdateCleaningEquipmentDto,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
      const localVarAxiosArgs = await CleaningEquipmentsApiAxiosParamCreator(configuration).cleaningEquipmentsControllerUpdate(body, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CleaningEquipmentsApi - factory interface
 * @export
 */
export const CleaningEquipmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {CreateCleaningEquipmentDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerCreate(
      body: CreateCleaningEquipmentDto,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<CleaningEquipment>> {
      return CleaningEquipmentsApiFp(configuration)
        .cleaningEquipmentsControllerCreate(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [order]
     * @param {number} [page]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerFindAll(
      order?: string,
      page?: number,
      take?: number,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<InlineResponse2003>> {
      return CleaningEquipmentsApiFp(configuration)
        .cleaningEquipmentsControllerFindAll(order, page, take, options)
        .then((request) => request(axios, basePath));
    },
     /**
     *
     * @param {string} pollutionSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
      async cleaningEquipmentsControllerFindByPollutionSourceId(
        pollutionSourceId: string,
        options?: AxiosRequestConfig
      ): Promise<AxiosResponse<InlineResponse2003>> {
        return CleaningEquipmentsApiFp(configuration)
          .cleaningEquipmentsControllerFindByPollutionSourceId(pollutionSourceId, options)
          .then((request) => request(axios, basePath));
      },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CleaningEquipment>> {
      return CleaningEquipmentsApiFp(configuration)
        .cleaningEquipmentsControllerFindOne(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CleaningEquipment>> {
      return CleaningEquipmentsApiFp(configuration)
        .cleaningEquipmentsControllerRemove(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateCleaningEquipmentDto} body
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleaningEquipmentsControllerUpdate(
      body: UpdateCleaningEquipmentDto,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return CleaningEquipmentsApiFp(configuration)
        .cleaningEquipmentsControllerUpdate(body, id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CleaningEquipmentsApi - object-oriented interface
 * @export
 * @class CleaningEquipmentsApi
 * @extends {BaseAPI}
 */
export class CleaningEquipmentsApi extends BaseAPI {
  /**
   *
   * @param {CreateCleaningEquipmentDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CleaningEquipmentsApi
   */
  public async cleaningEquipmentsControllerCreate(
    body: CreateCleaningEquipmentDto,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<CleaningEquipment>> {
    return CleaningEquipmentsApiFp(this.configuration)
      .cleaningEquipmentsControllerCreate(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} [order]
   * @param {number} [page]
   * @param {number} [take]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CleaningEquipmentsApi
   */
  public async cleaningEquipmentsControllerFindAll(
    order?: string,
    page?: number,
    take?: number,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<InlineResponse2003>> {
    return CleaningEquipmentsApiFp(this.configuration)
      .cleaningEquipmentsControllerFindAll(order, page, take, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} pollutionSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SelectionSourcesApi
   */
   public async cleaningEquipmentsControllerFindByPollutionSourceId(
    pollutionSourceId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<InlineResponse2003>> {
    return CleaningEquipmentsApiFp(this.configuration)
      .cleaningEquipmentsControllerFindByPollutionSourceId(pollutionSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CleaningEquipmentsApi
   */
  public async cleaningEquipmentsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CleaningEquipment>> {
    return CleaningEquipmentsApiFp(this.configuration)
      .cleaningEquipmentsControllerFindOne(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CleaningEquipmentsApi
   */
  public async cleaningEquipmentsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CleaningEquipment>> {
    return CleaningEquipmentsApiFp(this.configuration)
      .cleaningEquipmentsControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {UpdateCleaningEquipmentDto} body
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CleaningEquipmentsApi
   */
  public async cleaningEquipmentsControllerUpdate(
    body: UpdateCleaningEquipmentDto,
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return CleaningEquipmentsApiFp(this.configuration)
      .cleaningEquipmentsControllerUpdate(body, id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
