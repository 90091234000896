/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateRopPackagingClassifierDto } from '../models/create-rop-packaging-classifier-dto';
import { RopPackagingClassifier } from '../models/rop-packaging-classifier';
import { UpdateRopPackagingClassifierDto } from '../models/update-rop-packaging-classifier-dto';
import { getFromStorage } from '../../app/helpers';

/**
 * RopPackagingClassifiersApi - axios parameter creator
 * @export
 */
export const RopPackagingClassifiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRopPackagingClassifierDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ropPackagingClassifiersControllerCreate: async (body: CreateRopPackagingClassifierDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ropPackagingClassifiersControllerCreate.');
            }
            const localVarPath = `/rop-packaging-classifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json'; localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ropPackagingClassifiersControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rop-packaging-classifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ropPackagingClassifiersControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ropPackagingClassifiersControllerFindOne.');
            }
            const localVarPath = `/rop-packaging-classifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ropPackagingClassifiersControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ropPackagingClassifiersControllerRemove.');
            }
            const localVarPath = `/rop-packaging-classifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRopPackagingClassifierDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ropPackagingClassifiersControllerUpdate: async (body: UpdateRopPackagingClassifierDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ropPackagingClassifiersControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ropPackagingClassifiersControllerUpdate.');
            }
            const localVarPath = `/rop-packaging-classifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json'; localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RopPackagingClassifiersApi - functional programming interface
 * @export
 */
export const RopPackagingClassifiersApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRopPackagingClassifierDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerCreate(body: CreateRopPackagingClassifierDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<RopPackagingClassifier>>> {
            const localVarAxiosArgs = await RopPackagingClassifiersApiAxiosParamCreator(configuration).ropPackagingClassifiersControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<RopPackagingClassifier>>>> {
            const localVarAxiosArgs = await RopPackagingClassifiersApiAxiosParamCreator(configuration).ropPackagingClassifiersControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<RopPackagingClassifier>>> {
            const localVarAxiosArgs = await RopPackagingClassifiersApiAxiosParamCreator(configuration).ropPackagingClassifiersControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<RopPackagingClassifier>>> {
            const localVarAxiosArgs = await RopPackagingClassifiersApiAxiosParamCreator(configuration).ropPackagingClassifiersControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateRopPackagingClassifierDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerUpdate(body: UpdateRopPackagingClassifierDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await RopPackagingClassifiersApiAxiosParamCreator(configuration).ropPackagingClassifiersControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RopPackagingClassifiersApi - factory interface
 * @export
 */
export const RopPackagingClassifiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateRopPackagingClassifierDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerCreate(body: CreateRopPackagingClassifierDto, options?: AxiosRequestConfig): Promise<AxiosResponse<RopPackagingClassifier>> {
            return RopPackagingClassifiersApiFp(configuration).ropPackagingClassifiersControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<RopPackagingClassifier>>> {
            return RopPackagingClassifiersApiFp(configuration).ropPackagingClassifiersControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<RopPackagingClassifier>> {
            return RopPackagingClassifiersApiFp(configuration).ropPackagingClassifiersControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<RopPackagingClassifier>> {
            return RopPackagingClassifiersApiFp(configuration).ropPackagingClassifiersControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRopPackagingClassifierDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ropPackagingClassifiersControllerUpdate(body: UpdateRopPackagingClassifierDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return RopPackagingClassifiersApiFp(configuration).ropPackagingClassifiersControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RopPackagingClassifiersApi - object-oriented interface
 * @export
 * @class RopPackagingClassifiersApi
 * @extends {BaseAPI}
 */
export class RopPackagingClassifiersApi extends BaseAPI {
    /**
     * 
     * @param {CreateRopPackagingClassifierDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RopPackagingClassifiersApi
     */
    public async ropPackagingClassifiersControllerCreate(body: CreateRopPackagingClassifierDto, options?: AxiosRequestConfig): Promise<AxiosResponse<RopPackagingClassifier>> {
        return RopPackagingClassifiersApiFp(this.configuration).ropPackagingClassifiersControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RopPackagingClassifiersApi
     */
    public async ropPackagingClassifiersControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<RopPackagingClassifier>>> {
        return RopPackagingClassifiersApiFp(this.configuration).ropPackagingClassifiersControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RopPackagingClassifiersApi
     */
    public async ropPackagingClassifiersControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<RopPackagingClassifier>> {
        return RopPackagingClassifiersApiFp(this.configuration).ropPackagingClassifiersControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RopPackagingClassifiersApi
     */
    public async ropPackagingClassifiersControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<RopPackagingClassifier>> {
        return RopPackagingClassifiersApiFp(this.configuration).ropPackagingClassifiersControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateRopPackagingClassifierDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RopPackagingClassifiersApi
     */
    public async ropPackagingClassifiersControllerUpdate(body: UpdateRopPackagingClassifierDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
        return RopPackagingClassifiersApiFp(this.configuration).ropPackagingClassifiersControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
