import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateTaxDropDto, TaxDropsApi, UpdateTaxDropDto, TaxDrop } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface TaxDropsState {
  taxDrops: TaxDrop[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: TaxDropsState = {
  taxDrops: [],
  status: "idle",
};

export const createTaxDropAsync = createAsyncThunk(
  "taxDrops/create",
  async (taxDrop: CreateTaxDropDto, { rejectWithValue }) => {
    try {
      const taxDropsApi = new TaxDropsApi();
      const response = await taxDropsApi.taxDropsControllerCreate(taxDrop);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaxDropAsync = createAsyncThunk(
  "taxDrops/update",
  async ({ taxDrop, id }: { taxDrop: UpdateTaxDropDto; id: string }, { rejectWithValue }) => {
    try {
      const taxDropsApi = new TaxDropsApi();
      const response = await taxDropsApi.taxDropsControllerUpdate(taxDrop, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTaxDropAsync = createAsyncThunk(
  "taxDrops/remove",
  async ({ taxDrop }: { taxDrop: TaxDrop }, { rejectWithValue }) => {
    try {
      const taxDropsApi = new TaxDropsApi();
      const response = await taxDropsApi.taxDropsControllerRemove(taxDrop.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTaxDropsAsync = createAsyncThunk(
  "taxDrops/fetch", async (_, { rejectWithValue }) => {
    try {
      const taxDropsApi = new TaxDropsApi();
      const response = await taxDropsApi.taxDropsControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchTaxDrops = () => {
    const { pending, fulfilled, rejected } = fetchTaxDropsAsync;
    return {
      [`${pending}`]: (state: TaxDropsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxDropsState, action: PayloadAction<TaxDrop[]>) => {
        state.taxDrops = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxDropsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createTaxDrop = () => {
    const { pending, fulfilled, rejected } = createTaxDropAsync;
    return {
      [`${pending}`]: (state: TaxDropsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxDropsState, action: PayloadAction<TaxDrop>) => {
        state.taxDrops = [action.payload, ...state.taxDrops];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxDropsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateTaxDrop = () => {
    const { pending, fulfilled, rejected } = updateTaxDropAsync;

    return {
      [`${pending}`]: (state: TaxDropsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxDropsState, action: PayloadAction<TaxDrop>) => {
        state.taxDrops = state.taxDrops.map((TaxDrop) => (TaxDrop.id === action.payload.id ? action.payload : TaxDrop));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxDropsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeTaxDrop = () => {
    const { pending, fulfilled, rejected } = deleteTaxDropAsync;
    return {
      [`${pending}`]: (state: TaxDropsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxDropsState, action: PayloadAction<TaxDrop>) => {
        state.taxDrops.splice(state.taxDrops.indexOf(
          state.taxDrops.find((ws: TaxDrop) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxDropsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchTaxDrops(), ...createTaxDrop(), ...updateTaxDrop(), ...removeTaxDrop() };
};

export const taxDropsSlice = createSlice({
  name: "taxDrops",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectTaxDrops = (state: RootState) => state.taxDrops.taxDrops;

export default taxDropsSlice.reducer;
