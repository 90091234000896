import { Col, Form, Layout, Row } from "antd";
import Logo from "../assets/Logo";
import { SignInForm } from "../features/auth/SignInForm";

export const SignInPage = () => {
  return (
    <Layout>
      <Row className="min-h-screen" justify="center" align="middle">
        <Col span={6}>
          <Row justify={"center"}>
            <Form.Item>
              <Logo width="20rem" height="20rem" />
            </Form.Item>
          </Row>
          <SignInForm />
        </Col>
      </Row>
    </Layout>
  );
};
