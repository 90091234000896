import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreatePollutionSubstanceDto, PollutionSubstance, PollutionSubstancesApi, UpdatePollutionSubstanceDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";
import { DefaultOptionType } from "antd/es/select";

export interface PollutionSubstancesState {
  pollutionSubstances: PollutionSubstance[];
  pollutionSubstancesInputOptions: DefaultOptionType[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: PollutionSubstancesState = {
  pollutionSubstances: [],
  pollutionSubstancesInputOptions: [],
  status: "idle",
};

export const fetchPollutionSubstancesAsync = createAsyncThunk("pollutionSubstances/fetch", async (_, { rejectWithValue }) => {
  try {
    const pollutionSubstancesApi = new PollutionSubstancesApi();
    const response = await pollutionSubstancesApi.pollutionSubstancesControllerFindAll();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createPollutionSubstanceAsync = createAsyncThunk(
  "pollutionSubstances/create",
  async (pollutionSubstance: CreatePollutionSubstanceDto, { rejectWithValue }) => {
    try {
      const pollutionSubstancesApi = new PollutionSubstancesApi();
      const response = await pollutionSubstancesApi.pollutionSubstancesControllerCreate(pollutionSubstance);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePollutionSubstanceAsync = createAsyncThunk(
  "pollutionSubstances/update",
  async ({ pollutionSubstance, id }: { pollutionSubstance: UpdatePollutionSubstanceDto; id: string }, { rejectWithValue }) => {
    try {
      const pollutionSubstancesApi = new PollutionSubstancesApi();
      const response = await pollutionSubstancesApi.pollutionSubstancesControllerUpdate(pollutionSubstance, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchPollutionSubstances = () => {
    const { pending, fulfilled, rejected } = fetchPollutionSubstancesAsync;
    return {
      [`${pending}`]: (state: PollutionSubstancesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSubstancesState, action: PayloadAction<PollutionSubstance[]>) => {
        state.pollutionSubstances = action.payload;
        state.pollutionSubstancesInputOptions = action.payload.map((substance) => ({
          label: `${substance.code} ${substance.name}`,
          value: substance.code,
        }));
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSubstancesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createPollutionSubstance = () => {
    const { pending, fulfilled, rejected } = createPollutionSubstanceAsync;
    return {
      [`${pending}`]: (state: PollutionSubstancesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSubstancesState, action: PayloadAction<PollutionSubstance>) => {
        state.pollutionSubstances.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSubstancesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updatePollutionSubstance = () => {
    const { pending, fulfilled, rejected } = updatePollutionSubstanceAsync;

    return {
      [`${pending}`]: (state: PollutionSubstancesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSubstancesState, action: PayloadAction<PollutionSubstance>) => {
        state.pollutionSubstances = state.pollutionSubstances.map((pollutionSubstance) =>
          pollutionSubstance.code === action.payload.code ? action.payload : pollutionSubstance
        );
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSubstancesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchPollutionSubstances(), ...createPollutionSubstance(), ...updatePollutionSubstance() };
};

export const pollutionSubstancesSlice = createSlice({
  name: "pollutionSubstances",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectPollutionSubstances = (state: RootState) => state.pollutionSubstances.pollutionSubstances;
export const selectPollutionSubstancesInputOptions = (state: RootState) => state.pollutionSubstances.pollutionSubstancesInputOptions;

export default pollutionSubstancesSlice.reducer;
