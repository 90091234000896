import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreatePollutionSubstanceDto, PollutionSubstance, UpdatePollutionSubstanceDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { PollutionSubstancesForm } from "./components/PollutionSubstancesForm";
import { pollutionSubstanceColumns } from "./form/columns";
import {
  selectPollutionSubstances,
  updatePollutionSubstanceAsync,
  createPollutionSubstanceAsync,
  fetchPollutionSubstancesAsync,
} from "./pollutionSubstancesSlice";

export const PollutionSubstancesComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const pollutionSubstances = useAppSelector(selectPollutionSubstances);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchPollutionSubstancesAsync());
  }, [dispatch]);

  const onCreatePollutionSubstance = (PollutionSubstance: CreatePollutionSubstanceDto) => {
    dispatch(createPollutionSubstanceAsync({ ...PollutionSubstance })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdatePollutionSubstance = async (pollutionSubstance: UpdatePollutionSubstanceDto, id: string) => {
    return dispatch(updatePollutionSubstanceAsync({ pollutionSubstance, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  //   const deletePollutionSubstance = (PollutionSubstance: PollutionSubstance) => {
  //     return dispatch(deletePollutionSubstanceAsync({ PollutionSubstance })).then((res: any) => {
  //       if (!res.error) {
  //         return true;
  //       }
  //       return false;
  //     });
  //   };

  const addButton = (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        Добавить код загрязнения
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <PollutionSubstancesForm visible={visible} setVisible={setVisible} onCreatePollutionSubstance={onCreatePollutionSubstance} />}

      <EditableTable<PollutionSubstance, UpdatePollutionSubstanceDto>
        onSave={onUpdatePollutionSubstance}
        entityColumns={pollutionSubstanceColumns}
        dataSource={pollutionSubstances}
        footer={() => addButton}
      />
    </Layout>
  );
};
