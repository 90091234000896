import { Button, Layout, List } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createUserAsync, fetchUsersAsync, removeUserAsync, selectUsers, updateUserAsync } from "./usersSlice";
import { UsersListItem } from "./components/UsersListItem";
import { UserForm } from "./components/UserForm";
import { selectAuthUser } from "../auth/authSlice";
import { getPermittedRolesToCreateUser } from "../../app/role";
import { DefaultOptionType } from "antd/es/select";
import { SignUpDto, UpdateUserDto, User } from "../../api";
import { UserUpdateForm } from "./components/UserUpdateForm";
import { fetchOrganizationsAsync, selectOrganizations } from "../organizations/organizationsSlice";

export const UsersComponent: React.FC = () => {
  const [permittedRoles, setPermittedRoles] = useState<DefaultOptionType[]>([]);
  const [editUser, setEditUser] = useState<User | null>();
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  const users = useAppSelector(selectUsers);
  const authUser = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();
  const organizations = useAppSelector(selectOrganizations);

  useEffect(() => {
    dispatch(fetchUsersAsync());
  }, [dispatch]);

  useEffect(() => {
    if (authUser) {
      const roles = getPermittedRolesToCreateUser(authUser.roles[0]);
      setPermittedRoles(roles);
    }
  }, [authUser]);

  useEffect(() => {
    dispatch(fetchOrganizationsAsync());
  }, [dispatch]);

  const onClickEditButton = (user: User) => {
    setVisibleUpdate(true);
    setEditUser(user);
  };

  const onCreateUser = (user: SignUpDto) => {
    dispatch(createUserAsync(user)).then((res: any) => {
      if (!res.error) {
        setVisibleCreate(false);
      }
    });
  };

  const onDeleteUser = async (id: string) => {
    dispatch(removeUserAsync(id));
  };

  const onUpdateUser = (userUpdateData: UpdateUserDto, userId: string) => {
    dispatch(updateUserAsync({ user: userUpdateData, id: userId })).then((res: any) => {
      if (!res.error) {
        setVisibleUpdate(false);
      }
    });
  };

  const addButton = (
    <div className="border-box w-full h-full p-4">
      <Button onClick={() => setVisibleCreate(true)} className="w-full h-full text-lg" type="dashed">
        + Добавить
      </Button>
    </div>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg overflow-scroll">
      <Title className="ml-4 !my-0" level={4}>
        Количество: {users.length}
      </Title>
      <UserForm roles={permittedRoles} organizations={organizations.map((org) => ({ value: org.id, label: org.name }))} visible={visibleCreate} setVisible={setVisibleCreate} onCreateUser={onCreateUser} />
      {editUser && visibleUpdate && (
        <UserUpdateForm user={editUser} roles={permittedRoles} organizations={organizations.map((org) => ({ value: org.id, label: org.name }))} visible={visibleUpdate} setVisible={setVisibleUpdate} onUpdateUser={onUpdateUser} />
      )}
      <List
        grid={{ column: 3 }}
        dataSource={[0, ...users]}
        renderItem={(user) =>
          typeof user === "number" ? addButton : <UsersListItem user={user} onEdit={() => { setEditUser(null); onClickEditButton(user); }} onDelete={onDeleteUser} />
        }
      />
    </Layout>
  );
};
