import { Form, Modal } from "antd";
import React from "react";
import { CreateSoilJournalDto } from "../../../api";
import { SoilJournalsColumns } from "../form/columns";

interface SoilJournalsFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateSoilJournal: (values: CreateSoilJournalDto) => void;
  organizationId?: string;
}

export const SoilJournalForm: React.FC<SoilJournalsFormProps> = ({ visible, setVisible, onCreateSoilJournal, organizationId }) => {
  const [form] = Form.useForm<CreateSoilJournalDto>();

  const onChange = (value: any, additional?: any) => {

    (Object.keys(form.getFieldsValue()) as (keyof CreateSoilJournalDto)[]).find((key) => {
      return form.getFieldsValue()[key] === value;
    });

  }

  return (
    <Modal width="60%" open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateSoilJournal}>
        {SoilJournalsColumns().map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
            {
              React.cloneElement(
                inputNode,
                {
                  onChange: onChange
                }
              )
            }
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
