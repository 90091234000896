import { Checkbox, Col, Divider, Form, Input, Layout, Modal, Row } from "antd";
import React, { ChangeEvent, useEffect } from "react";
import { CalculationInput, CreateCalculationDto, CreateCleaningEquipmentDto, PollutionSource } from "../../../api";
import { calculationsColumns } from "../form/columns";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch } from "../../../app/hooks";
import { updateCalculationInputAsync } from "../calculationsSlice";
import { debounce } from "lodash";
import Text from "antd/es/typography/Text";

interface CalculationInputFormProps {
  inputs: any;
  disabled: boolean;
}

export const CalculationInputForm: React.FC<CalculationInputFormProps> = ({ inputs, disabled }) => {
  const dispatch = useAppDispatch();
  const onChange = (valueId: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) dispatch(updateCalculationInputAsync({ calculationInputId: valueId, value: parseFloat(value) }));
  };

  if (!inputs) {
    <div>Невозможно рассчитать</div>;
  }

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {Object.keys(inputs).map((pollutionSourceName: any) => {
        const pollutionSource = inputs[pollutionSourceName];
        return (
          <div>
            <Divider orientation="left" orientationMargin={0}>Источник выбросов: {pollutionSourceName}</Divider>

            {Object.keys(pollutionSource).map((selectionSourceName: any) => {
              const selectionSource = pollutionSource[selectionSourceName];
              return (
                <div className="">
                  <Divider orientation="left">Источник выделения: {selectionSourceName}</Divider>
                  <Form layout="inline" className="ml-14">
                    {selectionSource.map((obj: CalculationInput) => (
                      <Form.Item className="mt-2 ml-4" label={obj.incomingParameter.name}>
                        {/* <Input onChange={debounce(onChange(obj.id), 1000)} disabled={disabled} type="number" defaultValue={obj.value} /> */}
                        {obj.value ? <Text keyboard><b>{obj.value}</b></Text> : <Text keyboard><b>-</b></Text>}
                      </Form.Item>
                    ))}
                  </Form>
                </div>
              );
            })}
          </div>
        );
      })}
    </Layout>
  );
};
