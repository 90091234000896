import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { SignUpDto } from "../../../api";
import { DefaultOptionType } from "antd/es/select";

interface UserFormProps {
  roles: DefaultOptionType[];
  organizations?: DefaultOptionType[];
  visible: boolean;
  setVisible: Function;
  onCreateUser: (values: SignUpDto) => void;
}

export const UserForm: React.FC<UserFormProps> = ({ roles, organizations, visible, setVisible, onCreateUser }) => {
  const [form] = Form.useForm<SignUpDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateUser}>
        <Form.Item label="Имя" name="name" rules={[{ required: true, message: "Заполните поле" }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Заполните поле" },
            { type: "email", message: "Неправильный формат почты" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            { required: true, message: "Заполните поле" },
            { message: "Минимум 8 знаков", min: 8 },
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item label="Отделы" name="roles" rules={[{ required: true, message: "Выберите отделы" }]}>
          <Select mode="multiple" placeholder="Выберите отделы" options={roles} />
        </Form.Item>

        <Form.Item label="Телефон" name="phone">
          <Input prefix={"+7"} maxLength={10} />
        </Form.Item>

        <Form.Item label="Филиал" name="organizationIds">
          <Select mode="multiple" placeholder="Выберите филиалы" options={organizations} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
