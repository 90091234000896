import { Button, Form, Input, Layout, Table } from "antd";
import { ChangeEvent, useEffect } from "react";
import { Organization } from "../../../../api";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchCarbonStationarySourceControllerFindByOrganizationIdYearQuarterAsync, selectCarbonStationarySources, updateCarbonStationarySourceControllerInputAsync } from "../CarbonStationarySourceSlice";
import { debounce } from "lodash";
import { BASE_PATH } from "../../../../api/base";

interface CarbonStationarySourceProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const CarbonStationarySource: React.FC<CarbonStationarySourceProps> = ({ organization, year, quarter }) => {
  const dispatch = useAppDispatch();
  const сarbonStationarySources = useAppSelector(selectCarbonStationarySources);

  const [form] = Form.useForm();

  useEffect(() => {
    if (organization && year && quarter) {
      dispatch(fetchCarbonStationarySourceControllerFindByOrganizationIdYearQuarterAsync({ organizationId: organization.id, year, quarter }));
    }
  }, [organization, year, quarter, dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      'diesel': сarbonStationarySources?.diesel?.quantity,
      'coal': сarbonStationarySources?.coal?.quantity,
    });
  }, [сarbonStationarySources]);

  const onChange = (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value && year && quarter && organization) {
      const { value } = e.target;
      dispatch(updateCarbonStationarySourceControllerInputAsync({
        type, updateCarbonStationarySourceDto: {
          value: parseFloat(value),
          year: year,
          quarter: quarter,
          organizationId: organization.id,
        }
      }));
    }
  };
  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Form form={form}>
        <Table scroll={{ x: "max-content" }}
          columns={[
            {
              title: 'Вид установки',
              dataIndex: 'type',
              key: 'type',
            },
            {
              title: 'Количество, т',
              dataIndex: 'quantity',
              key: 'quantity',
            },
            {
              title: '"Выброс СО2 (тСО2)"',
              dataIndex: 'co2',
              key: 'co2',
            },
            {
              title: 'Выброс СН4 (тСН4)',
              dataIndex: 'ch4',
              key: 'ch4',
            },
            {
              title: 'Выброс N2O (тN2O)',
              dataIndex: 'n2o',
              key: 'n2o',
            },
            {
              title: '"Количество выбросов (т СО2е)"',
              dataIndex: 'co2e',
              key: 'co2e',
            },
          ]}
          dataSource={
            [
              {
                type: 'ДЭС (ДТ)',
                // quantity: сarbonStationarySources?.diesel?.quantity,
                quantity:
                  <Form.Item name="diesel">
                    <Input name="diesel" type="number" onChange={debounce(onChange('diesel'), 1000)} style={{ width: '100%' }} />
                  </Form.Item>,
                co2: сarbonStationarySources?.diesel?.co2,
                ch4: сarbonStationarySources?.diesel?.ch4,
                n2o: сarbonStationarySources?.diesel?.n2o,
                co2e: сarbonStationarySources?.diesel?.co2e,
              },
              {
                type: 'Котлы и печи (уголь)',
                // quantity: сarbonStationarySources?.coal?.quantity,
                quantity:
                  <Form.Item name="coal">
                    <Input name="coal" type="number" onChange={debounce(onChange('coal'), 1000)} style={{ width: '100%' }} />
                  </Form.Item>,
                co2: сarbonStationarySources?.coal?.co2,
                ch4: сarbonStationarySources?.coal?.ch4,
                n2o: сarbonStationarySources?.coal?.n2o,
                co2e: сarbonStationarySources?.coal?.co2e,
              },
              {
                type: 'Всего',
                quantity: сarbonStationarySources?.diesel?.quantity + сarbonStationarySources?.coal?.quantity,
                co2: сarbonStationarySources?.diesel?.co2 + сarbonStationarySources?.coal?.co2,
                ch4: сarbonStationarySources?.diesel?.ch4 + сarbonStationarySources?.coal?.ch4,
                n2o: сarbonStationarySources?.diesel?.n2o + сarbonStationarySources?.coal?.n2o,
                co2e: сarbonStationarySources?.diesel?.co2e + сarbonStationarySources?.coal?.co2e,
              },
            ]
          }
        />
      </Form>
      <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/carbon-stationary-source/report/organization/${organization?.id || ""}/year/${year}/quarter/${quarter}`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
    </Layout>
  );
};
