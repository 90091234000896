import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { CarbonContractWork } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { EntityColumns } from "../../../../app/types";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";

export const CarbonContractWorkColumns = (): EntityColumns<CarbonContractWork>[] => {
  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Вид работ",
      dataIndex: "carbonContractWorkType",
      key: "carbonContractWorkType",
      inputNode: <Select
        options={[
          {
            value: 'rock',
            label: 'БВР (руда/порода)',
          },
          {
            value: 'explosion',
            label: 'БВР (контурное взрывание)'
          },
          {
            value: 'rc',
            label: 'Бурение RC скважин методом обратной продувки'
          },
          {
            value: 'wells',
            label: 'Бурение колонковых скважин'
          }
        ]} />,
      render: (obj) => {
        let label = '';
        if (obj === 'rock') {
          label = 'БВР (руда/порода)';
        }
        if (obj === 'explosion') {
          label = 'БВР (контурное взрывание)';
        }
        if (obj === 'rc') {
          label = 'Бурение RC скважин методом обратной продувки';
        }
        if (obj === 'wells') {
          label = 'Бурение колонковых скважин';
        }
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "ед.изм",
      dataIndex: "unit",
      key: "unit",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Объем выполнения",
      dataIndex: "volume",
      key: "volume",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вид топлива",
      dataIndex: "fuelType",
      key: "fuelType",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Расход дизельного топлива (т)",
      dataIndex: "consumption",
      key: "consumption",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Выброс СО2 (тСО2)",
      dataIndex: "co2",
      key: "co2",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Выброс СН4 (тСН4)",
      dataIndex: "ch4",
      key: "ch4",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Выброс N2O (тN2O)",
      dataIndex: "n2o",
      key: "n2o",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Количество выбросов (т СО2е)",
      dataIndex: "co2e",
      key: "co2e",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
