import { Button, Layout, Modal, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterPointDto, WaterPoint, UpdateWaterPointDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WaterPointPollutantsComponent } from "../waterPointPollutants/WaterPointPollutantsComponent";
import { WaterPointForm } from "./components/WaterPointForm";
import { waterPointsColumns } from "./form/columns";
import { selectWaterPoints, updateWaterPointAsync, createWaterPointAsync, deleteWaterPointAsync, fetchWaterPointsByOrganizationidAsync, copyWaterPointAsync } from "./WaterPointsSlice";
import { CopyOutlined } from '@ant-design/icons';


interface WaterPointsComponentProps {
  organizationId?: string;
}

export const WaterPointsComponent: React.FC<WaterPointsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const waterPoints = useAppSelector(selectWaterPoints);

  const [visible, setVisible] = useState(false);
  const [point, setPoint] = useState<WaterPoint | undefined>(undefined);


  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWaterPointsByOrganizationidAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateWaterPoint = async (waterPoint: UpdateWaterPointDto, id: string) => {
    return dispatch(updateWaterPointAsync({ waterPoint, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterPoint = (waterPoint: CreateWaterPointDto) => {
    if (!organizationId) return;

    dispatch(createWaterPointAsync({ ...waterPoint, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterPoint = (waterPoint: WaterPoint) => {
    return dispatch(deleteWaterPointAsync({ waterPoint })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const copy = async (id: string) => {
    return dispatch(copyWaterPointAsync({ id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить точку
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterPointForm visible={visible} setVisible={setVisible} onCreateWaterPoint={onCreateWaterPoint} />}
      <EditableTable<WaterPoint, UpdateWaterPointDto>
        onSave={onUpdateWaterPoint}
        onDelete={deleteWaterPoint}
        entityColumns={
          [ {
              title: "",
              dataIndex: "btnc" as keyof WaterPoint,
              key: "btnc",
              inputNode: <></>,
              render: (value: any, record: WaterPoint, index: any) =>
                <Button.Group>
                  <Button icon={<CopyOutlined />} onClick={() => { copy(record.id) }}></Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },
            ...waterPointsColumns, 
            {
              title: "Данные",
              dataIndex: "data" as keyof WaterPoint,
              key: "data",
              inputNode: <></>,
              render: (value: any, record: WaterPoint, index: any) =>
                <Button.Group>
                  <Button onClick={() => { setPoint(record) }}>Загрязнения</Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },]
        }
        dataSource={waterPoints}
        footer={() => addButton}
      />
      {point && (
        <Modal
          open={true}
          title={`Загрязнения - ${point?.name}`}
          onCancel={() => setPoint(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"85%"}
        >
          <WaterPointPollutantsComponent pointId={point.id} />
        </Modal>
      )}
    </Layout>
  );
};
