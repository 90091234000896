import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { IndustrialSite, Category } from "../../../api";
import { EntityColumns } from "../../../app/types";
import { useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";
import { UserRole, UserRoles, getPermittedRolesToCreateUser } from "../../../app/role";
import { selectAuthUser } from "../../auth/authSlice";
import { DefaultOptionType } from "antd/es/select";

export const CategoryColumns = (): EntityColumns<Category>[] => {

  const authUser = useAppSelector(selectAuthUser);
  let roles: DefaultOptionType[] = [];
  if (authUser) {
    roles = getPermittedRolesToCreateUser(authUser.roles[0]);
  }

  return [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Отдел",
      dataIndex: "role",
      key: "role",
      inputNode: <Select placeholder="Выберите отдел" options={roles} />,
      render: (role) => (
        <Text className="w-64" ellipsis={{ tooltip: UserRoles[role as UserRole] }}>
          {UserRoles[role as UserRole]}
        </Text>
      ),
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => (
        <Text className="w-64" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      editable: true,
      copyable: true,
    },
  ];
};
