import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteBurialSiteDto, WasteBurialSitesApi, UpdateWasteBurialSiteDto, WasteBurialSite } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteBurialSitesState {
  wasteBurialSites: WasteBurialSite[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteBurialSitesState = {
  wasteBurialSites: [],
  status: "idle",
};

export const createWasteBurialSiteAsync = createAsyncThunk(
  "wasteBurialSites/create",
  async (wasteBurialSite: CreateWasteBurialSiteDto, { rejectWithValue }) => {
    try {
      const wasteBurialSitesApi = new WasteBurialSitesApi();
      const response = await wasteBurialSitesApi.wasteBurialSitesControllerCreate(wasteBurialSite);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteBurialSiteAsync = createAsyncThunk(
  "wasteBurialSites/update",
  async ({ wasteBurialSite, id }: { wasteBurialSite: UpdateWasteBurialSiteDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteBurialSitesApi = new WasteBurialSitesApi();
      const response = await wasteBurialSitesApi.wasteBurialSitesControllerUpdate(wasteBurialSite, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteBurialSiteAsync = createAsyncThunk(
  "wasteBurialSites/remove",
  async ({ wasteBurialSite }: { wasteBurialSite: WasteBurialSite }, { rejectWithValue }) => {
    try {
      const wasteBurialSitesApi = new WasteBurialSitesApi();
      const response = await wasteBurialSitesApi.wasteBurialSitesControllerRemove(wasteBurialSite.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteBurialSitesByOrganizationAsync = createAsyncThunk("wasteBurialSites/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteBurialSitesApi();
    const response = await wasteApi.wasteBurialSitesControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteBurialSitesByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWasteBurialSitesByOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteBurialSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialSitesState, action: PayloadAction<WasteBurialSite[]>) => {
        state.wasteBurialSites = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteBurialSite = () => {
    const { pending, fulfilled, rejected } = createWasteBurialSiteAsync;
    return {
      [`${pending}`]: (state: WasteBurialSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialSitesState, action: PayloadAction<WasteBurialSite>) => {
        state.wasteBurialSites.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteBurialSite = () => {
    const { pending, fulfilled, rejected } = updateWasteBurialSiteAsync;

    return {
      [`${pending}`]: (state: WasteBurialSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialSitesState, action: PayloadAction<WasteBurialSite>) => {
        state.wasteBurialSites = state.wasteBurialSites.map((wasteBurialSite) => (wasteBurialSite.id === action.payload.id ? action.payload : wasteBurialSite));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteBurialSite = () => {
    const { pending, fulfilled, rejected } = deleteWasteBurialSiteAsync;
    return {
      [`${pending}`]: (state: WasteBurialSitesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialSitesState, action: PayloadAction<WasteBurialSite>) => {
        state.wasteBurialSites.splice(state.wasteBurialSites.indexOf(
          state.wasteBurialSites.find((ws: WasteBurialSite) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialSitesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteBurialSitesByOrganization(), ...createWasteBurialSite(), ...updateWasteBurialSite(), ...removeWasteBurialSite() };
};

export const wasteBurialSitesSlice = createSlice({
  name: "wasteBurialSites",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteBurialSites = (state: RootState) => state.wasteBurialSites.wasteBurialSites;

export default wasteBurialSitesSlice.reducer;
