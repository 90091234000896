/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AirEmissionsJournal } from '../models';
import { CreateAirEmissionsJournalDto } from '../models';
import { UpdateAirEmissionsJournalDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * AirEmissionsJournalApi - axios parameter creator
 * @export
 */
export const AirEmissionsJournalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAirEmissionsJournalDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerCreate: async (body: CreateAirEmissionsJournalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling airEmissionsJournalControllerCreate.');
            }
            const localVarPath = `/air-emissions-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/air-emissions-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} airEmissionsPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerFindByAirEmissionsPointId: async (airEmissionsPointId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'airEmissionsPointId' is not null or undefined
            if (airEmissionsPointId === null || airEmissionsPointId === undefined) {
                throw new RequiredError('airEmissionsPointId','Required parameter airEmissionsPointId was null or undefined when calling airEmissionsJournalControllerFindByAirEmissionsPointId.');
            }
            const localVarPath = `/air-emissions-journal/airEmissionsPoint/{airEmissionsPointId}`
                .replace(`{${"airEmissionsPointId"}}`, encodeURIComponent(String(airEmissionsPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerFindByOrganizationid: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling airEmissionsJournalControllerFindByOrganizationid.');
            }
            const localVarPath = `/air-emissions-journal/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling airEmissionsJournalControllerFindOne.');
            }
            const localVarPath = `/air-emissions-journal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerGetAirEmissionsReport: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling airEmissionsJournalControllerGetAirEmissionsReport.');
            }
            const localVarPath = `/air-emissions-journal/report/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling airEmissionsJournalControllerRemove.');
            }
            const localVarPath = `/air-emissions-journal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAirEmissionsJournalDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        airEmissionsJournalControllerUpdate: async (body: UpdateAirEmissionsJournalDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling airEmissionsJournalControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling airEmissionsJournalControllerUpdate.');
            }
            const localVarPath = `/air-emissions-journal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AirEmissionsJournalApi - functional programming interface
 * @export
 */
export const AirEmissionsJournalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAirEmissionsJournalDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerCreate(body: CreateAirEmissionsJournalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsJournal>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AirEmissionsJournal>>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} airEmissionsPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindByAirEmissionsPointId(airEmissionsPointId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AirEmissionsJournal>>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerFindByAirEmissionsPointId(airEmissionsPointId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindByOrganizationid(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AirEmissionsJournal>>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerFindByOrganizationid(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsJournal>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerGetAirEmissionsReport(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerGetAirEmissionsReport(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsJournal>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateAirEmissionsJournalDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerUpdate(body: UpdateAirEmissionsJournalDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AirEmissionsJournal>>> {
            const localVarAxiosArgs = await AirEmissionsJournalApiAxiosParamCreator(configuration).airEmissionsJournalControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AirEmissionsJournalApi - factory interface
 * @export
 */
export const AirEmissionsJournalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAirEmissionsJournalDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerCreate(body: CreateAirEmissionsJournalDto, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsJournal>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AirEmissionsJournal>>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} airEmissionsPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindByAirEmissionsPointId(airEmissionsPointId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AirEmissionsJournal>>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerFindByAirEmissionsPointId(airEmissionsPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindByOrganizationid(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AirEmissionsJournal>>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerFindByOrganizationid(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsJournal>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerGetAirEmissionsReport(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerGetAirEmissionsReport(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsJournal>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAirEmissionsJournalDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async airEmissionsJournalControllerUpdate(body: UpdateAirEmissionsJournalDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AirEmissionsJournal>> {
            return AirEmissionsJournalApiFp(configuration).airEmissionsJournalControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AirEmissionsJournalApi - object-oriented interface
 * @export
 * @class AirEmissionsJournalApi
 * @extends {BaseAPI}
 */
export class AirEmissionsJournalApi extends BaseAPI {
    /**
     * 
     * @param {CreateAirEmissionsJournalDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerCreate(body: CreateAirEmissionsJournalDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsJournal>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AirEmissionsJournal>>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} airEmissionsPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerFindByAirEmissionsPointId(airEmissionsPointId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AirEmissionsJournal>>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerFindByAirEmissionsPointId(airEmissionsPointId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerFindByOrganizationid(organizationId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AirEmissionsJournal>>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerFindByOrganizationid(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsJournal>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerGetAirEmissionsReport(organizationId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerGetAirEmissionsReport(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsJournal>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateAirEmissionsJournalDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AirEmissionsJournalApi
     */
    public async airEmissionsJournalControllerUpdate(body: UpdateAirEmissionsJournalDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AirEmissionsJournal>> {
        return AirEmissionsJournalApiFp(this.configuration).airEmissionsJournalControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
