import { Button, Form, Input, Select, Space } from "antd";
import React, { useEffect } from "react";
import { CreateMethodicDto, Methodic, UpdateMethodicDto } from "../../../api";
import Title from "antd/es/typography/Title";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSubstancesInputOptions } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface MethodicFormProps {
  visible?: boolean;
  setVisible?: Function;
  onSaveMethodic: (values: CreateMethodicDto | UpdateMethodicDto) => void;
  selectionSourceId?: string;
  initialValues?: Partial<Methodic>;
  withoutSource?: boolean;
}

export const MethodicForm: React.FC<MethodicFormProps> = ({
  visible,
  setVisible,
  onSaveMethodic,
  selectionSourceId,
  initialValues,
  withoutSource = false,
}) => {
  const [form] = Form.useForm<CreateMethodicDto>();

  const pollutionSubstances = useAppSelector(selectPollutionSubstancesInputOptions);

  useEffect(() => {
    form.setFieldsValue(initialValues || {});
  }, [initialValues, form]);

  return (
    // <Modal width={"80%"} open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
    <Form form={form} layout="vertical" onFinish={onSaveMethodic}>
      <Title level={5}>Название методики расчета</Title>
      <Form.Item name={"name"} rules={[{ required: true, message: "Введите название" }]}>
        <Input placeholder="Название методики" />
      </Form.Item>

      <Title level={5}>Входные параметры</Title>
      <div className="px-4 py-2">
        <Form.List name="incomingParameters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div>
                  <Space key={key}>
                    <Form.Item name={[name, "name"]} rules={[{ required: true, message: "Введите название" }]}>
                      <Input placeholder="Название" />
                    </Form.Item>
                    <Form.Item name={[name, "code"]} rules={[{ required: true, message: "Введите код" }]}>
                      <Input placeholder="Код в формуле" />
                    </Form.Item>
                    <MinusCircleOutlined className="pb-6" onClick={() => remove(name)} />
                  </Space>
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить входящий параметр
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>

      <Title level={5}>Cтатичные параметры</Title>
      <div className="px-4 py-2">
        <Form.List name="staticParameters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div>
                  <Space key={key}>
                    <Form.Item name={[name, "name"]} rules={[{ required: true, message: "Введите название" }]}>
                      <Input placeholder="Название" />
                    </Form.Item>
                    <Form.Item name={[name, "code"]} rules={[{ required: true, message: "Введите код" }]}>
                      <Input placeholder="Код в формуле" />
                    </Form.Item>

                    {withoutSource || (
                      <Form.Item name={[name, "value"]} rules={[{ required: true, message: "Введите значение" }]}>
                        <Input placeholder="Значение" />
                      </Form.Item>
                    )}
                    <MinusCircleOutlined className="pb-6" onClick={() => remove(name)} />
                  </Space>
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить статичный параметр
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>

      <Title level={5}>Загрязняющие вещества</Title>
      <div className="px-4 py-2">
        <Form.List name="expressions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div>
                  <Space key={key}>
                    <Form.Item name={[name, "pollutionSubstanceCode"]} rules={[{ required: true, message: "Выберите вещество" }]}>
                      <Select
                        style={{ width: '180px' }}
                        showSearch
                        filterOption={(input, option: any) => {
                          if (option) {
                            return (
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                          } else {
                            return true;
                          }
                        }}
                        placeholder={"Выберите код загрезняющего вещества"}
                        options={pollutionSubstances}
                      />
                    </Form.Item>
                    <Form.Item name={[name, "gramPerSecFormula"]} rules={[{ required: true, message: "Введит формулу г/с" }]}>
                      <Input placeholder="Расчет г/с" />
                    </Form.Item>
                    <Form.Item name={[name, "tonPerYearFormula"]} rules={[{ required: true, message: "Введит формулу т/г" }]}>
                      <Input placeholder="Расчет т/г" />
                    </Form.Item>
                    <MinusCircleOutlined className="pb-6" onClick={() => remove(name)} />
                  </Space>
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить вещество
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>

      <Button htmlType="submit" type="primary" block>
        Сохранить
      </Button>
    </Form>
    // </Modal>
  );
};
