import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFromStorage, serializeError, setLocalStorage } from "../../app/helpers";
import { AxiosError } from "axios";
import { RootState } from "../../app/store";
import message from "antd/es/message";

const quarter = getFromStorage("quarter", 1);
const year = getFromStorage("year", new Date().getFullYear());

export interface Period {
  quarter: number,
  year: number,
}

export interface OrganizationModuleState {
  period: Period;
  status: "idle" | "loading" | "failed";
  error?: any;
}

const initialState: OrganizationModuleState = {
  period: {
    quarter: quarter,
    year: year,
  },
  status: "idle",
};


export const periodAsync = createAsyncThunk(
  'organizationModule/period',
  async () => {
    try {
      const response = {
        quarter: getFromStorage("quarter"),
        year: getFromStorage("year")
      };
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setPeriodAsync = createAsyncThunk(
  'organizationModule/setPeriod',
  async (period: Period, { rejectWithValue }) => {
    try {
      return period;
    } catch (error) {
      return rejectWithValue(error);
    }
  });


const createExtraReducers = () => {

  const period = () => {
    const { pending, fulfilled, rejected } = periodAsync;
    return {
      [`${pending}`]: (state: OrganizationModuleState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: OrganizationModuleState, action: PayloadAction<Period>) => {
        state.period = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: OrganizationModuleState, action: PayloadAction<AxiosError>) => {
        const error = action.payload;

        state = {
          period: {
            quarter: getFromStorage("quarter", 1),
            year: getFromStorage("year", new Date().getFullYear()),
          }, status: "failed", error
        };
        return state;
      },
    };
  };

  const setPeriod = () => {
    const { pending, fulfilled, rejected } = setPeriodAsync;
    return {
      [`${pending}`]: (state: OrganizationModuleState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: OrganizationModuleState, action: PayloadAction<Period>) => {
        if (action.payload.quarter) setLocalStorage("quarter", action.payload.quarter);
        if (action.payload.year) setLocalStorage("year", action.payload.year);
        state.period = action.payload;
        message.success("Период успешно установлен")
        state.status = "idle";
      },
      [`${rejected}`]: (state: OrganizationModuleState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка!")
        state.error = error;
        return state;
      },
    };
  };

  return {
    ...period(),
    ...setPeriod(),
  };
};

export const selectOrganizationModulePeriod = (state: RootState) => state.organizationModule.period;

export const organizationModuleSlice = createSlice({
  name: "organizationModule",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export default organizationModuleSlice.reducer;
function rejectWithValue(error: unknown): any {
  throw new Error("Function not implemented.");
}

