import { Avatar, Button, Card, Divider } from "antd";
import Text from "antd/es/typography/Text";
import React from "react";
import { User } from "../../../api";
import moment from "moment";

interface PersonalInformationProps {
  user: User;
  onEdit: (user: User) => void;
  onDelete: (id: string) => void;
}

export const UsersListItem: React.FC<PersonalInformationProps> = ({ user, onEdit, onDelete }) => {
  return (
    <Card type="inner" className="max-w-lg m-4">
      <div className="flex justify-between">
        <Avatar size={100} className="border-solid border-2 border-gray-400" src="https://joeschmoe.io/api/v1/random" />
        <div className="grid grid-cols-3 w-2/3">
          <Text className="text-gray-500 col-span-1">Имя: </Text>
          <Text className="font-semibold col-span-2">{user.name}</Text>

          <Text className="text-gray-500 col-span-1">Email: </Text>
          <Text className="font-semibold col-span-2">{user.email}</Text>

          <Text className="text-gray-500 col-span-1">Телефон: </Text>
          <Text className="font-semibold col-span-2">{user.phone}</Text>

          <Text className="text-gray-500 col-span-1">Отдел: </Text>
          <Text className="font-semibold col-span-2">{user.roles}</Text>

          <Text className="text-gray-500 col-span-1">Создано: </Text>
          <Text className="font-semibold col-span-2">{moment(user.createdAt).format("L")}</Text>

          <Text className="text-gray-500 col-span-1">Изменено: </Text>
          <Text className="font-semibold col-span-2">{moment(user.updatedAt).format("L")}</Text>
        </div>
      </div>
      <Divider />

      <div className="w-full flex justify-around items-center">
        <Button type="text" onClick={() => onEdit(user)}>
          Изменить
        </Button>
        <Divider type="vertical" />
        <Button type="text" danger onClick={() => onDelete(user.id)}>
          Удалить
        </Button>
      </div>
    </Card>
  );
};
