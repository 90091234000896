import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteEquipmentSortDto } from "../../../api";
import { wasteEquipmentSortsColumns } from "../form/columns";

interface WasteEquipmentSortFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteEquipmentSort: (values: CreateWasteEquipmentSortDto) => void;
}

export const WasteEquipmentSortForm: React.FC<WasteEquipmentSortFormProps> = ({ visible, setVisible, onCreateWasteEquipmentSort }) => {
  const [form] = Form.useForm<CreateWasteEquipmentSortDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteEquipmentSort}>
        {wasteEquipmentSortsColumns.map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
