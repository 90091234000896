import { BellOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Row, Avatar, Typography, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { User } from "../../api";
import { logOut } from "../../features/auth/authSlice";

interface HeaderProps {
  authUser?: User;
};

const Header: React.FC<HeaderProps> = ({ authUser }) => {

  const dispatch = useDispatch()

  return (
    <Row justify="end" align={"middle"} className="grow h-fit">
      <Row className="w-max" justify="space-around" align="middle">
        <Tooltip title="Уведомления">
          <Button shape="circle" size="large" className="m-2">
            <BellOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Выйти">
          <Button shape="circle" size="large" danger className="m-2" onClick={() => dispatch(logOut())}>
            <LogoutOutlined />
          </Button>
        </Tooltip>
        <Avatar className="m-2" size={"large"} src="https://joeschmoe.io/api/v1/random" />
        <Link to="/profile" className="m-2 mr-8">
          <Typography.Title className="!my-0" level={5}>
            {authUser?.name}
          </Typography.Title>
          <Typography.Text>{authUser?.email}</Typography.Text>
        </Link>
      </Row>
    </Row>
  );
};

export default Header;
