import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateMethodicDto, Methodic, SaveStaticParameterValuesDto, UpdateMethodicDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";
import { MethodicsApi } from "../../api/apis/methodics-api";

export interface MethodicsState {
  methodics: Methodic[];
  sourceMethodic: Methodic | undefined;
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: MethodicsState = {
  methodics: [],
  sourceMethodic: undefined,
  status: "idle",
};

export const fetchMethodicsAsync = createAsyncThunk("methodic/fetch", async (_, { rejectWithValue }) => {
  try {
    const methodicsApi = new MethodicsApi();
    const response = await methodicsApi.methodicsControllerFindAll();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchSourceMethodicAsync = createAsyncThunk("methodic/fetchSource", async (selectionSourceId: string, { rejectWithValue }) => {
  try {
    const methodicsApi = new MethodicsApi();
    const response = await methodicsApi.methodicsControllerFindBySelectionSource(selectionSourceId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createMethodicAsync = createAsyncThunk("methodic/create", async (methodic: CreateMethodicDto, { rejectWithValue }) => {
  try {
    const methodicsApi = new MethodicsApi();
    const response = await methodicsApi.methodicsControllerCreate(methodic);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateMethodicAsync = createAsyncThunk("methodic/update", async ({ id, methodic }: { id: string, methodic: UpdateMethodicDto }, { rejectWithValue }) => {
  try {
    const methodicsApi = new MethodicsApi();
    const response = await methodicsApi.methodicsControllerUpdate(methodic, id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const saveSaveStaticParameterValuesAsync = createAsyncThunk("static-parameter-values/save", async ({ staticParameterValues, selectionSourceId }: { staticParameterValues: SaveStaticParameterValuesDto[], selectionSourceId: string }, { rejectWithValue }) => {
  try {
    const methodicsApi = new MethodicsApi();
    const response = await methodicsApi.methodicsControllerSaveStaticParameterValues(staticParameterValues, selectionSourceId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteMethodicAsync = createAsyncThunk(
  "methodic/remove",
  async ({ methodic }: { methodic: Methodic }, { rejectWithValue }) => {
    try {
      const methodicsApi = new MethodicsApi();
      const response = await methodicsApi.methodicsControllerRemove(methodic.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchMethodics = () => {
    const { pending, fulfilled, rejected } = fetchMethodicsAsync;
    return {
      [`${pending}`]: (state: MethodicsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MethodicsState, action: PayloadAction<Methodic[]>) => {
        state.methodics = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: MethodicsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const removeMethodic = () => {
    const { pending, fulfilled, rejected } = deleteMethodicAsync;
    return {
      [`${pending}`]: (state: MethodicsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MethodicsState, action: PayloadAction<Methodic>) => {
        console.log('state.methodics', state.methodics);
        console.log('action.payload', action.payload);
        console.log('index', state.methodics.indexOf(
          state.methodics.find((ws: Methodic) => ws.id === action.payload.id) || action.payload
        ));
        state.methodics.splice(state.methodics.indexOf(
          state.methodics.find((ws: Methodic) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MethodicsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const fetchSourceMethodic = () => {
    const { pending, fulfilled, rejected } = fetchSourceMethodicAsync;
    return {
      [`${pending}`]: (state: MethodicsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MethodicsState, action: PayloadAction<Methodic[]>) => {
        state.sourceMethodic = action.payload[0];
        state.status = "idle";
      },
      [`${rejected}`]: (state: MethodicsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createMethodic = () => {
    const { pending, fulfilled, rejected } = createMethodicAsync;
    return {
      [`${pending}`]: (state: MethodicsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MethodicsState, action: PayloadAction<Methodic>) => {
        state.methodics.push(action.payload);
        state.sourceMethodic = action.payload;
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MethodicsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateMethodic = () => {
    const { pending, fulfilled, rejected } = updateMethodicAsync;

    return {
      [`${pending}`]: (state: MethodicsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MethodicsState, action: PayloadAction<Methodic>) => {
        state.methodics = state.methodics.map((methodic) => (methodic.id === action.payload.id ? action.payload : methodic));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MethodicsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const saveSaveStaticParameterValues = () => {
    const { pending, fulfilled, rejected } = saveSaveStaticParameterValuesAsync;
    return {
      [`${pending}`]: (state: MethodicsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MethodicsState, action: PayloadAction<Methodic[]>) => {
        state.sourceMethodic = action.payload[0];
        message.success("Данные успешно сохранены");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MethodicsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchMethodics(), ...removeMethodic(), ...createMethodic(), ...fetchSourceMethodic(), ...saveSaveStaticParameterValues(), ...updateMethodic() };
};

export const methodicSlice = createSlice({
  name: "methodic",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectSourceMethodic = (state: RootState) => state.methodic.sourceMethodic;
export const selectMethodics = (state: RootState) => state.methodic.methodics;
export const selectMethodicsStatus = (state: RootState) => state.methodic.status;

export default methodicSlice.reducer;
