import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { IndustrialSite } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const industrialSitesColumns: EntityColumns<IndustrialSite>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Широта",
    dataIndex: "latitude",
    inputNode: <Input placeholder="48.0854" />,
    rules: [{ required: true, message: "Широта неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },],
    editable: true,
    copyable: true,
  },
  {
    title: "Долгота",
    dataIndex: "longitude",
    inputNode: <Input placeholder="48.0854" />,
    rules: [{ required: true, message: "Долгота неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },],
    editable: true,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
];
