import { Modal, Layout, Upload, Button, UploadFile } from "antd";
import { BASE_PATH } from "../../../api/base";
import { getFromStorage } from "../../../app/helpers";
import { useEffect, useState } from "react";
import { FileEntity } from "../../../api/models/file-entity";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteFileAsync, selectFileEntities } from "../FilesEntitySlice";
import { UploadOutlined } from '@ant-design/icons';

interface FileFormProps {
    visible: boolean;
    setVisible: Function;
    onUpdate: (dto: any, id: string) => Promise<boolean>;
    fileIds?: string[],
    id?: string,
    maxCount?: number
}

export const FileForm: React.FC<FileFormProps> = ({ visible, setVisible, onUpdate, fileIds, id, maxCount = 3 }) => {
    const dispatch = useAppDispatch();

    let fetchedFiles: FileEntity[] = useAppSelector(selectFileEntities)
    const [fileList, setFileList] = useState<any[]>([]);

    useEffect(() => {
        if (visible && fileIds) {
            if (fileIds.length > 0) {
                const preparedFileList = fetchedFiles.map((file) => ({
                    uid: file.id,
                    name: file.fileName,
                    status: "done",
                    url: `${BASE_PATH}/files/download/${file.id}`,
                }));
                setFileList(preparedFileList);

            }
        }
    }, [visible, fileIds, dispatch, fetchedFiles]);

    const deleteFile = (id: string) => {
        return dispatch(deleteFileAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }

    const [currentFileId, setCurrenFileId] = useState<string>();

    const onOk = () => {
        if (id && currentFileId) {
            if (fileIds) {
                let temp = [...fileIds];
                temp.push(currentFileId)
                onUpdate({ fileIds: temp, fileId: currentFileId }, id)
            } else {
                onUpdate({ fileIds: [currentFileId], fileId: currentFileId }, id)
            }
        }
        setVisible(false)
    }

    const handleRemove = (file: UploadFile) => {
        if (fileIds && id && file.uid) {
            const fileId = file.response?.id ?? file.uid;
            const updatedFileIds = fileIds.filter((id) => id !== fileId);
            onUpdate({ fileIds: updatedFileIds, fileId: null }, id).then((res: boolean) => {
                if (res) {
                    // File deletion successful, update the fileList state
                    const updatedFileList = fileList.filter((f) => f.uid !== fileId);
                    deleteFile(fileId)
                    setFileList(updatedFileList);
                }
            });
        }
    };

    const defaultFileList: UploadFile<any>[] = fetchedFiles.map((file) => {
        const isFileIdPresent = fileIds?.includes(file.id);

        if (isFileIdPresent) {
            return {
                uid: file.id, // Unique ID for each file
                name: file.fileName, // Name of the file to be displayed
                status: "done", // You can set the status as "done" for already uploaded files
                url: `${BASE_PATH}/files/download/${file.id}`, // URL to download the file
            };
        }
        return null; // Return null for non-existent files
    }).filter(Boolean) as UploadFile<any>[];

    return <Modal
        open={visible}
        title={`Файлы`}
        onCancel={() => { setVisible(false) }}
        onOk={onOk}
        okText="Сохранить"
        cancelText="Закрыть"
        width={"85%"}
    >
        <Layout className="bg-white p-4 rounded-lg">
            <Upload
                action={`${BASE_PATH}/files/`}
                listType="picture"
                maxCount={maxCount}
                headers={{
                    Authorization: 'Bearer ' + getFromStorage("token"),
                }}
                defaultFileList={defaultFileList}
                onRemove={handleRemove}
                onChange={(info) => {
                    if (info.file.status === "done") {
                        console.log(info.file.response.id)
                        setCurrenFileId(info.file.response?.id)
                    }
                }}
            >
                <Button icon={<UploadOutlined />}>Загрузить (Max: {maxCount})</Button>
            </Upload>
        </Layout>
    </Modal>
}