import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterPointPollutantDto, WaterPointPollutant, UpdateWaterPointPollutantDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WaterPointPollutantForm } from "./components/WaterPointPollutantForm";
import { waterPointPollutantsColumns } from "./form/columns";
import { createWaterPointPollutantAsync, updateWaterPointPollutantAsync, selectWaterPointPollutants, deleteWaterPointPollutantAsync, fetchWaterPointPollutantsByPointAsync } from "./WaterPointPollutantsSlice";

interface WaterPointPollutantsComponentProps {
  pointId?: string;
}

export const WaterPointPollutantsComponent: React.FC<WaterPointPollutantsComponentProps> = ({ pointId }) => {
  const dispatch = useAppDispatch();
  const waterPointPollutants = useAppSelector(selectWaterPointPollutants);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (pointId) {
      dispatch(fetchWaterPointPollutantsByPointAsync(pointId));
    }
  }, [pointId, dispatch]);

  const onUpdate = async (waterPointPollutant: UpdateWaterPointPollutantDto, id: string) => {
    return dispatch(updateWaterPointPollutantAsync({ waterPointPollutant, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterPointPollutant = (waterPointPollutant: CreateWaterPointPollutantDto) => {
    if (!pointId) return;

    dispatch(createWaterPointPollutantAsync({ ...waterPointPollutant, pointId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterPointPollutants = (waterPointPollutant: WaterPointPollutant) => {
    return dispatch(deleteWaterPointPollutantAsync({ waterPointPollutant })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить загрязнение
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterPointPollutantForm visible={visible} setVisible={setVisible} onCreateWaterPointPollutant={onCreateWaterPointPollutant} />}
      <EditableTable<WaterPointPollutant, UpdateWaterPointPollutantDto>
        onSave={onUpdate}
        onDelete={deleteWaterPointPollutants}
        entityColumns={waterPointPollutantsColumns}
        dataSource={waterPointPollutants}
        footer={() => addButton}
      />
    </Layout>
  );
};
