import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateOrganizationDto, Organization, OrganizationsApi, UpdateOrganizationDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface OrganizationState {
  organizations: Organization[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: OrganizationState = {
  organizations: [],
  status: "idle",
};

export const fetchOrganizationsAsync = createAsyncThunk("organizations/fetch", async (_, { rejectWithValue }) => {
  try {
    const organizationApi = new OrganizationsApi();
    const response = await organizationApi.organizationsControllerFindAll();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createOrganizationAsync = createAsyncThunk("organizations/create", async (organization: CreateOrganizationDto, { rejectWithValue }) => {
  try {
    const organizationApi = new OrganizationsApi();
    const response = await organizationApi.organizationsControllerCreate(organization);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateOrganizationAsync = createAsyncThunk(
  "organizations/update",
  async ({ organization, id }: { organization: UpdateOrganizationDto; id: string }, { rejectWithValue }) => {
    try {
      const organizationApi = new OrganizationsApi();
      const response = await organizationApi.organizationsControllerUpdate(organization, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchOrganizations = () => {
    const { pending, fulfilled, rejected } = fetchOrganizationsAsync;
    return {
      [`${pending}`]: (state: OrganizationState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: OrganizationState, action: PayloadAction<Organization[]>) => {
        state.organizations = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: OrganizationState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createOrganization = () => {
    const { pending, fulfilled, rejected } = createOrganizationAsync;
    return {
      [`${pending}`]: (state: OrganizationState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: OrganizationState, action: PayloadAction<Organization>) => {
        state.organizations.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: OrganizationState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateOrganization = () => {
    const { pending, fulfilled, rejected } = updateOrganizationAsync;

    return {
      [`${pending}`]: (state: OrganizationState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: OrganizationState, action: PayloadAction<Organization>) => {
        state.organizations = state.organizations.map((organization) => (organization.id === action.payload.id ? action.payload : organization));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: OrganizationState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchOrganizations(), ...createOrganization(), ...updateOrganization() };
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectOrganizations = (state: RootState) => state.organizations.organizations;
export const selectOrganizationsError = (state: RootState) => state.organizations.error;

export default organizationsSlice.reducer;
