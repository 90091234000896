import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterDrainageObjectDto, WaterDrainageObject, UpdateWaterDrainageObjectDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WaterDrainageObjectForm } from "./components/WaterDrainageObjectForm";
import { waterDrainageObjectsColumns } from "./form/columns";
import { selectWaterDrainageObjects, updateWaterDrainageObjectAsync, createWaterDrainageObjectAsync, deleteWaterDrainageObjectAsync, fetchWaterDrainageObjectsAsync } from "./WaterDrainageObjectsSlice";

export const WaterDrainageObjectsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const waterDrainageObjects = useAppSelector(selectWaterDrainageObjects);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchWaterDrainageObjectsAsync());
  }, [dispatch]);

  const onUpdateWaterDrainageObject = async (waterDrainageObject: UpdateWaterDrainageObjectDto, id: string) => {
    return dispatch(updateWaterDrainageObjectAsync({ waterDrainageObject, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterDrainageObject = (waterDrainageObject: CreateWaterDrainageObjectDto) => {

    dispatch(createWaterDrainageObjectAsync({ ...waterDrainageObject })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterDrainageObject = (waterDrainageObject: WaterDrainageObject) => {
    return dispatch(deleteWaterDrainageObjectAsync({ waterDrainageObject })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить объект
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterDrainageObjectForm visible={visible} setVisible={setVisible} onCreateWaterDrainageObject={onCreateWaterDrainageObject} />}
      <EditableTable<WaterDrainageObject, UpdateWaterDrainageObjectDto>
        onSave={onUpdateWaterDrainageObject}
        onDelete={deleteWaterDrainageObject}
        entityColumns={waterDrainageObjectsColumns}
        dataSource={waterDrainageObjects}
        footer={() => addButton}
      />
    </Layout>
  );
};
