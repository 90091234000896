import { Form, Modal, Select } from "antd";
import React from "react";
import { CreateStandardDto } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSubstancesInputOptions } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { selectSelectionSources } from "../../selectionSources/selectionSourcesSlice";
import { standardsColumns } from "../form/columns";

interface WorkshopStandardFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateStandard: (values: CreateStandardDto) => void;
}

export const WorkshopStandardForm: React.FC<WorkshopStandardFormProps> = ({ visible, setVisible, onCreateStandard }) => {
  const pollutionSubstances = useAppSelector(selectPollutionSubstancesInputOptions);
  const selectionSources = useAppSelector(selectSelectionSources);
  const [form] = Form.useForm<CreateStandardDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateStandard}>
        {standardsColumns(pollutionSubstances).map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
        <Form.Item name="selectionSourceId" label="Источник выделения" rules={[{ required: true, message: "Выберите источник" }]}>
          <Select
            showSearch
            filterOption={(input, option) => {
              if (option) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              } else {
                return true;
              }
            }}
            placeholder={"Выберите источник выделения"}
            options={selectionSources.map((source) => ({ value: source.id, label: `${source?.pollutionSource?.inventoryNumber}-${source.inventoryNumber} ${source.name}` }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
