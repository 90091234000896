/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AnalyticsProductionForKpi } from '../models';
import { CreateAnalyticsProductionForKpiDto } from '../models';
import { UpdateAnalyticsProductionForKpiDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * AnalyticsProductionForKpisApi - axios parameter creator
 * @export
 */
export const AnalyticsProductionForKpisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAnalyticsProductionForKpiDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsProductionForKpisControllerCreate: async (body: CreateAnalyticsProductionForKpiDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling analyticsProductionForKpisControllerCreate.');
            }
            const localVarPath = `/analytics-production-for-kpis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsProductionForKpisControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/analytics-production-for-kpis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsProductionForKpisControllerFindByOrganizationid: async (organizationId: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling analyticsProductionForKpisControllerFindByOrganizationid.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling analyticsProductionForKpisControllerFindByOrganizationid.');
            }
            const localVarPath = `/analytics-production-for-kpis/organization/{organizationId}/{year}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsProductionForKpisControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analyticsProductionForKpisControllerFindOne.');
            }
            const localVarPath = `/analytics-production-for-kpis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsProductionForKpisControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analyticsProductionForKpisControllerRemove.');
            }
            const localVarPath = `/analytics-production-for-kpis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnalyticsProductionForKpiDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsProductionForKpisControllerUpdate: async (body: UpdateAnalyticsProductionForKpiDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling analyticsProductionForKpisControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analyticsProductionForKpisControllerUpdate.');
            }
            const localVarPath = `/analytics-production-for-kpis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");


            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})["Content-Type"] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsProductionForKpisApi - functional programming interface
 * @export
 */
export const AnalyticsProductionForKpisApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAnalyticsProductionForKpiDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerCreate(body: CreateAnalyticsProductionForKpiDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AnalyticsProductionForKpi>>> {
            const localVarAxiosArgs = await AnalyticsProductionForKpisApiAxiosParamCreator(configuration).analyticsProductionForKpisControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AnalyticsProductionForKpi>>>> {
            const localVarAxiosArgs = await AnalyticsProductionForKpisApiAxiosParamCreator(configuration).analyticsProductionForKpisControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerFindByOrganizationid(organizationId: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AnalyticsProductionForKpi>>>> {
            const localVarAxiosArgs = await AnalyticsProductionForKpisApiAxiosParamCreator(configuration).analyticsProductionForKpisControllerFindByOrganizationid(organizationId, year, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AnalyticsProductionForKpi>>> {
            const localVarAxiosArgs = await AnalyticsProductionForKpisApiAxiosParamCreator(configuration).analyticsProductionForKpisControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AnalyticsProductionForKpi>>> {
            const localVarAxiosArgs = await AnalyticsProductionForKpisApiAxiosParamCreator(configuration).analyticsProductionForKpisControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateAnalyticsProductionForKpiDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerUpdate(body: UpdateAnalyticsProductionForKpiDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AnalyticsProductionForKpi>>> {
            const localVarAxiosArgs = await AnalyticsProductionForKpisApiAxiosParamCreator(configuration).analyticsProductionForKpisControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AnalyticsProductionForKpisApi - factory interface
 * @export
 */
export const AnalyticsProductionForKpisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAnalyticsProductionForKpiDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerCreate(body: CreateAnalyticsProductionForKpiDto, options?: AxiosRequestConfig): Promise<AxiosResponse<AnalyticsProductionForKpi>> {
            return AnalyticsProductionForKpisApiFp(configuration).analyticsProductionForKpisControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AnalyticsProductionForKpi>>> {
            return AnalyticsProductionForKpisApiFp(configuration).analyticsProductionForKpisControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerFindByOrganizationid(organizationId: string, year: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AnalyticsProductionForKpi>>> {
            return AnalyticsProductionForKpisApiFp(configuration).analyticsProductionForKpisControllerFindByOrganizationid(organizationId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AnalyticsProductionForKpi>> {
            return AnalyticsProductionForKpisApiFp(configuration).analyticsProductionForKpisControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AnalyticsProductionForKpi>> {
            return AnalyticsProductionForKpisApiFp(configuration).analyticsProductionForKpisControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnalyticsProductionForKpiDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsProductionForKpisControllerUpdate(body: UpdateAnalyticsProductionForKpiDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AnalyticsProductionForKpi>> {
            return AnalyticsProductionForKpisApiFp(configuration).analyticsProductionForKpisControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsProductionForKpisApi - object-oriented interface
 * @export
 * @class AnalyticsProductionForKpisApi
 * @extends {BaseAPI}
 */
export class AnalyticsProductionForKpisApi extends BaseAPI {
    /**
     * 
     * @param {CreateAnalyticsProductionForKpiDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsProductionForKpisApi
     */
    public async analyticsProductionForKpisControllerCreate(body: CreateAnalyticsProductionForKpiDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<AnalyticsProductionForKpi>> {
        return AnalyticsProductionForKpisApiFp(this.configuration).analyticsProductionForKpisControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsProductionForKpisApi
     */
    public async analyticsProductionForKpisControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AnalyticsProductionForKpi>>> {
        return AnalyticsProductionForKpisApiFp(this.configuration).analyticsProductionForKpisControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsProductionForKpisApi
     */
    public async analyticsProductionForKpisControllerFindByOrganizationid(organizationId: string, year: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AnalyticsProductionForKpi>>> {
        return AnalyticsProductionForKpisApiFp(this.configuration).analyticsProductionForKpisControllerFindByOrganizationid(organizationId, year, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsProductionForKpisApi
     */
    public async analyticsProductionForKpisControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AnalyticsProductionForKpi>> {
        return AnalyticsProductionForKpisApiFp(this.configuration).analyticsProductionForKpisControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsProductionForKpisApi
     */
    public async analyticsProductionForKpisControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AnalyticsProductionForKpi>> {
        return AnalyticsProductionForKpisApiFp(this.configuration).analyticsProductionForKpisControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateAnalyticsProductionForKpiDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsProductionForKpisApi
     */
    public async analyticsProductionForKpisControllerUpdate(body: UpdateAnalyticsProductionForKpiDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AnalyticsProductionForKpi>> {
        return AnalyticsProductionForKpisApiFp(this.configuration).analyticsProductionForKpisControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
