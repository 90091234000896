import { Layout, Tabs } from "antd";
import { Organization } from "../../api";
import { WaterConsumptionComponent } from "./waterConsumptions/WaterConsumptionsComponent";
import { WaterDrainageComponent } from "./waterDrainages/WaterDrainagesComponent";
import { history } from "../../app/helpers";

interface WaterUseComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const WaterUseComponent: React.FC<WaterUseComponentProps> = ({ organization, year, quarter }) => {
  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Tabs
          tabPosition="top"
          destroyInactiveTabPane={true}
          onChange={(path) => {
            let tabPath = history?.location?.pathname.split('/') || [];
            tabPath[6] = path;
            history.navigate && history.navigate(`${tabPath.join('/')}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[6]}
          items={[
            {
              label: 'Водопотребление',
              key: 'waterConsumption',
              children: <WaterConsumptionComponent organization={organization} year={year} />,
            },
            {
              label: 'Водоотведение',
              key: 'waterDrainage',
              children: <WaterDrainageComponent organization={organization} year={year} />,
            }
          ]}
        />
      </Layout>
    </>
  );
};
