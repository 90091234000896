import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateRadiologyJournalDto, RadiologyJournalApi, UpdateRadiologyJournalDto, RadiologyJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface RadiologyJournalsState {
  radiologyJournals: RadiologyJournal[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: RadiologyJournalsState = {
  radiologyJournals: [],
  status: "idle",
};

export const createRadiologyJournalAsync = createAsyncThunk(
  "radiologyJournals/create",
  async (radiologyJournal: CreateRadiologyJournalDto, { rejectWithValue }) => {
    try {
      const radiologyJournalsApi = new RadiologyJournalApi();
      const response = await radiologyJournalsApi.radiologyJournalControllerCreate(radiologyJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRadiologyJournalAsync = createAsyncThunk(
  "radiologyJournals/update",
  async ({ radiologyJournal, id }: { radiologyJournal: UpdateRadiologyJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const radiologyJournalsApi = new RadiologyJournalApi();
      const response = await radiologyJournalsApi.radiologyJournalControllerUpdate(radiologyJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRadiologyJournalAsync = createAsyncThunk(
  "radiologyJournals/remove",
  async ({ radiologyJournal }: { radiologyJournal: RadiologyJournal }, { rejectWithValue }) => {
    try {
      const radiologyJournalsApi = new RadiologyJournalApi();
      const response = await radiologyJournalsApi.radiologyJournalControllerRemove(radiologyJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRadiologyJournalsByQuarterAsync = createAsyncThunk("radiologyJournals/fetch", async ({organizationId, year, quarter}: {organizationId: string, year: number, quarter: number}, { rejectWithValue }) => {
  try {
    const radiologyApi = new RadiologyJournalApi();
    const response = await radiologyApi.radiologyJournalControllerFindByQuart(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchRadiologyJournalsByRadiologySite = () => {
    const { pending, fulfilled, rejected } = fetchRadiologyJournalsByQuarterAsync;
    return {
      [`${pending}`]: (state: RadiologyJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyJournalsState, action: PayloadAction<RadiologyJournal[]>) => {
        state.radiologyJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createRadiologyJournal = () => {
    const { pending, fulfilled, rejected } = createRadiologyJournalAsync;
    return {
      [`${pending}`]: (state: RadiologyJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyJournalsState, action: PayloadAction<RadiologyJournal>) => {
        state.radiologyJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateRadiologyJournal = () => {
    const { pending, fulfilled, rejected } = updateRadiologyJournalAsync;

    return {
      [`${pending}`]: (state: RadiologyJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyJournalsState, action: PayloadAction<RadiologyJournal>) => {
        state.radiologyJournals = state.radiologyJournals.map((radiologyJournal) => (radiologyJournal.id === action.payload.id ? action.payload : radiologyJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeRadiologyJournal = () => {
    const { pending, fulfilled, rejected } = deleteRadiologyJournalAsync;
    return {
      [`${pending}`]: (state: RadiologyJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyJournalsState, action: PayloadAction<RadiologyJournal>) => {
        state.radiologyJournals.splice(state.radiologyJournals.indexOf(
          state.radiologyJournals.find((ws: RadiologyJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchRadiologyJournalsByRadiologySite(), ...createRadiologyJournal(), ...updateRadiologyJournal(), ...removeRadiologyJournal() };
};

export const radiologyJournalsSlice = createSlice({
  name: "radiologyJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectRadiologyJournals = (state: RootState) => state.radiologyJournals.radiologyJournals;

export default radiologyJournalsSlice.reducer;
