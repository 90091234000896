import { Input, InputNumber } from "antd";
import { RopCalculationCable } from "../../../api/models/rop-calculation-cable";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";

export const RopCalculationsCableColumns = (): EntityColumns<RopCalculationCable>[] => {
    const period = useAppSelector(selectOrganizationModulePeriod);
    
    return [
        {
            title: `№`,
            dataIndex: "no",
            key: "no",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Номер декларации`,
            dataIndex: "declarationNumber",
            key: "declarationNumber",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `БИН/ИИН`,
            dataIndex: "bin" as keyof RopCalculationCable,
            key: "bin",
            inputNode: <Input style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Наименование компании`,
            dataIndex: "organizationName" as keyof RopCalculationCable,
            key: "organizationName",
            inputNode: <Input style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Код ТН ВЭД ЕАЭС`,
            dataIndex: "codeTNVEDEAEU",
            key: "codeTNVEDEAEU",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Наименование товара`,
            dataIndex: "name",
            key: "name",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Стоимость товара в тенге`,
            dataIndex: "price",
            key: "price",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Коэффициент`,
            dataIndex: "coefficient",
            key: "coefficient",
            inputNode: <InputNumber defaultValue={0.05} style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Сумма платежа`,
            dataIndex: "amount",
            key: "amount",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: "Год",
            dataIndex: "year",
            key: "year",
            inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: false, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
        {
            title: "Квартал",
            dataIndex: "quarter",
            key: "quarter",
            inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
            rules: [{ required: false, message: "Заполните поле" }],
            editable: false,
            copyable: true,
        },
    ];
}