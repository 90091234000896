import { Button, Layout, Modal, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateSoilPointDto, SoilPoint, UpdateSoilPointDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { SoilPointPollutantsComponent } from "../soilPointPollutants/SoilPointPollutantsComponent";
import { SoilPointForm } from "./components/SoilPointForm";
import { soilPointsColumns } from "./form/columns";
import { selectSoilPoints, updateSoilPointAsync, createSoilPointAsync, deleteSoilPointAsync, fetchSoilPointsByOrganizationidAsync, copySoilPointAsync } from "./SoilPointsSlice";
import { CopyOutlined } from '@ant-design/icons';


interface SoilPointsComponentProps {
  organizationId?: string;
}

export const SoilPointsComponent: React.FC<SoilPointsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const soilPoints = useAppSelector(selectSoilPoints);

  const [visible, setVisible] = useState(false);
  const [point, setPoint] = useState<SoilPoint | undefined>(undefined);


  useEffect(() => {
    if (organizationId) {
      dispatch(fetchSoilPointsByOrganizationidAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateSoilPoint = async (soilPoint: UpdateSoilPointDto, id: string) => {
    return dispatch(updateSoilPointAsync({ soilPoint, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateSoilPoint = (soilPoint: CreateSoilPointDto) => {
    if (!organizationId) return;

    dispatch(createSoilPointAsync({ ...soilPoint, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteSoilPoint = (soilPoint: SoilPoint) => {
    return dispatch(deleteSoilPointAsync({ soilPoint })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const copy = async (id: string) => {
    return dispatch(copySoilPointAsync({ id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить точку
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <SoilPointForm visible={visible} setVisible={setVisible} onCreateSoilPoint={onCreateSoilPoint} />}
      <EditableTable<SoilPoint, UpdateSoilPointDto>
        onSave={onUpdateSoilPoint}
        onDelete={deleteSoilPoint}
        entityColumns={
          [ {
              title: "",
              dataIndex: "btnc" as keyof SoilPoint,
              key: "btnc",
              inputNode: <></>,
              render: (value: any, record: SoilPoint, index: any) =>
                <Button.Group>
                  <Button icon={<CopyOutlined />} onClick={() => { copy(record.id) }}></Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },
            ...soilPointsColumns, 
            {
              title: "Данные",
              dataIndex: "data" as keyof SoilPoint,
              key: "data",
              inputNode: <></>,
              render: (value: any, record: SoilPoint, index: any) =>
                <Button.Group>
                  <Button onClick={() => { setPoint(record) }}>Загрязнения</Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },]
        }
        dataSource={soilPoints}
        footer={() => addButton}
      />
      {point && (
        <Modal
          open={true}
          title={`Загрязнения - ${point?.name}`}
          onCancel={() => setPoint(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"85%"}
        >
          <SoilPointPollutantsComponent pointId={point.id} />
        </Modal>
      )}
    </Layout>
  );
};
