import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { Standard, UpdateStandardDto } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSubstancesInputOptions } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { standardsColumns } from "../form/columns";

interface SelectionSourceStandardUpdateProps {
  standard: Standard;
  visible: boolean;
  setVisible: Function;
  onUpdateStandard: (values: UpdateStandardDto, id: string) => void;
}

export const SelectionSourceStandardUpdateForm: React.FC<SelectionSourceStandardUpdateProps> = ({ standard, visible, setVisible, onUpdateStandard }) => {
  const [form] = Form.useForm<UpdateStandardDto>();
  const pollutionSubstances = useAppSelector(selectPollutionSubstancesInputOptions);

  useEffect(() => {
    form.setFieldsValue({ ...standard, pollutionSubstance: standard.pollutionSubstance.code });
  }, [standard, form]);

  const onFinish = (values: UpdateStandardDto) => {
    onUpdateStandard(values, standard.id);
  };

  return (
    <Modal open={visible} onOk={form.submit} okText="Обновить" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onFinish}>
        {standardsColumns(pollutionSubstances).map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
