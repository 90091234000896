import { Input, InputNumber } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectRadiologyPoints } from "../../radiologyPoints/RadiologyPointsSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import TextArea from "antd/es/input/TextArea";
import { ReportPayment } from "../../../api/models/report-payment";

export const ReportPaymentsColumns = (): EntityColumns<ReportPayment>[] => {

  const radiologyPoints = useAppSelector(selectRadiologyPoints);
  let list: any = [];
  for (const point of radiologyPoints) {
    for (const pollutant of (point.pollutants || [])) {
      list = [...list, {
        value: pollutant.id,
        label: `№${point.no}, точка: ${point.name}, загрязнение: ${pollutant.name}`
      }];
    }
  }

  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "МРП",
      dataIndex: "mciKZT",
      key: "mciKZT",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Бензин",
      dataIndex: "mciRateForPetrol",
      key: "mciRateForPetrol",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Дизельное топливо",
      dataIndex: "mciRateForDiesel",
      key: "mciRateForDiesel",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), СУГ, керосин",
      dataIndex: "mciRateForKerosene",
      key: "mciRateForKerosene",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Факт по Бензину, тонн",
      dataIndex: "actualPetrolT",
      key: "actualPetrolT",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Факт по Дизельному топливу, тонн",
      dataIndex: "actualDieselT",
      key: "actualDieselT",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Факт по СУГ, керосину, тонн",
      dataIndex: "actualKeroseneT",
      key: "actualKeroseneT",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Опасный отход",
      dataIndex: "mciRateForDangerWaste",
      key: "mciRateForDangerWaste",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Неопасный отход",
      dataIndex: "mciRateForNotDangerWaste",
      key: "mciRateForNotDangerWaste",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Вскрышные породы",
      dataIndex: "mciRateForOverburdenWaste",
      key: "mciRateForOverburdenWaste",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Вымещающие породы",
      dataIndex: "mciRateForLandfillWaste",
      key: "mciRateForLandfillWaste",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП), Отходы обогащения",
      dataIndex: "mciRateForEnrichmentWaste",
      key: "mciRateForEnrichmentWaste",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      editable: true,
      copyable: true,
    },
  ];
}
