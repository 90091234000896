import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateMaterialDto, Material, UpdateMaterialDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { MaterialForm } from "./components/MaterialForm";
import { materialsColumns } from "./form/columns";
import { selectMaterials, updateMaterialAsync, createMaterialAsync, deleteMaterialAsync, fetchMaterialsAsync } from "./MaterialsSlice";

export const MaterialsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const materials = useAppSelector(selectMaterials);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchMaterialsAsync());
  }, [dispatch]);

  const onUpdateMaterial = async (material: UpdateMaterialDto, id: string) => {
    return dispatch(updateMaterialAsync({ material, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateMaterial = (material: CreateMaterialDto) => {

    dispatch(createMaterialAsync({ ...material })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteMaterial = (material: Material) => {
    return dispatch(deleteMaterialAsync({ material })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить вещество
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <MaterialForm visible={visible} setVisible={setVisible} onCreateMaterial={onCreateMaterial} />}
      <EditableTable<Material, UpdateMaterialDto>
        onSave={onUpdateMaterial}
        onDelete={deleteMaterial}
        entityColumns={materialsColumns}
        dataSource={materials}
        footer={() => addButton}
      />
    </Layout>
  );
};
