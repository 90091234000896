import { Button, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { selectAuthUser } from "../auth/authSlice";
import { CreateOrganizationDto, Organization, UpdateOrganizationDto } from "../../api";
import { createOrganizationAsync, fetchOrganizationsAsync, selectOrganizations, updateOrganizationAsync } from "./organizationsSlice";
import { OrganizationForm } from "./components/OrganizationForm";
import { organizationColumns } from "./form/columns";
import Title from "antd/es/typography/Title";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { useNavigate } from "react-router";
import { fetchPollutionSubstancesAsync } from "../pollutionSubstances/pollutionSubstancesSlice";

export const OrganizationsComponent: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const organizations = useAppSelector(selectOrganizations);
  // const authUser = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOrganizationsAsync());
  }, [dispatch]);

  const onCreateOrganization = (organization: CreateOrganizationDto) => {
    dispatch(createOrganizationAsync(organization)).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdate = async (organization: UpdateOrganizationDto, id: string) => {
    const result = await dispatch(updateOrganizationAsync({ organization, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });

    return result;
  };

  const onWatch = (organization: Organization) => {
    navigate(`/organizations/${organization.id}`);
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} size="large" type="primary">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg overflow-scroll">
      <OrganizationForm visible={visible} setVisible={setVisible} onCreateOrganization={onCreateOrganization} />
      <Row className="p-4" justify={"space-between"}>
        <Title className="ml-4 !my-0" level={4}>
          Количество: {organizations.length}
        </Title>
        {addButton}
      </Row>
      <EditableTable<Organization, UpdateOrganizationDto> onSave={onUpdate} onWatch={onWatch} entityColumns={organizationColumns} dataSource={organizations} />
    </Layout>
  );
};
