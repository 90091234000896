import { Form } from "antd";
import { Rule } from "rc-field-form/es/interface";
import { ReactNode } from "react";

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputNode: ReactNode;
  record: T;
  rules?: Rule[];
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps<any>> = ({ editing, dataIndex, title, inputNode, record, children, rules, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item className="!mb-0" name={dataIndex} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
