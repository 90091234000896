import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterConsumptionProductionDto, WaterConsumptionProduction, Organization, UpdateWaterConsumptionProductionDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { WaterConsumptionProductionForm } from "./components/WaterConsumptionProductionForm";
import { WaterConsumptionProductionColumns } from "./form/columns";
import { createWaterConsumptionProductionAsync, updateWaterConsumptionProductionAsync, selectWaterConsumptionProduction, deleteWaterConsumptionProductionAsync, fetchWaterConsumptionProductionByOrganizationIdAsync } from "./WaterConsumptionProductionsSlice";
import { fetchWaterConsumptionObjectsAsync } from "../../waterConsumptionObject/WaterConsumptionObjectsSlice";

interface WaterConsumptionProductionComponentProps {
  organization?: Organization;
  year?: number;
}

export const WaterConsumptionProductionComponent: React.FC<WaterConsumptionProductionComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const waterConsumptionProduction = useAppSelector(selectWaterConsumptionProduction);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWaterConsumptionProductionByOrganizationIdAsync({ organizationId: organization.id, year }));
      dispatch(fetchWaterConsumptionObjectsAsync());
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (waterConsumptionProduction: UpdateWaterConsumptionProductionDto, id: string) => {
    return dispatch(updateWaterConsumptionProductionAsync({ waterConsumptionProduction, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterConsumptionProduction = (waterConsumptionProduction: CreateWaterConsumptionProductionDto) => {
    if (!organization || !year) return;

    dispatch(createWaterConsumptionProductionAsync({ ...waterConsumptionProduction, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterConsumptionProduction = (waterConsumptionProduction: WaterConsumptionProduction) => {
    return dispatch(deleteWaterConsumptionProductionAsync({ waterConsumptionProduction })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterConsumptionProductionForm visible={visible} setVisible={setVisible} onCreateWaterConsumptionProduction={onCreateWaterConsumptionProduction} />}
      <EditableTable<WaterConsumptionProduction, UpdateWaterConsumptionProductionDto>
        onSave={onUpdate}
        onDelete={deleteWaterConsumptionProduction}
        entityColumns={WaterConsumptionProductionColumns()}
        dataSource={structuredClone(waterConsumptionProduction).map((data: any) => {
          const waterConsumptionObject = data?.waterConsumptionObject?.id;
          delete data?.waterConsumptionObject;
          data.waterConsumptionObject = waterConsumptionObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
