import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateCarbonWasteRecycleDto, CarbonWasteRecycle, Organization, UpdateCarbonWasteRecycleDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { CarbonWasteRecycleForm } from "./components/CarbonWasteRecycleForm";
import { CarbonWasteRecycleColumns } from "./form/columns";
import { createCarbonWasteRecycleAsync, updateCarbonWasteRecycleAsync, selectCarbonWasteRecycle, deleteCarbonWasteRecycleAsync, fetchCarbonWasteRecycleByOrganizationIdAsync } from "./CarbonWasteRecycleSlice";
import { BASE_PATH } from "../../../api/base";

interface CarbonWasteRecycleComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const CarbonWasteRecycleComponent: React.FC<CarbonWasteRecycleComponentProps> = ({ organization, year, quarter }) => {
  const dispatch = useAppDispatch();
  const carbonWasteRecycle = useAppSelector(selectCarbonWasteRecycle);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year && quarter) {
      dispatch(fetchCarbonWasteRecycleByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
    }
  }, [organization, year, quarter, dispatch]);

  const onUpdate = async (carbonWasteRecycle: UpdateCarbonWasteRecycleDto, id: string) => {
    return dispatch(updateCarbonWasteRecycleAsync({ carbonWasteRecycle, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateCarbonWasteRecycle = (carbonWasteRecycle: CreateCarbonWasteRecycleDto) => {
    if (!organization || !year || !quarter) return;

    dispatch(createCarbonWasteRecycleAsync({ ...carbonWasteRecycle, organizationId: organization.id, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteCarbonWasteRecycle = (carbonWasteRecycle: CarbonWasteRecycle) => {
    return dispatch(deleteCarbonWasteRecycleAsync({ carbonWasteRecycle })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );
  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }
  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <CarbonWasteRecycleForm visible={visible} setVisible={setVisible} onCreateCarbonWasteRecycle={onCreateCarbonWasteRecycle} />}
      <EditableTable<CarbonWasteRecycle, UpdateCarbonWasteRecycleDto>
        onSave={onUpdate}
        onDelete={deleteCarbonWasteRecycle}
        entityColumns={CarbonWasteRecycleColumns()}
        dataSource={carbonWasteRecycle}
        footer={() => addButton}
      />
      <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/carbon-waste-recycle/report/organization/${organization?.id || ""}/year/${year}/quarter/${quarter}`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
    </Layout>
  );
};
