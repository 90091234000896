import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { IndustrialSite, Organization, OrganizationsApi, PekProgram, Permission } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { RootState } from "../../app/store";
import { updateOrganizationAsync } from "../organizations/organizationsSlice";
import { createIndustrialSiteAsync, deleteIndustrialSiteAsync, updateIndustrialSiteAsync } from "../industrialSites/industrialSitesSlice";
import { createPekProgramAsync, updatePekProgramAsync } from "../pekPrograms/pekProgramsSlice";
import { createPermissionAsync, updatePermissionAsync } from "../premissions/PermissionsSlice";
import { remove } from "lodash";
import { message } from "antd";
import { act } from "react-dom/test-utils";

export interface OrganizationProfileState {
  organization: Organization | undefined;
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: OrganizationProfileState = {
  organization: undefined,
  status: "idle",
};

export const fetchOrganizationProfileAsync = createAsyncThunk("organizationProfile/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const organizationApi = new OrganizationsApi();
    const response = await organizationApi.organizationsControllerFindOne(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchOrganizationProfile = () => {
    const { pending, fulfilled, rejected } = fetchOrganizationProfileAsync;
    return {
      [`${pending}`]: (state: OrganizationProfileState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<Organization>) => {
        state.organization = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: OrganizationProfileState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const updateOrganizationProfile = () => {
    const { fulfilled } = updateOrganizationAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<Organization>) => {
        state.organization = { ...state.organization, ...action.payload };
        state.status = "idle";
      },
    };
  };

  const createIndustrialSite = () => {
    const { fulfilled } = createIndustrialSiteAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<IndustrialSite>) => {
        state.organization?.industrialSites.push(action.payload);
      },
    };
  };

  const updateIndustrialSite = () => {
    const { fulfilled } = updateIndustrialSiteAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<IndustrialSite>) => {
        if (!state.organization?.industrialSites) return;

        state.organization.industrialSites = state.organization.industrialSites.map((industrialSite) =>
          industrialSite.id === action.payload.id ? action.payload : industrialSite
        );
      },
    };
  };

  const deleteIndustrialSite = () => {
    const { fulfilled } = deleteIndustrialSiteAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<IndustrialSite>) => {
        if (!state.organization?.industrialSites) return;

        state.organization.industrialSites = state.organization.industrialSites.filter((site) => site.id !== action.payload.id);
        message.success("Успешно удален");
        state.status = "idle";
      },
    };
  };

  const createPekProgram = () => {
    const { fulfilled } = createPekProgramAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<PekProgram>) => {
        state.organization?.pekPrograms.push(action.payload);
      },
    };
  };

  const updatePekProgram = () => {
    const { fulfilled } = updatePekProgramAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<PekProgram>) => {
        if (!state.organization?.industrialSites) return;

        state.organization.pekPrograms = state.organization.pekPrograms.map((pekProgram) =>
          pekProgram.id === action.payload.id ? action.payload : pekProgram
        );
      },
    };
  };

  const createPermission = () => {
    const { fulfilled } = createPermissionAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<Permission>) => {
        state.organization?.permissions.push(action.payload);
      },
    };
  };

  const updatePermission = () => {
    const { fulfilled } = updatePermissionAsync;

    return {
      [`${fulfilled}`]: (state: OrganizationProfileState, action: PayloadAction<Permission>) => {
        if (!state.organization?.permissions) return;

        state.organization.permissions = state.organization.permissions.map((permission) =>
          permission.id === action.payload.id ? action.payload : permission
        );
      },
    };
  };

  return {
    ...fetchOrganizationProfile(),
    ...updateOrganizationProfile(),
    ...createIndustrialSite(),
    ...updateIndustrialSite(),
    ...deleteIndustrialSite(),
    ...createPekProgram(),
    ...updatePekProgram(),
    ...createPermission(),
    ...updatePermission(),
  };
};

export const selectOrganizationsProfile = (state: RootState) => state.organizationsProfile.organization;

export const organizationsSlice = createSlice({
  name: "organizationProfile",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export default organizationsSlice.reducer;
