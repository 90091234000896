import { DatePicker, Input, InputNumber, Select } from "antd";
import { WasteEquipmentRecycle, WasteJournal, WasteRegister, WasteRegisterHazardEnum } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import Text from "antd/es/typography/Text";
import { selectWasteRegisters } from "../../wasteRegister/WasteRegisterSlice";
import { DefaultOptionType } from "antd/es/select";
import { selectWasteAccumulationSites } from "../../wasteAccumulationSites/WasteAccumulationSitesSlice";
import { selectWasteBurialSites } from "../../wasteBurialSites/WasteBurialSitesSlice";
import { selectWasteEquipmentRecycles } from "../../wasteEquipmentRecycles/WasteEquipmentRecyclesSlice";
import { selectWasteEquipmentSorts } from "../../wasteEquipmentSorts/WasteEquipmentSortsSlice";
import { selectWasteAccumulationLimits } from "../../wasteAccumulationLimits/WasteAccumulationLimitsSlice";
import { selectWasteBurialLimits } from "../../wasteBurialLimits/WasteBurialLimitsSlice";
import { selectWasteContragents } from "../../wasteContragents/WasteContragentsSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { selectWorkshops } from "../../workshops/workshopsSlice";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";
import { useEffect, useState } from "react";

export const WasteJournalsColumns = (): EntityColumns<WasteJournal>[] => {
  const wasteRegisters = useAppSelector(selectWasteRegisters);
  const wasteAccumulationSites = useAppSelector(selectWasteAccumulationSites);
  const wasteBurialSites = useAppSelector(selectWasteBurialSites);
  const wasteEquipmentRecycles = useAppSelector(selectWasteEquipmentRecycles);
  const wasteEquipmentSorts = useAppSelector(selectWasteEquipmentSorts);
  const wasteAccumulationLimits = useAppSelector(selectWasteAccumulationLimits);
  const wasteBurialLimits = useAppSelector(selectWasteBurialLimits);
  const wasteContragents = useAppSelector(selectWasteContragents);
  const period = useAppSelector(selectOrganizationModulePeriod);
  const workshops = useAppSelector(selectWorkshops);
  const organization = useAppSelector(selectOrganizationsProfile);

  let [selectedIndustrialSite, setIndustrialSite] = useState();
  let [filteredWorkshops, setFilteredWorkshops] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    setFilteredWorkshops(workshops
      .filter(w => {
        return (w.industrialSite.id === selectedIndustrialSite);
      })
      .map((workshop) => {
        return {
          value: workshop.id,
          label: `${workshop.name}`
        }
      }));
  }, [selectedIndustrialSite, workshops]);

  const signList: DefaultOptionType[] =
    [
      { value: 'TTACWA', label: 'Передача контрагенту без накопления' },
      { value: 'TTACFAS', label: 'Передача контрагенту с площадок накопления' },
      { value: 'PATL', label: 'Размещение на полигоне' },
      { value: 'PAOF', label: 'Перерарботка на собственных мощностях' },
      { value: 'RFATP', label: 'Прием от сторонней организации' },
      { value: 'POTAS', label: 'Размещение на площадке накопления' },
    ];

  function getSignLabel(value: String) {
    return signList.find(item => item.value === value)?.label
  }

  const operationList: DefaultOptionType[] = 
    [
      {value: 'Удаление', label: 'Удаление'},
      {value: 'Переработка', label: 'Переработка'},
      {value: 'Сортировка', label: 'Сортировка'},
      {value: 'Утилизация', label: 'Утилизация'},
      {value: 'Уничтожение', label: 'Уничтожение'},
    ];

  function getHazardLabel(code: string): string {
    switch (code) {
    case WasteRegisterHazardEnum.DANGER:
      return "Опасный ☢";
    case WasteRegisterHazardEnum.NOTDANGER: 
      return "Не опасный";
    case WasteRegisterHazardEnum.MIRROR: 
      return "Зеркальный";
    default:
      return code;
    }
  }

  function getWasteRegisterLabel(r: WasteRegister): string {
    return `${r.name}-${r.code} (${getHazardLabel(r.hazard)})`
  }

  function getWasteEquipmentRecycleLabel(w: WasteEquipmentRecycle): string {
    return `${w.name} (${w.operation})`
  }

  return [
    {
      title: "Площадка",
      dataIndex: "industrialSite",
      key: "industrialSite",
      inputNode: 
      <Select 
        options={organization?.industrialSites.map(
          (industrialSite) => {
            return {
              value: industrialSite.id,
              label: industrialSite.name
            }
          }
        )}
        showSearch
        filterOption={(input, option) => {
          if (option){
            const str = option.label + '';
            return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          } else {
            return true
          }            
        }}
        onSelect={(industrialSite) => {setIndustrialSite(industrialSite)}}
      />,
      render: (industrialSite) => <Text className="w-64" ellipsis={{ tooltip: `${organization?.industrialSites.find((i) => i.id === industrialSite)?.name}` }}>{organization?.industrialSites.find((i) => i.id === industrialSite)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Цех",
      dataIndex: "workshop",
      key: "workshopId",
      inputNode: 
      <Select 
        options={filteredWorkshops}
        showSearch
        filterOption={(input, option) => {
          if (option){
            const str = option.label + '';
            return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          } else {
            return true
          }            
        }}
      />,
      render: (workshop) => {
        const wrOpt = workshops.find((w) => w.id === workshop)
        const label = (wrOpt) ? wrOpt.name : ""
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>;
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Отход",
      dataIndex: "wasteRegister",
      key: "wasteRegisterId",
      inputNode: <Select options={wasteRegisters.map(
        (wasteRegister) => {
          return {
            value: wasteRegister.id,
            label: getWasteRegisterLabel(wasteRegister)
          }
        }
      )} />,
      render: (wasteRegister) => {
        const wrOpt = wasteRegisters.find((wr) => wr.id === wasteRegister)
        const label = (wrOpt) ? getWasteRegisterLabel(wrOpt) : ""
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>;
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Признак",
      dataIndex: "sign",
      key: "sign",
      inputNode: <Select options={signList} />,
      render: (sign) => <Text className="w-64" ellipsis={{ tooltip: `${getSignLabel(sign)}` }}>{getSignLabel(sign)}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Масса образованного отхода (тонны)",
      dataIndex: "productionVolume",
      key: "productionVolume",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Дата образования",
      dataIndex: "productionDate",
      key: "productionDate",
      inputNode: <DatePicker format={"DD/MM/YYYY"} style={{ width: '100%' }} />,
      render: (date) =>
        <>
          {date && <Text className="w-64" ellipsis={{ tooltip: new Date(date).toLocaleDateString('en-GB') }}>{new Date(date).toLocaleDateString('en-GB')}</Text>}
        </>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Площадка накопления",
      dataIndex: "wasteAccumulationSite",
      key: "wasteAccumulationSiteId",
      inputNode: <Select options={wasteAccumulationSites.map(
        (wasteAccumulationSite) => {
          return {
            value: wasteAccumulationSite.id,
            label: wasteAccumulationSite.name,
          }
        }
      )} />,
      render: (wasteAccumulationSite) => <Text className="w-64" ellipsis={{ tooltip: `${wasteAccumulationSites.find((wa) => wa.id === wasteAccumulationSite)?.name}` }}>{wasteAccumulationSites.find((wa) => wa.id === wasteAccumulationSite)?.name}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Наименование полигона",
      dataIndex: "wasteBurialSite",
      key: "wasteBurialSiteId",
      inputNode: <Select options={wasteBurialSites.map(
        (wasteBurialSite) => {
          return {
            value: wasteBurialSite.id,
            label: wasteBurialSite.name,
          }
        }
      )} />,
      render: (wasteBurialSite) => <Text className="w-64" ellipsis={{ tooltip: `${wasteBurialSites.find((wb) => wb.id === wasteBurialSite)?.name}` }}>{wasteBurialSites.find((wb) => wb.id === wasteBurialSite)?.name}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Оборудование переработки",
      dataIndex: "wasteEquipmentRecycle",
      key: "wasteEquipmentRecycleId",
      inputNode: <Select options={wasteEquipmentRecycles.map(
        (wasteEquipmentRecycle) => {
          return {
            value: wasteEquipmentRecycle.id,
            label: getWasteEquipmentRecycleLabel(wasteEquipmentRecycle)
          }
        }
      )} />,
      render: (wasteEquipmentRecycle) => {
        const werOpt = wasteEquipmentRecycles.find((wer) => wer.id === wasteEquipmentRecycle)
        const label = (werOpt) ? getWasteEquipmentRecycleLabel(werOpt) : ""
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>;
      },
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Оборудование сортировки",
      dataIndex: "wasteEquipmentSort",
      key: "wasteEquipmentSortId",
      inputNode: <Select options={wasteEquipmentSorts.map(
        (wasteEquipmentSort) => {
          return {
            value: wasteEquipmentSort.id,
            label: wasteEquipmentSort.name,
          }
        }
      )} />,
      render: (wasteEquipmentSort) => <Text className="w-64" ellipsis={{ tooltip: `${wasteEquipmentSorts.find((wes) => wes.id === wasteEquipmentSort)?.name}` }}>{wasteEquipmentSorts.find((wes) => wes.id === wasteEquipmentSort)?.name}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Дата размешения на площадке накопления",
      dataIndex: "placementDate",
      key: "placementDate",
      inputNode: <DatePicker format={"DD/MM/YYYY"} style={{ width: '100%' }} />,
      render: (date) =>
        <>
          {date && <Text className="w-64" ellipsis={{ tooltip: new Date(date).toLocaleDateString('en-GB') }}>{new Date(date).toLocaleDateString('en-GB')}</Text>}
        </>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Дата размешения на полигоне захоронения",
      dataIndex: "burialDate",
      key: "burialDate",
      inputNode: <DatePicker format={"DD/MM/YYYY"} style={{ width: '100%' }} />,
      render: (date) =>
        <>
          {date && <Text className="w-64" ellipsis={{ tooltip: new Date(date).toLocaleDateString('en-GB') }}>{new Date(date).toLocaleDateString('en-GB')}</Text>}
        </>
      ,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Количество дней фактического хранения",
      dataIndex: "actualStorageDays",
      key: "actualStorageDays",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Масса размещения (тонны)",
      dataIndex: "placementWeight",
      key: "placementWeight",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Дата передачи контрагенту",
      dataIndex: "transferDate",
      key: "transferDate",
      inputNode: <DatePicker format={"DD/MM/YYYY"} style={{ width: '100%' }} />,
      render: (date) =>
        <>
          {date && <Text className="w-64" ellipsis={{ tooltip: new Date(date).toLocaleDateString('en-GB') }}>{new Date(date).toLocaleDateString('en-GB')}</Text>}
        </>
      ,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Масса отхода с которой проведена операция самостоятельно на предприятии (тонны)",
      dataIndex: "operatedWeight",
      key: "operatedWeight",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Масса переданного отхода/сырья сторонней организации (тонны)",
      dataIndex: "transferWeight",
      key: "transferWeight",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Организация которой передан отход",
      dataIndex: "transferContragent",
      key: "transferContragentId",
      inputNode: <Select options={wasteContragents
        .filter((wasteContragent) => wasteContragent.hasTransmission)
        .map((wasteContragent) => {
          return {
            value: wasteContragent.id,
            label: wasteContragent.name,
          }
        }
      )} />,
      render: (wasteContragent) => <Text className="w-64" ellipsis={{ tooltip: `${wasteContragents.find((wb) => wb.id === wasteContragent)?.name}` }}>{wasteContragents.find((wb) => wb.id === wasteContragent)?.name}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вид проведенной опереации с отходом",
      dataIndex: "operation",
      key: "operation",
      inputNode: <Select options={operationList} />,
      render: (operation) => <Text className="w-64" ellipsis={{ tooltip: `${operation}` }}>{operation}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Оставшийся объем отхода после проведения операции",
      dataIndex: "remainingWeight",
      key: "remainingWeight",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вид проведенной опереации с оставшимся видом отходов",
      dataIndex: "residueOperation",
      key: "residueOperation",
      inputNode: <Input />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Лимит накопления",
      dataIndex: "wasteAccumulationLimit",
      key: "wasteAccumulationLimitId",
      inputNode: <Select options={wasteAccumulationLimits.map(
        (wasteAccumulationLimit) => {
          return {
            value: wasteAccumulationLimit.id,
            label: `${wasteAccumulationLimit.wasteRegister.name}-${wasteAccumulationLimit.wasteRegister.code}, лимит: ${wasteAccumulationLimit.limit}, разрешение: ${wasteAccumulationLimit.permission.name}`
          }
        }
      )} />,
      render: (wasteAccumulationLimit) => {
        const label = `${wasteAccumulationLimits.find((wal) => wal.id === wasteAccumulationLimit)?.wasteRegister.name}-${wasteAccumulationLimits.find((wal) => wal.id === wasteAccumulationLimit)?.wasteRegister.code}, лимит: ${wasteAccumulationLimits.find((wal) => wal.id === wasteAccumulationLimit)?.limit}`;
        return <>
          {wasteAccumulationLimit && <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>}
        </>;
      },
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Лимит размещения",
      dataIndex: "wasteBurialLimit",
      key: "wasteBurialLimit",
      inputNode: <Select options={wasteBurialLimits.map(
        (wasteBurialLimit) => {
          return {
            value: wasteBurialLimit.id,
            label: `${wasteBurialLimit.wasteRegister.name}-${wasteBurialLimit.wasteRegister.code}, лимит: ${wasteBurialLimit.limit} разрешение: ${wasteBurialLimit.permission.name}`
          }
        }
      )} />,
      render: (wasteBurialLimit) => {
        const label = `${wasteBurialLimits.find((wbl) => wbl.id === wasteBurialLimit)?.wasteRegister.name}-${wasteBurialLimits.find((wbl) => wbl.id === wasteBurialLimit)?.wasteRegister.code}, лимит: ${wasteBurialLimits.find((wbl) => wbl.id === wasteBurialLimit)?.limit}`;
        return <>
          {wasteBurialLimit && <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>}
        </>
      },
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Организация от которой получен отход",
      dataIndex: "senderContragent",
      key: "senderContragentId",
      inputNode: <Select options={wasteContragents
        .filter((wasteContragent) => wasteContragent.hasReception)
        .map((wasteContragent) => {
          return {
            value: wasteContragent.id,
            label: wasteContragent.name,
          }
        }
      )} />,
      render: (wasteContragent) => <Text className="w-64" ellipsis={{ tooltip: `${wasteContragents.find((wb) => wb.id === wasteContragent)?.name}` }}>{wasteContragents.find((wb) => wb.id === wasteContragent)?.name}</Text>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
  ];
}
