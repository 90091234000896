import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateCarbonWasteRecycleDto, CarbonWasteRecycleApi, UpdateCarbonWasteRecycleDto, CarbonWasteRecycle } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface CarbonWasteRecycleState {
  carbonWasteRecycle: CarbonWasteRecycle[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CarbonWasteRecycleState = {
  carbonWasteRecycle: [],
  status: "idle",
};

export const createCarbonWasteRecycleAsync = createAsyncThunk(
  "carbonWasteRecycle/create",
  async (carbonWasteRecycle: CreateCarbonWasteRecycleDto, { rejectWithValue }) => {
    try {
      const carbonWasteRecycleApi = new CarbonWasteRecycleApi();
      const response = await carbonWasteRecycleApi.carbonWasteRecycleControllerCreate(carbonWasteRecycle);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCarbonWasteRecycleAsync = createAsyncThunk(
  "carbonWasteRecycle/update",
  async ({ carbonWasteRecycle, id }: { carbonWasteRecycle: UpdateCarbonWasteRecycleDto; id: string }, { rejectWithValue }) => {
    try {
      const carbonWasteRecycleApi = new CarbonWasteRecycleApi();
      const response = await carbonWasteRecycleApi.carbonWasteRecycleControllerUpdate(carbonWasteRecycle, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCarbonWasteRecycleAsync = createAsyncThunk(
  "carbonWasteRecycle/remove",
  async ({ carbonWasteRecycle }: { carbonWasteRecycle: CarbonWasteRecycle }, { rejectWithValue }) => {
    try {
      const carbonWasteRecycleApi = new CarbonWasteRecycleApi();
      const response = await carbonWasteRecycleApi.carbonWasteRecycleControllerRemove(carbonWasteRecycle.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCarbonWasteRecycleByOrganizationIdAsync = createAsyncThunk("carbonWasteRecycle/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const carbonWasteRecycleApi = new CarbonWasteRecycleApi();
    const response = await carbonWasteRecycleApi.carbonWasteRecycleControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchCarbonWasteRecycleByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchCarbonWasteRecycleByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: CarbonWasteRecycleState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonWasteRecycleState, action: PayloadAction<CarbonWasteRecycle[]>) => {
        state.carbonWasteRecycle = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonWasteRecycleState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCarbonWasteRecycle = () => {
    const { pending, fulfilled, rejected } = createCarbonWasteRecycleAsync;
    return {
      [`${pending}`]: (state: CarbonWasteRecycleState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonWasteRecycleState, action: PayloadAction<CarbonWasteRecycle>) => {
        state.carbonWasteRecycle.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonWasteRecycleState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCarbonWasteRecycle = () => {
    const { pending, fulfilled, rejected } = updateCarbonWasteRecycleAsync;

    return {
      [`${pending}`]: (state: CarbonWasteRecycleState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonWasteRecycleState, action: PayloadAction<CarbonWasteRecycle>) => {
        state.carbonWasteRecycle = state.carbonWasteRecycle.map((carbonWasteRecycle) => (carbonWasteRecycle.id === action.payload.id ? action.payload : carbonWasteRecycle));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonWasteRecycleState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeCarbonWasteRecycle = () => {
    const { pending, fulfilled, rejected } = deleteCarbonWasteRecycleAsync;
    return {
      [`${pending}`]: (state: CarbonWasteRecycleState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonWasteRecycleState, action: PayloadAction<CarbonWasteRecycle>) => {
        state.carbonWasteRecycle.splice(state.carbonWasteRecycle.indexOf(
          state.carbonWasteRecycle.find((ws: CarbonWasteRecycle) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonWasteRecycleState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCarbonWasteRecycleByOrganization(), ...createCarbonWasteRecycle(), ...updateCarbonWasteRecycle(), ...removeCarbonWasteRecycle() };
};

export const carbonWasteRecycleSlice = createSlice({
  name: "carbonWasteRecycle",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectCarbonWasteRecycle = (state: RootState) => state.carbonWasteRecycle.carbonWasteRecycle;

export default carbonWasteRecycleSlice.reducer;
