import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateStandardDto, PageDto, PageMetaDto, Standard, StandardsApi, UpdateStandardDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface StandardsState {
  workshopStandards: Standard[];
  workshopPagination?: PageMetaDto;

  pollutionSourceStandards: Standard[];
  pollutionSourcePagination?: PageMetaDto;

  selectionSourceStandards: Standard[];
  selectionSourcePagination?: PageMetaDto;

  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: StandardsState = {
  workshopStandards: [],
  pollutionSourceStandards: [],
  selectionSourceStandards: [],
  status: "idle",
};

export const fetchWorkshopStandardsAsync = createAsyncThunk(
  "standards/workshop/fetch",
  async ({ workshopId, year }: { workshopId: string; year: number }, { rejectWithValue }) => {
    try {
      const standardsApi = new StandardsApi();
      const response = await standardsApi.standardsControllerFindByWorkshopId(workshopId, year.toString());
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPollutionSourceStandardsAsync = createAsyncThunk(
  "standards/pollutionSource/fetch",
  async (pollutionSourceId: string, { rejectWithValue }) => {
    try {
      const standardsApi = new StandardsApi();
      const response = await standardsApi.standardsControllerFindByPollutionSourceId(pollutionSourceId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSelectionSourceStandardsAsync = createAsyncThunk(
  "standards/selectionSource/fetch",
  async (selectionSourceId: string, { rejectWithValue }) => {
    try {
      const standardsApi = new StandardsApi();
      const response = await standardsApi.standardsControllerFindBySelectionSourceId(selectionSourceId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createStandardAsync = createAsyncThunk("standards/create", async (standard: CreateStandardDto, { rejectWithValue }) => {
  try {
    const pollutionSourcesApi = new StandardsApi();
    const response = await pollutionSourcesApi.standardsControllerCreate(standard);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateStandardAsync = createAsyncThunk(
  "standards/update",
  async ({ standard, id }: { standard: UpdateStandardDto; id: string }, { rejectWithValue }) => {
    try {
      const standardsApi = new StandardsApi();
      const response = await standardsApi.standardsControllerUpdate(standard, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeStandardAsync = createAsyncThunk("standards/remove", async (id: string, { rejectWithValue }) => {
  try {
    const standardsApi = new StandardsApi();
    const response = await standardsApi.standardsControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWorkshopStandards = () => {
    const { pending, fulfilled, rejected } = fetchWorkshopStandardsAsync;
    return {
      [`${pending}`]: (state: StandardsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: StandardsState, action: PayloadAction<PageDto<Standard>>) => {
        state.workshopStandards = action.payload.data;
        state.workshopPagination = action.payload.meta;
        state.status = "idle";
      },
      [`${rejected}`]: (state: StandardsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchPollutionSourceStandards = () => {
    const { pending, fulfilled, rejected } = fetchPollutionSourceStandardsAsync;
    return {
      [`${pending}`]: (state: StandardsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: StandardsState, action: PayloadAction<PageDto<Standard>>) => {
        state.pollutionSourceStandards = action.payload.data;
        state.pollutionSourcePagination = action.payload.meta;
        state.status = "idle";
      },
      [`${rejected}`]: (state: StandardsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchSelectionSourceStandards = () => {
    const { pending, fulfilled, rejected } = fetchSelectionSourceStandardsAsync;
    return {
      [`${pending}`]: (state: StandardsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: StandardsState, action: PayloadAction<PageDto<Standard>>) => {
        state.selectionSourceStandards = action.payload.data;
        state.selectionSourcePagination = action.payload.meta;
        state.status = "idle";
      },
      [`${rejected}`]: (state: StandardsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createStandard = () => {
    const { pending, fulfilled, rejected } = createStandardAsync;
    return {
      [`${pending}`]: (state: StandardsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: StandardsState, action: PayloadAction<Standard>) => {
        state.workshopStandards.push(action.payload);
        if (state.workshopPagination) state.workshopPagination.itemCount++;

        state.selectionSourceStandards.push(action.payload);
        if (state.selectionSourcePagination) state.selectionSourcePagination.itemCount++;
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: StandardsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateStandard = () => {
    const { pending, fulfilled, rejected } = updateStandardAsync;

    return {
      [`${pending}`]: (state: StandardsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: StandardsState, action: PayloadAction<Standard>) => {
        state.workshopStandards = state.workshopStandards.map((workshopStandard) =>
          workshopStandard.id === action.payload.id ? action.payload : workshopStandard
        );

        state.selectionSourceStandards = state.selectionSourceStandards.map((selectionSourceStandard) =>
          selectionSourceStandard.id === action.payload.id ? action.payload : selectionSourceStandard
        );
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: StandardsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeStandard = () => {
    const { pending, fulfilled, rejected } = removeStandardAsync;

    return {
      [`${pending}`]: (state: StandardsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: StandardsState, action: PayloadAction<Standard>) => {
        state.workshopStandards = state.workshopStandards.filter((workshopStandard) => workshopStandard.id !== action.payload.id);

        state.selectionSourceStandards = state.selectionSourceStandards.filter((selectionSourceStandard) =>
          selectionSourceStandard.id !== action.payload.id
        );

        message.success("Успешно удалено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: StandardsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchPollutionSourceStandards(), ...fetchSelectionSourceStandards(), ...fetchWorkshopStandards(), ...createStandard(), ...updateStandard(), ...removeStandard() };
};

export const standardsSlice = createSlice({
  name: "standards",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectWorkshopStandards = (state: RootState) => state.standards.workshopStandards;
export const selectPollutionSourceStandards = (state: RootState) => state.standards.pollutionSourceStandards;
export const selectSelectionSourceStandards = (state: RootState) => state.standards.selectionSourceStandards;

export default standardsSlice.reducer;
