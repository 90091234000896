import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWastePassportDto, WastePassport, UpdateWastePassportDto, Organization } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { FileForm } from "../files/components/FileForm";
import { fetchFilesByIdsAsync } from "../files/FilesEntitySlice";
import { WastePassportForm } from "./components/WastePassportsForm";
import { WastePassportsColumns } from "./form/columns";
import { createWastePassportAsync, updateWastePassportAsync, selectWastePassports, deleteWastePassportAsync, fetchWastePassportsByOrganizationIdAsync } from "./WastePassportsSlice";
import { FileOutlined, PlusCircleOutlined } from '@ant-design/icons';

interface WastePassportsComponentProps {
  organization?: Organization;
}

export const WastePassportsComponent: React.FC<WastePassportsComponentProps> = ({ organization }) => {
  const dispatch = useAppDispatch();
  const wastePassports = useAppSelector(selectWastePassports);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (organization) {
      dispatch(fetchWastePassportsByOrganizationIdAsync({ organizationId: organization.id }));
    }
  }, [organization, dispatch]);

  const onUpdate = async (wastePassport: UpdateWastePassportDto, id: string) => {
    return dispatch(updateWastePassportAsync({ wastePassport, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWastePassport = (wastePassport: CreateWastePassportDto) => {
    if (!organization) return;

    dispatch(createWastePassportAsync({ ...wastePassport, organizationId: organization.id })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWastePassports = (wastePassport: WastePassport) => {
    return dispatch(deleteWastePassportAsync({ wastePassport })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить паспорт отхода
    </Button>
  );

  const [visibleFileForm, setVisibleFileForm] = useState(false);
  const [currentWastePassport, setCurrentWastePassport] = useState<WastePassport>();

  const showFileModal = async (wastePassport: WastePassport) => {
      // Fetch file for the current calculation
      if (wastePassport.fileId) {
          await dispatch(fetchFilesByIdsAsync({ ids: wastePassport.fileId }));
      }

      setCurrentWastePassport(wastePassport);
      setVisibleFileForm(true);
  };

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WastePassportForm visible={visible} setVisible={setVisible} onCreateWastePassport={onCreateWastePassport} />}
      <EditableTable<WastePassport, UpdateWastePassportDto>
        onSave={onUpdate}
        onDelete={deleteWastePassports}
        entityColumns={
          [
            {
              title: "Файл",
              dataIndex: "btnc" as keyof WastePassport,
              key: "btnc",
              inputNode: <></>,
              render: (value: any, record: WastePassport, index: any) => {
                if (record.fileId != null)
                  return <Button.Group><Button icon={ <FileOutlined />} onClick={() => showFileModal(record)}></Button></Button.Group>;
                else
                  return <Button.Group><Button icon={ <PlusCircleOutlined />} onClick={() => showFileModal(record)}></Button></Button.Group>;
              }
              ,
              editable: false,
              copyable: false,
            },
            ...WastePassportsColumns()
          ]
        }
        dataSource={structuredClone(wastePassports).map((data: any) => {
          const permission = data?.permission?.id;
          delete data?.permission;
          data.permission = permission;

          const industrialSite = data?.industrialSite?.id;
          delete data?.industrialSite;
          data.industrialSite = industrialSite;

          const wasteBurialSite = data?.wasteBurialSite?.id;
          delete data?.wasteBurialSite;
          data.wasteBurialSite = wasteBurialSite;
          return data;
        }) as any}

        footer={() => addButton}
      />
       {visibleFileForm && currentWastePassport && <FileForm visible={visibleFileForm} setVisible={setVisibleFileForm} onUpdate={onUpdate} fileIds={[currentWastePassport.fileId]} id={currentWastePassport.id} maxCount={1} />}
    </Layout>
  );
};
