import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ProList } from "@ant-design/pro-components";
import { Button, Collapse, Layout, Popconfirm, Row, Typography } from "antd";
import { Dictionary, groupBy } from "lodash";
import { useEffect, useState } from "react";
import { CreateStandardDto, Organization, Standard, UpdateStandardDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { WorkshopStandardForm } from "./components/WorkshopStandardForm";
import { WorkshopStandardUpdateForm } from "./components/WorkshopStandardUpdateForm";
import { createStandardAsync, fetchWorkshopStandardsAsync, removeStandardAsync } from "./standardsSlice";
import { selectWorkshopStandards, updateStandardAsync } from "./standardsSlice";
import { fetchSelectionSourcesByOrganizationAsync } from "../selectionSources/selectionSourcesSlice";

interface WorkshopStandardsComponentProps {
  organization: Organization;
  year: number;
  quarter: number;
}

export const WorkshopStandardComponent: React.FC<WorkshopStandardsComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const workshopStandards = useAppSelector(selectWorkshopStandards);

  const [updatingStandard, setUpdatingStandard] = useState<Standard | undefined>();

  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  useEffect(() => {
    dispatch(fetchSelectionSourcesByOrganizationAsync(organization.id))
  }, [dispatch, organization]);

  useEffect(() => {
    dispatch(fetchWorkshopStandardsAsync({ workshopId: organization.id, year: year }));
  }, [dispatch, organization, year]);

  const onCreateStandard = (standard: CreateStandardDto) => {
    dispatch(createStandardAsync({ ...standard, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdate = async (standard: UpdateStandardDto, id: string) => {
    return dispatch(
      updateStandardAsync({
        standard: {
          ...standard,
          valueGramPerSecond: parseFloat(`${standard.valueGramPerSecond}`),
          valueTonPerYear: parseFloat(`${standard.valueTonPerYear}`),
        },
        id,
      })
    ).then((res: any) => {
      if (!res.error) {
        setVisibleUpdate(false);
        return true;
      }
      return false;
    });
  };

  const onDelete = async (id: string) => {
    return dispatch(removeStandardAsync(id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const edit = (record: Standard) => {
    setUpdatingStandard(record);
    setVisibleUpdate(true);
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} type="primary" className="my-4 w-48" size="middle">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {addButton}
      <WorkshopStandardForm visible={visible} setVisible={setVisible} onCreateStandard={onCreateStandard} />
      {updatingStandard && (
        <WorkshopStandardUpdateForm standard={updatingStandard} visible={visibleUpdate} setVisible={setVisibleUpdate} onUpdateStandard={onUpdate} />
      )}

      <ProList<Standard>
        rowKey="name"
        dataSource={workshopStandards}
        metas={{
          title: {
            dataIndex: "selectionSource",
            render: (_, record) => `${record.selectionSource?.pollutionSource?.inventoryNumber}-${record.selectionSource?.inventoryNumber}`,
          },
          description: {
            dataIndex: "selectionSource",
            // render: (_, record) => `Цех: ${record.pollutionSource?.workshop?.code} ${record.pollutionSource?.workshop?.name}`,
            render: (_, record) => `${record.selectionSource?.pollutionSource?.name}-${record.selectionSource?.name}`,
          },
          content: {
            dataIndex: "content",
            render: (_, record) => (
              <div key="label" style={{ width: 800, justifyContent: "space-between" }} className="flex">
                <div className="w-24">
                  <div>Цех</div>
                  <Typography.Text strong>
                    {record.selectionSource?.pollutionSource?.workshop?.code} {record.selectionSource?.pollutionSource?.workshop?.name}
                  </Typography.Text>
                </div>
                <div className="w-72">
                  <div>Вещество</div>
                  <div>
                    <Typography.Text style={{ fontWeight: 700 }}>{record.pollutionSubstance.code}</Typography.Text>{" "}
                    <Typography.Text ellipsis={{ tooltip: record.pollutionSubstance.name }}>{record.pollutionSubstance.name}</Typography.Text>
                  </div>
                </div>
                <div>
                  <div>Лимит г/с</div>
                  <Typography.Text>{record.valueGramPerSecond}</Typography.Text>
                </div>
                <div>
                  <div>Лимит т/г</div>
                  <Typography.Text>{record.valueTonPerYear}</Typography.Text>
                </div>

                <div>
                  <Row justify={"center"} align="middle">
                    <Button size="small" onClick={() => edit(record)} className="mr-2">
                      <EditOutlined />
                    </Button>
                    <Popconfirm
                      title="Вы уверены?"
                      onConfirm={() => onDelete(record.id)}
                      okText="Удалить"
                      cancelText="Отмена"
                    >
                      <Button size="small" danger>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </Row>
                </div>
              </div>
            ),
          },
        }}
      />
    </Layout>
  );
};
