import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { CreateWasteJournalDto } from "../../../api";
import { WasteJournalsColumns } from "../form/columns";

interface WasteJournalFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteJournal: (values: CreateWasteJournalDto) => void;
}

export const WasteJournalForm: React.FC<WasteJournalFormProps> = ({ visible, setVisible, onCreateWasteJournal }) => {
  const [form] = Form.useForm<CreateWasteJournalDto>();

  const [visibilityMapper, setVisibilityMapper] = useState<{ [key: string]: boolean }>({});
  const wasteJournalsColumns = WasteJournalsColumns();
  const [wasteJournalsColumnsState] = useState(wasteJournalsColumns);

  useEffect(() => {
    let visibilityMapperArray: { [key: string]: boolean } = {};
    for (const col of wasteJournalsColumnsState) {
      visibilityMapperArray[col.dataIndex] = false;
    }
    setVisibilityMapper({
      ...visibilityMapperArray,
      industrialSite: true,
      workshop: true,
      wasteRegister: true,
      sign: true,
    });

  }, [wasteJournalsColumnsState]);

  const onChange = (value: any, additional?: any) => {

    const field = (Object.keys(form.getFieldsValue()) as (keyof CreateWasteJournalDto)[]).find((key) => {
      return form.getFieldsValue()[key] === value;
    });

    if (field === 'sign') {
      if (form.getFieldValue('sign')) {
        switch (form.getFieldValue('sign')) {
          case 'TTACWA':
            setVisibilityMapper({
              ...visibilityMapper,
              industrialSite: true,
              workshop: true,
              wasteRegister: true,
              productionVolume: true,
              productionDate: true,
              sign: true,
              wasteAccumulationSite: false,
              wasteBurialSite: false,
              wasteEquipmentRecycle: false,
              wasteEquipmentSort: false,
              placementDate: false,
              burialDate: false,
              placementWeight: false,
              transferDate: true,
              operatedWeight: false,
              transferWeight: true,
              transferContragent: true,
              operation: false,
              remainingWeight: false,
              residueOperation: false,
              wasteAccumulationLimit: false,
              wasteBurialLimit: false,
              senderContragent: false,
            });
            break;
          case 'TTACFAS':
            setVisibilityMapper({
              ...visibilityMapper,
              industrialSite: true,
              workshop: true,
              wasteRegister: true,
              productionVolume: false,
              productionDate: false,
              sign: true,
              wasteAccumulationSite: true,
              wasteBurialSite: false,
              wasteEquipmentRecycle: false,
              wasteEquipmentSort: false,
              placementDate: false,
              burialDate: false,
              placementWeight: false,
              transferDate: true,
              operatedWeight: false,
              transferWeight: true,
              transferContragent: true,
              operation: false,
              remainingWeight: false,
              residueOperation: false,
              wasteAccumulationLimit: true,
              wasteBurialLimit: false,
              senderContragent: false,
            });
            break;
          case 'PATL':
            setVisibilityMapper({
              ...visibilityMapper,
              industrialSite: true,
              workshop: true,
              wasteRegister: true,
              productionVolume: true,
              productionDate: true,
              sign: true,
              wasteAccumulationSite: false,
              wasteBurialSite: true,
              wasteEquipmentRecycle: false,
              wasteEquipmentSort: false,
              placementDate: false,
              burialDate: true,
              placementWeight: true,
              transferDate: false,
              operatedWeight: false,
              transferWeight: false,
              transferContragent: false,
              operation: false,
              remainingWeight: false,
              residueOperation: false,
              wasteAccumulationLimit: false,
              wasteBurialLimit: true,
              senderContragent: false,
            });
            break;
          case 'PAOF':
            setVisibilityMapper({
              ...visibilityMapper,
              industrialSite: true,
              workshop: true,
              wasteRegister: true,
              productionVolume: true,
              productionDate: true,
              sign: true,
              wasteAccumulationSite: false,
              wasteBurialSite: false,
              wasteEquipmentRecycle: true,
              wasteEquipmentSort: false,
              placementDate: false,
              burialDate: false,
              placementWeight: false,
              transferDate: true,
              operatedWeight: true,
              transferWeight: true,
              transferContragent: true,
              operation: false,
              remainingWeight: true,
              residueOperation: true,
              wasteAccumulationLimit: false,
              wasteBurialLimit: false,
              senderContragent: false,
            });
            break;
          case 'RFATP':
            setVisibilityMapper({
              ...visibilityMapper,
              industrialSite: true,
              workshop: true,
              wasteRegister: true,
              productionVolume: true,
              productionDate: true,
              sign: true,
              wasteAccumulationSite: false,
              wasteBurialSite: false,
              wasteEquipmentRecycle: false,
              wasteEquipmentSort: false,
              placementDate: false,
              burialDate: false,
              placementWeight: false,
              transferDate: false,
              operatedWeight: true,
              transferWeight: true,
              transferContragent: false,
              operation: true,
              remainingWeight: false,
              residueOperation: false,
              wasteAccumulationLimit: false,
              wasteBurialLimit: false,
              senderContragent: true,
            });
            break;
          case 'POTAS':
            setVisibilityMapper({
              ...visibilityMapper,
              industrialSite: true,
              workshop: true,
              wasteRegister: true,
              productionVolume: true,
              productionDate: true,
              sign: true,
              wasteAccumulationSite: true,
              wasteBurialSite: false,
              wasteEquipmentRecycle: false,
              wasteEquipmentSort: false,
              placementDate: true,
              burialDate: false,
              placementWeight: true,
              transferDate: false,
              operatedWeight: false,
              transferWeight: false,
              transferContragent: false,
              operation: false,
              remainingWeight: false,
              residueOperation: false,
              wasteAccumulationLimit: true,
              wasteBurialLimit: false,
              senderContragent: false,
            });
            break;
        }
      }
    }

    // if (field === 'transferWeight' || field === 'weight') {
    //   if (!isNaN(+form.getFieldValue('weight')) && !isNaN(+form.getFieldValue('transferWeight'))) {
    //     const weightDifference = Math.max(0, +form.getFieldValue('weight') - +form.getFieldValue('transferWeight'));
    //     form.setFieldValue('remainderWeight', weightDifference);
    //   }
    // }
  }

  return (
    <Modal width="60%" open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteJournal}>
        {wasteJournalsColumns.map(({ title, key, rules, inputNode, dataIndex }, index) => (
          <>
            {
              visibilityMapper[dataIndex] && <Form.Item className={`focking-${dataIndex}-${visibilityMapper[dataIndex]}`} label={title} name={key} rules={rules} key={index}>
                {
                  React.cloneElement(
                    inputNode,
                    {
                      onChange: onChange
                    }
                  )
                }
              </Form.Item>
            }
          </>
        ))}
      </Form>
    </Modal>
  );
};
