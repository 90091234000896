import { PageLoading, ProList } from "@ant-design/pro-components";
import { Badge, Button, Layout, Modal, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { CreateMethodicDto, Methodic, UpdateMethodicDto } from "../../api";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createMethodicAsync, deleteMethodicAsync, fetchMethodicsAsync, selectMethodics, selectMethodicsStatus, updateMethodicAsync } from "./methodicsSlice";
import { MethodicForm } from "./component/MethodicForm";

interface MethodicsComponentProps { }

export const MethodicsComponent: React.FC<MethodicsComponentProps> = ({ }) => {
  const dispatch = useAppDispatch();
  const methodics = useAppSelector(selectMethodics);
  const status = useAppSelector(selectMethodicsStatus);

  const [visible, setVisible] = useState(false);
  const [whichMethodic, setWhichMethodic] = useState<Methodic | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchMethodicsAsync());
  }, [dispatch]);

  const onCreateMethodic = async (values: any) => {
    dispatch(createMethodicAsync(values)).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdateMethodic = async (methodic: any) => {
    if (!whichMethodic) return;

    dispatch(updateMethodicAsync({ id: whichMethodic.id, methodic: methodic })).then((res: any) => {
      if (!res.error) {
        setWhichMethodic(undefined);
      }
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="primary">
      + Добавить
    </Button>
  );

  const renderBadge = (count: number, active = false) => {
    return (
      <Badge
        count={count}
        style={{
          marginBlockStart: -2,
          marginInlineStart: 4,
          color: active ? "#1890FF" : "#999",
          backgroundColor: active ? "#E6F7FF" : "#eee",
        }}
      />
    );
  };

  const deleteMethodic = (methodic: Methodic) => {
    return dispatch(deleteMethodicAsync({ methodic })).then((res: any) => {
      if (!res.error) {
        setWhichMethodic(undefined);
        return true;
      }
      return false;
    });
  }

  if (status === "loading") return <PageLoading />;

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && (
        <Modal
          width={"80%"}
          open={visible}
          onCancel={() => setVisible(false)}
          cancelText={"Закрыть"}
          okButtonProps={{ disabled: true, style: { visibility: "hidden" } }}
        >
          <MethodicForm visible={visible} setVisible={setVisible} onSaveMethodic={onCreateMethodic} withoutSource={true} />
        </Modal>
      )}
      <ProList<Methodic>
        rowKey="name"
        dataSource={methodics}
        metas={{
          title: { dataIndex: "name" },
          description: { dataIndex: "comment" },
          content: {
            dataIndex: "content",
            render: (_, record) => (
              <div key="label" className="flex justify-around text-center">
                <div>
                  <div style={{ color: "#00000073" }}>Источники</div>
                  <div style={{ color: "#000000D9" }}>{record.selectionSourceLength || 0}</div>
                </div>

                <div>
                  <div style={{ color: "#00000073" }}>Статичные параметры</div>
                  <div style={{ color: "#000000D9" }}>{record.staticParameters.length || 0}</div>
                </div>

                <div>
                  <div style={{ color: "#00000073" }}>Входные параметры</div>
                  <div style={{ color: "#000000D9" }}>{record.incomingParameters?.length || 0}</div>
                </div>

                <div>
                  <div style={{ color: "#00000073" }}>Загрязняющие вещества</div>
                  <div style={{ color: "#000000D9" }}>{record.totalCountOfExpressions || 0}</div>
                </div>

                <div className="flex items-center">
                  <Button onClick={() => setWhichMethodic(record)} size="small" className="mr-2">
                    <EditOutlined />
                  </Button>
                  <Popconfirm
                    title="Вы уверены?"
                    onConfirm={() => deleteMethodic(record)}
                    okText="Удалить"
                    cancelText="Отмена"
                  >
                    <Button size="small" danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            ),
          },
        }}
        toolbar={{
          menu: {
            items: [
              {
                key: "tab1",
                label: <span>Список методик{renderBadge(methodics.length)}</span>,
              },
            ],
          },
          actions: [addButton],
        }}
      />

      {whichMethodic && (
        <Modal
          open={true}
          title={`Цэх - ${whichMethodic.name}`}
          onCancel={() => setWhichMethodic(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"92%"}
        >
          <MethodicForm visible={visible} setVisible={setVisible} initialValues={whichMethodic} onSaveMethodic={onUpdateMethodic} withoutSource={true} />
        </Modal>
      )}
    </Layout>
  );
};
