import { Layout, Tabs } from "antd";
import { Organization } from "../../../api";
import { history } from "../../../app/helpers";
import { WaterDrainageHouseholdComponent } from "../waterDrainageHouseholds/WaterDrainageHouseholdsComponent";
import { WaterDrainageProductionComponent } from "../waterDrainageProductions/WaterDrainageProductionsComponent";

interface WaterDrainageComponentProps {
  organization?: Organization;
  year?: number;
}

export const WaterDrainageComponent: React.FC<WaterDrainageComponentProps> = ({ organization, year }) => {
  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane={true}
          onChange={(path) => {
            let tabPath = history?.location?.pathname.split('/') || [];
            tabPath[6] = path;
            history.navigate && history.navigate(`${tabPath.join('/')}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[6]}
          items={[
            {
              label: 'Хозяйственно-бытовые стоки',
              key: 'waterDrainageHousehold',
              children: <WaterDrainageHouseholdComponent organization={organization} year={year} />,
            },
            {
              label: 'Производственные',
              key: 'waterDrainageProduction',
              children: <WaterDrainageProductionComponent organization={organization} year={year} />,
            },
          ]}
        />
      </Layout>
    </>
  );
};
