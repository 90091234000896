import Table, { ColumnsType } from 'antd/es/table';
import React from 'react'

interface DataType {
    key: string;
    date: string;
    action: string;
  }

const columns: ColumnsType<DataType> = [
    {
      title: 'Дата и время',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
    },
  ];
  

export const ActivityMonitoring: React.FC = () => {

    return(
        <Table columns={columns} dataSource={[{key: "1", date: 'asdf', action: 'asdf' }]} />
    )
}