import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAnalyticsProductionForKpiDto, AnalyticsProductionForKpisApi, UpdateAnalyticsProductionForKpiDto, AnalyticsProductionForKpi } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface AnalyticsProductionForKpisState {
  analyticsProductionForKpis: AnalyticsProductionForKpi[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AnalyticsProductionForKpisState = {
  analyticsProductionForKpis: [],
  status: "idle",
};

export const createAnalyticsProductionForKpisAsync = createAsyncThunk(
  "analyticsProductionForKpis/create",
  async (analyticsProductionForKpi: CreateAnalyticsProductionForKpiDto, { rejectWithValue }) => {
    try {
      const analyticsProductionForKpisApi = new AnalyticsProductionForKpisApi();
      const response = await analyticsProductionForKpisApi.analyticsProductionForKpisControllerCreate(analyticsProductionForKpi);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAnalyticsProductionForKpisAsync = createAsyncThunk(
  "analyticsProductionForKpis/update",
  async ({ analyticsProductionForKpi, id }: { analyticsProductionForKpi: UpdateAnalyticsProductionForKpiDto; id: string }, { rejectWithValue }) => {
    try {
      const analyticsProductionForKpisApi = new AnalyticsProductionForKpisApi();
      const response = await analyticsProductionForKpisApi.analyticsProductionForKpisControllerUpdate(analyticsProductionForKpi, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAnalyticsProductionForKpisAsync = createAsyncThunk(
  "analyticsProductionForKpis/remove",
  async ({ analyticsProductionForKpi }: { analyticsProductionForKpi: AnalyticsProductionForKpi }, { rejectWithValue }) => {
    try {
      const analyticsProductionForKpisApi = new AnalyticsProductionForKpisApi();
      const response = await analyticsProductionForKpisApi.analyticsProductionForKpisControllerRemove(analyticsProductionForKpi.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAnalyticsProductionForKpisByOrganizationIdAsync = createAsyncThunk("analyticsProductionForKpis/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const analyticsProductionForKpisApi = new AnalyticsProductionForKpisApi();
    const response = await analyticsProductionForKpisApi.analyticsProductionForKpisControllerFindByOrganizationid(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAnalyticsProductionForKpisByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchAnalyticsProductionForKpisByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: AnalyticsProductionForKpisState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AnalyticsProductionForKpi[]>) => {
        state.analyticsProductionForKpis = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAnalyticsProductionForKpis = () => {
    const { pending, fulfilled, rejected } = createAnalyticsProductionForKpisAsync;
    return {
      [`${pending}`]: (state: AnalyticsProductionForKpisState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AnalyticsProductionForKpi>) => {
        state.analyticsProductionForKpis.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAnalyticsProductionForKpis = () => {
    const { pending, fulfilled, rejected } = updateAnalyticsProductionForKpisAsync;

    return {
      [`${pending}`]: (state: AnalyticsProductionForKpisState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AnalyticsProductionForKpi>) => {
        state.analyticsProductionForKpis = state.analyticsProductionForKpis.map((analyticsProductionForKpis) => (analyticsProductionForKpis.id === action.payload.id ? action.payload : analyticsProductionForKpis));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAnalyticsProductionForKpis = () => {
    const { pending, fulfilled, rejected } = deleteAnalyticsProductionForKpisAsync;
    return {
      [`${pending}`]: (state: AnalyticsProductionForKpisState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AnalyticsProductionForKpi>) => {
        state.analyticsProductionForKpis.splice(state.analyticsProductionForKpis.indexOf(
          state.analyticsProductionForKpis.find((ws: AnalyticsProductionForKpi) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AnalyticsProductionForKpisState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAnalyticsProductionForKpisByOrganization(), ...createAnalyticsProductionForKpis(), ...updateAnalyticsProductionForKpis(), ...removeAnalyticsProductionForKpis() };
};

export const analyticsProductionForKpisSlice = createSlice({
  name: "analyticsProductionForKpis",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAnalyticsProductionForKpis = (state: RootState) => state.analyticsProductionForKpis.analyticsProductionForKpis;

export default analyticsProductionForKpisSlice.reducer;
