/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organization } from './organization';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    roles: Array<UserRolesEnum>;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    deletedAt: Date;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof User
     */
    organizations: Array<Organization>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    token?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRolesEnum {
    Admin = 'admin',
    Ecolog = 'ecolog',
    Head = 'head',
    Chief = 'chief',
    Surveyor = 'surveyor',
    Engineer = 'engineer',
    Tailings = 'tailings',
    Career = 'career',
    Mechanical = 'mechanical',
    Atc = 'atc',
    Depot = 'depot',
    Rsg = 'rsg',
    Oks = 'oks',
    Ahs = 'ahs',
    Cl = 'cl',
    Icu = 'icu',
    Vet = 'vet',
    Of = 'of',
    Concentrator = 'concentrator',
    Gardening = 'gardening',
    Peo = 'peo',
    Accounting = 'accounting',
}

