import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateRadiologyPointPollutantDto, RadiologyPointPollutant, UpdateRadiologyPointPollutantDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { RadiologyPointPollutantForm } from "./components/RadiologyPointPollutantForm";
import { radiologyPointPollutantsColumns } from "./form/columns";
import { createRadiologyPointPollutantAsync, updateRadiologyPointPollutantAsync, selectRadiologyPointPollutants, deleteRadiologyPointPollutantAsync, fetchRadiologyPointPollutantsByPointAsync } from "./RadiologyPointPollutantsSlice";

interface RadiologyPointPollutantsComponentProps {
  pointId?: string;
}

export const RadiologyPointPollutantsComponent: React.FC<RadiologyPointPollutantsComponentProps> = ({ pointId }) => {
  const dispatch = useAppDispatch();
  const radiologyPointPollutants = useAppSelector(selectRadiologyPointPollutants);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (pointId) {
      dispatch(fetchRadiologyPointPollutantsByPointAsync(pointId));
    }
  }, [pointId, dispatch]);

  const onUpdate = async (radiologyPointPollutant: UpdateRadiologyPointPollutantDto, id: string) => {
    return dispatch(updateRadiologyPointPollutantAsync({ radiologyPointPollutant, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateRadiologyPointPollutant = (radiologyPointPollutant: CreateRadiologyPointPollutantDto) => {
    if (!pointId) return;

    dispatch(createRadiologyPointPollutantAsync({ ...radiologyPointPollutant, pointId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteRadiologyPointPollutants = (radiologyPointPollutant: RadiologyPointPollutant) => {
    return dispatch(deleteRadiologyPointPollutantAsync({ radiologyPointPollutant })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить загрязнение
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <RadiologyPointPollutantForm visible={visible} setVisible={setVisible} onCreateRadiologyPointPollutant={onCreateRadiologyPointPollutant} />}
      <EditableTable<RadiologyPointPollutant, UpdateRadiologyPointPollutantDto>
        onSave={onUpdate}
        onDelete={deleteRadiologyPointPollutants}
        entityColumns={radiologyPointPollutantsColumns}
        dataSource={radiologyPointPollutants}
        footer={() => addButton}
      />
    </Layout>
  );
};
