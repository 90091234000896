/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateStandardDto } from '../models';
import { InlineResponse2003 } from '../models';
import { Standard } from '../models';
import { UpdateStandardDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * StandardsApi - axios parameter creator
 * @export
 */
export const StandardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStandardDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerCreate: async (body: CreateStandardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling standardsControllerCreate.');
            }
            const localVarPath = `/standards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pollutionSourceId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerFindByPollutionSourceId: async (pollutionSourceId: string, order?: string, page?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pollutionSourceId' is not null or undefined
            if (pollutionSourceId === null || pollutionSourceId === undefined) {
                throw new RequiredError('pollutionSourceId','Required parameter pollutionSourceId was null or undefined when calling standardsControllerFindByPollutionSourceId.');
            }
            const localVarPath = `/standards/pollutionSource/{pollutionSourceId}`
                .replace(`{${"pollutionSourceId"}}`, encodeURIComponent(String(pollutionSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} selectionSourceId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerFindBySelectionSourceId: async (selectionSourceId: string, order?: string, page?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectionSourceId' is not null or undefined
            if (selectionSourceId === null || selectionSourceId === undefined) {
                throw new RequiredError('selectionSourceId','Required parameter selectionSourceId was null or undefined when calling standardsControllerFindBySelectionSourceId.');
            }
            const localVarPath = `/standards/selectionSource/{selectionSourceId}`
                .replace(`{${"selectionSourceId"}}`, encodeURIComponent(String(selectionSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workshopId 
         * @param {string} year 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerFindByWorkshopId: async (workshopId: string, year: string, order?: string, page?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workshopId' is not null or undefined
            if (workshopId === null || workshopId === undefined) {
                throw new RequiredError('workshopId','Required parameter workshopId was null or undefined when calling standardsControllerFindByWorkshopId.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling standardsControllerFindByWorkshopId.');
            }
            const localVarPath = `/standards/workshop/{workshopId}/{year}`
                .replace(`{${"workshopId"}}`, encodeURIComponent(String(workshopId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling standardsControllerFindOne.');
            }
            const localVarPath = `/standards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling standardsControllerRemove.');
            }
            const localVarPath = `/standards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateStandardDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standardsControllerUpdate: async (body: UpdateStandardDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling standardsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling standardsControllerUpdate.');
            }
            const localVarPath = `/standards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StandardsApi - functional programming interface
 * @export
 */
export const StandardsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStandardDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerCreate(body: CreateStandardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Standard>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} pollutionSourceId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindByPollutionSourceId(pollutionSourceId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse2003>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerFindByPollutionSourceId(pollutionSourceId, order, page, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} selectionSourceId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindBySelectionSourceId(selectionSourceId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse2003>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerFindBySelectionSourceId(selectionSourceId, order, page, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} workshopId 
         * @param {string} year 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindByWorkshopId(workshopId: string, year: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse2003>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerFindByWorkshopId(workshopId, year, order, page, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Standard>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Standard>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateStandardDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerUpdate(body: UpdateStandardDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Standard>>> {
            const localVarAxiosArgs = await StandardsApiAxiosParamCreator(configuration).standardsControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StandardsApi - factory interface
 * @export
 */
export const StandardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateStandardDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerCreate(body: CreateStandardDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Standard>> {
            return StandardsApiFp(configuration).standardsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pollutionSourceId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindByPollutionSourceId(pollutionSourceId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse2003>> {
            return StandardsApiFp(configuration).standardsControllerFindByPollutionSourceId(pollutionSourceId, order, page, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} selectionSourceId 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindBySelectionSourceId(selectionSourceId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse2003>> {
            return StandardsApiFp(configuration).standardsControllerFindBySelectionSourceId(selectionSourceId, order, page, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workshopId 
         * @param {string} year 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindByWorkshopId(workshopId: string, year: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse2003>> {
            return StandardsApiFp(configuration).standardsControllerFindByWorkshopId(workshopId, year, order, page, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Standard>> {
            return StandardsApiFp(configuration).standardsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Standard>> {
            return StandardsApiFp(configuration).standardsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateStandardDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standardsControllerUpdate(body: UpdateStandardDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Standard>> {
            return StandardsApiFp(configuration).standardsControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StandardsApi - object-oriented interface
 * @export
 * @class StandardsApi
 * @extends {BaseAPI}
 */
export class StandardsApi extends BaseAPI {
    /**
     * 
     * @param {CreateStandardDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerCreate(body: CreateStandardDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<Standard>> {
        return StandardsApiFp(this.configuration).standardsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} pollutionSourceId 
     * @param {string} [order] 
     * @param {number} [page] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerFindByPollutionSourceId(pollutionSourceId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<InlineResponse2003>> {
        return StandardsApiFp(this.configuration).standardsControllerFindByPollutionSourceId(pollutionSourceId, order, page, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} selectionSourceId 
     * @param {string} [order] 
     * @param {number} [page] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerFindBySelectionSourceId(selectionSourceId: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<InlineResponse2003>> {
        return StandardsApiFp(this.configuration).standardsControllerFindBySelectionSourceId(selectionSourceId, order, page, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} workshopId 
     * @param {string} year 
     * @param {string} [order] 
     * @param {number} [page] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerFindByWorkshopId(workshopId: string, year: string, order?: string, page?: number, take?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<InlineResponse2003>> {
        return StandardsApiFp(this.configuration).standardsControllerFindByWorkshopId(workshopId, year, order, page, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Standard>> {
        return StandardsApiFp(this.configuration).standardsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Standard>> {
        return StandardsApiFp(this.configuration).standardsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateStandardDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardsApi
     */
    public async standardsControllerUpdate(body: UpdateStandardDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Standard>> {
        return StandardsApiFp(this.configuration).standardsControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
