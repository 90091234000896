import { Form, Input, Layout, Table } from "antd";
import { ChangeEvent, useEffect, useRef } from "react";
import { Category, Organization } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { debounce } from "lodash";
import React from "react";
import { fetchCategoryVariableAsync, selectCategoryVariableValueStatus, selectCategoryVariables, updateCategoryVariableCalculationInputAsync } from "../../categoryVariables/categoryVariablesSlice";
import { PageLoading } from "@ant-design/pro-components";

interface PeriodDataTableProps {
  organization?: Organization;
  category?: Category;
  year?: number;
  quarter?: number;
}

export const PeriodDataTable: React.FC<PeriodDataTableProps> = ({ organization, category, year, quarter }) => {

  const dispatch = useAppDispatch();
  const categoryVariables = useAppSelector(selectCategoryVariables);
  const status = useAppSelector(selectCategoryVariableValueStatus);

  const [form] = Form.useForm();

  useEffect(() => {
    if (category) {
      dispatch(fetchCategoryVariableAsync(category.id));
    }
  }, [organization, category, year, quarter, dispatch]);

  useEffect(() => {
    categoryVariables?.map(categoryVariable => {
      form.setFieldsValue({
        [`${categoryVariable.id}`]: categoryVariable.values.filter(value => (value.quarter === quarter && value.year === year))?.[0]?.value,
      });
    });
  }, [categoryVariables]);

  const onChange = (categoryVariableId: string, valueId: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value && year && quarter) {
      dispatch(updateCategoryVariableCalculationInputAsync({
        categoryVariableId, categoryVariableValue: {
          value: parseFloat(value),
          year: year,
          quarter: quarter,
          id: valueId,
        }
      }));
    }
  };

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Данные',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  if (status === "loading") return <PageLoading />;

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {organization && category && categoryVariables && year && quarter &&
        <Form form={form}>
          <Table
            bordered
            columns={columns}
            pagination={false}
            dataSource={categoryVariables?.map(categoryVariable => {
              return {
                key: categoryVariable.id,
                name: categoryVariable.name,
                value:
                  <Form.Item name={categoryVariable.id} key={categoryVariable.id}>
                    <Input name={categoryVariable.id} type="number" onChange={debounce(onChange(categoryVariable.id, categoryVariable.values.filter(value => (value.quarter === quarter && value.year === year))?.[0]?.id), 1000)} style={{ width: '100%' }} />
                  </Form.Item>,
              }
            })}
          />
        </Form>}
    </Layout>
  );
};
