import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { IndustrialSite, Workshop } from "../../../api";
import { EntityColumns } from "../../../app/types";
import { useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";

export const WorkshopColumns = (): EntityColumns<Workshop>[] => {

  return [
    {
      title: "Площадка",
      dataIndex: "industrialSite",
      key: "industrialSite",
      inputNode: <></>,
      render: (industrialSite: IndustrialSite) => (
        <Text className="w-64" ellipsis={{ tooltip: industrialSite?.name }}>
          {industrialSite?.name}
        </Text>
      ),
      editable: false,
      copyable: true,
    },
    {
      title: "Код производства",
      dataIndex: "code",
      key: "code",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => (
        <Text className="w-64" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      editable: true,
      copyable: true,
    },
  ];
};
