import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateTaxEmissionDto, PollutionSubstance, TaxEmission, UpdateTaxEmissionDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchPollutionSubstancesAsync } from "../pollutionSubstances/pollutionSubstancesSlice";
import { TaxEmissionForm } from "./components/TaxEmissionForm";
import { TaxEmissionsColumns } from "./form/columns";
import { selectTaxEmissions, updateTaxEmissionAsync, createTaxEmissionAsync, deleteTaxEmissionAsync, fetchTaxEmissionsAsync } from "./TaxEmissionsSlice";

export const TaxEmissionsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const taxEmissions = useAppSelector(selectTaxEmissions);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchTaxEmissionsAsync());
    dispatch(fetchPollutionSubstancesAsync());
  }, [dispatch]);

  const onUpdateTaxEmission = async (taxEmission: UpdateTaxEmissionDto, id: string) => {
    return dispatch(updateTaxEmissionAsync({ taxEmission, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateTaxEmission = (taxEmission: CreateTaxEmissionDto) => {

    dispatch(createTaxEmissionAsync({ ...taxEmission })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteTaxEmission = (taxEmission: TaxEmission) => {
    return dispatch(deleteTaxEmissionAsync({ taxEmission })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <TaxEmissionForm visible={visible} setVisible={setVisible} onCreateTaxEmission={onCreateTaxEmission} />}
      <EditableTable<TaxEmission, UpdateTaxEmissionDto>
        onSave={onUpdateTaxEmission}
        onDelete={deleteTaxEmission}
        dataSource={structuredClone(taxEmissions).map((data: any) => {
          const pollutionSubstances = data?.pollutionSubstances?.map((pollutionSubstance: PollutionSubstance) => pollutionSubstance?.code);
          delete data?.pollutionSubstances;
          data.pollutionSubstances = pollutionSubstances;
          return data;
        }) as any}
        entityColumns={TaxEmissionsColumns()}
        footer={() => addButton}
      />
    </Layout>
  );
};
