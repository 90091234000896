import { RadarChartOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Layout, Modal, Tabs, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { CreatePollutionSourceDto, PollutionSource, UpdatePollutionSourceDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { SelectionSourcesComponent } from "../selectionSources/SelectionSourceComponent";
import { selectSelectionSourcesLength } from "../selectionSources/selectionSourcesSlice";
import { PollutionSourceForm } from "./components/PollutionSourceForm";
import { pollutionSourcesColumns } from "./form/columns";
import {
  createPollutionSourceAsync,
  fetchPollutionSourceAsync,
  removePollutionSourceAsync,
  selectPollutionSources,
  updatePollutionSourceAsync,
} from "./pollutionSourcesSlice";
import { useParams } from "react-router";
import { history } from "../../app/helpers";
import { Link } from "react-router-dom";
import { fetchWorkshopProfileAsync, selectWorkshopProfile } from "../workshops/workshopsSlice";
import {
  RollbackOutlined,
} from '@ant-design/icons';

export const PollutionSourcesComponent: React.FC = () => {
  let { workshopId } = useParams();

  const dispatch = useAppDispatch();
  const pollutionSources = useAppSelector(selectPollutionSources);
  const workshopProfile = useAppSelector(selectWorkshopProfile);
  const selectionSourceLength = useAppSelector(selectSelectionSourcesLength);
  const [whichPollutionSource, setWhichPollutionSource] = useState<PollutionSource | undefined>(undefined);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!workshopId) return;

    dispatch(fetchPollutionSourceAsync(workshopId));
    dispatch(fetchWorkshopProfileAsync(workshopId))
  }, [workshopId, dispatch]);

  const onCreatePollutionSource = (pollutionSource: CreatePollutionSourceDto) => {
    if (!workshopId) return;

    dispatch(createPollutionSourceAsync({ ...pollutionSource, workshopId: workshopId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdate = async (pollutionSource: UpdatePollutionSourceDto, id: string) => {
    return dispatch(updatePollutionSourceAsync({ pollutionSource, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onDelete = async (record: PollutionSource) => {
    return dispatch(removePollutionSourceAsync(record.id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">

      <Breadcrumb className="mb-4 pollution-bread">
        <Breadcrumb.Item>
          <Tooltip title="Вернуться к списку цехов">
            <Button type="link" onClick={() => { history.navigate && history.navigate(`/organizations/${history?.location?.pathname.split('/')[2]}/module/directory/organizationSource/workshops`) }} icon={<RollbackOutlined />} />
          </Tooltip>
          <Link to={`/organizations/${history?.location?.pathname.split('/')[2]}/module/directory/organizationSource/workshops`}>Цеха</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Источники выбросов "{workshopProfile?.name}"</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      {visible && <PollutionSourceForm visible={visible} setVisible={setVisible} onCreatePollutionSource={onCreatePollutionSource} />}
      <EditableTable<PollutionSource, UpdatePollutionSourceDto>
        onSave={onUpdate}
        onDelete={onDelete}
        onWatch={setWhichPollutionSource}
        entityColumns={pollutionSourcesColumns()}
        dataSource={structuredClone(pollutionSources).map((data: any) => {
          const material = data?.material?.id;
          delete data?.material;
          data.material = material;
          return data;
        }) as any}
        footer={() => addButton}
      />
      {whichPollutionSource && (
        <Modal
          open={true}
          title={`Источник выбросов - ${whichPollutionSource.name}`}
          onCancel={() => setWhichPollutionSource(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"90%"}
        >
          <Tabs
            destroyInactiveTabPane={true}
            type="card"
            items={[
              {
                label: (
                  <span>
                    <RadarChartOutlined /> Источники выделения ({selectionSourceLength})
                  </span>
                ),
                key: "selectionSources",
                children: <SelectionSourcesComponent pollutionSourceId={whichPollutionSource.id} />,
              },
              // {
              //   label: (
              //     <span>
              //       <ClearOutlined />
              //       Очистные сооружения ({whichPollutionSource.totalCleaningEquipmentCount})
              //     </span>
              //   ),
              //   key: "cleaningEquipments",
              //   children: <CleaningEquipmentsComponent pollutionSourceId={whichPollutionSource.id} />,
              // },
            ]}
          />
        </Modal>
      )}
    </Layout>
  );
};
