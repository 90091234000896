import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateReportPekDto, ReportPek, UpdateReportPekDto } from "../../api";
import { BASE_PATH } from "../../api/base";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { ReportPekForm } from "./components/ReportPeksForm";
import { ReportPeksColumns } from "./form/columns";
import { createReportPekAsync, updateReportPekAsync, selectReportPeks, deleteReportPekAsync, fetchReportPeksByQuarterAsync, generateReportPekAsync, selectStatusReportPeks } from "./ReportPeksSlice";
import { PageLoading } from "@ant-design/pro-components";

interface ReportPeksComponentProps {
  organizationId?: string;
  year?: number;
  quarter?: number;
}

export const ReportPeksComponent: React.FC<ReportPeksComponentProps> = ({ organizationId, year, quarter }) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const reportPeks = useAppSelector(selectReportPeks);
  const status = useAppSelector(selectStatusReportPeks);

  useEffect(() => {
    if (organizationId) {
      if (year && quarter) {
        dispatch(fetchReportPeksByQuarterAsync({ organizationId, year, quarter }));
      } else {
        dispatch(fetchReportPeksByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
      }
    }
  }, [year, quarter, organizationId, dispatch]);

  const onUpdate = async (reportPek: UpdateReportPekDto, id: string) => {
    return dispatch(updateReportPekAsync({ reportPek, id })).then((res: any) => {
      if (!res.error) {
        if (organizationId && year && quarter) {
          dispatch(fetchReportPeksByQuarterAsync({ organizationId, year, quarter }));
        }
        return true;
      }
      return false;
    });
  };

  const onCreateReportPek = (reportPek: CreateReportPekDto) => {
    if (!organizationId || !year || !quarter) return;

    dispatch(createReportPekAsync({ ...reportPek, organizationId, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteReportPeks = (reportPek: ReportPek) => {
    return dispatch(deleteReportPekAsync({ reportPek })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const generate = async (id: string) => {
    return dispatch(generateReportPekAsync({ id })).then((res: any) => {
      if (!res.error) {
        if (organizationId && year && quarter) {
          dispatch(fetchReportPeksByQuarterAsync({ organizationId, year, quarter }));
        }
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить отчет
    </Button>
  );

  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        {visible && <ReportPekForm visible={visible} setVisible={setVisible} onCreateReportPek={onCreateReportPek} organizationId={organizationId} />}
        {(status === 'loading') && <PageLoading />}
        <div style={{ display: ((status === 'loading') ? 'none' : 'block') }} className="table-responsive">
          <EditableTable<ReportPek, UpdateReportPekDto>
            onSave={onUpdate}
            onDelete={deleteReportPeks}
            entityColumns={
              [...ReportPeksColumns(), {
                title: "Операции",
                dataIndex: "operation" as keyof ReportPek,
                key: "operation",
                inputNode: <></>,
                render: (value: any, record: ReportPek, index: any) => {
                  if (record.isGenerated) {
                    return <Button.Group><Button href={`${BASE_PATH}/report-pek/${record.id}/download`} block type="primary" ghost>Скачать</Button></Button.Group>;
                  } else {
                    return <Button.Group><Button onClick={() => { generate(record.id) }}>Сформировать</Button></Button.Group>;
                  }
                },
                editable: false,
                copyable: false,
              },]
            }
            dataSource={reportPeks}
            footer={() => addButton}
          />
        </div>
      </Layout>
    </>
  );
};
