import { Button, Layout, Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import { CreateIndustrialSiteDto, IndustrialSite, UpdateIndustrialSiteDto } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WorkshopsComponent } from "../workshops/WorkshopsComponent";
import { IndustrialSitesComponent } from "../industrialSites/IndustrialSitesComponent";
import { PollutionSourcesComponent } from "../pollutionSources/PollutionSourcesComponent";
import { fetchWorkshopsAsync } from "../workshops/workshopsSlice";
import { Route, Routes } from "react-router";
import { history } from "../../app/helpers";
import { CategoriesComponent } from "../categories/CategoriesComponent";

interface OrganizationSourceComponentProps {
  industrialSites?: IndustrialSite[];
  organizationId?: string;
  code?: string;
}

export const OrganizationSourceComponent: React.FC<OrganizationSourceComponentProps> = ({ organizationId = "", industrialSites, code = "" }) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [whichIndustrialWorkshops, setWhichIndustrialWorkshops] = useState<IndustrialSite | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchWorkshopsAsync(organizationId));
  }, [organizationId, dispatch]);

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Tabs
        destroyInactiveTabPane={true}
        tabPosition="left"
        onChange={(path) => {
          let tabPath = history?.location?.pathname.split('/') || [];
          tabPath[6] = path;
          history.navigate && history.navigate(`${tabPath.join('/')}`);
        }}
        defaultActiveKey={history?.location?.pathname.split('/')[6]}
        items={[
          {
            label: `Площадки`,
            key: "industrialSites",
            children: <IndustrialSitesComponent organizationId={organizationId} industrialSites={industrialSites} />,
          },
          {
            label: `Цеха`,
            key: "workshops",
            children: <WorkshopsComponent organizationId={organizationId} />,
          },
          {
            label: `Категории`,
            key: "categories",
            children: <CategoriesComponent organizationId={organizationId} />,
          },
        ]}
      />
    </Layout>
  );
};
