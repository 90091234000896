import { InputNumber, Select, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Standard } from "../../../api";
import { EntityColumns } from "../../../app/types";
import { useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";

const StandardsColumns = (pollutionSubstances: DefaultOptionType[]): EntityColumns<Standard>[] => {
  const organization = useAppSelector(selectOrganizationsProfile);

  return [
    // {
    //   title: "На какой год норматив",
    //   dataIndex: "year",
    //   key: "year",
    //   inputNode: <InputNumber className="w-full" placeholder="2022" />,
    //   rules: [
    //     { required: true, message: "Заполните поле" },
    //     { pattern: new RegExp(/(?:(?:19|20)[0-9]{2})/), message: "Год неправильного формата" },
    //   ],
    //   editable: true,
    //   copyable: true,
    // },
    // {
    //   title: "Разрешение",
    //   dataIndex: "permission",
    //   key: "permissionId",
    //   inputNode: (
    //     <Select
    //       options={organization?.permissions?.map((permission) => {
    //         return {
    //           value: permission.id,
    //           label: permission.name,
    //         };
    //       })}
    //     />
    //   ),
    //   render: (perm) => (
    //     <Typography.Text className="w-64" ellipsis={{ tooltip: `${organization?.permissions?.find((permission) => permission.id === perm)?.name}` }}>
    //       {organization?.permissions?.find((permission) => permission.id === perm)?.name}
    //     </Typography.Text>
    //   ),
    //   rules: [{ required: true, message: "Заполните поле" }],
    //   editable: true,
    //   copyable: true,
    // },
    {
      title: "Код вещества",
      dataIndex: "pollutionSubstance",
      key: "pollutionSubstance",
      inputNode: <Select showSearch placeholder={"Выберите код загрезняющего вещества"} options={pollutionSubstances} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Лимит г/с",
      dataIndex: "valueGramPerSecond",
      key: "valueGramPerSecond",
      inputNode: <InputNumber className="w-full" />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Лимит т/г",
      dataIndex: "valueTonPerYear",
      key: "valueTonPerYear",
      inputNode: <InputNumber className="w-full" />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];
};

export const standardsColumns = StandardsColumns;
