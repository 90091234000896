import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterConsumptionIntakeDto, WaterConsumptionIntakeApi, UpdateWaterConsumptionIntakeDto, WaterConsumptionIntake } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WaterConsumptionIntakeState {
  waterConsumptionIntake: WaterConsumptionIntake[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterConsumptionIntakeState = {
  waterConsumptionIntake: [],
  status: "idle",
};

export const createWaterConsumptionIntakeAsync = createAsyncThunk(
  "waterConsumptionIntake/create",
  async (waterConsumptionIntake: CreateWaterConsumptionIntakeDto, { rejectWithValue }) => {
    try {
      const waterConsumptionIntakeApi = new WaterConsumptionIntakeApi();
      const response = await waterConsumptionIntakeApi.waterConsumptionIntakeControllerCreate(waterConsumptionIntake);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterConsumptionIntakeAsync = createAsyncThunk(
  "waterConsumptionIntake/update",
  async ({ waterConsumptionIntake, id }: { waterConsumptionIntake: UpdateWaterConsumptionIntakeDto; id: string }, { rejectWithValue }) => {
    try {
      const waterConsumptionIntakeApi = new WaterConsumptionIntakeApi();
      const response = await waterConsumptionIntakeApi.waterConsumptionIntakeControllerUpdate(waterConsumptionIntake, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterConsumptionIntakeAsync = createAsyncThunk(
  "waterConsumptionIntake/remove",
  async ({ waterConsumptionIntake }: { waterConsumptionIntake: WaterConsumptionIntake }, { rejectWithValue }) => {
    try {
      const waterConsumptionIntakeApi = new WaterConsumptionIntakeApi();
      const response = await waterConsumptionIntakeApi.waterConsumptionIntakeControllerRemove(waterConsumptionIntake.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterConsumptionIntakeByOrganizationIdAsync = createAsyncThunk("waterConsumptionIntake/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const waterConsumptionIntakeApi = new WaterConsumptionIntakeApi();
    const response = await waterConsumptionIntakeApi.waterConsumptionIntakeControllerFindByOrganizationid(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterConsumptionIntakeByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWaterConsumptionIntakeByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionIntakeState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<WaterConsumptionIntake[]>) => {
        state.waterConsumptionIntake = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterConsumptionIntake = () => {
    const { pending, fulfilled, rejected } = createWaterConsumptionIntakeAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionIntakeState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<WaterConsumptionIntake>) => {
        state.waterConsumptionIntake.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterConsumptionIntake = () => {
    const { pending, fulfilled, rejected } = updateWaterConsumptionIntakeAsync;

    return {
      [`${pending}`]: (state: WaterConsumptionIntakeState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<WaterConsumptionIntake>) => {
        state.waterConsumptionIntake = state.waterConsumptionIntake.map((waterConsumptionIntake) => (waterConsumptionIntake.id === action.payload.id ? action.payload : waterConsumptionIntake));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterConsumptionIntake = () => {
    const { pending, fulfilled, rejected } = deleteWaterConsumptionIntakeAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionIntakeState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<WaterConsumptionIntake>) => {
        state.waterConsumptionIntake.splice(state.waterConsumptionIntake.indexOf(
          state.waterConsumptionIntake.find((ws: WaterConsumptionIntake) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionIntakeState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterConsumptionIntakeByOrganization(), ...createWaterConsumptionIntake(), ...updateWaterConsumptionIntake(), ...removeWaterConsumptionIntake() };
};

export const waterConsumptionIntakeSlice = createSlice({
  name: "waterConsumptionIntake",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterConsumptionIntake = (state: RootState) => state.waterConsumptionIntake.waterConsumptionIntake;

export default waterConsumptionIntakeSlice.reducer;
