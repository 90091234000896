import { Form, Modal } from "antd";
import React from "react";
import { CreateCategoryVariableDto } from "../../../api";
import { categoryVariablesColumns } from "../form/columns";

interface PollutionSoucreFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateCategoryVariable: (values: CreateCategoryVariableDto) => void;
}

export const CategoryVariableForm: React.FC<PollutionSoucreFormProps> = ({ visible, setVisible, onCreateCategoryVariable }) => {
  const [form] = Form.useForm<CreateCategoryVariableDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateCategoryVariable}>
        {categoryVariablesColumns().map(({ title, key, rules, inputNode }) => (
          <Form.Item label={title} name={key} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
