import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteEquipmentRecycleDto } from "../../../api";
import { wasteEquipmentRecyclesColumns } from "../form/columns";

interface WasteEquipmentRecycleFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteEquipmentRecycle: (values: CreateWasteEquipmentRecycleDto) => void;
}

export const WasteEquipmentRecycleForm: React.FC<WasteEquipmentRecycleFormProps> = ({ visible, setVisible, onCreateWasteEquipmentRecycle }) => {
  const [form] = Form.useForm<CreateWasteEquipmentRecycleDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteEquipmentRecycle}>
        {wasteEquipmentRecyclesColumns.map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
