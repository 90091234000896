import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DefaultOptionType } from "antd/es/select";
import Text from "antd/es/typography/Text";
import { AnalyticsProductionForKpi } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { EntityColumns } from "../../../../app/types";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";

export const AnalyticsProductionForKpisColumns = (): EntityColumns<AnalyticsProductionForKpi>[] => {
  const period = useAppSelector(selectOrganizationModulePeriod);

  const volumeTypeList : DefaultOptionType[] = 
  [
    {value: 'PROCESSING_VOLUME', label: 'Объем переработки'},
    {value: 'OVERBURDEN_VOLUME', label: 'Объем вскрыши'},
    {value: 'PRODUCTION_VOLUME', label: 'Объем продукции'},
  ]
  
  function getVolumeTypeLabel(value: String) {
    return volumeTypeList.find(item => item.value === value)?.label
  }

  return [
    {
      title: "Производственный показатель для расчета KPI",
      dataIndex: "volumeType",
      key: "volumeType",
      inputNode: <Select options={volumeTypeList} />,
      render: (value) => <Text className="w-64" ellipsis={{ tooltip: `${getVolumeTypeLabel(value)}` }}>{getVolumeTypeLabel(value)}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Единица измерения",
      dataIndex: "measure",
      key: "measure",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Январь",
      dataIndex: "jan",
      key: "jan",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Февраль",
      dataIndex: "feb",
      key: "feb",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Март",
      dataIndex: "mar",
      key: "mar",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Апрель",
      dataIndex: "apr",
      key: "apr",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Май",
      dataIndex: "may",
      key: "may",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Июнь",
      dataIndex: "jun",
      key: "jun",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Июль",
      dataIndex: "jul",
      key: "jul",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Август",
      dataIndex: "aug",
      key: "aug",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Сентябрь",
      dataIndex: "sep",
      key: "sep",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Октябрь",
      dataIndex: "oct",
      key: "oct",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ноябрь",
      dataIndex: "nov",
      key: "nov",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Декабрь",
      dataIndex: "dec",
      key: "dec",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Итого",
      dataIndex: "total",
      key: "total",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
