import { Form, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { Standard, UpdateStandardDto } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSources } from "../../pollutionSources/pollutionSourcesSlice";
import { selectPollutionSubstancesInputOptions } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { selectSelectionSources } from "../../selectionSources/selectionSourcesSlice";
import { standardsColumns } from "../form/columns";

interface WorkshopStandardUpdateProps {
  standard: Standard;
  visible: boolean;
  setVisible: Function;
  onUpdateStandard: (values: UpdateStandardDto, id: string) => void;
}

export const WorkshopStandardUpdateForm: React.FC<WorkshopStandardUpdateProps> = ({ standard, visible, setVisible, onUpdateStandard }) => {
  const [form] = Form.useForm<UpdateStandardDto>();
  const pollutionSubstances = useAppSelector(selectPollutionSubstancesInputOptions);
  const selectionSources = useAppSelector(selectSelectionSources);

  useEffect(() => {
    form.setFieldsValue({ ...standard, pollutionSubstance: standard.pollutionSubstance.code, selectionSourceId: standard.selectionSource.id });
  }, [standard, form]);

  const onFinish = (values: UpdateStandardDto) => {
    onUpdateStandard(values, standard.id);
  };

  return (
    <Modal open={visible} onOk={form.submit} okText="Обновить" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onFinish}>
        {standardsColumns(pollutionSubstances).map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
        <Form.Item name="selectionSourceId" label="Источник выделения" rules={[{ required: true, message: "Выберите источник" }]}>
          <Select
            showSearch
            filterOption={(input, option) => {
              if (option) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              } else {
                return true;
              }
            }}
            placeholder={"Выберите источник выделения"}
            options={selectionSources.map((source) => ({ value: source.id, label: `${source?.pollutionSource?.inventoryNumber}-${source.inventoryNumber} ${source.name}` }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
