import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateSoilPointPollutantDto, SoilPointPollutant, UpdateSoilPointPollutantDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { SoilPointPollutantForm } from "./components/SoilPointPollutantForm";
import { soilPointPollutantsColumns } from "./form/columns";
import { createSoilPointPollutantAsync, updateSoilPointPollutantAsync, selectSoilPointPollutants, deleteSoilPointPollutantAsync, fetchSoilPointPollutantsByPointAsync } from "./SoilPointPollutantsSlice";

interface SoilPointPollutantsComponentProps {
  pointId?: string;
}

export const SoilPointPollutantsComponent: React.FC<SoilPointPollutantsComponentProps> = ({ pointId }) => {
  const dispatch = useAppDispatch();
  const soilPointPollutants = useAppSelector(selectSoilPointPollutants);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (pointId) {
      dispatch(fetchSoilPointPollutantsByPointAsync(pointId));
    }
  }, [pointId, dispatch]);

  const onUpdate = async (soilPointPollutant: UpdateSoilPointPollutantDto, id: string) => {
    return dispatch(updateSoilPointPollutantAsync({ soilPointPollutant, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateSoilPointPollutant = (soilPointPollutant: CreateSoilPointPollutantDto) => {
    if (!pointId) return;

    dispatch(createSoilPointPollutantAsync({ ...soilPointPollutant, pointId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteSoilPointPollutants = (soilPointPollutant: SoilPointPollutant) => {
    return dispatch(deleteSoilPointPollutantAsync({ soilPointPollutant })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить загрязнение
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <SoilPointPollutantForm visible={visible} setVisible={setVisible} onCreateSoilPointPollutant={onCreateSoilPointPollutant} />}
      <EditableTable<SoilPointPollutant, UpdateSoilPointPollutantDto>
        onSave={onUpdate}
        onDelete={deleteSoilPointPollutants}
        entityColumns={soilPointPollutantsColumns}
        dataSource={soilPointPollutants}
        footer={() => addButton}
      />
    </Layout>
  );
};
