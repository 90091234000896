import { Form, Modal, Select } from "antd";
import React from "react";
import { CreateCategoryDto } from "../../../api";
import { CategoryColumns } from "../form/columns";
import { useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";

interface CategoryFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateCategory: (values: CreateCategoryDto) => void;
}

export const CategoryForm: React.FC<CategoryFormProps> = ({ visible, setVisible, onCreateCategory }) => {
  const organization = useAppSelector(selectOrganizationsProfile);

  const [form] = Form.useForm<CreateCategoryDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateCategory}>
        {CategoryColumns().map(({ title, dataIndex, key, rules, inputNode }) => (
          <Form.Item label={title} name={key} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
