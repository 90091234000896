import { Input, Select } from "antd";
import { Material, MaterialTypeEnum } from "../../../api";
import { EntityColumns } from "../../../app/types";
import Text from "antd/es/typography/Text";

export const materialsColumns: EntityColumns<Material>[] =
  [
    {
      title: "Вид",
      dataIndex: "type",
      key: "type",
      inputNode: <Select options={[
        {
          label: 'Топливо',
          value: MaterialTypeEnum.Fuel,
        },
        {
          label: 'Сырьевой материал',
          value: MaterialTypeEnum.RawMaterial,
        }
      ]} />,
      rules: [{ required: true, message: "Заполните поле" }],
      render: (type) => {
        const label = type === MaterialTypeEnum.Fuel ? 'Топливо' : 'Сырьевой материал';
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>;
      },
      editable: true,
      copyable: true,
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];