import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteApplication16RecordDto, WasteApplication16Record, UpdateWasteApplication16RecordDto, Organization, UserRolesEnum } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { fetchWasteContragentsByOrganizationAsync } from "../../wasteContragents/WasteContragentsSlice";
import { WasteApplication16RecordForm } from "./components/WasteApplication16RecordForm";
import { WasteApplication16RecordsColumns } from "./form/columns";
import { createWasteApplication16RecordAsync, updateWasteApplication16RecordAsync, selectWasteApplication16Records, deleteWasteApplication16RecordAsync, fetchWasteApplication16RecordsByMonthAsync } from "./WasteApplication16RecordsSlice";
import { selectAuthUser } from "../../auth/authSlice";
import dayjs from "dayjs";

interface WasteApplication16RecordsComponentProps {
  organization?: Organization;
  year: number;
  quarter: number;
  month: number;
}

export const WasteApplication16RecordsComponent: React.FC<WasteApplication16RecordsComponentProps> = ({ organization, year, quarter, month }) => {
  const dispatch = useAppDispatch();
  const wasteApplication16Records = useAppSelector(selectWasteApplication16Records);
  const authUser = useAppSelector(selectAuthUser);
  let superRoles = [
    UserRolesEnum.Admin,
    UserRolesEnum.Head,
    UserRolesEnum.Ecolog,
    UserRolesEnum.Chief,
  ];

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWasteApplication16RecordsByMonthAsync({ organizationId: organization.id, year: year, quarter: quarter, month: month }));
      dispatch(fetchWasteContragentsByOrganizationAsync(organization.id));
    }
  }, [organization, year, quarter, month, dispatch]);

  const onUpdate = async (wasteApplication16Record: UpdateWasteApplication16RecordDto, id: string) => {
    return dispatch(updateWasteApplication16RecordAsync({ wasteApplication16Record, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteApplication16Record = (wasteApplication16Record: CreateWasteApplication16RecordDto) => {
    if (!organization || !year || !quarter || !authUser) return;

    let params;

    if ((superRoles.find((sr) => authUser?.roles.includes(sr)))) {
      params = { ...wasteApplication16Record, organizationId: organization.id, year, quarter, month };
    } else {
      params = { ...wasteApplication16Record, organizationId: organization.id, year, quarter, month, role: authUser.roles[0] as any };
    }

    dispatch(createWasteApplication16RecordAsync(params)).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteApplication16Records = (wasteApplication16Record: WasteApplication16Record) => {
    return dispatch(deleteWasteApplication16RecordAsync({ wasteApplication16Record })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить запись
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteApplication16RecordForm visible={visible} setVisible={setVisible} onCreateWasteApplication16Record={onCreateWasteApplication16Record} />}
      <EditableTable<WasteApplication16Record, UpdateWasteApplication16RecordDto>
        onSave={onUpdate}
        onDelete={deleteWasteApplication16Records}
        entityColumns={WasteApplication16RecordsColumns()}
        dataSource={structuredClone(wasteApplication16Records).map((data: any) => {
          const wasteContragent = data?.wasteContragent?.id;
          delete data?.wasteContragent;
          data.wasteContragent = wasteContragent;

          if (data.date) {
            const date = data.productionDate;
            delete data.date;
            data.date = dayjs(date);
          }

          return data;
        }) as any}

        footer={() => addButton}
      />
    </Layout>
  );
};
