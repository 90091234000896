import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiError } from "../../app/types";
import { RopPackagingClassifiersApi } from "../../api";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { RopPackagingClassifier } from "../../api/models/rop-packaging-classifier";

export interface RopPackagingClassifierState {
    classifiers: RopPackagingClassifier[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: RopPackagingClassifierState = {
    classifiers: [],
    status: "idle",
};

export const fetchRopPackagingClassifiersAsync = createAsyncThunk(
    "ropPackagingClassifiers/fetch", async (_, { rejectWithValue }) => {
        try {
            const classifierApi = new RopPackagingClassifiersApi();
            const response = await classifierApi.ropPackagingClassifiersControllerFindAll();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchRopPackagingClassifiers = () => {
        const { pending, fulfilled, rejected } = fetchRopPackagingClassifiersAsync;
        return {
            [`${pending}`]: (state: RopPackagingClassifierState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopPackagingClassifierState, action: PayloadAction<RopPackagingClassifier[]>) => {
                state.classifiers = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopPackagingClassifierState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    return { ...fetchRopPackagingClassifiers()};
}

export const ropPackagingClassifiersSlice = createSlice({
    name: "ropPackagingClassifiers",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
  });
  export const selectRopPackagingQualifiers = (state: RootState) => state.ropPackagingClassifiers.classifiers;
  
  export default ropPackagingClassifiersSlice.reducer;
