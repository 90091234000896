/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateWasteManagementDocumentDto } from '../models/create-waste-management-document-dto';
import { getFromStorage } from '../../app/helpers';
import { UpdateWasteManagementDocumentDto } from '../models/update-waste-management-document-dto';
import { WasteManagementDocument } from '../models/waste-management-document';

/**
 * WasteManagementDocumentsApi - axios parameter creator
 * @export
 */
export const WasteManagementDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteManagementDocumentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementDocumentsControllerCreate: async (body: CreateWasteManagementDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteManagementDocumentsControllerCreate.');
            }
            const localVarPath = `/WasteManagement-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementDocumentsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WasteManagement-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementDocumentsControllerFindByQuart: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling wasteManagementDocumentsControllerFindByQuart.');
            }
          
            const localVarPath = `/WasteManagement-documents/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementDocumentsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteManagementDocumentsControllerFindOne.');
            }
            const localVarPath = `/WasteManagement-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementDocumentsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteManagementDocumentsControllerRemove.');
            }
            const localVarPath = `/WasteManagement-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWasteManagementDocumentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementDocumentsControllerUpdate: async (body: UpdateWasteManagementDocumentDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteManagementDocumentsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteManagementDocumentsControllerUpdate.');
            }
            const localVarPath = `/WasteManagement-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WasteManagementDocumentsApi - functional programming interface
 * @export
 */
export const WasteManagementDocumentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteManagementDocumentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerCreate(body: CreateWasteManagementDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteManagementDocument>>> {
            const localVarAxiosArgs = await WasteManagementDocumentsApiAxiosParamCreator(configuration).wasteManagementDocumentsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteManagementDocument>>>> {
            const localVarAxiosArgs = await WasteManagementDocumentsApiAxiosParamCreator(configuration).wasteManagementDocumentsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerFindByQuart(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteManagementDocument>>>> {
            const localVarAxiosArgs = await WasteManagementDocumentsApiAxiosParamCreator(configuration).wasteManagementDocumentsControllerFindByQuart(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteManagementDocument>>> {
            const localVarAxiosArgs = await WasteManagementDocumentsApiAxiosParamCreator(configuration).wasteManagementDocumentsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteManagementDocument>>> {
            const localVarAxiosArgs = await WasteManagementDocumentsApiAxiosParamCreator(configuration).wasteManagementDocumentsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateWasteManagementDocumentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerUpdate(body: UpdateWasteManagementDocumentDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteManagementDocument>>> {
            const localVarAxiosArgs = await WasteManagementDocumentsApiAxiosParamCreator(configuration).wasteManagementDocumentsControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WasteManagementDocumentsApi - factory interface
 * @export
 */
export const WasteManagementDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateWasteManagementDocumentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerCreate(body: CreateWasteManagementDocumentDto, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteManagementDocument>> {
            return WasteManagementDocumentsApiFp(configuration).wasteManagementDocumentsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteManagementDocument>>> {
            return WasteManagementDocumentsApiFp(configuration).wasteManagementDocumentsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerFindByQuart(organizationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteManagementDocument>>> {
            return WasteManagementDocumentsApiFp(configuration).wasteManagementDocumentsControllerFindByQuart(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteManagementDocument>> {
            return WasteManagementDocumentsApiFp(configuration).wasteManagementDocumentsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteManagementDocument>> {
            return WasteManagementDocumentsApiFp(configuration).wasteManagementDocumentsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWasteManagementDocumentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementDocumentsControllerUpdate(body: UpdateWasteManagementDocumentDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteManagementDocument>> {
            return WasteManagementDocumentsApiFp(configuration).wasteManagementDocumentsControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WasteManagementDocumentsApi - object-oriented interface
 * @export
 * @class WasteManagementDocumentsApi
 * @extends {BaseAPI}
 */
export class WasteManagementDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {CreateWasteManagementDocumentDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteManagementDocumentsApi
     */
    public async wasteManagementDocumentsControllerCreate(body: CreateWasteManagementDocumentDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteManagementDocument>> {
        return WasteManagementDocumentsApiFp(this.configuration).wasteManagementDocumentsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteManagementDocumentsApi
     */
    public async wasteManagementDocumentsControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteManagementDocument>>> {
        return WasteManagementDocumentsApiFp(this.configuration).wasteManagementDocumentsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteManagementDocumentsApi
     */
    public async wasteManagementDocumentsControllerFindByQuart(organizationId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteManagementDocument>>> {
        return WasteManagementDocumentsApiFp(this.configuration).wasteManagementDocumentsControllerFindByQuart(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteManagementDocumentsApi
     */
    public async wasteManagementDocumentsControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteManagementDocument>> {
        return WasteManagementDocumentsApiFp(this.configuration).wasteManagementDocumentsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteManagementDocumentsApi
     */
    public async wasteManagementDocumentsControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteManagementDocument>> {
        return WasteManagementDocumentsApiFp(this.configuration).wasteManagementDocumentsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateWasteManagementDocumentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteManagementDocumentsApi
     */
    public async wasteManagementDocumentsControllerUpdate(body: UpdateWasteManagementDocumentDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteManagementDocument>> {
        return WasteManagementDocumentsApiFp(this.configuration).wasteManagementDocumentsControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
