import { Button, Form, Input, InputNumber, Modal, Select, Upload, UploadFile } from "antd";
import React, { useState } from "react";
import { CreateRopCalculationDto } from "../../../api/models/create-rop-calculation-dto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";
import { selectRopGoodQualifiers } from "../RopGoodClassifierSlice";
import { selectRopPackagingQualifiers } from "../RopPackagingClassifierSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { DefaultOptionType } from "antd/es/select";
import { UploadOutlined } from '@ant-design/icons';
import { BASE_PATH } from "../../../api/base";
import { getFromStorage } from "../../../app/helpers";
import { deleteFileAsync } from "../../files/FilesEntitySlice";

interface RopCalculationsFormProps {
    visible: boolean;
    setVisible: Function;
    onCreateRopCalculations: (values: CreateRopCalculationDto) => void;
    organizationId?: string;
}

export const RopCalculationsForm: React.FC<RopCalculationsFormProps> = ({ visible, setVisible, onCreateRopCalculations, organizationId }) => {
    const [form] = Form.useForm<any>();
    const dispatch = useAppDispatch();
    const ropGoodsClassifiers = useAppSelector(selectRopGoodQualifiers);
    const ropPackagingClassifiers = useAppSelector(selectRopPackagingQualifiers);
    const organization = useAppSelector(selectOrganizationsProfile);
    const period = useAppSelector(selectOrganizationModulePeriod);

    let [selectedGoodClassifier, setGoodClassifier] = useState<string>("");
    let ropGoodsClassifiersLabels: DefaultOptionType[] = [];
    for (const classifier of ropGoodsClassifiers) {
        ropGoodsClassifiersLabels = [...ropGoodsClassifiersLabels, {
            value: classifier.id,
            label: classifier.name,
            coefficient: classifier.coefficient
        }]
    }
    let [selectedPackagingClassifier, setPackagingClassifier] = useState<string>("");
    let ropPackagingClassifiersLabels: DefaultOptionType[] = [];
    for (const classifier of ropPackagingClassifiers) {
        ropPackagingClassifiersLabels = [...ropPackagingClassifiersLabels, {
            value: classifier.id,
            label: classifier.name,
            coefficient: classifier.coefficient
        }]
    }

    const [ids, setIds] = useState<string[]>([]);

    const onFinish = (value: any) => {
        const dto: CreateRopCalculationDto = {
            declarationNumber: value.declarationNumber,
            codeTNVEDEAEU: value.codeTNVEDEAEU,
            goodsClassifierId: selectedGoodClassifier,
            packageClassifierId: selectedPackagingClassifier,
            goodsNetWeight: value.goodsNetWeight,
            packageNetWeight: value.packageNetWeight,
            mciRate: value.mciRate,
            year: value.year,
            quarter: value.quarter,
            isActive: true,
            organizationId: organizationId,
            fileIds: ids
        }
        onCreateRopCalculations(dto);
    }

    const deleteFile = (id: string) => {
        return dispatch(deleteFileAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }
    const handleRemove = (file: UploadFile) => {
        if(file.response) {
            setIds(ids.filter(id => id === file.response.id));
            deleteFile(file.response.id)
        }
    };

    
    return (
        <Modal width="60%" open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
            <Form initialValues={{ year: period.year, quarter: period.quarter, bin: organization?.bin, organizationName: organization?.name }} form={form} className="p-8" layout="vertical" onFinish={onFinish}>
                <Form.Item label={"Номер декларации"} name={"declarationNumber"} key={"declarationNumber"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"БИН/ИИН"} name={"bin"} key={"bin"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Наименование компании"} name={"organizationName"} key={"organizationName"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Код ТН ВЭД ЕАЭС"} name={"codeTNVEDEAEU"} key={"codeTNVEDEAEU"}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Наименование импортированного товара"} name={"goodsClassifierName"} key={"goodsClassifierName"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Select
                                options={ropGoodsClassifiersLabels}
                                showSearch
                                filterOption={(input, option) => {
                                    if (option) {
                                        const str = option.label + '';
                                        return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                    } else {
                                        return true
                                    }
                                }}
                                onSelect={(selectedClassifier) => setGoodClassifier(selectedClassifier)}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Материал упаковки импортированного товара"} name={"packagingClassifierName"} key={"packagingClassifierName"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Select
                                options={ropPackagingClassifiersLabels}
                                showSearch
                                filterOption={(input, option) => {
                                    if (option) {
                                        const str = option.label + '';
                                        return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                    } else {
                                        return true
                                    }
                                }}
                                onSelect={(selectedClassifier) => setPackagingClassifier(selectedClassifier)}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Масса нетто в тоннах импортированного товара"} name={"goodsNetWeight"} key={"goodsNetWeight"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <InputNumber style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Масса нетто в тоннах упаковки импортированного товара"} name={"packageNetWeight"} key={"packageNetWeight"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <InputNumber style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Ставка МРП на отчетный период"} name={"mciRate"} key={"mciRate"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <InputNumber style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Год"} name={"year"} key={"year"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Квартал"} name={"quarter"} key={"quarter"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Upload
                    action={`${BASE_PATH}/files/`}
                    listType="picture"
                    maxCount={3}
                    headers={{
                        Authorization: 'Bearer ' + getFromStorage("token"),
                    }}
                    onChange={(info) => {
                        if (info.file.status === "done") {
                            console.log(info.file.response.id)
                            let temp = [...ids]
                            temp.push(info.file.response?.id);
                            setIds(temp);
                        }
                    }}
                    onRemove={handleRemove}
                >
                    <Button icon={<UploadOutlined />}>Upload (Max: 3)</Button>
                </Upload>
            </Form>
        </Modal>
    );
};
