import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateCarbonContractWorkDto, CarbonContractWork, Organization, UpdateCarbonContractWorkDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { CarbonContractWorkForm } from "./components/CarbonContractWorkForm";
import { CarbonContractWorkColumns } from "./form/columns";
import { createCarbonContractWorkAsync, updateCarbonContractWorkAsync, selectCarbonContractWork, deleteCarbonContractWorkAsync, fetchCarbonContractWorkByOrganizationIdAsync } from "./CarbonContractWorkSlice";
import { BASE_PATH } from "../../../api/base";

interface CarbonContractWorkComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const CarbonContractWorkComponent: React.FC<CarbonContractWorkComponentProps> = ({ organization, year, quarter }) => {
  const dispatch = useAppDispatch();
  const carbonContractWork = useAppSelector(selectCarbonContractWork);
  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year && quarter) {
      dispatch(fetchCarbonContractWorkByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
    }
  }, [organization, year, quarter, dispatch]);

  const onUpdate = async (carbonContractWork: UpdateCarbonContractWorkDto, id: string) => {
    return dispatch(updateCarbonContractWorkAsync({ carbonContractWork, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateCarbonContractWork = (carbonContractWork: CreateCarbonContractWorkDto) => {
    if (!organization || !year || !quarter) return;

    dispatch(createCarbonContractWorkAsync({ ...carbonContractWork, organizationId: organization.id, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteCarbonContractWork = (carbonContractWork: CarbonContractWork) => {
    return dispatch(deleteCarbonContractWorkAsync({ carbonContractWork })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <CarbonContractWorkForm visible={visible} setVisible={setVisible} onCreateCarbonContractWork={onCreateCarbonContractWork} />}
      <EditableTable<CarbonContractWork, UpdateCarbonContractWorkDto>
        onSave={onUpdate}
        onDelete={deleteCarbonContractWork}
        entityColumns={CarbonContractWorkColumns()}
        dataSource={carbonContractWork}
        footer={() => {
          return <>
            <p className="mb-0">Всего:</p>
            <ul>
              <li>объем выполнения: <b>{carbonContractWork.reduce((n, { volume }) => n + volume, 0)}</b></li>
              <li>расход дизельного топлива (т): <b>{carbonContractWork.reduce((n, { consumption }) => n + consumption, 0)}</b></li>
              <li>выброс СО2 (тСО2): <b>{carbonContractWork.reduce((n, { co2 }) => n + co2, 0)}</b></li>
              <li>выброс СН4 (тСН4): <b>{carbonContractWork.reduce((n, { ch4 }) => n + ch4, 0)}</b></li>
              <li>выброс N2O (тN2O): <b>{carbonContractWork.reduce((n, { n2o }) => n + n2o, 0)}</b></li>
              <li>количество выбросов (т СО2е): <b>{carbonContractWork.reduce((n, { co2e }) => n + co2e, 0)}</b></li>
            </ul>
            {addButton}
          </>;
        }}
      />
      <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/carbon-contract-work/report/organization/${organization?.id || ""}/year/${year}/quarter/${quarter}`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
    </Layout>
  );
};
