import { Input } from "antd";
import { DropCode } from "../../../api";
import { EntityColumns } from "../../../app/types";


export const dropCodesColumns: EntityColumns<DropCode>[] =
  [
    {
      title: "Наименование вещества",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];