import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreatePermissionDto, PermissionsApi, UpdatePermissionDto } from "../../api";
import { ApiError } from "../../app/types";

export interface PermissionsState {
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: PermissionsState = {
  status: "idle",
};

export const createPermissionAsync = createAsyncThunk(
  "permissions/create",
  async (permission: CreatePermissionDto, { rejectWithValue }) => {
    try {
      const permissionsApi = new PermissionsApi();
      const response = await permissionsApi.permissionsControllerCreate(permission);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePermissionAsync = createAsyncThunk(
  "permissions/update",
  async ({ permission, id }: { permission: UpdatePermissionDto; id: string }, { rejectWithValue }) => {
    try {
      const permissionsApi = new PermissionsApi();
      const response = await permissionsApi.permissionsControllerUpdate(permission, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
});

export default permissionsSlice.reducer;
