import { Button, Layout } from "antd";
import { useState } from "react";
import { CreatePermissionDto, Permission, UpdatePermissionDto } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { PermissionForm } from "./components/PermissionForm";
import { permissionsColumns } from "./form/columns";
import { createPermissionAsync, updatePermissionAsync } from "./PermissionsSlice";

interface PermissionsComponentProps {
  permissions?: Permission[];
  organizationId?: string;
}

export const PermissionsComponent: React.FC<PermissionsComponentProps> = ({ organizationId, permissions }) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);

  const onUpdate = async (permission: UpdatePermissionDto, id: string) => {
    return dispatch(updatePermissionAsync({ permission, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreatePermission = (permission: CreatePermissionDto) => {
    if (!organizationId) return;

    dispatch(createPermissionAsync({ ...permission, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <PermissionForm visible={visible} setVisible={setVisible} onCreatePermission={onCreatePermission} />}
      <EditableTable<Permission, UpdatePermissionDto>
        onSave={onUpdate}
        entityColumns={permissionsColumns}
        dataSource={permissions}
        footer={() => addButton}
      />
    </Layout>
  );
};
