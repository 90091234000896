import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateSelectionSourceDto, SelectionSource, SelectionSourcesApi, UpdateSelectionSourceDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface SelectionSourceState {
  selectionSources: SelectionSource[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: SelectionSourceState = {
  selectionSources: [],
  status: "idle",
};

export const fetchSelectionSourcesAsync = createAsyncThunk("selectionSources/fetch", async (pollutionSourceId: string, { rejectWithValue }) => {
  try {
    const selectionSourcesApi = new SelectionSourcesApi();
    const response = await selectionSourcesApi.selectionSourcesControllerFindByPollutionSourceId(pollutionSourceId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchSelectionSourcesByOrganizationAsync = createAsyncThunk("selectionSources/fetch", async (organizationId: string, { rejectWithValue }) => {
  try {
    const selectionSourcesApi = new SelectionSourcesApi();
    const response = await selectionSourcesApi.selectionSourcesControllerFindByOrganizationId(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createSelectionSourceAsync = createAsyncThunk(
  "selectionSources/create",
  async (selectionSource: CreateSelectionSourceDto, { rejectWithValue }) => {
    try {
      const selectionSourcesApi = new SelectionSourcesApi();
      const response = await selectionSourcesApi.selectionSourcesControllerCreate(selectionSource);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSelectionSourceAsync = createAsyncThunk(
  "selectionSource/update",
  async ({ selectionSource, id }: { selectionSource: UpdateSelectionSourceDto; id: string }, { rejectWithValue }) => {
    try {
      const selectionSourcesApi = new SelectionSourcesApi();
      const response = await selectionSourcesApi.selectionSourcesControllerUpdate(selectionSource, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeSelectionSourceAsync = createAsyncThunk("selectionSource/remove", async (id: string, { rejectWithValue }) => {
  try {
    const selectionSourcesApi = new SelectionSourcesApi();
    const response = await selectionSourcesApi.selectionSourcesControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchSelectionSource = () => {
    const { pending, fulfilled, rejected } = fetchSelectionSourcesAsync;
    return {
      [`${pending}`]: (state: SelectionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SelectionSourceState, action: PayloadAction<SelectionSource[]>) => {
        state.selectionSources = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: SelectionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchSelectionSourcesByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchSelectionSourcesByOrganizationAsync;
    return {
      [`${pending}`]: (state: SelectionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SelectionSourceState, action: PayloadAction<SelectionSource[]>) => {
        state.selectionSources = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: SelectionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createSelectionSource = () => {
    const { pending, fulfilled, rejected } = createSelectionSourceAsync;
    return {
      [`${pending}`]: (state: SelectionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SelectionSourceState, action: PayloadAction<SelectionSource>) => {
        state.selectionSources.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SelectionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateSelectionSource = () => {
    const { pending, fulfilled, rejected } = updateSelectionSourceAsync;

    return {
      [`${pending}`]: (state: SelectionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SelectionSourceState, action: PayloadAction<SelectionSource>) => {
        state.selectionSources = state.selectionSources.map((selectionSource) =>
          selectionSource.id === action.payload.id ? action.payload : selectionSource
        );
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SelectionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeSelectionSource = () => {
    const { pending, fulfilled, rejected } = removeSelectionSourceAsync;

    return {
      [`${pending}`]: (state: SelectionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SelectionSourceState, action: PayloadAction<SelectionSource>) => {
        state.selectionSources = state.selectionSources.filter((selectionSource) => selectionSource.id !== action.payload.id);
        message.success("Успешно удалено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SelectionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchSelectionSource(), ...fetchSelectionSourcesByOrganization(), ...createSelectionSource(), ...updateSelectionSource(), ...removeSelectionSource() };
};

export const selectionSourcesSlice = createSlice({
  name: "selectionSources",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectSelectionSources = (state: RootState) => state.selectionSources.selectionSources;
export const selectSelectionSourcesLength = (state: RootState) => state.selectionSources.selectionSources.length;

export default selectionSourcesSlice.reducer;
