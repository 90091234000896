import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DefaultOptionType } from "antd/es/select";
import Text from "antd/es/typography/Text";
import { UserRolesEnum, WasteApplication7Record, WasteRegister } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { getPermittedRolesToCreateUser, UserRoles, UserRole, getRoleLabel } from "../../../../app/role";
import { EntityColumns } from "../../../../app/types";
import { selectAuthUser } from "../../../auth/authSlice";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";
import { selectWasteRegisters } from "../../../wasteRegister/WasteRegisterSlice";

export const WasteApplication7RecordsColumns = (): EntityColumns<WasteApplication7Record>[] => {
  const wasteRegisters = useAppSelector(selectWasteRegisters);
  const period = useAppSelector(selectOrganizationModulePeriod);
  const authUser = useAppSelector(selectAuthUser);

  let superRoles = [
    UserRolesEnum.Admin,
    UserRolesEnum.Head,
    UserRolesEnum.Ecolog,
    UserRolesEnum.Chief,
  ];

  let simpleRoles = [UserRolesEnum.Surveyor, UserRolesEnum.Engineer, UserRolesEnum.Tailings, UserRolesEnum.Career, UserRolesEnum.Mechanical, UserRolesEnum.Atc, UserRolesEnum.Depot, UserRolesEnum.Rsg, UserRolesEnum.Oks, UserRolesEnum.Ahs, UserRolesEnum.Cl, UserRolesEnum.Icu, UserRolesEnum.Of, UserRolesEnum.Concentrator,];

  let roles: DefaultOptionType[] = authUser ? simpleRoles.map(sr => getRoleLabel(sr)) : [];

  function getWasteRegisterLabel(r: WasteRegister): string {
    return `${r.name}-${r.code}`
  }

  const hideOnForm = (superRoles.find((sr) => authUser?.roles.includes(sr))) ? false : true;

  return [
    {
      title: "Отход",
      dataIndex: "wasteRegister",
      key: "wasteRegisterId",
      inputNode: <Select options={wasteRegisters.map(
        (wasteRegister) => {
          return {
            value: wasteRegister.id,
            label: getWasteRegisterLabel(wasteRegister)
          }
        }
      )} />,
      render: (wasteRegister) => {
        const wrOpt = wasteRegisters.find((wr) => wr.id === wasteRegister)
        const label = (wrOpt) ? getWasteRegisterLabel(wrOpt) : ""
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>;
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Образовано в месяце",
      dataIndex: "educatedVolumeAtMonth",
      key: "educatedVolumeAtMonth",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Наличие на начало года",
      dataIndex: "remainedVolumeOnStartYear",
      key: "remainedVolumeOnStartYear",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Образовано за год",
      dataIndex: "educatedVolumeAtYear",
      key: "educatedVolumeAtYear",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Размещено для временного хранения за год",
      dataIndex: "placedVolumeForTemporaryStorage",
      key: "placedVolumeForTemporaryStorage",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Размещено для длительного (более 6 месяцев), хранения/захоронения за год",
      dataIndex: "placedVolumeForLongtermStorage",
      key: "placedVolumeForLongtermStorage",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Использовано на предприятии за год",
      dataIndex: "usedVolumeInEnterpriseAtYear",
      key: "usedVolumeInEnterpriseAtYear",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Передано подрядным организациям на переработку за год",
      dataIndex: "transferredVolumeToContractorsForProcessingAtYear",
      key: "transferredVolumeToContractorsForProcessingAtYear",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Наличие на конец отчетного периода",
      dataIndex: "availabilityAtTheEndOfReportingPeriod",
      key: "availabilityAtTheEndOfReportingPeriod",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Отдел",
      dataIndex: "role",
      key: "role",
      inputNode: <Select placeholder="Выберите отдел" options={roles} />,
      render: (role) => (
        <Text className="w-64" ellipsis={{ tooltip: UserRoles[role as UserRole] }}>
          {UserRoles[role as UserRole]}
        </Text>
      ),
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: hideOnForm,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    // {
    //   title: "Квартал",
    //   dataIndex: "month",
    //   key: "month",
    //   inputNode: <Input defaultValue={month} style={{ width: '100%' }} disabled={true} />,
    //   rules: [{ required: false, message: "Заполните поле" }],
    //   editable: false,
    //   copyable: true,
    // },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
