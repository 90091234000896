import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAirEmissionsPointPollutantDto, AirEmissionsPointPollutantsApi, UpdateAirEmissionsPointPollutantDto, AirEmissionsPointPollutant } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface AirEmissionsPointPollutantsState {
  airEmissionsPointPollutants: AirEmissionsPointPollutant[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AirEmissionsPointPollutantsState = {
  airEmissionsPointPollutants: [],
  status: "idle",
};

export const createAirEmissionsPointPollutantAsync = createAsyncThunk(
  "airEmissionsPointPollutants/create",
  async (airEmissionsPointPollutant: CreateAirEmissionsPointPollutantDto, { rejectWithValue }) => {
    try {
      const airEmissionsPointPollutantsApi = new AirEmissionsPointPollutantsApi();
      const response = await airEmissionsPointPollutantsApi.airEmissionsPointPollutantsControllerCreate(airEmissionsPointPollutant);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAirEmissionsPointPollutantAsync = createAsyncThunk(
  "airEmissionsPointPollutants/update",
  async ({ airEmissionsPointPollutant, id }: { airEmissionsPointPollutant: UpdateAirEmissionsPointPollutantDto; id: string }, { rejectWithValue }) => {
    try {
      const airEmissionsPointPollutantsApi = new AirEmissionsPointPollutantsApi();
      const response = await airEmissionsPointPollutantsApi.airEmissionsPointPollutantsControllerUpdate(airEmissionsPointPollutant, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAirEmissionsPointPollutantAsync = createAsyncThunk(
  "airEmissionsPointPollutants/remove",
  async ({ airEmissionsPointPollutant }: { airEmissionsPointPollutant: AirEmissionsPointPollutant }, { rejectWithValue }) => {
    try {
      const airEmissionsPointPollutantsApi = new AirEmissionsPointPollutantsApi();
      const response = await airEmissionsPointPollutantsApi.airEmissionsPointPollutantsControllerRemove(airEmissionsPointPollutant.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAirEmissionsPointPollutantsByPointAsync = createAsyncThunk("airEmissionsPointPollutants/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const airEmissionsPointPollutantsApi = new AirEmissionsPointPollutantsApi();
    const response = await airEmissionsPointPollutantsApi.airEmissionsPointPollutantsControllerFindByPointId(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAirEmissionsPointPollutantsByPoint = () => {
    const { pending, fulfilled, rejected } = fetchAirEmissionsPointPollutantsByPointAsync;
    return {
      [`${pending}`]: (state: AirEmissionsPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AirEmissionsPointPollutant[]>) => {
        state.airEmissionsPointPollutants = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAirEmissionsPointPollutant = () => {
    const { pending, fulfilled, rejected } = createAirEmissionsPointPollutantAsync;
    return {
      [`${pending}`]: (state: AirEmissionsPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AirEmissionsPointPollutant>) => {
        state.airEmissionsPointPollutants.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAirEmissionsPointPollutant = () => {
    const { pending, fulfilled, rejected } = updateAirEmissionsPointPollutantAsync;

    return {
      [`${pending}`]: (state: AirEmissionsPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AirEmissionsPointPollutant>) => {
        state.airEmissionsPointPollutants = state.airEmissionsPointPollutants.map((airEmissionsPointPollutant) => (airEmissionsPointPollutant.id === action.payload.id ? action.payload : airEmissionsPointPollutant));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAirEmissionsPointPollutant = () => {
    const { pending, fulfilled, rejected } = deleteAirEmissionsPointPollutantAsync;
    return {
      [`${pending}`]: (state: AirEmissionsPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AirEmissionsPointPollutant>) => {
        state.airEmissionsPointPollutants.splice(state.airEmissionsPointPollutants.indexOf(
          state.airEmissionsPointPollutants.find((ws: AirEmissionsPointPollutant) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAirEmissionsPointPollutantsByPoint(), ...createAirEmissionsPointPollutant(), ...updateAirEmissionsPointPollutant(), ...removeAirEmissionsPointPollutant() };
};

export const airEmissionsPointPollutantsSlice = createSlice({
  name: "airEmissionsPointPollutants",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAirEmissionsPointPollutants = (state: RootState) => state.airEmissionsPointPollutants.airEmissionsPointPollutants;

export default airEmissionsPointPollutantsSlice.reducer;
