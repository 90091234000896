import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import usersReducer from "../features/users/usersSlice";
import organizationsReducer from "../features/organizations/organizationsSlice";
import organizationProfileReducer from "../features/organizationProfile/organizationProfileSlice";
import industrialSitesReducer from "../features/industrialSites/industrialSitesSlice";
import wasteAccumulationSitesReducer from "../features/wasteAccumulationSites/WasteAccumulationSitesSlice";
import wasteBurialSitesReducer from "../features/wasteBurialSites/WasteBurialSitesSlice";
import wasteEquipmentRecyclesReducer from "../features/wasteEquipmentRecycles/WasteEquipmentRecyclesSlice";
import wasteEquipmentSortsReducer from "../features/wasteEquipmentSorts/WasteEquipmentSortsSlice";
import wasteRegistersReducer from "../features/wasteRegister/WasteRegisterSlice";
import wastePassportsReducer from "../features/wastePassports/WastePassportsSlice";
import wasteAccumulationLimitsReducer from "../features/wasteAccumulationLimits/WasteAccumulationLimitsSlice";
import wasteBurialLimitsReducer from "../features/wasteBurialLimits/WasteBurialLimitsSlice";
import pekProgramsReducer from "../features/pekPrograms/pekProgramsSlice";
import workshopsReducer from "../features/workshops/workshopsSlice";
import pollutionSourcesReducer from "../features/pollutionSources/pollutionSourcesSlice";
import selectionSourcesReducer from "../features/selectionSources/selectionSourcesSlice";
import standardsReducer from "../features//standards/standardsSlice";
import pollutionSubstancesReducer from "../features/pollutionSubstances/pollutionSubstancesSlice";
import cleaningEquipmentsReducer from "../features/cleaningEquipments/cleaningEquipmentsSlice";
import contragentsReducer from "../features/laboratories/ContragentsSlice";
import soilPointsReducer from "../features/soilPoints/SoilPointsSlice";
import airSzzPointsReducer from "../features/airSzzPoints/AirSzzPointsSlice";
import airEmissionsPointsReducer from "../features/airEmissionsPoints/AirEmissionsPointsSlice";
import waterPointsReducer from "../features/waterPoints/WaterPointsSlice";
import soilJournalsReducer from "../features/soilJournals/SoilJournalsSlice";
import airSzzJournalsReducer from "../features/airSzzJournals/AirSzzJournalsSlice";
import airEmissionsJournalsReducer from "../features/airEmissionsJournals/AirEmissionsJournalsSlice";
import waterJournalsReducer from "../features/waterJournals/WaterJournalsSlice";
import waterAdditionalJournalsReducer from "../features/waterAdditionalJournals/WaterAdditionalJournalsSlice";
import radiologyPointsReducer from "../features/radiologyPoints/RadiologyPointsSlice";
import radiologyPointPollutantsReducer from "../features/radiologyPointPollutants/RadiologyPointPollutantsSlice";
import radiologyJournalsReducer from "../features/radiologyJournals/RadiologyJournalsSlice";
import airEmissionsPointPollutantsReducer from "../features/airEmissionsPointPollutants/AirEmissionsPointPollutantsSlice";
import airSzzPointPollutantsReducer from "../features/airSzzPointPollutants/AirSzzPointPollutantsSlice";
import soilPointPollutantsReducer from "../features/soilPointPollutants/SoilPointPollutantsSlice";
import waterPointPollutantsReducer from "../features/waterPointPollutants/WaterPointPollutantsSlice";
import methodicsReducer from "../features/methodics/methodicsSlice";
import pollutantCodesReducer from "../features/pollutantCodes/PollutantCodesSlice";
import organizationModuleReducer from "../features/organizationModule/OrganizationModuleSlice";
import wasteJournalsReducer from "../features/wasteJournals/WasteJournalsSlice";
import reportPeksReducer from "../features/reportPeks/ReportPeksSlice";
import reportPaymentsReducer from "../features/reportPayments/ReportPaymentsSlice";
import calculationsReducer from "../features/calculations/calculationsSlice";
import wasteContragentsReducer from "../features/wasteContragents/WasteContragentsSlice";
import emissionReducer from "../features/emissions/EmissionsSlice";
import influenceObjectReducer from "../features/influenceObjects/InfluenceObjectsSlice";
import dropReducer from "../features/drops/DropsSlice";
import dropPeriodReducer from "../features/dropPeriods/DropPeriodsSlice";
import dropCodesReducer from "../features/dropCodes/DropCodesSlice";
import dropResultReducer from "../features/dropResults/DropResultsSlice";
import waterConsumptionIntakeReducer from "../features/waterUse/waterConsumptionIntakes/WaterConsumptionIntakesSlice";
import taxEmissionsReducer from "../features/taxEmissions/TaxEmissionsSlice";
import taxDropsReducer from "../features/taxDrops/TaxDropsSlice";
import totalEmissionsReducer from "../features/totalEmissions/TotalEmissionsSlice";
import materialsReducer from "../features/materials/MaterialsSlice";
import ropGoodsClassifierReducer from "../features/rop/RopGoodClassifierSlice";
import ropPackagingClassifierReducer from "../features/rop/RopPackagingClassifierSlice";
import ropCalculationsReducer from "../features/rop/RopCalculationsSlice";
import filesReducer from "../features/files/FilesEntitySlice";
import ropCalculationsCableReducer from "../features/ropCable/RopCalculationsCableSlice";
import ropTransportClassifiersReducer from "../features/ropTransport/RopTransportClassifiersSlice";
import ropCalculationsTransportReducer from "../features/ropTransport/RopCalculationsTransportSlice";
import ropDocumentsReducer from "../features/ropDocuments/RopDocumentsSlice";
import waterConsumptionObjectsReducer from "../features/waterConsumptionObject/WaterConsumptionObjectsSlice";
import waterConsumptionHouseholdReducer from "../features/waterUse/waterConsumptionHouseholds/WaterConsumptionHouseholdsSlice";
import waterConsumptionProductionReducer from "../features/waterUse/waterConsumptionProductions/WaterConsumptionProductionsSlice";
import waterDrainageObjectsReducer from "../features/waterDrainageObjects/WaterDrainageObjectsSlice";
import waterDrainageHouseholdReducer from "../features/waterUse/waterDrainageHouseholds/WaterDrainageHouseholdsSlice";
import waterDrainageProductionReducer from "../features/waterUse/waterDrainageProductions/WaterDrainageProductionsSlice";
import carbonStationarySourcesReducer from "../features/carbon/carbonStationarySource/CarbonStationarySourceSlice";
import carbonMobileSourceReducer from "../features/carbon/carbonMobileSource/CarbonMobileSourceSlice";
import carbonContractWorkReducer from "../features/carbon/carbonContractWork/CarbonContractWorkSlice";
import carbonWasteRecycleReducer from "../features/carbon/carbonWasteRecycle/CarbonWasteRecycleSlice";
import carbonIndirectEmissionReducer from "../features/carbon/carbonIndirectEmission/CarbonIndirectEmissionSlice";
import analyticsProductionForKpisReducer from "../features/analytics/analyticsProductionForKpis/AnalyticsProductionForKpisSlice";
import analyticsProductionIndicatorsReducer from "../features/analytics/analyticsProductionIndicator/AnalyticsProductionIndicatorSlice";
import analyticsKpisReducer from "../features/analytics/analyticsKpi/AnalyticsKpiSlice";
import categoriesReducer from "../features/categories/categoriesSlice";
import categoryVariablesReducer from "../features/categoryVariables/categoryVariablesSlice";
import wasteApplication7RecordsReducer from "../features/wasteApplications/wasteApplication7Records/WasteApplication7RecordsSlice";
import wasteApplication16RecordsReducer from "../features/wasteApplications/wasteApplication16Records/WasteApplication16RecordsSlice";
import wasteManagementDocumentsReducer from "../features/wasteManagementMethodicalDocuments/WasteManagementDocumentSlice";
import taxWasteReducer from "../features/taxWaste/TaxWastesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    organizations: organizationsReducer,
    organizationsProfile: organizationProfileReducer,
    industrialSites: industrialSitesReducer,
    wasteAccumulationSites: wasteAccumulationSitesReducer,
    wasteBurialSites: wasteBurialSitesReducer,
    wasteEquipmentRecycles: wasteEquipmentRecyclesReducer,
    wasteEquipmentSorts: wasteEquipmentSortsReducer,
    wasteRegisters: wasteRegistersReducer,
    wastePassports: wastePassportsReducer,
    wasteAccumulationLimits: wasteAccumulationLimitsReducer,
    wasteBurialLimits: wasteBurialLimitsReducer,
    pekPrograms: pekProgramsReducer,
    workshops: workshopsReducer,
    pollutionSources: pollutionSourcesReducer,
    selectionSources: selectionSourcesReducer,
    standards: standardsReducer,
    pollutionSubstances: pollutionSubstancesReducer,
    cleaningEquipments: cleaningEquipmentsReducer,
    contragents: contragentsReducer,
    soilPoints: soilPointsReducer,
    airSzzPoints: airSzzPointsReducer,
    airEmissionsPoints: airEmissionsPointsReducer,
    waterPoints: waterPointsReducer,
    soilJournals: soilJournalsReducer,
    airSzzJournals: airSzzJournalsReducer,
    airEmissionsJournals: airEmissionsJournalsReducer,
    waterJournals: waterJournalsReducer,
    waterAdditionalJournals: waterAdditionalJournalsReducer,
    radiologyPoints: radiologyPointsReducer,
    radiologyPointPollutants: radiologyPointPollutantsReducer,
    radiologyJournals: radiologyJournalsReducer,
    airEmissionsPointPollutants: airEmissionsPointPollutantsReducer,
    airSzzPointPollutants: airSzzPointPollutantsReducer,
    soilPointPollutants: soilPointPollutantsReducer,
    waterPointPollutants: waterPointPollutantsReducer,
    methodic: methodicsReducer,
    pollutantCodes: pollutantCodesReducer,
    organizationModule: organizationModuleReducer,
    wasteJournals: wasteJournalsReducer,
    reportPeks: reportPeksReducer,
    reportPayments: reportPaymentsReducer,
    calculations: calculationsReducer,
    wasteContragents: wasteContragentsReducer,
    emission: emissionReducer,
    influenceObject: influenceObjectReducer,
    dropCodes: dropCodesReducer,
    drop: dropReducer,
    dropPeriod: dropPeriodReducer,
    dropResult: dropResultReducer,
    waterConsumptionIntake: waterConsumptionIntakeReducer,
    taxEmissions: taxEmissionsReducer,
    taxDrops: taxDropsReducer,
    totalEmissions: totalEmissionsReducer,
    materials: materialsReducer,
    ropGoodsClassifiers: ropGoodsClassifierReducer,
    ropPackagingClassifiers: ropPackagingClassifierReducer,
    ropCalculations: ropCalculationsReducer,
    files: filesReducer,
    ropCalculationsCable: ropCalculationsCableReducer,
    ropTransportClassifers: ropTransportClassifiersReducer,
    ropCalculationsTransport: ropCalculationsTransportReducer,
    ropDocuments: ropDocumentsReducer,
    waterConsumptionObjects: waterConsumptionObjectsReducer,
    waterConsumptionHousehold: waterConsumptionHouseholdReducer,
    waterConsumptionProduction: waterConsumptionProductionReducer,
    waterDrainageObjects: waterDrainageObjectsReducer,
    waterDrainageHousehold: waterDrainageHouseholdReducer,
    waterDrainageProduction: waterDrainageProductionReducer,
    carbonStationarySources: carbonStationarySourcesReducer,
    carbonMobileSource: carbonMobileSourceReducer,
    carbonContractWork: carbonContractWorkReducer,
    carbonWasteRecycle: carbonWasteRecycleReducer,
    carbonIndirectEmission: carbonIndirectEmissionReducer,
    analyticsProductionForKpis: analyticsProductionForKpisReducer,
    analyticsProductionIndicators: analyticsProductionIndicatorsReducer,
    analyticsKpis: analyticsKpisReducer,
    categories: categoriesReducer,
    categoryVariables: categoryVariablesReducer,
    wasteApplication7Records: wasteApplication7RecordsReducer,
    wasteApplication16Records: wasteApplication16RecordsReducer,
    wasteManagementDocuments: wasteManagementDocumentsReducer,
    taxWastes: taxWasteReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
