import { Navigate } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { selectIsSignedIn } from "../../features/auth/authSlice";

export const ProtectedRoute = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  if (!isSignedIn) {
    return <Navigate to="/sign-in" />;
  }
  return <>{children}</>;
};
