import { Button, Layout, Modal, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateRadiologyPointDto, RadiologyPoint, UpdateRadiologyPointDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { RadiologyPointPollutantsComponent } from "../radiologyPointPollutants/RadiologyPointPollutantsComponent";
import { RadiologyPointForm } from "./components/RadiologyPointForm";
import { radiologyPointsColumns } from "./form/columns";
import { selectRadiologyPoints, updateRadiologyPointAsync, createRadiologyPointAsync, deleteRadiologyPointAsync, fetchRadiologyPointsByOrganizationidAsync, copyRadiologyPointAsync } from "./RadiologyPointsSlice";
import { CopyOutlined } from '@ant-design/icons';

interface RadiologyPointsComponentProps {
  organizationId?: string;
}

export const RadiologyPointsComponent: React.FC<RadiologyPointsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const radiologyPoints = useAppSelector(selectRadiologyPoints);

  const [visible, setVisible] = useState(false);
  const [point, setPoint] = useState<RadiologyPoint | undefined>(undefined);


  useEffect(() => {
    if (organizationId) {
      dispatch(fetchRadiologyPointsByOrganizationidAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateRadiologyPoint = async (radiologyPoint: UpdateRadiologyPointDto, id: string) => {
    return dispatch(updateRadiologyPointAsync({ radiologyPoint, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateRadiologyPoint = (radiologyPoint: CreateRadiologyPointDto) => {
    if (!organizationId) return;

    dispatch(createRadiologyPointAsync({ ...radiologyPoint, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteRadiologyPoint = (radiologyPoint: RadiologyPoint) => {
    return dispatch(deleteRadiologyPointAsync({ radiologyPoint })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const copy = async (id: string) => {
    return dispatch(copyRadiologyPointAsync({ id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить точку
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <RadiologyPointForm visible={visible} setVisible={setVisible} onCreateRadiologyPoint={onCreateRadiologyPoint} />}
      <EditableTable<RadiologyPoint, UpdateRadiologyPointDto>
        onSave={onUpdateRadiologyPoint}
        onDelete={deleteRadiologyPoint}
        entityColumns={
          [ {
              title: "",
              dataIndex: "btnc" as keyof RadiologyPoint,
              key: "btnc",
              inputNode: <></>,
              render: (value: any, record: RadiologyPoint, index: any) =>
                <Button.Group>
                  <Button icon={<CopyOutlined />} onClick={() => { copy(record.id) }}></Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },
            ...radiologyPointsColumns, 
            {
            title: "Данные",
            dataIndex: "data" as keyof RadiologyPoint,
            key: "data",
            inputNode: <></>,
            render: (value: any, record: RadiologyPoint, index: any) =>
              <Button.Group>
                <Button onClick={() => { setPoint(record) }}>Загрязнения</Button>
              </Button.Group>
            ,
            editable: false,
            copyable: false,
            },]
        }
        dataSource={radiologyPoints}
        footer={() => addButton}
      />
      {point && (
        <Modal
          open={true}
          title={`Загрязнения - ${point?.name}`}
          onCancel={() => setPoint(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"85%"}
        >
          <RadiologyPointPollutantsComponent pointId={point.id} />
        </Modal>
      )}
    </Layout>
  );
};
