/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateWasteBurialLimitDto } from '../models';
import { UpdateWasteBurialLimitDto } from '../models';
import { WasteBurialLimit } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * WasteBurialLimitsApi - axios parameter creator
 * @export
 */
export const WasteBurialLimitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteBurialLimitDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerCreate: async (body: CreateWasteBurialLimitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteBurialLimitsControllerCreate.');
            }
            const localVarPath = `/waste-burial-limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/waste-burial-limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} industrialSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerFindByIndustrialSiteId: async (industrialSiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'industrialSiteId' is not null or undefined
            if (industrialSiteId === null || industrialSiteId === undefined) {
                throw new RequiredError('industrialSiteId','Required parameter industrialSiteId was null or undefined when calling wasteBurialLimitsControllerFindByIndustrialSiteId.');
            }
            const localVarPath = `/waste-burial-limits/industrialSite/{industrialSiteId}`
                .replace(`{${"industrialSiteId"}}`, encodeURIComponent(String(industrialSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerFindByPermissionId: async (permissionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionId' is not null or undefined
            if (permissionId === null || permissionId === undefined) {
                throw new RequiredError('permissionId','Required parameter permissionId was null or undefined when calling wasteBurialLimitsControllerFindByPermissionId.');
            }
            const localVarPath = `/waste-burial-limits/permission/{permissionId}`
                .replace(`{${"permissionId"}}`, encodeURIComponent(String(permissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerFindByYear: async (organizationId: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling wasteBurialLimitsControllerFindByYear.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling wasteBurialLimitsControllerFindByYear.');
            }
            const localVarPath = `/waste-burial-limits/organization/{organizationId}/year/{year}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteBurialLimitsControllerFindOne.');
            }
            const localVarPath = `/waste-burial-limits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteBurialLimitsControllerRemove.');
            }
            const localVarPath = `/waste-burial-limits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWasteBurialLimitDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteBurialLimitsControllerUpdate: async (body: UpdateWasteBurialLimitDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteBurialLimitsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteBurialLimitsControllerUpdate.');
            }
            const localVarPath = `/waste-burial-limits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WasteBurialLimitsApi - functional programming interface
 * @export
 */
export const WasteBurialLimitsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteBurialLimitDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerCreate(body: CreateWasteBurialLimitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteBurialLimit>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteBurialLimit>>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} industrialSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindByIndustrialSiteId(industrialSiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteBurialLimit>>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerFindByIndustrialSiteId(industrialSiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindByPermissionId(permissionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteBurialLimit>>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerFindByPermissionId(permissionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindByYear(organizationId: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteBurialLimit>>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerFindByYear(organizationId, year, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteBurialLimit>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteBurialLimit>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateWasteBurialLimitDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerUpdate(body: UpdateWasteBurialLimitDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteBurialLimit>>> {
            const localVarAxiosArgs = await WasteBurialLimitsApiAxiosParamCreator(configuration).wasteBurialLimitsControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WasteBurialLimitsApi - factory interface
 * @export
 */
export const WasteBurialLimitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateWasteBurialLimitDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerCreate(body: CreateWasteBurialLimitDto, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteBurialLimit>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteBurialLimit>>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} industrialSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindByIndustrialSiteId(industrialSiteId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteBurialLimit>>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerFindByIndustrialSiteId(industrialSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindByPermissionId(permissionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteBurialLimit>>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerFindByPermissionId(permissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindByYear(organizationId: string, year: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteBurialLimit>>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerFindByYear(organizationId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteBurialLimit>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteBurialLimit>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWasteBurialLimitDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteBurialLimitsControllerUpdate(body: UpdateWasteBurialLimitDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteBurialLimit>> {
            return WasteBurialLimitsApiFp(configuration).wasteBurialLimitsControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WasteBurialLimitsApi - object-oriented interface
 * @export
 * @class WasteBurialLimitsApi
 * @extends {BaseAPI}
 */
export class WasteBurialLimitsApi extends BaseAPI {
    /**
     * 
     * @param {CreateWasteBurialLimitDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerCreate(body: CreateWasteBurialLimitDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteBurialLimit>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteBurialLimit>>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} industrialSiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerFindByIndustrialSiteId(industrialSiteId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteBurialLimit>>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerFindByIndustrialSiteId(industrialSiteId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} permissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerFindByPermissionId(permissionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteBurialLimit>>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerFindByPermissionId(permissionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerFindByYear(organizationId: string, year: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteBurialLimit>>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerFindByYear(organizationId, year, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteBurialLimit>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteBurialLimit>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateWasteBurialLimitDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteBurialLimitsApi
     */
    public async wasteBurialLimitsControllerUpdate(body: UpdateWasteBurialLimitDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteBurialLimit>> {
        return WasteBurialLimitsApiFp(this.configuration).wasteBurialLimitsControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
