import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterPointDto, WaterPointsApi, UpdateWaterPointDto, WaterPoint } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WaterPointsState {
  waterPoints: WaterPoint[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterPointsState = {
  waterPoints: [],
  status: "idle",
};

export const createWaterPointAsync = createAsyncThunk(
  "waterPoints/create",
  async (waterPoint: CreateWaterPointDto, { rejectWithValue }) => {
    try {
      const waterPointsApi = new WaterPointsApi();
      const response = await waterPointsApi.waterPointsControllerCreate(waterPoint);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterPointAsync = createAsyncThunk(
  "waterPoints/update",
  async ({ waterPoint, id }: { waterPoint: UpdateWaterPointDto; id: string }, { rejectWithValue }) => {
    try {
      const waterPointsApi = new WaterPointsApi();
      const response = await waterPointsApi.waterPointsControllerUpdate(waterPoint, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterPointAsync = createAsyncThunk(
  "waterPoints/remove",
  async ({ waterPoint }: { waterPoint: WaterPoint }, { rejectWithValue }) => {
    try {
      const waterPointsApi = new WaterPointsApi();
      const response = await waterPointsApi.waterPointsControllerRemove(waterPoint.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterPointsByOrganizationidAsync = createAsyncThunk("waterPoints/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const waterPointsApi = new WaterPointsApi();
    const response = await waterPointsApi.waterPointsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const copyWaterPointAsync = createAsyncThunk(
  "waterPoints/copy",
  async ({id} : { id: string }, { rejectWithValue }) => {
    try {
      const waterPointsApi = new WaterPointsApi();
      const response = await waterPointsApi.waterPointsControllerCopy(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchWaterPointsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWaterPointsByOrganizationidAsync;
    return {
      [`${pending}`]: (state: WaterPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointsState, action: PayloadAction<WaterPoint[]>) => {
        state.waterPoints = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterPoint = () => {
    const { pending, fulfilled, rejected } = createWaterPointAsync;
    return {
      [`${pending}`]: (state: WaterPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointsState, action: PayloadAction<WaterPoint>) => {
        state.waterPoints.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterPoint = () => {
    const { pending, fulfilled, rejected } = updateWaterPointAsync;

    return {
      [`${pending}`]: (state: WaterPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointsState, action: PayloadAction<WaterPoint>) => {
        state.waterPoints = state.waterPoints.map((WaterPoint) => (WaterPoint.id === action.payload.id ? action.payload : WaterPoint));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterPoint = () => {
    const { pending, fulfilled, rejected } = deleteWaterPointAsync;
    return {
      [`${pending}`]: (state: WaterPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointsState, action: PayloadAction<WaterPoint>) => {
        state.waterPoints.splice(state.waterPoints.indexOf(
          state.waterPoints.find((ws: WaterPoint) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const copyWaterPoint = () => {
    const { pending, fulfilled, rejected } = copyWaterPointAsync;
    return {
      [`${pending}`]: (state: WaterPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointsState, action: PayloadAction<WaterPoint>) => {
        state.waterPoints.push(action.payload);
        message.success("Успешно скопирован");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterPointsByOrganization(), ...createWaterPoint(), ...updateWaterPoint(), ...removeWaterPoint(), ...copyWaterPoint() };
};

export const waterPointsSlice = createSlice({
  name: "waterPoints",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterPoints = (state: RootState) => state.waterPoints.waterPoints;

export default waterPointsSlice.reducer;
