import { Input, InputNumber, Select } from "antd";
import { SoilJournal } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectSoilPoints } from "../../soilPoints/SoilPointsSlice";
import Text from "antd/es/typography/Text";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { useState } from "react";

export const SoilJournalsColumns = (): EntityColumns<SoilJournal>[] => {
  let [selectedPoint, setPoint] = useState()

  const soilPoints = useAppSelector(selectSoilPoints);
  let list: any = [];
  for (const point of soilPoints) {
    if(point.id === selectedPoint) {
      for (const pollutant of (point.pollutants || [])) {
        list = [...list, {
          value: pollutant.id,
          label: `${pollutant.name}`
        }];
      }
    }
  }
  let listOfPoints: any  = [];
  for (const point of soilPoints) {
    listOfPoints = [...listOfPoints, {
      value: point.id,
      label: `№${point.no}, ${point.name}, (${point?.latitude}° ш. ${point?.longitude}° д.)`
    }];
  }

  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Точка",
      dataIndex: "point",
      key: "point",
      inputNode: 
        <Select 
          options={listOfPoints} 
          showSearch
          filterOption={(input, option) => {
            if (option){
              const str = option.label + '';
              return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }            
          }}
          onSelect={(selectedPoint) => setPoint(selectedPoint)}
        />,
      render: (point) => point,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Загрязнение",
      dataIndex: "pollutant",
      key: "pollutant",
      inputNode: 
        <Select 
          options={list} 
          showSearch
          filterOption={(input, option) => {
            if (option){
              const str = option.label + '';
              return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }            
          }}
        />,
      render: (pollutant) => <Text className="w-128" ellipsis={{ tooltip: `${pollutant?.name}` }}>{pollutant?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: `Фактическая концентрация (мг/кг)`,
      dataIndex: "actualConcentration",
      key: "actualConcentration",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Методика проведения измерений",
      dataIndex: "method",
      key: "method",
      inputNode: <Input />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
  ];
}
