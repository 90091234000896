import { Button, Layout, Modal, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateAirSzzPointDto, AirSzzPoint, UpdateAirSzzPointDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { AirSzzPointForm } from "./components/AirSzzPointForm";
import { airSzzPointsColumns } from "./form/columns";
import { selectAirSzzPoints, updateAirSzzPointAsync, createAirSzzPointAsync, deleteAirSzzPointAsync, fetchAirSzzPointsByOrganizationidAsync, copyAirSzzPointAsync } from "./AirSzzPointsSlice";
import { AirSzzPointPollutantsComponent } from "../airSzzPointPollutants/AirSzzPointPollutantsComponent";
import { CopyOutlined } from '@ant-design/icons';


interface AirSzzPointsComponentProps {
  organizationId?: string;
}

export const AirSzzPointsComponent: React.FC<AirSzzPointsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const airSzzPoints = useAppSelector(selectAirSzzPoints);

  const [visible, setVisible] = useState(false);
  const [point, setPoint] = useState<AirSzzPoint | undefined>(undefined);


  useEffect(() => {
    if (organizationId) {
      dispatch(fetchAirSzzPointsByOrganizationidAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateAirSzzPoint = async (airSzzPoint: UpdateAirSzzPointDto, id: string) => {
    return dispatch(updateAirSzzPointAsync({ airSzzPoint, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateAirSzzPoint = (airSzzPoint: CreateAirSzzPointDto) => {
    if (!organizationId) return;

    dispatch(createAirSzzPointAsync({ ...airSzzPoint, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteAirSzzPoint = (airSzzPoint: AirSzzPoint) => {
    return dispatch(deleteAirSzzPointAsync({ airSzzPoint })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const copy = async (id: string) => {
    return dispatch(copyAirSzzPointAsync({ id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить точку
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <AirSzzPointForm visible={visible} setVisible={setVisible} onCreateAirSzzPoint={onCreateAirSzzPoint} />}
      <EditableTable<AirSzzPoint, UpdateAirSzzPointDto>
        onSave={onUpdateAirSzzPoint}
        onDelete={deleteAirSzzPoint}
        entityColumns={
          [ {
              title: "",
              dataIndex: "btnc" as keyof AirSzzPoint,
              key: "btnc",
              inputNode: <></>,
              render: (value: any, record: AirSzzPoint, index: any) =>
                <Button.Group>
                  <Button icon={<CopyOutlined />} onClick={() => { copy(record.id) }}></Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },
            ...airSzzPointsColumns, 
            {
              title: "Данные",
              dataIndex: "data" as keyof AirSzzPoint,
              key: "data",
              inputNode: <></>,
              render: (value: any, record: AirSzzPoint, index: any) =>
                <Button.Group>
                  <Button onClick={() => { setPoint(record) }}>Загрязнения</Button>
                </Button.Group>
              ,
              editable: false,
              copyable: false,
            },]
        }
        dataSource={airSzzPoints}
        footer={() => addButton}
      />
      {point && (
        <Modal
          open={true}
          title={`Загрязнения - ${point?.name}`}
          onCancel={() => setPoint(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"85%"}
        >
          <AirSzzPointPollutantsComponent pointId={point.id} />
        </Modal>
      )}
    </Layout>
  );
};
