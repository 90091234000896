import { Button, Layout } from "antd";
import { CreateRopCalculationsTransportDto } from "../../api/models/create-rop-calculations-transport-dto";
import { UpdateRopCalculationsTransportDto } from "../../api/models/update-rop-calculations-transport-dto";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createRopCalculationTransportAsync, deleteRopCalculationTransportAsync, fetchRopCalculationsTransportByQuarterAsync, selectRopCalculationsTransport, updateRopCalculationTransportAsync } from "./RopCalculationsTransportSlice";
import { fetchRopTransportClassifiersAsync, selectRopTransportClassifiers } from "./RopTransportClassifiersSlice";
import React, { useEffect, useState } from "react";
import { RopCalculationTransport } from "../../api/models/rop-calculation-transport";
import { fetchFilesByIdsAsync } from "../files/FilesEntitySlice";
import { RopCalculationsTransportForm } from "./components/RopCalculationsTransportForm";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { RopCalculationsTransportColumns } from "./form/columns";
import { BASE_PATH } from "../../api/base";
import { FileForm } from "../files/components/FileForm";
import { FolderOutlined } from '@ant-design/icons';
import { selectOrganizationsProfile } from "../organizationProfile/organizationProfileSlice";

interface RopCalculationsTransportComponentProps {
    organizationId?: string,
    year: number;
    quarter: number;
}

export const RopCalculationsTransportComponent: React.FC<RopCalculationsTransportComponentProps> = ({ organizationId, year, quarter }) => {
    const dispatch = useAppDispatch();
    const transportClassifiers = useAppSelector(selectRopTransportClassifiers);
    const calculations: RopCalculationTransport[] = useAppSelector(selectRopCalculationsTransport);
    const organization = useAppSelector(selectOrganizationsProfile)
    useEffect(() => {
        dispatch(fetchRopTransportClassifiersAsync());
        if (organizationId) {
            if (year && quarter) {
                dispatch(fetchRopCalculationsTransportByQuarterAsync({ organizationId, year, quarter }));
            } else {
                dispatch(fetchRopCalculationsTransportByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
            }
        }
    }, [year, quarter, organizationId, dispatch]);

    const [visibleForm, setFormVisible] = useState(false);

    const onUpdate = async (dto: any, id: string) => {
        let ropCalculation = dto
        return dispatch(updateRopCalculationTransportAsync({ ropCalculation, id })).then((res: any) => {
            if (!res.error) {
                if (organizationId) {
                    if (year && quarter) {
                        dispatch(fetchRopCalculationsTransportByQuarterAsync({ organizationId, year, quarter }));
                    } else {
                        dispatch(fetchRopCalculationsTransportByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
                    }
                }
                return true;
            }
            return false;
        });
    };

    const onCreateRopCalculation = (ropCalculation: CreateRopCalculationsTransportDto) => {
        if (!organizationId || !year || !quarter) return;

        dispatch(createRopCalculationTransportAsync({ ...ropCalculation, organizationId, year, quarter })).then((res: any) => {
            if (!res.error) {
                setFormVisible(false);
            }
        });
    };

    const deleteRopCalculation = (id: string) => {
        return dispatch(deleteRopCalculationTransportAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }

    const addButton = (
        <Button onClick={() => setFormVisible(true)} block type="dashed" className="">
            + Добавить запись
        </Button>)

    const [visible, setVisible] = useState(false);
    const [ropCalculation, setCurrentRopCalculation] = useState<RopCalculationTransport>();

    const showFileModal = async (calculation: RopCalculationTransport) => {
        // Fetch files for the current calculation
        if (calculation.fileIds?.length > 0) {
            await dispatch(fetchFilesByIdsAsync({ ids: calculation.fileIds?.join(',') }));
        }

        setCurrentRopCalculation(calculation);
        setVisible(true);
    };
    
    return (
        <Layout className="bg-white p-4 rounded-lg">
            {visibleForm && <RopCalculationsTransportForm visible={visibleForm} setVisible={setFormVisible} onCreateRopCalculations={onCreateRopCalculation} organizationId={organizationId} classifiers={transportClassifiers} />}
            <div className="table-responsive">
                <EditableTable<RopCalculationTransport, UpdateRopCalculationsTransportDto>
                    onSave={(value, id) => onUpdate(value, id)}
                    onDelete={(calculation) => deleteRopCalculation(calculation.id)}
                    entityColumns={[
                        {
                            title: "Файлы",
                            dataIndex: "btnc" as keyof RopCalculationTransport,
                            key: "btnc",
                            inputNode: <></>,
                            render: (value: any, record: RopCalculationTransport, index: any) => (
                                <Button.Group>
                                    <Button icon={<FolderOutlined />} onClick={() => showFileModal(record)}></Button>
                                </Button.Group>
                            )
                            ,
                            editable: false,
                            copyable: false,
                        },
                        ...RopCalculationsTransportColumns(organization)]}
                    dataSource={calculations}
                    footer={() => addButton}
                />
            </div>
            <Button href={`${BASE_PATH}/rop-calculations-transport/report/organization/${organizationId}/year/${year}/quarter/${quarter}`} block type="primary" ghost>
                Получить отчёт
            </Button>
            {visible && <FileForm visible={visible} setVisible={setVisible} onUpdate={onUpdate} fileIds={ropCalculation?.fileIds} id={ropCalculation?.id} />}
        </Layout>

    );
}
