import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { WasteManagementDocument } from "../../api/models/waste-management-document";
import { CreateWasteManagementDocumentDto } from "../../api/models/create-waste-management-document-dto";
import { WasteManagementDocumentsApi } from "../../api/apis/waste-management-documents-api";
import { UpdateWasteManagementDocumentDto } from "../../api/models/update-waste-management-document-dto";

export interface WasteManagementDocumentState {
    wasteManagementDocuments: WasteManagementDocument[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: WasteManagementDocumentState = {
    wasteManagementDocuments: [],
    status: "idle",
};

export const createWasteManagementDocumentAsync = createAsyncThunk(
    "wasteManagementDocument/create",
    async (wasteManagementDocument: CreateWasteManagementDocumentDto, { rejectWithValue }) => {
        try {
            const wasteManagementDocumentsApi = new WasteManagementDocumentsApi();
            const response = await wasteManagementDocumentsApi.wasteManagementDocumentsControllerCreate(wasteManagementDocument);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateWasteManagementDocumentAsync = createAsyncThunk(
    "wasteManagementDocument/update",
    async ({ wasteManagementDocument, id }: { wasteManagementDocument: UpdateWasteManagementDocumentDto; id: string }, { rejectWithValue }) => {
        try {
            const wasteManagementDocumentsApi = new WasteManagementDocumentsApi();
            const response = await wasteManagementDocumentsApi.wasteManagementDocumentsControllerUpdate(wasteManagementDocument, id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteWasteManagementDocumentAsync = createAsyncThunk(
    "wasteManagementDocument/remove",
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const wasteManagementDocumentsApi = new WasteManagementDocumentsApi();
            const response = await wasteManagementDocumentsApi.wasteManagementDocumentsControllerRemove(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchWasteManagementDocumentByQuarterAsync = createAsyncThunk(
    "wasteManagementDocument/fetch",
    async ({ organizationId }: { organizationId: string }, { rejectWithValue }) => {
        try {
            const wasteManagementDocumentsApi = new WasteManagementDocumentsApi();
            const response = await wasteManagementDocumentsApi.wasteManagementDocumentsControllerFindByQuart(organizationId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchWasteManagementDocumentBySite = () => {
        const { pending, fulfilled, rejected } = fetchWasteManagementDocumentByQuarterAsync;
        return {
            [`${pending}`]: (state: WasteManagementDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: WasteManagementDocumentState, action: PayloadAction<WasteManagementDocument[]>) => {
                state.wasteManagementDocuments = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: WasteManagementDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const createWasteManagementDocument = () => {
        const { pending, fulfilled, rejected } = createWasteManagementDocumentAsync;
        return {
            [`${pending}`]: (state: WasteManagementDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: WasteManagementDocumentState, action: PayloadAction<WasteManagementDocument>) => {
                state.wasteManagementDocuments.push(action.payload);
                message.success("Успешно создан");
                state.status = "idle";
            },
            [`${rejected}`]: (state: WasteManagementDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const updateWasteManagementDocument = () => {
        const { pending, fulfilled, rejected } = updateWasteManagementDocumentAsync;

        return {
            [`${pending}`]: (state: WasteManagementDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: WasteManagementDocumentState, action: PayloadAction<WasteManagementDocument>) => {
                state.wasteManagementDocuments = state.wasteManagementDocuments.map((wasteManagementDocument) => (wasteManagementDocument.id === action.payload.id ? action.payload : wasteManagementDocument));
                message.success("Успешно изменено");
                state.status = "idle";
            },
            [`${rejected}`]: (state: WasteManagementDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const removeWasteManagementDocument = () => {
        const { pending, fulfilled, rejected } = deleteWasteManagementDocumentAsync;
        return {
            [`${pending}`]: (state: WasteManagementDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: WasteManagementDocumentState, action: PayloadAction<WasteManagementDocument>) => {
                state.wasteManagementDocuments.splice(state.wasteManagementDocuments.indexOf(
                    state.wasteManagementDocuments.find((ws: WasteManagementDocument) => ws.id === action.payload.id) || action.payload
                ), 1);
                message.success("Успешно удален");
                state.status = "idle";
            },
            [`${rejected}`]: (state: WasteManagementDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    return { ...fetchWasteManagementDocumentBySite(), ...createWasteManagementDocument(), ...updateWasteManagementDocument(), ...removeWasteManagementDocument() };
};

export const WasteManagementDocumentSlice = createSlice({
    name: "WasteManagementDocument",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
});

export const selectWasteManagementDocuments = (state: RootState) => state.wasteManagementDocuments.wasteManagementDocuments;

export default WasteManagementDocumentSlice.reducer;
