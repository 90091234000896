import { Input } from "antd";
import { ReportPek } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectRadiologyPoints } from "../../radiologyPoints/RadiologyPointsSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import TextArea from "antd/es/input/TextArea";

export const ReportPeksColumns = (): EntityColumns<ReportPek>[] => {

  const radiologyPoints = useAppSelector(selectRadiologyPoints);
  let list: any = [];
  for (const point of radiologyPoints) {
    for (const pollutant of (point.pollutants || [])) {
      list = [...list, {
        value: pollutant.id,
        label: `№${point.no}, точка: ${point.name}, загрязнение: ${pollutant.name}`
      }];
    }
  }

  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      editable: true,
      copyable: true,
    },
  ];
}
