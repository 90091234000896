import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateCarbonMobileSourceDto, CarbonMobileSourceApi, UpdateCarbonMobileSourceDto, CarbonMobileSource } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface CarbonMobileSourceState {
  carbonMobileSource: CarbonMobileSource[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CarbonMobileSourceState = {
  carbonMobileSource: [],
  status: "idle",
};

export const createCarbonMobileSourceAsync = createAsyncThunk(
  "carbonMobileSource/create",
  async (carbonMobileSource: CreateCarbonMobileSourceDto, { rejectWithValue }) => {
    try {
      const carbonMobileSourceApi = new CarbonMobileSourceApi();
      const response = await carbonMobileSourceApi.carbonMobileSourceControllerCreate(carbonMobileSource);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCarbonMobileSourceAsync = createAsyncThunk(
  "carbonMobileSource/update",
  async ({ carbonMobileSource, id }: { carbonMobileSource: UpdateCarbonMobileSourceDto; id: string }, { rejectWithValue }) => {
    try {
      const carbonMobileSourceApi = new CarbonMobileSourceApi();
      const response = await carbonMobileSourceApi.carbonMobileSourceControllerUpdate(carbonMobileSource, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCarbonMobileSourceAsync = createAsyncThunk(
  "carbonMobileSource/remove",
  async ({ carbonMobileSource }: { carbonMobileSource: CarbonMobileSource }, { rejectWithValue }) => {
    try {
      const carbonMobileSourceApi = new CarbonMobileSourceApi();
      const response = await carbonMobileSourceApi.carbonMobileSourceControllerRemove(carbonMobileSource.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCarbonMobileSourceByOrganizationIdAsync = createAsyncThunk("carbonMobileSource/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const carbonMobileSourceApi = new CarbonMobileSourceApi();
    const response = await carbonMobileSourceApi.carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchCarbonMobileSourceByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchCarbonMobileSourceByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: CarbonMobileSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonMobileSourceState, action: PayloadAction<CarbonMobileSource[]>) => {
        state.carbonMobileSource = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonMobileSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCarbonMobileSource = () => {
    const { pending, fulfilled, rejected } = createCarbonMobileSourceAsync;
    return {
      [`${pending}`]: (state: CarbonMobileSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonMobileSourceState, action: PayloadAction<CarbonMobileSource>) => {
        state.carbonMobileSource.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonMobileSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCarbonMobileSource = () => {
    const { pending, fulfilled, rejected } = updateCarbonMobileSourceAsync;

    return {
      [`${pending}`]: (state: CarbonMobileSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonMobileSourceState, action: PayloadAction<CarbonMobileSource>) => {
        state.carbonMobileSource = state.carbonMobileSource.map((carbonMobileSource) => (carbonMobileSource.id === action.payload.id ? action.payload : carbonMobileSource));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonMobileSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeCarbonMobileSource = () => {
    const { pending, fulfilled, rejected } = deleteCarbonMobileSourceAsync;
    return {
      [`${pending}`]: (state: CarbonMobileSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonMobileSourceState, action: PayloadAction<CarbonMobileSource>) => {
        state.carbonMobileSource.splice(state.carbonMobileSource.indexOf(
          state.carbonMobileSource.find((ws: CarbonMobileSource) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonMobileSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCarbonMobileSourceByOrganization(), ...createCarbonMobileSource(), ...updateCarbonMobileSource(), ...removeCarbonMobileSource() };
};

export const carbonMobileSourceSlice = createSlice({
  name: "carbonMobileSource",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectCarbonMobileSource = (state: RootState) => state.carbonMobileSource.carbonMobileSource;

export default carbonMobileSourceSlice.reducer;
