import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreatePollutionSourceDto, PageDto, PageMetaDto, PollutionSource, PollutionSourcesApi, UpdatePollutionSourceDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface PollutionSourceState {
  pollutionSources: PollutionSource[];
  pollutionSourcesForMethodic: PollutionSource[];
  pagination?: PageMetaDto;
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: PollutionSourceState = {
  pollutionSources: [],
  pollutionSourcesForMethodic: [],
  status: "idle",
};

export const fetchPollutionSourceByOrganizationIdAllAsync = createAsyncThunk("pollutionSources/fetchByOrganizationIdAll", async (organizationId: string, { rejectWithValue }) => {
  try {
    const pollutionSourcesApi = new PollutionSourcesApi();
    const response = await pollutionSourcesApi.pollutionSourcesControllerFindAllByOrganizationId(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPollutionSourceByOrganizationIdAsync = createAsyncThunk("pollutionSources/fetchByOrganizationId", async (organizationId: string, { rejectWithValue }) => {
  try {
    const pollutionSourcesApi = new PollutionSourcesApi();
    const response = await pollutionSourcesApi.pollutionSourcesControllerFindByOrganizationId(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPollutionSourceAsync = createAsyncThunk("pollutionSources/fetch", async (workshopId: string, { rejectWithValue }) => {
  try {
    const pollutionSourcesApi = new PollutionSourcesApi();
    const response = await pollutionSourcesApi.pollutionSourcesControllerFindByWorkshopId(workshopId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPollutionSourcesForMethodicAsync = createAsyncThunk(
  "pollutionSources/fetchForMethodic",
  async (organizationId: string, { rejectWithValue }) => {
    try {
      const pollutionSourcesApi = new PollutionSourcesApi();
      const response = await pollutionSourcesApi.pollutionSourcesControllerFindForMethodicByOrganizationId(organizationId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPollutionSourceAsync = createAsyncThunk(
  "pollutionSources/create",
  async (pollutionSource: CreatePollutionSourceDto, { rejectWithValue }) => {
    try {
      const pollutionSourcesApi = new PollutionSourcesApi();
      const response = await pollutionSourcesApi.pollutionSourcesControllerCreate(pollutionSource);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePollutionSourceAsync = createAsyncThunk(
  "pollutionSources/update",
  async ({ pollutionSource, id }: { pollutionSource: UpdatePollutionSourceDto; id: string }, { rejectWithValue }) => {
    try {
      const pollutionSourcesApi = new PollutionSourcesApi();
      const response = await pollutionSourcesApi.pollutionSourcesControllerUpdate(pollutionSource, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removePollutionSourceAsync = createAsyncThunk("pollutionSources/remove", async (id: string, { rejectWithValue }) => {
  try {
    const pollutionSourcesApi = new PollutionSourcesApi();
    const response = await pollutionSourcesApi.pollutionSourcesControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {

  const fetchPollutionSourceByOrganizationIdAll = () => {
    const { pending, fulfilled, rejected } = fetchPollutionSourceByOrganizationIdAllAsync;
    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PollutionSource[]>) => {
        state.pollutionSources = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  }

  const fetchPollutionSourceByOrganizationId = () => {
    const { pending, fulfilled, rejected } = fetchPollutionSourceByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PageDto<PollutionSource>>) => {
        state.pollutionSources = action.payload.data;
        state.pagination = action.payload.meta;
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  }

  const fetchPollutionSource = () => {
    const { pending, fulfilled, rejected } = fetchPollutionSourceAsync;
    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PageDto<PollutionSource>>) => {
        state.pollutionSources = action.payload.data;
        state.pagination = action.payload.meta;
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchPollutionSourcesForMethodic = () => {
    const { pending, fulfilled, rejected } = fetchPollutionSourcesForMethodicAsync;
    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PollutionSource[]>) => {
        state.pollutionSourcesForMethodic = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createPollutionSource = () => {
    const { pending, fulfilled, rejected } = createPollutionSourceAsync;
    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PollutionSource>) => {
        state.pollutionSources.push(action.payload);
        if (state.pagination) state.pagination.itemCount++;
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updatePollutionSource = () => {
    const { pending, fulfilled, rejected } = updatePollutionSourceAsync;

    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PollutionSource>) => {
        state.pollutionSources = state.pollutionSources.map((pollutionSource) =>
          pollutionSource.id === action.payload.id ? action.payload : pollutionSource
        );
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removePollutionSource = () => {
    const { pending, fulfilled, rejected } = removePollutionSourceAsync;

    return {
      [`${pending}`]: (state: PollutionSourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutionSourceState, action: PayloadAction<PollutionSource>) => {
        state.pollutionSources = state.pollutionSources.filter((pollutionSource) => pollutionSource.id !== action.payload.id);
        message.success("Успешно удалено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutionSourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return {
    ...fetchPollutionSourceByOrganizationIdAll(),
    ...fetchPollutionSourceByOrganizationId(),
    ...fetchPollutionSource(),
    ...createPollutionSource(),
    ...updatePollutionSource(),
    ...fetchPollutionSourcesForMethodic(),
    ...removePollutionSource(),
  };
};

export const pollutionSourcesSlice = createSlice({
  name: "pollutionSources",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectPollutionSources = (state: RootState) => state.pollutionSources.pollutionSources;
export const selectPollutionSourcesForMethodic = (state: RootState) => state.pollutionSources.pollutionSourcesForMethodic;
export const selectPollutionSourcesPagination = (state: RootState) => state.pollutionSources.pagination;

export default pollutionSourcesSlice.reducer;
