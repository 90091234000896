import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateCategoryDto, UpdateCategoryDto, Category, CategoriesApi } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface CategoriesState {
  categories: Category[];
  categoryProfile?: Category;
  statusProfile: "idle" | "loading" | "failed";
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CategoriesState = {
  categories: [],
  status: "idle",
  statusProfile: "idle",
};

export const fetchCategoriesAsync = createAsyncThunk("categories/fetch", async (organizationId: string, { rejectWithValue }) => {
  try {
    const categoriesApi = new CategoriesApi();
    const response = await categoriesApi.categoriesControllerFindByOrganizationId(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchCategoryProfileAsync = createAsyncThunk("categories/fetchProfile", async (categoryId: string, { rejectWithValue }) => {
  try {
    const categoriesApi = new CategoriesApi();
    const response = await categoriesApi.categoriesControllerFindOne(categoryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCategoryAsync = createAsyncThunk("categories/create", async (category: CreateCategoryDto, { rejectWithValue }) => {
  try {
    const categoriesApi = new CategoriesApi();
    const response = await categoriesApi.categoriesControllerCreate(category);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCategoryAsync = createAsyncThunk(
  "categories/update",
  async ({ category, id }: { category: UpdateCategoryDto; id: string }, { rejectWithValue }) => {
    try {
      const categoriesApi = new CategoriesApi();
      const response = await categoriesApi.categoriesControllerUpdate(category, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCategoryAsync = createAsyncThunk("categories/delete", async (id: string, { rejectWithValue }) => {
  try {
    const categoriesApi = new CategoriesApi();
    const response = await categoriesApi.categoriesControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchCategories = () => {
    const { pending, fulfilled, rejected } = fetchCategoriesAsync;
    return {
      [`${pending}`]: (state: CategoriesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoriesState, action: PayloadAction<Category[]>) => {
        state.categories = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoriesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchCategoryProfile = () => {
    const { pending, fulfilled, rejected } = fetchCategoryProfileAsync;

    return {
      [`${pending}`]: (state: CategoriesState) => {
        state.statusProfile = "loading";
      },
      [`${fulfilled}`]: (state: CategoriesState, action: PayloadAction<Category>) => {
        state.categoryProfile = action.payload;
        state.statusProfile = "idle";
      },
      [`${rejected}`]: (state: CategoriesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.statusProfile = "failed";
        state.error = error;
      },
    };
  };

  const createCategory = () => {
    const { pending, fulfilled, rejected } = createCategoryAsync;
    return {
      [`${pending}`]: (state: CategoriesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoriesState, action: PayloadAction<Category>) => {
        state.categories.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoriesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCategory = () => {
    const { pending, fulfilled, rejected } = updateCategoryAsync;

    return {
      [`${pending}`]: (state: CategoriesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoriesState, action: PayloadAction<Category>) => {
        state.categories = state.categories.map((category) => (category.id === action.payload.id ? action.payload : category));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoriesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const deleteCategory = () => {
    const { pending, fulfilled, rejected } = deleteCategoryAsync;

    return {
      [`${pending}`]: (state: CategoriesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoriesState, action: PayloadAction<Category>) => {
        state.categories = state.categories.filter((category) => category.id !== action.payload.id);

        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoriesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCategories(), ...createCategory(), ...updateCategory(), ...fetchCategoryProfile(), ...deleteCategory() };
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectCategories = (state: RootState) => state.categories.categories;
export const selectCategoryProfile = (state: RootState) => state.categories.categoryProfile;

export default categoriesSlice.reducer;
