import { DatePicker, Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DefaultOptionType } from "antd/es/select";
import Text from "antd/es/typography/Text";
import { UserRolesEnum, WasteApplication16Record, WasteContragent } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { getPermittedRolesToCreateUser, UserRoles, UserRole, getRoleLabel } from "../../../../app/role";
import { EntityColumns } from "../../../../app/types";
import { selectAuthUser } from "../../../auth/authSlice";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";
import { selectWasteContragents } from "../../../wasteContragents/WasteContragentsSlice";

export const WasteApplication16RecordsColumns = (): EntityColumns<WasteApplication16Record>[] => {
  const wasteContragents = useAppSelector(selectWasteContragents);
  const period = useAppSelector(selectOrganizationModulePeriod);
  const authUser = useAppSelector(selectAuthUser);

  let superRoles = [
    UserRolesEnum.Admin,
    UserRolesEnum.Head,
    UserRolesEnum.Ecolog,
    UserRolesEnum.Chief,
  ];

  let roles: DefaultOptionType[] = authUser ? [
    getRoleLabel(UserRolesEnum.Accounting),
  ] : [];

  function getWasteContragentLabel(r: WasteContragent): string {
    return `${r.name} ${r.contractNumberAndDateOfConclusion}`
  }

  const hideOnForm = (superRoles.find((sr) => authUser?.roles.includes(sr))) ? false : true;

  return [
    {
      title: "Дата реализации",
      dataIndex: "date",
      key: "date",
      inputNode: <DatePicker format={"DD/MM/YYYY"} style={{ width: '100%' }} />,
      render: (date) =>
        <>
          {date && <Text className="w-64" ellipsis={{ tooltip: new Date(date).toLocaleDateString('en-GB') }}>{new Date(date).toLocaleDateString('en-GB')}</Text>}
        </>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "№ документа",
      dataIndex: "documentNo",
      key: "documentNo",
      inputNode: <Input />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Наименование контрагента",
      dataIndex: "wasteContragent",
      key: "wasteContragentId",
      inputNode: <Select options={wasteContragents.map(
        (wasteContragent) => {
          return {
            value: wasteContragent.id,
            label: getWasteContragentLabel(wasteContragent)
          }
        }
      )} />,
      render: (wasteContragent) => {
        const wrOpt = wasteContragents.find((wr) => wr.id === wasteContragent)
        const label = (wrOpt) ? getWasteContragentLabel(wrOpt) : ""
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>;
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "ТМЗ номенкулатура (отход)",
      dataIndex: "tmzNomenclature",
      key: "tmzNomenclature",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Единица измерения",
      dataIndex: "measure",
      key: "measure",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Количество",
      dataIndex: "count",
      key: "count",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Отдел",
      dataIndex: "role",
      key: "role",
      inputNode: <Select placeholder="Выберите Отдел" options={roles} />,
      render: (role) => (
        <Text className="w-64" ellipsis={{ tooltip: UserRoles[role as UserRole] }}>
          {UserRoles[role as UserRole]}
        </Text>
      ),
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: hideOnForm,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    // {
    //   title: "Квартал",
    //   dataIndex: "month",
    //   key: "month",
    //   inputNode: <Input defaultValue={month} style={{ width: '100%' }} disabled={true} />,
    //   rules: [{ required: false, message: "Заполните поле" }],
    //   editable: false,
    //   copyable: true,
    // },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
