import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateDropDto, Drop, UpdateDropDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { DropForm } from "./components/DropForm";
import { DropColumns } from "./form/columns";
import { createDropAsync, updateDropAsync, selectDrop, deleteDropAsync, fetchDropByOrganizationIdAsync } from "./DropsSlice";
import { fetchPollutionSubstancesAsync } from "../pollutionSubstances/pollutionSubstancesSlice";
import { fetchInfluenceObjectByOrganizationIdAsync } from "../influenceObjects/InfluenceObjectsSlice";
import { fetchDropCodesAsync } from "../dropCodes/DropCodesSlice";

interface DropComponentProps {
  organizationId?: string;
}

export const DropComponent: React.FC<DropComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const drop = useAppSelector(selectDrop);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchDropByOrganizationIdAsync(organizationId));
      dispatch(fetchInfluenceObjectByOrganizationIdAsync(organizationId));
      dispatch(fetchDropCodesAsync());
      dispatch(fetchPollutionSubstancesAsync());
    }
  }, [organizationId, dispatch]);

  const onUpdate = async (drop: UpdateDropDto, id: string) => {
    return dispatch(updateDropAsync({ drop, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateDrop = (drop: CreateDropDto) => {
    if (!organizationId) return;

    dispatch(createDropAsync({ ...drop, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteDrop = (drop: Drop) => {
    return dispatch(deleteDropAsync({ drop })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить сброс
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <DropForm visible={visible} setVisible={setVisible} onCreateDrop={onCreateDrop} />}
      <EditableTable<Drop, UpdateDropDto>
        onSave={onUpdate}
        onDelete={deleteDrop}
        entityColumns={DropColumns()}
        dataSource={structuredClone(drop).map((data: any) => {
          const influenceObject = data?.influenceObject?.id;
          delete data?.influenceObject;
          data.influenceObject = influenceObject;

          const dropCode = data?.dropCode?.id;
          delete data?.dropCode;
          data.dropCode = dropCode;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
