import React from "react";
import mainLogo from './polymetallogo.png';

interface LogoSize {
  width: string;
  height: string;
};

function Logo(params: LogoSize) {
  return (
    <img src={mainLogo} width={params.width} height={params.height} />
  );
}

export default Logo;
