import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateDropResultDto, DropResult, Organization, UpdateDropResultDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { DropResultForm } from "./components/DropResultForm";
import { DropResultColumns } from "./form/columns";
import { createDropResultAsync, updateDropResultAsync, selectDropResult, deleteDropResultAsync, fetchDropResultByOrganizationIdAsync } from "./DropResultsSlice";
import { fetchPollutionSubstancesAsync } from "../pollutionSubstances/pollutionSubstancesSlice";
import { fetchInfluenceObjectByOrganizationIdAsync } from "../influenceObjects/InfluenceObjectsSlice";
import { fetchDropByOrganizationIdAsync, fetchDropByOrganizationIdPeriodLinkedAsync } from "../drops/DropsSlice";

interface DropResultComponentProps {
  organizationId?: string;
  year?: number;
  quarter?: number;
}

export const DropResultComponent: React.FC<DropResultComponentProps> = ({ organizationId, year, quarter }) => {
  const dispatch = useAppDispatch();
  const dropResult = useAppSelector(selectDropResult);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId && year && quarter) {
      dispatch(fetchDropResultByOrganizationIdAsync({ organizationId, year, quarter }));
      dispatch(fetchDropByOrganizationIdPeriodLinkedAsync({ organizationId, year, quarter }));
    }
  }, [organizationId, year, quarter, dispatch]);

  const onUpdate = async (dropResult: UpdateDropResultDto, id: string) => {
    return dispatch(updateDropResultAsync({ dropResult, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateDropResult = (dropResult: CreateDropResultDto) => {
    if (!organizationId || !year || !quarter) return;

    dispatch(createDropResultAsync({ ...dropResult, organizationId, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteDropResult = (dropResult: DropResult) => {
    return dispatch(deleteDropResultAsync({ dropResult })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <DropResultForm visible={visible} setVisible={setVisible} onCreateDropResult={onCreateDropResult} />}
      <EditableTable<DropResult, UpdateDropResultDto>
        onSave={onUpdate}
        onDelete={deleteDropResult}
        entityColumns={DropResultColumns()}
        dataSource={structuredClone(dropResult).map((data: any) => {
          const drop = data?.drop?.id;
          delete data?.drop;
          data.drop = drop;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
