import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { BASE_PATH } from "../../api/base";
import { CreateReportPaymentDto } from "../../api/models/create-report-payment-dto";
import { ReportPayment } from "../../api/models/report-payment";
import { UpdateReportPaymentDto } from "../../api/models/update-report-payment-dto";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchRadiologyPointsByOrganizationidAsync } from "../radiologyPoints/RadiologyPointsSlice";
import { ReportPaymentForm } from "./components/ReportPaymentsForm";
import { ReportPaymentsColumns } from "./form/columns";
import { createReportPaymentAsync, updateReportPaymentAsync, selectReportPayments, deleteReportPaymentAsync, fetchReportPaymentsByQuarterAsync, generateReportPaymentAsync } from "./ReportPaymentsSlice";

interface ReportPaymentsComponentProps {
  organizationId?: string;
  year?: number;
  quarter?: number;
}

export const ReportPaymentsComponent: React.FC<ReportPaymentsComponentProps> = ({ organizationId, year, quarter }) => {
  const dispatch = useAppDispatch();
  const reportPayments = useAppSelector(selectReportPayments);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      if (year && quarter) {
        dispatch(fetchReportPaymentsByQuarterAsync({ organizationId, year, quarter }));
      } else {
        dispatch(fetchReportPaymentsByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
      }

      dispatch(fetchRadiologyPointsByOrganizationidAsync(organizationId));
    }
  }, [year, quarter, organizationId, dispatch]);

  const onUpdate = async (reportPayment: UpdateReportPaymentDto, id: string) => {
    return dispatch(updateReportPaymentAsync({ reportPayment, id })).then((res: any) => {
      if (!res.error) {
        if (organizationId && year && quarter) {
          dispatch(fetchReportPaymentsByQuarterAsync({ organizationId, year, quarter }));
        }
        return true;
      }
      return false;
    });
  };

  const onCreateReportPayment = (reportPayment: CreateReportPaymentDto) => {
    if (!organizationId || !year || !quarter) return;

    dispatch(createReportPaymentAsync({ ...reportPayment, organizationId, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteReportPayments = (reportPayment: ReportPayment) => {
    return dispatch(deleteReportPaymentAsync({ reportPayment })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const generate = async (id: string) => {
    return dispatch(generateReportPaymentAsync({ id })).then((res: any) => {
      if (!res.error) {
        if (organizationId && year && quarter) {
          dispatch(fetchReportPaymentsByQuarterAsync({ organizationId, year, quarter }));
        }
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить отчет
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <ReportPaymentForm visible={visible} setVisible={setVisible} onCreateReportPayment={onCreateReportPayment} organizationId={organizationId} />}
      <div className="table-responsive">
        <EditableTable<ReportPayment, UpdateReportPaymentDto>
          onSave={onUpdate}
          onDelete={deleteReportPayments}
          entityColumns={
            [...ReportPaymentsColumns(), {
              title: "Операции",
              dataIndex: "operation" as keyof ReportPayment,
              key: "operation",
              inputNode: <></>,
              render: (value: any, record: ReportPayment, index: any) => {
                if (record.isGenerated) {
                  return <Button.Group><Button href={`${BASE_PATH}/report-payments/${record.id}/download`} block type="primary" ghost>Скачать</Button></Button.Group>;
                } else {
                  return <Button.Group><Button onClick={() => { generate(record.id) }}>Сформировать</Button></Button.Group>;
                }
              }
              ,
              editable: false,
              copyable: false,
            },]
          }
          dataSource={reportPayments}
          footer={() => addButton}
        />
      </div>
    </Layout>
  );
};
