import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterDrainageHouseholdDto, WaterDrainageHouseholdApi, UpdateWaterDrainageHouseholdDto, WaterDrainageHousehold } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WaterDrainageHouseholdState {
  waterDrainageHousehold: WaterDrainageHousehold[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterDrainageHouseholdState = {
  waterDrainageHousehold: [],
  status: "idle",
};

export const createWaterDrainageHouseholdAsync = createAsyncThunk(
  "waterDrainageHousehold/create",
  async (waterDrainageHousehold: CreateWaterDrainageHouseholdDto, { rejectWithValue }) => {
    try {
      const waterDrainageHouseholdApi = new WaterDrainageHouseholdApi();
      const response = await waterDrainageHouseholdApi.waterDrainageHouseholdsControllerCreate(waterDrainageHousehold);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterDrainageHouseholdAsync = createAsyncThunk(
  "waterDrainageHousehold/update",
  async ({ waterDrainageHousehold, id }: { waterDrainageHousehold: UpdateWaterDrainageHouseholdDto; id: string }, { rejectWithValue }) => {
    try {
      const waterDrainageHouseholdApi = new WaterDrainageHouseholdApi();
      const response = await waterDrainageHouseholdApi.waterDrainageHouseholdsControllerUpdate(waterDrainageHousehold, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterDrainageHouseholdAsync = createAsyncThunk(
  "waterDrainageHousehold/remove",
  async ({ waterDrainageHousehold }: { waterDrainageHousehold: WaterDrainageHousehold }, { rejectWithValue }) => {
    try {
      const waterDrainageHouseholdApi = new WaterDrainageHouseholdApi();
      const response = await waterDrainageHouseholdApi.waterDrainageHouseholdsControllerRemove(waterDrainageHousehold.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterDrainageHouseholdByOrganizationIdAsync = createAsyncThunk("waterDrainageHousehold/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const waterDrainageHouseholdApi = new WaterDrainageHouseholdApi();
    const response = await waterDrainageHouseholdApi.waterDrainageHouseholdsControllerFindByOrganizationid(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterDrainageHouseholdByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWaterDrainageHouseholdByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: WaterDrainageHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<WaterDrainageHousehold[]>) => {
        state.waterDrainageHousehold = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterDrainageHousehold = () => {
    const { pending, fulfilled, rejected } = createWaterDrainageHouseholdAsync;
    return {
      [`${pending}`]: (state: WaterDrainageHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<WaterDrainageHousehold>) => {
        state.waterDrainageHousehold.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterDrainageHousehold = () => {
    const { pending, fulfilled, rejected } = updateWaterDrainageHouseholdAsync;

    return {
      [`${pending}`]: (state: WaterDrainageHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<WaterDrainageHousehold>) => {
        state.waterDrainageHousehold = state.waterDrainageHousehold.map((waterDrainageHousehold) => (waterDrainageHousehold.id === action.payload.id ? action.payload : waterDrainageHousehold));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterDrainageHousehold = () => {
    const { pending, fulfilled, rejected } = deleteWaterDrainageHouseholdAsync;
    return {
      [`${pending}`]: (state: WaterDrainageHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<WaterDrainageHousehold>) => {
        state.waterDrainageHousehold.splice(state.waterDrainageHousehold.indexOf(
          state.waterDrainageHousehold.find((ws: WaterDrainageHousehold) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterDrainageHouseholdByOrganization(), ...createWaterDrainageHousehold(), ...updateWaterDrainageHousehold(), ...removeWaterDrainageHousehold() };
};

export const waterDrainageHouseholdSlice = createSlice({
  name: "waterDrainageHousehold",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterDrainageHousehold = (state: RootState) => state.waterDrainageHousehold.waterDrainageHousehold;

export default waterDrainageHouseholdSlice.reducer;
