import { Form, Input, InputNumber, Modal } from "antd";
import React, { useState } from "react";
import { CreateAirSzzJournalDto } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSubstances } from "../../pollutionSubstances/pollutionSubstancesSlice";
import Select, { DefaultOptionType } from "antd/es/select";
import { selectAirSzzPoints } from "../../airSzzPoints/AirSzzPointsSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";

interface AirSzzJournalsFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateAirSzzJournal: (values: CreateAirSzzJournalDto) => void;
  organizationId?: string;
}

export const AirSzzJournalsForm: React.FC<AirSzzJournalsFormProps> = ({ visible, setVisible, onCreateAirSzzJournal, organizationId }) => {
  const [form] = Form.useForm<any>();

  let [selectedPoint, setPoint] = useState()

  const pollutantCodes = useAppSelector(selectPollutionSubstances);
  let listPC: DefaultOptionType[] = [];
  for (const item of pollutantCodes) {
    listPC = [...listPC, {
      value: item.code,
      label: `${item.code} - ${item.name}`
    }];
  }

  function getLabelByCode(code: string) {
    return listPC.find(item => item.value === code)?.label
  }

  const points = useAppSelector(selectAirSzzPoints);
  let pollutantsByPoint: any = [];
  for (const point of points) {
    if (point.id === selectedPoint) {
      for (const pollutant of (point.pollutants || [])) {
        pollutantsByPoint = [...pollutantsByPoint, {
          id: pollutant.id
        }];
      }
    }
  }

  let listOfPoints: any = [];
  for (const point of points) {
    listOfPoints = [...listOfPoints, {
      value: point.id,
      label: `№${point.no}, ${point.name}, (${point?.latitude}° ш. ${point?.longitude}° д.)`
    }];
  }

  const period = useAppSelector(selectOrganizationModulePeriod);


  let listOfP: any = [];
  for (const point of points) {
    if (point.id === selectedPoint) {
      for (const pollutant of (point.pollutants || [])) {
        listOfP = [...listOfP, (
          <>
            <Form.Item label={`${getLabelByCode(pollutant.code)} - Фактическая концентрация мг/м3`} name={`${pollutant.id}`} key={`${pollutant.id}`} rules={[{required:true, message: "Заполните поле" }]}>
              {
                React.cloneElement(
                  <InputNumber style={{ width: '100%' }} />
                )
              }
            </Form.Item>
          </>
        )];
      }
    }
  }

  const onFinish = (values: any) => {
    for(const pollutant of pollutantsByPoint) {
      const dto: CreateAirSzzJournalDto = {
        pollutant: pollutant.id,
        actualConcentration: values[pollutant.id],
        year: values.year,
        quarter: values.quarter,
        isActive: true,
        organizationId: organizationId
      }
      onCreateAirSzzJournal(dto)
    }
  }


  return (
    <Modal width="60%" open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form initialValues={{year : period.year, quarter : period.quarter}} form={form} className="p-8" layout="vertical" onFinish={onFinish}>
        <Form.Item label={"Точка"} name={"point"} key={"point"}>
          {
            React.cloneElement(
              <Select
                options={listOfPoints}
                showSearch
                filterOption={(input, option) => {
                  if (option) {
                    const str = option.label + '';
                    return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  } else {
                    return true
                  }
                }}
                onSelect={(selectedPoint) => setPoint(selectedPoint)}
              />
            )
          }
        </Form.Item>
        {listOfP}
        <Form.Item label={"Год"} name={"year"} key={"year"} rules={[{ required: false, message: "Заполните поле" }]}>
          {
            React.cloneElement(
              <Input style={{ width: '100%' }} disabled={true} />
            )
          }
        </Form.Item>
        <Form.Item label={"Квартал"} name={"quarter"} key={"quarter"} rules={[{ required: false, message: "Заполните поле" }]}>
          {
            React.cloneElement(
              <Input style={{ width: '100%' }} disabled={true} />
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};
