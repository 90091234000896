import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateTaxDropDto, DropCode, TaxDrop, UpdateTaxDropDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchDropCodesAsync } from "../dropCodes/DropCodesSlice";
import { TaxDropForm } from "./components/TaxDropForm";
import { TaxDropsColumns } from "./form/columns";
import { selectTaxDrops, updateTaxDropAsync, createTaxDropAsync, deleteTaxDropAsync, fetchTaxDropsAsync } from "./TaxDropsSlice";

export const TaxDropsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const taxDrops = useAppSelector(selectTaxDrops);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchTaxDropsAsync());
    dispatch(fetchDropCodesAsync());
  }, [dispatch]);

  const onUpdateTaxDrop = async (taxDrop: UpdateTaxDropDto, id: string) => {
    return dispatch(updateTaxDropAsync({ taxDrop, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateTaxDrop = (taxDrop: CreateTaxDropDto) => {

    dispatch(createTaxDropAsync({ ...taxDrop })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteTaxDrop = (taxDrop: TaxDrop) => {
    return dispatch(deleteTaxDropAsync({ taxDrop })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <TaxDropForm visible={visible} setVisible={setVisible} onCreateTaxDrop={onCreateTaxDrop} />}
      <EditableTable<TaxDrop, UpdateTaxDropDto>
        onSave={onUpdateTaxDrop}
        onDelete={deleteTaxDrop}
        dataSource={structuredClone(taxDrops).map((data: any) => {
          const dropCodes = data?.dropCodes?.map((dropCode: DropCode) => dropCode?.id);
          delete data?.dropCodes;
          data.dropCodes = dropCodes;
          return data;
        }) as any}
        entityColumns={TaxDropsColumns()}
        footer={() => addButton}
      />
    </Layout>
  );
};
