import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createRopCalculationAsync, deleteRopCalculationAsync, fetchRopCalculationsByQuarterAsync, selectRopCalculations, updateRopCalculationAsync } from "./RopCalculationsSlice";
import { RopCalculationsColumns } from "./form/columns";
import { RopCalculationColumns } from "./form/rop-calculation-columns";
import { RopCalculation } from "../../api/models/rop-calculation";
import { UpdateRopCalculationDto } from "../../api/models/update-rop-calculation-dto";
import { CreateRopCalculationDto } from "../../api/models/create-rop-calculation-dto";
import { Button, Layout} from "antd";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { BASE_PATH } from "../../api/base";
import { RopCalculationsForm } from "./components/RopCalculationsForm";
import { fetchRopGoodClassifiersAsync } from "./RopGoodClassifierSlice";
import { fetchRopPackagingClassifiersAsync } from "./RopPackagingClassifierSlice";
import { FolderOutlined } from '@ant-design/icons';
import { fetchFilesByIdsAsync } from "../files/FilesEntitySlice";
import { FileForm } from "../files/components/FileForm";

interface RopCalculationsComponentProps {
    organizationId?: string,
    year: number;
    quarter: number;
}

export const RopCalculationsComponent: React.FC<RopCalculationsComponentProps> = ({ organizationId, year, quarter }) => {
    const dispatch = useAppDispatch();
    const calculations: RopCalculation[] = useAppSelector(selectRopCalculations);
    const calculationsColumns: RopCalculationColumns[] = calculations.map(calculation =>
    (
        {
            id: calculation.id,
            no: calculation.no,
            declarationNumber: calculation.declarationNumber,
            bin: calculation.organization.bin,
            organizationName: calculation.organization.name,
            codeTNVEDEAEU: calculation.codeTNVEDEAEU,
            goodsClassifierId: calculation.goodsClassifier.id,
            goodsClassifierName: calculation.goodsClassifier.name,
            goodsClassifierCoefficient: calculation.goodsClassifier.coefficient,
            packageClassifierName: calculation.packagingClassifier.name,
            packageClassifierId: calculation.packagingClassifier.id,
            packagingClassifierCoefficient: calculation.packagingClassifier.coefficient,
            goodsNetWeight: calculation.goodsNetWeight,
            packageNetWeight: calculation.packageNetWeight,
            mciRate: calculation.mciRate,
            amount: calculation.amount,
            year: calculation.year,
            quarter: calculation.quarter,
            fileIds: calculation.fileIds
        }))

    const [visibleForm, setFormVisible] = useState(false);

    useEffect(() => {
        dispatch(fetchRopGoodClassifiersAsync());
        dispatch(fetchRopPackagingClassifiersAsync());
        if (organizationId) {
            if (year && quarter) {
                dispatch(fetchRopCalculationsByQuarterAsync({ organizationId, year, quarter }));
            } else {
                dispatch(fetchRopCalculationsByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
            }
        }
    }, [year, quarter, organizationId, dispatch]);

    const onUpdate = async (dto: UpdateRopCalculationDto, id: string) => {
        const ropCalculation = dto
        return dispatch(updateRopCalculationAsync({ ropCalculation, id })).then((res: any) => {
            if (!res.error) {
                if (organizationId) {
                    if (year && quarter) {
                        dispatch(fetchRopCalculationsByQuarterAsync({ organizationId, year, quarter }));
                    } else {
                        dispatch(fetchRopCalculationsByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
                    }
                }
                return true;
            }
            return false;
        });
    };

    const onCreateRopCalculation = (ropCalculation: CreateRopCalculationDto) => {
        if (!organizationId || !year || !quarter) return;

        dispatch(createRopCalculationAsync({ ...ropCalculation, organizationId, year, quarter })).then((res: any) => {
            if (!res.error) {
                setFormVisible(false);
            }
        });
    };

    const deleteRopCalculation = (id: string) => {
        return dispatch(deleteRopCalculationAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }

    const addButton = (
        <Button onClick={() => setFormVisible(true)} block type="dashed" className="">
            + Добавить запись
        </Button>)

    const [visible, setVisible] = useState(false);
    const [ropCalculation, setCurrentRopCalculation] = useState<RopCalculationColumns>();

    const showFileModal = async (calculation: RopCalculationColumns) => {
        // Fetch files for the current calculation
        if (calculation.fileIds?.length > 0) {
            await dispatch(fetchFilesByIdsAsync({ ids: calculation.fileIds?.join(',') }));
        }
        setCurrentRopCalculation(calculation);
        setVisible(true);
    };
    return (
        <Layout className="bg-white p-4 rounded-lg">
            {visibleForm && <RopCalculationsForm visible={visibleForm} setVisible={setFormVisible} onCreateRopCalculations={onCreateRopCalculation} organizationId={organizationId} />}
            <div className="table-responsive">
                <EditableTable<RopCalculationColumns, UpdateRopCalculationDto>
                    onSave={(value, id) => onUpdate(value, id)}
                    onDelete={(calculation) => deleteRopCalculation(calculation.id)}
                    entityColumns={[
                        {
                            title: "Файлы",
                            dataIndex: "btnc" as keyof RopCalculationColumns,
                            key: "btnc",
                            inputNode: <></>,
                            render: (value: any, record: RopCalculationColumns, index: any) => (
                                <Button.Group>
                                    <Button icon={<FolderOutlined />} onClick={() => showFileModal(record)}></Button>
                                </Button.Group>
                            )
                            ,
                            editable: false,
                            copyable: false,
                        },
                        ...RopCalculationsColumns()
                    ]}
                    dataSource={calculationsColumns}
                    footer={() => addButton}
                />
            </div>
            <Button href={`${BASE_PATH}/rop-calculations/report/organization/${organizationId}/year/${year}/quarter/${quarter}`} block type="primary" ghost>
                Получить отчёт
            </Button>
            {visible && <FileForm visible={visible} setVisible={setVisible} onUpdate={onUpdate} fileIds={ropCalculation?.fileIds} id={ropCalculation?.id}/>}
        </Layout>
    );

}