/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CalculationInput } from './calculation-input';
import { CalculationResult } from './calculation-result';
import { Organization } from './organization';
import { Permission } from './permission';
/**
 * 
 * @export
 * @interface Calculation
 */
export interface Calculation {
    /**
     * 
     * @type {string}
     * @memberof Calculation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Calculation
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Calculation
     */
    quarter: number;
    /**
     * 
     * @type {number}
     * @memberof Calculation
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof Calculation
     */
    status: CalculationStatusEnum;
    /**
     * 
     * @type {Array<CalculationInput>}
     * @memberof Calculation
     */
    calculationInputs: Array<CalculationInput>;
    /**
     * 
     * @type {Array<CalculationResult>}
     * @memberof Calculation
     */
    calculationResults: Array<CalculationResult>;
    /**
     * 
     * @type {Organization}
     * @memberof Calculation
     */
    organization: Organization;
    /**
     * 
     * @type {Permission}
     * @memberof Calculation
     */
    permission: Permission | null;
    /**
     * 
     * @type {string}
     * @memberof Calculation
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof Calculation
     */
    isActive: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Calculation
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Calculation
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Calculation
     */
    deletedAt: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum CalculationStatusEnum {
    Created = 'created',
    Calculated = 'calculated',
    Error = 'error'
}

