import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteAccumulationLimitDto, WasteAccumulationLimit, UpdateWasteAccumulationLimitDto, Organization } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchWasteAccumulationSitesByOrganizationAsync } from "../wasteAccumulationSites/WasteAccumulationSitesSlice";
import { fetchWasteRegistersByYearAsync } from "../wasteRegister/WasteRegisterSlice";
import { WasteAccumulationLimitForm } from "./components/WasteAccumulationLimitForm";
import { WasteAccumulationLimitsColumns } from "./form/columns";
import { createWasteAccumulationLimitAsync, updateWasteAccumulationLimitAsync, selectWasteAccumulationLimits, deleteWasteAccumulationLimitAsync, fetchWasteAccumulationLimitsByYearAsync } from "./WasteAccumulationLimitsSlice";

interface WasteAccumulationLimitsComponentProps {
  organization?: Organization;
  year?: number;
}

export const WasteAccumulationLimitsComponent: React.FC<WasteAccumulationLimitsComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const wasteAccumulationLimits = useAppSelector(selectWasteAccumulationLimits);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWasteAccumulationLimitsByYearAsync({ organizationId: organization.id, year }));
      dispatch(fetchWasteAccumulationSitesByOrganizationAsync(organization.id));
      dispatch(fetchWasteRegistersByYearAsync({ organizationId: organization.id, year }));
    }
  }, [year, organization, dispatch]);

  const onUpdate = async (wasteAccumulationLimit: UpdateWasteAccumulationLimitDto, id: string) => {
    return dispatch(updateWasteAccumulationLimitAsync({ wasteAccumulationLimit, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteAccumulationLimit = (wasteAccumulationLimit: CreateWasteAccumulationLimitDto) => {
    if (!year || !organization) return;

    dispatch(createWasteAccumulationLimitAsync({ ...wasteAccumulationLimit, year, organizationId: organization.id })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteAccumulationLimits = (wasteAccumulationLimit: WasteAccumulationLimit) => {
    return dispatch(deleteWasteAccumulationLimitAsync({ wasteAccumulationLimit })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить лимит
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteAccumulationLimitForm visible={visible} setVisible={setVisible} onCreateWasteAccumulationLimit={onCreateWasteAccumulationLimit} />}
      <EditableTable<WasteAccumulationLimit, UpdateWasteAccumulationLimitDto>
        onSave={onUpdate}
        onDelete={deleteWasteAccumulationLimits}
        entityColumns={WasteAccumulationLimitsColumns()}
        dataSource={structuredClone(wasteAccumulationLimits).map((data: any) => {
          const permission = data?.permission?.id;
          delete data?.permission;
          data.permission = permission;

          const industrialSite = data?.industrialSite?.id;
          delete data?.industrialSite;
          data.industrialSite = industrialSite;

          const wasteAccumulationSite = data?.wasteAccumulationSite?.id;
          delete data?.wasteAccumulationSite;
          data.wasteAccumulationSite = wasteAccumulationSite;

          const wasteRegister = data?.wasteRegister?.id;
          delete data?.wasteRegister;
          data.wasteRegister = wasteRegister;
          return data;
        }) as any}

        footer={() => addButton}
      />
    </Layout>
  );
};
