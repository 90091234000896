import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateCarbonIndirectEmissionDto, CarbonIndirectEmissionApi, UpdateCarbonIndirectEmissionDto, CarbonIndirectEmission } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface CarbonIndirectEmissionState {
  carbonIndirectEmission: CarbonIndirectEmission[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CarbonIndirectEmissionState = {
  carbonIndirectEmission: [],
  status: "idle",
};

export const createCarbonIndirectEmissionAsync = createAsyncThunk(
  "carbonIndirectEmission/create",
  async (carbonIndirectEmission: CreateCarbonIndirectEmissionDto, { rejectWithValue }) => {
    try {
      const carbonIndirectEmissionApi = new CarbonIndirectEmissionApi();
      const response = await carbonIndirectEmissionApi.carbonIndirectEmissionControllerCreate(carbonIndirectEmission);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCarbonIndirectEmissionAsync = createAsyncThunk(
  "carbonIndirectEmission/update",
  async ({ carbonIndirectEmission, id }: { carbonIndirectEmission: UpdateCarbonIndirectEmissionDto; id: string }, { rejectWithValue }) => {
    try {
      const carbonIndirectEmissionApi = new CarbonIndirectEmissionApi();
      const response = await carbonIndirectEmissionApi.carbonIndirectEmissionControllerUpdate(carbonIndirectEmission, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCarbonIndirectEmissionAsync = createAsyncThunk(
  "carbonIndirectEmission/remove",
  async ({ carbonIndirectEmission }: { carbonIndirectEmission: CarbonIndirectEmission }, { rejectWithValue }) => {
    try {
      const carbonIndirectEmissionApi = new CarbonIndirectEmissionApi();
      const response = await carbonIndirectEmissionApi.carbonIndirectEmissionControllerRemove(carbonIndirectEmission.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCarbonIndirectEmissionByOrganizationIdAsync = createAsyncThunk("carbonIndirectEmission/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const carbonIndirectEmissionApi = new CarbonIndirectEmissionApi();
    const response = await carbonIndirectEmissionApi.carbonIndirectEmissionControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchCarbonIndirectEmissionByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchCarbonIndirectEmissionByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: CarbonIndirectEmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<CarbonIndirectEmission[]>) => {
        state.carbonIndirectEmission = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCarbonIndirectEmission = () => {
    const { pending, fulfilled, rejected } = createCarbonIndirectEmissionAsync;
    return {
      [`${pending}`]: (state: CarbonIndirectEmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<CarbonIndirectEmission>) => {
        state.carbonIndirectEmission.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCarbonIndirectEmission = () => {
    const { pending, fulfilled, rejected } = updateCarbonIndirectEmissionAsync;

    return {
      [`${pending}`]: (state: CarbonIndirectEmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<CarbonIndirectEmission>) => {
        state.carbonIndirectEmission = state.carbonIndirectEmission.map((carbonIndirectEmission) => (carbonIndirectEmission.id === action.payload.id ? action.payload : carbonIndirectEmission));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeCarbonIndirectEmission = () => {
    const { pending, fulfilled, rejected } = deleteCarbonIndirectEmissionAsync;
    return {
      [`${pending}`]: (state: CarbonIndirectEmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<CarbonIndirectEmission>) => {
        state.carbonIndirectEmission.splice(state.carbonIndirectEmission.indexOf(
          state.carbonIndirectEmission.find((ws: CarbonIndirectEmission) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonIndirectEmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCarbonIndirectEmissionByOrganization(), ...createCarbonIndirectEmission(), ...updateCarbonIndirectEmission(), ...removeCarbonIndirectEmission() };
};

export const carbonIndirectEmissionSlice = createSlice({
  name: "carbonIndirectEmission",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectCarbonIndirectEmission = (state: RootState) => state.carbonIndirectEmission.carbonIndirectEmission;

export default carbonIndirectEmissionSlice.reducer;
