import { Button, Layout, Table } from "antd";
import { BASE_PATH } from "../../../api/base";

export const CarbonCoefficients: React.FC = () => {
  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }
  return (
    <Layout className="bg-white p-4 rounded-lg">

      <Table scroll={{ x: "max-content" }}
        columns={[
          {
            title: 'Вид установки',
            dataIndex: 'a',
            key: 'a',
          },
          {
            title: 'Коэффициент пересчета в энерг.ед., ТДж/т',
            dataIndex: 'b',
            key: 'b',
          },
          {
            title: 'Коэффициент выброса СО2, тСО2/ТДж',
            dataIndex: 'c',
            key: 'c',
          },
          {
            title: 'Коэффициент выброса СН4, тСН4/ТДж',
            dataIndex: 'd',
            key: 'd',
          },
          {
            title: 'Коэффициент выброса N2O, тN2O/ТДж',
            dataIndex: 'e',
            key: 'e',
          },
          {
            title: 'Потенциал глобального потепления СН4',
            dataIndex: 'f',
            key: 'f',
          },
          {
            title: 'УПотенциал глобального потепления N2O',
            dataIndex: 'g',
            key: 'g',
          }
        ]}
        dataSource={
          [
            {
              a: 'ДЭС (ДТ)',
              b: '0.043',
              c: '74.1',
              d: '0.003',
              e: '0.0003',
              f: '25',
              g: '298',
            },
            {
              a: 'Котлы и печи (уголь',
              b: '0.01964',
              c: '93.8',
              d: '0.001',
              e: '0.0007',
              f: '25',
              g: '298',
            },
            {
              a: 'Транспорт (ДТ)',
              b: '0.0425',
              c: '74.1',
              d: '0.0039',
              e: '0.0039',
              f: '25',
              g: '298',
            },
            {
              a: 'Транспорт (бензин)',
              b: '0.0437',
              c: '69.3',
              d: '0.025',
              e: '0.008',
              f: '25',
              g: '298',
            }
          ]
        }
      />
    <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/carbon-stationary-source/coefficient-report`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
    </Layout>
  );
};
