import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteRegisterDto, WasteRegistersApi, UpdateWasteRegisterDto, WasteRegister } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteRegistersState {
  wasteRegisters: WasteRegister[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteRegistersState = {
  wasteRegisters: [],
  status: "idle",
};

export const createWasteRegisterAsync = createAsyncThunk(
  "wasteRegisters/create",
  async (wasteRegister: CreateWasteRegisterDto, { rejectWithValue }) => {
    try {
      const wasteRegistersApi = new WasteRegistersApi();
      const response = await wasteRegistersApi.wasteRegistersControllerCreate(wasteRegister);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteRegisterAsync = createAsyncThunk(
  "wasteRegisters/update",
  async ({ wasteRegister, id }: { wasteRegister: UpdateWasteRegisterDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteRegistersApi = new WasteRegistersApi();
      const response = await wasteRegistersApi.wasteRegistersControllerUpdate(wasteRegister, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteRegisterAsync = createAsyncThunk(
  "wasteRegisters/remove",
  async ({ wasteRegister }: { wasteRegister: WasteRegister }, { rejectWithValue }) => {
    try {
      const wasteRegistersApi = new WasteRegistersApi();
      const response = await wasteRegistersApi.wasteRegistersControllerRemove(wasteRegister.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteRegistersByYearAsync = createAsyncThunk("wasteRegisters/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteRegistersApi();
    const response = await wasteApi.wasteRegistersControllerFindByYear(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteRegistersByYear = () => {
    const { pending, fulfilled, rejected } = fetchWasteRegistersByYearAsync;
    return {
      [`${pending}`]: (state: WasteRegistersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteRegistersState, action: PayloadAction<WasteRegister[]>) => {
        state.wasteRegisters = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteRegistersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteRegister = () => {
    const { pending, fulfilled, rejected } = createWasteRegisterAsync;
    return {
      [`${pending}`]: (state: WasteRegistersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteRegistersState, action: PayloadAction<WasteRegister>) => {
        state.wasteRegisters.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteRegistersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteRegister = () => {
    const { pending, fulfilled, rejected } = updateWasteRegisterAsync;

    return {
      [`${pending}`]: (state: WasteRegistersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteRegistersState, action: PayloadAction<WasteRegister>) => {
        state.wasteRegisters = state.wasteRegisters.map((wasteRegister) => (wasteRegister.id === action.payload.id ? action.payload : wasteRegister));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteRegistersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteRegister = () => {
    const { pending, fulfilled, rejected } = deleteWasteRegisterAsync;
    return {
      [`${pending}`]: (state: WasteRegistersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteRegistersState, action: PayloadAction<WasteRegister>) => {
        state.wasteRegisters.splice(state.wasteRegisters.indexOf(
          state.wasteRegisters.find((ws: WasteRegister) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteRegistersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteRegistersByYear(), ...createWasteRegister(), ...updateWasteRegister(), ...removeWasteRegister() };
};

export const wasteRegistersSlice = createSlice({
  name: "wasteRegisters",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteRegisters = (state: RootState) => state.wasteRegisters.wasteRegisters;

export default wasteRegistersSlice.reducer;
