import { Button, Layout } from "antd";
import { BASE_PATH } from "../../api/base";

interface ReportStaticComponentProps {
  organizationId?: string;
  year?: number;
}

export const ReportStaticComponent: React.FC<ReportStaticComponentProps> = ({ organizationId, year }) => {

  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Button.Group className="mb-5"><Button onClick={() => downloadFile(`${BASE_PATH}/report-static/report-water-consumption/organization/${organizationId}/year/${year}`)} block type="primary" ghost>Скачать форму "Водопотребления"</Button></Button.Group>
      <Button.Group className="mb-5"><Button onClick={() => downloadFile(`${BASE_PATH}/report-static/report-water-drainage/organization/${organizationId}/year/${year}`)} block type="primary" ghost>Скачать форму "Водоотведения"</Button></Button.Group>
      <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/report-static/report-air/organization/${organizationId}/year/${year}`)} block type="primary" ghost>Скачать форму "2ТП воздух"</Button></Button.Group>
    </Layout>
  );
};
