import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteEquipmentSortDto, WasteEquipmentSort, UpdateWasteEquipmentSortDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WasteEquipmentSortForm } from "./components/WasteEquipmentSortsForm";
import { wasteEquipmentSortsColumns } from "./form/columns";
import { createWasteEquipmentSortAsync, updateWasteEquipmentSortAsync, fetchWasteEquipmentSortsByOrganizationAsync, selectWasteEquipmentSorts, deleteWasteEquipmentSortAsync } from "./WasteEquipmentSortsSlice";

interface WasteEquipmentSortsComponentProps {
  organizationId?: string;
}

export const WasteEquipmentSortsComponent: React.FC<WasteEquipmentSortsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const wasteEquipmentSorts = useAppSelector(selectWasteEquipmentSorts);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWasteEquipmentSortsByOrganizationAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdate = async (wasteEquipmentSort: UpdateWasteEquipmentSortDto, id: string) => {
    return dispatch(updateWasteEquipmentSortAsync({ wasteEquipmentSort, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteEquipmentSort = (wasteEquipmentSort: CreateWasteEquipmentSortDto) => {
    if (!organizationId) return;

    dispatch(createWasteEquipmentSortAsync({ ...wasteEquipmentSort, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteEquipmentSorts = (wasteEquipmentSort: WasteEquipmentSort) => {
    return dispatch(deleteWasteEquipmentSortAsync({ wasteEquipmentSort })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить оборудование
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteEquipmentSortForm visible={visible} setVisible={setVisible} onCreateWasteEquipmentSort={onCreateWasteEquipmentSort} />}
      <EditableTable<WasteEquipmentSort, UpdateWasteEquipmentSortDto>
        onSave={onUpdate}
        onDelete={deleteWasteEquipmentSorts}
        entityColumns={wasteEquipmentSortsColumns}
        dataSource={wasteEquipmentSorts}
        footer={() => addButton}
      />
    </Layout>
  );
};
