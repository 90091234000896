import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteBurialLimitDto, WasteBurialLimit, UpdateWasteBurialLimitDto, Organization } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchWasteBurialSitesByOrganizationAsync } from "../wasteBurialSites/WasteBurialSitesSlice";
import { fetchWasteRegistersByYearAsync } from "../wasteRegister/WasteRegisterSlice";
import { WasteBurialLimitForm } from "./components/WasteBurialLimitForm";
import { WasteBurialLimitsColumns } from "./form/columns";
import { createWasteBurialLimitAsync, updateWasteBurialLimitAsync, selectWasteBurialLimits, deleteWasteBurialLimitAsync, fetchWasteBurialLimitsByYearAsync } from "./WasteBurialLimitsSlice";

interface WasteBurialLimitsComponentProps {
  organization?: Organization;
  year?: number;
}

export const WasteBurialLimitsComponent: React.FC<WasteBurialLimitsComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const wasteBurialLimits = useAppSelector(selectWasteBurialLimits);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWasteBurialLimitsByYearAsync({ organizationId: organization.id, year }));
      dispatch(fetchWasteBurialSitesByOrganizationAsync(organization.id));
      dispatch(fetchWasteRegistersByYearAsync({ organizationId: organization.id, year }));
    }
  }, [year, organization, dispatch]);

  const onUpdate = async (wasteBurialLimit: UpdateWasteBurialLimitDto, id: string) => {
    return dispatch(updateWasteBurialLimitAsync({ wasteBurialLimit, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteBurialLimit = (wasteBurialLimit: CreateWasteBurialLimitDto) => {
    if (!year || !organization) return;

    dispatch(createWasteBurialLimitAsync({ ...wasteBurialLimit, year, organizationId: organization.id })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteBurialLimits = (wasteBurialLimit: WasteBurialLimit) => {
    return dispatch(deleteWasteBurialLimitAsync({ wasteBurialLimit })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить лимит
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteBurialLimitForm visible={visible} setVisible={setVisible} onCreateWasteBurialLimit={onCreateWasteBurialLimit} />}
      <EditableTable<WasteBurialLimit, UpdateWasteBurialLimitDto>
        onSave={onUpdate}
        onDelete={deleteWasteBurialLimits}
        entityColumns={WasteBurialLimitsColumns()}
        dataSource={structuredClone(wasteBurialLimits).map((data: any) => {
          const permission = data?.permission?.id;
          delete data?.permission;
          data.permission = permission;

          const industrialSite = data?.industrialSite?.id;
          delete data?.industrialSite;
          data.industrialSite = industrialSite;

          const wasteBurialSite = data?.wasteBurialSite?.id;
          delete data?.wasteBurialSite;
          data.wasteBurialSite = wasteBurialSite;

          const wasteRegister = data?.wasteRegister?.id;
          delete data?.wasteRegister;
          data.wasteRegister = wasteRegister;
          return data;
        }) as any}

        footer={() => addButton}
      />
    </Layout>
  );
};
