import { Layout, Table } from "antd";
import { useEffect } from "react";
import { Organization } from "../../../../api";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchAnalyticsProductionIndicatorControllerFindByOrganizationIdYearQuarterAsync, selectAnalyticsProductionIndicators } from "../AnalyticsProductionIndicatorSlice";

interface AnalyticsProductionIndicatorProps {
  organization?: Organization;
  year?: number;
}

export const AnalyticsProductionIndicator: React.FC<AnalyticsProductionIndicatorProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const analyticsProductionIndicators = useAppSelector(selectAnalyticsProductionIndicators);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchAnalyticsProductionIndicatorControllerFindByOrganizationIdYearQuarterAsync({ organizationId: organization.id, year }));
    }
  }, [organization, year, dispatch]);

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Table scroll={{ x: "max-content" }}
        columns={[
          {
            title: 'Производственный показатель для расчета KPI (ежемесячный учет)',
            dataIndex: 'index',
            key: 'index',
          },
          {
            title: 'ед изм',
            dataIndex: 'unit',
            key: 'unit',
          },
          {
            title: '1 квартал',
            dataIndex: 'q1',
            key: 'q1',
          },
          {
            title: '2 квартал',
            dataIndex: 'q2',
            key: 'q2',
          },
          {
            title: '3 квартал',
            dataIndex: 'q3',
            key: 'q3',
          },
          {
            title: '4 квартал',
            dataIndex: 'q4',
            key: 'q4',
          },
          {
            title: 'Итого',
            dataIndex: 'total',
            key: 'total',
          },
        ]}
        dataSource={
          [
            {
              index: 'Объем выбросов',
              unit: 'тонн',
              q1: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.emissions,
              q2: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.emissions,
              q3: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.emissions,
              q4: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.emissions,
              total: (analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.emissions
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.emissions
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.emissions
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.emissions || 0),
            },
            {
              index: 'Объем вскрыши',
              unit: 'тонн',
              q1: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.overburdenVolume,
              q2: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.overburdenVolume,
              q3: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.overburdenVolume,
              q4: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.overburdenVolume,
              total: (analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.overburdenVolume
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.overburdenVolume
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.overburdenVolume
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.overburdenVolume || 0),
            },
            {
              index: 'Объем хвостов обогащения',
              unit: 'тонн',
              q1: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.tailVolume,
              q2: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.tailVolume,
              q3: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.tailVolume,
              q4: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.tailVolume,
              total: (analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.tailVolume
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.tailVolume
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.tailVolume
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.tailVolume || 0),
            },
            {
              index: 'Выбросы ПГ',
              unit: 'тСО2е',
              q1: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.carbonTotal,
              q2: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.carbonTotal,
              q3: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.carbonTotal,
              q4: analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.carbonTotal,
              total: (analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.carbonTotal
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.carbonTotal
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.carbonTotal
                + analyticsProductionIndicators?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.carbonTotal || 0),
            },
          ]
        }
      />
    </Layout>
  );
};
