import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreatePollutantCodeDto, PollutantCodesApi, UpdatePollutantCodeDto, PollutantCode } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

//TODO Drop PollutantCodes

export interface PollutantCodesState {
  pollutantCodes: PollutantCode[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: PollutantCodesState = {
  pollutantCodes: [],
  status: "idle",
};

export const createPollutantCodeAsync = createAsyncThunk(
  "pollutantCodes/create",
  async (pollutantCode: CreatePollutantCodeDto, { rejectWithValue }) => {
    try {
      const pollutantCodesApi = new PollutantCodesApi();
      const response = await pollutantCodesApi.pollutantCodesControllerCreate(pollutantCode);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePollutantCodeAsync = createAsyncThunk(
  "pollutantCodes/update",
  async ({ pollutantCode, id }: { pollutantCode: UpdatePollutantCodeDto; id: string }, { rejectWithValue }) => {
    try {
      const pollutantCodesApi = new PollutantCodesApi();
      const response = await pollutantCodesApi.pollutantCodesControllerUpdate(pollutantCode, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePollutantCodeAsync = createAsyncThunk(
  "pollutantCodes/remove",
  async ({ pollutantCode }: { pollutantCode: PollutantCode }, { rejectWithValue }) => {
    try {
      const pollutantCodesApi = new PollutantCodesApi();
      const response = await pollutantCodesApi.pollutantCodesControllerRemove(pollutantCode.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPollutantCodesAsync = createAsyncThunk(
  "pollutantCodes/fetch", async (_, { rejectWithValue }) => {
  try {
    const pollutantCodesApi = new PollutantCodesApi();
    const response = await pollutantCodesApi.pollutantCodesControllerFindAll();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchPollutantCodes = () => {
    const { pending, fulfilled, rejected } = fetchPollutantCodesAsync;
    return {
      [`${pending}`]: (state: PollutantCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutantCodesState, action: PayloadAction<PollutantCode[]>) => {
        state.pollutantCodes = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutantCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createPollutantCode = () => {
    const { pending, fulfilled, rejected } = createPollutantCodeAsync;
    return {
      [`${pending}`]: (state: PollutantCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutantCodesState, action: PayloadAction<PollutantCode>) => {
        state.pollutantCodes.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutantCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updatePollutantCode = () => {
    const { pending, fulfilled, rejected } = updatePollutantCodeAsync;

    return {
      [`${pending}`]: (state: PollutantCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutantCodesState, action: PayloadAction<PollutantCode>) => {
        state.pollutantCodes = state.pollutantCodes.map((PollutantCode) => (PollutantCode.id === action.payload.id ? action.payload : PollutantCode));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutantCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removePollutantCode = () => {
    const { pending, fulfilled, rejected } = deletePollutantCodeAsync;
    return {
      [`${pending}`]: (state: PollutantCodesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: PollutantCodesState, action: PayloadAction<PollutantCode>) => {
        state.pollutantCodes.splice(state.pollutantCodes.indexOf(
          state.pollutantCodes.find((ws: PollutantCode) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: PollutantCodesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchPollutantCodes(), ...createPollutantCode(), ...updatePollutantCode(), ...removePollutantCode() };
};

export const pollutantCodesSlice = createSlice({
  name: "pollutantCodes",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectPollutantCodes = (state: RootState) => state.pollutantCodes.pollutantCodes;

export default pollutantCodesSlice.reducer;
