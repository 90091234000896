import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateIndustrialSiteDto, IndustrialSitesApi, UpdateIndustrialSiteDto } from "../../api";
import { ApiError } from "../../app/types";

export interface IndustrialSitesState {
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: IndustrialSitesState = {
  status: "idle",
};

export const createIndustrialSiteAsync = createAsyncThunk(
  "industrialSites/create",
  async (industrialSite: CreateIndustrialSiteDto, { rejectWithValue }) => {
    try {
      const industrialSitesApi = new IndustrialSitesApi();
      const response = await industrialSitesApi.industrialSitesControllerCreate(industrialSite);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateIndustrialSiteAsync = createAsyncThunk(
  "industrialSites/update",
  async ({ industrialSite, id }: { industrialSite: UpdateIndustrialSiteDto; id: string }, { rejectWithValue }) => {
    try {
      const industrialSitesApi = new IndustrialSitesApi();
      const response = await industrialSitesApi.industrialSitesControllerUpdate(industrialSite, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteIndustrialSiteAsync = createAsyncThunk("industrialSites/delete", async (id: string, { rejectWithValue }) => {
  try {
    const industrialSitesApi = new IndustrialSitesApi();
    const response = await industrialSitesApi.industrialSitesControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const industrialSitesSlice = createSlice({
  name: "industrialSites",
  initialState,
  reducers: {},
});

export default industrialSitesSlice.reducer;
