import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteJournalDto, WasteJournalApi, UpdateWasteJournalDto, WasteJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { WasteJournalLimitsInfo } from "../../api/models/waste-journal-limits-info";

export interface WasteJournalsState {
  wasteJournals: WasteJournal[];
  wasteJournalInfos: WasteJournalLimitsInfo[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteJournalsState = {
  wasteJournals: [],
  wasteJournalInfos: [],
  status: "idle",
};

export const createWasteJournalAsync = createAsyncThunk(
  "wasteJournals/create",
  async (wasteJournal: CreateWasteJournalDto, { rejectWithValue }) => {
    try {
      const wasteJournalsApi = new WasteJournalApi();
      const response = await wasteJournalsApi.wasteJournalControllerCreate(wasteJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteJournalAsync = createAsyncThunk(
  "wasteJournals/update",
  async ({ wasteJournal, id }: { wasteJournal: UpdateWasteJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteJournalsApi = new WasteJournalApi();
      const response = await wasteJournalsApi.wasteJournalControllerUpdate(wasteJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteJournalAsync = createAsyncThunk(
  "wasteJournals/remove",
  async ({ wasteJournal }: { wasteJournal: WasteJournal }, { rejectWithValue }) => {
    try {
      const wasteJournalsApi = new WasteJournalApi();
      const response = await wasteJournalsApi.wasteJournalControllerRemove(wasteJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteJournalsByWasteOrganizationAsync = createAsyncThunk("wasteJournals/fetch", async ({ id, year, quarter }: { id: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteJournalApi();
    const response = await wasteApi.wasteJournalControllerFindByWasteOrganizationId(id, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchWasteJournalsLimitInfoByOrganizationAsync = createAsyncThunk("wasterJournalsLimitInfoByOrganizationId/fech", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, {rejectWithValue}) => {
  try {
    const wasteApi = new WasteJournalApi();
    const response = await wasteApi.wasteJournalControllerFindByWasteJournalLimitsInfoOrganizationId(organizationId, year, quarter);

    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteJournalsByWasteSite = () => {
    const { pending, fulfilled, rejected } = fetchWasteJournalsByWasteOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteJournalsState, action: PayloadAction<WasteJournal[]>) => {
        state.wasteJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchWasteJournalsInfoByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWasteJournalsLimitInfoByOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteJournalsState, action: PayloadAction<WasteJournalLimitsInfo[]>) => {
        state.wasteJournalInfos = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  }


  const createWasteJournal = () => {
    const { pending, fulfilled, rejected } = createWasteJournalAsync;
    return {
      [`${pending}`]: (state: WasteJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteJournalsState, action: PayloadAction<WasteJournal>) => {
        state.wasteJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteJournal = () => {
    const { pending, fulfilled, rejected } = updateWasteJournalAsync;

    return {
      [`${pending}`]: (state: WasteJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteJournalsState, action: PayloadAction<WasteJournal>) => {
        state.wasteJournals = state.wasteJournals.map((wasteJournal) => (wasteJournal.id === action.payload.id ? action.payload : wasteJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteJournal = () => {
    const { pending, fulfilled, rejected } = deleteWasteJournalAsync;
    return {
      [`${pending}`]: (state: WasteJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteJournalsState, action: PayloadAction<WasteJournal>) => {
        state.wasteJournals.splice(state.wasteJournals.indexOf(
          state.wasteJournals.find((ws: WasteJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteJournalsByWasteSite(), ...fetchWasteJournalsInfoByOrganization(), ...createWasteJournal(), ...updateWasteJournal(), ...removeWasteJournal()};
};

export const wasteJournalsSlice = createSlice({
  name: "wasteJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteJournals = (state: RootState) => state.wasteJournals.wasteJournals;

export const selectWasteJournalsInfo = (state: RootState) => state.wasteJournals.wasteJournalInfos;

export default wasteJournalsSlice.reducer;
