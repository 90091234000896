import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteJournalDto, WasteJournal, UpdateWasteJournalDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchWasteAccumulationLimitsByYearAsync } from "../wasteAccumulationLimits/WasteAccumulationLimitsSlice";
import { fetchWasteAccumulationSitesByOrganizationAsync } from "../wasteAccumulationSites/WasteAccumulationSitesSlice";
import { fetchWasteBurialLimitsByYearAsync } from "../wasteBurialLimits/WasteBurialLimitsSlice";
import { fetchWasteBurialSitesByOrganizationAsync } from "../wasteBurialSites/WasteBurialSitesSlice";
import { fetchWasteEquipmentRecyclesByOrganizationAsync } from "../wasteEquipmentRecycles/WasteEquipmentRecyclesSlice";
import { fetchWasteEquipmentSortsByOrganizationAsync } from "../wasteEquipmentSorts/WasteEquipmentSortsSlice";
import { fetchWasteRegistersByYearAsync } from "../wasteRegister/WasteRegisterSlice";
import { fetchWasteContragentsByOrganizationAsync } from "../wasteContragents/WasteContragentsSlice";
import { WasteJournalForm } from "./components/WasteJournalForm";
import { WasteJournalsColumns } from "./form/columns";
import { createWasteJournalAsync, updateWasteJournalAsync, selectWasteJournals, deleteWasteJournalAsync, fetchWasteJournalsByWasteOrganizationAsync } from "./WasteJournalsSlice";
import dayjs from "dayjs";
import { BASE_PATH } from "../../api/base";
import { fetchWorkshopsAsync } from "../workshops/workshopsSlice";

interface WasteJournalsComponentProps {
  organizationId?: string;
  quarter: number;
  year: number;
}

export const WasteJournalsComponent: React.FC<WasteJournalsComponentProps> = ({ organizationId, quarter, year }) => {
  const dispatch = useAppDispatch();
  const journals = useAppSelector(selectWasteJournals);

  const wasteJournals = journals.map((journal) => (
    {
    ...journal,
    industrialSite: `${journal?.workshop?.industrialSite?.id}` || undefined,
  }));

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWasteJournalsByWasteOrganizationAsync({ id: organizationId, year, quarter }));
      dispatch(fetchWasteRegistersByYearAsync({ organizationId, year: year }));
      dispatch(fetchWasteAccumulationSitesByOrganizationAsync(organizationId));
      dispatch(fetchWasteBurialSitesByOrganizationAsync(organizationId));
      dispatch(fetchWasteEquipmentRecyclesByOrganizationAsync(organizationId));
      dispatch(fetchWasteEquipmentSortsByOrganizationAsync(organizationId));
      dispatch(fetchWasteAccumulationLimitsByYearAsync({ organizationId, year: year }));
      dispatch(fetchWasteBurialLimitsByYearAsync({ organizationId, year: year }));
      dispatch(fetchWasteContragentsByOrganizationAsync(organizationId));
      dispatch(fetchWorkshopsAsync(organizationId));
    }
  }, [organizationId, year, quarter, dispatch]);

  const onUpdate = async (wasteJournal: UpdateWasteJournalDto, id: string) => {
    return dispatch(updateWasteJournalAsync({ wasteJournal, id })).then((res: any) => {
      if (!res.error) {
        if (organizationId) {
          dispatch(fetchWasteJournalsByWasteOrganizationAsync({ id: organizationId, year, quarter }));
        }
        return true;
      }
      return false;
    });
  };

  const onCreateWasteJournal = (wasteJournal: CreateWasteJournalDto) => {
    if (!organizationId) return;

    dispatch(createWasteJournalAsync({ ...wasteJournal, organizationId, quarter, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteJournals = (wasteJournal: WasteJournal) => {
    return dispatch(deleteWasteJournalAsync({ wasteJournal })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить запись в журнал
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteJournalForm visible={visible} setVisible={setVisible} onCreateWasteJournal={onCreateWasteJournal} />}
      <div className="table-responsive">
        <EditableTable<WasteJournal, UpdateWasteJournalDto>
          onSave={onUpdate}
          onDelete={deleteWasteJournals}
          entityColumns={WasteJournalsColumns()}
          dataSource={structuredClone(wasteJournals).map((journal: any) => {
            const workshop = journal.workshop?.id;
            delete journal.workshop;
            journal.workshop = workshop;

            const wasteAccumulationSite = journal.wasteAccumulationSite?.id;
            delete journal.wasteAccumulationSite;
            journal.wasteAccumulationSite = wasteAccumulationSite;

            const wasteAccumulationLimit = journal.wasteAccumulationLimit?.id;
            delete journal.wasteAccumulationLimit;
            journal.wasteAccumulationLimit = wasteAccumulationLimit;

            const wasteBurialSite = journal.wasteBurialSite?.id;
            delete journal.wasteBurialSite;
            journal.wasteBurialSite = wasteBurialSite;

            const wasteBurialLimit = journal.wasteBurialLimit?.id;
            delete journal.wasteBurialLimit;
            journal.wasteBurialLimit = wasteBurialLimit;

            const wasteRegister = journal.wasteRegister?.id;
            delete journal.wasteRegister;
            journal.wasteRegister = wasteRegister;

            const wasteEquipmentRecycle = journal.wasteEquipmentRecycle?.id;
            delete journal.wasteEquipmentRecycle;
            journal.wasteEquipmentRecycle = wasteEquipmentRecycle;

            const wasteEquipmentSort = journal.wasteEquipmentSort?.id;
            delete journal.wasteEquipmentSort;
            journal.wasteEquipmentSort = wasteEquipmentSort;

            const transferContragent = journal.transferContragent?.id;
            delete journal.transferContragent;
            journal.transferContragent = transferContragent;

            const senderContragent = journal.senderContragent?.id;
            delete journal.senderContragent;
            journal.senderContragent = senderContragent;

            if (journal.placementDate) {
              const placementDate = journal.placementDate;
              delete journal.placementDate;
              journal.placementDate = dayjs(placementDate);
            }

            if (journal.burialDate) {
              const burialDate = journal.burialDate;
              delete journal.burialDate;
              journal.burialDate = dayjs(burialDate);
            }

            if (journal.productionDate) {
              const productionDate = journal.productionDate;
              delete journal.productionDate;
              journal.productionDate = dayjs(productionDate);
            }

            if (journal.transferDate) {
              const transferDate = journal.transferDate;
              delete journal.transferDate;
              journal.transferDate = dayjs(transferDate);
            }

            return journal;
          }) as any}
          footer={() => addButton}
        />
      </div>
      <Button href={`${BASE_PATH}/waste-journal/report/organization/${organizationId}/year/${year}/quarter/${quarter}`} block type="primary" ghost>
        Получить отчёт за квартал
      </Button>
      <Button href={`${BASE_PATH}/waste-journal/report/organization/${organizationId}/year/${year}`} block type="primary" ghost>
        Получить отчёт за год
      </Button>
    </Layout>
  );
};
