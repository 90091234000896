import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { WaterConsumptionIntake } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { EntityColumns } from "../../../../app/types";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";
import { selectWaterConsumptionObjects } from "../../../waterConsumptionObject/WaterConsumptionObjectsSlice";

export const WaterConsumptionIntakeColumns = (): EntityColumns<WaterConsumptionIntake>[] => {
  const period = useAppSelector(selectOrganizationModulePeriod);
  const waterConsumptionObjects = useAppSelector(selectWaterConsumptionObjects);

  return [
    {
      title: "Источник",
      dataIndex: "waterConsumptionObject",
      key: "waterConsumptionObjectId",
      inputNode: <Select
        showSearch
        filterOption={(input, option) => {
          if (option) {
            return (
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          } else {
            return true;
          }
        }}
        options={waterConsumptionObjects?.map(
          (waterConsumptionObject) => {
            return {
              value: waterConsumptionObject.id,
              label: `${waterConsumptionObject.name} ${waterConsumptionObject.sourceCode}`
            }
          }
        )} />,
      render: (obj) => <Text className="w-64" ellipsis={{ tooltip: `${waterConsumptionObjects?.find((waterConsumptionObject) => waterConsumptionObject.id === obj)?.name}` }}>{`${waterConsumptionObjects?.find((waterConsumptionObject) => waterConsumptionObject.id === obj)?.name} - ${waterConsumptionObjects?.find((waterConsumptionObject) => waterConsumptionObject.id === obj)?.sourceCode}`}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Январь, тыс м3",
      dataIndex: "jan",
      key: "jan",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Февраль, тыс м3",
      dataIndex: "feb",
      key: "feb",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Март, тыс м3",
      dataIndex: "mar",
      key: "mar",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Апрель, тыс м3",
      dataIndex: "apr",
      key: "apr",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Май, тыс м3",
      dataIndex: "may",
      key: "may",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Июнь, тыс м3",
      dataIndex: "jun",
      key: "jun",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Июль, тыс м3",
      dataIndex: "jul",
      key: "jul",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Август, тыс м3",
      dataIndex: "aug",
      key: "aug",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Сентябрь, тыс м3",
      dataIndex: "sep",
      key: "sep",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Октябрь, тыс м3",
      dataIndex: "oct",
      key: "oct",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ноябрь, тыс м3",
      dataIndex: "nov",
      key: "nov",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Декабрь, тыс м3",
      dataIndex: "dec",
      key: "dec",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Лимит, тыс м3",
      dataIndex: "limit",
      key: "limit",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Итого",
      dataIndex: "total",
      key: "total",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
