import { EyeOutlined, EditOutlined, DeleteOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm, Row, Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { moderateDataByColumns } from "../../app/helpers";
import { EntityColumns } from "../../app/types";
import { EditableCell } from "./EditableCell";

interface EditableTableProps<T = object, V = object> extends TableProps<T> {
  entityColumns: EntityColumns<T>[];
  onSave?: (saveData: V, id: string) => Promise<boolean>;
  onWatch?: (record: T) => void;
  onDelete?: (record: T) => void;
}

interface ID { id: string; }
interface CODE { code: string; }
type UnionType = ID | CODE;

export const EditableTable = <T extends UnionType, V extends object>({ entityColumns, onSave, onWatch, onDelete, ...rest }: EditableTableProps<T>) => {
  const [editingKey, setEditingKey] = useState<string>("");
  const [form] = Form.useForm();

  const isResourceVisible = !!onWatch;

  const edit = (record: T) => {
    const rowValues = moderateDataByColumns<T>(record, entityColumns);


    if ("id" in rowValues) {
      const { id, ...rest } = rowValues;
      form.setFieldsValue({ ...rest });
      id && setEditingKey(id);
      return id;
    }

    if ("code" in rowValues) {
      const { code, ...rest } = rowValues;
      form.setFieldsValue({ ...rest });
      code && setEditingKey(code);
      return code;
    }

    // EditableTable date converter for Ant Design (bug found)
    // let fields: { [key: string]: any } = {};
    // for (const [key, value] of Object.entries(rest)) {
    //   fields[key] = value;
    //   if (typeof value === 'string' && !isNaN(Date.parse(value as string))) {
    //     fields[key] = dayjs(new Date(value));
    //   }
    // }

    // form.setFieldsValue({ ...rest });
    // id && setEditingKey(id);
  };

  const isEditing = (record: T) => {
    if ("id" in record) {
      return record.id === editingKey
    }

    if ("code" in record) {
      return record.code === editingKey
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const onSubmit = async (values: V) => {
    if (!onSave) return;

    const result = await onSave(values, editingKey);
    if (result) cancel();
  };

  const mergedColumns = entityColumns.map((col) => {
    const { editable, rules, inputNode, dataIndex, ...rest } = col;
    const returnColumn = { dataIndex: dataIndex as string, ...rest };

    if (!editable) {
      return returnColumn;
    }
    return {
      ...returnColumn,
      onCell: (record: T) => ({
        record,
        dataIndex: dataIndex,
        title: rest.title,
        editing: isEditing(record),
        inputNode,
        rules: rules,
      }),
    };
  });

  const columns: ColumnsType<T> = [
    {
      title: "Действия",
      key: "actions",
      render: (_, record) => {
        const editing = isEditing(record);
        return (
          <Row justify={"center"}>
            {editing ? (
              <>
                <Button onClick={form.submit} size="small" className="mr-2" type="primary">
                  <SaveOutlined />
                </Button>
                <Button onClick={cancel} size="small" danger>
                  <CloseOutlined />
                </Button>
              </>
            ) : (
              <>
                {isResourceVisible && (
                  <Button size="small" className="mr-2" onClick={() => onWatch(record)}>
                    <EyeOutlined />
                  </Button>
                )}
                {!!onSave && (<Button size="small" onClick={() => edit(record)} className="mr-2">
                  <EditOutlined />
                </Button>)}
                {!!onDelete && (
                  <Popconfirm
                    title="Вы уверены?"
                    onConfirm={() => onDelete(record)}
                    okText="Удалить"
                    cancelText="Отмена"
                  >
                    <Button size="small" danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Row>
        );
      },

      width: "140px",
      align: "center",
    },
    ...mergedColumns,
  ];

  return (
    <Form form={form} onFinish={onSubmit}>
      <Table scroll={{ x: "max-content" }} columns={columns} components={{ body: { cell: EditableCell } }} {...rest} />
    </Form>
  );
};
