import { Card, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";

interface CountInformationProps {
  link: string;
  informationName: string;
  count?: number;
  icon: ReactNode;
  showDate?: boolean;
};

export const CountInformation: React.FC<CountInformationProps> = (props) => {
  return (
    <Col xs={24} md={12} xl={8} className="p-4">
      <Link to={props.link}>
        <Card className="rounded-lg gridmenu-card">
          <Row className="!m-2 text-2xl p-4 bg-slate-100 w-fit rounded-lg">{props.icon}</Row>
          <Title className="!m-2" level={5}>
            {props.informationName}
          </Title>
          {
            props.count &&
            <Title className="!m-2" level={5}>
              {props.count}
            </Title>
          }
          {
            props.showDate !== false &&
            <Text className="ml-2 text-xs text-gray-500 font-semibold">{moment().locale("ru").format("LL")}</Text>
          }
        </Card>
      </Link>
    </Col>
  );
};
