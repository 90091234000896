import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { Organization } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const organizationColumns: EntityColumns<Organization>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Бин",
    dataIndex: "bin",
    inputNode: <Input />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "БИН неправильного формата", pattern: new RegExp("^[0-9]{12}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "КАТО",
    dataIndex: "kato",
    inputNode: <Input />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "КАТО неправильного формата", pattern: new RegExp("^[0-9]{5,9}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "ОКЭД",
    dataIndex: "oked",
    inputNode: <Input />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "ОКЭД неправильного формата", pattern: new RegExp("^[0-9]{4,7}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "Краткое описание тех процесса",
    dataIndex: "productionProcessDescription",
    inputNode: <TextArea />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "Минимум 10 символов", min: 10 },
    ],
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
  {
    title: "Категория",
    dataIndex: "category",
    inputNode: <Select options={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]} />,
    editable: true,
    copyable: true,
  },
  {
    title: "Токен интеграции",
    dataIndex: "k2BIntegrationToken",
    inputNode: <Input />,
    editable: true,
    copyable: true,
  },
];
