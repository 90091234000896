import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AnalyticsProductionIndicatorApi } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface AnalyticsProductionIndicatorState {
  data: any;
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AnalyticsProductionIndicatorState = {
  data: null,
  status: "idle",
};

export const fetchAnalyticsProductionIndicatorControllerFindByOrganizationIdYearQuarterAsync = createAsyncThunk("analyticsProductionIndicators/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const analyticsProductionIndicatorsApi = new AnalyticsProductionIndicatorApi();
    const response = await analyticsProductionIndicatorsApi.analyticsProductionIndicatorControllerFindByOrganizationIdYearQuarter(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAnalyticsProductionIndicatorControllerFindByOrganizationIdYearQuarter = () => {
    const { pending, fulfilled, rejected } = fetchAnalyticsProductionIndicatorControllerFindByOrganizationIdYearQuarterAsync;
    return {
      [`${pending}`]: (state: AnalyticsProductionIndicatorState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AnalyticsProductionIndicatorState, action: PayloadAction<any[]>) => {
        state.data = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AnalyticsProductionIndicatorState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };


  return { ...fetchAnalyticsProductionIndicatorControllerFindByOrganizationIdYearQuarter() };
};

export const analyticsProductionIndicatorsSlice = createSlice({
  name: "analyticsProductionIndicators",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAnalyticsProductionIndicators = (state: RootState) => state.analyticsProductionIndicators.data;

export default analyticsProductionIndicatorsSlice.reducer;
