import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateCarbonMobileSourceDto, CarbonMobileSource, Organization, UpdateCarbonMobileSourceDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { CarbonMobileSourceForm } from "./components/CarbonMobileSourceForm";
import { CarbonMobileSourceColumns } from "./form/columns";
import { createCarbonMobileSourceAsync, updateCarbonMobileSourceAsync, selectCarbonMobileSource, deleteCarbonMobileSourceAsync, fetchCarbonMobileSourceByOrganizationIdAsync } from "./CarbonMobileSourceSlice";
import { BASE_PATH } from "../../../api/base";

interface CarbonMobileSourceComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const CarbonMobileSourceComponent: React.FC<CarbonMobileSourceComponentProps> = ({ organization, year, quarter }) => {
  const dispatch = useAppDispatch();
  const carbonMobileSource = useAppSelector(selectCarbonMobileSource);

  const [visible, setVisible] = useState(false);
  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }
  useEffect(() => {
    if (organization && year && quarter) {
      dispatch(fetchCarbonMobileSourceByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
    }
  }, [organization, year, quarter, dispatch]);

  const onUpdate = async (carbonMobileSource: UpdateCarbonMobileSourceDto, id: string) => {
    return dispatch(updateCarbonMobileSourceAsync({ carbonMobileSource, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateCarbonMobileSource = (carbonMobileSource: CreateCarbonMobileSourceDto) => {
    if (!organization || !year || !quarter) return;

    dispatch(createCarbonMobileSourceAsync({ ...carbonMobileSource, organizationId: organization.id, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteCarbonMobileSource = (carbonMobileSource: CarbonMobileSource) => {
    return dispatch(deleteCarbonMobileSourceAsync({ carbonMobileSource })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <CarbonMobileSourceForm visible={visible} setVisible={setVisible} onCreateCarbonMobileSource={onCreateCarbonMobileSource} />}
      <EditableTable<CarbonMobileSource, UpdateCarbonMobileSourceDto>
        onSave={onUpdate}
        onDelete={deleteCarbonMobileSource}
        entityColumns={CarbonMobileSourceColumns()}
        dataSource={carbonMobileSource}
        footer={() => {
          return <>
            <p className="mb-0">Всего:</p>
            <ul>
              <li>количество, т: <b>{carbonMobileSource.reduce((n, { quantity }) => n + quantity, 0)}</b></li>
              <li>выброс СО2 (тСО2): <b>{carbonMobileSource.reduce((n, { co2 }) => n + co2, 0)}</b></li>
              <li>выброс СН4 (тСН4): <b>{carbonMobileSource.reduce((n, { ch4 }) => n + ch4, 0)}</b></li>
              <li>выброс N2O (тN2O): <b>{carbonMobileSource.reduce((n, { n2o }) => n + n2o, 0)}</b></li>
              <li>количество выбросов (т СО2е): <b>{carbonMobileSource.reduce((n, { co2e }) => n + co2e, 0)}</b></li>
            </ul>
            {addButton}
          </>;
        }}
      />
      <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/carbon-mobile-source/report/organization/${organization?.id || ""}/year/${year}/quarter/${quarter}`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
    </Layout>
  );
};
