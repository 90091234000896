import { Input, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { Permission } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const permissionsColumns: EntityColumns<Permission>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Год с",
    dataIndex: "yearFrom",
    inputNode: <InputNumber style={{width: '100%'}} type="number" placeholder="2012" />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "Год неправильного формата", pattern: new RegExp("^[0-9]{4}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "Год по",
    dataIndex: "yearTo",
    inputNode: <InputNumber style={{width: '100%'}} placeholder="2045" />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "Год неправильного формата", pattern: new RegExp("^[0-9]{4}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
];
