import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Calculation, CalculationInput, CalculationsApi, CreateCalculationDto } from "../../api";
import { ApiError } from "../../app/types";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { message } from "antd";
import { RootState } from "../../app/store";

export interface CalculationsState {
  status: "idle" | "loading" | "failed";
  calculateStatus: "idle" | "loading" | "failed";
  calculations: Calculation[];
  selectedCalculation: Calculation | undefined;
  inputParameters: Calculation | undefined;
  error?: ApiError;
}

const initialState: CalculationsState = {
  calculations: [],
  selectedCalculation: undefined,
  inputParameters: undefined,
  status: "idle",
  calculateStatus: "idle",
};

export const fetchCalculationsAsync = createAsyncThunk(
  "calculations/fetch",
  async ({ organizationId, year, quarter }: { organizationId: string; year: number; quarter: number }, { rejectWithValue }) => {
    try {
      const calculationsApi = new CalculationsApi();
      const response = await calculationsApi.calculationsControllerFindByOrganizationAndPeriod(organizationId, year, quarter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCalculationsByIdAsync = createAsyncThunk("calculations/fetchById", async (calculationId: string, { rejectWithValue }) => {
  try {
    const calculationsApi = new CalculationsApi();
    const response = await calculationsApi.calculationsControllerFindById(calculationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchInputParametersByWorkshopIdAsync = createAsyncThunk("calculations/fetchInputParametersByWorkshopId", async ({ organizationId, workshopId, year, quarter }: { organizationId: string; workshopId: string; year: number; quarter: number; }, { rejectWithValue }) => {
  try {
    const calculationsApi = new CalculationsApi();
    const response = await calculationsApi.calculationsControllerFindByWorkshopId(organizationId, workshopId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCalculationAsync = createAsyncThunk("calculations/create", async (data: CreateCalculationDto, { rejectWithValue }) => {
  try {
    const calcualtionsApi = new CalculationsApi();
    const response = await calcualtionsApi.calculationsControllerCreate(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const calculateAsync = createAsyncThunk("calculations/calculate", async (calculationId: string, { rejectWithValue }) => {
  try {
    const calcualtionsApi = new CalculationsApi();
    const response = await calcualtionsApi.calculationsControllerCalculate(calculationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCalculationInputAsync = createAsyncThunk(
  "calculations/calculationInputUpdate",
  async ({ calculationInputId, value }: { calculationInputId: string; value: number }, { rejectWithValue }) => {
    try {
      const calcualtionsApi = new CalculationsApi();
      const response = await calcualtionsApi.calculationsControllerUpdateCalculationInput({ value }, calculationInputId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCalculationAsync = createAsyncThunk(
  "calculations/remove",
  async ({ calculation }: { calculation: Calculation }, { rejectWithValue }) => {
    try {
      const calcualtionsApi = new CalculationsApi();
      const response = await calcualtionsApi.calculationsControllerRemove(calculation.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchCalculations = () => {
    const { pending, fulfilled, rejected } = fetchCalculationsAsync;
    return {
      [`${pending}`]: (state: CalculationsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CalculationsState, action: PayloadAction<Calculation[]>) => {
        state.calculations = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CalculationsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchCalculationsById = () => {
    const { pending, fulfilled, rejected } = fetchCalculationsByIdAsync;
    return {
      [`${pending}`]: (state: CalculationsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CalculationsState, action: PayloadAction<Calculation>) => {
        state.selectedCalculation = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CalculationsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchInputParametersByWorkshopId = () => {
    const { pending, fulfilled, rejected } = fetchInputParametersByWorkshopIdAsync;
    return {
      [`${pending}`]: (state: CalculationsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CalculationsState, action: PayloadAction<Calculation>) => {
        state.inputParameters = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CalculationsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCalculation = () => {
    const { pending, fulfilled, rejected } = createCalculationAsync;
    return {
      [`${pending}`]: (state: CalculationsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CalculationsState, action: PayloadAction<Calculation>) => {
        state.calculations.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CalculationsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const calculate = () => {
    const { pending, fulfilled, rejected } = calculateAsync;

    return {
      [`${pending}`]: (state: CalculationsState) => {
        state.calculateStatus = "loading";
      },
      [`${fulfilled}`]: (state: CalculationsState, action: PayloadAction<Calculation>) => {
        message.success("Расчет произошел успешно");
        console.log(action.payload);
        state.selectedCalculation = { ...state.selectedCalculation, ...action.payload };
        state.calculateStatus = "idle";
      },
      [`${rejected}`]: (state: CalculationsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        console.log(error);
        state.status = "failed";
        message.error("Произошла ошибка !");
      },
    };
  };

  const updateCalculationInput = () => {
    const { rejected } = updateCalculationInputAsync;

    return {
      [`${rejected}`]: () => {
        message.error("Не удалось сохранить !");
      },
    };
  };

  const deleteCalculation = () => {
    const { pending, fulfilled, rejected } = deleteCalculationAsync;
    return {
      [`${pending}`]: (state: CalculationsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CalculationsState, action: PayloadAction<Calculation>) => {
        state.calculations.splice(state.calculations.indexOf(
          state.calculations.find((ws: Calculation) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CalculationsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCalculations(), ...fetchCalculationsById(), ...fetchInputParametersByWorkshopId(), ...createCalculation(), ...calculate(), ...updateCalculationInput(), ...deleteCalculation() };
};

export const pekProgramsSlice = createSlice({
  name: "calculations",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectCalculations = (state: RootState) => state.calculations.calculations;
export const selectCalculationsStatus = (state: RootState) => state.calculations.status;
export const selectSelectedCalculation = (state: RootState) => state.calculations.selectedCalculation;
export const inputParameters = (state: RootState) => state.calculations.inputParameters;

export default pekProgramsSlice.reducer;
