import { Checkbox, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { CreateCalculationDto, PollutionSource } from "../../../api";
import TextArea from "antd/es/input/TextArea";

interface CalculationsFormProps {
  visible: boolean;
  setVisible: Function;
  sources: PollutionSource[];
  onCreateCalculation: (values: CreateCalculationDto) => void;
}

export const CalculationsForm: React.FC<CalculationsFormProps> = ({ visible, setVisible, sources, onCreateCalculation }) => {
  const [form] = Form.useForm<CreateCalculationDto>();

  // useEffect(() => {
  //   const defaultAllSelected: string[] = [];
  //   sources.forEach((pollutionSource) => pollutionSource.selectionSources.forEach((source) => defaultAllSelected.push(source.id)));
  //   form.setFieldValue("sourcesId", defaultAllSelected);
  // }, [sources, form]);

  return (
    <Modal width={"90%"} open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateCalculation}>
        <Form.Item label="Наименование" name={"name"} rules={[{ required: true, message: "Заполните поле" }]}>
          <Input className="w-full" />
        </Form.Item>

        <Form.Item label="Комментарии" name={"comment"}>
          <TextArea />
        </Form.Item>

        <Form.Item name="sourcesId" label="Источники">
          <Checkbox.Group className="ml-4 grid grid-cols-3">
            {sources.map((pollutionSource) => (
              <div className="mb-4 mr-14">
                <div>
                  {pollutionSource.inventoryNumber}-{pollutionSource.name}
                </div>
                {pollutionSource.selectionSources.map((selectionSource) => (
                  <Row className="ml-4">
                    <Checkbox value={selectionSource.id}>
                      {selectionSource.inventoryNumber}-{selectionSource.name}
                    </Checkbox>
                  </Row>
                ))}
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
