import { Layout, Tabs } from "antd";
import { Organization, UserRolesEnum } from "../../api";
import { history } from "../../app/helpers";
import { WasteApplication16ByQuarterComponent } from "./WasteApplication16ByQuarterComponent";
import { WasteApplication7ByQuarterComponent } from "./WasteApplication7ByQuarterComponent";
import { useAppSelector } from "../../app/hooks";
import { selectAuthUser } from "../auth/authSlice";

interface WasteApplicationsComponentProps {
  organization?: Organization;
  year: number;
  quarter: number;
}

export const WasteApplicationsComponent: React.FC<WasteApplicationsComponentProps> = ({ organization, year, quarter }) => {
  const authUser = useAppSelector(selectAuthUser);

  return (<Layout className="bg-white p-4 rounded-lg">
    <Tabs
      tabPosition="top"
      destroyInactiveTabPane={true}
      onChange={(path) => {
        let tabPath = history?.location?.pathname.split('/') || [];
        tabPath[6] = path;
        history.navigate && history.navigate(`${tabPath.join('/')}`);
      }}
      defaultActiveKey={history?.location?.pathname.split('/')[6]}
      items={[
        {
          label: 'Приложение 7',
          key: 'wasteApplication7',
          children: <WasteApplication7ByQuarterComponent organization={organization} year={year} quarter={quarter} />,
          roles: ['surveyor', 'engineer', 'tailings', 'career', 'mechanical', 'atc', 'depot', 'rsg', 'oks', 'ahs', 'cl', 'icu', 'of', 'concentrator',],
        },
        {
          label: 'Приложение 16',
          key: 'wasteApplication16',
          children: <WasteApplication16ByQuarterComponent organization={organization} year={year} quarter={quarter} />,
          roles: ['accounting',],
        }
      ].filter((item: any) => {
        if (item.roles.some((role: any) => authUser?.roles.includes(role)) || authUser?.roles.includes(UserRolesEnum.Admin) || authUser?.roles.includes(UserRolesEnum.Chief) || authUser?.roles.includes(UserRolesEnum.Head) || authUser?.roles.includes(UserRolesEnum.Ecolog)) {
          return true;
        } else {
          return false;
        }
      })} />
  </Layout>);
};
