import { Form, Modal } from "antd";
import React from "react";
import { CreateOrganizationDto } from "../../../api";
import { organizationColumns } from "../form/columns";

interface OrganizationFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateOrganization: (values: CreateOrganizationDto) => void;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({ visible, setVisible, onCreateOrganization }) => {
  const [form] = Form.useForm<CreateOrganizationDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateOrganization}>
        {organizationColumns.map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
