import { Layout } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { OrganizationsComponent } from "../features/organizations/OrganizationsComponent";

const OrganizationsPage: React.FC = () => (
  <Layout className="p-8">
    <Title className="ml-2 !mt-0" level={1}>
      Филиалы
    </Title>
    <OrganizationsComponent />
  </Layout>
);

export default OrganizationsPage;
