import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { RopDocument } from "../../api/models/rop-document";
import { CreateRopDocumentDto } from "../../api/models/create-rop-document-dto";
import { UpdateRopDocumentDto } from "../../api/models/update-rop-document-dto";
import { RopDocumentsApi } from "../../api/apis/rop-documents-api";

export interface RopDocumentState {
    ropDocuments: RopDocument[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: RopDocumentState = {
    ropDocuments: [],
    status: "idle",
};

export const createRopDocumentAsync = createAsyncThunk(
    "ropDocument/create",
    async (ropDocument: CreateRopDocumentDto, { rejectWithValue }) => {
        try {
            const ropDocumnetsApi = new RopDocumentsApi();
            const response = await ropDocumnetsApi.ropDocumentsControllerCreate(ropDocument);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateRopDocumentAsync = createAsyncThunk(
    "ropDocument/update",
    async ({ ropDocument, id }: { ropDocument: UpdateRopDocumentDto; id: string }, { rejectWithValue }) => {
        try {
            const ropDocumentApi = new RopDocumentsApi();
            const response = await ropDocumentApi.ropDocumentsControllerUpdate(ropDocument, id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteRopDocumentAsync = createAsyncThunk(
    "ropDocument/remove",
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const ropDocumentsApi = new RopDocumentsApi();
            const response = await ropDocumentsApi.ropDocumentsControllerRemove(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchRopDocumentByQuarterAsync = createAsyncThunk(
    "ropDocument/fetch",
    async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
        try {
            const ropDocumentsApi = new RopDocumentsApi();
            const response = await ropDocumentsApi.ropDocumentsControllerFindByQuart(organizationId, year, quarter);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchRopDocumentBySite = () => {
        const { pending, fulfilled, rejected } = fetchRopDocumentByQuarterAsync;
        return {
            [`${pending}`]: (state: RopDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopDocumentState, action: PayloadAction<RopDocument[]>) => {
                state.ropDocuments = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const createRopCalculation = () => {
        const { pending, fulfilled, rejected } = createRopDocumentAsync;
        return {
            [`${pending}`]: (state: RopDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopDocumentState, action: PayloadAction<RopDocument>) => {
                state.ropDocuments.push(action.payload);
                message.success("Успешно создан");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const updateRopCalculation = () => {
        const { pending, fulfilled, rejected } = updateRopDocumentAsync;

        return {
            [`${pending}`]: (state: RopDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopDocumentState, action: PayloadAction<RopDocument>) => {
                state.ropDocuments = state.ropDocuments.map((ropDocument) => (ropDocument.id === action.payload.id ? action.payload : ropDocument));
                message.success("Успешно изменено");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const removeRopCalculation = () => {
        const { pending, fulfilled, rejected } = deleteRopDocumentAsync;
        return {
            [`${pending}`]: (state: RopDocumentState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopDocumentState, action: PayloadAction<RopDocument>) => {
                state.ropDocuments.splice(state.ropDocuments.indexOf(
                    state.ropDocuments.find((ws: RopDocument) => ws.id === action.payload.id) || action.payload
                ), 1);
                message.success("Успешно удален");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopDocumentState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    return { ...fetchRopDocumentBySite(), ...createRopCalculation(), ...updateRopCalculation(), ...removeRopCalculation() };
};

export const RopDocumentSlice = createSlice({
    name: "RopDocument",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
});

export const selectRopDocuments = (state: RootState) => state.ropDocuments.ropDocuments;

export default RopDocumentSlice.reducer;
