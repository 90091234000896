import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateDropResultDto, DropResultApi, UpdateDropResultDto, DropResult } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface DropResultState {
  dropResult: DropResult[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: DropResultState = {
  dropResult: [],
  status: "idle",
};

export const createDropResultAsync = createAsyncThunk(
  "dropResult/create",
  async (dropResult: CreateDropResultDto, { rejectWithValue }) => {
    try {
      const dropResultApi = new DropResultApi();
      const response = await dropResultApi.dropResultControllerCreate(dropResult);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDropResultAsync = createAsyncThunk(
  "dropResult/update",
  async ({ dropResult, id }: { dropResult: UpdateDropResultDto; id: string }, { rejectWithValue }) => {
    try {
      const dropResultApi = new DropResultApi();
      const response = await dropResultApi.dropResultControllerUpdate(dropResult, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDropResultAsync = createAsyncThunk(
  "dropResult/remove",
  async ({ dropResult }: { dropResult: DropResult }, { rejectWithValue }) => {
    try {
      const dropResultApi = new DropResultApi();
      const response = await dropResultApi.dropResultControllerRemove(dropResult.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDropResultByOrganizationIdAsync = createAsyncThunk("dropResult/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const dropResultApi = new DropResultApi();
    const response = await dropResultApi.dropResultControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchDropResultByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchDropResultByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: DropResultState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropResultState, action: PayloadAction<DropResult[]>) => {
        state.dropResult = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropResultState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createDropResult = () => {
    const { pending, fulfilled, rejected } = createDropResultAsync;
    return {
      [`${pending}`]: (state: DropResultState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropResultState, action: PayloadAction<DropResult>) => {
        state.dropResult.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropResultState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateDropResult = () => {
    const { pending, fulfilled, rejected } = updateDropResultAsync;

    return {
      [`${pending}`]: (state: DropResultState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropResultState, action: PayloadAction<DropResult>) => {
        state.dropResult = state.dropResult.map((dropResult) => (dropResult.id === action.payload.id ? action.payload : dropResult));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropResultState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeDropResult = () => {
    const { pending, fulfilled, rejected } = deleteDropResultAsync;
    return {
      [`${pending}`]: (state: DropResultState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropResultState, action: PayloadAction<DropResult>) => {
        state.dropResult.splice(state.dropResult.indexOf(
          state.dropResult.find((ws: DropResult) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropResultState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchDropResultByOrganization(), ...createDropResult(), ...updateDropResult(), ...removeDropResult() };
};

export const dropResultSlice = createSlice({
  name: "dropResult",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectDropResult = (state: RootState) => state.dropResult.dropResult;

export default dropResultSlice.reducer;
