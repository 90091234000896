import { ProList } from "@ant-design/pro-components";
import { Badge, Button, Input, Layout, Modal } from "antd";
import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { CreateWorkshopDto, IndustrialSite, UpdateWorkshopDto, Workshop } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { WorkshopForm } from "./components/WorkshopForm";
import { createWorkshopAsync, deleteWorkshopAsync, fetchWorkshopsAsync, selectWorkshops, updateWorkshopAsync } from "./workshopsSlice";
import AirPage from "../../pages/AirPage";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WorkshopColumns } from "./form/columns";
import { history } from "../../app/helpers";
import { PollutionSourcesComponent } from "../pollutionSources/PollutionSourcesComponent";

interface WorkshopsComponentProps {
  organizationId: string;
}

export const WorkshopsComponent: React.FC<WorkshopsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const workshops = useAppSelector(selectWorkshops);

  const [visible, setVisible] = useState(false);
  const [whichWorkshop, setWhicWorkshop] = useState<Workshop | undefined>(undefined);



  const onUpdateWorkshop = async (workshop: UpdateWorkshopDto, id: string) => {
    return dispatch(updateWorkshopAsync({ workshop, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWorkshop = (workshop: CreateWorkshopDto) => {
    dispatch(createWorkshopAsync({ ...workshop })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWorkshop = (record: Workshop) => {
    return dispatch(deleteWorkshopAsync(record.id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="primary">
      + Добавить
    </Button>
  );

  const renderBadge = (count: number, active = false) => {
    return (
      <Badge
        count={count}
        style={{
          marginBlockStart: -2,
          marginInlineStart: 4,
          color: active ? "#1890FF" : "#999",
          backgroundColor: active ? "#E6F7FF" : "#eee",
        }}
      />
    );
  };

  return (
    <Layout className="bg-white p-4 rounded-lg">

      <Routes>
        <Route path="/organizationSource/workshops/:workshopId" element={<PollutionSourcesComponent />} />
        <Route path="*" element={
          <>
            {visible && <WorkshopForm visible={visible} setVisible={setVisible} onCreateWorkshop={onCreateWorkshop} />}
            <EditableTable<Workshop, UpdateWorkshopDto>
              onSave={onUpdateWorkshop}
              onDelete={deleteWorkshop}
              onWatch={(workshop) => { history.navigate && history.navigate(`/organizations/${organizationId}/module/directory/organizationSource/workshops/${workshop.id}`) }}
              entityColumns={[
                ...WorkshopColumns(),
                {
                  title: "Количество источников",
                  dataIndex: "totalPollutionSourceCount",
                  editable: false,
                  inputNode: <Input />,
                  render: (value) => value || 0,
                },
              ]}
              dataSource={workshops}
              footer={() => addButton}
            />

            {/* <ProList<Workshop>
        rowKey="name"
        dataSource={workshops}
        metas={{
          title: { dataIndex: "name" },
          description: { dataIndex: "comment" },
          content: {
            dataIndex: "content",
            render: (_, record) => (
              <div key="label" className="flex justify-around">
                <div>
                  <div style={{ color: "#00000073" }}>Количество источников выбросов</div>
                  <div style={{ color: "#000000D9" }}>{record.totalPollutionSourceCount || 0}</div>
                </div>
              </div>
            ),
          },
        }}
        onRow={(record: Workshop) => ({
          onClick: () => setWhicWorkshop(record),
        })}
        toolbar={{
          menu: {
            items: [
              {
                key: "tab1",
                label: <span>Список цехов{renderBadge(workshops.length)}</span>,
              },
            ],
          },
          actions: [addButton],
        }}
      /> */}

            {/* {whichWorkshop && (
        <Modal
          open={true}
          title={`Цэх - ${whichWorkshop.name}`}
          onCancel={() => setWhicWorkshop(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"92%"}
        >
          <AirPage workshopId={whichWorkshop.id} />
        </Modal>
      )} */}
          </>
        } />
      </Routes>
    </Layout>
  );
};
