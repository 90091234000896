import { Button, Layout, Modal } from "antd";
import { useState } from "react";
import { CreateIndustrialSiteDto, IndustrialSite, UpdateIndustrialSiteDto } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WorkshopsComponent } from "../workshops/WorkshopsComponent";
import { IndustrialSiteForm } from "./components/IndustrialSiteForm";
import { industrialSitesColumns } from "./form/columns";
import { createIndustrialSiteAsync, deleteIndustrialSiteAsync, updateIndustrialSiteAsync } from "./industrialSitesSlice";

interface IndustrialSitesComponentProps {
  industrialSites?: IndustrialSite[];
  organizationId?: string;
}

export const IndustrialSitesComponent: React.FC<IndustrialSitesComponentProps> = ({ organizationId, industrialSites }) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [whichIndustrialWorkshops, setWhichIndustrialWorkshops] = useState<IndustrialSite | undefined>(undefined);

  const onUpdate = async (industrialSite: UpdateIndustrialSiteDto, id: string) => {
    return dispatch(updateIndustrialSiteAsync({ industrialSite, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateIndustrialSite = (industrialSite: CreateIndustrialSiteDto) => {
    if (!organizationId) return;

    dispatch(createIndustrialSiteAsync({ ...industrialSite, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteIndustrialSite = (record: IndustrialSite) => {
    return dispatch(deleteIndustrialSiteAsync(record.id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <IndustrialSiteForm visible={visible} setVisible={setVisible} onCreateIndustrialSite={onCreateIndustrialSite} />
      <EditableTable<IndustrialSite, UpdateIndustrialSiteDto>
        onSave={onUpdate}
        onDelete={deleteIndustrialSite}
        // onWatch={setWhichIndustrialWorkshops}
        entityColumns={industrialSitesColumns}
        dataSource={industrialSites}
        footer={() => addButton}
      />
      {/* {whichIndustrialWorkshops && (
        <Modal
          open={true}
          title={`Площадка - ${whichIndustrialWorkshops.name}`}
          onCancel={() => setWhichIndustrialWorkshops(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"90%"}
        >
          <WorkshopsComponent industrialSite={whichIndustrialWorkshops} />
        </Modal>
      )} */}
    </Layout>
  );
};
