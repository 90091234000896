import { Empty, Layout, Tabs } from "antd";
import { useEffect } from "react";
import { Organization, UserRolesEnum } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchWorkshopsAsync, selectWorkshops } from "../workshops/workshopsSlice";
import { PeriodDataTable } from "./components/PeriodDataTable";
import { fetchCategoriesAsync, selectCategories } from "../categories/categoriesSlice";
import { selectAuthUser } from "../auth/authSlice";
import { UserRole } from "../../app/role";

interface PeriodDataComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const PeriodDataComponent: React.FC<PeriodDataComponentProps> = ({ organization, year, quarter }) => {

  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectCategories);
  const authUser = useAppSelector(selectAuthUser);

  useEffect(() => {
    if (organization) {
      dispatch(fetchCategoriesAsync(organization.id));
    }
  }, [organization, dispatch]);

  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        {categories && <Tabs
          tabPosition="left"
          destroyInactiveTabPane={true}
          defaultActiveKey={'defaultTab'}
          size="small"
          items={[{
            label: '',
            key: '',
            children:
              <Empty
                description={
                  <span>
                    Выберите категорию чтобы начать заполнение
                  </span>
                }
              />
            ,
          }, ...categories?.filter(category => {
            if (authUser?.roles.includes(category.role as any) || authUser?.roles.includes(UserRolesEnum.Admin) || authUser?.roles.includes(UserRolesEnum.Chief) || authUser?.roles.includes(UserRolesEnum.Head) || authUser?.roles.includes(UserRolesEnum.Ecolog)) {
              return true;
            } else {
              return false;
            }
          })?.map((category, index) => {
            return {
              label: `${category.name}`,
              key: `${index}`,
              children: <PeriodDataTable organization={organization} category={category} year={year} quarter={quarter} />,
            }
          })]}
        />}
      </Layout>
    </>
  );
};
