import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAirSzzJournalDto, AirSzzJournalApi, UpdateAirSzzJournalDto, AirSzzJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface AirSzzJournalsState {
  airSzzJournals: AirSzzJournal[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AirSzzJournalsState = {
  airSzzJournals: [],
  status: "idle",
};

export const createAirSzzJournalAsync = createAsyncThunk(
  "airSzzJournals/create",
  async (airSzzJournal: CreateAirSzzJournalDto, { rejectWithValue }) => {
    try {
      const airSzzJournalsApi = new AirSzzJournalApi();
      const response = await airSzzJournalsApi.airSzzJournalControllerCreate(airSzzJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAirSzzJournalAsync = createAsyncThunk(
  "airSzzJournals/update",
  async ({ airSzzJournal, id }: { airSzzJournal: UpdateAirSzzJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const airSzzJournalsApi = new AirSzzJournalApi();
      const response = await airSzzJournalsApi.airSzzJournalControllerUpdate(airSzzJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAirSzzJournalAsync = createAsyncThunk(
  "airSzzJournals/remove",
  async ({ airSzzJournal }: { airSzzJournal: AirSzzJournal }, { rejectWithValue }) => {
    try {
      const airSzzJournalsApi = new AirSzzJournalApi();
      const response = await airSzzJournalsApi.airSzzJournalControllerRemove(airSzzJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAirSzzJournalsByQuarterAsync = createAsyncThunk("airSzzJournals/fetch", async ({organizationId, year, quarter}: {organizationId: string, year: number, quarter: number}, { rejectWithValue }) => {
  try {
    const airSzzApi = new AirSzzJournalApi();
    const response = await airSzzApi.airSzzJournalControllerFindByQuart(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAirSzzJournalsByAirSzzSite = () => {
    const { pending, fulfilled, rejected } = fetchAirSzzJournalsByQuarterAsync;
    return {
      [`${pending}`]: (state: AirSzzJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzJournalsState, action: PayloadAction<AirSzzJournal[]>) => {
        state.airSzzJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAirSzzJournal = () => {
    const { pending, fulfilled, rejected } = createAirSzzJournalAsync;
    return {
      [`${pending}`]: (state: AirSzzJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzJournalsState, action: PayloadAction<AirSzzJournal>) => {
        state.airSzzJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAirSzzJournal = () => {
    const { pending, fulfilled, rejected } = updateAirSzzJournalAsync;

    return {
      [`${pending}`]: (state: AirSzzJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzJournalsState, action: PayloadAction<AirSzzJournal>) => {
        state.airSzzJournals = state.airSzzJournals.map((airSzzJournal) => (airSzzJournal.id === action.payload.id ? action.payload : airSzzJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAirSzzJournal = () => {
    const { pending, fulfilled, rejected } = deleteAirSzzJournalAsync;
    return {
      [`${pending}`]: (state: AirSzzJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzJournalsState, action: PayloadAction<AirSzzJournal>) => {
        state.airSzzJournals.splice(state.airSzzJournals.indexOf(
          state.airSzzJournals.find((ws: AirSzzJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAirSzzJournalsByAirSzzSite(), ...createAirSzzJournal(), ...updateAirSzzJournal(), ...removeAirSzzJournal() };
};

export const airSzzJournalsSlice = createSlice({
  name: "airSzzJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAirSzzJournals = (state: RootState) => state.airSzzJournals.airSzzJournals;

export default airSzzJournalsSlice.reducer;
