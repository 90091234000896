import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { CarbonWasteRecycle } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";
import { EntityColumns } from "../../../../app/types";
import { selectOrganizationModulePeriod } from "../../../organizationModule/OrganizationModuleSlice";

export const CarbonWasteRecycleColumns = (): EntityColumns<CarbonWasteRecycle>[] => {
  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Наименование процесса",
      dataIndex: "carbonWasteRecycleProcess",
      key: "carbonWasteRecycleProcess",
      inputNode: <Select
        options={[
          {
            value: 'incineration',
            label: 'Сжигание отходов',
          },
        ]} />,
      render: (obj) => {
        let label = '';
        if (obj === 'incineration') {
          label = 'Сжигание отходов';
        }
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Количество, т",
      dataIndex: "quantity",
      key: "quantity",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Доля углерода сжигаемого отхода",
      dataIndex: "carbon",
      key: "carbon",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Коэффициент окисления",
      dataIndex: "oxidation",
      key: "oxidation",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Коэффициент выбросов N2O (тN2O/т)",
      dataIndex: "coefficient",
      key: "coefficient",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Выброс СО2 (тСО2)",
      dataIndex: "co2",
      key: "co2",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Выброс N2O (тN2O)",
      dataIndex: "n2o",
      key: "n2o",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Количество выбросов (т СО2е)",
      dataIndex: "co2e",
      key: "co2e",
      inputNode: <></>,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
      hideOnForm: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
