import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteEquipmentRecycleDto, WasteEquipmentRecyclesApi, UpdateWasteEquipmentRecycleDto, WasteEquipmentRecycle } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteEquipmentRecyclesState {
  wasteEquipmentRecycles: WasteEquipmentRecycle[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteEquipmentRecyclesState = {
  wasteEquipmentRecycles: [],
  status: "idle",
};

export const createWasteEquipmentRecycleAsync = createAsyncThunk(
  "wasteEquipmentRecycles/create",
  async (wasteEquipmentRecycle: CreateWasteEquipmentRecycleDto, { rejectWithValue }) => {
    try {
      const wasteEquipmentRecyclesApi = new WasteEquipmentRecyclesApi();
      const response = await wasteEquipmentRecyclesApi.wasteEquipmentRecyclesControllerCreate(wasteEquipmentRecycle);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteEquipmentRecycleAsync = createAsyncThunk(
  "wasteEquipmentRecycles/update",
  async ({ wasteEquipmentRecycle, id }: { wasteEquipmentRecycle: UpdateWasteEquipmentRecycleDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteEquipmentRecyclesApi = new WasteEquipmentRecyclesApi();
      const response = await wasteEquipmentRecyclesApi.wasteEquipmentRecyclesControllerUpdate(wasteEquipmentRecycle, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteEquipmentRecycleAsync = createAsyncThunk(
  "wasteEquipmentRecycles/remove",
  async ({ wasteEquipmentRecycle }: { wasteEquipmentRecycle: WasteEquipmentRecycle }, { rejectWithValue }) => {
    try {
      const wasteEquipmentRecyclesApi = new WasteEquipmentRecyclesApi();
      const response = await wasteEquipmentRecyclesApi.wasteEquipmentRecyclesControllerRemove(wasteEquipmentRecycle.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteEquipmentRecyclesByOrganizationAsync = createAsyncThunk("wasteEquipmentRecycles/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteEquipmentRecyclesApi();
    const response = await wasteApi.wasteEquipmentRecyclesControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteEquipmentRecyclesByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWasteEquipmentRecyclesByOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteEquipmentRecyclesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<WasteEquipmentRecycle[]>) => {
        state.wasteEquipmentRecycles = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteEquipmentRecycle = () => {
    const { pending, fulfilled, rejected } = createWasteEquipmentRecycleAsync;
    return {
      [`${pending}`]: (state: WasteEquipmentRecyclesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<WasteEquipmentRecycle>) => {
        state.wasteEquipmentRecycles.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteEquipmentRecycle = () => {
    const { pending, fulfilled, rejected } = updateWasteEquipmentRecycleAsync;

    return {
      [`${pending}`]: (state: WasteEquipmentRecyclesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<WasteEquipmentRecycle>) => {
        state.wasteEquipmentRecycles = state.wasteEquipmentRecycles.map((wasteEquipmentRecycle) => (wasteEquipmentRecycle.id === action.payload.id ? action.payload : wasteEquipmentRecycle));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteEquipmentRecycle = () => {
    const { pending, fulfilled, rejected } = deleteWasteEquipmentRecycleAsync;
    return {
      [`${pending}`]: (state: WasteEquipmentRecyclesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<WasteEquipmentRecycle>) => {
        state.wasteEquipmentRecycles.splice(state.wasteEquipmentRecycles.indexOf(
          state.wasteEquipmentRecycles.find((ws: WasteEquipmentRecycle) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentRecyclesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteEquipmentRecyclesByOrganization(), ...createWasteEquipmentRecycle(), ...updateWasteEquipmentRecycle(), ...removeWasteEquipmentRecycle() };
};

export const wasteEquipmentRecyclesSlice = createSlice({
  name: "wasteEquipmentRecycles",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteEquipmentRecycles = (state: RootState) => state.wasteEquipmentRecycles.wasteEquipmentRecycles;

export default wasteEquipmentRecyclesSlice.reducer;
