import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteRegisterDto } from "../../../api";
import { WasteRegistersColumns } from "../form/columns";

interface WasteRegisterFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteRegister: (values: CreateWasteRegisterDto) => void;
}

export const WasteRegisterForm: React.FC<WasteRegisterFormProps> = ({ visible, setVisible, onCreateWasteRegister }) => {
  const [form] = Form.useForm<CreateWasteRegisterDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteRegister}>
        {WasteRegistersColumns().map(({ title, key, rules, inputNode, hideOnForm }, index) => (
          <>
            {!hideOnForm && <Form.Item label={title} name={key} rules={rules} key={index}>
              {inputNode}
            </Form.Item>}
          </>
        ))}
      </Form>
    </Modal>
  );
};
