import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterJournalDto, WaterJournalApi, UpdateWaterJournalDto, WaterJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WaterJournalsState {
  waterJournals: WaterJournal[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterJournalsState = {
  waterJournals: [],
  status: "idle",
};

export const createWaterJournalAsync = createAsyncThunk(
  "waterJournals/create",
  async (waterJournal: CreateWaterJournalDto, { rejectWithValue }) => {
    try {
      const waterJournalsApi = new WaterJournalApi();
      const response = await waterJournalsApi.waterJournalControllerCreate(waterJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterJournalAsync = createAsyncThunk(
  "waterJournals/update",
  async ({ waterJournal, id }: { waterJournal: UpdateWaterJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const waterJournalsApi = new WaterJournalApi();
      const response = await waterJournalsApi.waterJournalControllerUpdate(waterJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterJournalAsync = createAsyncThunk(
  "waterJournals/remove",
  async ({ waterJournal }: { waterJournal: WaterJournal }, { rejectWithValue }) => {
    try {
      const waterJournalsApi = new WaterJournalApi();
      const response = await waterJournalsApi.waterJournalControllerRemove(waterJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterJournalsByQuarterAsync = createAsyncThunk("waterJournals/fetch", async ({organizationId, year, quarter}: {organizationId: string, year: number, quarter: number}, { rejectWithValue }) => {
  try {
    const waterApi = new WaterJournalApi();
    const response = await waterApi.waterJournalControllerFindByQuart(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterJournalsByWaterSite = () => {
    const { pending, fulfilled, rejected } = fetchWaterJournalsByQuarterAsync;
    return {
      [`${pending}`]: (state: WaterJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterJournalsState, action: PayloadAction<WaterJournal[]>) => {
        state.waterJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterJournal = () => {
    const { pending, fulfilled, rejected } = createWaterJournalAsync;
    return {
      [`${pending}`]: (state: WaterJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterJournalsState, action: PayloadAction<WaterJournal>) => {
        state.waterJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterJournal = () => {
    const { pending, fulfilled, rejected } = updateWaterJournalAsync;

    return {
      [`${pending}`]: (state: WaterJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterJournalsState, action: PayloadAction<WaterJournal>) => {
        state.waterJournals = state.waterJournals.map((waterJournal) => (waterJournal.id === action.payload.id ? action.payload : waterJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterJournal = () => {
    const { pending, fulfilled, rejected } = deleteWaterJournalAsync;
    return {
      [`${pending}`]: (state: WaterJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterJournalsState, action: PayloadAction<WaterJournal>) => {
        state.waterJournals.splice(state.waterJournals.indexOf(
          state.waterJournals.find((ws: WaterJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterJournalsByWaterSite(), ...createWaterJournal(), ...updateWaterJournal(), ...removeWaterJournal() };
};

export const waterJournalsSlice = createSlice({
  name: "waterJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterJournals = (state: RootState) => state.waterJournals.waterJournals;

export default waterJournalsSlice.reducer;
