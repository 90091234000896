import { Input, InputNumber } from "antd";
import { WaterPointPollutant } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const waterPointPollutantsColumns: EntityColumns<WaterPointPollutant>[] = [
  {
    title: "Наименование загрязняющего вещества",
    dataIndex: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: `Предельно допустимая концентрация (мг/м3) - От`,
    dataIndex: "minAvailableConcentration",
    key: "minAvailableConcentration",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: false, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: `Предельно допустимая концентрация (мг/м3) - До`,
    dataIndex: "maxAvailableConcentration",
    key: "maxAvailableConcentration",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: false, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
];
