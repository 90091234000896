import React from "react";

function ProfileMenuIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="208" height="208" fill="none" viewBox="0 0 208 208">
      <path fill="#DFE6E9" d="M119.062 23.59V72.747c0 .33.52.33.52 0V34.493 23.59c0-.33-.52-.33-.52 0z"></path>
      <path
        fill="#DFE6E9"
        d="M119.063 23.4c13.711.226 27.421.607 41.132 1.144.329.017.329-.503 0-.52a1810.26 1810.26 0 00-41.132-1.144c-.329 0-.329.52 0 .52zM119.496 73.06c12.844.936 25.601 2.738 38.341 4.558.33.052.468-.45.139-.502-12.792-1.82-25.601-3.623-38.48-4.576-.329-.018-.329.502 0 .52z"
      ></path>
      <path fill="#DFE6E9" d="M159.935 24.648v53.161c0 .33.52.33.52 0V36.382 24.648c0-.33-.52-.33-.52 0z"></path>
      <path
        fill="#DFE6E9"
        d="M157.525 77.584c.85.12 1.699.277 2.531.433.329.07.468-.433.139-.503a43.033 43.033 0 00-2.531-.433c-.329-.035-.468.45-.139.502zM119.565 46.314c6.968.087 13.902.78 20.8 1.733 3.38.469 6.76.989 10.123 1.509 3.224.502 6.5 1.23 9.776.953.329-.035.329-.555 0-.52-3.397.277-6.812-.503-10.14-1.023a526.03 526.03 0 00-10.296-1.525c-6.725-.902-13.468-1.578-20.263-1.664-.346 0-.346.52 0 .537z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M122.252 46.505c.295 7.783.607 15.566.901 23.348.018.33.538.33.52 0-.294-7.782-.606-15.565-.901-23.348-.017-.329-.537-.329-.52 0z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M123.777 70.148c11.822 1.439 23.66 2.687 35.499 3.918.329.034.329-.486 0-.52-11.839-1.249-23.677-2.497-35.499-3.918-.329-.034-.329.486 0 .52z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M119.826 72.61c1.3-.746 2.582-1.492 3.882-2.254.295-.173.035-.624-.26-.45-1.3.744-2.582 1.49-3.882 2.252-.295.174-.035.624.26.451zM123.31 43.472c10.538.814 21.094 1.612 31.633 2.426.329.018.329-.502 0-.52-10.539-.814-21.095-1.612-31.633-2.426-.33-.035-.33.485 0 .52zM122.096 39.762a713.18 713.18 0 0134.424.988c.33.018.33-.502 0-.52a690.435 690.435 0 00-34.424-.988c-.346 0-.346.52 0 .52zM124.141 34.407l23.712.052c.33 0 .33-.52 0-.52l-23.712-.052c-.346 0-.346.52 0 .52zM122.339 29.241c10.764-.139 21.51.156 32.257.867.329.017.329-.503 0-.52a408.482 408.482 0 00-32.257-.867c-.347 0-.347.52 0 .52zM149.032 72.002c-.225-.503-.156-.78.208-.867.139-.052.277-.104.416-.121.173-.035.347-.07.503-.104.312-.087.606-.225.832-.468.416-.433.52-.988.416-1.56-.018-.104-.139-.208-.243-.19-1.439.12-1.317-2.133.156-1.682.26.087.416-.26.26-.433-1.144-1.231-3.345-4.23-1.837-5.876.398-.434 1.109-.711 1.646-.364.33.208.538.606.711.953.243.433.451.919.867 1.196.936.624 1.889-.243 2.045-1.179.139-.866-.277-1.733-.277-2.6 0-.607.121-1.282.572-1.733.468-.468 1.265-.624 1.82-.173.19.155.52.017.433-.26-.173-.572-.243-1.18.087-1.716.381-.59 1.3-.815 1.681-.087.156.294.607.035.451-.26-.399-.745-1.37-.919-2.08-.486-.902.555-.919 1.769-.642 2.67.139-.087.295-.173.434-.26-1.37-1.144-3.016.156-3.242 1.664-.069.45-.034.901.035 1.352.104.71.485 1.664.017 2.323-.97 1.369-1.75-.867-2.201-1.422-.988-1.23-2.773-.59-3.224.815-.659 2.028 1.005 4.403 2.305 5.79.087-.14.174-.295.26-.434-.814-.26-1.802.208-1.941 1.11-.121.935.763 1.646 1.629 1.577-.086-.07-.173-.122-.242-.191.346 1.855-2.271 1.04-2.722 2.41-.225.693.451 1.317 1.11 1.022.329-.121.069-.572-.243-.416zM148.096 84.69v15.947c0 .329.52.329.52 0V84.69c0-.33-.52-.33-.52 0z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M148.408 84.673c6.535 1.456 13.052 2.912 19.587 4.35.329.07.468-.432.138-.502-6.534-1.456-13.052-2.912-19.586-4.35-.33-.07-.468.433-.139.502zM148.633 101.088a986.252 986.252 0 0017.16 5.408c.33.104.451-.399.139-.503a977.73 977.73 0 01-17.16-5.408c-.312-.104-.451.399-.139.503z"
      ></path>
      <path fill="#DFE6E9" d="M167.908 88.798v17.767c0 .329.52.329.52 0V88.798c0-.347-.52-.347-.52 0z"></path>
      <path
        fill="#DFE6E9"
        d="M165.481 106.513c.919.121 1.838.26 2.739.381.329.052.468-.451.139-.503-.919-.121-1.838-.26-2.739-.381-.329-.035-.468.468-.139.503zM149.899 86.736c-.26 4.142-.312 8.285-.173 12.427.017.33.537.33.52 0-.139-4.142-.087-8.285.173-12.427.017-.33-.503-.33-.52 0z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M149.933 99.892c5.391 1.438 10.99 2.582 16.103 4.836.312.138.572-.312.26-.451-5.165-2.271-10.799-3.449-16.241-4.888-.295-.087-.434.416-.122.503zM150.003 86.736a547.5 547.5 0 0116.744 3.137c.329.07.468-.433.138-.503a499.222 499.222 0 00-16.744-3.137c-.329-.052-.468.45-.138.503z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M166.486 89.474a176.173 176.173 0 00-.554 14.803c0 .329.52.329.52 0-.035-4.94.156-9.88.554-14.803.018-.329-.502-.329-.52 0zM150.298 93.062a39.539 39.539 0 006.205 2.392c.329.087.468-.416.139-.502a36.382 36.382 0 01-6.084-2.34c-.295-.139-.555.312-.26.45z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M158.063 88.174a59.69 59.69 0 00-2.046 7.176c-.069.33.434.468.503.139a61.369 61.369 0 012.045-7.176c.104-.33-.398-.45-.502-.139z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M157.508 91.155c1.56-.294 2.791 1.404 3.051 2.74.277 1.42-.364 2.72-1.404 3.691-1.11 1.023-2.652 1.335-4.004.555-1.387-.797-1.82-2.618-1.214-4.022.139-.312-.312-.572-.45-.26-.659 1.526-.347 3.398.988 4.455 1.438 1.144 3.449 1.006 4.818-.156 1.439-1.213 2.236-2.999 1.647-4.836-.468-1.49-1.889-2.964-3.571-2.652-.329.052-.19.555.139.485zM160.524 99.944a8.933 8.933 0 011.768 1.438c.226.243.607-.121.364-.364a9.863 9.863 0 00-1.872-1.525c-.277-.173-.537.277-.26.45zM162.188 97.326c.572.139 1.127.26 1.699.399.329.07.468-.433.139-.503-.572-.138-1.127-.26-1.699-.398-.329-.07-.468.416-.139.502zM161.685 91.745a3.256 3.256 0 001.89-.71c.26-.209-.104-.573-.364-.365a2.71 2.71 0 01-1.526.572c-.346 0-.346.52 0 .503zM158.375 86.094c-.017-.381-.069-.936.329-1.144.382-.208.486.174.468.45-.017.504-.173.989 0 1.474.122.312.624.174.503-.138-.26-.676.399-1.751-.364-2.236-.364-.243-.832-.104-1.127.19-.364.382-.364.919-.346 1.404.017.33.537.33.537 0zM40.474 5.182v28.739c0 .33.52.33.52 0V5.182c0-.346-.52-.346-.52 0z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M40.37 34.077A31.718 31.718 0 0026 48.914c-.138.295.312.572.451.26A31.392 31.392 0 0140.63 34.528c.294-.139.034-.59-.26-.451z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M40.699 34.389c6.119 2.548 11.059 7.592 13.485 13.745.122.312.624.173.503-.139-2.514-6.36-7.54-11.491-13.85-14.109-.312-.121-.45.381-.138.503z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M27.352 49.487c8.355-3.796 17.715-2.375 26.433-.936.33.052.468-.45.139-.503-8.892-1.456-18.339-2.86-26.85.988-.277.139-.017.59.278.45z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M37.544 47.268c-.502 1.092-.71 2.41-.173 3.536.59 1.248 2.045 1.803 3.362 1.837 1.231.035 2.531-.485 3.155-1.612.572-1.057.503-2.34-.33-3.241-.224-.243-.589.121-.363.364.71.763.693 1.941.121 2.79-.607.92-1.785 1.231-2.843 1.145-1.091-.087-2.236-.59-2.669-1.647-.398-.953-.225-2.028.19-2.947.14-.277-.311-.537-.45-.225zM15.08 63.7c3.363-2.15 6.743-4.299 10.105-6.43.278-.174.018-.625-.26-.452-3.362 2.15-6.742 4.3-10.105 6.431-.295.19-.035.642.26.45zM16.917 73.979a114.813 114.813 0 008.164-8.268c.226-.243-.139-.624-.364-.364a114.813 114.813 0 01-8.164 8.268c-.242.225.121.59.364.364zM30.558 72.314a397.841 397.841 0 00-7.262 20.298c-.104.312.399.45.503.138a403.914 403.914 0 017.262-20.297c.122-.312-.381-.434-.503-.139zM41.357 67.582c2.028 5.356 4.074 10.712 6.102 16.05.12.313.623.174.502-.138-2.028-5.356-4.073-10.712-6.101-16.05-.121-.313-.624-.174-.503.138zM50.908 60.147a81.732 81.732 0 018.51 8.285c.226.243.59-.121.365-.364a83.515 83.515 0 00-8.51-8.285c-.244-.226-.608.138-.365.364zM55.57 53.04c2.358 1.16 4.733 2.305 7.09 3.466.295.139.572-.295.26-.45-2.358-1.162-4.732-2.306-7.09-3.467-.294-.139-.554.294-.26.45zM40.82 134.593c3.83-3.293 8.77-4.611 13.572-5.824 2.444-.624 4.87-1.248 7.263-2.08 2.756-.953 5.442-2.115 8.112-3.276.312-.139.034-.572-.26-.451-2.843 1.231-5.686 2.462-8.615 3.45-2.444.814-4.94 1.421-7.436 2.062-4.61 1.196-9.308 2.583-12.983 5.755-.277.208.087.572.347.364zM73.979 45.552a244.589 244.589 0 0133.644 4.437c.329.07.468-.433.138-.503a246.352 246.352 0 00-33.765-4.472c-.364 0-.364.52-.017.538z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M73.996 45.153a9.384 9.384 0 00.104-2.2c-.017-.33-.537-.33-.52 0a8.371 8.371 0 01-.087 2.08c-.051.311.451.45.503.12z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M73.944 43.455c11.301 1.161 22.654 2.184 33.782 4.576.33.07.468-.434.139-.503-11.18-2.392-22.568-3.432-33.921-4.593-.33-.035-.33.485 0 .52z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M107.501 47.684c-.069.658-.017 1.317.104 1.976.07.329.572.19.503-.14a6.528 6.528 0 01-.087-1.837c.035-.329-.485-.329-.52 0zM74.81 37.491c-.225 2.011 0 3.987.694 5.894.121.312.624.173.503-.139-.676-1.855-.901-3.779-.676-5.755.034-.346-.486-.329-.52 0z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M74.724 37.544c2.167.59 4.385.85 6.621.745.33-.017.33-.537 0-.52a21.483 21.483 0 01-6.482-.728c-.33-.086-.468.416-.139.503z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M80.912 38.272a8.967 8.967 0 01-.693 5.165c-.14.294.312.572.45.26.797-1.768 1.04-3.64.746-5.564-.053-.33-.555-.19-.503.139zM76.717 37.336c-1.352-1.786-1.855-4.022-1.37-6.206-.138.035-.294.07-.432.122a10.775 10.775 0 013.206 6.482c.035.33.555.33.52 0a11.166 11.166 0 00-3.363-6.846c-.138-.139-.398-.087-.433.121-.502 2.323 0 4.715 1.421 6.604.191.243.642-.017.451-.277z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M78.07 35.29c.242-1.23.623-2.409 1.195-3.518.26-.52.572-1.023.92-1.508.242-.33.242-.538.398-.156.104.26.139.745.173 1.005a9.267 9.267 0 01-1.941 6.708c-.208.26.156.624.364.364 1.785-2.27 2.686-5.495 1.872-8.337-.382-1.335-1.283.086-1.647.641-.901 1.421-1.508 3.016-1.837 4.663-.052.329.45.468.502.138zM85.835 44.546c.07-.242.07-.416.052-.676-.017-.208-.208-.641-.139-.814.26-.711 1.89-.191 2.375-.14 1.716.209 3.415.434 5.113.677 3.294.468 6.57 1.005 9.828 1.577-.069-.086-.121-.173-.19-.242.052.364.086.728.138 1.092.035.329.555.329.52 0-.052-.364-.086-.728-.138-1.092-.018-.104-.07-.226-.191-.243-3.83-.693-7.661-1.3-11.51-1.82-1.767-.243-3.605-.624-5.39-.676-.399-.017-.797.052-1.005.45-.278.555.173 1.18.034 1.751-.086.347.416.486.503.156z"
      ></path>
      <path
        fill="#DFE6E9"
        d="M91.173 42.605c-.658-1.005.728-1.543 1.49-1.543.729 0 1.457.174 2.185.278 1.317.19 2.652.381 3.97.589 1.299.19 2.617.416 3.917.659.589.121 1.178.242 1.785.381.52.121 2.635.381 2.184 1.352-.139.295.312.572.451.26.728-1.577-1.647-1.924-2.652-2.15-2.098-.467-4.212-.814-6.344-1.126-1.058-.156-2.098-.312-3.155-.45-.936-.14-1.959-.417-2.912-.26-1.057.155-2.01 1.265-1.335 2.287.156.26.607 0 .416-.277zM103.081 45.153a8.837 8.837 0 003.692.173c.33-.052.191-.554-.138-.502a8.411 8.411 0 01-3.415-.174c-.329-.069-.468.434-.139.503zM26.26 49.504c3.588.416 7.193.64 10.816.676.33 0 .33-.52 0-.52a107.188 107.188 0 01-10.816-.676c-.33-.035-.33.485 0 .52zM44.304 49.955c3.38 0 6.69-.416 10.002-1.075.329-.07.19-.572-.14-.503-3.258.659-6.516 1.057-9.862 1.057-.33 0-.33.52 0 .52zM31.79 61.308l-5.2 9.256c-.157.295.277.555.45.26l5.2-9.256c.156-.295-.295-.555-.45-.26zM43.49 58.118a60.983 60.983 0 013.084 5.495c.157.295.607.035.451-.26a68.393 68.393 0 00-3.085-5.495c-.173-.294-.624-.034-.45.26zM51.203 54.41c1.317.762 2.617 1.507 3.934 2.27.295.173.555-.277.26-.45-1.317-.763-2.617-1.509-3.934-2.271-.295-.174-.555.277-.26.45z"
      ></path>
      <path
        fill="#2D3436"
        d="M85.766 164.302a398.517 398.517 0 0037.925-12.358 378.2 378.2 0 0018.2-7.488 358.128 358.128 0 009.083-4.143c2.964-1.387 5.876-2.895 8.874-4.177 3.432-1.474 7.107-2.635 10.886-2.392 3.796.242 7.228 2.028 10.538 3.761.295.156.555-.295.26-.451-2.773-1.456-5.616-2.946-8.718-3.57-3.016-.59-6.084-.226-9.014.641-3.12.919-6.049 2.357-8.961 3.761a365.115 365.115 0 01-9.169 4.299 396.664 396.664 0 01-38.116 14.976 387.828 387.828 0 01-21.91 6.621c-.346.104-.208.607.122.52z"
      ></path>
      <path
        fill="#2D3436"
        d="M172.155 138.545c2.444.486 4.749-.45 7.159-.71 2.756-.295 5.442.502 8.008 1.421a75.142 75.142 0 0114.178 6.812c.295.173.555-.277.26-.451a76.128 76.128 0 00-14.005-6.76c-2.392-.866-4.905-1.664-7.488-1.612-2.687.07-5.287 1.318-7.973.798-.33-.07-.468.433-.139.502zM69.126 177.337c-1.89 3.016-2.566 6.535-2.965 10.019-.468 4.073-.832 8.164-1.23 12.237-.035.33.485.33.52 0 .381-3.882.745-7.765 1.161-11.648.399-3.588 1.006-7.228 2.964-10.348.174-.277-.26-.537-.45-.26zM81.398 184.86c-3.086 5.148-3.502 11.249-3.484 17.108 0 .329.52.329.52 0-.018-5.772.364-11.787 3.414-16.848.174-.295-.277-.555-.45-.26zM55.138 182.988c3.016-3.311 6.5-6.569 10.33-8.909 3.987-2.427 8.597-2.86 13.139-3.19.33-.017.33-.537 0-.52-4.524.347-9.152.763-13.139 3.103-1.941 1.144-3.675 2.583-5.373 4.021a59.324 59.324 0 00-5.322 5.114c-.225.26.14.624.364.381zM134.039 128.422a503.87 503.87 0 0128.635 6.084c.329.087.468-.416.138-.502a502.104 502.104 0 00-28.634-6.084c-.33-.052-.468.45-.139.502z"
      ></path>
      <path
        fill="#fff"
        d="M88.851 92.543c.278 1.16.659 3.067.659 5.442 0 6.726-3.016 12.879-3.935 14.734-.589 1.178-1.768 3.432-2.53 6.76a22.118 22.118 0 00-.468 2.634c.693.07 1.75.191 2.998.382 2.808.416 3.987.78 5.634 1.04 1.421.208 3.449.364 6.014.086-.069.642-.19 1.595-.381 2.722-.45 2.808-.676 4.194-1.213 5.252-.832 1.629-1.751 2.097-1.508 2.808.173.52.832.762 2.444 1.126 2.652.607 4.073.676 5.442.936 1.526.295 1.578.538 4.888 1.786 2.86 1.074 4.507 1.698 6.57 2.166.658.156 4.108.919 8.632.746 2.53-.087 3.449-.399 5.824-.191 1.993.173 3.605.607 4.697.936.156-.971.381-2.427.555-4.229.381-3.848.086-4.49.658-6.379.434-1.473.659-1.231 2.34-4.784 1.092-2.323 1.647-3.484 2.063-4.888.243-.849.953-3.38.849-6.76-.104-3.467-.901-2.808-1.213-6.569-.035-.486-.104-1.543-.693-1.768-.434-.174-.954.19-1.56.589a35.466 35.466 0 01-3.363 1.959c-.867.416-2.271.728-4.541.052.242-.78.554-1.855.884-3.172.572-2.34.814-4.004 1.074-5.859 0 0 .59-4.125-.19-6.535-.156-.468-.382-.832-.728-.97-.347-.14-.676 0-1.023.103-.503.157-1.213.295-2.201.295a6.333 6.333 0 00-.676-.97c-.399-.468-1.196-1.248-2.739-1.855-1.907-.763-4.039-.884-6.101-.884-3.502 0-3.762.381-7.159.398-1.785 0-3.293.018-5.321-.294-3.536-.52-3.796-1.283-5.755-1.075-1.37.139-2.635.797-5.165 2.097a53.015 53.015 0 00-3.762 2.133z"
      ></path>
      <path
        fill="#2D3436"
        d="M107.796 105.005c4.108-.589 7.835 1.751 11.579 3.016 3.831 1.283 7.904 1.751 11.943 1.421.329-.034.329-.554 0-.52a29.867 29.867 0 01-12.706-1.716c-3.553-1.317-7.054-3.258-10.954-2.686-.33.034-.191.52.138.485z"
      ></path>
      <path
        fill="#2D3436"
        d="M109.217 105.543c-1.404-.59-2.86-2.826-4.541-1.647-.763.52-1.075 1.491-1.335 2.323-.416 1.317-.728 2.669-1.074 4.021-.694 2.808-1.768 5.373-2.999 7.991-1.127 2.357-2.063 4.697-2.548 7.279-.45 2.41-.676 4.958-1.924 7.124-.347.624-1.352 1.543-.59 2.236.729.676 2.167 1.04 3.086 1.283 1.213.329 2.444.399 3.709.451 1.543.052 2.86.433 4.247 1.04 2.513 1.074 5.009 2.201 7.644 2.946 3.12.884 6.101.902 9.325.694 3.45-.226 6.83.034 10.175.918.139.035.26-.052.312-.173 1.265-3.207.329-6.708 1.231-9.984.901-3.276 3.224-5.928 4.246-9.169 1.006-3.12 1.179-6.483.798-9.724a29.78 29.78 0 00-.902-4.611c-.208-.78-.398-2.669-1.629-2.218-.745.277-1.473.866-2.132 1.317-.728.485-1.456.988-2.219 1.439-1.612.988-3.328 1.768-5.252 1.75-.329 0-.329.52 0 .52 2.791.018 5.131-1.542 7.367-3.033.572-.381 1.144-.78 1.751-1.127.069-.052.346-.26.468-.277.849-.139.572.156.762.485.208.347.278.902.382 1.283.433 1.56.745 3.155.918 4.784.33 3.155.139 6.396-.866 9.429-.988 2.999-3.051 5.477-4.039 8.459-1.144 3.432-.052 7.176-1.404 10.556.104-.052.208-.122.312-.174a30.635 30.635 0 00-7.627-1.022c-2.686-.018-5.373.45-8.06.173-2.617-.277-5.148-1.092-7.592-2.063-2.236-.884-4.524-2.27-6.933-2.6-1.231-.173-2.496-.069-3.727-.277-1.126-.191-2.72-.485-3.622-1.179-.399-.312-.468-.537-.26-1.005.104-.243.33-.468.45-.693.243-.416.451-.832.624-1.283.416-1.023.676-2.097.902-3.189.45-2.271.728-4.541 1.542-6.725.867-2.323 2.098-4.507 3.051-6.795 1.109-2.652 1.577-5.477 2.409-8.233.364-1.196.988-3.328 2.67-2.566.953.434 1.612 1.335 2.582 1.751.364.087.624-.364.312-.485z"
      ></path>
      <path
        fill="#2D3436"
        d="M116.567 111.124a15.054 15.054 0 007.28.693c.329-.052.191-.554-.139-.502a14.582 14.582 0 01-7.002-.694c-.33-.121-.451.382-.139.503zM128.25 109.512c1.75.104 3.466-.104 5.13-.642.312-.104.174-.606-.138-.502-1.63.52-3.276.728-4.992.606-.347 0-.33.52 0 .538zM89.406 92.664c1.456-.884 2.894-1.768 4.35-2.635 1.387-.832 2.74-1.473 4.403-1.421 1.733.07 3.432.641 5.131.953 1.508.26 3.05.416 4.576.468 5.581.156 13.034-2.218 17.246 2.79.208.26.59-.12.364-.363-4.264-5.062-11.63-2.843-17.281-2.93a32.063 32.063 0 01-5.443-.572c-1.629-.329-3.258-.866-4.922-.849-1.508.017-2.808.59-4.09 1.335-1.544.901-3.069 1.855-4.594 2.79-.295.156-.035.607.26.434z"
      ></path>
      <path
        fill="#2D3436"
        d="M118.127 93.808c2.08.243 4.16.19 6.223-.173.953-.174 1.889-.503 2.842-.642.52-.069 1.144-.12 1.612.156.642.364.694 1.18.711 1.838.069 4.628-.659 9.308-1.959 13.745-.086.329.416.468.503.139a49.346 49.346 0 001.595-7.558c.173-1.317.277-2.652.346-3.986.052-1.11.243-2.444-.086-3.519-.538-1.803-2.635-1.387-4.004-1.04-2.566.659-5.166.85-7.783.537-.329-.069-.329.451 0 .503zM88.574 92.42c.052 2.895 1.04 5.686.849 8.615-.173 2.583-1.04 5.044-2.045 7.402-1.907 4.472-4.438 8.753-5.027 13.658-.017.139.139.26.26.26 1.317-.034 2.548.312 3.813.624 1.196.295 2.392.538 3.623.711 2.427.329 4.888.381 7.332.173.33-.034.33-.554 0-.52a32.8 32.8 0 01-7.713-.225c-2.34-.364-4.646-1.317-7.038-1.265l.26.26c.624-5.096 3.398-9.516 5.304-14.196.902-2.202 1.612-4.49 1.786-6.864.19-2.912-.798-5.703-.85-8.615-.034-.364-.554-.364-.554-.017z"
      ></path>
      <path
        fill="#636E72"
        d="M112.598 104.953c.936.121 4.316.589 6.742-.954 1.942-1.23 3.363-3.83 3.363-6.222 0-1.63-.676-3.103.173-3.883.226-.208.364-.173 1.387-.433 2.496-.624 2.825-1.11 3.709-.867 1.04.277 1.578 1.179 1.647 1.3.572.988.312 2.08.173 2.756-.97 4.68-.052 4.819-1.473 10.105a50.658 50.658 0 01-.693 2.34c.728.087 1.698.139 2.842 0 .988-.121 2.375-.294 3.796-1.126 1.422-.832 1.855-1.734 2.496-1.56.572.156.798.988.954 1.56 1.074 4.125 1.04 6.136 1.04 6.136-.087 4.576-.122 6.881-.434 8.025-1.352 5.027-3.848 4.472-4.489 8.719-.399 2.652.381 4.108-.52 7.938-.312 1.3-.676 2.34-.953 3.016a26.56 26.56 0 00-5.53-.953c-3.518-.225-4.87.399-8.285.347-2.981-.035-7.973-.59-8.112-1.82-.225-1.768 10.036-2.67 10.712-6.83.451-2.756-3.675-4.125-5.529-8.805-1.803-4.524-.347-9.36 1.126-12.688 2.098.208 4.195.399 6.31.607-10.47-5.2-12.48-6.604-12.342-6.916.052-.122.694.034 1.89.208z"
        opacity="0.13"
      ></path>
      <path
        fill="#636E72"
        d="M137.124 106.687c-.555.034-.312 3.12-1.387 8.285-.138.659-1.248 5.928-3.709 11.145-1.56 3.311-4.801 8.996-11.319 14.595 3.918.485 7.835.971 11.752 1.473.243-.814.607-2.097.867-3.709.624-3.761.052-4.888.693-7.419.607-2.392 1.335-2.305 2.756-5.529.191-.433 1.595-3.64 2.08-7.332.763-5.911-.97-11.561-1.733-11.509z"
        opacity="0.16"
      ></path>
      <path
        fill="#636E72"
        d="M129.532 99.25c-.346-.103-.97 2.566-3.102 4.663-2.375 2.34-5.668 2.999-8.459 3.103a26.578 26.578 0 006.309 1.733c1.318.191 2.531.26 3.623.26 1.751-7.661 1.924-9.672 1.629-9.758z"
        opacity="0.18"
      ></path>
      <path
        fill="#2D3436"
        d="M38.359 171.011c.485 3.294.97 6.57 1.438 9.863-7.28 9.585-10.452 14.837-9.498 15.739 1.022.97 6.846-3.103 17.472-12.22l-1.092-16.987c-2.774 1.196-5.547 2.409-8.32 3.605z"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M67.08 126.621c-3.83 1.248-11.457 4.177-19.05 10.59-3.206 2.704-6.465 5.495-9.134 10.314-2.288 4.125-2.964 7.626-3.97 13.104a89.34 89.34 0 00-1.455 17.454 101.18 101.18 0 007.28-.797 100.29 100.29 0 008.996-1.716 56.128 56.128 0 011.456-13.225 58.512 58.512 0 012.773-8.736c7.193-6.656 13.867-9.776 18.79-11.371 5.945-1.941 10.867-2.167 14.68-6.621 2.271-2.635 4.733-7.454 3.051-10.192-3.328-5.374-20.87.364-23.417 1.196z"
      ></path>
      <path
        fill="#636E72"
        d="M88.417 123.933c-1.057.208-.763 2.964-1.681 5.443-1.664 4.437-6.621 6.396-10.608 7.973-23.452 9.326-26.035 11.163-28.063 13.988-1.924 2.687-2.756 5.547-4.402 11.267a68.354 68.354 0 00-2.531 14.456l8.632-1.404c-.018-1.907.07-3.952.277-6.101a59.576 59.576 0 013.935-16.138 82.633 82.633 0 014.507-3.466c3.848-2.756 6.62-4.75 10.417-6.292 2.964-1.214 3.206-.78 10.608-3.276 2.045-.694 3.154-1.11 4.507-1.976 3.12-1.994 4.662-4.559 5.356-5.72 1.16-1.976 2.01-3.415 1.68-5.252-.311-1.872-1.715-3.692-2.634-3.502z"
        opacity="0.14"
      ></path>
      <path
        fill="#636E72"
        d="M63.37 146.086c-6.326 1.162-9.706 4.022-11.266 5.634-2.947 3.05-4.16 6.864-6.483 14.352a117.48 117.48 0 00-2.721 10.504c2.167-.278 4.316-.555 6.483-.85.069-3.657.45-6.742.849-9.1.243-1.49.659-3.882 1.595-6.95a50.465 50.465 0 012.34-6.188c3.068-2.462 6.136-4.94 9.204-7.402z"
        opacity="0.16"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M41.67 141.995c.675-.693 1.715-1.612 3.171-2.253 3.883-1.699 7.887-.277 10.296.555 5.39 1.872 11.11 2.842 16.276 5.234 7.817 3.623 18.079 8.39 28.808 10.296 12.029 2.132 14.595-1.109 30.871 1.318 5.997.884 10.85 2.097 14.022 2.998-1.386 2.791-2.825 3.675-3.934 3.935-.746.173-1.127.017-1.682.381-1.386.902-.658 2.947-1.681 4.49-1.005 1.508-2.947 1.369-5.044 1.872-4.264 1.005-4.68 3.518-9.533 6.742-2.358 1.56-6.795 4.49-11.787 4.299-5.356-.208-6.032-3.796-11.977-5.807-7.419-2.496-9.672 2.028-19.639 1.318-2.097-.156-12.428-.884-19.639-8.043-4.922-4.871-4.02-8.424-9.533-14.023-4.212-4.281-8.927-6.5-12.168-7.678.399-.832.85-1.682 1.335-2.566a53.501 53.501 0 011.837-3.068z"
      ></path>
      <path
        fill="#2D3436"
        d="M43.801 141.961c2.41.069 4.75.606 6.95 1.594.226.104.434-.225.191-.329a18.873 18.873 0 00-7.141-1.664c-.26 0-.26.399 0 .399zM49.538 151.789c4.091 2.34 6.258 6.708 9.1 10.278a31.472 31.472 0 0011.285 8.858c.225.104.415-.226.19-.33-4.576-2.149-8.597-5.356-11.665-9.394-2.635-3.467-4.801-7.506-8.702-9.742-.225-.138-.433.208-.208.33zM124.124 163.887c4.888.052 9.776-.225 14.629-.814.243-.035.243-.416 0-.399-4.853.589-9.741.867-14.629.815-.26.017-.26.398 0 .398zM122.65 170.353a44.412 44.412 0 005.495 1.629c.243.052.347-.312.104-.381a45.64 45.64 0 01-5.495-1.63c-.242-.086-.346.295-.104.382zM61.862 158.375c4.282 3.796 9.464 6.812 15.011 8.32 2.79.746 5.633.867 8.459.26 2.981-.641 5.754-1.924 8.406-3.414.226-.122.018-.468-.19-.33-2.531 1.422-5.183 2.652-8.008 3.311-2.964.693-5.928.555-8.858-.277-5.373-1.526-10.365-4.438-14.542-8.13-.19-.19-.468.087-.278.26zM86.008 156.087c1.144.226 2.184.728 3.016 1.56.901.902 1.854 2.999.086 3.588-.242.087-.138.451.104.382 1.352-.451 1.543-1.976.936-3.12-.797-1.508-2.426-2.462-4.038-2.774-.243-.052-.347.33-.104.364zM91.936 166.244c3.726-2.409 8.198-2.877 12.514-3.276.243-.017.243-.416 0-.398-4.385.398-8.926.884-12.705 3.328-.208.138-.017.485.19.346zM55.64 142.965c3.83 1.023 6.846 3.64 10.036 5.842a42.412 42.412 0 0010.746 5.356c.243.086.347-.295.104-.382a41.84 41.84 0 01-11.128-5.65c-3.05-2.15-5.997-4.576-9.672-5.564-.225-.035-.329.329-.086.398z"
      ></path>
      <path
        fill="#636E72"
        d="M51.428 139.066c4.905 2.288 8.424 4.698 10.73 6.483 2.46 1.907 3.258 2.895 5.927 4.559 1.439.901 4.42 2.756 8.65 4.073 5.2 1.629 7.453.919 9.446 3.207.26.294 1.75 1.993 1.525 4.073-.277 2.6-3.068 4.94-7.037 5.529a24.637 24.637 0 003.761-.242 25.58 25.58 0 007.766-2.392c-.607.71-1.23 1.525-1.04 1.768.26.329 1.941-.624 3.12-1.196 2.166-1.058 3.605-1.196 8.718-1.994 11.284-1.75 12.376-2.253 15.93-1.924 2.738.26 5.928.936 5.928 1.526-.018.606-3.536.832-3.519 1.126.035.364 6.275 2.063 12.48-.156 1.404-.502 4.923-1.958 6.153-.641.347.364.52.971 1.127 1.127.728.19 1.491-.451 1.993-.884.538-.451 1.404-1.335 1.994-2.878-.33-.104-.798-.242-1.352-.398-1.179-.33-4.68-1.3-9.759-2.167-1.577-.26-4.524-.763-8.008-1.04-7.297-.589-9.221.329-15.843.156a72.572 72.572 0 01-13.052-1.595c-5.564-1.213-9.568-2.808-16.24-5.529-5.808-2.357-6.466-2.947-10.8-4.715-4.246-1.75-10.486-4.021-18.598-5.876z"
        opacity="0.13"
      ></path>
      <path
        fill="#636E72"
        d="M101.85 176.68a34.237 34.237 0 016.483-1.994 43.513 43.513 0 012.964-.485 65.508 65.508 0 01-.884 1.993 67.774 67.774 0 01-2.479 4.888c-2.028-1.456-4.056-2.929-6.084-4.402z"
        opacity="0.18"
      ></path>
      <path
        fill="#636E72"
        d="M133.206 166.765c-4.403 1.005-7.661.711-7.679.953-.017.208 2.184.607 11.926 1.526.294-.676.606-1.422.884-2.236.26-.763.468-1.491.641-2.167a30.492 30.492 0 01-5.772 1.924z"
        opacity="0.15"
      ></path>
      <path
        fill="#636E72"
        d="M86.164 155.966c-.07.139 1.629.52 2.478 1.993.624 1.092.572 2.358.312 3.45.174-.07 1.179-.486 1.439-1.439.26-.988-.468-1.837-.884-2.323-1.283-1.525-3.276-1.837-3.345-1.681z"
        opacity="0.22"
      ></path>
      <path
        fill="#636E72"
        d="M110.482 156.607c5.998-.589 13.642-.728 22.326.728a86.76 86.76 0 0111.925 2.877 5.534 5.534 0 01-1.127 2.08s-.693.798-1.768 1.352c-2.652 1.37-6.794 0-7.037-.086a27.663 27.663 0 003.761-.798c.78-.225 1.491-.468 2.167-.728a69.032 69.032 0 00-7.523-2.634c-8.649-2.514-16.518-2.999-22.724-2.791z"
        opacity="0.15"
      ></path>
      <path
        fill="#2D3436"
        d="M120.467 162.101c.19-.225.468-.208 1.196-.346a13.249 13.249 0 002.01-.572c2.618-.728 4.524-1.699 5.824-2.479 2.774-1.681 4.022-3.276 5.686-2.912 1.057.243 2.028 1.231 1.941 2.063-.069.71-.867 1.178-4.853 2.686-6.188 2.34-5.616 1.716-7.471 2.687-1.699.901-2.652 1.664-3.519 1.283-.849-.416-1.282-1.872-.814-2.41zM153.14 148.147c-2.548 2.011-2.496 5.287-2.496 5.564a6.962 6.962 0 00.52 2.427c-3.068 1.56-3.571 2.201-3.484 2.496.242.763 4.801.884 6.899-1.681.485-.59.676-1.179 1.04-2.323.728-2.271 1.716-6.847.294-7.592-.814-.416-2.149.624-2.773 1.109z"
      ></path>
      <path
        fill="#2D3436"
        d="M134.784 157.335c.381-.78 1.317-.954 3.051-1.352 1.906-.434 5.13-1.179 6.933-1.855 2.08-.797 3.311-1.907 5.425-3.813 4.23-3.814 4.42-6.171 6.327-6.258 2.375-.104 5.339 3.38 5.044 6.483-.225 2.427-2.357 3.796-6.327 6.327-.918.589-4.888 3.05-11.006 4.385-3.64.797-6.223 1.335-8.026-.173-1.126-.936-1.941-2.67-1.421-3.744z"
      ></path>
      <path
        fill="#2D3436"
        d="M157.63 149.205c-2.548 2.011-2.496 5.287-2.496 5.564a6.962 6.962 0 00.52 2.427c-3.068 1.56-3.571 2.201-3.484 2.496.242.762 4.801.884 6.898-1.682.486-.589.676-1.178 1.04-2.322.728-2.271 1.716-6.847.295-7.592-.832-.434-2.167.624-2.773 1.109zM154.666 145.738c.312-.294.728-.71 1.178-1.248.278-.329 1.318-1.577 2.444-3.986.641-1.352 1.144-2.427 1.491-4.022.329-1.542.607-2.912-.069-4.09-.555-.971-1.196-1.491-1.196-1.491-.572-.45-1.058-.624-1.04-.901.034-.243.468-.451.866-.451 1.214-.017 2.115 2.115 2.271 2.461.797 1.89.502 3.692.364 4.316-.087.364-.087.26-.745 2.236-.642 1.942-.625 1.976-.902 2.756-.936 2.635-1.456 4.091-2.669 4.542-.347.138-1.005.26-1.993-.122zM131.075 160.281c-.607-.295-2.271-1.092-2.358-2.305-.017-.208.174-.954.538-2.427.156-.624.173-.659.242-.971.226-1.022.156-1.23.364-1.698.087-.191.468-1.075.971-1.092.451-.018.953.693 1.144 1.75.867-.017 1.976-.034 2.843-.052.936-.017 2.045-.034 2.964-.052.173-.277.468-.676.901-1.092.416-.398.711-.676.971-.606.346.086.416.797.537 1.698.433 3.138.815 3.398.485 4.247-.346.867-1.04 1.317-1.82 1.82-.156.121-4.09 2.565-7.782.78z"
      ></path>
      <path
        fill="#fff"
        d="M130.537 156.173c.607-.121 1.127.243 1.595.589.069.052.139-.069.069-.104-.502-.381-1.04-.728-1.698-.606-.07 0-.035.138.034.121zM134.68 156.762a3.883 3.883 0 011.855-.762c.087-.018.087-.139 0-.139a3.923 3.923 0 00-1.924.78c-.052.052 0 .173.069.121zM131.664 154.89c.173-.139.381-.191.607-.139.086.018.121-.104.034-.121a.8.8 0 00-.728.173c-.069.052.018.139.087.087zM134.645 154.804a.58.58 0 01.555.069c.069.052.138-.069.069-.104a.703.703 0 00-.641-.069c-.104.017-.07.138.017.104zM133.241 157.508c.018-.018.035-.035.052-.07-.034-.017-.052-.034-.086-.052a.302.302 0 01-.052.243c.017-.017.052-.017.069-.035-.052-.069-.052-.138-.017-.19-.035.017-.07.017-.104.034 0 .07 0 .139.017.208l.104.052c0-.052 0-.121.017-.19h-.138a.83.83 0 01-.087.572.68.68 0 01-.208.242c-.277.139-.277-.225-.277-.346 0-.087-.139-.087-.139 0 0 .156.017.329.139.45.121.104.26.087.381-.017.277-.208.364-.589.295-.919-.018-.069-.122-.052-.122.018v.208c0 .052.07.086.104.052.104-.122.104-.278-.017-.382-.035-.034-.087-.017-.104.035-.052.139-.069.295.104.347.035.017.052 0 .069-.035a.365.365 0 00.07-.347.097.097 0 00-.087-.052c-.104.035-.139.087-.156.191.035.069.173.069.173-.017z"
      ></path>
      <path
        fill="#fff"
        d="M133.19 157.56c-.139.312-.087.814.26.988.381.19.537-.295.572-.572 0-.087-.121-.087-.139 0-.017.121-.017.242-.086.346-.122.191-.33.139-.451-.017-.156-.208-.156-.468-.052-.711.052-.069-.069-.104-.104-.034zM131.751 157.179a12.51 12.51 0 01-1.144-.676c-.069-.052-.139.069-.069.104.364.242.745.468 1.144.676.086.052.156-.07.069-.104zM131.612 157.594a4.34 4.34 0 00-1.161 0c-.087.017-.087.139 0 .139a4.34 4.34 0 011.161 0c.087 0 .087-.139 0-.139zM131.595 157.803a2.059 2.059 0 00-.936.641c-.052.069.034.156.086.087.226-.295.538-.503.884-.607.087-.035.052-.156-.034-.121zM134.576 157.699a3.5 3.5 0 011.785-.607c.087 0 .087-.139 0-.139-.676.052-1.3.26-1.854.624-.07.052 0 .174.069.122zM134.819 158.011a1.849 1.849 0 011.456.139c.069.034.138-.07.069-.104a2.033 2.033 0 00-1.56-.156c-.087.017-.052.138.035.121zM135.027 158.323c.416.086.762.294 1.04.606.052.07.138-.034.086-.086a1.96 1.96 0 00-1.092-.642c-.086-.034-.121.104-.034.122zM139.325 152.602a3.02 3.02 0 00-.45 1.127c0 .052.034.087.086.087.07-.018.07-.035.139-.07-.035.018.347-.26.225 0-.034.052.035.122.087.087.069 0 .139.017.208.017-.052.07.035.156.087.087.052-.069.069-.156 0-.225-.104-.104-.243-.052-.364.017l.086.087c.052-.104.104-.243-.034-.33-.191-.121-.33.156-.468.208l.086.087c.07-.399.226-.763.434-1.092.034-.087-.07-.156-.122-.087zM130.728 152.672c.087.295.121.59.104.884h.139c0-.208-.243-.364-.399-.173-.035.035-.035.087-.069.104-.035.035-.087.052-.139.087a.333.333 0 00-.087.346c.018.087.139.052.122-.034-.018-.156.034-.26.19-.295.035 0 .052-.035.052-.069h.191c0 .086.121.086.139 0 .034-.313 0-.625-.104-.919-.035-.052-.156-.018-.139.069z"
      ></path>
      <path
        fill="#2D3436"
        d="M124.246 164.667c.19-.226.468-.208 1.196-.347a13.132 13.132 0 002.01-.572c2.618-.728 4.524-1.699 5.824-2.479 2.773-1.681 4.021-3.276 5.685-2.912 1.058.243 2.028 1.231 1.942 2.063-.07.711-.867 1.179-4.854 2.687-6.187 2.34-5.615 1.716-7.47 2.686-1.699.902-2.652 1.664-3.519 1.283-.866-.399-1.282-1.855-.814-2.409z"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M75.296 89.405c-1.283-.676-2.947.07-3.866.486-1.629.728-3.466 2.218-5.564 7.124-1.178 2.738-2.669 7.106-3.137 12.93-1.837.798-4.437 1.664-7.61 1.82-3.431.156-5.217-.624-6.17.486-1.196 1.386-.173 4.714 1.699 6.656 2.097 2.184 4.974 2.322 6.898 2.409 1.404.069 6.518.139 11.128-3.38 4.455-3.415 5.651-8.06 7.02-13.295.347-1.49 3.311-13.294-.398-15.236z"
      ></path>
      <path
        fill="#636E72"
        d="M73.875 89.215c-1.508.537-.66 4.87-1.04 9.758-.087 1.041-.694 8.303-3.762 12.671-4.94 7.02-16.38 6.622-19.05 6.483a6.364 6.364 0 001.786 1.976c1.04.728 2.167 1.005 3.658 1.127 1.56.121 4.038.329 7.314-.659 1.699-.52 6.015-1.82 8.91-5.824.797-1.092 1.248-2.167 2.166-4.316.486-1.144 1.647-4.039 2.53-8.251 0 0 .59-2.825.556-9.759 0-.78-.174-1.456-.66-2.062-.554-.763-1.698-1.404-2.408-1.144z"
        opacity="0.12"
      ></path>
      <path
        fill="#636E72"
        d="M75.85 101.885c-.19-.052-.71 2.739-3.102 7.038-1.56 2.825-3.623 6.413-7.42 9.1-3.882 2.738-7.54 3.085-7.505 3.189.035.139 7.437-.104 12.48-4.975 5.634-5.442 5.877-14.265 5.547-14.352z"
        opacity="0.25"
      ></path>
      <path
        fill="#B2BEC3"
        d="M75.521 86.407c-2.41 1.664-3.83 3.501-4.507 4.385-4.004 5.27-4.471 11.007-4.627 13.52-.365 5.582 1.16 6.778 1.369 15.514a82.17 82.17 0 01-.121 6.76c2.114 1.542 6.655 4.437 13.138 5.252 7.956 1.005 14.075-1.699 16.397-2.878 1.352-1.265 3.207-3.189 4.923-5.858 1.768-2.739 2.999-5.668 4.663-12.48a155.545 155.545 0 003.016-15.964c-1.387-1.994-6.275-8.442-15.236-10.747-1.612-.416-11.215-2.877-19.015 2.496z"
      ></path>
      <path
        fill="#636E72"
        d="M93.357 83.669a31.085 31.085 0 011.439 7.505c.364 5.252-.676 9.863-2.895 15.721-3.813 10.106-9.117 18.616-13.364 24.579 1.612.277 3.467.468 5.512.451 5.581-.035 10.07-1.578 13.017-2.895a32.24 32.24 0 003.883-4.524c.624-.867 2.513-3.623 4.247-8.303 3.154-8.493 4.194-19.032 4.42-21.597a26.105 26.105 0 00-8.771-8.13c-2.825-1.611-5.495-2.391-7.488-2.807z"
        opacity="0.09"
      ></path>
      <path
        fill="#636E72"
        d="M109.165 97.587a129.793 129.793 0 01-6.95 15.634c-2.15 4.074-3.38 6.362-5.512 8.944-4.316 5.218-9.499 8.199-13.555 9.932a36.465 36.465 0 005.963-.624 34.829 34.829 0 007.678-2.444 24.8 24.8 0 004.524-4.61c2.08-2.791 3.259-5.651 4.784-11.475a131.502 131.502 0 003.068-15.357z"
        opacity="0.14"
      ></path>
      <path
        fill="#2D3436"
        d="M68.57 155.411c-1.628 2.895-3.258 5.789-4.887 8.684-11.63 3.12-17.35 5.339-17.143 6.639.208 1.404 7.315 1.75 21.286 1.022 3.172-4.697 6.344-9.412 9.533-14.109l-8.788-2.236z"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M99.285 125.442c4.455 2.634 5.148 10.348 3.086 15.704-3.328 8.597-13.4 10.14-13.832 10.192 3.952-4.542 5.91-6.812 5.91-6.83-.017-.017-6.136 6.934-18.356 20.835-1.196-.156-6.17-.919-9.69-5.287-2.044-2.53-2.703-5.182-2.928-6.604l21.874-25.254c4.264-4.022 10.054-5.044 13.936-2.756z"
      ></path>
      <path
        fill="#636E72"
        d="M99.615 125.893c.139 2.253.052 5.65-1.439 9.273-1.907 4.663-5.165 7.367-7.401 9.169-6.483 5.235-13.884 11.111-22.308 17.455.606.572 1.37 1.196 2.323 1.768a13.894 13.894 0 005.2 1.872l12.584-14.144a19.798 19.798 0 004.749-1.317c1.768-.746 4.888-2.115 7.297-5.408 2.08-2.878 2.531-5.738 2.756-7.298.26-1.837.607-4.16-.433-6.846-.919-2.323-2.444-3.796-3.328-4.524z"
        opacity="0.16"
      ></path>
      <path
        fill="#636E72"
        d="M102.925 131.734a27.256 27.256 0 01-3.085 8.84c-3.605 6.379-8.91 9.551-11.37 10.833 1.94-.312 5.997-1.248 9.602-4.524 1.023-.936 3.068-2.825 4.316-6.066 1.525-4.039.884-7.627.537-9.083z"
        opacity="0.19"
      ></path>
      <path
        fill="#636E72"
        d="M94.207 144.439c-.468-.468-7.939 6.084-22.43 19.656 1.37.486 2.722.954 4.091 1.439 12.67-13.607 18.79-20.644 18.339-21.095z"
        opacity="0.12"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M46.8 97.656h.537c.052-.19.104-.399.156-.59.208.434.555 1.145.988 2.029 1.907 3.865 2.288 4.506 2.635 5.668.277.953.606 2.322.71 4.073.33-.225.815-.537 1.44-.884 3.362-1.837 6.17-1.855 8.7-2.305 1.89-.347 4.542-1.11 7.644-2.964-.19.26-.38.537-.572.797.295.104.607.225.902.329.173.226.398.59.59 1.058 1.004 2.478-.486 5.2-1.925 7.696-5.876 10.33-6.483 10.868-7.54 11.388-.433.225-4.801 2.357-7.904.381-.641-.399-1.803-1.352-3.293-8.095-1.023-4.593-1.144-7.158-2.115-13.19-.364-2.288-.71-4.16-.953-5.391z"
      ></path>
      <path
        fill="#2D3436"
        d="M47.788 98.61c1.473 3.154 2.929 6.361 3.38 9.827.017.087.138.087.138 0-.45-3.501-1.906-6.725-3.397-9.897-.052-.07-.156 0-.121.07z"
      ></path>
      <path
        fill="#2D3436"
        d="M47.233 98.054a41.508 41.508 0 012.167 5.668c.017.087.156.052.121-.034a42.017 42.017 0 00-2.184-5.703c-.035-.07-.139 0-.104.07zM49.608 103.619c.572 1.56.953 3.172 1.179 4.819.017.086.138.086.138 0a23.35 23.35 0 00-1.178-4.854c-.035-.069-.174-.034-.139.035zM48.1 101.157a55.969 55.969 0 012.063 6.015c.017.087.155.052.12-.035a55.877 55.877 0 00-2.062-6.014c-.017-.087-.156-.052-.121.034zM52.139 109.269a17.277 17.277 0 017.176-2.739c.086-.017.052-.138-.035-.121-2.6.364-5.027 1.3-7.21 2.738-.07.052 0 .174.069.122z"
      ></path>
      <path
        fill="#2D3436"
        d="M53.213 109.061c2.253-1.369 4.801-1.889 7.332-2.461a49.727 49.727 0 007.852-2.461c.07-.035.035-.156-.035-.122-2.634 1.092-5.356 1.907-8.146 2.531-2.444.555-4.906 1.092-7.072 2.409-.052.035 0 .139.069.104z"
      ></path>
      <path
        fill="#2D3436"
        d="M52.797 109.668c2.67-1.266 5.39-2.046 8.25-2.739 2.809-.693 5.53-1.768 8.269-2.704.087-.035.052-.156-.035-.121-2.825.97-5.65 2.097-8.562 2.773-2.756.659-5.408 1.473-7.991 2.687-.07.034 0 .138.07.104z"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M46.713 97.621c1.265 4.004 2.548 7.991 3.813 11.995l1.872.624c5.928-1.959 11.84-3.9 17.767-5.859.208.173.416.347.624.537.676 8.234 1.37 16.45 2.045 24.683-.242.156-.468.295-.71.451-6.015.918-12.012 1.837-18.027 2.756-.867-.208-1.716-.416-2.583-.624-.762-3.38-1.542-6.743-2.305-10.123l-3.38-23.972c.295-.173.59-.312.884-.468z"
      ></path>
      <path
        fill="#2D3436"
        d="M50.232 109.72c.277 7.505.815 15.028 1.23 22.533.018.243.4.243.4 0-.417-7.505-.954-15.01-1.231-22.533-.018-.243-.4-.243-.4 0zM52.035 110.205c.243 2.133.347 4.265.347 6.414 0 .243.38.243.398 0 0-2.149-.104-4.281-.346-6.414-.035-.26-.434-.26-.4 0z"
      ></path>
      <path
        fill="#0010F7"
        d="M56.767 110.795c.104 1.3.19 2.6.294 3.9 3.83-1.161 7.662-2.34 11.475-3.501-.121-1.352-.243-2.704-.347-4.056-3.813 1.23-7.61 2.444-11.422 3.657z"
      ></path>
      <path
        fill="#636E72"
        d="M62.816 106.982c.069 1.733.017 5.616-2.202 9.793a19.352 19.352 0 01-7.297 7.592c-.07-1.421-.156-2.843-.225-4.247-.59.988-1.162 1.976-1.751 2.964.121 3.086.26 6.171.381 9.274.832.173 1.664.346 2.514.537 5.84-.936 11.7-1.872 17.54-2.808.313-.121.608-.26.92-.381l-1.977-24.995c-2.634.763-5.269 1.508-7.903 2.271z"
        opacity="0.16"
      ></path>
      <path
        fill="#636E72"
        d="M52.121 110.361c-.121 0-.26 3.207-.173 6.691.104 4.957.572 7.124.433 11.856a72.688 72.688 0 01-.208 3.622c.642.087 1.3.174 1.942.26-.122-.849-.295-2.08-.468-3.553-1.352-11.024-1.335-18.876-1.526-18.876z"
        opacity="0.1"
      ></path>
      <path
        fill="#fff"
        d="M46.817 109.669c-.19.069-.173.416-.33 2.028-.103 1.092-.138 1.109-.103 1.369.086.728.346.867.433 1.699.052.537-.052.606.035 1.057.104.555.346.971.537 1.231 0 .173.035.468.173.762.278.538.919 1.006 1.266.832.19-.086.242-.45.329-1.161.087-.659.035-.693.104-1.456.07-.624.121-.745.07-1.057-.053-.382-.191-.503-.33-.902-.19-.554-.104-.762-.225-1.334-.174-.954-.624-1.63-.867-1.994-.243-.398-.78-1.178-1.092-1.074z"
      ></path>
      <path
        fill="#2D3436"
        d="M46.68 109.495c-.936 2.028 0 4.299 2.115 5.044.242.087.346-.295.104-.381-1.872-.659-2.704-2.67-1.872-4.472.104-.208-.243-.416-.347-.191z"
      ></path>
      <path
        fill="#2D3436"
        d="M46.748 109.824a6.452 6.452 0 012.08 4.386c.017.242.398.242.398 0a6.785 6.785 0 00-2.201-4.663c-.19-.173-.468.104-.277.277zM46.245 111.921c-.45 2.028.745 3.953 2.808 4.386.243.052.347-.33.104-.382-1.837-.381-2.947-2.062-2.53-3.917.034-.225-.33-.329-.382-.087z"
      ></path>
      <path
        fill="#2D3436"
        d="M46.609 114.487c-.364 1.542.572 3.016 2.132 3.31.242.052.346-.329.104-.381-1.352-.243-2.184-1.473-1.872-2.825.069-.243-.295-.347-.364-.104z"
      ></path>
      <path
        fill="#2D3436"
        d="M47.112 116.948c-.07.746.364 1.491 1.057 1.768.295.122.676.139.901-.121.243-.277.14-.693 0-.988-.086-.225-.468-.121-.38.104.086.191.26.572-.018.693-.208.087-.538-.121-.694-.242a1.35 1.35 0 01-.485-1.214c.035-.242-.347-.242-.381 0z"
      ></path>
      <path
        fill="#2D3436"
        d="M48.897 117.763c.381 0 .537-.382.59-.711.069-.399 0-.815-.209-1.161-.121-.226-.468-.018-.33.19.122.208.191.434.191.659 0 .121-.034.624-.242.624-.243.017-.243.399 0 .399z"
      ></path>
      <path
        fill="#2D3436"
        d="M49.383 116.169c.242-.226.242-.659.19-.971a1.84 1.84 0 00-.502-1.04c-.174-.191-.451.087-.278.277.174.191.312.416.364.694.035.19.087.606-.07.762-.155.191.122.468.295.278z"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M104.745 91.728c-4.437.07-7.315 6.864-8.181 8.91-1.248 2.964-1.023 4.194-2.825 11.873a163.117 163.117 0 01-1.595 6.292c-1.612.78-4.767 2.011-8.754 1.664-2.374-.208-5.06-.451-6.985-2.409-1.872-1.907-1.681-4.039-3.657-4.698-1.283-.433-2.825-.034-3.016.555-.121.416.45.745 1.179 2.149.26.52.45.954.554 1.283a30.22 30.22 0 01-5.702.971c-3.484.242-5.807-.208-6.5 1.022-.278.486-.382 1.439.086 2.098.243.346.572.52.78.606-.034.052-.537.694-.277 1.491.243.745.919 1.005.988 1.023-.035.086-.33.797.087 1.508.398.676 1.109.814 1.196.814-.07.243-.14.659.034 1.058.26.624.936.901 1.526 1.092 3.172.988 5.789.572 5.789.572 2.964-.468 5.165-1.803 6.448-2.756 1.352 1.23 4.125 3.414 8.25 4.489 1.474.381 6.05 1.525 11.076-.364 5.339-1.993 8.026-6.205 10.349-9.845 2.496-3.918 3.414-7.228 4.628-11.579 1.716-6.171 2.548-9.325 1.005-12.584-1.04-2.322-3.553-5.286-6.483-5.234z"
      ></path>
      <path
        fill="#2D3436"
        d="M59.887 122.061c2.27.746 4.645.832 6.95.278.243-.052.139-.434-.104-.382a12.218 12.218 0 01-6.742-.277c-.243-.069-.347.295-.104.381zM60.823 124.679c2.218.555 4.489.572 6.725.087.243-.052.139-.433-.104-.381a14.516 14.516 0 01-6.517-.087c-.243-.069-.347.312-.104.381zM61.897 126.95c1.994.607 4.04.711 6.067.295.243-.052.139-.434-.104-.382a11.769 11.769 0 01-5.859-.294c-.242-.07-.346.312-.104.381z"
      ></path>
      <path
        fill="#636E72"
        d="M108.767 93.306a52.962 52.962 0 01-2.375 13.503c-2.011 6.413-4.143 13.208-10.573 17.437-1.612 1.057-5.825 3.796-11.597 3.311-4.316-.364-7.418-2.34-8.84-3.398-2.79 1.318-5.2 1.838-6.794 2.046-2.045.277-3.484.19-3.865.156a15.897 15.897 0 01-3.953-.798c.4.451.798.902 1.18 1.335-.053.329-.07.867.242 1.335.346.537.953.745 2.045.953 1.49.277 2.236.416 3.553.399 1.197-.035 2.878-.087 4.888-.867a12.194 12.194 0 003.155-1.82 21.546 21.546 0 003.31 2.288c1.682.953 5.166 2.877 9.95 2.843 1.37-.018 4.541-.07 7.974-1.89 2.2-1.178 3.536-2.565 4.888-4.021 2.842-3.051 4.298-5.945 5.442-8.285 1.82-3.71 2.548-6.535 3.623-10.816 1.3-5.079 1.179-6.622.953-7.73-.537-2.791-2.097-4.802-3.206-5.98z"
        opacity="0.12"
      ></path>
      <path
        fill="#636E72"
        d="M111.453 103.723a71.903 71.903 0 01-5.269 13.26c-3.918 7.575-6.656 9.481-7.61 10.105-3.258 2.115-6.465 2.531-7.817 2.687-8.303.901-14.577-3.605-14.837-3.085-.208.416 3.588 3.588 8.84 4.732 1.56.346 6.222 1.3 11.284-.954 3.744-1.664 5.824-4.246 7.418-6.24 2.288-2.842 3.363-5.39 4.576-8.372 1.179-2.808 2.583-6.916 3.415-12.133z"
        opacity="0.2"
      ></path>
      <path
        fill="#636E72"
        d="M62.278 127.955c1.214.382 2.722.711 4.507.798 4.264.19 7.575-1.231 9.308-2.132-.641.606-3.57 3.241-7.973 3.154-2.843-.052-4.923-1.213-5.842-1.82z"
        opacity="0.12"
      ></path>
      <path
        fill="#fff"
        stroke="#636E72"
        strokeMiterlimit="10"
        d="M74.707 63.18c-.572.97-.711 2.774-.988 6.379-.295 3.9-.087 5.668-.226 8.563-.104 2.27-.416 5.511-1.334 9.446 1.56 2.964 3.432 4.09 4.454 4.559 5.166 2.392 11.631-1.387 15.912-3.9a38.626 38.626 0 004.68-3.224 20.775 20.775 0 01-1.438-3.675c-.746-2.565-.676-4.108-.884-7.002-.503-6.847-.52-8.372-1.56-10.452-.52-1.04-.954-1.942-1.855-2.791-4.316-4.039-13.97-2.583-16.761 2.097z"
      ></path>
      <path
        fill="#2D3436"
        d="M73.372 74.706c-.71.988-2.046 3.12-2.479 6.171a13.859 13.859 0 00.693 6.57c.451 1.23 1.543 4.055 4.472 6.049 1.664 1.144 3.311 1.56 4.3 1.75-.59-.64-1.162-1.282-1.752-1.924a7.827 7.827 0 004.108.971c.607-.035 2.566-.19 4.386-1.577 1.334-1.023 1.89-2.184 2.808-4.195 1.213-2.635 2.062-4.524 2.01-7.003-.069-3.224-1.612-3.986-1.057-7.002.121-.676.503-2.756 1.75-3.068.59-.139 1.231.156 1.578.52.746.78.676 2.374-.433 3.674.537-.12 1.768-.52 2.635-1.664 1.317-1.768.987-4.316-.26-5.772-.313-.364-.885-1.04-1.751-1.144-1.162-.138-2.132.867-3.848 2.895-3.588 4.247-3.536 4.992-5.339 6.31-2.167 1.577-4.125 1.871-4.697 1.958-.97.121-2.427.295-4.022-.433-1.906-.902-2.808-2.496-3.102-3.086z"
      ></path>
      <path
        fill="#2D3436"
        d="M78.659 69.819c-.451 2.253-.711 4.576-.694 6.881 0 .503 0 1.265.624 1.421.659.156 1.63-.658 2.115-.988.277-.19.017-.641-.26-.45-.434.294-1.872 1.352-1.959.346-.052-.745.017-1.542.052-2.288.087-1.612.312-3.206.624-4.784.07-.312-.433-.45-.502-.138zM75.972 71.76c-.26-.416-.278-.919-.018-1.335-.156-.017-.312-.034-.468-.069.191.485.278 1.005.208 1.525.122-.052.26-.104.382-.156-.052-.034-.104-.052-.156-.086-.295-.156-.555.294-.26.45.052.035.104.052.156.087.138.07.364.017.381-.156.087-.624.035-1.23-.208-1.803-.087-.208-.347-.26-.468-.069a1.747 1.747 0 00.017 1.855c.156.312.607.052.434-.243zM83.425 71.604a1.232 1.232 0 01-.173-.606c0-.104.017-.191.034-.295l.052-.156c.07-.104.018-.121-.156-.07-.017.018.122.555.122.607.017.243-.018.486-.07.728.139-.034.295-.069.434-.121-.122-.104-.191-.208-.191-.381-.017-.33-.537-.33-.52 0 .017.294.121.554.347.745.138.121.381.087.433-.121.087-.416.104-.85.035-1.266-.052-.277-.156-.728-.52-.693-.347.035-.451.503-.486.78-.052.381.035.745.226 1.092.156.312.606.052.433-.243z"
      ></path>
      <path
        fill="#2D3436"
        d="M82.957 70.668c.035.312.052.624.087.936.035.33.555.33.52 0-.035-.312-.052-.624-.087-.936-.034-.329-.554-.329-.52 0z"
      ></path>
      <path
        fill="#2D3436"
        d="M82.922 71.379c-.051.225-.069.485.105.641.173.156.45.19.606 0 .087-.104.104-.26 0-.364-.087-.087-.277-.104-.364 0-.035.052.035-.017.07-.035h.034c-.017 0 .035.018 0 0 .017.018.017.035.035.053.017.017 0-.035 0-.018v-.052-.017c0-.035.017-.052.017-.087.035-.138-.034-.294-.173-.312-.139-.017-.295.052-.33.19zM75.4 70.27a2.64 2.64 0 00.052 1.595c.104.312.607.173.503-.14a2.138 2.138 0 01-.052-1.316c.086-.313-.416-.451-.503-.14z"
      ></path>
      <path
        fill="#2D3436"
        d="M75.868 71.17a1.34 1.34 0 01.017-.64c-.173-.053-.33-.088-.503-.14-.069.33-.07.642-.017.971.017.139.19.208.312.173.139-.034.208-.173.173-.312a1.866 1.866 0 01.018-.693c.069-.33-.416-.468-.503-.138-.07.311-.07.606-.017.918.086.33.572.19.52-.139zM74.741 69.282c.278-.815 1.266-.97 1.82-.33.174.191.434-.086.278-.277-.763-.867-2.098-.572-2.462.503-.104.243.278.347.364.104zM82.09 69.281c.624-1.005 2.098-.346 2.462.52.087.226.468.122.381-.104-.485-1.196-2.357-1.906-3.172-.624-.138.226.208.416.33.208zM67.635 64.29c9.533 1.264 19.049 2.512 28.582 3.778.988.139.988-1.439 0-1.56-9.533-1.265-19.05-2.513-28.582-3.779-.988-.139-.988 1.421 0 1.56z"
      ></path>
      <path
        fill="#0010F7"
        d="M74.29 63.63a12.843 12.843 0 012.115-4.922c.537-.762 1.474-2.097 3.085-3.05 3.051-1.82 7.731-1.647 11.111.64 3.31 2.237 4.94 6.189 4.195 10.124-6.83-.936-13.676-1.855-20.506-2.791z"
      ></path>
      <path
        fill="#2D3436"
        d="M83.633 54.566c2.67.537 4.663 2.704 4.906 5.252.208 2.097-.815 4.194-2.635 5.442 2.999.382 5.98.78 8.979 1.162.156-.728.312-1.82.121-3.103-.416-2.912-2.167-4.75-2.947-5.547-.606-.606-2.825-2.808-6.309-3.206a9.455 9.455 0 00-2.115 0z"
      ></path>
      <path
        fill="#636E72"
        d="M85.384 66.647c.277.97.555 2.27.537 3.813a13.42 13.42 0 01-1.768 6.465A7.954 7.954 0 0086.32 75.4c.555-.555.763-.954 1.352-1.82 0 0 .988-1.456 2.461-3.224a28.007 28.007 0 012.6-2.756c-2.461-.33-4.905-.641-7.349-.953z"
        opacity="0.24"
      ></path>
      <path
        fill="#636E72"
        d="M92.421 71.413c-.45.174-.71.52-.884.763-.208.295-.433.71-.59 1.82-.138.953-.276 1.82-.086 2.93.139.831.312.849.59 1.871.12.434.502 1.855.416 3.519-.052 1.265-.347 2.15-.763 3.397a23.22 23.22 0 01-2.045 4.42 112.937 112.937 0 005.806-3.518c.815-.52 1.595-1.04 2.375-1.578a17.102 17.102 0 01-1.23-2.582 18.156 18.156 0 01-1.145-7.263c-.33.139-.658.277-.97.416 1.022-1.612.97-3.276.208-3.9-.347-.312-1.058-.537-1.682-.295z"
        opacity="0.22"
      ></path>
      <path
        fill="#636E72"
        d="M94.466 72.35a11.548 11.548 0 01-.762 2.2c-.277.59-.572 1.093-.85 1.526a7.102 7.102 0 011.734 3.224c.399 1.699.035 3.068-.381 4.576a15.885 15.885 0 01-2.046 4.542c1.63-1.11 3.259-2.202 4.888-3.311a23.958 23.958 0 01-1.699-5.425 23.303 23.303 0 01-.38-4.507c-.417.156-.816.312-1.231.468.19-.19.658-.71.849-1.525a3.178 3.178 0 00-.121-1.768z"
        opacity="0.21"
      ></path>
      <path
        fill="#636E72"
        d="M85.263 66.578c2.062.728 4.142 1.456 6.205 2.184.468-.382.936-.78 1.421-1.162-2.548-.346-5.078-.693-7.626-1.022z"
        opacity="0.19"
      ></path>
    </svg>
  );
}

export default ProfileMenuIcon;
