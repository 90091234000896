import { UserRolesEnum } from "../api";

export type UserRole = typeof UserRolesEnum[keyof typeof UserRolesEnum];

export const UserRoles = {
  [UserRolesEnum.Admin]: 'Администраторы системы',
  [UserRolesEnum.Head]: 'Директорат',
  [UserRolesEnum.Ecolog]: 'Отдел охраны окружающей среды',
  [UserRolesEnum.Chief]: 'Главные экологи',
  [UserRolesEnum.Surveyor]: 'Маркшейдерский отдел',
  [UserRolesEnum.Engineer]: 'Служба главного энергетика',
  [UserRolesEnum.Tailings]: 'Хвостовое хозяйство',
  [UserRolesEnum.Career]: 'Карьер',
  [UserRolesEnum.Mechanical]: 'Служба главного механика',
  [UserRolesEnum.Atc]: 'Автотранспортный цех',
  [UserRolesEnum.Depot]: 'Склад ГСМ',
  [UserRolesEnum.Rsg]: 'Ремонтно-строительная группа',
  [UserRolesEnum.Oks]: 'Отдел капитального строительства',
  [UserRolesEnum.Ahs]: 'Административно-хозяйственная служба',
  [UserRolesEnum.Cl]: 'Центральная лаборатория',
  [UserRolesEnum.Icu]: 'Отдел ИТ и связи',
  [UserRolesEnum.Vet]: 'Производственно-технический отдел',
  [UserRolesEnum.Of]: 'Обогатительная фабрика',
  [UserRolesEnum.Concentrator]: 'Отдел главного обогатителя',
  [UserRolesEnum.Gardening]: 'Рекультивация и озеленение',
  [UserRolesEnum.Peo]: 'Планово-экономический отдел',
  [UserRolesEnum.Accounting]: 'Бухгалтерия',
};

export const UserRolesGroupId = {
  [UserRolesEnum.Admin]: 0,
  [UserRolesEnum.Head]: 1,
  [UserRolesEnum.Ecolog]: 2,
  [UserRolesEnum.Chief]: 2,
  [UserRolesEnum.Surveyor]: 3,
  [UserRolesEnum.Engineer]: 3,
  [UserRolesEnum.Tailings]: 3,
  [UserRolesEnum.Career]: 3,
  [UserRolesEnum.Mechanical]: 3,
  [UserRolesEnum.Atc]: 3,
  [UserRolesEnum.Depot]: 3,
  [UserRolesEnum.Rsg]: 3,
  [UserRolesEnum.Oks]: 3,
  [UserRolesEnum.Ahs]: 3,
  [UserRolesEnum.Cl]: 3,
  [UserRolesEnum.Icu]: 3,
  [UserRolesEnum.Vet]: 3,
  [UserRolesEnum.Of]: 3,
  [UserRolesEnum.Concentrator]: 3,
  [UserRolesEnum.Gardening]: 3,
  [UserRolesEnum.Peo]: 3,
  [UserRolesEnum.Accounting]: 3,
};

export const getPermittedRolesToCreateUser = (role: UserRole) => {
  const id = UserRolesGroupId[role];

  const userRolesGroupIdArray = Object.entries(UserRolesGroupId);
  const permittedRoles = Object.fromEntries(userRolesGroupIdArray.filter(([_, value]) => value >= id));
  const permittedRolesWithLabel = Object.keys(permittedRoles).map((key) => ({ value: key, label: UserRoles[key as UserRole] }));

  return permittedRolesWithLabel;
};

export const getRoleLabel = (role: UserRole) => {
  return { value: role, label: UserRoles[role] };
};
