import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { MaterialTypeEnum, SelectionSource } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectMaterials } from "../../materials/MaterialsSlice";

const SelectionSourcesColumns = (): EntityColumns<SelectionSource>[] => {
  const materials = useAppSelector(selectMaterials);

  return [
    {
      title: "Источник выделения (ИВ)",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Номер ИВ",
      dataIndex: "inventoryNumber",
      key: "inventoryNumber",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Потребляемое сырье/материал",
      dataIndex: "material",
      key: "material",
      inputNode: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          options={materials?.map((material) => {
            const type = material?.type ? (material?.type === MaterialTypeEnum.Fuel ? '(Топливо)' : '(Сырьевой материал)') : null;
            return {
              value: material?.id,
              label: `${material?.name} ${type}`,
            };
          })}
        />
      ),
      render: (material) => {
        const currentMaterial = materials?.find(m => m?.id === material);
        const type = currentMaterial?.type ? (currentMaterial?.type === MaterialTypeEnum.Fuel ? '(Топливо)' : '(Сырьевой материал)') : null;
        return <Text ellipsis={{ tooltip: `${currentMaterial?.name} ${type}` }}>
          {currentMaterial?.name} {type}
        </Text>
      },
      rules: [{ required: false }],
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      inputNode: <TextArea />,
      render: (text) => (
        <Text className="w-64" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      editable: true,
      copyable: true,
    },
  ];
};

export const selectionSourcesColumns = SelectionSourcesColumns;
