import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateDropPeriodDto, DropPeriod, Organization, UpdateDropPeriodDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { DropPeriodForm } from "./components/DropPeriodForm";
import { DropPeriodColumns } from "./form/columns";
import { createDropPeriodAsync, updateDropPeriodAsync, selectDropPeriod, deleteDropPeriodAsync, fetchDropPeriodByOrganizationIdAsync } from "./DropPeriodsSlice";
import { fetchPollutionSubstancesAsync } from "../pollutionSubstances/pollutionSubstancesSlice";
import { fetchInfluenceObjectByOrganizationIdAsync } from "../influenceObjects/InfluenceObjectsSlice";

interface DropPeriodComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const DropPeriodComponent: React.FC<DropPeriodComponentProps> = ({ organization, year, quarter }) => {
  const dispatch = useAppDispatch();
  const dropPeriod = useAppSelector(selectDropPeriod);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year && quarter) {
      dispatch(fetchDropPeriodByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
      dispatch(fetchInfluenceObjectByOrganizationIdAsync(organization.id));
      dispatch(fetchPollutionSubstancesAsync());
    }
  }, [organization, year, quarter, dispatch]);

  const onUpdate = async (dropPeriod: UpdateDropPeriodDto, id: string) => {
    return dispatch(updateDropPeriodAsync({ dropPeriod, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateDropPeriod = (dropPeriod: CreateDropPeriodDto) => {
    if (!organization || !year || !quarter) return;

    dispatch(createDropPeriodAsync({ ...dropPeriod, organizationId: organization.id, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteDropPeriod = (dropPeriod: DropPeriod) => {
    return dispatch(deleteDropPeriodAsync({ dropPeriod })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <DropPeriodForm visible={visible} setVisible={setVisible} onCreateDropPeriod={onCreateDropPeriod} />}
      <EditableTable<DropPeriod, UpdateDropPeriodDto>
        onSave={onUpdate}
        onDelete={deleteDropPeriod}
        entityColumns={DropPeriodColumns()}
        dataSource={structuredClone(dropPeriod).map((data: any) => {
          const influenceObject = data?.influenceObject?.id;
          delete data?.influenceObject;
          data.influenceObject = influenceObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
