import { Button, Input, InputRef, Layout, Space, Table } from "antd";
import { ColumnType, FilterConfirmProps } from "antd/es/table/interface";
import { useEffect, useRef } from "react";
import { Organization } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchTotalAirEmissionByOrganizationIdAsync, fetchTotalDropEmissionByOrganizationIdAsync, selectTotalAirEmission, selectTotalDropEmission } from "../TotalEmissionsSlice";
import { SearchOutlined } from '@ant-design/icons';

interface TotalEmissionProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
  type: 'air' | 'drop';
}

export const TotalEmission: React.FC<TotalEmissionProps> = ({ organization, year, quarter, type }) => {
  const dispatch = useAppDispatch();
  const airTotalEmissions = useAppSelector(selectTotalAirEmission);
  const dropTotalEmissions = useAppSelector(selectTotalDropEmission);

  const searchInput = useRef<InputRef>(null);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  interface DataType {
    name: string;
    code: number;
  }

  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Найти
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Применить
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  useEffect(() => {
    if (organization && year && quarter && type) {
      if (type === 'air') {
        dispatch(fetchTotalAirEmissionByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
      }

      if (type === 'drop') {
        dispatch(fetchTotalDropEmissionByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
      }
    }
  }, [organization, year, quarter, type, dispatch]);

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {
        type === 'air' &&
        <Table
          scroll={{ x: "max-content" }}
          columns={[
            {
              title: 'Код ЗВ',
              dataIndex: 'code',
              key: 'code',
              ...getColumnSearchProps('code'),
            },
            {
              title: 'Наименование ЗВ',
              dataIndex: 'name',
              key: 'name',
              ...getColumnSearchProps('name'),
            },
            {
              title: 'Норматив, г/с',
              dataIndex: 'standardGS',
              key: 'standardGS',
            },
            {
              title: 'Норматив, т/г',
              dataIndex: 'standardTY',
              key: 'standardTY',
            },
            {
              title: 'Факт, г/с',
              dataIndex: 'actualGS',
              key: 'actualGS',
            },
            {
              title: 'Факт, т/г',
              dataIndex: 'actualTY',
              key: 'actualTY',
            },
            {
              title: 'Уловлено, т/г',
              dataIndex: 'caughtTY',
              key: 'caughtTY',
            }
          ]}
          dataSource={
            airTotalEmissions
              .filter(airTotalEmissions => (
                airTotalEmissions.standardGS > 0
                && airTotalEmissions.standardTY > 0
              ))}
          footer={() => {
            return <>
              <p className="mb-0">Всего:</p>
              <ul>
                <li>Норматив, г/с: <b>{airTotalEmissions.reduce((n, { standardGS }) => n + standardGS, 0)}</b></li>
                <li>Норматив, т/г: <b>{airTotalEmissions.reduce((n, { standardTY }) => n + standardTY, 0)}</b></li>
                <li>Факт, г/с: <b>{airTotalEmissions.reduce((n, { actualGS }) => n + actualGS, 0)}</b></li>
                <li>Факт, т/г: <b>{airTotalEmissions.reduce((n, { actualTY }) => n + actualTY, 0)}</b></li>
                <li>Уловлено, т/г: <b>{airTotalEmissions.reduce((n, { caughtTY }) => n + caughtTY, 0)}</b></li>
              </ul>
            </>;
          }}
        />
      }

      {
        type === 'drop' &&
        <Table
          scroll={{ x: "max-content" }}
          columns={[
            {
              title: 'Наименование ЗВ',
              dataIndex: 'name',
              key: 'name',
              filters: dropTotalEmissions
                .filter(dropTotalEmission => (
                  dropTotalEmission.standardTY > 0
                ))
                .map(dropTotalEmission => ({
                  text: dropTotalEmission.name,
                  value: dropTotalEmission.name,
                })),
              onFilter: (value: any, record: any) => record.name.includes(value),
              filterSearch: true,
            },
            {
              title: 'Норматив, т/г',
              dataIndex: 'standardTY',
              key: 'standardTY',
            },
            {
              title: 'Факт, т/г',
              dataIndex: 'actualTY',
              key: 'actualTY',
            },
          ]}
          dataSource={
            dropTotalEmissions
              .filter(dropTotalEmission => (
                dropTotalEmission.standardTY > 0
              ))
          }
          footer={() => {
            return <>
              <p className="mb-0">Всего:</p>
              <ul>
                <li>Норматив, т/г: <b>{dropTotalEmissions.reduce((n, { standardTY }) => n + standardTY, 0)}</b></li>
                <li>Факт, т/г: <b>{dropTotalEmissions.reduce((n, { actualTY }) => n + actualTY, 0)}</b></li>
              </ul>
            </>;
          }}
        />
      }
    </Layout>
  );
};
