import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateAnalyticsProductionForKpiDto, AnalyticsProductionForKpi, Organization, UpdateAnalyticsProductionForKpiDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { AnalyticsProductionForKpisForm } from "./components/AnalyticsProductionForKpisForm";
import { AnalyticsProductionForKpisColumns } from "./form/columns";
import { createAnalyticsProductionForKpisAsync, updateAnalyticsProductionForKpisAsync, selectAnalyticsProductionForKpis, deleteAnalyticsProductionForKpisAsync, fetchAnalyticsProductionForKpisByOrganizationIdAsync } from "./AnalyticsProductionForKpisSlice";
import { fetchWaterDrainageObjectsAsync } from "../../waterDrainageObjects/WaterDrainageObjectsSlice";

interface AnalyticsProductionForKpisComponentProps {
  organization?: Organization;
  year?: number;
}

export const AnalyticsProductionForKpisComponent: React.FC<AnalyticsProductionForKpisComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const analyticsProductionForKpis = useAppSelector(selectAnalyticsProductionForKpis);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchAnalyticsProductionForKpisByOrganizationIdAsync({ organizationId: organization.id, year }));
      dispatch(fetchWaterDrainageObjectsAsync());
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (analyticsProductionForKpi: UpdateAnalyticsProductionForKpiDto, id: string) => {
    return dispatch(updateAnalyticsProductionForKpisAsync({ analyticsProductionForKpi, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateAnalyticsProductionForKpi = (analyticsProductionForKpi: CreateAnalyticsProductionForKpiDto) => {
    if (!organization || !year) return;

    dispatch(createAnalyticsProductionForKpisAsync({ ...analyticsProductionForKpi, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteAnalyticsProductionForKpis = (analyticsProductionForKpi: AnalyticsProductionForKpi) => {
    return dispatch(deleteAnalyticsProductionForKpisAsync({ analyticsProductionForKpi })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <AnalyticsProductionForKpisForm visible={visible} setVisible={setVisible} onCreateAnalyticsProductionForKpi={onCreateAnalyticsProductionForKpi} />}
      <EditableTable<AnalyticsProductionForKpi, UpdateAnalyticsProductionForKpiDto>
        onSave={onUpdate}
        onDelete={deleteAnalyticsProductionForKpis}
        entityColumns={AnalyticsProductionForKpisColumns()}
        dataSource={structuredClone(analyticsProductionForKpis).map((data: any) => {
          const waterDrainageObject = data?.waterDrainageObject?.id;
          delete data?.waterDrainageObject;
          data.waterDrainageObject = waterDrainageObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
