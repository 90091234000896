import { Layout } from "antd";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { history } from "./app/helpers";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { ProtectedRoute } from "./components/route/ProtectedRoute";
import Header from "./components/sidebar/Header";
import Sidebar from "./components/sidebar/Sidebar";
import { fetchMeAsync, selectAuthUser } from "./features/auth/authSlice";
import { OrganizationModuleComponent } from "./features/organizationModule/OrganizatioModuleComponent";
import AirPage from "./pages/AirPage";
import DashboardPage from "./pages/DashboardPage";
import NotFoundPage from "./pages/NotFoundPage";
import OrganizationProfilePage from "./pages/OrganizationProfilePage";
import OrganizationsPage from "./pages/OrganizationsPage";
import ProfilePage from "./pages/ProfilePage";
import { SignInPage } from "./pages/SignInPage";
import UsersPage from "./pages/UsersPage";
import { fetchPollutionSubstancesAsync } from "./features/pollutionSubstances/pollutionSubstancesSlice";
import { CalculationComponent } from "./features/calculations/CalculationComponent";
import { PollutionSourcesComponent } from "./features/pollutionSources/PollutionSourcesComponent";

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <>
      <Routes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

const Main = () => {
  const authUser = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authUser) {
      dispatch(fetchMeAsync());
    }
  }, [dispatch, authUser]);

  useEffect(() => {
    dispatch(fetchPollutionSubstancesAsync());
  }, [dispatch]);

  return (
    <div className="w-screen h-screen overflow-scroll flex">
      <Sidebar authUser={authUser} />
      <div className="flex flex-col grow w-5/6">
        <Header authUser={authUser} />
        <Layout className="h-screen mx-8 my-4 rounded-lg overflow-scroll">
          <Routes>
            <Route path="/" element={<OrganizationsPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/organizations" element={<OrganizationsPage />} />
            <Route path="/organizations/:id" element={<OrganizationProfilePage />} />
            <Route path="/organizations/:id/module/:code/calculation/:calculationId" element={<CalculationComponent />} />
            <Route path="/organizations/:id/module/:code/*" element={<OrganizationModuleComponent />} />
            {/* <Route path="/organizations/:organizationId/module/:code/organization-source/workshop/:workshopId" element={<PollutionSourcesComponent />} /> */}
            <Route path="/reports" element={<div>I am Reports</div>} />
            <Route path="/profile/*" element={<ProfilePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </div>
    </div>
  );
};

export default App;
