import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { Contragent } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const contragentsColumns: EntityColumns<Contragent>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "№ договора и дата заключения",
    dataIndex: "contractNumberAndDateOfConclusion",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Бин",
    dataIndex: "bin",
    inputNode: <Input />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "БИН неправильного формата", pattern: new RegExp("^[0-9]{12}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "Область аккредитации",
    dataIndex: "scopeOfAccreditation",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "№ аттестата аккредитации и срок действия",
    dataIndex: "numberOfAccreditationCertAndValidityPeriod",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
];
