import { Form, Layout, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Organization, UpdateOrganizationDto, UserRolesEnum } from "../../api";
import { fetchOrganizationProfileAsync, selectOrganizationsProfile } from "./organizationProfileSlice";
import { useParams } from "react-router";
import { updateOrganizationAsync } from "../organizations/organizationsSlice";
import { ProDescriptions, ProDescriptionsItemProps } from "@ant-design/pro-components";
import { organizationColumns } from "../organizations/form/columns";
import { SolutionOutlined, LinkOutlined, CalendarOutlined, EnvironmentOutlined, GlobalOutlined, HourglassOutlined, DeleteOutlined, BarChartOutlined, FileTextOutlined, WalletOutlined, ClusterOutlined, PlusOutlined, TeamOutlined, SnippetsOutlined } from '@ant-design/icons';
import { CountInformation } from "../dashboard/components/CountInformation";
import Title from "antd/es/typography/Title";
import { selectAuthUser } from "../auth/authSlice";

export const OrganizationProfileComponent: React.FC = () => {
  let { id } = useParams();

  const organization = useAppSelector(selectOrganizationsProfile);
  const actionRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);

  useEffect(() => {
    if (id) {
      dispatch(fetchOrganizationProfileAsync(id));
    }
  }, [dispatch, id]);

  const onUpdate = async (organization: UpdateOrganizationDto, id: string) => {
    const result = await dispatch(updateOrganizationAsync({ organization, id })).then((res: any) => {
      if (!res.error) return true;
      return false;
    });
    return result;
  };

  const descriptionColumns = organizationColumns.map((orgCol) => {
    const { editable, rules, title, inputNode, ...rest } = orgCol;

    if (orgCol.dataIndex === "productionProcessDescription") return null;

    return { ...rest, formItemProps: { rules }, renderFormItem: () => inputNode, ellipsis: true, title: title.toUpperCase() };
  });

  const gridMenu = [
    {
      informationName: "Справочники",
      code: "directory",
      icon: <SnippetsOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Нормативные данные",
      code: "regulatoryData",
      icon: <LinkOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Данные за период",
      code: "periodData",
      icon: <CalendarOutlined />,
      showDate: false,
      roles: ['surveyor', 'engineer', 'tailings', 'career', 'mechanical', 'atc', 'depot', 'rsg', 'oks', 'ahs', 'cl', 'icu', 'vet', 'of', 'concentrator', 'gardening', 'peo', 'accounting'],
    },
    {
      informationName: "Мониторинг эмиссий",
      code: "emissions",
      icon: <EnvironmentOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "План график контроля",
      code: "laboratoryPoints",
      icon: <GlobalOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Результаты измерений",
      code: "laboratoryJournals",
      icon: <HourglassOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Управление отходами",
      code: "wasteManagement",
      icon: <DeleteOutlined />,
      showDate: false,
      roles: ['surveyor', 'engineer', 'tailings', 'career', 'mechanical', 'atc', 'depot', 'rsg', 'oks', 'ahs', 'cl', 'icu', 'of', 'accounting',],
    },
    {
      informationName: "Аналитика",
      code: "analytics",
      icon: <BarChartOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Отчеты",
      code: "reports",
      icon: <FileTextOutlined />,
      showDate: false,
      roles: [],
    },
    // {
    //   informationName: "Платежи",
    //   code: "#",
    //   icon: <WalletOutlined />,
    //   showDate: false,
    //   roles: [],
    // },
    {
      informationName: "АСМ",
      code: "#",
      icon: <ClusterOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Углеродный след",
      code: "carbon",
      icon: <PlusOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "Контрагенты",
      code: "contragents",
      icon: <TeamOutlined />,
      showDate: false,
      roles: [],
    },
    {
      informationName: "РОП",
      code: "rop",
      icon: <SolutionOutlined />,
      showDate: false,
      roles: [],
    }
  ]

  return (
    <>
      <Title className="ml-2 !mt-0" level={3}>
        Организация «{organization?.name}»
      </Title>

      <Layout className="bg-white p-4 rounded-lg overflow-scroll">
        <ProDescriptions<Organization>
          column={{ sm: 1, md: 2, xl: 3 }}
          actionRef={actionRef}
          editable={{
            form,
            onlyOneLineEditorAlertMessage: "Вы не сохранили предедущее поле",
            onSave: (_, record) => onUpdate(record, record.id),
          }}
          columns={descriptionColumns as ProDescriptionsItemProps<Organization>[]}
          dataSource={organization}
          layout="horizontal"
          tooltip="Информация о организации, вы здесь можете изменять и добавлять сущности"
        >
          <ProDescriptions.Item dataIndex="productionProcessDescription" label="КРАТКОЕ ОПИСАНИЕ ТЕХ ПРОЦЕССА" copyable={true}>
            {organization?.productionProcessDescription}
          </ProDescriptions.Item>
        </ProDescriptions>

        <Row gutter={{ xs: 8, sm: 16, md: 32 }}>
          {
            gridMenu.filter(menuItem => {
              if (menuItem.roles.some(role => authUser?.roles.includes(role as any)) || authUser?.roles.includes(UserRolesEnum.Admin) || authUser?.roles.includes(UserRolesEnum.Chief) || authUser?.roles.includes(UserRolesEnum.Head) || authUser?.roles.includes(UserRolesEnum.Ecolog)) {
                return true;
              } else {
                return false;
              }
            })?.map(menuItem => {
              return (
                <CountInformation informationName={menuItem.informationName} link={`/organizations/${id}/module/${menuItem.code}`} icon={menuItem.icon} showDate={menuItem.showDate} />
              );
            })
          }
        </Row>
      </Layout>
    </>
  );
};
