import { UserOutlined, BellOutlined, MonitorOutlined, LockOutlined } from "@ant-design/icons";
import { Avatar, Layout, Menu } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import React from "react";
import ProfileMenuIcon from "../../assets/ProfileMenuIcon";
import { useAppSelector } from "../../app/hooks";
import { selectAuthUser } from "../auth/authSlice";
import { PersonalInformation } from "./components/Personalnformation";
import { Route, Routes, useNavigate } from "react-router";
import { ActivityMonitoring } from "./components/ActivityMonitoring";

export const Profile: React.FC = () => {
  const user = useAppSelector(selectAuthUser);
  const navigation = useNavigate()

  return (
    <Layout className="bg-white rounded-lg flex flex-row pr-8">
      <div className="w-fit border-solid border-0 !border-r border-gray-300">
        <div className="p-8 flex flex-col content-center items-center">
          <Avatar size={120} className="border-solid border-2 border-gray-400" src="https://joeschmoe.io/api/v1/random" />
          <Title level={3} className="!my-0 mt-4">
            {user?.name}
          </Title>
          <Text>{user?.email}</Text>
        </div>

        <Menu>
          <Menu.Item onClick={() => navigation('information')} icon={<UserOutlined />}>Персональная информация</Menu.Item>
          <Menu.Item icon={<BellOutlined />}>Уведомления</Menu.Item>
          <Menu.Item onClick={() => navigation('activity')} icon={<MonitorOutlined />}>Монитор Активности</Menu.Item>
          <Menu.Item icon={<LockOutlined />}>Пароль</Menu.Item>
        </Menu>
        <div className="px-8 py-2">
          <ProfileMenuIcon />
        </div>
      </div>
      <div className="grow">
        <Routes>
          <Route path="/activity" element={<ActivityMonitoring/> } />
          <Route path="/information" element={<PersonalInformation authUser={user} /> } />
        </Routes>
      </div>
    </Layout>
  );
};
