import { Input, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { Calculation, CalculationStatusEnum } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const statusTags: { [key in CalculationStatusEnum]?: { color: string; text: string } } = {
  [CalculationStatusEnum.Created]: {
    color: "processing",
    text: "Расчет создан",
  },
  [CalculationStatusEnum.Calculated]: {
    color: "success",
    text: "Успешно рассчитан",
  },
  [CalculationStatusEnum.Error]: {
    color: "error",
    text: "Произошла ошибка",
  },
};

export const calculationsColumns: EntityColumns<Calculation>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (text: CalculationStatusEnum) => (
      <Tag color={statusTags[text]?.color} >
        {statusTags[text]?.text}
      </Tag>
    ),
    inputNode: <Input disabled className="w-full" />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: false,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => (
      <Text className="w-64" ellipsis={{ tooltip: text }}>
        {text}
      </Text>
    ),
    editable: true,
    copyable: true,
  },
];
