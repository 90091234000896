import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateRadiologyPointPollutantDto, RadiologyPointPollutantsApi, UpdateRadiologyPointPollutantDto, RadiologyPointPollutant } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface RadiologyPointPollutantsState {
  radiologyPointPollutants: RadiologyPointPollutant[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: RadiologyPointPollutantsState = {
  radiologyPointPollutants: [],
  status: "idle",
};

export const createRadiologyPointPollutantAsync = createAsyncThunk(
  "radiologyPointPollutants/create",
  async (radiologyPointPollutant: CreateRadiologyPointPollutantDto, { rejectWithValue }) => {
    try {
      const radiologyPointPollutantsApi = new RadiologyPointPollutantsApi();
      const response = await radiologyPointPollutantsApi.radiologyPointPollutantsControllerCreate(radiologyPointPollutant);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRadiologyPointPollutantAsync = createAsyncThunk(
  "radiologyPointPollutants/update",
  async ({ radiologyPointPollutant, id }: { radiologyPointPollutant: UpdateRadiologyPointPollutantDto; id: string }, { rejectWithValue }) => {
    try {
      const radiologyPointPollutantsApi = new RadiologyPointPollutantsApi();
      const response = await radiologyPointPollutantsApi.radiologyPointPollutantsControllerUpdate(radiologyPointPollutant, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRadiologyPointPollutantAsync = createAsyncThunk(
  "radiologyPointPollutants/remove",
  async ({ radiologyPointPollutant }: { radiologyPointPollutant: RadiologyPointPollutant }, { rejectWithValue }) => {
    try {
      const radiologyPointPollutantsApi = new RadiologyPointPollutantsApi();
      const response = await radiologyPointPollutantsApi.radiologyPointPollutantsControllerRemove(radiologyPointPollutant.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRadiologyPointPollutantsByPointAsync = createAsyncThunk("radiologyPointPollutants/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const radiologyPointPollutantsApi = new RadiologyPointPollutantsApi();
    const response = await radiologyPointPollutantsApi.radiologyPointPollutantsControllerFindByPointId(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchRadiologyPointPollutantsByPoint = () => {
    const { pending, fulfilled, rejected } = fetchRadiologyPointPollutantsByPointAsync;
    return {
      [`${pending}`]: (state: RadiologyPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<RadiologyPointPollutant[]>) => {
        state.radiologyPointPollutants = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createRadiologyPointPollutant = () => {
    const { pending, fulfilled, rejected } = createRadiologyPointPollutantAsync;
    return {
      [`${pending}`]: (state: RadiologyPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<RadiologyPointPollutant>) => {
        state.radiologyPointPollutants.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateRadiologyPointPollutant = () => {
    const { pending, fulfilled, rejected } = updateRadiologyPointPollutantAsync;

    return {
      [`${pending}`]: (state: RadiologyPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<RadiologyPointPollutant>) => {
        state.radiologyPointPollutants = state.radiologyPointPollutants.map((radiologyPointPollutant) => (radiologyPointPollutant.id === action.payload.id ? action.payload : radiologyPointPollutant));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeRadiologyPointPollutant = () => {
    const { pending, fulfilled, rejected } = deleteRadiologyPointPollutantAsync;
    return {
      [`${pending}`]: (state: RadiologyPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<RadiologyPointPollutant>) => {
        state.radiologyPointPollutants.splice(state.radiologyPointPollutants.indexOf(
          state.radiologyPointPollutants.find((ws: RadiologyPointPollutant) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: RadiologyPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchRadiologyPointPollutantsByPoint(), ...createRadiologyPointPollutant(), ...updateRadiologyPointPollutant(), ...removeRadiologyPointPollutant() };
};

export const radiologyPointPollutantsSlice = createSlice({
  name: "radiologyPointPollutants",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectRadiologyPointPollutants = (state: RootState) => state.radiologyPointPollutants.radiologyPointPollutants;

export default radiologyPointPollutantsSlice.reducer;
