import { Form, Modal } from "antd";
import React from "react";
import { CreateIndustrialSiteDto } from "../../../api";
import { industrialSitesColumns } from "../form/columns";

interface IndustrialSiteFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateIndustrialSite: (values: CreateIndustrialSiteDto) => void;
}

export const IndustrialSiteForm: React.FC<IndustrialSiteFormProps> = ({ visible, setVisible, onCreateIndustrialSite }) => {
  const [form] = Form.useForm<CreateIndustrialSiteDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateIndustrialSite}>
        {industrialSitesColumns.map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
