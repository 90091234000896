import { Layout, Button } from "antd";
import { BASE_PATH } from "../../api/base";

interface ReportInventorizationWasteComponentProps {
    organizationId?: string;
    year?: number;
    quarter?: number;
}

export const ReportInventorizationWasteComponent: React.FC<ReportInventorizationWasteComponentProps> = ({ organizationId, year, quarter }) => {

    const downloadFile = (file: string) => {
      window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
    }
  
    return (
      <Layout className="bg-white p-4 rounded-lg">
        <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/report-static/report-waste/organization/${organizationId}/year/${year}/quarter/${quarter}`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
      </Layout>
    );
  };
  