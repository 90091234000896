import { Input, InputNumber } from "antd";
import { SoilPointPollutant } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const soilPointPollutantsColumns: EntityColumns<SoilPointPollutant>[] = [
  {
    title: "Наименование загрязняющего веществ",
    dataIndex: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: `Предельно допустимая концентрация (мг/кг)`,
    dataIndex: "maxAvailableConcentration",
    key: "maxAvailableConcentration",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: false, message: "no" }],
    editable: true,
    copyable: true,
  },
];
