import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateSoilJournalDto, SoilJournal, UpdateSoilJournalDto } from "../../api";
import { BASE_PATH } from "../../api/base";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { fetchSoilPointsByOrganizationidAsync } from "../soilPoints/SoilPointsSlice";
import { SoilJournalsForm } from "./components/SoilJournalsForm";
import { SoilJournalsColumns } from "./form/columns";
import { createSoilJournalAsync, updateSoilJournalAsync, selectSoilJournals, deleteSoilJournalAsync, fetchSoilJournalsByQuarterAsync } from "./SoilJournalsSlice";
import { SoilJournalForm } from "./components/SoilJournalForm";

interface SoilJournalsComponentProps {
  organizationId?: string;
  year?: number;
  quarter?: number;
}

export const SoilJournalsComponent: React.FC<SoilJournalsComponentProps> = ({ organizationId, year, quarter }) => {
  const dispatch = useAppDispatch();
  const journals = useAppSelector(selectSoilJournals);
  const soilJournals = journals.map((journal) => (
    {
    ...journal,
    point: `№${journal?.pollutant?.point?.no} (${journal?.pollutant?.point?.latitude}° ш. ${journal?.pollutant?.point?.longitude}° д.)` || undefined,
  }));
  
  const [visibleForms, setFormsVisible] = useState(false);
  const [visibleForm, setFormVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      if (year && quarter) {
        dispatch(fetchSoilJournalsByQuarterAsync({ organizationId, year, quarter }));
      } else {
        dispatch(fetchSoilJournalsByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
      }

      dispatch(fetchSoilPointsByOrganizationidAsync(organizationId));
    }
  }, [year, quarter, organizationId, dispatch]);

  const onUpdate = async (soilJournal: UpdateSoilJournalDto, id: string) => {
    return dispatch(updateSoilJournalAsync({ soilJournal, id })).then((res: any) => {
      if (!res.error) {
        if (organizationId) {
          if (year && quarter) {
            dispatch(fetchSoilJournalsByQuarterAsync({ organizationId, year, quarter }));
          } else {
            dispatch(fetchSoilJournalsByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
          }
        }
        return true;
      }
      return false;
    });
  };

  const onCreateSoilJournal = (soilJournal: CreateSoilJournalDto) => {
    if (!organizationId || !year || !quarter) return;

    dispatch(createSoilJournalAsync({ ...soilJournal, organizationId, year, quarter })).then((res: any) => {
      if (!res.error) {
        setFormVisible(false);
        setFormsVisible(false);
      }
    });
  };

  const deleteSoilJournals = (soilJournal: SoilJournal) => {
    return dispatch(deleteSoilJournalAsync({ soilJournal })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButtons = (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Button onClick={() => setFormVisible(true)} block type="dashed" className="">
        + Добавить запись в журнал
      </Button>
      <Button onClick={() => setFormsVisible(true)} block type="dashed">
        + Добавить несколько записей в журнал
      </Button>
    </Space>
  )


  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visibleForms && <SoilJournalsForm visible={visibleForms} setVisible={setFormsVisible} onCreateSoilJournal={onCreateSoilJournal} organizationId={organizationId} />}
      {visibleForm && <SoilJournalForm visible={visibleForm} setVisible={setFormVisible} onCreateSoilJournal={onCreateSoilJournal} organizationId={organizationId} />}
      <div className="table-responsive">
        <EditableTable<SoilJournal, UpdateSoilJournalDto>
          onSave={onUpdate}
          onDelete={deleteSoilJournals}
          entityColumns={SoilJournalsColumns()}
          dataSource={soilJournals}
          footer={() => addButtons}
        />
      </div>
      <Button href={`${BASE_PATH}/soil-journal/report/organization/${organizationId}`} block type="primary" ghost>
        Получить отчёт
      </Button>
    </Layout>
  );
};
