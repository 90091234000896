import { Button, Layout, Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import { CreateSelectionSourceDto, SelectionSource, UpdateSelectionSourceDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { SelectionSourceForm } from "./components/SelectionSourceForm";
import { selectionSourcesColumns } from "./form/columns";
import {
  createSelectionSourceAsync,
  fetchSelectionSourcesAsync,
  removeSelectionSourceAsync,
  selectSelectionSources,
  updateSelectionSourceAsync,
} from "./selectionSourcesSlice";
import { SlidersOutlined, LineHeightOutlined } from "@ant-design/icons";
import { MethodicForm } from "../methodics/component/MethodicForm";
import { SourceMethodics } from "../methodics/SourceMethodics";
import { SelectionSourceStandardComponent } from "../standards/SelectionStandardsComponent";
import { fetchMaterialsAsync } from "../materials/MaterialsSlice";

interface SelectionSourcesComponentProps {
  pollutionSourceId: string;
}

export const SelectionSourcesComponent: React.FC<SelectionSourcesComponentProps> = ({ pollutionSourceId }) => {
  const dispatch = useAppDispatch();
  const selectionSources = useAppSelector(selectSelectionSources);
  const [whichSelectionSource, setWhichSelectionSource] = useState<SelectionSource | undefined>(undefined);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchSelectionSourcesAsync(pollutionSourceId));
    dispatch(fetchMaterialsAsync());
  }, [pollutionSourceId, dispatch]);

  const onCreateSelectionSource = (selectionSource: CreateSelectionSourceDto) => {
    dispatch(createSelectionSourceAsync({ ...selectionSource, pollutionSourceId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdate = async (selectionSource: UpdateSelectionSourceDto, id: string) => {
    return dispatch(updateSelectionSourceAsync({ selectionSource, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onDelete = async (selectionSource: SelectionSource) => {
    return dispatch(removeSelectionSourceAsync(selectionSource.id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <SelectionSourceForm visible={visible} setVisible={setVisible} onCreateSelectionSource={onCreateSelectionSource} />
      <EditableTable<SelectionSource, UpdateSelectionSourceDto>
        onSave={onUpdate}
        onDelete={onDelete}
        onWatch={setWhichSelectionSource}
        entityColumns={selectionSourcesColumns()}
        dataSource={structuredClone(selectionSources).map((data: any) => {
          const material = data?.material?.id;
          delete data?.material;
          data.material = material;
          return data;
        }) as any}
        footer={() => addButton}
      />
      {whichSelectionSource && (
        <Modal
          open={true}
          title={`Источник выбросов - ${whichSelectionSource?.name}`}
          onCancel={() => setWhichSelectionSource(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="Закрыть"
          width={"80%"}
        >
          <Tabs
            destroyInactiveTabPane={true}
            type="card"
            items={[
              {
                label: (
                  <span>
                    <SlidersOutlined /> Методика расчета
                  </span>
                ),
                key: "methodics",
                children: whichSelectionSource && <SourceMethodics selectionSourceId={whichSelectionSource?.id} />,
              },
              {
                label: (
                  <span>
                    <LineHeightOutlined />
                    Нормативы источника
                  </span>
                ),
                key: "selectionSourceStandards",
                children: <SelectionSourceStandardComponent selectionSourceId={whichSelectionSource?.id} />,
              },
            ]}
          />
        </Modal>
      )}
    </Layout>
  );
};
