import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteApplication7RecordDto, WasteApplication7RecordsApi, UpdateWasteApplication7RecordDto, WasteApplication7Record } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WasteApplication7RecordsState {
  wasteApplication7Records: WasteApplication7Record[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteApplication7RecordsState = {
  wasteApplication7Records: [],
  status: "idle",
};

export const createWasteApplication7RecordAsync = createAsyncThunk(
  "wasteApplication7Records/create",
  async (wasteApplication7Record: CreateWasteApplication7RecordDto, { rejectWithValue }) => {
    try {
      const wasteApplication7RecordsApi = new WasteApplication7RecordsApi();
      const response = await wasteApplication7RecordsApi.wasteApplication7RecordsControllerCreate(wasteApplication7Record);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteApplication7RecordAsync = createAsyncThunk(
  "wasteApplication7Records/update",
  async ({ wasteApplication7Record, id }: { wasteApplication7Record: UpdateWasteApplication7RecordDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteApplication7RecordsApi = new WasteApplication7RecordsApi();
      const response = await wasteApplication7RecordsApi.wasteApplication7RecordsControllerUpdate(wasteApplication7Record, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteApplication7RecordAsync = createAsyncThunk(
  "wasteApplication7Records/remove",
  async ({ wasteApplication7Record }: { wasteApplication7Record: WasteApplication7Record }, { rejectWithValue }) => {
    try {
      const wasteApplication7RecordsApi = new WasteApplication7RecordsApi();
      const response = await wasteApplication7RecordsApi.wasteApplication7RecordsControllerRemove(wasteApplication7Record.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteApplication7RecordsByMonthAsync = createAsyncThunk("wasteApplication7Records/fetch", async ({ organizationId, year, quarter, month }: { organizationId: string, year: number, quarter: number, month: number }, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteApplication7RecordsApi();
    const response = await wasteApi.wasteApplication7RecordsControllerFindByMonth(organizationId, year, quarter, month);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteApplication7RecordsByMonth = () => {
    const { pending, fulfilled, rejected } = fetchWasteApplication7RecordsByMonthAsync;
    return {
      [`${pending}`]: (state: WasteApplication7RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication7RecordsState, action: PayloadAction<WasteApplication7Record[]>) => {
        state.wasteApplication7Records = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication7RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteApplication7Record = () => {
    const { pending, fulfilled, rejected } = createWasteApplication7RecordAsync;
    return {
      [`${pending}`]: (state: WasteApplication7RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication7RecordsState, action: PayloadAction<WasteApplication7Record>) => {
        state.wasteApplication7Records.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication7RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteApplication7Record = () => {
    const { pending, fulfilled, rejected } = updateWasteApplication7RecordAsync;

    return {
      [`${pending}`]: (state: WasteApplication7RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication7RecordsState, action: PayloadAction<WasteApplication7Record>) => {
        state.wasteApplication7Records = state.wasteApplication7Records.map((wasteApplication7Record) => (wasteApplication7Record.id === action.payload.id ? action.payload : wasteApplication7Record));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication7RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteApplication7Record = () => {
    const { pending, fulfilled, rejected } = deleteWasteApplication7RecordAsync;
    return {
      [`${pending}`]: (state: WasteApplication7RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication7RecordsState, action: PayloadAction<WasteApplication7Record>) => {
        state.wasteApplication7Records.splice(state.wasteApplication7Records.indexOf(
          state.wasteApplication7Records.find((ws: WasteApplication7Record) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication7RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteApplication7RecordsByMonth(), ...createWasteApplication7Record(), ...updateWasteApplication7Record(), ...removeWasteApplication7Record() };
};

export const wasteApplication7RecordsSlice = createSlice({
  name: "wasteApplication7Records",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteApplication7Records = (state: RootState) => state.wasteApplication7Records.wasteApplication7Records;

export default wasteApplication7RecordsSlice.reducer;
