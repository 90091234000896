import { Form, Modal } from "antd";
import React from "react";
import { CreatePollutionSubstanceDto } from "../../../api";
import { pollutionSubstanceColumns } from "../form/columns";

interface PollutionSubstancesFormProps {
  visible: boolean;
  setVisible: Function;
  onCreatePollutionSubstance: (values: CreatePollutionSubstanceDto) => void;
}

export const PollutionSubstancesForm: React.FC<PollutionSubstancesFormProps> = ({ visible, setVisible, onCreatePollutionSubstance }) => {
  const [form] = Form.useForm<CreatePollutionSubstanceDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreatePollutionSubstance}>
        {pollutionSubstanceColumns.map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
