import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAirSzzPointPollutantDto, AirSzzPointPollutantsApi, UpdateAirSzzPointPollutantDto, AirSzzPointPollutant } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface AirSzzPointPollutantsState {
  airSzzPointPollutants: AirSzzPointPollutant[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AirSzzPointPollutantsState = {
  airSzzPointPollutants: [],
  status: "idle",
};

export const createAirSzzPointPollutantAsync = createAsyncThunk(
  "airSzzPointPollutants/create",
  async (airSzzPointPollutant: CreateAirSzzPointPollutantDto, { rejectWithValue }) => {
    try {
      const airSzzPointPollutantsApi = new AirSzzPointPollutantsApi();
      const response = await airSzzPointPollutantsApi.airSzzPointPollutantsControllerCreate(airSzzPointPollutant);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAirSzzPointPollutantAsync = createAsyncThunk(
  "airSzzPointPollutants/update",
  async ({ airSzzPointPollutant, id }: { airSzzPointPollutant: UpdateAirSzzPointPollutantDto; id: string }, { rejectWithValue }) => {
    try {
      const airSzzPointPollutantsApi = new AirSzzPointPollutantsApi();
      const response = await airSzzPointPollutantsApi.airSzzPointPollutantsControllerUpdate(airSzzPointPollutant, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAirSzzPointPollutantAsync = createAsyncThunk(
  "airSzzPointPollutants/remove",
  async ({ airSzzPointPollutant }: { airSzzPointPollutant: AirSzzPointPollutant }, { rejectWithValue }) => {
    try {
      const airSzzPointPollutantsApi = new AirSzzPointPollutantsApi();
      const response = await airSzzPointPollutantsApi.airSzzPointPollutantsControllerRemove(airSzzPointPollutant.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAirSzzPointPollutantsByPointAsync = createAsyncThunk("airSzzPointPollutants/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const airSzzPointPollutantsApi = new AirSzzPointPollutantsApi();
    const response = await airSzzPointPollutantsApi.airSzzPointPollutantsControllerFindByPointId(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAirSzzPointPollutantsByPoint = () => {
    const { pending, fulfilled, rejected } = fetchAirSzzPointPollutantsByPointAsync;
    return {
      [`${pending}`]: (state: AirSzzPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AirSzzPointPollutant[]>) => {
        state.airSzzPointPollutants = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAirSzzPointPollutant = () => {
    const { pending, fulfilled, rejected } = createAirSzzPointPollutantAsync;
    return {
      [`${pending}`]: (state: AirSzzPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AirSzzPointPollutant>) => {
        state.airSzzPointPollutants.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAirSzzPointPollutant = () => {
    const { pending, fulfilled, rejected } = updateAirSzzPointPollutantAsync;

    return {
      [`${pending}`]: (state: AirSzzPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AirSzzPointPollutant>) => {
        state.airSzzPointPollutants = state.airSzzPointPollutants.map((airSzzPointPollutant) => (airSzzPointPollutant.id === action.payload.id ? action.payload : airSzzPointPollutant));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAirSzzPointPollutant = () => {
    const { pending, fulfilled, rejected } = deleteAirSzzPointPollutantAsync;
    return {
      [`${pending}`]: (state: AirSzzPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AirSzzPointPollutant>) => {
        state.airSzzPointPollutants.splice(state.airSzzPointPollutants.indexOf(
          state.airSzzPointPollutants.find((ws: AirSzzPointPollutant) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAirSzzPointPollutantsByPoint(), ...createAirSzzPointPollutant(), ...updateAirSzzPointPollutant(), ...removeAirSzzPointPollutant() };
};

export const airSzzPointPollutantsSlice = createSlice({
  name: "airSzzPointPollutants",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAirSzzPointPollutants = (state: RootState) => state.airSzzPointPollutants.airSzzPointPollutants;

export default airSzzPointPollutantsSlice.reducer;
