import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteBurialLimitDto, WasteBurialLimitsApi, UpdateWasteBurialLimitDto, WasteBurialLimit } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteBurialLimitsState {
  wasteBurialLimits: WasteBurialLimit[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteBurialLimitsState = {
  wasteBurialLimits: [],
  status: "idle",
};

export const createWasteBurialLimitAsync = createAsyncThunk(
  "wasteBurialLimits/create",
  async (wasteBurialLimit: CreateWasteBurialLimitDto, { rejectWithValue }) => {
    try {
      const wasteBurialLimitsApi = new WasteBurialLimitsApi();
      const response = await wasteBurialLimitsApi.wasteBurialLimitsControllerCreate(wasteBurialLimit);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteBurialLimitAsync = createAsyncThunk(
  "wasteBurialLimits/update",
  async ({ wasteBurialLimit, id }: { wasteBurialLimit: UpdateWasteBurialLimitDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteBurialLimitsApi = new WasteBurialLimitsApi();
      const response = await wasteBurialLimitsApi.wasteBurialLimitsControllerUpdate(wasteBurialLimit, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteBurialLimitAsync = createAsyncThunk(
  "wasteBurialLimits/remove",
  async ({ wasteBurialLimit }: { wasteBurialLimit: WasteBurialLimit }, { rejectWithValue }) => {
    try {
      const wasteBurialLimitsApi = new WasteBurialLimitsApi();
      const response = await wasteBurialLimitsApi.wasteBurialLimitsControllerRemove(wasteBurialLimit.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteBurialLimitsByYearAsync = createAsyncThunk("wasteBurialLimits/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteBurialLimitsApi();
    const response = await wasteApi.wasteBurialLimitsControllerFindByYear(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteBurialLimitsByWasteSite = () => {
    const { pending, fulfilled, rejected } = fetchWasteBurialLimitsByYearAsync;
    return {
      [`${pending}`]: (state: WasteBurialLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialLimitsState, action: PayloadAction<WasteBurialLimit[]>) => {
        state.wasteBurialLimits = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteBurialLimit = () => {
    const { pending, fulfilled, rejected } = createWasteBurialLimitAsync;
    return {
      [`${pending}`]: (state: WasteBurialLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialLimitsState, action: PayloadAction<WasteBurialLimit>) => {
        state.wasteBurialLimits.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteBurialLimit = () => {
    const { pending, fulfilled, rejected } = updateWasteBurialLimitAsync;

    return {
      [`${pending}`]: (state: WasteBurialLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialLimitsState, action: PayloadAction<WasteBurialLimit>) => {
        state.wasteBurialLimits = state.wasteBurialLimits.map((wasteBurialLimit) => (wasteBurialLimit.id === action.payload.id ? action.payload : wasteBurialLimit));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteBurialLimit = () => {
    const { pending, fulfilled, rejected } = deleteWasteBurialLimitAsync;
    return {
      [`${pending}`]: (state: WasteBurialLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteBurialLimitsState, action: PayloadAction<WasteBurialLimit>) => {
        state.wasteBurialLimits.splice(state.wasteBurialLimits.indexOf(
          state.wasteBurialLimits.find((ws: WasteBurialLimit) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteBurialLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteBurialLimitsByWasteSite(), ...createWasteBurialLimit(), ...updateWasteBurialLimit(), ...removeWasteBurialLimit() };
};

export const wasteBurialLimitsSlice = createSlice({
  name: "wasteBurialLimits",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteBurialLimits = (state: RootState) => state.wasteBurialLimits.wasteBurialLimits;

export default wasteBurialLimitsSlice.reducer;
