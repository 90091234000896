import { Input, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { WasteEquipmentRecycle } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const wasteEquipmentRecyclesColumns: EntityColumns<WasteEquipmentRecycle>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Производитель",
    dataIndex: "manufacturer",
    key: "manufacturer",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Инвентарный номер",
    dataIndex: "inventory",
    key: "inventory",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Мощность оборудования т/год",
    dataIndex: "capacity",
    key: "capacity",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Проводимая операция с отходом",
    dataIndex: "operation",
    key: "operation",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
];
