import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterConsumptionObjectDto, WaterConsumptionObjectsApi, UpdateWaterConsumptionObjectDto, WaterConsumptionObject } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WaterConsumptionObjectsState {
  waterConsumptionObjects: WaterConsumptionObject[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterConsumptionObjectsState = {
  waterConsumptionObjects: [],
  status: "idle",
};

export const createWaterConsumptionObjectAsync = createAsyncThunk(
  "waterConsumptionObjects/create",
  async (waterConsumptionObject: CreateWaterConsumptionObjectDto, { rejectWithValue }) => {
    try {
      const waterConsumptionObjectsApi = new WaterConsumptionObjectsApi();
      const response = await waterConsumptionObjectsApi.waterConsumptionObjectsControllerCreate(waterConsumptionObject);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterConsumptionObjectAsync = createAsyncThunk(
  "waterConsumptionObjects/update",
  async ({ waterConsumptionObject, id }: { waterConsumptionObject: UpdateWaterConsumptionObjectDto; id: string }, { rejectWithValue }) => {
    try {
      const waterConsumptionObjectsApi = new WaterConsumptionObjectsApi();
      const response = await waterConsumptionObjectsApi.waterConsumptionObjectsControllerUpdate(waterConsumptionObject, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterConsumptionObjectAsync = createAsyncThunk(
  "waterConsumptionObjects/remove",
  async ({ waterConsumptionObject }: { waterConsumptionObject: WaterConsumptionObject }, { rejectWithValue }) => {
    try {
      const waterConsumptionObjectsApi = new WaterConsumptionObjectsApi();
      const response = await waterConsumptionObjectsApi.waterConsumptionObjectsControllerRemove(waterConsumptionObject.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterConsumptionObjectsAsync = createAsyncThunk(
  "waterConsumptionObjects/fetch", async (_, { rejectWithValue }) => {
    try {
      const waterConsumptionObjectsApi = new WaterConsumptionObjectsApi();
      const response = await waterConsumptionObjectsApi.waterConsumptionObjectsControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchWaterConsumptionObjects = () => {
    const { pending, fulfilled, rejected } = fetchWaterConsumptionObjectsAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<WaterConsumptionObject[]>) => {
        state.waterConsumptionObjects = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterConsumptionObject = () => {
    const { pending, fulfilled, rejected } = createWaterConsumptionObjectAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<WaterConsumptionObject>) => {
        state.waterConsumptionObjects = [action.payload, ...state.waterConsumptionObjects];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterConsumptionObject = () => {
    const { pending, fulfilled, rejected } = updateWaterConsumptionObjectAsync;

    return {
      [`${pending}`]: (state: WaterConsumptionObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<WaterConsumptionObject>) => {
        state.waterConsumptionObjects = state.waterConsumptionObjects.map((WaterConsumptionObject) => (WaterConsumptionObject.id === action.payload.id ? action.payload : WaterConsumptionObject));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterConsumptionObject = () => {
    const { pending, fulfilled, rejected } = deleteWaterConsumptionObjectAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<WaterConsumptionObject>) => {
        state.waterConsumptionObjects.splice(state.waterConsumptionObjects.indexOf(
          state.waterConsumptionObjects.find((ws: WaterConsumptionObject) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterConsumptionObjects(), ...createWaterConsumptionObject(), ...updateWaterConsumptionObject(), ...removeWaterConsumptionObject() };
};

export const waterConsumptionObjectsSlice = createSlice({
  name: "waterConsumptionObjects",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterConsumptionObjects = (state: RootState) => state.waterConsumptionObjects.waterConsumptionObjects;

export default waterConsumptionObjectsSlice.reducer;
