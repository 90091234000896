import { Form, Modal } from "antd";
import React from "react";
import { CreatePermissionDto } from "../../../api";
import { permissionsColumns } from "../form/columns";

interface PermissionFormProps {
  visible: boolean;
  setVisible: Function;
  onCreatePermission: (values: CreatePermissionDto) => void;
}

export const PermissionForm: React.FC<PermissionFormProps> = ({ visible, setVisible, onCreatePermission }) => {
  const [form] = Form.useForm<CreatePermissionDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreatePermission}>
        {permissionsColumns.map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
