import { Button, Form, Input, Modal, Select, Space } from "antd";
import React, { useEffect } from "react";
import { CreateMethodicDto, IncomingParameter, Methodic } from "../../../api";
import Title from "antd/es/typography/Title";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSubstancesInputOptions } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface StaticParametersFormProps {
  onSave: any;
  staticParameters: IncomingParameter[];
}

export const StaticParametersForm: React.FC<StaticParametersFormProps> = ({ staticParameters, onSave }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ staticParameters });
  }, [staticParameters, form]);

  return (
    <Form form={form} layout="vertical" onFinish={(values) => onSave(values)}>
      <Title level={5}>Cтатичные параметры</Title>
      <div className="px-4 py-2">
        <Form.List name="staticParameters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div>
                  <Space key={key}>
                    <Form.Item name={[name, "name"]} rules={[{ required: true, message: "Введите название" }]}>
                      <Input disabled={true} placeholder="Название" />
                    </Form.Item>
                    <Form.Item name={[name, "code"]} rules={[{ required: true, message: "Введите код" }]}>
                      <Input disabled={true} placeholder="Код в формуле" />
                    </Form.Item>

                    <Form.Item name={[name, "value"]} rules={[{ required: true, message: "Введите значение" }]}>
                      <Input type="number" placeholder="Значение" />
                    </Form.Item>
                  </Space>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </div>
      <Button block type="primary" htmlType="submit">Сохранить</Button>
    </Form>
  );
};
