import { AxiosError } from "axios";
import { ApiError, EntityColumns, RouterHistory } from "./types";

export const getFromStorage = <T>(key: string, defaultValue?: T) => {
  const item = localStorage.getItem(key);
  const storageValue = item === null ? defaultValue : JSON.parse(item);
  return storageValue;
};

export const setLocalStorage = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const serializeError = (payload: AxiosError) => {
  if (payload.response?.data) {
    const error = payload.response.data as ApiError;
    error.message = "Неизвестная ошибка, повторите позже";
    return error;
  } else {
    const error = {
      statusCode: 500,
      message: payload.code === "ERR_NETWORK" ? "Проблемы с подключением к серверу" : "Неизвестная ошибка, повторите позже",
    };
    return error;
  }
};

export const moderateDataByColumns = <T>(data: T, columns: EntityColumns<T>[]) => {
  const newData = { ...data };
  columns
    .filter((column) => column.getModeratedData)
    .forEach(({ dataIndex, getModeratedData = () => {} }) => {
      newData[dataIndex] = getModeratedData(data[dataIndex]);
    });
  return newData;
};

export const moderateDataOnSave = <T>(data: Partial<T>, columns: EntityColumns<T>[]) => {
  const newData = { ...data };
  columns
    .filter((column) => column.saveModeratedData)
    .forEach(({ dataIndex, saveModeratedData = () => {} }) => {
      newData[dataIndex] = saveModeratedData(data[dataIndex]);
    });
  return newData;
};

export const history: RouterHistory = {
  navigate: undefined,
  location: undefined,
};

export const debounce = (func: Function, timeout: number = 300) => {
  let timer: NodeJS.Timeout | undefined;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
