import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button, Layout } from "antd";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { FolderOutlined } from '@ant-design/icons';
import { fetchFilesByIdsAsync } from "../files/FilesEntitySlice";
import { FileForm } from "../files/components/FileForm";
import { WasteManagementDocumentsColumns } from "./form/column";
import { createWasteManagementDocumentAsync, deleteWasteManagementDocumentAsync, fetchWasteManagementDocumentByQuarterAsync, selectWasteManagementDocuments, updateWasteManagementDocumentAsync } from "./WasteManagementDocumentSlice";
import { WasteManagementDocumentsForm } from "./components/WasteManagementManagementDocumentsForm";
import { WasteManagementDocument } from "../../api/models/waste-management-document";
import { UpdateWasteManagementDocumentDto } from "../../api/models/update-waste-management-document-dto";
import { CreateWasteManagementDocumentDto } from "../../api/models/create-waste-management-document-dto";


interface WasteManagementDocumentsComponentProps {
    organizationId?: string,
}

export const WasteManagementDocumentsComponent: React.FC<WasteManagementDocumentsComponentProps> = ({ organizationId }) => {
    const dispatch = useAppDispatch();
    const documents: WasteManagementDocument[] = useAppSelector(selectWasteManagementDocuments);
    
    useEffect(() => {
        if (organizationId) {
            dispatch(fetchWasteManagementDocumentByQuarterAsync({ organizationId }));
        }
    }, [organizationId, dispatch]);

    const onUpdate = async (wasteManagementDocument: UpdateWasteManagementDocumentDto, id: string) => {
        return dispatch(updateWasteManagementDocumentAsync({ wasteManagementDocument, id })).then((res: any) => {
            if (!res.error) {
                if (organizationId) {
                        dispatch(fetchWasteManagementDocumentByQuarterAsync({ organizationId }));
                }
                return true;
            }
            return false;
        });
    };

    const onCreateWasteManagementDocument = (wasteManagementDocument: CreateWasteManagementDocumentDto) => {
        if (!organizationId) return;
        console.log(wasteManagementDocument)
        dispatch(createWasteManagementDocumentAsync({ ...wasteManagementDocument, organizationId })).then((res: any) => {
            if (!res.error) {
                setFormVisible(false);
            }
        });
    };

    const deleteWasteManagementDocument = (id: string) => {
        return dispatch(deleteWasteManagementDocumentAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }

    const [visibleForm, setFormVisible] = useState(false);

    const addButton = (
        <Button onClick={() => setFormVisible(true)} block type="dashed" className="">
            + Добавить документ
        </Button>)

    const [visible, setVisible] = useState(false);
    const [wasteManagementDocument, setCurrentWasteManagementDocument] = useState<WasteManagementDocument>();

    const showFileModal = async (document: WasteManagementDocument) => {
        if (document.fileIds.length > 0) {
            await dispatch(fetchFilesByIdsAsync({ ids: document.fileIds.join(',') }));
        }

        setCurrentWasteManagementDocument(document);
        setVisible(true);
    };

    return (
        <Layout className="bg-white p-4 rounded-lg">
            {visibleForm && <WasteManagementDocumentsForm visible={visibleForm} setVisible={setFormVisible} onCreateWasteManagementDocument={onCreateWasteManagementDocument} organizationId={organizationId} />}
            <div className="table-responsive">
                <EditableTable<WasteManagementDocument, UpdateWasteManagementDocumentDto>
                    onSave={(value, id) => onUpdate(value, id)}
                    onDelete={(document) => deleteWasteManagementDocument(document.id)}
                    entityColumns={[
                        ...WasteManagementDocumentsColumns(),
                        {
                            title: "Файл",
                            dataIndex: "btnc" as keyof WasteManagementDocument,
                            key: "btnc",
                            inputNode: <></>,
                            render: (value: any, record: WasteManagementDocument, index: any) => (
                                 <Button.Group>
                                    <Button icon={<FolderOutlined />} onClick={() => showFileModal(record)}></Button>
                                </Button.Group>
                            )
                            ,
                            editable: false,
                            copyable: false,
                        },
                    ]}
                    dataSource={documents}
                    footer={() => addButton}
                />
            </div>
            {visible && <FileForm visible={visible} setVisible={setVisible} onUpdate={onUpdate} fileIds={wasteManagementDocument?.fileIds} id={wasteManagementDocument?.id} maxCount={1}/>}
        </Layout>
    );

}