/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CarbonMobileSource } from '../models';
import { CreateCarbonMobileSourceDto } from '../models';
import { UpdateCarbonMobileSourceDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * CarbonMobileSourceApi - axios parameter creator
 * @export
 */
export const CarbonMobileSourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCarbonMobileSourceDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carbonMobileSourceControllerCreate: async (body: CreateCarbonMobileSourceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling carbonMobileSourceControllerCreate.');
            }
            const localVarPath = `/carbon-mobile-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carbonMobileSourceControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/carbon-mobile-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carbonMobileSourceControllerFindByOrganizationIdYearQuarter: async (organizationId: string, year: number, quarter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling carbonMobileSourceControllerFindByOrganizationIdYearQuarter.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling carbonMobileSourceControllerFindByOrganizationIdYearQuarter.');
            }
            // verify required parameter 'quarter' is not null or undefined
            if (quarter === null || quarter === undefined) {
                throw new RequiredError('quarter','Required parameter quarter was null or undefined when calling carbonMobileSourceControllerFindByOrganizationIdYearQuarter.');
            }
            const localVarPath = `/carbon-mobile-source/organization/{organizationId}/{year}/{quarter}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"quarter"}}`, encodeURIComponent(String(quarter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carbonMobileSourceControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carbonMobileSourceControllerFindOne.');
            }
            const localVarPath = `/carbon-mobile-source/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carbonMobileSourceControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carbonMobileSourceControllerRemove.');
            }
            const localVarPath = `/carbon-mobile-source/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCarbonMobileSourceDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carbonMobileSourceControllerUpdate: async (body: UpdateCarbonMobileSourceDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling carbonMobileSourceControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling carbonMobileSourceControllerUpdate.');
            }
            const localVarPath = `/carbon-mobile-source/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarbonMobileSourceApi - functional programming interface
 * @export
 */
export const CarbonMobileSourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCarbonMobileSourceDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerCreate(body: CreateCarbonMobileSourceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CarbonMobileSource>>> {
            const localVarAxiosArgs = await CarbonMobileSourceApiAxiosParamCreator(configuration).carbonMobileSourceControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<CarbonMobileSource>>>> {
            const localVarAxiosArgs = await CarbonMobileSourceApiAxiosParamCreator(configuration).carbonMobileSourceControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<CarbonMobileSource>>>> {
            const localVarAxiosArgs = await CarbonMobileSourceApiAxiosParamCreator(configuration).carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CarbonMobileSource>>> {
            const localVarAxiosArgs = await CarbonMobileSourceApiAxiosParamCreator(configuration).carbonMobileSourceControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CarbonMobileSource>>> {
            const localVarAxiosArgs = await CarbonMobileSourceApiAxiosParamCreator(configuration).carbonMobileSourceControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateCarbonMobileSourceDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerUpdate(body: UpdateCarbonMobileSourceDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CarbonMobileSource>>> {
            const localVarAxiosArgs = await CarbonMobileSourceApiAxiosParamCreator(configuration).carbonMobileSourceControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CarbonMobileSourceApi - factory interface
 * @export
 */
export const CarbonMobileSourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateCarbonMobileSourceDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerCreate(body: CreateCarbonMobileSourceDto, options?: AxiosRequestConfig): Promise<AxiosResponse<CarbonMobileSource>> {
            return CarbonMobileSourceApiFp(configuration).carbonMobileSourceControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<CarbonMobileSource>>> {
            return CarbonMobileSourceApiFp(configuration).carbonMobileSourceControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<CarbonMobileSource>>> {
            return CarbonMobileSourceApiFp(configuration).carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CarbonMobileSource>> {
            return CarbonMobileSourceApiFp(configuration).carbonMobileSourceControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CarbonMobileSource>> {
            return CarbonMobileSourceApiFp(configuration).carbonMobileSourceControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCarbonMobileSourceDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carbonMobileSourceControllerUpdate(body: UpdateCarbonMobileSourceDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CarbonMobileSource>> {
            return CarbonMobileSourceApiFp(configuration).carbonMobileSourceControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarbonMobileSourceApi - object-oriented interface
 * @export
 * @class CarbonMobileSourceApi
 * @extends {BaseAPI}
 */
export class CarbonMobileSourceApi extends BaseAPI {
    /**
     * 
     * @param {CreateCarbonMobileSourceDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarbonMobileSourceApi
     */
    public async carbonMobileSourceControllerCreate(body: CreateCarbonMobileSourceDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<CarbonMobileSource>> {
        return CarbonMobileSourceApiFp(this.configuration).carbonMobileSourceControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarbonMobileSourceApi
     */
    public async carbonMobileSourceControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<CarbonMobileSource>>> {
        return CarbonMobileSourceApiFp(this.configuration).carbonMobileSourceControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {number} year 
     * @param {number} quarter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarbonMobileSourceApi
     */
    public async carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId: string, year: number, quarter: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<CarbonMobileSource>>> {
        return CarbonMobileSourceApiFp(this.configuration).carbonMobileSourceControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarbonMobileSourceApi
     */
    public async carbonMobileSourceControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CarbonMobileSource>> {
        return CarbonMobileSourceApiFp(this.configuration).carbonMobileSourceControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarbonMobileSourceApi
     */
    public async carbonMobileSourceControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CarbonMobileSource>> {
        return CarbonMobileSourceApiFp(this.configuration).carbonMobileSourceControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateCarbonMobileSourceDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarbonMobileSourceApi
     */
    public async carbonMobileSourceControllerUpdate(body: UpdateCarbonMobileSourceDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CarbonMobileSource>> {
        return CarbonMobileSourceApiFp(this.configuration).carbonMobileSourceControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
