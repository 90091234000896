import { Input, InputNumber } from "antd";
import { EntityColumns } from "../../../app/types";
import { TaxWaste } from "../../../api/models/tax-waste";

export const TaxWastesColumns = (): EntityColumns<TaxWaste>[] => {

  return [
    {
      title: "№п/п",
      dataIndex: "indexNumber",
      key: "indexNumber",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Уровень опасности отхода",
      dataIndex: "wasteDangerLevel",
      key: "wasteDangerLevel",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП)",
      dataIndex: "mciRate",
      key: "mciRate",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];
}