import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { TotalEmissionsApi } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface TotalEmissionState {
  air: any[];
  drop: any[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: TotalEmissionState = {
  air: [],
  drop: [],
  status: "idle",
};

export const fetchTotalAirEmissionByOrganizationIdAsync = createAsyncThunk("totalEmissions/fetchAir", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const totalEmissionsApi = new TotalEmissionsApi();
    const response = await totalEmissionsApi.totalEmissionsControllerAir(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchTotalDropEmissionByOrganizationIdAsync = createAsyncThunk("totalEmissions/fetchDrop", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const totalEmissionsApi = new TotalEmissionsApi();
    const response = await totalEmissionsApi.totalEmissionsControllerDrop(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchTotalAirEmissionByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchTotalAirEmissionByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: TotalEmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TotalEmissionState, action: PayloadAction<any[]>) => {
        state.air = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: TotalEmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchTotalDropEmissionByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchTotalDropEmissionByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: TotalEmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TotalEmissionState, action: PayloadAction<any[]>) => {
        state.drop = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: TotalEmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };


  return { ...fetchTotalAirEmissionByOrganization(), ...fetchTotalDropEmissionByOrganization() };
};

export const totalEmissionsSlice = createSlice({
  name: "totalEmissions",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectTotalAirEmission = (state: RootState) => state.totalEmissions.air;
export const selectTotalDropEmission = (state: RootState) => state.totalEmissions.drop;

export default totalEmissionsSlice.reducer;
