import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteRegisterDto, WasteRegister, UpdateWasteRegisterDto, Organization } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WasteRegisterForm } from "./components/WasteRegisterForm";
import { WasteRegistersColumns } from "./form/columns";
import { createWasteRegisterAsync, updateWasteRegisterAsync, selectWasteRegisters, deleteWasteRegisterAsync, fetchWasteRegistersByYearAsync } from "./WasteRegisterSlice";

interface WasteRegistersComponentProps {
  organization?: Organization;
  year?: number;
}

export const WasteRegistersComponent: React.FC<WasteRegistersComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const wasteRegisters = useAppSelector(selectWasteRegisters);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWasteRegistersByYearAsync({ organizationId: organization.id, year: year }));
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (wasteRegister: UpdateWasteRegisterDto, id: string) => {
    return dispatch(updateWasteRegisterAsync({ wasteRegister, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteRegister = (wasteRegister: CreateWasteRegisterDto) => {
    if (!organization || !year) return;

    dispatch(createWasteRegisterAsync({ ...wasteRegister, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteRegisters = (wasteRegister: WasteRegister) => {
    return dispatch(deleteWasteRegisterAsync({ wasteRegister })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить отход
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteRegisterForm visible={visible} setVisible={setVisible} onCreateWasteRegister={onCreateWasteRegister} />}
      <EditableTable<WasteRegister, UpdateWasteRegisterDto>
        onSave={onUpdate}
        onDelete={deleteWasteRegisters}
        entityColumns={WasteRegistersColumns()}
        dataSource={structuredClone(wasteRegisters).map((data: any) => {
          const permission = data?.permission?.id;
          delete data?.permission;
          data.permission = permission;

          const industrialSite = data?.industrialSite?.id;
          delete data?.industrialSite;
          data.industrialSite = industrialSite;

          const wasteBurialSite = data?.wasteBurialSite?.id;
          delete data?.wasteBurialSite;
          data.wasteBurialSite = wasteBurialSite;
          return data;
        }) as any}

        footer={() => {
          return <>
          <p className="mb-1">Всего: {wasteRegisters.length}</p>
          {addButton}
        </>;
      }}
      />
    </Layout>
  );
};
