import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteEquipmentSortDto, WasteEquipmentSortsApi, UpdateWasteEquipmentSortDto, WasteEquipmentSort } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteEquipmentSortsState {
  wasteEquipmentSorts: WasteEquipmentSort[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteEquipmentSortsState = {
  wasteEquipmentSorts: [],
  status: "idle",
};

export const createWasteEquipmentSortAsync = createAsyncThunk(
  "wasteEquipmentSorts/create",
  async (wasteEquipmentSort: CreateWasteEquipmentSortDto, { rejectWithValue }) => {
    try {
      const wasteEquipmentSortsApi = new WasteEquipmentSortsApi();
      const response = await wasteEquipmentSortsApi.wasteEquipmentSortsControllerCreate(wasteEquipmentSort);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteEquipmentSortAsync = createAsyncThunk(
  "wasteEquipmentSorts/update",
  async ({ wasteEquipmentSort, id }: { wasteEquipmentSort: UpdateWasteEquipmentSortDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteEquipmentSortsApi = new WasteEquipmentSortsApi();
      const response = await wasteEquipmentSortsApi.wasteEquipmentSortsControllerUpdate(wasteEquipmentSort, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteEquipmentSortAsync = createAsyncThunk(
  "wasteEquipmentSorts/remove",
  async ({ wasteEquipmentSort }: { wasteEquipmentSort: WasteEquipmentSort }, { rejectWithValue }) => {
    try {
      const wasteEquipmentSortsApi = new WasteEquipmentSortsApi();
      const response = await wasteEquipmentSortsApi.wasteEquipmentSortsControllerRemove(wasteEquipmentSort.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteEquipmentSortsByOrganizationAsync = createAsyncThunk("wasteEquipmentSorts/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteEquipmentSortsApi();
    const response = await wasteApi.wasteEquipmentSortsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteEquipmentSortsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWasteEquipmentSortsByOrganizationAsync;
    return {
      [`${pending}`]: (state: WasteEquipmentSortsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentSortsState, action: PayloadAction<WasteEquipmentSort[]>) => {
        state.wasteEquipmentSorts = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentSortsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteEquipmentSort = () => {
    const { pending, fulfilled, rejected } = createWasteEquipmentSortAsync;
    return {
      [`${pending}`]: (state: WasteEquipmentSortsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentSortsState, action: PayloadAction<WasteEquipmentSort>) => {
        state.wasteEquipmentSorts.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentSortsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteEquipmentSort = () => {
    const { pending, fulfilled, rejected } = updateWasteEquipmentSortAsync;

    return {
      [`${pending}`]: (state: WasteEquipmentSortsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentSortsState, action: PayloadAction<WasteEquipmentSort>) => {
        state.wasteEquipmentSorts = state.wasteEquipmentSorts.map((wasteEquipmentSort) => (wasteEquipmentSort.id === action.payload.id ? action.payload : wasteEquipmentSort));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentSortsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteEquipmentSort = () => {
    const { pending, fulfilled, rejected } = deleteWasteEquipmentSortAsync;
    return {
      [`${pending}`]: (state: WasteEquipmentSortsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteEquipmentSortsState, action: PayloadAction<WasteEquipmentSort>) => {
        state.wasteEquipmentSorts.splice(state.wasteEquipmentSorts.indexOf(
          state.wasteEquipmentSorts.find((ws: WasteEquipmentSort) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteEquipmentSortsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteEquipmentSortsByOrganization(), ...createWasteEquipmentSort(), ...updateWasteEquipmentSort(), ...removeWasteEquipmentSort() };
};

export const wasteEquipmentSortsSlice = createSlice({
  name: "wasteEquipmentSorts",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteEquipmentSorts = (state: RootState) => state.wasteEquipmentSorts.wasteEquipmentSorts;

export default wasteEquipmentSortsSlice.reducer;
