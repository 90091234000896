import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { RopCalculationsTransportApi } from "../../api/apis/rop-calculations-transport-api";
import { CreateRopCalculationsTransportDto } from "../../api/models/create-rop-calculations-transport-dto";
import { RopCalculationTransport } from "../../api/models/rop-calculation-transport";
import { UpdateRopCalculationsTransportDto } from "../../api/models/update-rop-calculations-transport-dto";

export interface RopCalculationsTransportState {
    ropCalculations: RopCalculationTransport[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: RopCalculationsTransportState = {
    ropCalculations: [],
    status: "idle",
};

export const createRopCalculationTransportAsync = createAsyncThunk(
    "ropCalculationsTransport/create",
    async (ropCalculation: CreateRopCalculationsTransportDto, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsTransportApi();
            const response = await ropCalculationsApi.ropCalculationsTransportControllerCreate(ropCalculation);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateRopCalculationTransportAsync = createAsyncThunk(
    "ropCalculationsTransport/update",
    async ({ ropCalculation, id }: { ropCalculation: UpdateRopCalculationsTransportDto; id: string }, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsTransportApi();
            const response = await ropCalculationsApi.ropCalculationsTransportControllerUpdate(ropCalculation, id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteRopCalculationTransportAsync = createAsyncThunk(
    "ropCalculationsTransport/remove",
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsTransportApi();
            const response = await ropCalculationsApi.ropCalculationsTransportControllerRemove(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchRopCalculationsTransportByQuarterAsync = createAsyncThunk(
    "ropCalculationsTransport/fetch",
    async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
        try {
            const ropCalculationApi = new RopCalculationsTransportApi();
            const response = await ropCalculationApi.ropCalculationsTransportControllerFindByQuart(organizationId, year, quarter);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchRopCalculationsTransportBySite = () => {
        const { pending, fulfilled, rejected } = fetchRopCalculationsTransportByQuarterAsync;
        return {
            [`${pending}`]: (state: RopCalculationsTransportState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsTransportState, action: PayloadAction<RopCalculationTransport[]>) => {
                state.ropCalculations = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsTransportState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const createRopCalculation = () => {
        const { pending, fulfilled, rejected } = createRopCalculationTransportAsync;
        return {
            [`${pending}`]: (state: RopCalculationsTransportState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsTransportState, action: PayloadAction<RopCalculationTransport>) => {
                state.ropCalculations.push(action.payload);
                message.success("Успешно создан");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsTransportState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const updateRopCalculation = () => {
        const { pending, fulfilled, rejected } = updateRopCalculationTransportAsync;

        return {
            [`${pending}`]: (state: RopCalculationsTransportState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsTransportState, action: PayloadAction<RopCalculationTransport>) => {
                state.ropCalculations = state.ropCalculations.map((ropCalculation) => (ropCalculation.id === action.payload.id ? action.payload : ropCalculation));
                message.success("Успешно изменено");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsTransportState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const removeRopCalculation = () => {
        const { pending, fulfilled, rejected } = deleteRopCalculationTransportAsync;
        return {
            [`${pending}`]: (state: RopCalculationsTransportState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsTransportState, action: PayloadAction<RopCalculationTransport>) => {
                state.ropCalculations.splice(state.ropCalculations.indexOf(
                    state.ropCalculations.find((ws: RopCalculationTransport) => ws.id === action.payload.id) || action.payload
                ), 1);
                message.success("Успешно удален");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsTransportState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    return { ...fetchRopCalculationsTransportBySite(), ...createRopCalculation(), ...updateRopCalculation(), ...removeRopCalculation() };
};

export const RopCalculationsTransportSlice = createSlice({
    name: "RopCalculationsTransport",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
});

export const selectRopCalculationsTransport = (state: RootState) => state.ropCalculationsTransport.ropCalculations;

export default RopCalculationsTransportSlice.reducer;
