import { Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { Emission, Standard } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { selectWorkshopStandards } from "../../standards/standardsSlice";

export const EmissionColumns = (): EntityColumns<Emission>[] => {
  const standards = useAppSelector(selectWorkshopStandards);
  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Источник, вещество",
      dataIndex: "standard",
      key: "standardId",
      inputNode: <Select
        showSearch
        filterOption={(input, option) => {
          if (option) {
            const label = option?.label.props.children.reduce((res: any, item: any) => {
              return [...res.props?.children, ...item.props?.children].join('');
            }) || '';
            return (
              label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          } else {
            return true;
          }
        }}
        options={standards?.map(
          (standard) => {
            const label =
              <>
                <div>
                  Источник: {standard.selectionSource?.pollutionSource?.inventoryNumber}-{standard.selectionSource?.inventoryNumber} {standard.selectionSource?.name}
                </div>
                <div>
                  Вещество: {standard.pollutionSubstance?.code} {standard.pollutionSubstance?.name}
                </div>
              </>;
            return {
              value: standard?.id,
              label: label,
            }
          }
        )}
      />,
      render: (standardId: string) => {
        const standard = standards.find(s => s.id === standardId);
        const label =
          <>
            <div>
              Источник: {standard?.selectionSource?.pollutionSource?.inventoryNumber}-{standard?.selectionSource?.inventoryNumber} {standard?.selectionSource?.name}
            </div>
            <div>
              Вещество: {standard?.pollutionSubstance?.code} {standard?.pollutionSubstance?.name}
            </div>
          </>;
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Время работы, час",
      dataIndex: "workTime",
      key: "workTime",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Параметры ГВС, Объемная скорость, м3/с",
      dataIndex: "volumetricVelocity",
      key: "volumetricVelocity",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Норматив, мг/м3",
      dataIndex: "standardMGM3",
      key: "standardMGM3",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Выброс, мг/м3",
      dataIndex: "actualEmissionMGM3",
      key: "actualEmissionMGM3",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Выброс, г/с",
      dataIndex: "actualEmissionGS",
      key: "actualEmissionGS",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: true,
    },
    {
      title: "Выброс, тонн/год",
      dataIndex: "actualEmissionTY",
      key: "actualEmissionTY",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: true,
    },
    {
      title: "Степень очистки",
      dataIndex: "cleaningDegree",
      key: "cleaningDegree",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Уловлено",
      dataIndex: "caughtTY",
      key: "caughtTY",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
