import {
  Col, Layout, Menu, MenuProps, Row,
} from "antd";
import { useState } from "react";
import { SoilJournalsComponent } from "../soilJournals/SoilJournalsComponent";
import { AirSzzJournalsComponent } from "../airSzzJournals/AirSzzJournalsComponent";
import { WaterJournalsComponent } from "../waterJournals/WaterJournalsComponent";
import { RadiologyJournalsComponent } from "../radiologyJournals/RadiologyJournalsComponent";
import { EmissionComponent } from "../emissions/EmissionsComponent";
import { DropResultComponent } from "../dropResults/DropResultsComponent";

interface LaboratoryJournalsComponentProps {
  organizationId?: string;
  year: number;
  quarter: number;
}

type MenuItem = Required<MenuProps>['items'][number];

export const LaboratoryJournalsComponent: React.FC<LaboratoryJournalsComponentProps> = ({ organizationId, year, quarter }) => {

  const [menuSelected, setMenuSelected] = useState<any[]>([getItem('Выбросы', 'emissions')]);

  const menuItems: MenuProps['items'] = [
    getItem('Выбросы', 'emissions'),
    getItem('Сбросы', 'drops'),
    getItem('Атмосферный воздух', 'airSzzJournal'),
    getItem('Водные объекты', 'waterJournal'),
    getItem('Почва', 'siolJournal'),
    getItem('Радиологическая безопасность', 'radiologyJournal'),
  ];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const onClick: MenuProps['onClick'] = (e) => {
    let item: any;
    for (const key of e.keyPath.reverse()) {
      if (item && item.length && item[0] && item[0].children) {
        item = [...item, ...item[0].children.filter((child: any) => child?.key === key)];
      } else {
        item = menuItems.filter((item) => item?.key === key)
      }
    }
    setMenuSelected(item);
  };

  const menuComponents = {
    "emissions": (<EmissionComponent organizationId={organizationId} year={year} quarter={quarter} />),
    "drops": (<DropResultComponent organizationId={organizationId} year={year} quarter={quarter} />),
    "siolJournal": (<SoilJournalsComponent organizationId={organizationId} year={year} quarter={quarter} />),
    "waterJournal": (<WaterJournalsComponent organizationId={organizationId} year={year} quarter={quarter} />),
    "airSzzJournal": (<AirSzzJournalsComponent organizationId={organizationId} year={year} quarter={quarter} />),
    "radiologyJournal": (<RadiologyJournalsComponent organizationId={organizationId} year={year} quarter={quarter} />),
  }

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Row gutter={[24, 0]}>
        <Col span={24} md={6} className="mb-24">
          <Menu
            onClick={onClick}
            style={{ width: 256 }}
            defaultSelectedKeys={['emissions']}
            mode="inline"
            items={menuItems}
          />
        </Col>

        <Col span={24} md={18} className="mb-24">
          <h2>
            {menuSelected.map((menu: any, index) => {
              if (index + 1 >= menuSelected.length) {
                return `${menu?.label}`;
              } else {
                return `${menu?.label} / `;
              }
            })}
          </h2>

          {menuSelected.length > 0 && menuComponents[menuSelected[menuSelected.length - 1].key as keyof typeof menuComponents]}
        </Col>
      </Row>
    </Layout>
  );
};
