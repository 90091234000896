import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateDropCodeDto, DropCode, UpdateDropCodeDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { DropCodeForm } from "./components/DropCodeForm";
import { dropCodesColumns } from "./form/columns";
import { selectDropCodes, updateDropCodeAsync, createDropCodeAsync, deleteDropCodeAsync, fetchDropCodesAsync } from "./DropCodesSlice";

export const DropCodesComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const dropCodes = useAppSelector(selectDropCodes);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchDropCodesAsync());
  }, [dispatch]);

  const onUpdateDropCode = async (dropCode: UpdateDropCodeDto, id: string) => {
    return dispatch(updateDropCodeAsync({ dropCode, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateDropCode = (dropCode: CreateDropCodeDto) => {

    dispatch(createDropCodeAsync({ ...dropCode })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteDropCode = (dropCode: DropCode) => {
    return dispatch(deleteDropCodeAsync({ dropCode })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить вещество
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <DropCodeForm visible={visible} setVisible={setVisible} onCreateDropCode={onCreateDropCode} />}
      <EditableTable<DropCode, UpdateDropCodeDto>
        onSave={onUpdateDropCode}
        onDelete={deleteDropCode}
        entityColumns={dropCodesColumns}
        dataSource={dropCodes}
        footer={() => addButton}
      />
    </Layout>
  );
};
