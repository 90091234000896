import { Layout, Table } from "antd";
import React from "react";
import { CalculationResult, PollutionSubstance, SelectionSource, Standard } from "../../../api";
import { Typography } from 'antd';
const { Text } = Typography;

interface CalculationResultsProps {
  results: CalculationResult[];
}

export const CalculationResults: React.FC<CalculationResultsProps> = ({ results }) => {
  const columns = [
    {
      title: "Источник выбросов",
      dataIndex: "selectionSource",
      key: "pollutionSource",
      render: (source: SelectionSource) => (source?.pollutionSource?.inventoryNumber || "") + " " + (source?.pollutionSource?.name || ""),
    },
    {
      title: "Источник выделения",
      dataIndex: "selectionSource",
      key: "selectionSource",
      render: (source: SelectionSource) => source?.inventoryNumber + " " + source?.name,
    },
    {
      title: "Вещество",
      dataIndex: "pollutionSubstance",
      key: "pollutionSubstance",
      render: (pollutionSubstance: PollutionSubstance) => pollutionSubstance?.code + " " + pollutionSubstance?.name,
    },
    {
      title: "Норматив г/с",
      dataIndex: "standard",
      key: "valueGramPerSecond",
      render: (standard: Standard) => standard?.valueGramPerSecond,
    },
    {
      title: "Норматив т/г",
      dataIndex: "standard",
      key: "valueTonPerYear",
      render: (standard: Standard) => standard?.valueTonPerYear,
    },
    {
      title: "Фактический г/с",
      dataIndex: "gramPerSecValue",
      key: "gramPerSecValue",
    },
    {
      title: "Фактический т/г",
      dataIndex: "tonPerYearValue",
      key: "tonPerYearValue",
    },
    {
      title: "Превышение т/г",
      dataIndex: "overYear",
      key: "overYear",
    },
  ];

  console.log(results);

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Table scroll={{ x: 'calc(700px + 50%)', y: 240 }} dataSource={results.map(result => {
        const overSeconds = (result.gramPerSecValue - (result.standard?.valueGramPerSecond || 0)) > 0 ? <Text type="danger">Да</Text> : <Text type="success">Нет</Text>;
        const overYear = (result.tonPerYearValue - (result.standard?.valueTonPerYear || 0)) > 0 ? <Text type="danger">Да</Text> : <Text type="success">Нет</Text>;
        return { ...result, overSeconds, overYear };
      })} columns={columns} />
    </Layout>
  );
};
