import { Form, Modal } from "antd";
import React from "react";
import { TaxWastesColumns } from "../form/columns";
import { CreateTaxWasteDto } from "../../../api/models/create-tax-waste-dto";

interface TaxWasteFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateTaxWaste: (values: CreateTaxWasteDto) => void;
}

export const TaxWasteForm: React.FC<TaxWasteFormProps> = ({ visible, setVisible, onCreateTaxWaste }) => {
  const [form] = Form.useForm<CreateTaxWasteDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateTaxWaste}>
        {TaxWastesColumns().map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
          {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
