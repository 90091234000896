import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterConsumptionHouseholdDto, WaterConsumptionHouseholdApi, UpdateWaterConsumptionHouseholdDto, WaterConsumptionHousehold } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WaterConsumptionHouseholdState {
  waterConsumptionHousehold: WaterConsumptionHousehold[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterConsumptionHouseholdState = {
  waterConsumptionHousehold: [],
  status: "idle",
};

export const createWaterConsumptionHouseholdAsync = createAsyncThunk(
  "waterConsumptionHousehold/create",
  async (waterConsumptionHousehold: CreateWaterConsumptionHouseholdDto, { rejectWithValue }) => {
    try {
      const waterConsumptionHouseholdApi = new WaterConsumptionHouseholdApi();
      const response = await waterConsumptionHouseholdApi.waterConsumptionHouseholdControllerCreate(waterConsumptionHousehold);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterConsumptionHouseholdAsync = createAsyncThunk(
  "waterConsumptionHousehold/update",
  async ({ waterConsumptionHousehold, id }: { waterConsumptionHousehold: UpdateWaterConsumptionHouseholdDto; id: string }, { rejectWithValue }) => {
    try {
      const waterConsumptionHouseholdApi = new WaterConsumptionHouseholdApi();
      const response = await waterConsumptionHouseholdApi.waterConsumptionHouseholdControllerUpdate(waterConsumptionHousehold, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterConsumptionHouseholdAsync = createAsyncThunk(
  "waterConsumptionHousehold/remove",
  async ({ waterConsumptionHousehold }: { waterConsumptionHousehold: WaterConsumptionHousehold }, { rejectWithValue }) => {
    try {
      const waterConsumptionHouseholdApi = new WaterConsumptionHouseholdApi();
      const response = await waterConsumptionHouseholdApi.waterConsumptionHouseholdControllerRemove(waterConsumptionHousehold.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterConsumptionHouseholdByOrganizationIdAsync = createAsyncThunk("waterConsumptionHousehold/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const waterConsumptionHouseholdApi = new WaterConsumptionHouseholdApi();
    const response = await waterConsumptionHouseholdApi.waterConsumptionHouseholdControllerFindByOrganizationid(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterConsumptionHouseholdByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWaterConsumptionHouseholdByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<WaterConsumptionHousehold[]>) => {
        state.waterConsumptionHousehold = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterConsumptionHousehold = () => {
    const { pending, fulfilled, rejected } = createWaterConsumptionHouseholdAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<WaterConsumptionHousehold>) => {
        state.waterConsumptionHousehold.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterConsumptionHousehold = () => {
    const { pending, fulfilled, rejected } = updateWaterConsumptionHouseholdAsync;

    return {
      [`${pending}`]: (state: WaterConsumptionHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<WaterConsumptionHousehold>) => {
        state.waterConsumptionHousehold = state.waterConsumptionHousehold.map((waterConsumptionHousehold) => (waterConsumptionHousehold.id === action.payload.id ? action.payload : waterConsumptionHousehold));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterConsumptionHousehold = () => {
    const { pending, fulfilled, rejected } = deleteWaterConsumptionHouseholdAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionHouseholdState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<WaterConsumptionHousehold>) => {
        state.waterConsumptionHousehold.splice(state.waterConsumptionHousehold.indexOf(
          state.waterConsumptionHousehold.find((ws: WaterConsumptionHousehold) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionHouseholdState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterConsumptionHouseholdByOrganization(), ...createWaterConsumptionHousehold(), ...updateWaterConsumptionHousehold(), ...removeWaterConsumptionHousehold() };
};

export const waterConsumptionHouseholdSlice = createSlice({
  name: "waterConsumptionHousehold",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterConsumptionHousehold = (state: RootState) => state.waterConsumptionHousehold.waterConsumptionHousehold;

export default waterConsumptionHouseholdSlice.reducer;
