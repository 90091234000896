import { Input, InputNumber } from "antd";
import { RadiologyPointPollutant } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const radiologyPointPollutantsColumns: EntityColumns<RadiologyPointPollutant>[] = [
  {
    title: "Определяемый показатель",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: `Установленный норматив микрозиверт в час (мкЗв/час)`,
    dataIndex: "maxAvailableConcentration",
    key: "maxAvailableConcentration",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: false, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
];
