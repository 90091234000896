import React from "react";
import textLogo from './polymetallogowithtext.png';

function LogoWithText() {
  return (
    <img src={textLogo} height={35} />
  );
}

export default LogoWithText;
