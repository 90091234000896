import { Checkbox, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { CategoryVariable } from "../../../api";
import { EntityColumns } from "../../../app/types";

const CategoryVariablesColumns = (): EntityColumns<CategoryVariable>[] => {

  return [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Код переменной",
      dataIndex: "variable",
      key: "variable",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => (
        <Text className="w-64" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      editable: true,
      copyable: true,
    },
  ];
};

export const categoryVariablesColumns = CategoryVariablesColumns;
