import {
  Button, Col, Layout, Menu, MenuProps, Row, Space,
} from "antd";
import { useState, useEffect } from "react";
import { UpdateContragentDto, CreateContragentDto, Contragent } from "../../api";
import { BASE_PATH } from "../../api/base";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { ContragentForm } from "./components/ContragentForm";
import { contragentsColumns } from "./form/columns";
import { createContragentsAsync, updateContragentAsync, fetchContragentsByOrganizationAsync, selectContragents, deleteContragentAsync } from "./ContragentsSlice";
import { WasteContragentsComponent } from "../wasteContragents/WasteContragentsComponent";

interface ContragentsComponentProps {
  organizationId?: string;
}

type MenuItem = Required<MenuProps>['items'][number];

export const ContragentsComponent: React.FC<ContragentsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const contragents = useAppSelector(selectContragents);

  const [visible, setVisible] = useState(false);
  const [menuSelected, setMenuSelected] = useState<any[]>([getItem('Лаборатории', 'lab_contragents')]);

  const menuItems: MenuProps['items'] = [
    getItem('Лаборатории', 'lab_contragents'),
    getItem('Отходы', 'waste_contragents'),

  ];

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchContragentsByOrganizationAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateContragent = async (contragent: UpdateContragentDto, id: string) => {
    return dispatch(updateContragentAsync({ contragent, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateContragent = (contragent: CreateContragentDto) => {
    if (!organizationId) return;

    dispatch(createContragentsAsync({ ...contragent, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteContragents = (contragent: Contragent) => {
    return dispatch(deleteContragentAsync({ contragent })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const onClick: MenuProps['onClick'] = (e) => {
    let item: any;
    for (const key of e.keyPath.reverse()) {
      if (item && item.length && item[0] && item[0].children) {
        item = [...item, ...item[0].children.filter((child: any) => child?.key === key)];
      } else {
        item = menuItems.filter((item) => item?.key === key)
      }
    }
    setMenuSelected(item);
  };

  const addButtonContragent = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить контрагента
      </Button>
      {
        !!contragents.length &&
        <Button href={`${BASE_PATH}/contragents/report/organization/${organizationId}`} block type="primary" ghost>
          Получить отчёт
        </Button>
      }
    </Space>
  );

  const menuComponents = {
    "lab_contragents": (
      <>
        <ContragentForm visible={visible} setVisible={setVisible} onCreateContragent={onCreateContragent} />
        <EditableTable<Contragent, UpdateContragentDto>
          onSave={onUpdateContragent}
          onDelete={deleteContragents}
          entityColumns={
            [...contragentsColumns]
          }
          dataSource={contragents}
          footer={() => addButtonContragent}
        />
      </>
    ),
    "waste_contragents": (
    <>
    <WasteContragentsComponent organizationId={organizationId}/>
    </>
    ),
    }

  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Row gutter={[24, 0]}>
          <Col span={24} md={6} className="mb-24">
            <Menu
              onClick={onClick}
              style={{ width: 256 }}
              defaultSelectedKeys={['lab_contragents']}
              mode="inline"
              items={menuItems}
            />
          </Col>

          <Col span={24} md={18} className="mb-24">
            <h2>
              {menuSelected.map((menu: any, index) => {
                if (index + 1 >= menuSelected.length) {
                  return `${menu?.label}`;
                } else {
                  return `${menu?.label} / `;
                }
              })}
            </h2>

            {menuSelected.length > 0 && menuComponents[menuSelected[menuSelected.length - 1].key as keyof typeof menuComponents]}
          </Col>
        </Row>
      </Layout>
    </>
  );
};
