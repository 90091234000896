import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateReportPekDto, ReportPekApi, UpdateReportPekDto, ReportPek } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface ReportPeksState {
  reportPeks: ReportPek[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: ReportPeksState = {
  reportPeks: [],
  status: "idle",
};

export const generateReportPekAsync = createAsyncThunk(
  "reportPeks/generate",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const reportPeksApi = new ReportPekApi();
      const response = await reportPeksApi.reportPekControllerGenerate(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createReportPekAsync = createAsyncThunk(
  "reportPeks/create",
  async (reportPek: CreateReportPekDto, { rejectWithValue }) => {
    try {
      const reportPeksApi = new ReportPekApi();
      const response = await reportPeksApi.reportPekControllerCreate(reportPek);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateReportPekAsync = createAsyncThunk(
  "reportPeks/update",
  async ({ reportPek, id }: { reportPek: UpdateReportPekDto; id: string }, { rejectWithValue }) => {
    try {
      const reportPeksApi = new ReportPekApi();
      const response = await reportPeksApi.reportPekControllerUpdate(reportPek, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteReportPekAsync = createAsyncThunk(
  "reportPeks/remove",
  async ({ reportPek }: { reportPek: ReportPek }, { rejectWithValue }) => {
    try {
      const reportPeksApi = new ReportPekApi();
      const response = await reportPeksApi.reportPekControllerRemove(reportPek.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchReportPeksByQuarterAsync = createAsyncThunk("reportPeks/fetch", async ({organizationId, year, quarter}: {organizationId: string, year: number, quarter: number}, { rejectWithValue }) => {
  try {
    const radiologyApi = new ReportPekApi();
    const response = await radiologyApi.reportPekControllerFindByQuart(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchReportPeksByRadiologySite = () => {
    const { pending, fulfilled, rejected } = fetchReportPeksByQuarterAsync;
    return {
      [`${pending}`]: (state: ReportPeksState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPeksState, action: PayloadAction<ReportPek[]>) => {
        state.reportPeks = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPeksState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const generateReportPek = () => {
    const { pending, fulfilled, rejected } = generateReportPekAsync;

    return {
      [`${pending}`]: (state: ReportPeksState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPeksState, action: PayloadAction<ReportPek>) => {
        state.reportPeks = state.reportPeks.map((reportPek) => (reportPek.id === action.payload.id ? action.payload : reportPek));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPeksState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const createReportPek = () => {
    const { pending, fulfilled, rejected } = createReportPekAsync;
    return {
      [`${pending}`]: (state: ReportPeksState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPeksState, action: PayloadAction<ReportPek>) => {
        state.reportPeks.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPeksState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateReportPek = () => {
    const { pending, fulfilled, rejected } = updateReportPekAsync;

    return {
      [`${pending}`]: (state: ReportPeksState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPeksState, action: PayloadAction<ReportPek>) => {
        state.reportPeks = state.reportPeks.map((reportPek) => (reportPek.id === action.payload.id ? action.payload : reportPek));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPeksState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeReportPek = () => {
    const { pending, fulfilled, rejected } = deleteReportPekAsync;
    return {
      [`${pending}`]: (state: ReportPeksState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPeksState, action: PayloadAction<ReportPek>) => {
        state.reportPeks.splice(state.reportPeks.indexOf(
          state.reportPeks.find((ws: ReportPek) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPeksState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchReportPeksByRadiologySite(), ...createReportPek(), ...updateReportPek(), ...removeReportPek(), ...generateReportPek() };
};

export const reportPeksSlice = createSlice({
  name: "reportPeks",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectReportPeks = (state: RootState) => state.reportPeks.reportPeks;
export const selectStatusReportPeks = (state: RootState) => state.reportPeks.status;

export default reportPeksSlice.reducer;
