import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterDrainageObjectDto, WaterDrainageObjectsApi, UpdateWaterDrainageObjectDto, WaterDrainageObject } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WaterDrainageObjectsState {
  waterDrainageObjects: WaterDrainageObject[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterDrainageObjectsState = {
  waterDrainageObjects: [],
  status: "idle",
};

export const createWaterDrainageObjectAsync = createAsyncThunk(
  "waterDrainageObjects/create",
  async (waterDrainageObject: CreateWaterDrainageObjectDto, { rejectWithValue }) => {
    try {
      const waterDrainageObjectsApi = new WaterDrainageObjectsApi();
      const response = await waterDrainageObjectsApi.waterDrainageObjectsControllerCreate(waterDrainageObject);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterDrainageObjectAsync = createAsyncThunk(
  "waterDrainageObjects/update",
  async ({ waterDrainageObject, id }: { waterDrainageObject: UpdateWaterDrainageObjectDto; id: string }, { rejectWithValue }) => {
    try {
      const waterDrainageObjectsApi = new WaterDrainageObjectsApi();
      const response = await waterDrainageObjectsApi.waterDrainageObjectsControllerUpdate(waterDrainageObject, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterDrainageObjectAsync = createAsyncThunk(
  "waterDrainageObjects/remove",
  async ({ waterDrainageObject }: { waterDrainageObject: WaterDrainageObject }, { rejectWithValue }) => {
    try {
      const waterDrainageObjectsApi = new WaterDrainageObjectsApi();
      const response = await waterDrainageObjectsApi.waterDrainageObjectsControllerRemove(waterDrainageObject.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterDrainageObjectsAsync = createAsyncThunk(
  "waterDrainageObjects/fetch", async (_, { rejectWithValue }) => {
    try {
      const waterDrainageObjectsApi = new WaterDrainageObjectsApi();
      const response = await waterDrainageObjectsApi.waterDrainageObjectsControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchWaterDrainageObjects = () => {
    const { pending, fulfilled, rejected } = fetchWaterDrainageObjectsAsync;
    return {
      [`${pending}`]: (state: WaterDrainageObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageObjectsState, action: PayloadAction<WaterDrainageObject[]>) => {
        state.waterDrainageObjects = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterDrainageObject = () => {
    const { pending, fulfilled, rejected } = createWaterDrainageObjectAsync;
    return {
      [`${pending}`]: (state: WaterDrainageObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageObjectsState, action: PayloadAction<WaterDrainageObject>) => {
        state.waterDrainageObjects = [action.payload, ...state.waterDrainageObjects];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterDrainageObject = () => {
    const { pending, fulfilled, rejected } = updateWaterDrainageObjectAsync;

    return {
      [`${pending}`]: (state: WaterDrainageObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageObjectsState, action: PayloadAction<WaterDrainageObject>) => {
        state.waterDrainageObjects = state.waterDrainageObjects.map((WaterDrainageObject) => (WaterDrainageObject.id === action.payload.id ? action.payload : WaterDrainageObject));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterDrainageObject = () => {
    const { pending, fulfilled, rejected } = deleteWaterDrainageObjectAsync;
    return {
      [`${pending}`]: (state: WaterDrainageObjectsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterDrainageObjectsState, action: PayloadAction<WaterDrainageObject>) => {
        state.waterDrainageObjects.splice(state.waterDrainageObjects.indexOf(
          state.waterDrainageObjects.find((ws: WaterDrainageObject) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterDrainageObjectsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterDrainageObjects(), ...createWaterDrainageObject(), ...updateWaterDrainageObject(), ...removeWaterDrainageObject() };
};

export const waterDrainageObjectsSlice = createSlice({
  name: "waterDrainageObjects",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterDrainageObjects = (state: RootState) => state.waterDrainageObjects.waterDrainageObjects;

export default waterDrainageObjectsSlice.reducer;
