import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateCarbonIndirectEmissionDto, CarbonIndirectEmission, Organization, UpdateCarbonIndirectEmissionDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { CarbonIndirectEmissionForm } from "./components/CarbonIndirectEmissionForm";
import { CarbonIndirectEmissionColumns } from "./form/columns";
import { createCarbonIndirectEmissionAsync, updateCarbonIndirectEmissionAsync, selectCarbonIndirectEmission, deleteCarbonIndirectEmissionAsync, fetchCarbonIndirectEmissionByOrganizationIdAsync } from "./CarbonIndirectEmissionSlice";
import { BASE_PATH } from "../../../api/base";

interface CarbonIndirectEmissionComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

export const CarbonIndirectEmissionComponent: React.FC<CarbonIndirectEmissionComponentProps> = ({ organization, year, quarter }) => {
  const dispatch = useAppDispatch();
  const carbonIndirectEmission = useAppSelector(selectCarbonIndirectEmission);

  const [visible, setVisible] = useState(false);
  const downloadFile = (file: string) => {
    window.location.href = `${file}?nocache=${(Math.random() + 1).toString(36).substring(7)}`;
  }
  useEffect(() => {
    if (organization && year && quarter) {
      dispatch(fetchCarbonIndirectEmissionByOrganizationIdAsync({ organizationId: organization.id, year, quarter }));
    }
  }, [organization, year, quarter, dispatch]);

  const onUpdate = async (carbonIndirectEmission: UpdateCarbonIndirectEmissionDto, id: string) => {
    return dispatch(updateCarbonIndirectEmissionAsync({ carbonIndirectEmission, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateCarbonIndirectEmission = (carbonIndirectEmission: CreateCarbonIndirectEmissionDto) => {
    if (!organization || !year || !quarter) return;

    dispatch(createCarbonIndirectEmissionAsync({ ...carbonIndirectEmission, organizationId: organization.id, year, quarter })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteCarbonIndirectEmission = (carbonIndirectEmission: CarbonIndirectEmission) => {
    return dispatch(deleteCarbonIndirectEmissionAsync({ carbonIndirectEmission })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <CarbonIndirectEmissionForm visible={visible} setVisible={setVisible} onCreateCarbonIndirectEmission={onCreateCarbonIndirectEmission} />}
      <EditableTable<CarbonIndirectEmission, UpdateCarbonIndirectEmissionDto>
        onSave={onUpdate}
        onDelete={deleteCarbonIndirectEmission}
        entityColumns={CarbonIndirectEmissionColumns()}
        dataSource={carbonIndirectEmission}
        footer={() => addButton}
      />
      <Button.Group><Button onClick={() => downloadFile(`${BASE_PATH}/carbon-indirect-emission/report/organization/${organization?.id || ""}/year/${year}/quarter/${quarter}`)} block type="primary" ghost>Скачать форму</Button></Button.Group>
    </Layout>
  );
};
