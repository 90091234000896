import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteContragentDto } from "../../../api";
import { wasteContragentsColumns } from "../form/columns";

interface WasteContragentFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteContragent: (values: CreateWasteContragentDto) => void;
}

export const WasteContragentForm: React.FC<WasteContragentFormProps> = ({ visible, setVisible, onCreateWasteContragent }) => {
  const [form] = Form.useForm<CreateWasteContragentDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteContragent}>
        {wasteContragentsColumns.map(({ title, dataIndex, rules, inputNode }, index1) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index1}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
