import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateSoilPointDto, SoilPointsApi, UpdateSoilPointDto, SoilPoint } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface SoilPointsState {
  soilPoints: SoilPoint[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: SoilPointsState = {
  soilPoints: [],
  status: "idle",
};

export const createSoilPointAsync = createAsyncThunk(
  "soilPoints/create",
  async (soilPoint: CreateSoilPointDto, { rejectWithValue }) => {
    try {
      const soilPointsApi = new SoilPointsApi();
      const response = await soilPointsApi.soilPointsControllerCreate(soilPoint);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSoilPointAsync = createAsyncThunk(
  "soilPoints/update",
  async ({ soilPoint, id }: { soilPoint: UpdateSoilPointDto; id: string }, { rejectWithValue }) => {
    try {
      const soilPointsApi = new SoilPointsApi();
      const response = await soilPointsApi.soilPointsControllerUpdate(soilPoint, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSoilPointAsync = createAsyncThunk(
  "soilPoints/remove",
  async ({ soilPoint }: { soilPoint: SoilPoint }, { rejectWithValue }) => {
    try {
      const soilPointsApi = new SoilPointsApi();
      const response = await soilPointsApi.soilPointsControllerRemove(soilPoint.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const copySoilPointAsync = createAsyncThunk(
  "soilPoints/copy",
  async ({id} : { id: string }, { rejectWithValue }) => {
    try {
      const soilPointsApi = new SoilPointsApi();
      const response = await soilPointsApi.soilPointsControllerCopy(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSoilPointsByOrganizationidAsync = createAsyncThunk("soilPoints/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const soilPointsApi = new SoilPointsApi();
    const response = await soilPointsApi.soilPointsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchSoilPointsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchSoilPointsByOrganizationidAsync;
    return {
      [`${pending}`]: (state: SoilPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointsState, action: PayloadAction<SoilPoint[]>) => {
        state.soilPoints = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createSoilPoint = () => {
    const { pending, fulfilled, rejected } = createSoilPointAsync;
    return {
      [`${pending}`]: (state: SoilPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointsState, action: PayloadAction<SoilPoint>) => {
        state.soilPoints.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateSoilPoint = () => {
    const { pending, fulfilled, rejected } = updateSoilPointAsync;

    return {
      [`${pending}`]: (state: SoilPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointsState, action: PayloadAction<SoilPoint>) => {
        state.soilPoints = state.soilPoints.map((SoilPoint) => (SoilPoint.id === action.payload.id ? action.payload : SoilPoint));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeSoilPoint = () => {
    const { pending, fulfilled, rejected } = deleteSoilPointAsync;
    return {
      [`${pending}`]: (state: SoilPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointsState, action: PayloadAction<SoilPoint>) => {
        state.soilPoints.splice(state.soilPoints.indexOf(
          state.soilPoints.find((ws: SoilPoint) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const copySoilPoint = () => {
    const { pending, fulfilled, rejected } = copySoilPointAsync;
    return {
      [`${pending}`]: (state: SoilPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointsState, action: PayloadAction<SoilPoint>) => {
        state.soilPoints.push(action.payload);
        message.success("Успешно скопирован");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchSoilPointsByOrganization(), ...createSoilPoint(), ...updateSoilPoint(), ...removeSoilPoint(), ...copySoilPoint() };
};

export const soilPointsSlice = createSlice({
  name: "soilPoints",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectSoilPoints = (state: RootState) => state.soilPoints.soilPoints;

export default soilPointsSlice.reducer;
