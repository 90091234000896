import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterAdditionalJournalDto, WaterAdditionalJournalApi, UpdateWaterAdditionalJournalDto, WaterAdditionalJournal } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WaterAdditionalJournalsState {
  waterAdditionalJournals: WaterAdditionalJournal[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterAdditionalJournalsState = {
  waterAdditionalJournals: [],
  status: "idle",
};

export const createWaterAdditionalJournalAsync = createAsyncThunk(
  "waterAdditionalJournals/create",
  async (waterAdditionalJournal: CreateWaterAdditionalJournalDto, { rejectWithValue }) => {
    try {
      const waterAdditionalJournalsApi = new WaterAdditionalJournalApi();
      const response = await waterAdditionalJournalsApi.waterAdditionalJournalControllerCreate(waterAdditionalJournal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterAdditionalJournalAsync = createAsyncThunk(
  "waterAdditionalJournals/update",
  async ({ waterAdditionalJournal, id }: { waterAdditionalJournal: UpdateWaterAdditionalJournalDto; id: string }, { rejectWithValue }) => {
    try {
      const waterAdditionalJournalsApi = new WaterAdditionalJournalApi();
      const response = await waterAdditionalJournalsApi.waterAdditionalJournalControllerUpdate(waterAdditionalJournal, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterAdditionalJournalAsync = createAsyncThunk(
  "waterAdditionalJournals/remove",
  async ({ waterAdditionalJournal }: { waterAdditionalJournal: WaterAdditionalJournal }, { rejectWithValue }) => {
    try {
      const waterAdditionalJournalsApi = new WaterAdditionalJournalApi();
      const response = await waterAdditionalJournalsApi.waterAdditionalJournalControllerRemove(waterAdditionalJournal.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterAdditionalJournalsByOrganizationAsync = createAsyncThunk("waterAdditionalJournals/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const waterAdditionalApi = new WaterAdditionalJournalApi();
    const response = await waterAdditionalApi.waterAdditionalJournalControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterAdditionalJournalsByWaterAdditionalSite = () => {
    const { pending, fulfilled, rejected } = fetchWaterAdditionalJournalsByOrganizationAsync;
    return {
      [`${pending}`]: (state: WaterAdditionalJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<WaterAdditionalJournal[]>) => {
        state.waterAdditionalJournals = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterAdditionalJournal = () => {
    const { pending, fulfilled, rejected } = createWaterAdditionalJournalAsync;
    return {
      [`${pending}`]: (state: WaterAdditionalJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<WaterAdditionalJournal>) => {
        state.waterAdditionalJournals.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterAdditionalJournal = () => {
    const { pending, fulfilled, rejected } = updateWaterAdditionalJournalAsync;

    return {
      [`${pending}`]: (state: WaterAdditionalJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<WaterAdditionalJournal>) => {
        state.waterAdditionalJournals = state.waterAdditionalJournals.map((waterAdditionalJournal) => (waterAdditionalJournal.id === action.payload.id ? action.payload : waterAdditionalJournal));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterAdditionalJournal = () => {
    const { pending, fulfilled, rejected } = deleteWaterAdditionalJournalAsync;
    return {
      [`${pending}`]: (state: WaterAdditionalJournalsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<WaterAdditionalJournal>) => {
        state.waterAdditionalJournals.splice(state.waterAdditionalJournals.indexOf(
          state.waterAdditionalJournals.find((ws: WaterAdditionalJournal) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterAdditionalJournalsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterAdditionalJournalsByWaterAdditionalSite(), ...createWaterAdditionalJournal(), ...updateWaterAdditionalJournal(), ...removeWaterAdditionalJournal() };
};

export const waterAdditionalJournalsSlice = createSlice({
  name: "waterAdditionalJournals",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterAdditionalJournals = (state: RootState) => state.waterAdditionalJournals.waterAdditionalJournals;

export default waterAdditionalJournalsSlice.reducer;
