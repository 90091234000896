import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CleaningEquipment, CleaningEquipmentsApi, CreateCleaningEquipmentDto, UpdateCleaningEquipmentDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface CleaningEquipmentsState {
  cleaningEquipments: CleaningEquipment[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CleaningEquipmentsState = {
  cleaningEquipments: [],
  status: "idle",
};

export const fetchCleaningEquipmentsAsync = createAsyncThunk("cleaningEquipments/fetch", async (pollutionSourceId: string, { rejectWithValue }) => {
  try {
    const cleaningEquipmentsApi = new CleaningEquipmentsApi();
    const response = await cleaningEquipmentsApi.cleaningEquipmentsControllerFindByPollutionSourceId(pollutionSourceId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCleaningEquipmentsAsync = createAsyncThunk(
  "cleaningEquipments/create",
  async (cleaningEquipment: CreateCleaningEquipmentDto, { rejectWithValue }) => {
    try {
      const cleaningEquipmentsApi = new CleaningEquipmentsApi();
      const response = await cleaningEquipmentsApi.cleaningEquipmentsControllerCreate(cleaningEquipment);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCleaningEquipmentAsync = createAsyncThunk(
  "cleaningEquipments/update",
  async ({ cleaningEquipment, id }: { cleaningEquipment: UpdateCleaningEquipmentDto; id: string }, { rejectWithValue }) => {
    try {
      const cleaningEquipmentsApi = new CleaningEquipmentsApi();
      const response = await cleaningEquipmentsApi.cleaningEquipmentsControllerUpdate(cleaningEquipment, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchCleaningEquipments = () => {
    const { pending, fulfilled, rejected } = fetchCleaningEquipmentsAsync;
    return {
      [`${pending}`]: (state: CleaningEquipmentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CleaningEquipmentsState, action: PayloadAction<CleaningEquipment[]>) => {
        state.cleaningEquipments = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CleaningEquipmentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCleaningEquipment = () => {
    const { pending, fulfilled, rejected } = createCleaningEquipmentsAsync;
    return {
      [`${pending}`]: (state: CleaningEquipmentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CleaningEquipmentsState, action: PayloadAction<CleaningEquipment>) => {
        state.cleaningEquipments.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CleaningEquipmentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCleaningEquipment = () => {
    const { pending, fulfilled, rejected } = updateCleaningEquipmentAsync;

    return {
      [`${pending}`]: (state: CleaningEquipmentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CleaningEquipmentsState, action: PayloadAction<CleaningEquipment>) => {
        state.cleaningEquipments = state.cleaningEquipments.map((cleaningEquipment) =>
        cleaningEquipment.id === action.payload.id ? action.payload : cleaningEquipment
        );
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CleaningEquipmentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCleaningEquipments(), ...createCleaningEquipment(), ...updateCleaningEquipment() };
};

export const cleaningEquipmentsSlice = createSlice({
  name: "cleaningEquipments",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectCleaningEquipments = (state: RootState) => state.cleaningEquipments.cleaningEquipments;

export default cleaningEquipmentsSlice.reducer;
