import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  BankFilled,
} from "@ant-design/icons";
import { Avatar, Button, Divider, Dropdown, Menu, Row, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { User, UserRolesEnum } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import Logo from "../../assets/Logo";
import LogoWithText from "../../assets/LogoWithText";
import { logOut } from "../../features/auth/authSlice";

type SidebarProps = {
  authUser?: User;
};

const Sidebar: React.FC<SidebarProps> = ({ authUser }) => {
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useAppDispatch();

  return (
    <Menu mode="inline" className="max-w-xs min-h-screen flex flex-col overflow-scroll" inlineCollapsed={collapsed}>
      <Row className="h-32" justify={"space-around"} align={"middle"}>
        <Link to="/">{collapsed ? <Logo width="auto" height="30px" /> : <LogoWithText />}</Link>
        <Button type="text" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </Row>
      <Divider />

      {/* <Menu.Item icon={<PaperClipOutlined />}>
        <Link to="/reports">Отчеты</Link>
      </Menu.Item> */}
      <Menu.Item icon={<BankFilled />}>
        <Link to={"/organizations"}>Организации </Link>
      </Menu.Item>
      {
        (authUser?.roles.includes(UserRolesEnum.Admin) || authUser?.roles.includes(UserRolesEnum.Chief) || authUser?.roles.includes(UserRolesEnum.Head)) &&
        <Menu.Item icon={<UserOutlined />}>
          <Link to={"/users"}>Пользователи </Link>
        </Menu.Item>
      }

      <Row className="w-xs" justify={"space-around"} align={"middle"}>
        <Divider />
        {collapsed || (
          <>
            <Avatar size={"large"} src="https://joeschmoe.io/api/v1/random" />
            <Link to="/profile">
              <Typography.Title className="!my-0" level={5}>{authUser?.name}</Typography.Title>
              <Typography.Text>{authUser?.email}</Typography.Text>
            </Link>
            <Button size="large" shape="circle" danger onClick={() => dispatch(logOut())}>
              <LogoutOutlined />
            </Button>
          </>
        )}

        {collapsed && (
          <>
            <Dropdown
              menu={{
                items: [
                  { key: 1, label: <Link to="/profile">Профиль</Link>, icon: <UserOutlined /> },
                  {
                    key: 2,
                    label: "Выйти",
                    onClick: () => {
                      console.log("Hello");
                    },
                    icon: <LogoutOutlined />,
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Avatar size={"large"} src="https://joeschmoe.io/api/v1/random" />
            </Dropdown>
          </>
        )}
      </Row>
    </Menu>
  );
};

export default Sidebar;
