import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteBurialSiteDto } from "../../../api";
import { wasteBurialSitesColumns } from "../form/columns";

interface WasteBurialSiteFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteBurialSite: (values: CreateWasteBurialSiteDto) => void;
}

export const WasteBurialSiteForm: React.FC<WasteBurialSiteFormProps> = ({ visible, setVisible, onCreateWasteBurialSite }) => {
  const [form] = Form.useForm<CreateWasteBurialSiteDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteBurialSite}>
        {wasteBurialSitesColumns.map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
