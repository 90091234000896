import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreatePekProgramDto, PekProgramsApi, UpdatePekProgramDto } from "../../api";
import { ApiError } from "../../app/types";

export interface PekProgramsState {
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: PekProgramsState = {
  status: "idle",
};

export const createPekProgramAsync = createAsyncThunk("pekPrograms/create", async (data: CreatePekProgramDto, { rejectWithValue }) => {
  try {
    const pekProgramsApi = new PekProgramsApi();
    const response = await pekProgramsApi.pekProgramsControllerCreate(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePekProgramAsync = createAsyncThunk(
  "pekPrograms/update",
  async ({ pekProgram, id }: { pekProgram: UpdatePekProgramDto; id: string }, { rejectWithValue }) => {
    try {
      const pekProgramsApi = new PekProgramsApi();
      const response = await pekProgramsApi.pekProgramsControllerUpdate(pekProgram, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  return {};
};

export const pekProgramsSlice = createSlice({
  name: "pekPrograms",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export default pekProgramsSlice.reducer;
