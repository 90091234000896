/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Calculation } from '../models';
import { CreateCalculationDto } from '../models';
import { UpdateCalculationInputDto } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * CalculationsApi - axios parameter creator
 * @export
 */
export const CalculationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerCalculate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling calculationsControllerCalculate.');
            }
            const localVarPath = `/calculations/calculate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCalculationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerCreate: async (body: CreateCalculationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling calculationsControllerCreate.');
            }
            const localVarPath = `/calculations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calculations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerFindById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling calculationsControllerFindById.');
            }
            const localVarPath = `/calculations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerFindByOrganizationAndPeriod: async (organizationId: string, year: number, quarter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling calculationsControllerFindByOrganizationAndPeriod.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year', 'Required parameter year was null or undefined when calling calculationsControllerFindByOrganizationAndPeriod.');
            }
            // verify required parameter 'quarter' is not null or undefined
            if (quarter === null || quarter === undefined) {
                throw new RequiredError('quarter', 'Required parameter quarter was null or undefined when calling calculationsControllerFindByOrganizationAndPeriod.');
            }
            const localVarPath = `/calculations/organization/{organizationId}/year/{year}/quarter/{quarter}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"quarter"}}`, encodeURIComponent(String(quarter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} workshopId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerFindByWorkshopId: async (organizationId: string, workshopId: string, year: number, quarter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling calculationsControllerFindByWorkshopId.');
            }
            // verify required parameter 'workshopId' is not null or undefined
            if (workshopId === null || workshopId === undefined) {
                throw new RequiredError('workshopId', 'Required parameter workshopId was null or undefined when calling calculationsControllerFindByWorkshopId.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year', 'Required parameter year was null or undefined when calling calculationsControllerFindByWorkshopId.');
            }
            // verify required parameter 'quarter' is not null or undefined
            if (quarter === null || quarter === undefined) {
                throw new RequiredError('quarter', 'Required parameter quarter was null or undefined when calling calculationsControllerFindByWorkshopId.');
            }
            const localVarPath = `/calculations/inputs-by-workhshop/{organizationId}/{workshopId}/{year}/{quarter}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workshopId"}}`, encodeURIComponent(String(workshopId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"quarter"}}`, encodeURIComponent(String(quarter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling calculationsControllerRemove.');
            }
            const localVarPath = `/calculations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCalculationInputDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculationsControllerUpdateCalculationInput: async (body: UpdateCalculationInputDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling calculationsControllerUpdateCalculationInput.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling calculationsControllerUpdateCalculationInput.');
            }
            const localVarPath = `/calculations/calculationInput/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
            localVarHeaderParameter["Content-Type"] = "application/json";
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalculationsApi - functional programming interface
 * @export
 */
export const CalculationsApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerCalculate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerCalculate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateCalculationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerCreate(body: CreateCalculationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Calculation>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Calculation>>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerFindById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindByOrganizationAndPeriod(organizationId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Calculation>>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerFindByOrganizationAndPeriod(organizationId, year, quarter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} workshopId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindByWorkshopId(organizationId: string, workshopId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Calculation>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerFindByWorkshopId(organizationId, workshopId, year, quarter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateCalculationInputDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerUpdateCalculationInput(body: UpdateCalculationInputDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await CalculationsApiAxiosParamCreator(configuration).calculationsControllerUpdateCalculationInput(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CalculationsApi - factory interface
 * @export
 */
export const CalculationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerCalculate(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return CalculationsApiFp(configuration).calculationsControllerCalculate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCalculationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerCreate(body: CreateCalculationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Calculation>> {
            return CalculationsApiFp(configuration).calculationsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Calculation>>> {
            return CalculationsApiFp(configuration).calculationsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindById(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CalculationsApiFp(configuration).calculationsControllerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindByOrganizationAndPeriod(organizationId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Calculation>>> {
            return CalculationsApiFp(configuration).calculationsControllerFindByOrganizationAndPeriod(organizationId, year, quarter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} workshopId 
         * @param {number} year 
         * @param {number} quarter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerFindByWorkshopId(organizationId: string, workshopId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Calculation>> {
            return CalculationsApiFp(configuration).calculationsControllerFindByWorkshopId(organizationId, workshopId, year, quarter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CalculationsApiFp(configuration).calculationsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCalculationInputDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculationsControllerUpdateCalculationInput(body: UpdateCalculationInputDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return CalculationsApiFp(configuration).calculationsControllerUpdateCalculationInput(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalculationsApi - object-oriented interface
 * @export
 * @class CalculationsApi
 * @extends {BaseAPI}
 */
export class CalculationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerCalculate(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
        return CalculationsApiFp(this.configuration).calculationsControllerCalculate(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateCalculationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerCreate(body: CreateCalculationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Calculation>> {
        return CalculationsApiFp(this.configuration).calculationsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Calculation>>> {
        return CalculationsApiFp(this.configuration).calculationsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerFindById(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        return CalculationsApiFp(this.configuration).calculationsControllerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {number} year 
     * @param {number} quarter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerFindByOrganizationAndPeriod(organizationId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Calculation>>> {
        return CalculationsApiFp(this.configuration).calculationsControllerFindByOrganizationAndPeriod(organizationId, year, quarter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {string} workshopId 
     * @param {number} year 
     * @param {number} quarter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerFindByWorkshopId(organizationId: string, workshopId: string, year: number, quarter: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Calculation>> {
        return CalculationsApiFp(this.configuration).calculationsControllerFindByWorkshopId(organizationId, workshopId, year, quarter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        return CalculationsApiFp(this.configuration).calculationsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateCalculationInputDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public async calculationsControllerUpdateCalculationInput(body: UpdateCalculationInputDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
        return CalculationsApiFp(this.configuration).calculationsControllerUpdateCalculationInput(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
