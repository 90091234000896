import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteApplication7RecordDto, WasteApplication7Record, UpdateWasteApplication7RecordDto, Organization, UserRolesEnum } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { fetchWasteRegistersByYearAsync } from "../../wasteRegister/WasteRegisterSlice";
import { WasteApplication7RecordForm } from "./components/WasteApplication7RecordForm";
import { WasteApplication7RecordsColumns } from "./form/columns";
import { createWasteApplication7RecordAsync, updateWasteApplication7RecordAsync, selectWasteApplication7Records, deleteWasteApplication7RecordAsync, fetchWasteApplication7RecordsByMonthAsync } from "./WasteApplication7RecordsSlice";
import { selectAuthUser } from "../../auth/authSlice";

interface WasteApplication7RecordsComponentProps {
  organization?: Organization;
  year: number;
  quarter: number;
  month: number;
}

export const WasteApplication7RecordsComponent: React.FC<WasteApplication7RecordsComponentProps> = ({ organization, year, quarter, month }) => {
  const dispatch = useAppDispatch();
  const wasteApplication7Records = useAppSelector(selectWasteApplication7Records);
  const authUser = useAppSelector(selectAuthUser);
  let superRoles = [
    UserRolesEnum.Admin,
    UserRolesEnum.Head,
    UserRolesEnum.Ecolog,
    UserRolesEnum.Chief,
  ];

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWasteApplication7RecordsByMonthAsync({ organizationId: organization.id, year: year, quarter: quarter, month: month }));
      dispatch(fetchWasteRegistersByYearAsync({ organizationId: organization.id, year: year }));
    }
  }, [organization, year, quarter, month, dispatch]);

  const onUpdate = async (wasteApplication7Record: UpdateWasteApplication7RecordDto, id: string) => {
    return dispatch(updateWasteApplication7RecordAsync({ wasteApplication7Record, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteApplication7Record = (wasteApplication7Record: CreateWasteApplication7RecordDto) => {
    if (!organization || !year || !quarter || !authUser) return;

    let params;

    if ((superRoles.find((sr) => authUser?.roles.includes(sr)))) {
      params = { ...wasteApplication7Record, organizationId: organization.id, year, quarter, month };
    } else {
      params = { ...wasteApplication7Record, organizationId: organization.id, year, quarter, month, role: authUser.roles[0] as any };
    }

    dispatch(createWasteApplication7RecordAsync(params)).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteApplication7Records = (wasteApplication7Record: WasteApplication7Record) => {
    return dispatch(deleteWasteApplication7RecordAsync({ wasteApplication7Record })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить запись
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteApplication7RecordForm visible={visible} setVisible={setVisible} onCreateWasteApplication7Record={onCreateWasteApplication7Record} />}
      <EditableTable<WasteApplication7Record, UpdateWasteApplication7RecordDto>
        onSave={onUpdate}
        onDelete={deleteWasteApplication7Records}
        entityColumns={WasteApplication7RecordsColumns()}
        dataSource={structuredClone(wasteApplication7Records).map((data: any) => {
          const wasteRegister = data?.wasteRegister?.id;
          delete data?.wasteRegister;
          data.wasteRegister = wasteRegister;

          return data;
        }) as any}

        footer={() => addButton}
      />
    </Layout>
  );
};
