import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button, Layout } from "antd";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { FolderOutlined } from '@ant-design/icons';
import { fetchFilesByIdsAsync } from "../files/FilesEntitySlice";
import { FileForm } from "../files/components/FileForm";
import { RopDocument } from "../../api/models/rop-document";
import { createRopDocumentAsync, deleteRopDocumentAsync, fetchRopDocumentByQuarterAsync, selectRopDocuments, updateRopDocumentAsync } from "./RopDocumentsSlice";
import { UpdateRopDocumentDto } from "../../api/models/update-rop-document-dto";
import { CreateRopDocumentDto } from "../../api/models/create-rop-document-dto";
import { RopDocumentsForm } from "./components/RopDocumentsForm";
import { RopDocumentsColumns } from "./form/column";

interface RopDocumentsComponentProps {
    organizationId?: string,
    year: number;
    quarter: number;
}

export const RopDocumentsComponent: React.FC<RopDocumentsComponentProps> = ({ organizationId, year, quarter }) => {
    const dispatch = useAppDispatch();
    const documents: RopDocument[] = useAppSelector(selectRopDocuments);
    
    useEffect(() => {
        if (organizationId) {
            if (year && quarter) {
                dispatch(fetchRopDocumentByQuarterAsync({ organizationId, year, quarter }));
            } else {
                dispatch(fetchRopDocumentByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
            }
        }
    }, [year, quarter, organizationId, dispatch]);

    const onUpdate = async (ropDocument: UpdateRopDocumentDto, id: string) => {
        return dispatch(updateRopDocumentAsync({ ropDocument, id })).then((res: any) => {
            if (!res.error) {
                if (organizationId) {
                    if (year && quarter) {
                        dispatch(fetchRopDocumentByQuarterAsync({ organizationId, year, quarter }));
                    } else {
                        dispatch(fetchRopDocumentByQuarterAsync({ organizationId, year: new Date().getFullYear(), quarter: Math.ceil(new Date().getMonth() / 3) }));
                    }
                }
                return true;
            }
            return false;
        });
    };

    const onCreateRopDocument = (ropDocument: CreateRopDocumentDto) => {
        if (!organizationId || !year || !quarter) return;

        dispatch(createRopDocumentAsync({ ...ropDocument, organizationId, year, quarter })).then((res: any) => {
            if (!res.error) {
                setFormVisible(false);
            }
        });
    };

    const deleteRopDocument = (id: string) => {
        return dispatch(deleteRopDocumentAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }

    const [visibleForm, setFormVisible] = useState(false);

    const addButton = (
        <Button onClick={() => setFormVisible(true)} block type="dashed" className="">
            + Добавить документ
        </Button>)

    const [visible, setVisible] = useState(false);
    const [ropDocument, setCurrentRopDocument] = useState<RopDocument>();

    const showFileModal = async (document: RopDocument) => {
        if (document.fileIds.length > 0) {
            await dispatch(fetchFilesByIdsAsync({ ids: document.fileIds.join(',') }));
        }

        setCurrentRopDocument(document);
        setVisible(true);
    };

    return (
        <Layout className="bg-white p-4 rounded-lg">
            {visibleForm && <RopDocumentsForm visible={visibleForm} setVisible={setFormVisible} onCreateRopDocument={onCreateRopDocument} organizationId={organizationId} />}
            <div className="table-responsive">
                <EditableTable<RopDocument, UpdateRopDocumentDto>
                    onSave={(value, id) => onUpdate(value, id)}
                    onDelete={(document) => deleteRopDocument(document.id)}
                    entityColumns={[
                        ...RopDocumentsColumns(),
                        {
                            title: "Файл",
                            dataIndex: "btnc" as keyof RopDocument,
                            key: "btnc",
                            inputNode: <></>,
                            render: (value: any, record: RopDocument, index: any) => (
                                 <Button.Group>
                                    <Button icon={<FolderOutlined />} onClick={() => showFileModal(record)}></Button>
                                </Button.Group>
                            )
                            ,
                            editable: false,
                            copyable: false,
                        },
                    ]}
                    dataSource={documents}
                    footer={() => addButton}
                />
            </div>
            {visible && <FileForm visible={visible} setVisible={setVisible} onUpdate={onUpdate} fileIds={ropDocument?.fileIds} id={ropDocument?.id} maxCount={1}/>}
        </Layout>
    );

}