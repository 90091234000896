import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateWaterConsumptionHouseholdDto, WaterConsumptionHousehold, Organization, UpdateWaterConsumptionHouseholdDto } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditableTable } from "../../../components/editable-table/EditableTable";
import { WaterConsumptionHouseholdForm } from "./components/WaterConsumptionHouseholdForm";
import { WaterConsumptionHouseholdColumns } from "./form/columns";
import { createWaterConsumptionHouseholdAsync, updateWaterConsumptionHouseholdAsync, selectWaterConsumptionHousehold, deleteWaterConsumptionHouseholdAsync, fetchWaterConsumptionHouseholdByOrganizationIdAsync } from "./WaterConsumptionHouseholdsSlice";
import { fetchWaterConsumptionObjectsAsync } from "../../waterConsumptionObject/WaterConsumptionObjectsSlice";

interface WaterConsumptionHouseholdComponentProps {
  organization?: Organization;
  year?: number;
}

export const WaterConsumptionHouseholdComponent: React.FC<WaterConsumptionHouseholdComponentProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const waterConsumptionHousehold = useAppSelector(selectWaterConsumptionHousehold);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchWaterConsumptionHouseholdByOrganizationIdAsync({ organizationId: organization.id, year }));
      dispatch(fetchWaterConsumptionObjectsAsync());
    }
  }, [organization, year, dispatch]);

  const onUpdate = async (waterConsumptionHousehold: UpdateWaterConsumptionHouseholdDto, id: string) => {
    return dispatch(updateWaterConsumptionHouseholdAsync({ waterConsumptionHousehold, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWaterConsumptionHousehold = (waterConsumptionHousehold: CreateWaterConsumptionHouseholdDto) => {
    if (!organization || !year) return;

    dispatch(createWaterConsumptionHouseholdAsync({ ...waterConsumptionHousehold, organizationId: organization.id, year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWaterConsumptionHousehold = (waterConsumptionHousehold: WaterConsumptionHousehold) => {
    return dispatch(deleteWaterConsumptionHouseholdAsync({ waterConsumptionHousehold })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WaterConsumptionHouseholdForm visible={visible} setVisible={setVisible} onCreateWaterConsumptionHousehold={onCreateWaterConsumptionHousehold} />}
      <EditableTable<WaterConsumptionHousehold, UpdateWaterConsumptionHouseholdDto>
        onSave={onUpdate}
        onDelete={deleteWaterConsumptionHousehold}
        entityColumns={WaterConsumptionHouseholdColumns()}
        dataSource={structuredClone(waterConsumptionHousehold).map((data: any) => {
          const waterConsumptionObject = data?.waterConsumptionObject?.id;
          delete data?.waterConsumptionObject;
          data.waterConsumptionObject = waterConsumptionObject;
          return data;
        }) as any}
        footer={() => addButton}
      />
    </Layout>
  );
};
