import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { CreateCategoryVariableDto, CategoryVariable, CategoryVariablesApi, UpdateCategoryVariableDto, CategoryVariableValue, UpdateCategoryVariableValueDto } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface CategoryVariableState {
  categoryVariables: CategoryVariable[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CategoryVariableState = {
  categoryVariables: [],
  status: "idle",
};

export const fetchCategoryVariableAsync = createAsyncThunk("categoryVariables/fetch", async (categoryId: string, { rejectWithValue }) => {
  try {
    const categoryVariablesApi = new CategoryVariablesApi();
    const response = await categoryVariablesApi.categoryVariablesControllerFindByCategoryId(categoryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCategoryVariableAsync = createAsyncThunk(
  "categoryVariables/create",
  async (categoryVariable: CreateCategoryVariableDto, { rejectWithValue }) => {
    try {
      const categoryVariablesApi = new CategoryVariablesApi();
      const response = await categoryVariablesApi.categoryVariablesControllerCreate(categoryVariable);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCategoryVariableAsync = createAsyncThunk(
  "categoryVariables/update",
  async ({ categoryVariable, id }: { categoryVariable: UpdateCategoryVariableDto; id: string }, { rejectWithValue }) => {
    try {
      const categoryVariablesApi = new CategoryVariablesApi();
      const response = await categoryVariablesApi.categoryVariablesControllerUpdate(categoryVariable, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCategoryVariableCalculationInputAsync = createAsyncThunk(
  "categoryVariables/calculationInputUpdate",
  async ({ categoryVariableId, categoryVariableValue }: { categoryVariableId: string; categoryVariableValue: UpdateCategoryVariableValueDto }, { rejectWithValue }) => {
    try {
      const categoryVariablesApi = new CategoryVariablesApi();
      const response = await categoryVariablesApi.categoryVariablesControllerUpdateCalculationInput(categoryVariableValue, categoryVariableId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeCategoryVariableAsync = createAsyncThunk("categoryVariables/remove", async (id: string, { rejectWithValue }) => {
  try {
    const categoryVariablesApi = new CategoryVariablesApi();
    const response = await categoryVariablesApi.categoryVariablesControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {

  const fetchCategoryVariable = () => {
    const { pending, fulfilled, rejected } = fetchCategoryVariableAsync;
    return {
      [`${pending}`]: (state: CategoryVariableState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoryVariableState, action: PayloadAction<CategoryVariable[]>) => {
        state.categoryVariables = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoryVariableState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCategoryVariable = () => {
    const { pending, fulfilled, rejected } = createCategoryVariableAsync;
    return {
      [`${pending}`]: (state: CategoryVariableState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoryVariableState, action: PayloadAction<CategoryVariable>) => {
        state.categoryVariables.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoryVariableState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCategoryVariable = () => {
    const { pending, fulfilled, rejected } = updateCategoryVariableAsync;

    return {
      [`${pending}`]: (state: CategoryVariableState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoryVariableState, action: PayloadAction<CategoryVariable>) => {
        state.categoryVariables = state.categoryVariables.map((categoryVariable) =>
          categoryVariable.id === action.payload.id ? action.payload : categoryVariable
        );
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoryVariableState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeCategoryVariable = () => {
    const { pending, fulfilled, rejected } = removeCategoryVariableAsync;

    return {
      [`${pending}`]: (state: CategoryVariableState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoryVariableState, action: PayloadAction<CategoryVariable>) => {
        state.categoryVariables = state.categoryVariables.filter((categoryVariable) => categoryVariable.id !== action.payload.id);
        message.success("Успешно удалено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CategoryVariableState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCategoryVariableCalculationInput = () => {
    const { pending, fulfilled, rejected } = updateCategoryVariableCalculationInputAsync;

    return {
      [`${pending}`]: (state: CategoryVariableState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CategoryVariableState) => {
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: () => {
        message.error("Не удалось сохранить !");
      },
    };
  };

  return {
    ...fetchCategoryVariable(),
    ...createCategoryVariable(),
    ...updateCategoryVariable(),
    ...removeCategoryVariable(),
    ...updateCategoryVariableCalculationInput(),
  };
};

export const categoryVariablesSlice = createSlice({
  name: "categoryVariables",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});
export const selectCategoryVariables = (state: RootState) => state.categoryVariables.categoryVariables;
export const selectCategoryVariableValueStatus = (state: RootState) => state.categoryVariables.status;

export default categoryVariablesSlice.reducer;
