import React, { useState } from "react";
import { CreateRopCalculationsTransportDto } from "../../../api/models/create-rop-calculations-transport-dto";
import { RopTransportClassifier } from "../../../api/models/rop-transport-classifier";
import { Button, Form, Input, Modal, Upload, UploadFile } from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectOrganizationsProfile } from "../../organizationProfile/organizationProfileSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import Select, { DefaultOptionType } from "antd/es/select";
import { BASE_PATH } from "../../../api/base";
import { getFromStorage } from "../../../app/helpers";
import { UploadOutlined } from '@ant-design/icons';
import { selectRopTransportClassifiers } from "../RopTransportClassifiersSlice";
import { deleteFileAsync } from "../../files/FilesEntitySlice";

interface RopCalculationsTransportFormProps {
    visible: boolean;
    setVisible: Function;
    onCreateRopCalculations: (values: CreateRopCalculationsTransportDto) => void;
    organizationId?: string;
    classifiers: RopTransportClassifier[]
}

export const RopCalculationsTransportForm: React.FC<RopCalculationsTransportFormProps> = ({ visible, setVisible, onCreateRopCalculations, organizationId }) => {
    const [form] = Form.useForm<any>();

    const organization = useAppSelector(selectOrganizationsProfile);
    const period = useAppSelector(selectOrganizationModulePeriod);
    const transportClassifiers = useAppSelector(selectRopTransportClassifiers);
    const dispatch = useAppDispatch();

    let [selectedTransport, setTransport] = useState<string>("");
    let transportClassifierLabels: DefaultOptionType[] = [];
    for (const classifier of transportClassifiers) {
        transportClassifierLabels = [...transportClassifierLabels, {
            value: classifier.id,
            label: classifier.name
        }]
    }


    let [selectedTransportSubcat, setTransportSubcat] = useState<string>("");
    let transportSubcategoryClassifiersLabels: DefaultOptionType[] = []
    for (const classifier of transportClassifiers) {
        if (classifier.id === selectedTransport) {
            for (const subcat of classifier.ropTransportSubcategoryClassifier) {
                transportSubcategoryClassifiersLabels = [...transportSubcategoryClassifiersLabels, {
                    value: subcat.id,
                    label: subcat.name,
                    coefficient: subcat.coefficient
                }]
            }
        }
    }

    const [ids, setIds] = useState<string[]>([]);
    const deleteFile = (id: string) => {
        return dispatch(deleteFileAsync({ id })).then((res: any) => {
            if (!res.error) {
                return true;
            }
            return false;
        });
    }
    const handleRemove = (file: UploadFile) => {
        if(file.response) {
            setIds(ids.filter(id => id === file.response.id));
            deleteFile(file.response.id)
        }
    };
    const onFinish = (value: any) => {
        const dto: CreateRopCalculationsTransportDto = {
            declarationNumber: value.declarationNumber,
            codeTNVEDEAEU: value.codeTNVEDEAEU,
            name: value.name,
            transportClassifierId: selectedTransport,
            transportSubcategoryClassifierId: selectedTransportSubcat,
            year: value.year,
            quarter: value.quarter,
            isActive: true,
            organizationId: organizationId,
            fileIds: ids
        }
        onCreateRopCalculations(dto);
    }

    return (
        <Modal width="60%" open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
            <Form initialValues={{ year: period.year, quarter: period.quarter, bin: organization?.bin, organizationName: organization?.name }} form={form} className="p-8" layout="vertical" onFinish={onFinish}>
                <Form.Item label={"Номер декларации"} name={"declarationNumber"} key={"declarationNumber"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"БИН/ИИН"} name={"bin"} key={"bin"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Наименование компании"} name={"organizationName"} key={"organizationName"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Код ТН ВЭД ЕАЭС"} name={"codeTNVEDEAEU"} key={"codeTNVEDEAEU"}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Наименование товара"} name={"name"} key={"name"}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={false} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Вид транспортных средств и самоходной сельскохозяйственной техники"} name={"transportClassifierId"} key={"transportClassifierId"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Select
                                options={transportClassifierLabels}
                                showSearch
                                filterOption={(input, option) => {
                                    if (option) {
                                        const str = option.label + '';
                                        return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                    } else {
                                        return true
                                    }
                                }}
                                onSelect={(selectedClassifier) => setTransport(selectedClassifier)}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Категории транспортных средств и самоходной сельскохозяйственной техники"} name={"transportSubcategoryClassifierId"} key={"transportSubcategoryClassifierId"} rules={[{ required: true, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Select
                                options={transportSubcategoryClassifiersLabels}
                                showSearch
                                filterOption={(input, option) => {
                                    if (option) {
                                        const str = option.label + '';
                                        return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                    } else {
                                        return true
                                    }
                                }}
                                onSelect={(selectedClassifier) => setTransportSubcat(selectedClassifier)}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Год"} name={"year"} key={"year"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Form.Item label={"Квартал"} name={"quarter"} key={"quarter"} rules={[{ required: false, message: "Заполните поле" }]}>
                    {
                        React.cloneElement(
                            <Input style={{ width: '100%' }} disabled={true} />
                        )
                    }
                </Form.Item>
                <Upload
                    action={`${BASE_PATH}/files/`}
                    listType="picture"
                    maxCount={3}
                    headers={{
                        Authorization: 'Bearer ' + getFromStorage("token"),
                    }}
                    onChange={(info) => {
                        if (info.file.status === "done") {
                            console.log(info.file.response.id)
                            let temp = [...ids]
                            temp.push(info.file.response?.id);
                            setIds(temp);
                        }
                    }}
                    onRemove={handleRemove}
                >
                    <Button icon={<UploadOutlined />}>Upload (Max: 3)</Button>
                </Upload>
            </Form>
        </Modal>
    );
}