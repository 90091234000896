import { Input, InputNumber, Select, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { DropResult } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectDrop } from "../../drops/DropsSlice";
import { selectInfluenceObject } from "../../influenceObjects/InfluenceObjectsSlice";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";

export const DropResultColumns = (): EntityColumns<DropResult>[] => {
  const period = useAppSelector(selectOrganizationModulePeriod);
  const drops = useAppSelector(selectDrop);

  return [
    {
      title: "Объект воздействия, вещество",
      dataIndex: "drop",
      key: "dropId",
      inputNode: <Select
        showSearch
        filterOption={(input, option) => {
          if (option) {
            const label = option?.label.props.children.reduce((res: any, item: any) => {
              return [...res.props?.children, ...item.props?.children].join('');
            }) || '';
            return (
              label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          } else {
            return true;
          }
        }}
        options={drops?.map(
          (drop) => {
            const label =
              <>
                <div>
                  Объект воздействия: {drop.influenceObject?.name}
                </div>
                <div>
                  Вещество: {drop.dropCode.name}
                </div>
              </>;
            return {
              value: drop?.id,
              label: label,
            }
          }
        )}
      />,
      render: (dropId: string) => {
        const drop = drops.find(d => d.id === dropId);
        const label =
          <>
            <div>
              Объект воздействия: {drop?.influenceObject?.name}
            </div>
            <div>
              Вещество: {drop?.dropCode.name}
            </div>
          </>;
        return <Text className="w-64" ellipsis={{ tooltip: label }}>{label}</Text>
      },
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Фактическая концентрация, мг/дм3",
      dataIndex: "actualConcentrationMGDM3",
      key: "actualConcentrationMGDM3",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "тонн/год",
      dataIndex: "tonsYear",
      key: "tonsYear",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: true,
    },
    {
      title: "Объем сброса, м3",
      dataIndex: "dropVolumeM3",
      key: "dropVolumeM3",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: false,
      copyable: true,
      hideOnForm: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: false,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
