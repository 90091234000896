import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFromStorage, history, serializeError, setLocalStorage } from "../../app/helpers";
import { RootState } from "../../app/store";
import { AuthLoginBody, AuthApi, User, UpdateUserDto, UsersApi } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

const token = getFromStorage("token");

export interface AuthState {
  isSignedIn: boolean;
  status: "idle" | "loading" | "failed";
  token?: string;
  authUser?: User;
  error?: ApiError;
}

const initialState: AuthState = {
  isSignedIn: !!token,
  token,
  status: "idle",
};

export const signInAsync = createAsyncThunk("auth/signIn", async (signInData: AuthLoginBody, { rejectWithValue }) => {
  try {
    const authApi = new AuthApi();
    const response = await authApi.authControllerLogin(signInData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchMeAsync = createAsyncThunk("auth/fetchMe", async (_, { rejectWithValue }) => {
  try {
    const authApi = new AuthApi();
    const response = await authApi.authControllerMe();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


export const updateMeAsync = createAsyncThunk("auth/update", async ( usr: {user: UpdateUserDto, id: string}, { rejectWithValue }) => {
  try {
    const usersApi = new UsersApi();
    const response = await usersApi.usersControllerUpdate(usr.user, usr.id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {

  const login = () => {
    const { pending, fulfilled, rejected } = signInAsync;
    return {
      [`${pending}`]: (state: AuthState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AuthState, action: PayloadAction<User>) => {
        const { token, ...rest } = action.payload;
        if (token) {
          state = { status: "idle", isSignedIn: true, token: action.payload.token, authUser: rest };
          message.success("Успешно авторизован");
          setLocalStorage("token", token);
          history.navigate && history.navigate("/");
          return state;
        }
      },
      [`${rejected}`]: (state: AuthState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        if (error.statusCode === 401) error.message = "Неправильный логин или пароль";
        state = { isSignedIn: false, status: "failed", error };
        return state;
      },
    };
  };

  const fetchMe = () => {
    const { pending, fulfilled, rejected } = fetchMeAsync;
    return {
      [`${pending}`]: (state: AuthState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AuthState, action: PayloadAction<User>) => {
        state.authUser = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AuthState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        if (error.statusCode === 401) error.message = "Ваша сессия истекла";
        state = { isSignedIn: false, status: "failed", error };
        return state;
      },
    };
  };

  const updateMe = () => {
    const { pending, fulfilled, rejected } = updateMeAsync;
    return {
      [`${pending}`]: (state: AuthState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AuthState, action: PayloadAction<User>) => {
        state.authUser = action.payload;
        message.success("Успешно обновлен")
        state.status = "idle";
      },
      [`${rejected}`]: (state: AuthState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка!")
        state.error = error;
        return state;
      },
    };
  };
  return { ...login(), ...fetchMe(), ...updateMe() };
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isSignedIn = false
    }
  },
  extraReducers: createExtraReducers(),
});

export const selectIsSignedIn = (state: RootState) => state.auth.isSignedIn;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthUser = (state: RootState) => state.auth.authUser;
export const {logOut} = authSlice.actions

export default authSlice.reducer;
