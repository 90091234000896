import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { AuthApi, SignUpDto, UpdateUserDto, User, UsersApi } from "../../api";
import { AxiosError } from "axios";
import { ApiError } from "../../app/types";
import { message } from "antd";

export interface UsersState {
  users: User[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: UsersState = {
  users: [],
  status: "idle",
};

export const fetchUsersAsync = createAsyncThunk("users/fetch", async (_, { rejectWithValue }) => {
  try {
    const usersApi = new UsersApi();
    const response = await usersApi.usersControllerFindAll();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAsync = createAsyncThunk("users/create", async (user: SignUpDto, { rejectWithValue }) => {
  try {
    const authApi = new AuthApi();
    const response = await authApi.authControllerRegister(user);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUserAsync = createAsyncThunk("users/update", async ({ user, id }: { user: UpdateUserDto; id: string }, { rejectWithValue }) => {
  try {
    const usersApi = new UsersApi();
    const response = await usersApi.usersControllerUpdate(user, id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeUserAsync = createAsyncThunk("users/remove", async (id: string, { rejectWithValue }) => {
  try {
    const usersApi = new UsersApi();
    const response = await usersApi.usersControllerRemove(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchUsers = () => {
    const { pending, fulfilled, rejected } = fetchUsersAsync;
    return {
      [`${pending}`]: (state: UsersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: UsersState, action: PayloadAction<User[]>) => {
        const users = action.payload;
        state.users = users;
        state.status = "idle";
      },
      [`${rejected}`]: (state: UsersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createUser = () => {
    const { pending, fulfilled, rejected } = createUserAsync;
    return {
      [`${pending}`]: (state: UsersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: UsersState, action: PayloadAction<User>) => {
        const user = action.payload;
        state.users.push(user);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: UsersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateUser = () => {
    const { pending, fulfilled, rejected } = updateUserAsync;

    return {
      [`${pending}`]: (state: UsersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: UsersState, action: PayloadAction<User>) => {
        state.users = state.users.map((elem) => (elem.id === action.payload.id ? action.payload : elem));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: UsersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeUser = () => {
    const { pending, fulfilled, rejected } = removeUserAsync;
    return {
      [`${pending}`]: (state: UsersState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: UsersState, action: PayloadAction<User>) => {
        const user = action.payload;
        state.users = state.users.filter((elem) => elem.id !== user.id);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: UsersState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        message.error("Произошла ошибка !");
        state.status = "failed";
        state.error = error;
      },
    };
  };

  return { ...fetchUsers(), ...createUser(), ...updateUser(), ...removeUser() };
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectUsers = (state: RootState) => state.users.users;
export const selectUsersError = (state: RootState) => state.users.error;

export default usersSlice.reducer;
