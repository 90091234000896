import { Form, Modal } from "antd";
import React from "react";
import { CreateSelectionSourceDto } from "../../../api";
import { selectionSourcesColumns } from "../form/columns";

interface SelectionSoucreFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateSelectionSource: (values: CreateSelectionSourceDto) => void;
}

export const SelectionSourceForm: React.FC<SelectionSoucreFormProps> = ({ visible, setVisible, onCreateSelectionSource }) => {
  const [form] = Form.useForm<CreateSelectionSourceDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateSelectionSource}>
        {selectionSourcesColumns().map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
