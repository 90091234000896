import { Form, Input, Modal } from "antd";
import React from "react";
import { UpdateUserDto, User } from "../../../api";
import { useAppDispatch } from "../../../app/hooks";
import { updateMeAsync } from "../../auth/authSlice";

interface UserFormProps {
  user: User,
  visible: boolean;
  setVisible: Function;
}

export const ProfileUpdateForm: React.FC<UserFormProps> = ({ user, visible, setVisible}) => {
  const [form] = Form.useForm<UpdateUserDto>();

  const dispatch = useAppDispatch();
  const id = user && user.id

  const onUpdateMe = (user: UpdateUserDto) => {
    dispatch(updateMeAsync({user, id})).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };


  return (
    <Modal open={visible} onOk={form.submit} okText="Обновить" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onUpdateMe}>

        <Form.Item label="Имя" name="name" initialValue={user && user?.name} rules={[{ required: true, message: "Заполните поле" }]} >
          <Input/>
        </Form.Item>

        <Form.Item label="Телефон" name="phone" required={false} initialValue={user && user?.phone}>
          <Input prefix={"+7"} maxLength={10}/>
        </Form.Item>

      </Form>
    </Modal>
  );
};
