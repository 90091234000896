import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateEmissionDto, EmissionApi, UpdateEmissionDto, Emission } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface EmissionState {
  emission: Emission[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: EmissionState = {
  emission: [],
  status: "idle",
};

export const createEmissionAsync = createAsyncThunk(
  "emission/create",
  async (emission: CreateEmissionDto, { rejectWithValue }) => {
    try {
      const emissionApi = new EmissionApi();
      const response = await emissionApi.emissionControllerCreate(emission);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmissionAsync = createAsyncThunk(
  "emission/update",
  async ({ emission, id }: { emission: UpdateEmissionDto; id: string }, { rejectWithValue }) => {
    try {
      const emissionApi = new EmissionApi();
      const response = await emissionApi.emissionControllerUpdate(emission, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEmissionAsync = createAsyncThunk(
  "emission/remove",
  async ({ emission }: { emission: Emission }, { rejectWithValue }) => {
    try {
      const emissionApi = new EmissionApi();
      const response = await emissionApi.emissionControllerRemove(emission.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEmissionByOrganizationIdYearQuarterAsync = createAsyncThunk("emission/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const emissionApi = new EmissionApi();
    const response = await emissionApi.emissionControllerFindByOrganizationid(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchEmissionByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchEmissionByOrganizationIdYearQuarterAsync;
    return {
      [`${pending}`]: (state: EmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: EmissionState, action: PayloadAction<Emission[]>) => {
        state.emission = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: EmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createEmission = () => {
    const { pending, fulfilled, rejected } = createEmissionAsync;
    return {
      [`${pending}`]: (state: EmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: EmissionState, action: PayloadAction<Emission>) => {
        state.emission.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: EmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateEmission = () => {
    const { pending, fulfilled, rejected } = updateEmissionAsync;

    return {
      [`${pending}`]: (state: EmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: EmissionState, action: PayloadAction<Emission>) => {
        state.emission = state.emission.map((emission) => (emission.id === action.payload.id ? action.payload : emission));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: EmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeEmission = () => {
    const { pending, fulfilled, rejected } = deleteEmissionAsync;
    return {
      [`${pending}`]: (state: EmissionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: EmissionState, action: PayloadAction<Emission>) => {
        state.emission.splice(state.emission.indexOf(
          state.emission.find((ws: Emission) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: EmissionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchEmissionByOrganization(), ...createEmission(), ...updateEmission(), ...removeEmission() };
};

export const emissionSlice = createSlice({
  name: "emission",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectEmission = (state: RootState) => state.emission.emission;

export default emissionSlice.reducer;
