import { Layout, Table } from "antd";
import { useEffect } from "react";
import { Organization } from "../../../../api";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchAnalyticsKpiControllerFindByOrganizationIdYearQuarterAsync, selectAnalyticsKpis } from "../AnalyticsKpiSlice";

interface AnalyticsKpiProps {
  organization?: Organization;
  year?: number;
}

export const AnalyticsKpi: React.FC<AnalyticsKpiProps> = ({ organization, year }) => {
  const dispatch = useAppDispatch();
  const analyticsKpis = useAppSelector(selectAnalyticsKpis);

  useEffect(() => {
    if (organization && year) {
      dispatch(fetchAnalyticsKpiControllerFindByOrganizationIdYearQuarterAsync({ organizationId: organization.id, year }));
    }
  }, [organization, year, dispatch]);

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Table scroll={{ x: "max-content" }}
        columns={[
          {
            title: '',
            dataIndex: 'index',
            key: 'index',
          },
          {
            title: '1 квартал',
            dataIndex: 'q1',
            key: 'q1',
          },
          {
            title: '2 квартал',
            dataIndex: 'q2',
            key: 'q2',
          },
          {
            title: '3 квартал',
            dataIndex: 'q3',
            key: 'q3',
          },
          {
            title: '4 квартал',
            dataIndex: 'q4',
            key: 'q4',
          },
          {
            title: 'Год',
            dataIndex: 'average',
            key: 'average',
          },
        ]}
        dataSource={
          [
            {
              index: 'KPI по парниковым газам,  (т СО2е/тыс.т переработки руды)',
              q1: analyticsKpis?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.gasKpi,
              q2: analyticsKpis?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.gasKpi,
              q3: analyticsKpis?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.gasKpi,
              q4: analyticsKpis?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.gasKpi,
              average: (analyticsKpis?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.gasKpi
                + analyticsKpis?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.gasKpi
                + analyticsKpis?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.gasKpi
                + analyticsKpis?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.gasKpi || 0) / 4,
            },
            {
              index: 'KPI по потреблению воды,  (тыс.м3/тыс.т переработки руды)',
              q1: analyticsKpis?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.waterKpi,
              q2: analyticsKpis?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.waterKpi,
              q3: analyticsKpis?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.waterKpi,
              q4: analyticsKpis?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.waterKpi,
              average: (analyticsKpis?.filter((aPI: any) => aPI.quarter === 1)?.['0']?.waterKpi
                + analyticsKpis?.filter((aPI: any) => aPI.quarter === 2)?.['0']?.waterKpi
                + analyticsKpis?.filter((aPI: any) => aPI.quarter === 3)?.['0']?.waterKpi
                + analyticsKpis?.filter((aPI: any) => aPI.quarter === 4)?.['0']?.waterKpi || 0) / 4,
            },
          ]
        }
      />
    </Layout>
  );
};
