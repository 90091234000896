import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { TaxWastesApi } from "../../api";
import { CreateTaxWasteDto } from "../../api/models/create-tax-waste-dto";
import { TaxWaste } from "../../api/models/tax-waste";
import { UpdateTaxWasteDto } from "../../api/models/update-tax-waste-dto";

export interface TaxWastesState {
  taxWastes: TaxWaste[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: TaxWastesState = {
  taxWastes: [],
  status: "idle",
};

export const createTaxWasteAsync = createAsyncThunk(
  "taxWastes/create",
  async (taxWaste: CreateTaxWasteDto, { rejectWithValue }) => {
    try {
      const taxWastesApi = new TaxWastesApi();
      const response = await taxWastesApi.taxWastesControllerCreate(taxWaste);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaxWasteAsync = createAsyncThunk(
  "taxWastes/update",
  async ({ taxWaste, id }: { taxWaste: UpdateTaxWasteDto; id: string }, { rejectWithValue }) => {
    try {
      const taxWastesApi = new TaxWastesApi();
      const response = await taxWastesApi.taxWastesControllerUpdate(taxWaste, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTaxWasteAsync = createAsyncThunk(
  "taxWastes/remove",
  async ({ taxWaste }: { taxWaste: TaxWaste }, { rejectWithValue }) => {
    try {
      const taxWastesApi = new TaxWastesApi();
      const response = await taxWastesApi.taxWastesControllerRemove(taxWaste.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTaxWastesAsync = createAsyncThunk(
  "taxWastes/fetch", async (_, { rejectWithValue }) => {
    try {
      const taxWastesApi = new TaxWastesApi();
      const response = await taxWastesApi.taxWastesControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchTaxWastes = () => {
    const { pending, fulfilled, rejected } = fetchTaxWastesAsync;
    return {
      [`${pending}`]: (state: TaxWastesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxWastesState, action: PayloadAction<TaxWaste[]>) => {
        state.taxWastes = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxWastesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createTaxWaste = () => {
    const { pending, fulfilled, rejected } = createTaxWasteAsync;
    return {
      [`${pending}`]: (state: TaxWastesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxWastesState, action: PayloadAction<TaxWaste>) => {
        state.taxWastes = [action.payload, ...state.taxWastes];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxWastesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateTaxWaste = () => {
    const { pending, fulfilled, rejected } = updateTaxWasteAsync;

    return {
      [`${pending}`]: (state: TaxWastesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxWastesState, action: PayloadAction<TaxWaste>) => {
        state.taxWastes = state.taxWastes.map((TaxWaste) => (TaxWaste.id === action.payload.id ? action.payload : TaxWaste));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxWastesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeTaxWaste = () => {
    const { pending, fulfilled, rejected } = deleteTaxWasteAsync;
    return {
      [`${pending}`]: (state: TaxWastesState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxWastesState, action: PayloadAction<TaxWaste>) => {
        state.taxWastes.splice(state.taxWastes.indexOf(
          state.taxWastes.find((ws: TaxWaste) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxWastesState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchTaxWastes(), ...createTaxWaste(), ...updateTaxWaste(), ...removeTaxWaste() };
};

export const taxWastesSlice = createSlice({
  name: "taxWastes",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectTaxWastes = (state: RootState) => state.taxWastes.taxWastes;

export default taxWastesSlice.reducer;
