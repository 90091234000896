import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAirEmissionsPointDto, AirEmissionsPointsApi, UpdateAirEmissionsPointDto, AirEmissionsPoint } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface AirEmissionsPointsState {
  airEmissionsPoints: AirEmissionsPoint[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AirEmissionsPointsState = {
  airEmissionsPoints: [],
  status: "idle",
};

export const createAirEmissionsPointAsync = createAsyncThunk(
  "airEmissionsPoints/create",
  async (airEmissionsPoint: CreateAirEmissionsPointDto, { rejectWithValue }) => {
    try {
      const airEmissionsPointsApi = new AirEmissionsPointsApi();
      const response = await airEmissionsPointsApi.airEmissionsPointsControllerCreate(airEmissionsPoint);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAirEmissionsPointAsync = createAsyncThunk(
  "airEmissionsPoints/update",
  async ({ airEmissionsPoint, id }: { airEmissionsPoint: UpdateAirEmissionsPointDto; id: string }, { rejectWithValue }) => {
    try {
      const airEmissionsPointsApi = new AirEmissionsPointsApi();
      const response = await airEmissionsPointsApi.airEmissionsPointsControllerUpdate(airEmissionsPoint, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAirEmissionsPointAsync = createAsyncThunk(
  "airEmissionsPoints/remove",
  async ({ airEmissionsPoint }: { airEmissionsPoint: AirEmissionsPoint }, { rejectWithValue }) => {
    try {
      const airEmissionsPointsApi = new AirEmissionsPointsApi();
      const response = await airEmissionsPointsApi.airEmissionsPointsControllerRemove(airEmissionsPoint.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAirEmissionsPointsByOrganizationidAsync = createAsyncThunk("airEmissionsPoints/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const airEmissionsPointsApi = new AirEmissionsPointsApi();
    const response = await airEmissionsPointsApi.airEmissionsPointsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAirEmissionsPointsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchAirEmissionsPointsByOrganizationidAsync;
    return {
      [`${pending}`]: (state: AirEmissionsPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointsState, action: PayloadAction<AirEmissionsPoint[]>) => {
        state.airEmissionsPoints = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAirEmissionsPoint = () => {
    const { pending, fulfilled, rejected } = createAirEmissionsPointAsync;
    return {
      [`${pending}`]: (state: AirEmissionsPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointsState, action: PayloadAction<AirEmissionsPoint>) => {
        state.airEmissionsPoints.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAirEmissionsPoint = () => {
    const { pending, fulfilled, rejected } = updateAirEmissionsPointAsync;

    return {
      [`${pending}`]: (state: AirEmissionsPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointsState, action: PayloadAction<AirEmissionsPoint>) => {
        state.airEmissionsPoints = state.airEmissionsPoints.map((AirEmissionsPoint) => (AirEmissionsPoint.id === action.payload.id ? action.payload : AirEmissionsPoint));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAirEmissionsPoint = () => {
    const { pending, fulfilled, rejected } = deleteAirEmissionsPointAsync;
    return {
      [`${pending}`]: (state: AirEmissionsPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirEmissionsPointsState, action: PayloadAction<AirEmissionsPoint>) => {
        state.airEmissionsPoints.splice(state.airEmissionsPoints.indexOf(
          state.airEmissionsPoints.find((ws: AirEmissionsPoint) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirEmissionsPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAirEmissionsPointsByOrganization(), ...createAirEmissionsPoint(), ...updateAirEmissionsPoint(), ...removeAirEmissionsPoint() };
};

export const airEmissionsPointsSlice = createSlice({
  name: "airEmissionsPoints",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAirEmissionsPoints = (state: RootState) => state.airEmissionsPoints.airEmissionsPoints;

export default airEmissionsPointsSlice.reducer;
