import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateMaterialDto, MaterialsApi, UpdateMaterialDto, Material } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface MaterialsState {
  materials: Material[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: MaterialsState = {
  materials: [],
  status: "idle",
};

export const createMaterialAsync = createAsyncThunk(
  "materials/create",
  async (material: CreateMaterialDto, { rejectWithValue }) => {
    try {
      const materialsApi = new MaterialsApi();
      const response = await materialsApi.materialsControllerCreate(material);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMaterialAsync = createAsyncThunk(
  "materials/update",
  async ({ material, id }: { material: UpdateMaterialDto; id: string }, { rejectWithValue }) => {
    try {
      const materialsApi = new MaterialsApi();
      const response = await materialsApi.materialsControllerUpdate(material, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMaterialAsync = createAsyncThunk(
  "materials/remove",
  async ({ material }: { material: Material }, { rejectWithValue }) => {
    try {
      const materialsApi = new MaterialsApi();
      const response = await materialsApi.materialsControllerRemove(material.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMaterialsAsync = createAsyncThunk(
  "materials/fetch", async (_, { rejectWithValue }) => {
    try {
      const materialsApi = new MaterialsApi();
      const response = await materialsApi.materialsControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchMaterials = () => {
    const { pending, fulfilled, rejected } = fetchMaterialsAsync;
    return {
      [`${pending}`]: (state: MaterialsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MaterialsState, action: PayloadAction<Material[]>) => {
        state.materials = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: MaterialsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createMaterial = () => {
    const { pending, fulfilled, rejected } = createMaterialAsync;
    return {
      [`${pending}`]: (state: MaterialsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MaterialsState, action: PayloadAction<Material>) => {
        state.materials = [action.payload, ...state.materials];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MaterialsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateMaterial = () => {
    const { pending, fulfilled, rejected } = updateMaterialAsync;

    return {
      [`${pending}`]: (state: MaterialsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MaterialsState, action: PayloadAction<Material>) => {
        state.materials = state.materials.map((Material) => (Material.id === action.payload.id ? action.payload : Material));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MaterialsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeMaterial = () => {
    const { pending, fulfilled, rejected } = deleteMaterialAsync;
    return {
      [`${pending}`]: (state: MaterialsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: MaterialsState, action: PayloadAction<Material>) => {
        state.materials.splice(state.materials.indexOf(
          state.materials.find((ws: Material) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: MaterialsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchMaterials(), ...createMaterial(), ...updateMaterial(), ...removeMaterial() };
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectMaterials = (state: RootState) => state.materials.materials;

export default materialsSlice.reducer;
