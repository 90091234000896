/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface Material
 */
export interface Material {
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    type: MaterialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    deletedAt: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum MaterialTypeEnum {
    Fuel = 'Fuel',
    RawMaterial = 'RawMaterial'
}

