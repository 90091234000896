import { Input, InputNumber, Select } from "antd";
import { TaxEmission } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectPollutionSubstances } from "../../pollutionSubstances/pollutionSubstancesSlice";
import Text from "antd/es/typography/Text";

export const TaxEmissionsColumns = (): EntityColumns<TaxEmission>[] => {

  const pollutionSubstances = useAppSelector(selectPollutionSubstances);

  return [
    {
      title: "№п/п",
      dataIndex: "indexNumber",
      key: "indexNumber",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вид загрязняющего вещества",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Вещества",
      dataIndex: "pollutionSubstances",
      key: "pollutionSubstances",
      inputNode:
        <Select
          mode="multiple"
          showSearch
          filterOption={(input, option) => {
            if (option) {
              return (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }
          }}
          options={pollutionSubstances?.map(
            (pollutionSubstance) => {
              return {
                label: `${pollutionSubstance?.code}-${pollutionSubstance?.name}`,
                value: pollutionSubstance?.code,
              }
            }
          )}
        />,
      render: (pollutionSubstanceCodes: string[]) => pollutionSubstanceCodes?.map(pollutionSubstanceCode => {
        const pollutionSubstance = pollutionSubstances?.find(pollutionSubstance => pollutionSubstance?.code === pollutionSubstanceCode);
        return (
          <div>
            <Text className="w-128 mb-2" ellipsis={{ tooltip: `${pollutionSubstance?.code}-${pollutionSubstance?.name}` }}>
              {pollutionSubstance?.code}-{pollutionSubstance?.name}
            </Text>
          </div>
        );
      }),
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Ставка платы (МРП)",
      dataIndex: "mciRate",
      key: "mciRate",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
  ];
}