import { Breadcrumb, Button, Layout, Modal, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { CreateCategoryVariableDto, CategoryVariable, UpdateCategoryVariableDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { CategoryVariableForm } from "./components/CategoryVariableForm";
import { categoryVariablesColumns } from "./form/columns";
import {
  createCategoryVariableAsync,
  fetchCategoryVariableAsync,
  removeCategoryVariableAsync,
  selectCategoryVariables,
  updateCategoryVariableAsync,
} from "./categoryVariablesSlice";
import { useParams } from "react-router";
import { history } from "../../app/helpers";
import { Link } from "react-router-dom";
import {
  RollbackOutlined,
} from '@ant-design/icons';
import { fetchCategoryProfileAsync, selectCategoryProfile } from "../categories/categoriesSlice";

export const CategoryVariablesComponent: React.FC = () => {
  let { categoryId } = useParams();

  const dispatch = useAppDispatch();
  const categoryVariables = useAppSelector(selectCategoryVariables);
  const categoryProfile = useAppSelector(selectCategoryProfile);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!categoryId) return;

    dispatch(fetchCategoryVariableAsync(categoryId));
    dispatch(fetchCategoryProfileAsync(categoryId))
  }, [categoryId, dispatch]);

  const onCreateCategoryVariable = (categoryVariable: CreateCategoryVariableDto) => {
    if (!categoryId) return;

    dispatch(createCategoryVariableAsync({ ...categoryVariable, categoryId: categoryId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdate = async (categoryVariable: UpdateCategoryVariableDto, id: string) => {
    return dispatch(updateCategoryVariableAsync({ categoryVariable, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onDelete = async (record: CategoryVariable) => {
    return dispatch(removeCategoryVariableAsync(record.id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">

      <Breadcrumb className="mb-4 pollution-bread">
        <Breadcrumb.Item>
          <Tooltip title="Вернуться к списку категорий">
            <Button type="link" onClick={() => { history.navigate && history.navigate(`/organizations/${history?.location?.pathname.split('/')[2]}/module/directory/organizationSource/categories`) }} icon={<RollbackOutlined />} />
          </Tooltip>
          <Link to={`/organizations/${history?.location?.pathname.split('/')[2]}/module/directory/organizationSource/categories`}>Категории</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{categoryProfile?.name}</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      {visible && <CategoryVariableForm visible={visible} setVisible={setVisible} onCreateCategoryVariable={onCreateCategoryVariable} />}
      <EditableTable<CategoryVariable, UpdateCategoryVariableDto>
        onSave={onUpdate}
        onDelete={onDelete}
        entityColumns={categoryVariablesColumns()}
        dataSource={categoryVariables}
        footer={() => addButton}
      />
    </Layout>
  );
};
