import { Input } from "antd";
import { PollutionSubstance } from "../../../api";
import { EntityColumns } from "../../../app/types";


export const pollutionSubstanceColumns: EntityColumns<PollutionSubstance>[] =
[
  {
    title: `Код`,
    dataIndex: "code",
    key: "code",
    inputNode: <Input style={{ width: '100%' }} />,
    rules: [{ required: true, message: "no" }],
    editable: false,
    copyable: true,
  },
  {
    title: `Код ТП`,
    dataIndex: "tpCode",
    key: "tpCode",
    inputNode: <Input style={{ width: '100%' }} />,
    rules: [{ required: false, message: "no" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Наименование загрязнения",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
];