import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";
import { RopCalculationCable } from "../../api/models/rop-calculation-cable";
import { CreateRopCalculationsCableDto } from "../../api/models/create-rop-calculations-cable-dto";
import { RopCalculationsCableApi } from "../../api/apis/rop-calculations-cable-api";
import { UpdateRopCalculationsCableDto } from "../../api/models/update-rop-calculations-cable-dto";

export interface RopCalculationsCableState {
    ropCalculations: RopCalculationCable[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: RopCalculationsCableState = {
    ropCalculations: [],
    status: "idle",
};

export const createRopCalculationCableAsync = createAsyncThunk(
    "ropCalculationsCable/create",
    async (ropCalculation: CreateRopCalculationsCableDto, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsCableApi();
            const response = await ropCalculationsApi.ropCalculationsCableControllerCreate(ropCalculation);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateRopCalculationCableAsync = createAsyncThunk(
    "ropCalculationsCable/update",
    async ({ ropCalculation, id }: { ropCalculation: UpdateRopCalculationsCableDto; id: string }, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsCableApi();
            const response = await ropCalculationsApi.ropCalculationsCableControllerUpdate(ropCalculation, id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteRopCalculationCableAsync = createAsyncThunk(
    "ropCalculationsCable/remove",
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const ropCalculationsApi = new RopCalculationsCableApi();
            const response = await ropCalculationsApi.ropCalculationsCableControllerRemove(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchRopCalculationsCableByQuarterAsync = createAsyncThunk(
    "ropCalculationsCable/fetch",
    async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
        try {
            const ropCalculationApi = new RopCalculationsCableApi();
            const response = await ropCalculationApi.ropCalculationsCableControllerFindByQuart(organizationId, year, quarter);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchRopCalculationsCableBySite = () => {
        const { pending, fulfilled, rejected } = fetchRopCalculationsCableByQuarterAsync;
        return {
            [`${pending}`]: (state: RopCalculationsCableState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsCableState, action: PayloadAction<RopCalculationCable[]>) => {
                state.ropCalculations = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsCableState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const createRopCalculation = () => {
        const { pending, fulfilled, rejected } = createRopCalculationCableAsync;
        return {
            [`${pending}`]: (state: RopCalculationsCableState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsCableState, action: PayloadAction<RopCalculationCable>) => {
                state.ropCalculations.push(action.payload);
                message.success("Успешно создан");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsCableState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const updateRopCalculation = () => {
        const { pending, fulfilled, rejected } = updateRopCalculationCableAsync;

        return {
            [`${pending}`]: (state: RopCalculationsCableState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsCableState, action: PayloadAction<RopCalculationCable>) => {
                state.ropCalculations = state.ropCalculations.map((ropCalculation) => (ropCalculation.id === action.payload.id ? action.payload : ropCalculation));
                message.success("Успешно изменено");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsCableState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    const removeRopCalculation = () => {
        const { pending, fulfilled, rejected } = deleteRopCalculationCableAsync;
        return {
            [`${pending}`]: (state: RopCalculationsCableState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopCalculationsCableState, action: PayloadAction<RopCalculationCable>) => {
                state.ropCalculations.splice(state.ropCalculations.indexOf(
                    state.ropCalculations.find((ws: RopCalculationCable) => ws.id === action.payload.id) || action.payload
                ), 1);
                message.success("Успешно удален");
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopCalculationsCableState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    return { ...fetchRopCalculationsCableBySite(), ...createRopCalculation(), ...updateRopCalculation(), ...removeRopCalculation() };
};

export const RopCalculationsCableSlice = createSlice({
    name: "RopCalculationsCable",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
});

export const selectRopCalculationsCable = (state: RootState) => state.ropCalculationsCable.ropCalculations;

export default RopCalculationsCableSlice.reducer;
