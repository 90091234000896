import { Checkbox, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { PollutionSource } from "../../../api";
import { EntityColumns } from "../../../app/types";

const PollutionSourcesColumns = (): EntityColumns<PollutionSource>[] => {
  // const workshops = useAppSelector(selectWorkshops);

  return [
    // {
    //   title: "Цех",
    //   dataIndex: "workshop",
    //   key: "workshopId",
    //   inputNode: (
    //     <Select
    //       options={workshops.map((workshop) => {
    //         return {
    //           value: workshop.id,
    //           label: `${workshop?.code} - ${workshop?.name}`,
    //         };
    //       })}
    //     />
    //   ),
    //   render: (workshop) => (
    //     <Text ellipsis={{ tooltip: `${workshop?.code} - ${workshop?.name}` }}>
    //       {workshop?.code} - {workshop?.name}
    //     </Text>
    //   ),
    //   rules: [{ required: true, message: "Заполните поле" }],
    //   editable: true,
    //   copyable: true,
    // },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Инвентарный номер",
      dataIndex: "inventoryNumber",
      key: "inventoryNumber",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Максимальное фактическое и нормативное значение Г/C в ПЭК",
      dataIndex: "maxActualAndStarndardGSIntoReportPEK",
      key: "maxActualAndStarndardGSIntoReportPEK",
      inputNode:
        <Form.Item name="maxActualAndStarndardGSIntoReportPEK" initialValue={false} valuePropName="checked" noStyle>
          <Checkbox defaultChecked={false} />
        </Form.Item>,
      render: (maxActualAndStarndardGSIntoReportPEK) => <Checkbox checked={maxActualAndStarndardGSIntoReportPEK} />,
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => (
        <Text className="w-64" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      editable: true,
      copyable: true,
    },
  ];
};

export const pollutionSourcesColumns = PollutionSourcesColumns;
