import { Checkbox, Form, Input, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { WasteContragent } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const wasteContragentsColumns: EntityColumns<WasteContragent>[] = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "№ договора и дата заключения",
    dataIndex: "contractNumberAndDateOfConclusion",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Бин",
    dataIndex: "bin",
    inputNode: <Input />,
    rules: [
      { required: true, message: "Заполните поле" },
      { message: "БИН неправильного формата", pattern: new RegExp("^[0-9]{12}$") },
    ],
    editable: true,
    copyable: true,
  },
  {
    title: "Прием",
    dataIndex: "hasReception",
    key: "hasReception",
    inputNode: 
    <Form.Item name="hasReception" initialValue={false} valuePropName="checked" noStyle>
      <Checkbox defaultChecked={false} />
    </Form.Item>,
    render: (b) => <Checkbox checked={b} />,
    editable: true,
    copyable: true,
  },
  {
    title: "Передача",
    dataIndex: "hasTransmission",
    key: "hasTransmission",
    inputNode: 
    <Form.Item name="hasTransmission" initialValue={false} valuePropName="checked" noStyle>
      <Checkbox defaultChecked={false} />
    </Form.Item>,
    render: (b) => <Checkbox checked={b} />,
    editable: true,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
];
