import { Form, Modal } from "antd";
import React from "react";
import { CreateTaxDropDto } from "../../../api";
import { TaxDropsColumns } from "../form/columns";

interface TaxDropFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateTaxDrop: (values: CreateTaxDropDto) => void;
}

export const TaxDropForm: React.FC<TaxDropFormProps> = ({ visible, setVisible, onCreateTaxDrop }) => {
  const [form] = Form.useForm<CreateTaxDropDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateTaxDrop}>
        {TaxDropsColumns().map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
          {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
