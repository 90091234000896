import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateAirSzzPointDto, AirSzzPointsApi, UpdateAirSzzPointDto, AirSzzPoint } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface AirSzzPointsState {
  airSzzPoints: AirSzzPoint[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: AirSzzPointsState = {
  airSzzPoints: [],
  status: "idle",
};

export const createAirSzzPointAsync = createAsyncThunk(
  "airSzzPoints/create",
  async (airSzzPoint: CreateAirSzzPointDto, { rejectWithValue }) => {
    try {
      const airSzzPointsApi = new AirSzzPointsApi();
      const response = await airSzzPointsApi.airSzzPointsControllerCreate(airSzzPoint);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAirSzzPointAsync = createAsyncThunk(
  "airSzzPoints/update",
  async ({ airSzzPoint, id }: { airSzzPoint: UpdateAirSzzPointDto; id: string }, { rejectWithValue }) => {
    try {
      const airSzzPointsApi = new AirSzzPointsApi();
      const response = await airSzzPointsApi.airSzzPointsControllerUpdate(airSzzPoint, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAirSzzPointAsync = createAsyncThunk(
  "airSzzPoints/remove",
  async ({ airSzzPoint }: { airSzzPoint: AirSzzPoint }, { rejectWithValue }) => {
    try {
      const airSzzPointsApi = new AirSzzPointsApi();
      const response = await airSzzPointsApi.airSzzPointsControllerRemove(airSzzPoint.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const copyAirSzzPointAsync = createAsyncThunk(
  "airSzzPoints/copy",
  async ({id} : { id: string }, { rejectWithValue }) => {
    try {
      const airSzzPointsApi = new AirSzzPointsApi();
      const response = await airSzzPointsApi.airSzzPointsControllerCopy(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAirSzzPointsByOrganizationidAsync = createAsyncThunk("airSzzPoints/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const airSzzPointsApi = new AirSzzPointsApi();
    const response = await airSzzPointsApi.airSzzPointsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchAirSzzPointsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchAirSzzPointsByOrganizationidAsync;
    return {
      [`${pending}`]: (state: AirSzzPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointsState, action: PayloadAction<AirSzzPoint[]>) => {
        state.airSzzPoints = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createAirSzzPoint = () => {
    const { pending, fulfilled, rejected } = createAirSzzPointAsync;
    return {
      [`${pending}`]: (state: AirSzzPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointsState, action: PayloadAction<AirSzzPoint>) => {
        state.airSzzPoints.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateAirSzzPoint = () => {
    const { pending, fulfilled, rejected } = updateAirSzzPointAsync;

    return {
      [`${pending}`]: (state: AirSzzPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointsState, action: PayloadAction<AirSzzPoint>) => {
        state.airSzzPoints = state.airSzzPoints.map((AirSzzPoint) => (AirSzzPoint.id === action.payload.id ? action.payload : AirSzzPoint));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeAirSzzPoint = () => {
    const { pending, fulfilled, rejected } = deleteAirSzzPointAsync;
    return {
      [`${pending}`]: (state: AirSzzPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointsState, action: PayloadAction<AirSzzPoint>) => {
        state.airSzzPoints.splice(state.airSzzPoints.indexOf(
          state.airSzzPoints.find((ws: AirSzzPoint) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const copyAirSzzPoint = () => {
    const { pending, fulfilled, rejected } = copyAirSzzPointAsync;
    return {
      [`${pending}`]: (state: AirSzzPointsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: AirSzzPointsState, action: PayloadAction<AirSzzPoint>) => {
        state.airSzzPoints.push(action.payload);
        message.success("Успешно скопирован");
        state.status = "idle";
      },
      [`${rejected}`]: (state: AirSzzPointsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchAirSzzPointsByOrganization(), ...createAirSzzPoint(), ...updateAirSzzPoint(), ...removeAirSzzPoint(), ...copyAirSzzPoint() };
};

export const airSzzPointsSlice = createSlice({
  name: "airSzzPoints",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectAirSzzPoints = (state: RootState) => state.airSzzPoints.airSzzPoints;

export default airSzzPointsSlice.reducer;
