import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiError } from "../../app/types";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { RopTransportClassifier } from "../../api/models/rop-transport-classifier";
import { RopTransportClassifiersApi } from "../../api/apis/rop-transport-classifiers-api";

export interface RopTransportClassifierState {
    classifiers: RopTransportClassifier[];
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: RopTransportClassifierState = {
    classifiers: [],
    status: "idle",
};

export const fetchRopTransportClassifiersAsync = createAsyncThunk(
    "ropTransportClassifiers/fetch", async (_, { rejectWithValue }) => {
        try {
            const classifierApi = new RopTransportClassifiersApi();
            const response = await classifierApi.ropTransportClassifiersControllerFindAll();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

const createExtraReducers = () => {
    const fetchRopTransportClassifiers = () => {
        const { pending, fulfilled, rejected } = fetchRopTransportClassifiersAsync;
        return {
            [`${pending}`]: (state: RopTransportClassifierState) => {
                console.log("test" + state);
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: RopTransportClassifierState, action: PayloadAction<RopTransportClassifier[]>) => {
                state.classifiers = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: RopTransportClassifierState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    return { ...fetchRopTransportClassifiers()};
}

export const ropTransportClassifiersSlice = createSlice({
    name: "ropTransportClassifiers",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
  });

  export const selectRopTransportClassifiers = (state: RootState) => state.ropTransportClassifers.classifiers;
  
  export default ropTransportClassifiersSlice.reducer;
