import { Breadcrumb, Layout, Radio, RadioChangeEvent, Select, Tabs } from "antd";
import React, { useEffect } from "react";
import { BuildOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useParams } from "react-router";
import { HomeOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { PermissionsComponent } from "../premissions/PermissionsComponent";
import { WasteStandardsComponent } from "../wasteStandards/WasteStandardsComponent";
import { WasteAccumulationSitesComponent } from "../wasteAccumulationSites/WasteAccumulationSitesComponent";
import { WasteBurialSitesComponent } from "../wasteBurialSites/WasteBurialSitesComponent";
import Text from "antd/es/typography/Text";
import { WasteEquipmentRecyclesComponent } from "../wasteEquipmentRecycles/WasteEquipmentRecyclesComponent";
import { WasteEquipmentSortsComponent } from "../wasteEquipmentSorts/WasteEquipmentSortsComponent";
import { ContragentsComponent } from "../laboratories/ContragentsComponent";
import { LaboratoryPointsComponent } from "../laboratories/LaboratoryPointsComponent";
import { LaboratoryJournalsComponent } from "../laboratories/LaboratoryJournalsComponent";
import { fetchOrganizationProfileAsync, selectOrganizationsProfile } from "../organizationProfile/organizationProfileSlice";
import { selectOrganizationModulePeriod, setPeriodAsync } from "./OrganizationModuleSlice";
import { WasteJournalsComponent } from "../wasteJournals/WasteJournalsComponent";
import { ReportPeksComponent } from "../reportPeks/ReportPeksComponent";
import { MethodicsComponent } from "../methodics/MethodicsComponent";
import { CalculationsListComponent } from "../calculations/CalculcationsListComponent";
import { PollutionSubstancesComponent } from "../pollutionSubstances/PollutionSubstancesComponent";
import { OrganizationSourceComponent } from "../organizationProfile/OrganizationSourceComponent";
import { WorkshopStandardComponent } from "../standards/WorkshopStandardsComponent";
import { PeriodDataComponent } from "../periodData/PeriodDataComponent";
import { history } from "../../app/helpers";
import { DropComponent } from "../drops/DropsComponent";
import { InfluenceObjectComponent } from "../influenceObjects/InfluenceObjectsComponent";
import { DropCodesComponent } from "../dropCodes/DropCodesComponent";
import { DropPeriodComponent } from "../dropPeriods/DropPeriodsComponent";
import { WaterUseComponent } from "../waterUse/WaterUseComponent";
import { Link } from "react-router-dom";
import { TaxComponent } from "../taxes/TaxesComponent";
import { TotalEmissionsComponent } from "../totalEmissions/TotalEmissionsComponent";
import { ReportPaymentsComponent } from "../reportPayments/ReportPaymentsComponent";
import { MaterialsComponent } from "../materials/MaterialsComponent";
import { RopCalculationsComponent } from "../rop/RopCalculationsComponent";
import { RopCalculationsCableComponent } from "../ropCable/RopCalculationsCableComponent";
import { RopCalculationsTransportComponent } from "../ropTransport/RopCalculationsTransportComponent";
import { RopDocumentsComponent } from "../ropDocuments/RopDocumentsComponent";
import { WaterConsumptionObjectsComponent } from "../waterConsumptionObject/WaterConsumptionObjectsComponent";
import { WaterDrainageObjectsComponent } from "../waterDrainageObjects/WaterDrainageObjectsComponent";
import { ReportStaticComponent } from "../reportStatic/ReportStaticComponent";
import { CarbonComponent } from "../carbon/CarbonComponent";
import { AnalyticsProductionForKpisComponent } from "../analytics/analyticsProductionForKpis/AnalyticsProductionForKpisComponent";
import { AnalyticsProductionIndicator } from "../analytics/analyticsProductionIndicator/components/AnalyticsProductionIndicator";
import { AnalyticsKpi } from "../analytics/analyticsKpi/components/AnalyticsKpi";
import { selectAuthUser } from "../auth/authSlice";
import { UserRolesEnum } from "../../api";
import { WasteApplicationsComponent } from "../wasteApplications/WasteApplicationsComponent";
import { WasteManagementDocumentsComponent } from "../wasteManagementMethodicalDocuments/WasteManagementDocumentsComponent";
import { ReportRVPZComponent } from "../reportRVPZ/ReportRVPZComponent";
import { ReportInventorizationWasteComponent } from "../reportInvenotrizationWaste/ReportInventorizationWasteComponent";

export const OrganizationModuleComponent: React.FC = () => {
  let { id, code } = useParams();

  const period = useAppSelector(selectOrganizationModulePeriod);
  const years = Array(period.year - (period.year - 20))
    .fill("")
    .map((_, idx) => {
      return {
        label: period.year - (idx - 10),
        value: +(period.year - (idx - 10)),
      };
    });

  const authUser = useAppSelector(selectAuthUser);

  const organization = useAppSelector(selectOrganizationsProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchOrganizationProfileAsync(id));
    }
  }, [dispatch, id]);

  interface TabItems {
    [key: string]: {
      label: string;
      data: any;
    };
  }

  const items: TabItems = {
    directory: {
      label: "Справочники",
      data: [
        {
          label: <span>Разрешения</span>,
          key: "permissions",
          children: <PermissionsComponent permissions={organization?.permissions} organizationId={organization?.id} />,
          roles: [],
        },
        {
          label: <span>Исходные данные</span>,
          key: "organizationSource",
          children: <OrganizationSourceComponent industrialSites={organization?.industrialSites} organizationId={organization?.id} code={code} />,
          roles: [],
        },
        {
          label: <span>Расчетные методики</span>,
          key: "methodics",
          children: <MethodicsComponent />,
          roles: [],
        },
        {
          label: <span>Места накопления</span>,
          key: "wasteAccumulationSites",
          children: <WasteAccumulationSitesComponent organizationId={organization?.id} />,
          roles: [],
        },
        {
          label: <span>Места захоронения</span>,
          key: "wasteBurialSites",
          children: <WasteBurialSitesComponent organizationId={organization?.id} />,
          roles: [],
        },
        {
          label: <span>Оборудование по переработке отходов</span>,
          key: "wasteEquipmentRecycles",
          children: <WasteEquipmentRecyclesComponent organizationId={organization?.id} />,
          roles: [],
        },
        {
          label: <span>Оборудование по сортировке отходов</span>,
          key: "wasteEquipmentSorts",
          children: <WasteEquipmentSortsComponent organizationId={organization?.id} />,
          roles: [],
        },
        // {
        //   label: <span>Коды загрязнений</span>,
        //   key: "pollutantCodes",
        //   children: <PollutantCodesComponent />,
        // },
        {
          label: <span>Справочник веществ</span>,
          key: "pollutionSubstances",
          children: <PollutionSubstancesComponent />,
          roles: [],
        },
        {
          label: <span>Объекты воздействия</span>,
          key: "influenceObject",
          children: <InfluenceObjectComponent organizationId={organization?.id} />,
          roles: [],
        },
        {
          label: <span>Вещества сбросы</span>,
          key: "dropCodes",
          children: <DropCodesComponent />,
          roles: [],
        },
        {
          label: <span>Справочник налоговых ставок</span>,
          key: "taxes",
          children: <TaxComponent />,
          roles: [],
        },
        {
          label: <span>Виды потребляемого сырья/ материала</span>,
          key: "materials",
          children: <MaterialsComponent />,
          roles: [],
        },
        {
          label: <span>Объекты водопотребления</span>,
          key: "waterConsumptionObjectsComponent",
          children: <WaterConsumptionObjectsComponent />,
          roles: [],
        },
        {
          label: <span>Объекты водоотведения</span>,
          key: "waterDrainageObjectsComponent",
          children: <WaterDrainageObjectsComponent />,
          roles: [],
        },
        {
          label: <span>Методические документы в области управления отходами</span>,
          key: "wasteManagementMethodicalDocumentsComponent",
          children: <WasteManagementDocumentsComponent organizationId={organization?.id}/>,
          roles: []
        }
      ],
    },
    regulatoryData: {
      label: "Нормативные данные",
      data: [
        {
          label: <span>Выбросы</span>,
          key: "emissions",
          children: organization && <WorkshopStandardComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Сбросы</span>,
          key: "drops",
          children: <DropComponent organizationId={organization?.id} />,
          roles: [],
        },
        {
          label: <span>Отходы</span>,
          key: "waste",
          children: <WasteStandardsComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>ППМ</span>,
          key: "ppm",
          children: <></>,
          roles: [],
        },
      ],
    },
    periodData: {
      label: "Данные за период",
      data: [
        {
          label: "Данные за период, выбросы",
          key: "periodData",
          children: <PeriodDataComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: ['surveyor', 'engineer', 'tailings', 'career', 'mechanical', 'atc', 'depot', 'rsg', 'oks', 'ahs', 'cl', 'icu', 'vet', 'of', 'concentrator', 'gardening', 'peo', 'accounting',],
        },
        {
          label: "Данные за период, сбросы",
          key: "periodDataDrops",
          children: <DropPeriodComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: ['engineer', 'tailings', 'career',],
        },
        {
          label: "Водопользование",
          key: "waterUse",
          children: <WaterUseComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: ['engineer', 'tailings', 'career',],
        },
        {
          label: "Отходы",
          key: "wasteApplications",
          children: <WasteApplicationsComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: ['surveyor', 'engineer', 'tailings', 'career', 'mechanical', 'atc', 'depot', 'rsg', 'oks', 'ahs', 'cl', 'icu', 'of', 'concentrator', 'accounting',],
        },
      ]
    },
    contragents: {
      label: "Контрагенты",
      data: [
        {
          label: (
            <span>
              <BuildOutlined />
              Контрагенты
            </span>
          ),
          key: "contragents",
          children: <ContragentsComponent organizationId={organization?.id} />,
          roles: [],
        },
      ],
    },
    laboratoryPoints: {
      label: "План график контроля",
      data: [
        {
          label: (
            <span>
              <BuildOutlined />
              План график контроля
            </span>
          ),
          key: "laboratoryPoints",
          children: <LaboratoryPointsComponent organizationId={organization?.id} />,
          roles: [],
        },
      ],
    },
    laboratoryJournals: {
      label: "Результаты измерений",
      data: [
        {
          label: (
            <span>
              <BuildOutlined />
              Результаты измерений
            </span>
          ),
          key: "laboratoryJournals",
          children: <LaboratoryJournalsComponent organizationId={organization?.id} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
      ],
    },
    wasteManagement: {
      label: "Управление отходами",
      data: [
        {
          label: <span>Журнал учета объемов образования и обращения с отходами</span>,
          key: "wasteJournals",
          children: <WasteJournalsComponent organizationId={organization?.id} year={period.year} quarter={period.quarter} />,
          roles: ['surveyor', 'engineer', 'tailings', 'career', 'mechanical', 'atc', 'depot', 'rsg', 'oks', 'ahs', 'cl', 'icu', 'of', 'accounting',],
        },
      ],
    },
    reports: {
      label: "Отчеты",
      data: [
        {
          label: <span>Отчеты ПЭК</span>,
          key: "reportPeks",
          children: <ReportPeksComponent organizationId={organization?.id} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Эмиссии (итог)</span>,
          key: "totalEmissions",
          children: <TotalEmissionsComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Платежи</span>,
          key: "reportPayments",
          children: <ReportPaymentsComponent organizationId={organization?.id} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Статистические отчеты</span>,
          key: "reportStatics",
          children: <ReportStaticComponent organizationId={organization?.id} year={period.year} />,
          roles: [],
        },
        {
          label: <span>РВПЗ</span>,
          key: "reportRVPZ",
          children: <ReportRVPZComponent organizationId={organization?.id} year={period.year} quarter={period.quarter} />,
          roles:[],
        },
        {
          label: <span>Отчет по инвентаризации отходов</span>,
          key: "reportInventorizationWaste",
          children: <ReportInventorizationWasteComponent organizationId={organization?.id} year={period.year} quarter={period.quarter} />,
          roles:[],
        }
      ],
    },
    emissions: {
      label: "Мониторинг эмиссий",
      data: [
        {
          label: <span>Расчеты</span>,
          key: "calculations",
          children: <CalculationsListComponent organizationId={organization?.id || ""} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
      ],
    },
    rop: {
      label: "РОП",
      data: [
        {
          label: <span>Платежи за упаковку и компоненты</span>,
          key: "rop",
          children: <RopCalculationsComponent organizationId={organization?.id || ""} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Платежи за кабель</span>,
          key: "ropCable",
          children: <RopCalculationsCableComponent organizationId={organization?.id || ""} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Платежи за транспорт</span>,
          key: "ropTransport",
          children: <RopCalculationsTransportComponent organizationId={organization?.id || ""} year={period.year} quarter={period.quarter} />,
          roles: [],
        },
        {
          label: <span>Документы РОП</span>,
          key: "ropDocument",
          children: <RopDocumentsComponent organizationId={organization?.id || ""} year={period.year} quarter={period.quarter} />,
          roles: [],
        }
      ]
    },
    carbon: {
      label: "Углеродный след",
      data: [
        {
          label: <span>Углеродный след</span>,
          key: "carbon",
          children: <CarbonComponent organization={organization} year={period.year} quarter={period.quarter} />,
          roles: [],
        }
      ]
    },
    analytics: {
      label: "Аналитика",
      data: [
        {
          label: <span>Данные по производству</span>,
          key: "analyticsProductionForKpi",
          children: <AnalyticsProductionForKpisComponent organization={organization} year={period.year} />,
          roles: [],
        },
        {
          label: <span>Производственный показатель</span>,
          key: "analyticsProductionIndicator",
          children: <AnalyticsProductionIndicator organization={organization} year={period.year} />,
          roles: [],
        },
        {
          label: <span>KPI</span>,
          key: "analyticsKpi",
          children: <AnalyticsKpi organization={organization} year={period.year} />,
          roles: [],
        }
      ]
    },
  };

  const onChangeQuarter = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(
      setPeriodAsync({
        quarter: value,
        year: period.year,
      })
    );
  };

  const onChangeYear = (value: number) => {
    dispatch(
      setPeriodAsync({
        quarter: period.quarter,
        year: value,
      })
    );
  };

  return (
    <>
      <Layout className="p-4 rounded-lg overflow-scroll">
        <Title className="ml-2 !mt-0" level={3}>
          Организация «{organization?.name}»
        </Title>

        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>
            <Link to={`/`}><HomeOutlined /></Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/organizations/${id}`}>Профиль организации</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{items[code || "regulatoryData"].label}</Breadcrumb.Item>
        </Breadcrumb>

        <Tabs
          destroyInactiveTabPane={true}
          className="organization-modules bg-white rounded-lg p-4"
          type="card"
          onChange={(path) => {
            history.navigate && history.navigate(`organizations/${organization?.id}/module/${code}/${path}`);
          }}
          defaultActiveKey={history?.location?.pathname.split('/')[5]}
          items={
            items[code || "regulatoryData"]
              .data
              .filter((item: any) => {
                if (item.roles.some((role: any) => authUser?.roles.includes(role)) || authUser?.roles.includes(UserRolesEnum.Admin) || authUser?.roles.includes(UserRolesEnum.Chief) || authUser?.roles.includes(UserRolesEnum.Head) || authUser?.roles.includes(UserRolesEnum.Ecolog)) {
                  return true;
                } else {
                  return false;
                }
              })
          }
          tabBarExtraContent={
            <div className="p-4">
              <Text className="mr-2">Квартал:</Text>
              <Radio.Group
                className="mr-2"
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                ]}
                onChange={onChangeQuarter}
                value={period.quarter}
                optionType="button"
                buttonStyle="solid"
              />
              <Text className="mr-2">Год:</Text>
              <Select onChange={onChangeYear} defaultValue={period.year} options={years} />
            </div>
          }
        />
      </Layout>
    </>
  );
};
