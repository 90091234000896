import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterPointPollutantDto, WaterPointPollutantsApi, UpdateWaterPointPollutantDto, WaterPointPollutant } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WaterPointPollutantsState {
  waterPointPollutants: WaterPointPollutant[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterPointPollutantsState = {
  waterPointPollutants: [],
  status: "idle",
};

export const createWaterPointPollutantAsync = createAsyncThunk(
  "waterPointPollutants/create",
  async (waterPointPollutant: CreateWaterPointPollutantDto, { rejectWithValue }) => {
    try {
      const waterPointPollutantsApi = new WaterPointPollutantsApi();
      const response = await waterPointPollutantsApi.waterPointPollutantsControllerCreate(waterPointPollutant);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterPointPollutantAsync = createAsyncThunk(
  "waterPointPollutants/update",
  async ({ waterPointPollutant, id }: { waterPointPollutant: UpdateWaterPointPollutantDto; id: string }, { rejectWithValue }) => {
    try {
      const waterPointPollutantsApi = new WaterPointPollutantsApi();
      const response = await waterPointPollutantsApi.waterPointPollutantsControllerUpdate(waterPointPollutant, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterPointPollutantAsync = createAsyncThunk(
  "waterPointPollutants/remove",
  async ({ waterPointPollutant }: { waterPointPollutant: WaterPointPollutant }, { rejectWithValue }) => {
    try {
      const waterPointPollutantsApi = new WaterPointPollutantsApi();
      const response = await waterPointPollutantsApi.waterPointPollutantsControllerRemove(waterPointPollutant.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterPointPollutantsByPointAsync = createAsyncThunk("waterPointPollutants/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const waterPointPollutantsApi = new WaterPointPollutantsApi();
    const response = await waterPointPollutantsApi.waterPointPollutantsControllerFindByPointId(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterPointPollutantsByPoint = () => {
    const { pending, fulfilled, rejected } = fetchWaterPointPollutantsByPointAsync;
    return {
      [`${pending}`]: (state: WaterPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointPollutantsState, action: PayloadAction<WaterPointPollutant[]>) => {
        state.waterPointPollutants = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterPointPollutant = () => {
    const { pending, fulfilled, rejected } = createWaterPointPollutantAsync;
    return {
      [`${pending}`]: (state: WaterPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointPollutantsState, action: PayloadAction<WaterPointPollutant>) => {
        state.waterPointPollutants.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterPointPollutant = () => {
    const { pending, fulfilled, rejected } = updateWaterPointPollutantAsync;

    return {
      [`${pending}`]: (state: WaterPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointPollutantsState, action: PayloadAction<WaterPointPollutant>) => {
        state.waterPointPollutants = state.waterPointPollutants.map((waterPointPollutant) => (waterPointPollutant.id === action.payload.id ? action.payload : waterPointPollutant));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterPointPollutant = () => {
    const { pending, fulfilled, rejected } = deleteWaterPointPollutantAsync;
    return {
      [`${pending}`]: (state: WaterPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterPointPollutantsState, action: PayloadAction<WaterPointPollutant>) => {
        state.waterPointPollutants.splice(state.waterPointPollutants.indexOf(
          state.waterPointPollutants.find((ws: WaterPointPollutant) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterPointPollutantsByPoint(), ...createWaterPointPollutant(), ...updateWaterPointPollutant(), ...removeWaterPointPollutant() };
};

export const waterPointPollutantsSlice = createSlice({
  name: "waterPointPollutants",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterPointPollutants = (state: RootState) => state.waterPointPollutants.waterPointPollutants;

export default waterPointPollutantsSlice.reducer;
