import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteBurialSiteDto, WasteBurialSite, UpdateWasteBurialSiteDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WasteBurialSiteForm } from "./components/WasteBurialSitesForm";
import { wasteBurialSitesColumns } from "./form/columns";
import { createWasteBurialSiteAsync, updateWasteBurialSiteAsync, fetchWasteBurialSitesByOrganizationAsync, selectWasteBurialSites, deleteWasteBurialSiteAsync } from "./WasteBurialSitesSlice";

interface WasteBurialSitesComponentProps {
  organizationId?: string;
}

export const WasteBurialSitesComponent: React.FC<WasteBurialSitesComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const wasteBurialSites = useAppSelector(selectWasteBurialSites);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWasteBurialSitesByOrganizationAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdate = async (wasteBurialSite: UpdateWasteBurialSiteDto, id: string) => {
    return dispatch(updateWasteBurialSiteAsync({ wasteBurialSite, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteBurialSite = (wasteBurialSite: CreateWasteBurialSiteDto) => {
    if (!organizationId) return;

    dispatch(createWasteBurialSiteAsync({ ...wasteBurialSite, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteBurialSites = (wasteBurialSite: WasteBurialSite) => {
    return dispatch(deleteWasteBurialSiteAsync({ wasteBurialSite })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить площадку захоронения
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteBurialSiteForm visible={visible} setVisible={setVisible} onCreateWasteBurialSite={onCreateWasteBurialSite} />}
      <EditableTable<WasteBurialSite, UpdateWasteBurialSiteDto>
        onSave={onUpdate}
        onDelete={deleteWasteBurialSites}
        entityColumns={wasteBurialSitesColumns}
        dataSource={wasteBurialSites}
        footer={() => addButton}
      />
    </Layout>
  );
};
