import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateTaxEmissionDto, TaxEmissionsApi, UpdateTaxEmissionDto, TaxEmission } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface TaxEmissionsState {
  taxEmissions: TaxEmission[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: TaxEmissionsState = {
  taxEmissions: [],
  status: "idle",
};

export const createTaxEmissionAsync = createAsyncThunk(
  "taxEmissions/create",
  async (taxEmission: CreateTaxEmissionDto, { rejectWithValue }) => {
    try {
      const taxEmissionsApi = new TaxEmissionsApi();
      const response = await taxEmissionsApi.taxEmissionsControllerCreate(taxEmission);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaxEmissionAsync = createAsyncThunk(
  "taxEmissions/update",
  async ({ taxEmission, id }: { taxEmission: UpdateTaxEmissionDto; id: string }, { rejectWithValue }) => {
    try {
      const taxEmissionsApi = new TaxEmissionsApi();
      const response = await taxEmissionsApi.taxEmissionsControllerUpdate(taxEmission, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTaxEmissionAsync = createAsyncThunk(
  "taxEmissions/remove",
  async ({ taxEmission }: { taxEmission: TaxEmission }, { rejectWithValue }) => {
    try {
      const taxEmissionsApi = new TaxEmissionsApi();
      const response = await taxEmissionsApi.taxEmissionsControllerRemove(taxEmission.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTaxEmissionsAsync = createAsyncThunk(
  "taxEmissions/fetch", async (_, { rejectWithValue }) => {
    try {
      const taxEmissionsApi = new TaxEmissionsApi();
      const response = await taxEmissionsApi.taxEmissionsControllerFindAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const createExtraReducers = () => {
  const fetchTaxEmissions = () => {
    const { pending, fulfilled, rejected } = fetchTaxEmissionsAsync;
    return {
      [`${pending}`]: (state: TaxEmissionsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxEmissionsState, action: PayloadAction<TaxEmission[]>) => {
        state.taxEmissions = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxEmissionsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createTaxEmission = () => {
    const { pending, fulfilled, rejected } = createTaxEmissionAsync;
    return {
      [`${pending}`]: (state: TaxEmissionsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxEmissionsState, action: PayloadAction<TaxEmission>) => {
        state.taxEmissions = [action.payload, ...state.taxEmissions];
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxEmissionsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateTaxEmission = () => {
    const { pending, fulfilled, rejected } = updateTaxEmissionAsync;

    return {
      [`${pending}`]: (state: TaxEmissionsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxEmissionsState, action: PayloadAction<TaxEmission>) => {
        state.taxEmissions = state.taxEmissions.map((TaxEmission) => (TaxEmission.id === action.payload.id ? action.payload : TaxEmission));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxEmissionsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeTaxEmission = () => {
    const { pending, fulfilled, rejected } = deleteTaxEmissionAsync;
    return {
      [`${pending}`]: (state: TaxEmissionsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: TaxEmissionsState, action: PayloadAction<TaxEmission>) => {
        state.taxEmissions.splice(state.taxEmissions.indexOf(
          state.taxEmissions.find((ws: TaxEmission) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: TaxEmissionsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchTaxEmissions(), ...createTaxEmission(), ...updateTaxEmission(), ...removeTaxEmission() };
};

export const taxEmissionsSlice = createSlice({
  name: "taxEmissions",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectTaxEmissions = (state: RootState) => state.taxEmissions.taxEmissions;

export default taxEmissionsSlice.reducer;
