import { Input, InputNumber, Select } from "antd";
import { AirSzzJournal } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectAirSzzPoints } from "../../airSzzPoints/AirSzzPointsSlice";
import Text from "antd/es/typography/Text";
import { selectOrganizationModulePeriod } from "../../organizationModule/OrganizationModuleSlice";
import { selectPollutionSubstances } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { DefaultOptionType } from "antd/es/select";
import { useState } from "react";

export const AirSzzJournalsColumns = (): EntityColumns<AirSzzJournal>[] => {
  let [selectedPoint, setPoint] = useState()
  
  const pollutantCodes = useAppSelector(selectPollutionSubstances);
  let listPC: DefaultOptionType[] = [];
  for (const item of pollutantCodes) {
    listPC = [...listPC, {
      value: item.code,
      label: `${item.code} - ${item.name}`
    }];
  }

  function getLabelByCode(code: string) {
    return listPC.find(item => item.value === code)?.label
  }

  const points = useAppSelector(selectAirSzzPoints);
  let list: any = [];
  for (const point of points) {
    if(point.id === selectedPoint) {
    for (const pollutant of (point.pollutants || [])) {
      list = [...list, {
        value: pollutant.id,
        label: `${getLabelByCode(pollutant.code)}`
      }];
    }
  }
  }
  let listOfPoints: any  = [];
  for (const point of points) {
    listOfPoints = [...listOfPoints, {
      value: point.id,
      label: `№${point.no}, ${point.name}, (${point?.latitude}° ш. ${point?.longitude}° д.)`
    }];
  }

  const period = useAppSelector(selectOrganizationModulePeriod);

  return [
    {
      title: "Точка",
      dataIndex: "point",
      key: "point",
      inputNode: 
        <Select 
          options={listOfPoints} 
          showSearch
          filterOption={(input, option) => {
            if (option){
              const str = option.label + '';
              return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }            
          }}
          onSelect={(selectedPoint) => setPoint(selectedPoint)}
        />,
      render: (point) => point,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Загрязнение",
      dataIndex: "pollutant",
      key: "pollutant",
      inputNode: 
        <Select 
          options={list} 
          showSearch
          filterOption={(input, option) => {
            if (option){
              const str = option.label + '';
              return (str.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            } else {
              return true
            }            
          }}
        />,
      render: (pollutant) => <Text className="w-128" ellipsis={{ tooltip: `${getLabelByCode(pollutant?.code)}` }}>{getLabelByCode(pollutant?.code)}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: `Фактическая концентрация мг/м3`,
      dataIndex: "actualConcentration",
      key: "actualConcentration",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
      inputNode: <Input defaultValue={period.year} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
    {
      title: "Квартал",
      dataIndex: "quarter",
      key: "quarter",
      inputNode: <Input defaultValue={period.quarter} style={{ width: '100%' }} disabled={true} />,
      rules: [{ required: false, message: "Заполните поле" }],
      editable: false,
      copyable: true,
    },
  ];
}
