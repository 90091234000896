import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteApplication16RecordDto, WasteApplication16RecordsApi, UpdateWasteApplication16RecordDto, WasteApplication16Record } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WasteApplication16RecordsState {
  wasteApplication16Records: WasteApplication16Record[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteApplication16RecordsState = {
  wasteApplication16Records: [],
  status: "idle",
};

export const createWasteApplication16RecordAsync = createAsyncThunk(
  "wasteApplication16Records/create",
  async (wasteApplication16Record: CreateWasteApplication16RecordDto, { rejectWithValue }) => {
    try {
      const wasteApplication16RecordsApi = new WasteApplication16RecordsApi();
      const response = await wasteApplication16RecordsApi.wasteApplication16RecordsControllerCreate(wasteApplication16Record);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteApplication16RecordAsync = createAsyncThunk(
  "wasteApplication16Records/update",
  async ({ wasteApplication16Record, id }: { wasteApplication16Record: UpdateWasteApplication16RecordDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteApplication16RecordsApi = new WasteApplication16RecordsApi();
      const response = await wasteApplication16RecordsApi.wasteApplication16RecordsControllerUpdate(wasteApplication16Record, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteApplication16RecordAsync = createAsyncThunk(
  "wasteApplication16Records/remove",
  async ({ wasteApplication16Record }: { wasteApplication16Record: WasteApplication16Record }, { rejectWithValue }) => {
    try {
      const wasteApplication16RecordsApi = new WasteApplication16RecordsApi();
      const response = await wasteApplication16RecordsApi.wasteApplication16RecordsControllerRemove(wasteApplication16Record.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteApplication16RecordsByMonthAsync = createAsyncThunk("wasteApplication16Records/fetch", async ({ organizationId, year, quarter, month }: { organizationId: string, year: number, quarter: number, month: number }, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteApplication16RecordsApi();
    const response = await wasteApi.wasteApplication16RecordsControllerFindByMonth(organizationId, year, quarter, month);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteApplication16RecordsByMonth = () => {
    const { pending, fulfilled, rejected } = fetchWasteApplication16RecordsByMonthAsync;
    return {
      [`${pending}`]: (state: WasteApplication16RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication16RecordsState, action: PayloadAction<WasteApplication16Record[]>) => {
        state.wasteApplication16Records = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication16RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteApplication16Record = () => {
    const { pending, fulfilled, rejected } = createWasteApplication16RecordAsync;
    return {
      [`${pending}`]: (state: WasteApplication16RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication16RecordsState, action: PayloadAction<WasteApplication16Record>) => {
        state.wasteApplication16Records.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication16RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteApplication16Record = () => {
    const { pending, fulfilled, rejected } = updateWasteApplication16RecordAsync;

    return {
      [`${pending}`]: (state: WasteApplication16RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication16RecordsState, action: PayloadAction<WasteApplication16Record>) => {
        state.wasteApplication16Records = state.wasteApplication16Records.map((wasteApplication16Record) => (wasteApplication16Record.id === action.payload.id ? action.payload : wasteApplication16Record));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication16RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteApplication16Record = () => {
    const { pending, fulfilled, rejected } = deleteWasteApplication16RecordAsync;
    return {
      [`${pending}`]: (state: WasteApplication16RecordsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteApplication16RecordsState, action: PayloadAction<WasteApplication16Record>) => {
        state.wasteApplication16Records.splice(state.wasteApplication16Records.indexOf(
          state.wasteApplication16Records.find((ws: WasteApplication16Record) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteApplication16RecordsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteApplication16RecordsByMonth(), ...createWasteApplication16Record(), ...updateWasteApplication16Record(), ...removeWasteApplication16Record() };
};

export const wasteApplication16RecordsSlice = createSlice({
  name: "wasteApplication16Records",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteApplication16Records = (state: RootState) => state.wasteApplication16Records.wasteApplication16Records;

export default wasteApplication16RecordsSlice.reducer;
