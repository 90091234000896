import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { WastePassport, WastePassportHazardEnum } from "../../../api";
import { EntityColumns } from "../../../app/types";

export const WastePassportsColumns = (): EntityColumns<WastePassport>[] => {

  function getHazardLabel(code: string): string {
    switch (code) {
    case WastePassportHazardEnum.DANGER:
      return "Опасный";
    case WastePassportHazardEnum.NOTDANGER: 
      return "Не опасный";
    case WastePassportHazardEnum.MIRROR: 
      return "Зеркальный";
    default:
      return code;
    }
  }

  return [
    {
      title: "Наименование отхода",
      dataIndex: "name",
      key: "name",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Код отхода",
      dataIndex: "code",
      key: "code",
      inputNode: <Input />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Уровень опасности",
      dataIndex: "hazard",
      key: "hazard",
      inputNode: <Select options={[
        {
          value: WastePassportHazardEnum.DANGER,
          label: getHazardLabel(WastePassportHazardEnum.DANGER)
        },
        {
          value: WastePassportHazardEnum.NOTDANGER,
          label: getHazardLabel(WastePassportHazardEnum.NOTDANGER)
        },
        {
          value: WastePassportHazardEnum.MIRROR,
          label: getHazardLabel(WastePassportHazardEnum.MIRROR)
        }
      ]} />,
      render: (hazard) => <Text className="w-64" ellipsis={{ tooltip: `${getHazardLabel(hazard)}` }}>{getHazardLabel(hazard)}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    // {
    //   title: "Паспорт (PDF)",
    //   dataIndex: "fileId",
    //   key: "fileId",
    //   inputNode: <Input />,
    //   rules: [{ required: true, message: "Заполните поле" }],
    //   editable: true,
    //   copyable: true,
    // },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
