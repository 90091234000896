import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { CarbonStationarySourceApi, UpdateCarbonStationarySourceDto } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";
import { message } from "antd";

export interface CarbonStationarySourceState {
  data: any;
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CarbonStationarySourceState = {
  data: null,
  status: "idle",
};

export const fetchCarbonStationarySourceControllerFindByOrganizationIdYearQuarterAsync = createAsyncThunk("carbonStationarySources/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const carbonStationarySourcesApi = new CarbonStationarySourceApi();
    const response = await carbonStationarySourcesApi.carbonStationarySourceControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCarbonStationarySourceControllerInputAsync = createAsyncThunk(
  "carbonStationarySources/update",
  async ({ type, updateCarbonStationarySourceDto }: { type: string; updateCarbonStationarySourceDto: UpdateCarbonStationarySourceDto }, { rejectWithValue }) => {
    try {
      const carbonStationarySourcesApi = new CarbonStationarySourceApi();
      const response = await carbonStationarySourcesApi.carbonStationarySourceControllerUpdateInput(updateCarbonStationarySourceDto, type);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createExtraReducers = () => {
  const fetchCarbonStationarySourceControllerFindByOrganizationIdYearQuarter = () => {
    const { pending, fulfilled, rejected } = fetchCarbonStationarySourceControllerFindByOrganizationIdYearQuarterAsync;
    return {
      [`${pending}`]: (state: CarbonStationarySourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonStationarySourceState, action: PayloadAction<any[]>) => {
        state.data = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonStationarySourceState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const updateCarbonStationarySourceControllerInput = () => {
    const { pending, fulfilled, rejected } = updateCarbonStationarySourceControllerInputAsync;

    return {
      [`${pending}`]: (state: CarbonStationarySourceState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonStationarySourceState, action: PayloadAction<AxiosError>) => {
        message.success("Успешно изменено");
        state.data = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: () => {
        message.error("Не удалось сохранить !");
      },
    };
  };


  return {
    ...fetchCarbonStationarySourceControllerFindByOrganizationIdYearQuarter(),
    ...updateCarbonStationarySourceControllerInput(),
  };
};

export const carbonStationarySourcesSlice = createSlice({
  name: "carbonStationarySources",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectCarbonStationarySources = (state: RootState) => state.carbonStationarySources.data;

export default carbonStationarySourcesSlice.reducer;
