/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
import { CreatePollutionSubstanceDto } from "../models";
import { PollutionSubstance } from "../models";
import { UpdatePollutionSubstanceDto } from "../models";
import { getFromStorage } from "../../app/helpers";
/**
 * PollutionSubstancesApi - axios parameter creator
 * @export
 */
export const PollutionSubstancesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreatePollutionSubstanceDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pollutionSubstancesControllerCreate: async (body: CreatePollutionSubstanceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError("body", "Required parameter body was null or undefined when calling pollutionSubstancesControllerCreate.");
      }
      const localVarPath = `/pollution-substances`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter["Content-Type"] = "application/json";
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = typeof body !== "string" || (localVarRequestOptions.headers || {})["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pollutionSubstancesControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/pollution-substances`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pollutionSubstancesControllerFindOne: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError("code", "Required parameter code was null or undefined when calling pollutionSubstancesControllerFindOne.");
      }
      const localVarPath = `/pollution-substances/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pollutionSubstancesControllerRemove: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError("code", "Required parameter code was null or undefined when calling pollutionSubstancesControllerRemove.");
      }
      const localVarPath = `/pollution-substances/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdatePollutionSubstanceDto} body
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pollutionSubstancesControllerUpdate: async (
      body: UpdatePollutionSubstanceDto,
      code: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError("body", "Required parameter body was null or undefined when calling pollutionSubstancesControllerUpdate.");
      }
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError("code", "Required parameter code was null or undefined when calling pollutionSubstancesControllerUpdate.");
      }
      const localVarPath = `/pollution-substances/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");
      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = typeof body !== "string" || (localVarRequestOptions.headers || {})["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PollutionSubstancesApi - functional programming interface
 * @export
 */
export const PollutionSubstancesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreatePollutionSubstanceDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerCreate(
      body: CreatePollutionSubstanceDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSubstance>>> {
      const localVarAxiosArgs = await PollutionSubstancesApiAxiosParamCreator(configuration).pollutionSubstancesControllerCreate(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerFindAll(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<PollutionSubstance>>>> {
      const localVarAxiosArgs = await PollutionSubstancesApiAxiosParamCreator(configuration).pollutionSubstancesControllerFindAll(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerFindOne(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSubstance>>> {
      const localVarAxiosArgs = await PollutionSubstancesApiAxiosParamCreator(configuration).pollutionSubstancesControllerFindOne(code, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerRemove(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PollutionSubstance>>> {
      const localVarAxiosArgs = await PollutionSubstancesApiAxiosParamCreator(configuration).pollutionSubstancesControllerRemove(code, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpdatePollutionSubstanceDto} body
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerUpdate(
      body: UpdatePollutionSubstanceDto,
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
      const localVarAxiosArgs = await PollutionSubstancesApiAxiosParamCreator(configuration).pollutionSubstancesControllerUpdate(body, code, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PollutionSubstancesApi - factory interface
 * @export
 */
export const PollutionSubstancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {CreatePollutionSubstanceDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerCreate(
      body: CreatePollutionSubstanceDto,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<PollutionSubstance>> {
      return PollutionSubstancesApiFp(configuration)
        .pollutionSubstancesControllerCreate(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PollutionSubstance>>> {
      return PollutionSubstancesApiFp(configuration)
        .pollutionSubstancesControllerFindAll(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerFindOne(code: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSubstance>> {
      return PollutionSubstancesApiFp(configuration)
        .pollutionSubstancesControllerFindOne(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerRemove(code: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSubstance>> {
      return PollutionSubstancesApiFp(configuration)
        .pollutionSubstancesControllerRemove(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdatePollutionSubstanceDto} body
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pollutionSubstancesControllerUpdate(
      body: UpdatePollutionSubstanceDto,
      code: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return PollutionSubstancesApiFp(configuration)
        .pollutionSubstancesControllerUpdate(body, code, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PollutionSubstancesApi - object-oriented interface
 * @export
 * @class PollutionSubstancesApi
 * @extends {BaseAPI}
 */
export class PollutionSubstancesApi extends BaseAPI {
  /**
   *
   * @param {CreatePollutionSubstanceDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PollutionSubstancesApi
   */
  public async pollutionSubstancesControllerCreate(
    body: CreatePollutionSubstanceDto,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<PollutionSubstance>> {
    return PollutionSubstancesApiFp(this.configuration)
      .pollutionSubstancesControllerCreate(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PollutionSubstancesApi
   */
  public async pollutionSubstancesControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PollutionSubstance>>> {
    return PollutionSubstancesApiFp(this.configuration)
      .pollutionSubstancesControllerFindAll(options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PollutionSubstancesApi
   */
  public async pollutionSubstancesControllerFindOne(code: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSubstance>> {
    return PollutionSubstancesApiFp(this.configuration)
      .pollutionSubstancesControllerFindOne(code, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PollutionSubstancesApi
   */
  public async pollutionSubstancesControllerRemove(code: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PollutionSubstance>> {
    return PollutionSubstancesApiFp(this.configuration)
      .pollutionSubstancesControllerRemove(code, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {UpdatePollutionSubstanceDto} body
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PollutionSubstancesApi
   */
  public async pollutionSubstancesControllerUpdate(
    body: UpdatePollutionSubstanceDto,
    code: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return PollutionSubstancesApiFp(this.configuration)
      .pollutionSubstancesControllerUpdate(body, code, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
