import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateContragentDto, UpdateContragentDto, Contragent } from "../../api";
import { ContragentsApi } from "../../api/apis/contragents-api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface ContragentsState {
  contragents: Contragent[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: ContragentsState = {
  contragents: [],
  status: "idle",
};

export const createContragentsAsync = createAsyncThunk(
  "contragents/create",
  async (contragent: CreateContragentDto, { rejectWithValue }) => {
    try {
      const contragentsApi = new ContragentsApi();
      const response = await contragentsApi.contragentsControllerCreate(contragent);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateContragentAsync = createAsyncThunk(
  "contragents/update",
  async ({ contragent, id }: { contragent: UpdateContragentDto; id: string }, { rejectWithValue }) => {
    try {
      const contragentsApi = new ContragentsApi();
      const response = await contragentsApi.contragentsControllerUpdate(contragent, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteContragentAsync = createAsyncThunk(
  "contragents/remove",
  async ({ contragent }: { contragent: Contragent }, { rejectWithValue }) => {
    try {
      const contragentsApi = new ContragentsApi();
      const response = await contragentsApi.contragentsControllerRemove(contragent.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchContragentsByOrganizationAsync = createAsyncThunk(
  "contragents/fetch",
  async (id: string, { rejectWithValue }) => {
  try {
    const contragentsApi = new ContragentsApi();
    const response = await contragentsApi.contragentsControllerFindByOrganizationid(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchContragentsByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchContragentsByOrganizationAsync;
    return {
      [`${pending}`]: (state: ContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ContragentsState, action: PayloadAction<Contragent[]>) => {
        state.contragents = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: ContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createContragent = () => {
    const { pending, fulfilled, rejected } = createContragentsAsync;
    return {
      [`${pending}`]: (state: ContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ContragentsState, action: PayloadAction<Contragent>) => {
        state.contragents.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateContragent = () => {
    const { pending, fulfilled, rejected } = updateContragentAsync;

    return {
      [`${pending}`]: (state: ContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ContragentsState, action: PayloadAction<Contragent>) => {
        state.contragents = state.contragents.map((contragent) => (contragent.id === action.payload.id ? action.payload : contragent));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeContragent = () => {
    const { pending, fulfilled, rejected } = deleteContragentAsync;
    return {
      [`${pending}`]: (state: ContragentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ContragentsState, action: PayloadAction<Contragent>) => {
        state.contragents.splice(state.contragents.indexOf(
          state.contragents.find((ws: Contragent) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ContragentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchContragentsByOrganization(), ...createContragent(), ...updateContragent(), ...removeContragent() };
};

export const contragentsSlice = createSlice({
  name: "contragents",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectContragents = (state: RootState) => state.contragents.contragents;

export default contragentsSlice.reducer;
