import { Button, Divider, Form, Layout, Modal, Select, Space, Tabs } from "antd";
import { useEffect, useState } from "react";
import { CreateMethodicDto, CreateSelectionSourceDto, IncomingParameter, SaveStaticParameterValuesDto, SelectionSource, StaticParameterValues, UpdateSelectionSourceDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { PlusOutlined, SlidersOutlined } from "@ant-design/icons";
import { MethodicForm } from "../methodics/component/MethodicForm";
import { createMethodicAsync, fetchMethodicsAsync, fetchSourceMethodicAsync, saveSaveStaticParameterValuesAsync, selectMethodics, selectMethodicsStatus, selectSourceMethodic } from "./methodicsSlice";
import Title from "antd/es/typography/Title";
import { StaticParametersForm } from "./component/StaticParametersForm";
import { PageLoading } from "@ant-design/pro-components";

interface SourceMethodicsProps {
  selectionSourceId: string;
}

export const SourceMethodics: React.FC<SourceMethodicsProps> = ({ selectionSourceId }) => {
  const sourceMethodic = useAppSelector(selectSourceMethodic);
  const methodicsList = useAppSelector(selectMethodics);
  const status = useAppSelector(selectMethodicsStatus);

  const [staticParameters, setStaticParameters] = useState<IncomingParameter[] | undefined>();
  const [addNew, setAddNew] = useState(false);

  const [selectedMethodic, setSelectedMethodic] = useState(sourceMethodic?.id);

  const dispatch = useAppDispatch();

  const [form] = Form.useForm<{ id: string }>();

  useEffect(() => {
    dispatch(fetchSourceMethodicAsync(selectionSourceId));
    dispatch(fetchMethodicsAsync());
  }, [selectionSourceId, dispatch]);

  useEffect(() => {
    sourceMethodic && form.setFieldsValue(sourceMethodic);
  }, [sourceMethodic, form]);

  const onCreate = async (values: any) => {
    console.log(values);

    dispatch(createMethodicAsync({ ...values, selectionSourceId })).then((res: any) => {
      console.log(res);
    });
  };

  const controlSelectedMethodic = (methodicId: string) => {
    setAddNew(false);
    if (sourceMethodic?.id === methodicId) {
      setStaticParameters(undefined);
    } else {
      const methodic = methodicsList.find((methodic) => methodicId === methodic.id);
      setStaticParameters(methodic?.staticParameters);
    }
    setSelectedMethodic(methodicId);
  };

  const onSave = ({ staticParameters, ...rest }: { staticParameters: SaveStaticParameterValuesDto[], rest: any }) => {
    dispatch(saveSaveStaticParameterValuesAsync({ staticParameterValues: staticParameters, selectionSourceId })).then(res => {
      dispatch(fetchSourceMethodicAsync(selectionSourceId));
    });
  }

  if (status === "loading") return <PageLoading />;

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Form form={form} layout="vertical">
        <Title level={5}>Выберите методику расчета</Title>
        <Form.Item name={"name"} rules={[{ required: true, message: "Введите название" }]}>
          <Select
            onChange={controlSelectedMethodic}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Button block type="text" icon={<PlusOutlined />} onClick={() => setAddNew(true)}>
                  Добавить новую
                </Button>
              </>
            )}
            options={methodicsList.map(({ id, name }) => ({ value: id, label: name }))}
          />
        </Form.Item>
      </Form>

      {addNew && <MethodicForm onSaveMethodic={onCreate} />}

      {staticParameters && !addNew && <StaticParametersForm staticParameters={staticParameters} onSave={onSave} />}

      {!staticParameters && !addNew && sourceMethodic &&
        <StaticParametersForm
          staticParameters={
            structuredClone(sourceMethodic?.staticParameters).map((parameter: any) => {
              const source = sourceMethodic?.selectionSources?.find((selectionSource) => selectionSource?.id === selectionSourceId);
              const parameterValue = source?.staticParameterValues?.find(paramVal => paramVal?.staticParameter?.id === parameter?.id);
              parameter.value = parameterValue?.value;
              return parameter;
            }) as any
          }
          onSave={onSave}
        />
      }

      {/* {!staticParameters && !addNew && sourceMethodic && <MethodicForm initialValues={sourceMethodic} onCreateMethodic={() => { }} />} */}
    </Layout>
  );
};
