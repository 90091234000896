import { Form, Modal } from "antd";
import React from "react";
import { CreateWaterConsumptionObjectDto } from "../../../api";
import { waterConsumptionObjectsColumns } from "../form/columns";

interface WaterConsumptionObjectFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWaterConsumptionObject: (values: CreateWaterConsumptionObjectDto) => void;
}

export const WaterConsumptionObjectForm: React.FC<WaterConsumptionObjectFormProps> = ({ visible, setVisible, onCreateWaterConsumptionObject }) => {
  const [form] = Form.useForm<CreateWaterConsumptionObjectDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWaterConsumptionObject}>
        {waterConsumptionObjectsColumns.map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
          {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
