import { Button, Layout } from "antd";
import { useEffect, useState } from "react";
import { Calculation, CreateCalculationDto, UpdateCleaningEquipmentDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { calculationsColumns } from "./form/columns";
import { createCalculationAsync, deleteCalculationAsync, fetchCalculationsAsync, selectCalculations } from "./calculationsSlice";
import { fetchPollutionSourcesForMethodicAsync, selectPollutionSourcesForMethodic } from "../pollutionSources/pollutionSourcesSlice";
import { CalculationsForm } from "./components/CalculationsForm";
import { useNavigate } from "react-router";

interface CalculationsListComponentProps {
  organizationId: string;
  year: number;
  quarter: number;
}

export const CalculationsListComponent: React.FC<CalculationsListComponentProps> = ({ organizationId, year, quarter }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const calculations = useAppSelector(selectCalculations);
  const sources = useAppSelector(selectPollutionSourcesForMethodic);

  useEffect(() => {
    if (organizationId && year && quarter) {
      dispatch(fetchCalculationsAsync({ organizationId, year, quarter }));
      dispatch(fetchPollutionSourcesForMethodicAsync(organizationId));
    };
  }, [organizationId, year, quarter, dispatch]);

  const onCreateCalculation = (createCalculationDto: CreateCalculationDto) => {
    dispatch(createCalculationAsync({ ...createCalculationDto, year, quarter, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onWatch = (calculation: Calculation) => {
    navigate(`calculation/${calculation.id}`);
  };

  const onUpdateCalculation = async (updateCalculationDto: any, id: string) => {
    return true;
  };

  const deleteCalculation = (calculation: Calculation) => {
    return dispatch(deleteCalculationAsync({ calculation })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  // const addButton = (
  //   <Button onClick={() => setVisible(true)} block type="dashed">
  //     + Добавить
  //   </Button>
  // );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <CalculationsForm visible={visible} setVisible={setVisible} onCreateCalculation={onCreateCalculation} sources={sources} />
      <EditableTable<Calculation, UpdateCleaningEquipmentDto>
        onWatch={onWatch}
        onDelete={deleteCalculation}
        entityColumns={calculationsColumns}
        dataSource={calculations}
        // footer={() => addButton}
      />
    </Layout>
  );
};
