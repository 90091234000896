import { Layout } from "antd";
import React from "react";
import { OrganizationProfileComponent } from "../features/organizationProfile/OrganizationProfileComponent";

const OrganizationProfilePage: React.FC = () => (
  <Layout className="p-8">
    <OrganizationProfileComponent />
  </Layout>
);

export default OrganizationProfilePage;
