import { Form, Modal } from "antd";
import React from "react";
import { CreateWaterPointDto } from "../../../api";
import { waterPointsColumns } from "../form/columns";

interface WaterPointFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWaterPoint: (values: CreateWaterPointDto) => void;
}

export const WaterPointForm: React.FC<WaterPointFormProps> = ({ visible, setVisible, onCreateWaterPoint }) => {
  const [form] = Form.useForm<CreateWaterPointDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWaterPoint}>
        {waterPointsColumns.map(({ title, dataIndex, rules, inputNode }, index) => (
          <Form.Item label={title} name={dataIndex} rules={rules} key={index}>
          {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
