import { Form, Modal } from "antd";
import React from "react";
import { CreateCarbonIndirectEmissionDto } from "../../../../api";
import { CarbonIndirectEmissionColumns } from "../form/columns";

interface CarbonIndirectEmissionFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateCarbonIndirectEmission: (values: CreateCarbonIndirectEmissionDto) => void;
}

export const CarbonIndirectEmissionForm: React.FC<CarbonIndirectEmissionFormProps> = ({ visible, setVisible, onCreateCarbonIndirectEmission }) => {
  const [form] = Form.useForm<CreateCarbonIndirectEmissionDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateCarbonIndirectEmission}>
        {CarbonIndirectEmissionColumns().map(({ title, key, rules, inputNode, hideOnForm }, index) => (
          <>
            {!hideOnForm && <Form.Item label={title} name={key} rules={rules} key={index}>
              {inputNode}
            </Form.Item>}
          </>
        ))}
      </Form>
    </Modal>
  );
};
