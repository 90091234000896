import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWaterConsumptionProductionDto, WaterConsumptionProductionApi, UpdateWaterConsumptionProductionDto, WaterConsumptionProduction } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface WaterConsumptionProductionState {
  waterConsumptionProduction: WaterConsumptionProduction[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WaterConsumptionProductionState = {
  waterConsumptionProduction: [],
  status: "idle",
};

export const createWaterConsumptionProductionAsync = createAsyncThunk(
  "waterConsumptionProduction/create",
  async (waterConsumptionProduction: CreateWaterConsumptionProductionDto, { rejectWithValue }) => {
    try {
      const waterConsumptionProductionApi = new WaterConsumptionProductionApi();
      const response = await waterConsumptionProductionApi.waterConsumptionProductionControllerCreate(waterConsumptionProduction);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWaterConsumptionProductionAsync = createAsyncThunk(
  "waterConsumptionProduction/update",
  async ({ waterConsumptionProduction, id }: { waterConsumptionProduction: UpdateWaterConsumptionProductionDto; id: string }, { rejectWithValue }) => {
    try {
      const waterConsumptionProductionApi = new WaterConsumptionProductionApi();
      const response = await waterConsumptionProductionApi.waterConsumptionProductionControllerUpdate(waterConsumptionProduction, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWaterConsumptionProductionAsync = createAsyncThunk(
  "waterConsumptionProduction/remove",
  async ({ waterConsumptionProduction }: { waterConsumptionProduction: WaterConsumptionProduction }, { rejectWithValue }) => {
    try {
      const waterConsumptionProductionApi = new WaterConsumptionProductionApi();
      const response = await waterConsumptionProductionApi.waterConsumptionProductionControllerRemove(waterConsumptionProduction.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWaterConsumptionProductionByOrganizationIdAsync = createAsyncThunk("waterConsumptionProduction/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const waterConsumptionProductionApi = new WaterConsumptionProductionApi();
    const response = await waterConsumptionProductionApi.waterConsumptionProductionControllerFindByOrganizationid(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWaterConsumptionProductionByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchWaterConsumptionProductionByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionProductionState, action: PayloadAction<WaterConsumptionProduction[]>) => {
        state.waterConsumptionProduction = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWaterConsumptionProduction = () => {
    const { pending, fulfilled, rejected } = createWaterConsumptionProductionAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionProductionState, action: PayloadAction<WaterConsumptionProduction>) => {
        state.waterConsumptionProduction.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWaterConsumptionProduction = () => {
    const { pending, fulfilled, rejected } = updateWaterConsumptionProductionAsync;

    return {
      [`${pending}`]: (state: WaterConsumptionProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionProductionState, action: PayloadAction<WaterConsumptionProduction>) => {
        state.waterConsumptionProduction = state.waterConsumptionProduction.map((waterConsumptionProduction) => (waterConsumptionProduction.id === action.payload.id ? action.payload : waterConsumptionProduction));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWaterConsumptionProduction = () => {
    const { pending, fulfilled, rejected } = deleteWaterConsumptionProductionAsync;
    return {
      [`${pending}`]: (state: WaterConsumptionProductionState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WaterConsumptionProductionState, action: PayloadAction<WaterConsumptionProduction>) => {
        state.waterConsumptionProduction.splice(state.waterConsumptionProduction.indexOf(
          state.waterConsumptionProduction.find((ws: WaterConsumptionProduction) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WaterConsumptionProductionState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWaterConsumptionProductionByOrganization(), ...createWaterConsumptionProduction(), ...updateWaterConsumptionProduction(), ...removeWaterConsumptionProduction() };
};

export const waterConsumptionProductionSlice = createSlice({
  name: "waterConsumptionProduction",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWaterConsumptionProduction = (state: RootState) => state.waterConsumptionProduction.waterConsumptionProduction;

export default waterConsumptionProductionSlice.reducer;
