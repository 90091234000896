/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateWasteAccumulationLimitDto } from '../models';
import { UpdateWasteAccumulationLimitDto } from '../models';
import { WasteAccumulationLimit } from '../models';
import { getFromStorage } from '../../app/helpers';
/**
 * WasteAccumulationLimitsApi - axios parameter creator
 * @export
 */
export const WasteAccumulationLimitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteAccumulationLimitDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerCreate: async (body: CreateWasteAccumulationLimitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteAccumulationLimitsControllerCreate.');
            }
            const localVarPath = `/waste-accumulation-limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/waste-accumulation-limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} industrialSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerFindByIndustrialSiteId: async (industrialSiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'industrialSiteId' is not null or undefined
            if (industrialSiteId === null || industrialSiteId === undefined) {
                throw new RequiredError('industrialSiteId','Required parameter industrialSiteId was null or undefined when calling wasteAccumulationLimitsControllerFindByIndustrialSiteId.');
            }
            const localVarPath = `/waste-accumulation-limits/industrial-site/{industrialSiteId}`
                .replace(`{${"industrialSiteId"}}`, encodeURIComponent(String(industrialSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerFindByPermissionId: async (permissionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionId' is not null or undefined
            if (permissionId === null || permissionId === undefined) {
                throw new RequiredError('permissionId','Required parameter permissionId was null or undefined when calling wasteAccumulationLimitsControllerFindByPermissionId.');
            }
            const localVarPath = `/waste-accumulation-limits/permission/{permissionId}`
                .replace(`{${"permissionId"}}`, encodeURIComponent(String(permissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerFindByYear: async (organizationId: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling wasteAccumulationLimitsControllerFindByYear.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling wasteAccumulationLimitsControllerFindByYear.');
            }
            const localVarPath = `/waste-accumulation-limits/organization/{organizationId}/year/{year}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteAccumulationLimitsControllerFindOne.');
            }
            const localVarPath = `/waste-accumulation-limits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteAccumulationLimitsControllerRemove.');
            }
            const localVarPath = `/waste-accumulation-limits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWasteAccumulationLimitDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteAccumulationLimitsControllerUpdate: async (body: UpdateWasteAccumulationLimitDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling wasteAccumulationLimitsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling wasteAccumulationLimitsControllerUpdate.');
            }
            const localVarPath = `/waste-accumulation-limits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarHeaderParameter["Authorization"] = "Bearer " + getFromStorage("token");

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers || {})['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WasteAccumulationLimitsApi - functional programming interface
 * @export
 */
export const WasteAccumulationLimitsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWasteAccumulationLimitDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerCreate(body: CreateWasteAccumulationLimitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteAccumulationLimit>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteAccumulationLimit>>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} industrialSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindByIndustrialSiteId(industrialSiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteAccumulationLimit>>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerFindByIndustrialSiteId(industrialSiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindByPermissionId(permissionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteAccumulationLimit>>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerFindByPermissionId(permissionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindByYear(organizationId: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<WasteAccumulationLimit>>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerFindByYear(organizationId, year, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteAccumulationLimit>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteAccumulationLimit>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateWasteAccumulationLimitDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerUpdate(body: UpdateWasteAccumulationLimitDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WasteAccumulationLimit>>> {
            const localVarAxiosArgs = await WasteAccumulationLimitsApiAxiosParamCreator(configuration).wasteAccumulationLimitsControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WasteAccumulationLimitsApi - factory interface
 * @export
 */
export const WasteAccumulationLimitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateWasteAccumulationLimitDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerCreate(body: CreateWasteAccumulationLimitDto, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteAccumulationLimit>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} industrialSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindByIndustrialSiteId(industrialSiteId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerFindByIndustrialSiteId(industrialSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindByPermissionId(permissionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerFindByPermissionId(permissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindByYear(organizationId: string, year: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerFindByYear(organizationId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteAccumulationLimit>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteAccumulationLimit>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWasteAccumulationLimitDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteAccumulationLimitsControllerUpdate(body: UpdateWasteAccumulationLimitDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WasteAccumulationLimit>> {
            return WasteAccumulationLimitsApiFp(configuration).wasteAccumulationLimitsControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WasteAccumulationLimitsApi - object-oriented interface
 * @export
 * @class WasteAccumulationLimitsApi
 * @extends {BaseAPI}
 */
export class WasteAccumulationLimitsApi extends BaseAPI {
    /**
     * 
     * @param {CreateWasteAccumulationLimitDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerCreate(body: CreateWasteAccumulationLimitDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteAccumulationLimit>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} industrialSiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerFindByIndustrialSiteId(industrialSiteId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerFindByIndustrialSiteId(industrialSiteId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} permissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerFindByPermissionId(permissionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerFindByPermissionId(permissionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerFindByYear(organizationId: string, year: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<WasteAccumulationLimit>>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerFindByYear(organizationId, year, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteAccumulationLimit>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteAccumulationLimit>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateWasteAccumulationLimitDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteAccumulationLimitsApi
     */
    public async wasteAccumulationLimitsControllerUpdate(body: UpdateWasteAccumulationLimitDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WasteAccumulationLimit>> {
        return WasteAccumulationLimitsApiFp(this.configuration).wasteAccumulationLimitsControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
