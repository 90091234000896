import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ProList } from "@ant-design/pro-components";
import { Button, Collapse, Layout, Popconfirm, Row } from "antd";
import { Dictionary, groupBy } from "lodash";
import { useEffect, useState } from "react";
import { CreateStandardDto, Standard, UpdateStandardDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectOrganizationModulePeriod } from "../organizationModule/OrganizationModuleSlice";
import { SelectionSourceStandardForm } from "./components/SelectionSourceStandardForm";
import { SelectionSourceStandardUpdateForm } from "./components/SelectionSourceStandardUpdateForm";
import { createStandardAsync, fetchSelectionSourceStandardsAsync, removeStandardAsync } from "./standardsSlice";
import { selectSelectionSourceStandards, updateStandardAsync } from "./standardsSlice";

interface SelectionSourceStandardsComponentProps {
  selectionSourceId: string;
}

export const SelectionSourceStandardComponent: React.FC<SelectionSourceStandardsComponentProps> = ({ selectionSourceId }) => {
  const dispatch = useAppDispatch();
  const selectionSourceStandards = useAppSelector(selectSelectionSourceStandards);

  const period = useAppSelector(selectOrganizationModulePeriod);

  const [groupedStandards, setGroupedStandards] = useState<Dictionary<Standard[]>>({});
  const [updatingStandard, setUpdatingStandard] = useState<Standard | undefined>();

  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  useEffect(() => {
    dispatch(fetchSelectionSourceStandardsAsync(selectionSourceId));
  }, [dispatch, selectionSourceId]);

  useEffect(() => {
    setGroupedStandards(groupBy(selectionSourceStandards, "year"));
  }, [selectionSourceStandards]);

  const onCreateStandard = (standard: CreateStandardDto) => {
    dispatch(createStandardAsync({ ...standard, selectionSourceId, year: period.year })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const onUpdate = async (standard: UpdateStandardDto, id: string) => {
    return dispatch(
      updateStandardAsync({
        standard: {
          ...standard,
          valueGramPerSecond: parseFloat(`${standard.valueGramPerSecond}`),
          valueTonPerYear: parseFloat(`${standard.valueTonPerYear}`),
        },
        id,
      })
    ).then((res: any) => {
      if (!res.error) {
        setVisibleUpdate(false);
        return true;
      }
      return false;
    });
  };

  const edit = (record: Standard) => {
    setUpdatingStandard(record);
    setVisibleUpdate(true);
  };

  const addButton = (
    <Button onClick={() => setVisible(true)} type="primary" className="my-4 w-48" size="large">
      + Добавить
    </Button>
  );

  const onDelete = async (id: string) => {
    return dispatch(removeStandardAsync(id)).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {addButton}
      {visible && <SelectionSourceStandardForm visible={visible} setVisible={setVisible} onCreateStandard={onCreateStandard} />}
      {updatingStandard && (
        <SelectionSourceStandardUpdateForm
          standard={updatingStandard}
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          onUpdateStandard={onUpdate}
        />
      )}
      <Collapse>
        {Object.keys(groupedStandards).map((year) => {
          return (
            <Collapse.Panel header={year} key={year}>
              <ProList<Standard>
                rowKey="name"
                dataSource={groupedStandards[year]}
                metas={{
                  title: {
                    dataIndex: "pollutionSubstance",
                    render: (_, record) => `${record.pollutionSubstance.code} ${record.pollutionSubstance.name}`,
                  },
                  description: { dataIndex: "year" },
                  content: {
                    dataIndex: "content",
                    render: (_, record) => (
                      <div key="label" className="flex justify-around">
                        <div>
                          <div>Лимит г/с</div>
                          <div>{record.valueGramPerSecond}</div>
                        </div>
                        <div>
                          <div>Лимит т/г</div>
                          <div>{record.valueTonPerYear}</div>
                        </div>
                        <div>
                          <Row justify={"center"} align="middle">
                            <Button size="small" onClick={() => edit(record)} className="mr-2">
                              <EditOutlined />
                            </Button>
                            <Popconfirm
                              title="Вы уверены?"
                              onConfirm={() => onDelete(record.id)}
                              okText="Удалить"
                              cancelText="Отмена"
                            >
                              <Button size="small" danger>
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm>
                          </Row>
                        </div>
                      </div>
                    ),
                  },
                }}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </Layout>
  );
};
