import { Layout, Table } from "antd";
import { useEffect } from "react";
import { Organization } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchWasteBurialLimitsByYearAsync, selectWasteBurialLimits } from "../wasteBurialLimits/WasteBurialLimitsSlice";
import { fetchWasteJournalsLimitInfoByOrganizationAsync, selectWasteJournalsInfo } from "../wasteJournals/WasteJournalsSlice";

interface WasteBurialLimitsInfoComponentProps {
    organization?: Organization;
    year?: number;
    quarter?: number;
}

export const WasteBurialLimitsInfoComponent: React.FC<WasteBurialLimitsInfoComponentProps> = ({ organization, year, quarter }) => {
    const dispatch = useAppDispatch();
    const wasteJournalsInfo = useAppSelector(selectWasteJournalsInfo); 
    
    useEffect(() => {
        if (organization && year && quarter) {
          dispatch(fetchWasteJournalsLimitInfoByOrganizationAsync({ organizationId: organization.id, year: year, quarter: quarter }));
        }
      }, [organization, year,quarter, dispatch]);

    console.log(wasteJournalsInfo)
    return(
        <Layout className="bg-white p-4 rounded-lg">
            <Table 
            scroll={{x: "max-content"}}
            columns={[
                {
                    title: 'Уровень опасности отхода',
                    dataIndex: 'wasteDamageLevel',
                    key: 'wasteDamageLevel',
                },
                {
                    title: 'Лимит т/год',
                    dataIndex: 'limitYear',
                    key: 'limitYear',
                },
                {
                    title: 'Факт тонн за отчетный квартал',
                    dataIndex: 'factQuarter',
                    key: 'factQuarter',
                },
                {
                    title: 'Факт тонн за отчетный год',
                    dataIndex: 'factYear',
                    key: 'factYear',
                },
                {
                    title: 'Остаток лимита',
                    dataIndex: 'limitBalance',
                    key: 'limitBalance',
                },
            ]}
            dataSource={
                wasteJournalsInfo
            }
            >

            </Table>
        </Layout>
     )
}