/* tslint:disable */
/* eslint-disable */
/**
 * K2B
 * The K2B API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organization } from './organization';
/**
 * 
 * @export
 * @interface WasteRegister
 */
export interface WasteRegister {
    /**
     * 
     * @type {string}
     * @memberof WasteRegister
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WasteRegister
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WasteRegister
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof WasteRegister
     */
    hazard: WasteRegisterHazardEnum;
    /**
     * 
     * @type {string}
     * @memberof WasteRegister
     */
    type: WasteRegisterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WasteRegister
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof WasteRegister
     */
    year: number;
    /**
     * 
     * @type {boolean}
     * @memberof WasteRegister
     */
    isActive: boolean;
    /**
     * 
     * @type {Date}
     * @memberof WasteRegister
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WasteRegister
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WasteRegister
     */
    deletedAt: Date;
    /**
     * 
     * @type {Organization}
     * @memberof WasteRegister
     */
    organization: Organization;
    /**
     * 
     * @type {any}
     * @memberof WasteRegister
     */
    permission: any;
}

/**
    * @export
    * @enum {string}
    */
export enum WasteRegisterHazardEnum {
    DANGER = 'DANGER',
    NOTDANGER = 'NOT_DANGER',
    MIRROR = 'MIRROR'
}
/**
    * @export
    * @enum {string}
    */
export enum WasteRegisterTypeEnum {
    Overburden = 'overburden',
    Landfill = 'landfill',
    Enrichment = 'enrichment'
}

