import { Form, Modal } from "antd";
import React from "react";
import { CreateCarbonContractWorkDto } from "../../../../api";
import { CarbonContractWorkColumns } from "../form/columns";

interface CarbonContractWorkFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateCarbonContractWork: (values: CreateCarbonContractWorkDto) => void;
}

export const CarbonContractWorkForm: React.FC<CarbonContractWorkFormProps> = ({ visible, setVisible, onCreateCarbonContractWork }) => {
  const [form] = Form.useForm<CreateCarbonContractWorkDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateCarbonContractWork}>
        {CarbonContractWorkColumns().map(({ title, key, rules, inputNode, hideOnForm }, index) => (
          <>
            {!hideOnForm && <Form.Item label={title} name={key} rules={rules} key={index}>
              {inputNode}
            </Form.Item>}
          </>
        ))}
      </Form>
    </Modal>
  );
};
