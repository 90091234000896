import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateDropDto, DropApi, UpdateDropDto, Drop } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface DropState {
  drop: Drop[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: DropState = {
  drop: [],
  status: "idle",
};

export const createDropAsync = createAsyncThunk(
  "drop/create",
  async (drop: CreateDropDto, { rejectWithValue }) => {
    try {
      const dropApi = new DropApi();
      const response = await dropApi.dropControllerCreate(drop);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDropAsync = createAsyncThunk(
  "drop/update",
  async ({ drop, id }: { drop: UpdateDropDto; id: string }, { rejectWithValue }) => {
    try {
      const dropApi = new DropApi();
      const response = await dropApi.dropControllerUpdate(drop, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDropAsync = createAsyncThunk(
  "drop/remove",
  async ({ drop }: { drop: Drop }, { rejectWithValue }) => {
    try {
      const dropApi = new DropApi();
      const response = await dropApi.dropControllerRemove(drop.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDropByOrganizationIdAsync = createAsyncThunk("drop/fetch", async (organizationId: string, { rejectWithValue }) => {
  try {
    const dropApi = new DropApi();
    const response = await dropApi.dropControllerFindByOrganizationid(organizationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchDropByOrganizationIdPeriodLinkedAsync = createAsyncThunk("drop/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const dropApi = new DropApi();
    const response = await dropApi.dropControllerFindWithPeriodByOrganizationId(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchDropByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchDropByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: DropState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropState, action: PayloadAction<Drop[]>) => {
        state.drop = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const fetchDropByOrganizationIdPeriodLinked = () => {
    const { pending, fulfilled, rejected } = fetchDropByOrganizationIdPeriodLinkedAsync;
    return {
      [`${pending}`]: (state: DropState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropState, action: PayloadAction<Drop[]>) => {
        state.drop = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createDrop = () => {
    const { pending, fulfilled, rejected } = createDropAsync;
    return {
      [`${pending}`]: (state: DropState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropState, action: PayloadAction<Drop>) => {
        state.drop.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateDrop = () => {
    const { pending, fulfilled, rejected } = updateDropAsync;

    return {
      [`${pending}`]: (state: DropState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropState, action: PayloadAction<Drop>) => {
        state.drop = state.drop.map((drop) => (drop.id === action.payload.id ? action.payload : drop));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeDrop = () => {
    const { pending, fulfilled, rejected } = deleteDropAsync;
    return {
      [`${pending}`]: (state: DropState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: DropState, action: PayloadAction<Drop>) => {
        state.drop.splice(state.drop.indexOf(
          state.drop.find((ws: Drop) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: DropState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchDropByOrganization(), ...fetchDropByOrganizationIdPeriodLinked(), ...createDrop(), ...updateDrop(), ...removeDrop() };
};

export const dropSlice = createSlice({
  name: "drop",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectDrop = (state: RootState) => state.drop.drop;

export default dropSlice.reducer;
