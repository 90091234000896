import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteApplication16RecordDto } from "../../../../api";
import { WasteApplication16RecordsColumns } from "../form/columns";

interface WasteApplication16RecordFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteApplication16Record: (values: CreateWasteApplication16RecordDto) => void;
}

export const WasteApplication16RecordForm: React.FC<WasteApplication16RecordFormProps> = ({ visible, setVisible, onCreateWasteApplication16Record }) => {
  const [form] = Form.useForm<CreateWasteApplication16RecordDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteApplication16Record}>
        {WasteApplication16RecordsColumns().map(({ title, key, rules, inputNode, hideOnForm }, index) => (
          <>
            {!hideOnForm && <Form.Item label={title} name={key} rules={rules} key={index}>
              {inputNode}
            </Form.Item>}
          </>
        ))}
      </Form>
    </Modal>
  );
};
