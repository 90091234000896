import { Form, Modal } from "antd";
import React from "react";
import { CreateWasteBurialLimitDto } from "../../../api";
import { WasteBurialLimitsColumns } from "../form/columns";

interface WasteBurialLimitFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateWasteBurialLimit: (values: CreateWasteBurialLimitDto) => void;
}

export const WasteBurialLimitForm: React.FC<WasteBurialLimitFormProps> = ({ visible, setVisible, onCreateWasteBurialLimit }) => {
  const [form] = Form.useForm<CreateWasteBurialLimitDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateWasteBurialLimit}>
        {WasteBurialLimitsColumns().map(({ title, key, rules, inputNode }, index) => (
          <Form.Item label={title} name={key} rules={rules} key={index}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
