import { Form, Modal } from "antd";
import React from "react";
import { CreateStandardDto } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { selectPollutionSubstancesInputOptions } from "../../pollutionSubstances/pollutionSubstancesSlice";
import { standardsColumns } from "../form/columns";

interface SelectionSourceStandardFormProps {
  visible: boolean;
  setVisible: Function;
  onCreateStandard: (values: CreateStandardDto) => void;
}

export const SelectionSourceStandardForm: React.FC<SelectionSourceStandardFormProps> = ({ visible, setVisible, onCreateStandard }) => {
  const pollutionSubstances = useAppSelector(selectPollutionSubstancesInputOptions);
  const [form] = Form.useForm<CreateStandardDto>();

  return (
    <Modal open={visible} onOk={form.submit} okText="Создать" cancelText="Отмена" onCancel={() => setVisible(false)}>
      <Form form={form} className="p-8" layout="vertical" onFinish={onCreateStandard}>
        {standardsColumns(pollutionSubstances).map(({ title, dataIndex, rules, inputNode }) => (
          <Form.Item label={title} name={dataIndex} rules={rules}>
            {inputNode}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
