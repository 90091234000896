import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { CreateWasteEquipmentRecycleDto, WasteEquipmentRecycle, UpdateWasteEquipmentRecycleDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { WasteEquipmentRecycleForm } from "./components/WasteEquipmentRecyclesForm";
import { wasteEquipmentRecyclesColumns } from "./form/columns";
import { createWasteEquipmentRecycleAsync, updateWasteEquipmentRecycleAsync, fetchWasteEquipmentRecyclesByOrganizationAsync, selectWasteEquipmentRecycles, deleteWasteEquipmentRecycleAsync } from "./WasteEquipmentRecyclesSlice";

interface WasteEquipmentRecyclesComponentProps {
  organizationId?: string;
}

export const WasteEquipmentRecyclesComponent: React.FC<WasteEquipmentRecyclesComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const wasteEquipmentRecycles = useAppSelector(selectWasteEquipmentRecycles);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWasteEquipmentRecyclesByOrganizationAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdate = async (wasteEquipmentRecycle: UpdateWasteEquipmentRecycleDto, id: string) => {
    return dispatch(updateWasteEquipmentRecycleAsync({ wasteEquipmentRecycle, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteEquipmentRecycle = (wasteEquipmentRecycle: CreateWasteEquipmentRecycleDto) => {
    if (!organizationId) return;

    dispatch(createWasteEquipmentRecycleAsync({ ...wasteEquipmentRecycle, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteEquipmentRecycles = (wasteEquipmentRecycle: WasteEquipmentRecycle) => {
    return dispatch(deleteWasteEquipmentRecycleAsync({ wasteEquipmentRecycle })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить оборудование
      </Button>
    </Space>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <WasteEquipmentRecycleForm visible={visible} setVisible={setVisible} onCreateWasteEquipmentRecycle={onCreateWasteEquipmentRecycle} />}
      <EditableTable<WasteEquipmentRecycle, UpdateWasteEquipmentRecycleDto>
        onSave={onUpdate}
        onDelete={deleteWasteEquipmentRecycles}
        entityColumns={wasteEquipmentRecyclesColumns}
        dataSource={wasteEquipmentRecycles}
        footer={() => addButton}
      />
    </Layout>
  );
};
