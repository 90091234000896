import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateWasteAccumulationLimitDto, WasteAccumulationLimitsApi, UpdateWasteAccumulationLimitDto, WasteAccumulationLimit } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface WasteAccumulationLimitsState {
  wasteAccumulationLimits: WasteAccumulationLimit[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: WasteAccumulationLimitsState = {
  wasteAccumulationLimits: [],
  status: "idle",
};

export const createWasteAccumulationLimitAsync = createAsyncThunk(
  "wasteAccumulationLimits/create",
  async (wasteAccumulationLimit: CreateWasteAccumulationLimitDto, { rejectWithValue }) => {
    try {
      const wasteAccumulationLimitsApi = new WasteAccumulationLimitsApi();
      const response = await wasteAccumulationLimitsApi.wasteAccumulationLimitsControllerCreate(wasteAccumulationLimit);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWasteAccumulationLimitAsync = createAsyncThunk(
  "wasteAccumulationLimits/update",
  async ({ wasteAccumulationLimit, id }: { wasteAccumulationLimit: UpdateWasteAccumulationLimitDto; id: string }, { rejectWithValue }) => {
    try {
      const wasteAccumulationLimitsApi = new WasteAccumulationLimitsApi();
      const response = await wasteAccumulationLimitsApi.wasteAccumulationLimitsControllerUpdate(wasteAccumulationLimit, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWasteAccumulationLimitAsync = createAsyncThunk(
  "wasteAccumulationLimits/remove",
  async ({ wasteAccumulationLimit }: { wasteAccumulationLimit: WasteAccumulationLimit }, { rejectWithValue }) => {
    try {
      const wasteAccumulationLimitsApi = new WasteAccumulationLimitsApi();
      const response = await wasteAccumulationLimitsApi.wasteAccumulationLimitsControllerRemove(wasteAccumulationLimit.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWasteAccumulationLimitsByYearAsync = createAsyncThunk("wasteAccumulationLimits/fetch", async ({ organizationId, year }: { organizationId: string, year: number }, { rejectWithValue }) => {
  try {
    const wasteApi = new WasteAccumulationLimitsApi();
    const response = await wasteApi.wasteAccumulationLimitsControllerFindByYear(organizationId, year);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchWasteAccumulationLimitsByWasteSite = () => {
    const { pending, fulfilled, rejected } = fetchWasteAccumulationLimitsByYearAsync;
    return {
      [`${pending}`]: (state: WasteAccumulationLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<WasteAccumulationLimit[]>) => {
        state.wasteAccumulationLimits = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createWasteAccumulationLimit = () => {
    const { pending, fulfilled, rejected } = createWasteAccumulationLimitAsync;
    return {
      [`${pending}`]: (state: WasteAccumulationLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<WasteAccumulationLimit>) => {
        state.wasteAccumulationLimits.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateWasteAccumulationLimit = () => {
    const { pending, fulfilled, rejected } = updateWasteAccumulationLimitAsync;

    return {
      [`${pending}`]: (state: WasteAccumulationLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<WasteAccumulationLimit>) => {
        state.wasteAccumulationLimits = state.wasteAccumulationLimits.map((wasteAccumulationLimit) => (wasteAccumulationLimit.id === action.payload.id ? action.payload : wasteAccumulationLimit));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeWasteAccumulationLimit = () => {
    const { pending, fulfilled, rejected } = deleteWasteAccumulationLimitAsync;
    return {
      [`${pending}`]: (state: WasteAccumulationLimitsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<WasteAccumulationLimit>) => {
        state.wasteAccumulationLimits.splice(state.wasteAccumulationLimits.indexOf(
          state.wasteAccumulationLimits.find((ws: WasteAccumulationLimit) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: WasteAccumulationLimitsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchWasteAccumulationLimitsByWasteSite(), ...createWasteAccumulationLimit(), ...updateWasteAccumulationLimit(), ...removeWasteAccumulationLimit() };
};

export const wasteAccumulationLimitsSlice = createSlice({
  name: "wasteAccumulationLimits",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectWasteAccumulationLimits = (state: RootState) => state.wasteAccumulationLimits.wasteAccumulationLimits;

export default wasteAccumulationLimitsSlice.reducer;
