import { Input, InputNumber } from "antd";
import { EntityColumns } from "../../../app/types";
import { WasteManagementDocument } from "../../../api/models/waste-management-document";

export const WasteManagementDocumentsColumns = (): EntityColumns<WasteManagementDocument>[] => {
    
    return [
        {
            title: `№`,
            dataIndex: "no",
            key: "no",
            inputNode: <InputNumber style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: false,
            copyable: true,
        },
        {
            title: `Название файла`,
            dataIndex: "fileName",
            key: "fileName",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: true, message: "no" }],
            editable: true,
            copyable: true,
        },
        {
            title: `Ссылка на документ`,
            dataIndex: "fileUrl",
            key: "fileUrl",
            inputNode: <Input style={{ width: '100%' }} />,
            rules: [{ required: false, message: "no" }],
            editable: true,
            copyable: true,
        }
    ];
}