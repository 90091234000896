import { Button, Layout } from "antd";
import { useState, useEffect } from "react";
import { CreateEmissionDto, Emission, UpdateEmissionDto, Organization } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { EmissionForm } from "./components/EmissionForm";
import { EmissionColumns } from "./form/columns";
import { createEmissionAsync, updateEmissionAsync, selectEmission, deleteEmissionAsync, fetchEmissionByOrganizationIdYearQuarterAsync } from "./EmissionsSlice";
import { fetchSelectionSourcesByOrganizationAsync } from "../selectionSources/selectionSourcesSlice";
import { fetchWorkshopStandardsAsync } from "../standards/standardsSlice";

interface EmissionComponentProps {
  organizationId?: string;
  year?: number;
  quarter?: number;
}

export const EmissionComponent: React.FC<EmissionComponentProps> = ({ organizationId, year, quarter }) => {
  const dispatch = useAppDispatch();
  const emission = useAppSelector(selectEmission);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId && year && quarter) {
      dispatch(fetchEmissionByOrganizationIdYearQuarterAsync({ organizationId, year, quarter }));
      dispatch(fetchWorkshopStandardsAsync({ workshopId: organizationId, year }));
    }
  }, [year, quarter, organizationId, dispatch]);

  const onUpdate = async (emission: UpdateEmissionDto, id: string) => {
    return dispatch(updateEmissionAsync({ emission, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateEmission = (emission: CreateEmissionDto) => {
    if (!year || !quarter || !organizationId) return;

    dispatch(createEmissionAsync({ ...emission, year, quarter, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteEmission = (emission: Emission) => {
    return dispatch(deleteEmissionAsync({ emission })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const addButton = (
    <Button onClick={() => setVisible(true)} block type="dashed">
      + Добавить выброс
    </Button>
  );

  return (
    <Layout className="bg-white p-4 rounded-lg">
      {visible && <EmissionForm visible={visible} setVisible={setVisible} onCreateEmission={onCreateEmission} />}
      <EditableTable<Emission, UpdateEmissionDto>
        onSave={onUpdate}
        onDelete={deleteEmission}
        entityColumns={EmissionColumns()}
        dataSource={structuredClone(emission).map((data: any) => {
          const standard = data?.standard?.id;
          delete data?.standard;
          data.standard = standard;
          return data;
        }) as any}

        footer={() => addButton}
      />
    </Layout>
  );
};
