import { Button, Layout, Space } from "antd";
import { useState, useEffect } from "react";
import { UpdateWasteContragentDto, CreateWasteContragentDto, WasteContragent } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableTable } from "../../components/editable-table/EditableTable";
import { FileForm } from "../files/components/FileForm";
import { fetchFilesByIdsAsync } from "../files/FilesEntitySlice";
import { WasteContragentForm } from "./components/WasteContragentForm";
import { wasteContragentsColumns } from "./form/columns";
import { createWasteContragentsAsync, updateWasteContragentAsync, fetchWasteContragentsByOrganizationAsync, selectWasteContragents, deleteWasteContragentAsync } from "./WasteContragentsSlice";
import { FolderOutlined } from '@ant-design/icons';

interface WasteContragentsComponentProps {
  organizationId?: string;
}

export const WasteContragentsComponent: React.FC<WasteContragentsComponentProps> = ({ organizationId }) => {
  const dispatch = useAppDispatch();
  const wasteContragents = useAppSelector(selectWasteContragents);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchWasteContragentsByOrganizationAsync(organizationId));
    }
  }, [organizationId, dispatch]);

  const onUpdateWasteContragent = async (wasteContragent: UpdateWasteContragentDto, id: string) => {
    return dispatch(updateWasteContragentAsync({ wasteContragent, id })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  };

  const onCreateWasteContragent = (wasteContragent: CreateWasteContragentDto) => {
    if (!organizationId) return;

    dispatch(createWasteContragentsAsync({ ...wasteContragent, organizationId })).then((res: any) => {
      if (!res.error) {
        setVisible(false);
      }
    });
  };

  const deleteWasteContragents = (wasteContragent: WasteContragent) => {
    return dispatch(deleteWasteContragentAsync({ wasteContragent })).then((res: any) => {
      if (!res.error) {
        return true;
      }
      return false;
    });
  }

  const [visibleFileForm, setVisibleFileForm] = useState(false);
  const [currentWasteContragent, setCurrentWasteContragent] = useState<WasteContragent>();

  const showFileModal = async (wasteContragent: WasteContragent) => {
      // Fetch file for the current record
      if (wasteContragent.fileIds?.length > 0) {
          await dispatch(fetchFilesByIdsAsync({ ids: wasteContragent.fileIds.join(',') }));
      }

      setCurrentWasteContragent(wasteContragent);
      setVisibleFileForm(true);
  };

  return (
    <Layout className="bg-white p-4 rounded-lg">
    {visible && <WasteContragentForm visible={visible} setVisible={setVisible} onCreateWasteContragent={onCreateWasteContragent} />}
    <div className="table-responsive">
      <EditableTable<WasteContragent, UpdateWasteContragentDto>
        onSave={onUpdateWasteContragent}
        onDelete={deleteWasteContragents}
        entityColumns={
          [
            {
              title: "Файл",
              dataIndex: "btnc" as keyof WasteContragent,
              key: "btnc",
              inputNode: <></>,
              render: (value: any, record: WasteContragent, index: any) => (
                <Button.Group>
                    <Button icon={<FolderOutlined />} onClick={() => showFileModal(record)}></Button>
                </Button.Group>
              )
              ,
              editable: false,
              copyable: false,
            },
            ...wasteContragentsColumns
          ]
        }
        dataSource={wasteContragents}
      />
    </div>
    {visibleFileForm && <FileForm visible={visibleFileForm} setVisible={setVisibleFileForm} onUpdate={onUpdateWasteContragent} fileIds={currentWasteContragent?.fileIds} id={currentWasteContragent?.id} maxCount={3}/>}
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => setVisible(true)} block type="dashed">
        + Добавить контрагента
      </Button>
    </Space>
  </Layout>
  );
};
