import { Input, InputNumber } from "antd";
import { SoilPoint } from "../../../api";
import { EntityColumns } from "../../../app/types";
import Text from "antd/es/typography/Text";
import TextArea from "antd/es/input/TextArea";
import Select, { DefaultOptionType } from "antd/es/select";

const frequencyOfControlList : DefaultOptionType[] = 
[
  {value: 'WEEKLY', label: 'Еженедельно'},
  {value: 'ONCE_IN_TWO_WEEKS', label: 'Раз в две недели'},
  {value: 'MONTHLY', label: 'Ежемесячно'},
  {value: 'QUARTLY', label: 'Ежеквартально'},
  {value: 'HALF_YEARLY', label: 'Раз в пол года'},
  {value: 'YEARLY', label: 'Ежегодно'},
]

function getFrequencyOfControlLabel(value: String) {
  return frequencyOfControlList.find(item => item.value === value)?.label
}

export const soilPointsColumns: EntityColumns<SoilPoint>[] = [
  {
    title: `№`,
    dataIndex: "no",
    key: "no",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: true, message: "no" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Точка отбора проб",
    dataIndex: "name",
    key: "name",
    inputNode: <Input />,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: `Широта`,
    dataIndex: "latitude",
    key: "latitude",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: true, message: "Широта неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },],
    editable: true,
    copyable: true,
  },
  {
    title: `Долгота`,
    dataIndex: "longitude",
    key: "longitude",
    inputNode: <InputNumber style={{ width: '100%' }} />,
    rules: [{ required: true, message: "Долгота неправильного формата", pattern: new RegExp("^[0-9]{2,2}(.[0-9]{0,10})?$") },],
    editable: true,
    copyable: true,
  },
  {
    title: "Периодичность контроля",
    dataIndex: "frequencyOfControl",
    key: "frequencyOfControl",
    inputNode: <Select options={frequencyOfControlList} />,
    render: (value) => <Text className="w-64" ellipsis={{ tooltip: `${getFrequencyOfControlLabel(value)}` }}>{getFrequencyOfControlLabel(value)}</Text>,
    rules: [{ required: true, message: "Заполните поле" }],
    editable: true,
    copyable: true,
  },
  {
    title: "Комментарии",
    dataIndex: "comment",
    inputNode: <TextArea />,
    render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
    editable: true,
    copyable: true,
  },
];
