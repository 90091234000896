import { Input, InputNumber, Select, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import { Drop } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { EntityColumns } from "../../../app/types";
import { selectDropCodes } from "../../dropCodes/DropCodesSlice";
import { selectInfluenceObject } from "../../influenceObjects/InfluenceObjectsSlice";

export const DropColumns = (): EntityColumns<Drop>[] => {
  const influenceObjects = useAppSelector(selectInfluenceObject);
  const dropCodes = useAppSelector(selectDropCodes);

  return [
    {
      title: "Объект воздействия",
      dataIndex: "influenceObject",
      key: "influenceObjectId",
      inputNode: <Select
        showSearch
        filterOption={(input, option) => {
          if (option) {
            return (
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          } else {
            return true;
          }
        }}
        options={influenceObjects?.map(
          (influenceObject) => {
            return {
              value: influenceObject.id,
              label: influenceObject.name
            }
          }
        )} />,
      render: (obj) => <Text className="w-64" ellipsis={{ tooltip: `${influenceObjects?.find((influenceObject) => influenceObject.id === obj)?.name}` }}>{influenceObjects?.find((influenceObject) => influenceObject.id === obj)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Загрязняющее вещество",
      dataIndex: "dropCode",
      key: "dropCodeId",
      inputNode: <Select
        showSearch
        filterOption={(input, option) => {
          if (option) {
            return (
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          } else {
            return true;
          }
        }}
        options={dropCodes?.map(
          (dropCode) => {
            return {
              value: dropCode?.id,
              label: dropCode?.name
            }
          }
        )} />,
      render: (code) => <Text className="w-64" ellipsis={{ tooltip: `${dropCodes?.find((dropCode) => dropCode.id === code)?.name}` }}>{dropCodes?.find((dropCode) => dropCode.id === code)?.name}</Text>,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Расход сточных вод, тыс. м3/год",
      dataIndex: "waterConsumptionM3Y",
      key: "waterConsumptionM3Y",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Допустимая концентрация на выпуске, мг/дм3",
      dataIndex: "allowableConcentrationMGDM3",
      key: "allowableConcentrationMGDM3",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Сброс, г/ч",
      dataIndex: "dropGY",
      key: "dropGY",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Сброс, т/год",
      dataIndex: "dropTY",
      key: "dropTY",
      inputNode: <InputNumber style={{ width: '100%' }} />,
      rules: [{ required: true, message: "Заполните поле" }],
      editable: true,
      copyable: true,
    },
    {
      title: "Комментарии",
      dataIndex: "comment",
      key: "comment",
      inputNode: <TextArea />,
      render: (text) => <Text className="w-64" ellipsis={{ tooltip: text }}>{text}</Text>,
      editable: true,
      copyable: true,
    },
  ]
}
