import { Breadcrumb, Button, Layout, Popconfirm, Tabs, Tag } from "antd";
import { useEffect } from "react";
import { CalculationStatusEnum, Permission } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { statusTags } from "./form/columns";
import { calculateAsync, fetchCalculationsByIdAsync, selectCalculationsStatus, selectSelectedCalculation } from "./calculationsSlice";
import { useParams } from "react-router";
import Title from "antd/es/typography/Title";
import { HomeOutlined } from "@ant-design/icons";
import { PageLoading, ProDescriptions } from "@ant-design/pro-components";
import { CalculationInputForm } from "./components/CaclulationInputForm";
import { CalculationResults } from "./components/CalculationResults";
import { Link } from "react-router-dom";

interface CalculationComponentProps { }

export const CalculationComponent: React.FC<CalculationComponentProps> = () => {
  const dispatch = useAppDispatch();

  const { calculationId, id: organizationId } = useParams();

  const calculation = useAppSelector(selectSelectedCalculation);
  const status = useAppSelector(selectCalculationsStatus);

  useEffect(() => {
    calculationId && dispatch(fetchCalculationsByIdAsync(calculationId));
  }, [calculationId, dispatch]);

  const calculate = () => {
    if (calculationId) {
      dispatch(calculateAsync(calculationId));
    }
  };

  if (!calculation) return <PageLoading />;

  if (status === "loading") return <PageLoading />;

  return (
    <Layout className="bg-white p-4 rounded-lg">
      <Title className="ml-2 !mt-0" level={1}>
        Расчет "{calculation?.name}"
      </Title>

      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to={`/`}><HomeOutlined /></Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/organizations/${organizationId}`}>Профиль организации</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/organizations/${organizationId}/module/emissions`}>Мониторинг эмиссий</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Расчет</Breadcrumb.Item>
      </Breadcrumb>

      <ProDescriptions title={calculation?.name} dataSource={calculation}>
        <ProDescriptions.Item valueType="option">
          <Popconfirm
            title="Перед расчетом проверьте правильность введенных параметров?"
            onConfirm={calculate}
            disabled={calculation.status === CalculationStatusEnum.Calculated}
            okText="Рассчитать"
            cancelText="Отмена"
          >
            <Button type="primary" disabled={calculation.status === CalculationStatusEnum.Calculated}>
              Рассчитать
            </Button>
          </Popconfirm>
        </ProDescriptions.Item>
        <ProDescriptions.Item dataIndex="id" label="ID" />
        <ProDescriptions.Item dataIndex="createdAt" label="Создан" valueType="date" />
        <ProDescriptions.Item
          label="Статус"
          dataIndex="status"
          render={(text) => <Tag color={statusTags[text as CalculationStatusEnum]?.color}>{statusTags[text as CalculationStatusEnum]?.text}</Tag>}
        />
        <ProDescriptions.Item label="Квартал" dataIndex="quarter" />
        <ProDescriptions.Item label="Год" dataIndex="уear" render={(_, row) => row.year} />
        <ProDescriptions.Item
          label="Разрешение "
          dataIndex="permission"
          render={(permission: any) => `${(permission as Permission).name} от ${(permission as Permission).yearFrom}`}
        />
      </ProDescriptions>

      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="input"
        items={[
          {
            key: "input",
            label: "Входные параметры",
            children: (
              <CalculationInputForm inputs={calculation?.calculationInputs} disabled={calculation.status === CalculationStatusEnum.Calculated} />
            ),
          },
          {
            key: "results",
            label: "Результаты расчета",
            children: <CalculationResults results={calculation?.calculationResults} />,
          },
        ]}
      />
    </Layout>
  );
};
