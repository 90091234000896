import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { ReportPaymentsApi } from "../../api/apis/report-payments-api";
import { CreateReportPaymentDto } from "../../api/models/create-report-payment-dto";
import { ReportPayment } from "../../api/models/report-payment";
import { UpdateReportPaymentDto } from "../../api/models/update-report-payment-dto";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface ReportPaymentsState {
  reportPayments: ReportPayment[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: ReportPaymentsState = {
  reportPayments: [],
  status: "idle",
};

export const generateReportPaymentAsync = createAsyncThunk(
  "reportPayments/generate",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const reportPaymentsApi = new ReportPaymentsApi();
      const response = await reportPaymentsApi.reportPaymentsControllerGenerate(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createReportPaymentAsync = createAsyncThunk(
  "reportPayments/create",
  async (reportPayment: CreateReportPaymentDto, { rejectWithValue }) => {
    try {
      const reportPaymentsApi = new ReportPaymentsApi();
      const response = await reportPaymentsApi.reportPaymentsControllerCreate(reportPayment);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateReportPaymentAsync = createAsyncThunk(
  "reportPayments/update",
  async ({ reportPayment, id }: { reportPayment: UpdateReportPaymentDto; id: string }, { rejectWithValue }) => {
    try {
      const reportPaymentsApi = new ReportPaymentsApi();
      const response = await reportPaymentsApi.reportPaymentsControllerUpdate(reportPayment, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteReportPaymentAsync = createAsyncThunk(
  "reportPayments/remove",
  async ({ reportPayment }: { reportPayment: ReportPayment }, { rejectWithValue }) => {
    try {
      const reportPaymentsApi = new ReportPaymentsApi();
      const response = await reportPaymentsApi.reportPaymentsControllerRemove(reportPayment.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchReportPaymentsByQuarterAsync = createAsyncThunk("reportPayments/fetch", async ({organizationId, year, quarter}: {organizationId: string, year: number, quarter: number}, { rejectWithValue }) => {
  try {
    const radiologyApi = new ReportPaymentsApi();
    const response = await radiologyApi.reportPaymentsControllerFindByQuart(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchReportPaymentsByQuarter = () => {
    const { pending, fulfilled, rejected } = fetchReportPaymentsByQuarterAsync;
    return {
      [`${pending}`]: (state: ReportPaymentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPaymentsState, action: PayloadAction<ReportPayment[]>) => {
        state.reportPayments = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPaymentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const generateReportPayment = () => {
    const { pending, fulfilled, rejected } = generateReportPaymentAsync;

    return {
      [`${pending}`]: (state: ReportPaymentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPaymentsState, action: PayloadAction<ReportPayment>) => {
        state.reportPayments = state.reportPayments.map((reportPayment) => (reportPayment.id === action.payload.id ? action.payload : reportPayment));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPaymentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const createReportPayment = () => {
    const { pending, fulfilled, rejected } = createReportPaymentAsync;
    return {
      [`${pending}`]: (state: ReportPaymentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPaymentsState, action: PayloadAction<ReportPayment>) => {
        state.reportPayments.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPaymentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateReportPayment = () => {
    const { pending, fulfilled, rejected } = updateReportPaymentAsync;

    return {
      [`${pending}`]: (state: ReportPaymentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPaymentsState, action: PayloadAction<ReportPayment>) => {
        state.reportPayments = state.reportPayments.map((reportPayment) => (reportPayment.id === action.payload.id ? action.payload : reportPayment));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPaymentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeReportPayment = () => {
    const { pending, fulfilled, rejected } = deleteReportPaymentAsync;
    return {
      [`${pending}`]: (state: ReportPaymentsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: ReportPaymentsState, action: PayloadAction<ReportPayment>) => {
        state.reportPayments.splice(state.reportPayments.indexOf(
          state.reportPayments.find((ws: ReportPayment) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: ReportPaymentsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchReportPaymentsByQuarter(), ...createReportPayment(), ...updateReportPayment(), ...removeReportPayment(), ...generateReportPayment() };
};

export const reportPaymentsSlice = createSlice({
  name: "reportPayments",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectReportPayments = (state: RootState) => state.reportPayments.reportPayments;

export default reportPaymentsSlice.reducer;
