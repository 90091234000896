import { Col, Layout, Menu, MenuProps, Row } from "antd";
import { useState } from "react";
import { Organization } from "../../api";
import { WasteAccumulationLimitsComponent } from "../wasteAccumulationLimits/WasteAccumulationLimitsComponent";
import { WasteBurialLimitsComponent } from "../wasteBurialLimits/WasteBurialLimitsComponent";
import { WastePassportsComponent } from "../wastePassports/WastePassportsComponent";
import { WasteRegistersComponent } from "../wasteRegister/WasteRegisterComponent";

interface WasteStandardsComponentProps {
  organization?: Organization;
  year?: number;
  quarter?: number;
}

type MenuItem = Required<MenuProps>['items'][number];

export const WasteStandardsComponent: React.FC<WasteStandardsComponentProps> = ({ organization, year, quarter }) => {

  const [menuSelected, setMenuSelected] = useState<any[]>([getItem('Лимиты накопления отходов', 'accumulationLimits')]);

  const menuItems: MenuProps['items'] = [
    getItem('Лимиты накопления отходов', 'accumulationLimits'),
    getItem('Лимиты захоронения отходов', 'burialLimits'),
    getItem('Регистр образующихся/принимаемых на предприятии отходов ', 'wasteRegister'),
    getItem('Паспорта отходов', 'wastePassports'),
  ];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const onClick: MenuProps['onClick'] = (e) => {
    let item: any;
    for (const key of e.keyPath.reverse()) {
      if (item && item.length && item[0] && item[0].children) {
        item = [...item, ...item[0].children.filter((child: any) => child?.key === key)];
      } else {
        item = menuItems.filter((item) => item?.key === key)
      }
    }
    setMenuSelected(item);
  };

  const menuComponents = {
    "accumulationLimits": (<WasteAccumulationLimitsComponent organization={organization} year={year} />),
    "burialLimits": (<WasteBurialLimitsComponent organization={organization} year={year} />),
    "wasteRegister": (<WasteRegistersComponent organization={organization} year={year} />),
    "wastePassports": (<WastePassportsComponent organization={organization} />),
  }

  return (
    <>
      <Layout className="bg-white p-4 rounded-lg">
        <Row gutter={[24, 0]}>
          <Col span={24} md={6} className="mb-24">
            <Menu
              onClick={onClick}
              style={{ width: 256 }}
              defaultSelectedKeys={['accumulationLimits']}
              mode="inline"
              items={menuItems}
            />
          </Col>

          <Col span={24} md={18} className="mb-24" style={{ width: 100, overflowX: 'scroll' }}>
            <h2>
              {menuSelected.map((menu: any, index) => {
                if (index + 1 >= menuSelected.length) {
                  return `${menu?.label}`;
                } else {
                  return `${menu?.label} / `;
                }
              })}
            </h2>

            {menuSelected.length > 0 && menuComponents[menuSelected[menuSelected.length - 1].key as keyof typeof menuComponents]}
          </Col>
        </Row>
      </Layout>
    </>
  );
};
