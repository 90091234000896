import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateSoilPointPollutantDto, SoilPointPollutantsApi, UpdateSoilPointPollutantDto, SoilPointPollutant } from "../../api";
import { serializeError } from "../../app/helpers";
import { RootState } from "../../app/store";
import { ApiError } from "../../app/types";

export interface SoilPointPollutantsState {
  soilPointPollutants: SoilPointPollutant[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: SoilPointPollutantsState = {
  soilPointPollutants: [],
  status: "idle",
};

export const createSoilPointPollutantAsync = createAsyncThunk(
  "soilPointPollutants/create",
  async (soilPointPollutant: CreateSoilPointPollutantDto, { rejectWithValue }) => {
    try {
      const soilPointPollutantsApi = new SoilPointPollutantsApi();
      const response = await soilPointPollutantsApi.soilPointPollutantsControllerCreate(soilPointPollutant);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSoilPointPollutantAsync = createAsyncThunk(
  "soilPointPollutants/update",
  async ({ soilPointPollutant, id }: { soilPointPollutant: UpdateSoilPointPollutantDto; id: string }, { rejectWithValue }) => {
    try {
      const soilPointPollutantsApi = new SoilPointPollutantsApi();
      const response = await soilPointPollutantsApi.soilPointPollutantsControllerUpdate(soilPointPollutant, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSoilPointPollutantAsync = createAsyncThunk(
  "soilPointPollutants/remove",
  async ({ soilPointPollutant }: { soilPointPollutant: SoilPointPollutant }, { rejectWithValue }) => {
    try {
      const soilPointPollutantsApi = new SoilPointPollutantsApi();
      const response = await soilPointPollutantsApi.soilPointPollutantsControllerRemove(soilPointPollutant.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSoilPointPollutantsByPointAsync = createAsyncThunk("soilPointPollutants/fetch", async (id: string, { rejectWithValue }) => {
  try {
    const soilPointPollutantsApi = new SoilPointPollutantsApi();
    const response = await soilPointPollutantsApi.soilPointPollutantsControllerFindByPointId(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchSoilPointPollutantsByPoint = () => {
    const { pending, fulfilled, rejected } = fetchSoilPointPollutantsByPointAsync;
    return {
      [`${pending}`]: (state: SoilPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointPollutantsState, action: PayloadAction<SoilPointPollutant[]>) => {
        state.soilPointPollutants = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createSoilPointPollutant = () => {
    const { pending, fulfilled, rejected } = createSoilPointPollutantAsync;
    return {
      [`${pending}`]: (state: SoilPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointPollutantsState, action: PayloadAction<SoilPointPollutant>) => {
        state.soilPointPollutants.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateSoilPointPollutant = () => {
    const { pending, fulfilled, rejected } = updateSoilPointPollutantAsync;

    return {
      [`${pending}`]: (state: SoilPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointPollutantsState, action: PayloadAction<SoilPointPollutant>) => {
        state.soilPointPollutants = state.soilPointPollutants.map((soilPointPollutant) => (soilPointPollutant.id === action.payload.id ? action.payload : soilPointPollutant));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeSoilPointPollutant = () => {
    const { pending, fulfilled, rejected } = deleteSoilPointPollutantAsync;
    return {
      [`${pending}`]: (state: SoilPointPollutantsState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: SoilPointPollutantsState, action: PayloadAction<SoilPointPollutant>) => {
        state.soilPointPollutants.splice(state.soilPointPollutants.indexOf(
          state.soilPointPollutants.find((ws: SoilPointPollutant) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: SoilPointPollutantsState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchSoilPointPollutantsByPoint(), ...createSoilPointPollutant(), ...updateSoilPointPollutant(), ...removeSoilPointPollutant() };
};

export const soilPointPollutantsSlice = createSlice({
  name: "soilPointPollutants",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectSoilPointPollutants = (state: RootState) => state.soilPointPollutants.soilPointPollutants;

export default soilPointPollutantsSlice.reducer;
