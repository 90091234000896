import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { AxiosError } from "axios";
import { CreateCarbonContractWorkDto, CarbonContractWorkApi, UpdateCarbonContractWorkDto, CarbonContractWork } from "../../../api";
import { serializeError } from "../../../app/helpers";
import { RootState } from "../../../app/store";
import { ApiError } from "../../../app/types";

export interface CarbonContractWorkState {
  carbonContractWork: CarbonContractWork[];
  status: "idle" | "loading" | "failed";
  error?: ApiError;
}

const initialState: CarbonContractWorkState = {
  carbonContractWork: [],
  status: "idle",
};

export const createCarbonContractWorkAsync = createAsyncThunk(
  "carbonContractWork/create",
  async (carbonContractWork: CreateCarbonContractWorkDto, { rejectWithValue }) => {
    try {
      const carbonContractWorkApi = new CarbonContractWorkApi();
      const response = await carbonContractWorkApi.carbonContractWorkControllerCreate(carbonContractWork);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCarbonContractWorkAsync = createAsyncThunk(
  "carbonContractWork/update",
  async ({ carbonContractWork, id }: { carbonContractWork: UpdateCarbonContractWorkDto; id: string }, { rejectWithValue }) => {
    try {
      const carbonContractWorkApi = new CarbonContractWorkApi();
      const response = await carbonContractWorkApi.carbonContractWorkControllerUpdate(carbonContractWork, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCarbonContractWorkAsync = createAsyncThunk(
  "carbonContractWork/remove",
  async ({ carbonContractWork }: { carbonContractWork: CarbonContractWork }, { rejectWithValue }) => {
    try {
      const carbonContractWorkApi = new CarbonContractWorkApi();
      const response = await carbonContractWorkApi.carbonContractWorkControllerRemove(carbonContractWork.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCarbonContractWorkByOrganizationIdAsync = createAsyncThunk("carbonContractWork/fetch", async ({ organizationId, year, quarter }: { organizationId: string, year: number, quarter: number }, { rejectWithValue }) => {
  try {
    const carbonContractWorkApi = new CarbonContractWorkApi();
    const response = await carbonContractWorkApi.carbonContractWorkControllerFindByOrganizationIdYearQuarter(organizationId, year, quarter);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createExtraReducers = () => {
  const fetchCarbonContractWorkByOrganization = () => {
    const { pending, fulfilled, rejected } = fetchCarbonContractWorkByOrganizationIdAsync;
    return {
      [`${pending}`]: (state: CarbonContractWorkState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonContractWorkState, action: PayloadAction<CarbonContractWork[]>) => {
        state.carbonContractWork = action.payload;
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonContractWorkState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        state.error = error;
      },
    };
  };

  const createCarbonContractWork = () => {
    const { pending, fulfilled, rejected } = createCarbonContractWorkAsync;
    return {
      [`${pending}`]: (state: CarbonContractWorkState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonContractWorkState, action: PayloadAction<CarbonContractWork>) => {
        state.carbonContractWork.push(action.payload);
        message.success("Успешно создан");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonContractWorkState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const updateCarbonContractWork = () => {
    const { pending, fulfilled, rejected } = updateCarbonContractWorkAsync;

    return {
      [`${pending}`]: (state: CarbonContractWorkState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonContractWorkState, action: PayloadAction<CarbonContractWork>) => {
        state.carbonContractWork = state.carbonContractWork.map((carbonContractWork) => (carbonContractWork.id === action.payload.id ? action.payload : carbonContractWork));
        message.success("Успешно изменено");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonContractWorkState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  const removeCarbonContractWork = () => {
    const { pending, fulfilled, rejected } = deleteCarbonContractWorkAsync;
    return {
      [`${pending}`]: (state: CarbonContractWorkState) => {
        state.status = "loading";
      },
      [`${fulfilled}`]: (state: CarbonContractWorkState, action: PayloadAction<CarbonContractWork>) => {
        state.carbonContractWork.splice(state.carbonContractWork.indexOf(
          state.carbonContractWork.find((ws: CarbonContractWork) => ws.id === action.payload.id) || action.payload
        ), 1);
        message.success("Успешно удален");
        state.status = "idle";
      },
      [`${rejected}`]: (state: CarbonContractWorkState, action: PayloadAction<AxiosError>) => {
        const error = serializeError(action.payload);
        state.status = "failed";
        message.error("Произошла ошибка !");
        state.error = error;
      },
    };
  };

  return { ...fetchCarbonContractWorkByOrganization(), ...createCarbonContractWork(), ...updateCarbonContractWork(), ...removeCarbonContractWork() };
};

export const carbonContractWorkSlice = createSlice({
  name: "carbonContractWork",
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(),
});

export const selectCarbonContractWork = (state: RootState) => state.carbonContractWork.carbonContractWork;

export default carbonContractWorkSlice.reducer;
