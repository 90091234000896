import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FileEntity } from "../../api/models/file-entity";
import { ApiError } from "../../app/types";
import { FilesApi } from "../../api/apis/files-api";
import { AxiosError } from "axios";
import { serializeError } from "../../app/helpers";
import { message } from "antd";
import { RootState } from "../../app/store";

export interface FileEntityState {
    files: FileEntity[],
    status: "idle" | "loading" | "failed";
    error?: ApiError;
}

const initialState: FileEntityState = {
    files: [],
    status: "idle"
}

export const fetchFilesByIdAsync = createAsyncThunk(
    "files/fetch",
    async({id}: {id: string}, {rejectWithValue}) => {
        try {
            const fileApi = new FilesApi();

            const response = await fileApi.filesControllerFindOne(id);
            return response.data;
        } catch(error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchFilesByIdsAsync = createAsyncThunk(
    "files/fetchByIds",
    async({ids}: {ids: string}, {rejectWithValue}) => {
        try {
            const fileApi = new FilesApi();

            const response = await fileApi.filesControllerFindFiles(ids);
            return response.data;
        } catch(error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteFileAsync = createAsyncThunk(
    "files/remove",
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const filesApi = new FilesApi();
            const response = await filesApi.filesControllerRemove(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const createExtraReducers = () => {
    const fetchFiles = () => {
        const { pending, fulfilled, rejected } = fetchFilesByIdAsync;
        return {
            [`${pending}`]: (state: FileEntityState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: FileEntityState, action: PayloadAction<FileEntity[]>) => {
                state.files = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: FileEntityState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const fetchFilesByIds = () => {
        const { pending, fulfilled, rejected } = fetchFilesByIdsAsync;
        return {
            [`${pending}`]: (state: FileEntityState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: FileEntityState, action: PayloadAction<FileEntity[]>) => {
                state.files = action.payload;
                state.status = "idle";
            },
            [`${rejected}`]: (state: FileEntityState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                state.error = error;
            },
        };
    };

    const removeFile = () => {
        const { pending, fulfilled, rejected } = deleteFileAsync;
        return {
            [`${pending}`]: (state: FileEntityState) => {
                state.status = "loading";
            },
            [`${fulfilled}`]: (state: FileEntityState, action: PayloadAction<FileEntity>) => {
                state.files.splice(state.files.indexOf(
                    state.files.find((ws: FileEntity) => ws.id === action.payload.id) || action.payload
                ), 1);
                message.success("Успешно удален");
                state.status = "idle";
            },
            [`${rejected}`]: (state: FileEntityState, action: PayloadAction<AxiosError>) => {
                const error = serializeError(action.payload);
                state.status = "failed";
                message.error("Произошла ошибка !");
                state.error = error;
            },
        };
    };

    return {...fetchFiles(), ...removeFile(), ...fetchFilesByIds()}
};

export const FilesSlice = createSlice({
    name: "Files",
    initialState,
    reducers: {},
    extraReducers: createExtraReducers(),
});

export const selectFileEntities = (state: RootState) => state.files.files;

export default FilesSlice.reducer